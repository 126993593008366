/**
 * This   component use for to display the list of recognise fellow
 */
import { useEffect, useState } from 'react'
import ReadMoreReact from 'read-more-react'
import './index.scss'
import ImageSlider from '../Modal/ImageSlider'
import ReadMore from '../../../../../common/ReadMore/ReadMore'

const FellowList = props => {
  const { data } = props
  const onClickShowImageModal = img => {
    setAllImages(img)
    setShowSlider(true)
  }
  const [showSlider, setShowSlider] = useState(false)
  const [allImages, setAllImages] = useState([])

  return (
    <div className="fellows-wrapper">
      <div className="fellows-block">
        <div className="row">
          {data &&
            data.length > 0 &&
            data.map((value, index) => {
              return (
                <div className="col-md-4" key={index}>
                  <div className="fellow-item">
                    <div className="fellow-item-head">
                      <div className="fellow-img">
                        {value?.fellow_profile_pic && (
                          <img src={value.fellow_profile_pic} />
                        )}
                      </div>
                      <div className="fellow-info">
                        <ul>
                          <li>
                            <span className="fellow-name">
                              <strong>
                                {value?.fellow_name}
                                {value.affiliationId}{' '}
                              </strong>
                            </span>
                          </li>
                          <li>
                            <span className="fellow-topic">
                              {value?.field_project_name}
                            </span>
                          </li>
                          <li>
                            <span className="fellow-address">
                              {value?.field_project_location}
                            </span>
                          </li>
                          <li>
                            <span className="fellow-date">{value.date}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="fellow-item-content">
                      <div className="description">
                        <ReadMore
                          text={value?.field_initiatives_description}
                          length={150}
                        />
                      </div>
                      <div className="fellow-content-img">
                        {value?.thumbnail && value?.thumbnail.length > 0 && (
                          <div className="image">
                            <img
                              src={value?.thumbnail[0]}
                              onClick={() => {
                                onClickShowImageModal(value?.thumbnail)
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <ImageSlider
        isOpen={showSlider}
        imageList={allImages}
        className={'gallery-popup'}
        onClose={() => {
          setShowSlider(false)
        }}
      />
    </div>
  )
}

export default FellowList
