import { useState } from "react";
import PropTypes from "prop-types";
import InlineError from "../common/InlineError";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const DateField = (props) => {
  const {
    containerClass,
    value,
    error,
    onChange,
    type,
    label,
    required,
    name,
    hasInvalidSubmitOccurred,
    placeholder,
    readonly,
    disabled,
    onBlur,
    getRef = "",
    className,
    labelClass,
    showIcon,
    showTick,
    dateFormat,
    showMonthYearPicker,
    ...rest
  } = props;

  const [touched, setTouched] = useState(false);

  const handleInputBlur = (e, name) => {
    setTouched(true);
    if (onBlur) {
      onBlur(e, name);
    }

    return true;
  };

  const displayError = touched || hasInvalidSubmitOccurred;

  return (
    <div
      className={`input-field-container clearfix ${containerClass || ""} ${
        displayError && error ? "error" : ""
      }`}
    >
      <div className="inner-wrap">
        {label && (
          <label htmlFor="" className={labelClass}>
            {label}
            {required && <span className="required">&nbsp;*</span>}
          </label>
        )}
        {
          <DatePicker
            onBlur={(e) => handleInputBlur(e, name)}
            className={`input-element ${value ? "filled" : ""} ${className}`}
            disabled={disabled}
            ref={getRef}
            dateFormat={dateFormat}
            showMonthYearPicker={showMonthYearPicker}
            selected={value}
            onChange={(e) => onChange(e, name)}
            placeholderText={placeholder}
            maxDate={new Date()}
            {...rest}
          />
        }
      </div>
      {displayError && error && <InlineError message={error} />}
    </div>
  );
};

DateField.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  hasInvalidSubmitOccurred: PropTypes.bool,
  getRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string,
  dateFormat: PropTypes.string,
  dateFormat: PropTypes.bool,
};

DateField.defaultProps = {
  disabled: false,
  value: "",
  error: "",
  onChange: () => {},
  type: "",
  label: "",
  required: false,
  name: "",
  mask: "",
  placeholder: "",
  containerClass: "",
  onBlur: () => {},
  hasInvalidSubmitOccurred: false,
  getRef: () => {},
  className: "",
  labelClass: "",
  dateFormat: "MMMM yyyy",
  showMonthYearPicker: true,
};

export default DateField;
