/**
 * This  component use for to display the recognise fellow
 */
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
// import { getRecogniseFellowList } from '../../../action'

import './style.scss'
import FellowList from '../FellowList'
import Breadcrumb from '../../../../../common/Breadcrumbs'
import { Link } from 'react-router-dom'

import { initGA, logPageView } from '../../../../../../dependencies/utils/analytics'


const ViewAllProjects = props => {
  const [val, setval] = useState(
    props.location.state.results ? props.location.state.results : ''
  )
  const items = [
    { to: '/', label: '/' },
    { to: '/fellowship-home', label: 'Fellowship' },
  ]

  useEffect(() => {
    try {
      initGA()
      window.GA_INITIALIZED = true
      logPageView()
    } catch (error) {
      
    }
    }, [window])
  console.log('Data=', val)
  return (
    <div className="analytics-dashboard">
      <Breadcrumb data={items} displayName={'Swachhta Sarthi Initiatives'} />
      <div className="discussion-wrapper">
        <div className="discussion-right-block">
          <div className="view-all-wrap d-flex justify-content-start mr-left20">
            <Link
              className=" align-items-center green-circle-link"
              to={{ pathname: '/fellowship-home' }}
            >
              <i className="back-circle-goto-link-green" /> <p>Back</p>
            </Link>
          </div>
          <FellowList data={val} />
        </div>
      </div>
    </div>
  )
}

// const mapStateToProps = (state) => {
//     return {
//         categoryAdata: state?.categoryAdata?.categoryAdata
//     }
// }

const mapStateToProps = state => {
  return {
    val: state?.val?.val,
  }
}

export default connect(mapStateToProps)(withRouter(ViewAllProjects))
