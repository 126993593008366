import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import banner from "../../../assests/images/pledge-banner-dummy.png";
import { Link } from "react-router-dom";
import envalopIcon from "../../../assests/svg/circle-envalop.svg";
import downloadIcon from "../../../assests/svg/circle-download.svg";
import {
  TwitterShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
import sharetwiter from "../../../assests/svg/ic_twitter.svg";
import sharetinsta from "../../../assests/svg/ic_insta_green.svg";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const CertificatePage = (props) => {
  const {
    data,
    pledgeCount,
    PledgeName,
    url,
    bannerImage,
    history,
    numberOfVistiors,
    Studentswhoarenoteligible,
  } = props;

  const [baseUrl, setBaseUrl] = useState("");

  const downloadFile = (url, fileName) => {
    console.log("pledge url", url);
    console.log("pledge url sliced", url.slice(50));
    const newFilename = url.slice(50);
    // var url = "http://www.africau.edu/images/default/sample.pdf";
    // fileName = fileName + ".pdf";
    fileName = newFilename;
    //Create XMLHTTP Request.
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      let blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      let isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  const getDataFromURL = (url) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(url)
          .then((response) => response.text())
          .then((data) => {
            resolve(data);
          });
      });
    }, 2000);
  //href='http://www.africau.edu/images/default/sample.pdf'

  useEffect(() => {
    setBaseUrl(window?.location?.origin);
  }, [window?.location?.origin]);
  let twitterData = `I took the ${PledgeName} to make a difference. Click here to take yours ${baseUrl}/citizen-corner . To engage with the Waste to Wealth Mission, click here`;
  return (
    <div className="pledge-outer pledge-certificate-page">
      <div className="container">
        <div className="pledge-inner">
          <div className="banner-wrap">
            <img src={bannerImage} />
          </div>
          <div className="content">
            <button
              onClick={() => history.push("/citizen-corner")}
              className="green-btn back-button"
            >
              Back to Citizens Corner
            </button>
            <h3 className="text-center">{PledgeName}</h3>
            <div className="certificate-container">
              <div className="pledge-certificate-body mb-5">
                <span className="name">{data?.name}</span>
                <span className="date">{data?.date}</span>
              </div>
            </div>
            <div className="certifcate-share">
              Share :
              <TwitterShareButton url={baseUrl} title={twitterData}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <FacebookShareButton url={baseUrl} quote={twitterData}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <a
                href="https://www.instagram.com/wasteto.wealth/?hl=en"
                target="_blank"
              >
                <img
                  className="border-radius-50"
                  src={sharetinsta}
                  alt=""
                  width="32"
                />
              </a>
            </div>

            <div className="get-certificate-wrap mb-5">
              <a
                target="_blank"
                href="https://www.facebook.com/WasteToWealthMission"
                className="bordered-btn green-border email text-left"
              >
                <img width="68" src={envalopIcon} />
                To actively participate <br />
                Click Here
              </a>

              {/* <a href={data?.pldegeCertificate} target="_blank" className="bordered-btn green-border download text-left"> */}

              <a
                onClick={() => {
                  downloadFile(data?.pldegeCertificate, data?.name);
                }}
                target="_self"
                className="bordered-btn green-border download text-left"
              >
                <img width="68" src={downloadIcon} />
                Pledge to Make A Difference
                <br />
                Download Pledge Certificate
              </a>
            </div>
            <div className="count-wrap d-flex align-item-center justify-content-center">
              <div className="bottom-ribbon">
                {ReactHtmlParser(Studentswhoarenoteligible)}
                {/* <p>
                                    For students who are not eligible to participate on platforms like Facebook and Twitter, we do have customised fellowships and programs for you to actively engage with us <a href="#">click here.</a>
                                </p> */}
              </div>
              <div className="call-no">
                <span className="number">{pledgeCount}</span>Pledge Already
                Taken
              </div>
            </div>
          </div>
        </div>
        <div className="count-wrap d-flex align-item-center justify-content-center">
          <div className="call-no">
            <span className="phoneCal-icon"></span>
            <span className="number">{numberOfVistiors}</span>Visitors on this
            portal
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(CertificatePage);
