import "./style.scss";
import dummyImage from "../../../../../assests/images/dummy_img.png";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Fragment } from "react";

const SalientFeatures = (props) => {
  const { data } = props;
  let arrString = "";
  return (
    <div className="SalientFeatures">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{ReactHtmlParser(data?.heading)}</h1>
            <div className="d-flex salient-inner">
              {data &&
                Array.isArray(data?.features) &&
                data?.features?.length > 0 &&
                data?.features.map((value, index) => {
                  return (
                    <Fragment key={index}>
                      <ul>
                        {value &&
                        Array.isArray(value?.points) &&
                        value?.points?.length > 0 &&
                        value?.points?.length === 1
                          ? value?.points?.map((pointValue, index) => {
                              return (
                                <li key={index}>
                                  {ReactHtmlParser(pointValue)}
                                </li>
                              );
                            })
                          : ((arrString = " "),
                            value?.points?.map((pointValue, t, row) => {
                              arrString = arrString + " " + pointValue;
                              if (index + 1 === row.length) {
                                return <li>{arrString}</li>;
                              }
                            }))}
                      </ul>
                    </Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalientFeatures;
