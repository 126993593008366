import {
    studentName,
    validateDob,
    validateDropDown,
    validateCity,
    validatePincode,
    validateEmail,
    validatePhone,
    validationRadio,
} from '../../../dependencies/utils/validation';

const validation = {
    citizenPledgeFormValidation: (params) => {
        const {
            name,
           
            salutation,
            language,
            dob,
            state,
            city,
            pincode,
            email,
            phone,
            gender,
        } = params;
       

        let formStatus = {};
        const nameStatus = studentName(name);
        const salutationStatus = validateDropDown(salutation);
        const languageStatus = validateDropDown(language);
        const dobStatus = validateDob(dob);
        const stateStatus = validateDropDown(state);
        const cityStatus = validateCity(city);
        const pincodeStatus = validatePincode(pincode)
        const emailStatus = validateEmail(email);
        const phoneStatus = validatePhone(phone);
        const genderStatus = validationRadio(gender);
        formStatus = {
            name: nameStatus.error,
            salutation: salutationStatus.error,
            language: languageStatus.error,
            dob: dobStatus.error,
            state: stateStatus.error,
            city: cityStatus.error,
            pincode: pincodeStatus.error,
            email: emailStatus.error,
            phone: phoneStatus.error,
            gender: genderStatus.error,


            hasErrors: (
                nameStatus.hasError
                || salutationStatus.hasError
                || languageStatus.hasError
                || dobStatus.hasError
                || stateStatus.hasError
                || cityStatus.hasError
                || pincodeStatus.hasError
                || emailStatus.hasError
                || phoneStatus.hasError
                || genderStatus.hasError
            )
        };
        
        return formStatus;
    },
};


export default validation;
