import api from '../../../dependencies/utils/Api';
import {
    ANALYTICS_DASHBOARD_DATA,
} from "../../../constants/ApplicationUrl";

export const AnalyticsDashboardService = {
    analyticsData() {
        return api.GET(ANALYTICS_DASHBOARD_DATA)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response || {};
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
}