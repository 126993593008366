export const USER_LIST = "USER_LIST";
export const IS_LOADING = "IS_LOADING";
export const CHALLANGE_EVENT_LISTING = "CHALLANGE_EVENT_LISTING";
export const CHALLANGE_EVENT_DETAILS = "CHALLANGE_EVENT_DETAILS";
export const HEADER_DATA = "HEADER_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
export const CURRENT_INTERVENTION = "CURRENT_INTERVENTION";
export const QUOTES = "QUOTES";
export const FEATURED_TECHNOLOGY = "FEATURED_TECHNOLOGY";
export const IMPORTANT_CHALLENGES = "IMPORTANT_CHALLENGES";
export const BANNER = "BANNER";
export const FOOTER_DATA = "FOOTER_DATA";
export const MAJOR_INVESTORS = "MAJOR_INVESTORS";
export const LATEST_NEWS = "LATEST_NEWS";
export const UPCOMMING_EVENTS = "UPCOMMING_EVENTS";
export const CONTACT_US_DATA = "CONTACT_US_DATA";
export const CHALLENGE_TECHNOLOGY = "CHALLENGE_TECHNOLOGY";
export const CHALLENGE_CATEGORY = "CHALLENGE_CATEGORY";
export const ABOUT_US = "ABOUT_US";
export const EVENT_LISTING = "EVENT_LISTING";
export const EVENT_DETAILS = "EVENT_DETAILS";
export const FEATURED_EVENT = "FEATURED_EVENT";
export const CURRENT_INTERVENTION_LIST = "CURRENT_INTERVENTION_LIST";
export const ARTICLES_CASE_STUDIES_LIST = "ARTICLES_CASE_STUDIES_LIST";
export const ARTICLES_CASE_STUDIES_TAG_LIST = "ARTICLES_CASE_STUDIES_TAG_LIST";
export const ARTICLES_CASE_STUDIES_TAGS = "ARTICLES_CASE_STUDIES_TAGS";
export const ARTICLES_CASE_STUDIES_MOSTREAD = "ARTICLES_CASE_STUDIES_MOSTREAD";
export const ARTICLES_CASE_STUDIES_DETAILS = "ARTICLES_CASE_STUDIES_DETAILS";
export const PAST_PROJECTS_DETAILS = "PAST_PROJECTS_DETAILS";
export const INTERVENTION_TECNOLOGY = "INTERVENTION_TECNOLOGY";
export const CURRENT_INTERVENTION_DETAILS = "CURRENT_INTERVENTION_DETAILS";
export const FILTER_DATA = "FILTER_DATA";
export const FELLOWHIP = "FELLOWHIP";
export const FELLOWSHIP_DETAILS = "FELLOWSHIP_DETAILS";
export const CITEZEN_CORNER = "CITEZEN_CORNER";
export const PARTNER_WITH_US_DETAILS = "PARTNER_WITH_US_DETAILS";

export const SUCCESS_STORIES_LIST = "SUCCESS_STORIES_LIST";
export const SUCCESS_STORIES_TECNOLOGY = "SUCCESS_STORIES_TECNOLOGY";
export const SUCCESS_STORIES_DETAILS = "SUCCESS_STORIES_DETAILS";
export const SUCCESS_STORIES_FILTER_DATA = "SUCCESS_STORIES_FILTER_DATA";
export const SELECT_LANGUAGE = "SELECT_LANGUAGE";
export const SUBMIT_TECH_DATA = "SUBMIT_TECH_DATA";
export const FELLOWSHIP_INTERVENTION_TECNOLOGY =
  "FELLOWSHIP_INTERVENTION_TECNOLOGY";
export const FELLOWSHIP_INTERVENTION_LIST = "FELLOWSHIP_INTERVENTION_LIST";
export const PLEDGE_DATA = "PLEDGE_DATA";
export const ANALYTICS_DATA = "ANALYTICS_DATA";
export const POLICY_TYPE = "POLICY_TYPE";
export const POLICY_LIST = "POLICY_LIST";
export const POLICY_TECHNOLOGY = "POLICY_TECHNOLOGY";

export const KM_LIST = "KM_LIST";
export const KM_TECNOLOGY = "KM_TECNOLOGY";
export const KM_DETAILS = "KM_DETAILS";
export const KM_FILTER_DATA = "KM_FILTER_DATA";
export const NUMBER_OF_VISITORS = "NUMBER_OF_VISITORS";
export const VALIDATION_LINK = "VALIDATION_LINK";
export const SWACHHTA_SARTHI_FELLOWSHIP_HOME =
  "SWACHHTA_SARTHI_FELLOWSHIP_HOME";

export const ABOUT_COHORT_GET_STATE_DETAILS = "ABOUT_COHORT_GET_STATE_DETAILS";
export const FELLOWSHIP_INITIATIVES = "FELLOWSHIP_INITIATIVES";
export const PAST_PROJECTS_LIST = "PAST_PROJECTS_LIST";
export const EVENT_DETAILS2 = "EVENT_DETAILS2";
export const SWACHHTA_SARTHI_COHORT_MAP = "SWACHHTA_SARTHI_COHORT_MAP";
export const SWACHHTA_SARTHI_ABOUT_MENTOR = "SWACHHTA_SARTHI_ABOUT_MENTOR";
export const SWACHHTA_SARTHI_MEET_OUR_FELLOWS =
  "SWACHHTA_SARTHI_MEET_OUR_FELLOWS";
export const RUTAG_PAGE = "RUTAG_PAGE";
export const RUTAG_PAGE_DETAILS = "RUTAG_PAGE_DETAILS";
// export const VALIDATED_TECH_FILTERDATA = "VALIDATED_TECH_FILTERDATA";
