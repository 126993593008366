/**
 * This  component use for list of current intervention list with filter
 */
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import OverView2 from "../dependencies/component/OverView2";
// import { ShareSocial } from "react-share-social";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

// import InputField from "../../../components/common/InputField";
// import Button from "../../../components/common/Button";
// import ReactHtmlParser from "react-html-parser";
// import searchIcon from "../../../assests/svg/searchIcon.svg";
import {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
  getArticlesDetailsType,
} from "../dependencies/action";

// import LeftSideCategory from "../../../components/common/LeftSideCategory";
// import GlobalDropDown from "../../common/GlobalDropDown";
import Breadcrumb from "../../common/Breadcrumbs";
import "./style.scss";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const items = [
  // { to: "/", label: "/" },
  // { to: "/current-intervention-listing", label: "What We Do" },
  // { to: "/current-intervention-listing", label: "Our Sentinels" },
  // {
  //   to: "/current-intervention-listing",
  //   label: "Current Projects",
  //   active: true,
  // },

  { to: "/", label: "/" },
  { to: "/articles-case-studies", label: "Resources" },
  {
    to: "/articles-case-studies",
    label: "Articles & Case Studies",
    active: true,
  },
];

const style = {
  background: "none",
  borderRadius: 0,
  border: 0,
  color: "transparent",
  padding: "0px",
  boxShadow: "0px",
  // display: "none",
};

const ArticlesBlogpage = (props) => {
  const {
    getChallengeEventList,
    currentInterventionList,
    getTechnologyType,
    interventionTechnology,
    getFilterData,
    filterData,
    articlesDetails,
    getArticlesDetailsType,
  } = props;
  const [page, setPage] = useState(0);
  const [categoryId, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [pageStatus, setPageStatus] = useState(false);
  const [isMoreFilter, setMoreFilter] = useState(false);
  const handleShowMoreFiltere = () => setMoreFilter(!isMoreFilter);
  const defaultFormData = {
    state: "",
    city: "",
    projectTimeline: "",
    useType: "",
    subTheme: "",
    scale: "",
    specialCondition: "",
    search: "",
  };
  const [formData, setFormData] = useState(defaultFormData);

  const sharesocial = useRef();
  const articlestitletext = useRef();

  const changePageNumber = (pageNumber) => {
    setPageStatus(false);
    setPage((pageNumber - 1) * 6);
  };
  useEffect(() => {
    getChallengeEventListData();
  }, [page, categoryId, style]);
  const handleInputChange = (e, name) => {
    const { value } = e.target;

    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };

  useEffect(() => {
    // const reloadCount = sessionStorage.getItem("reloadCount");
    // if (reloadCount < 2) {
    //   // sharesocial.current.style.display = "none";
    //   sessionStorage.setItem("reloadCount", String(reloadCount + 1));
    //   window.location.reload();
    //   do {
    //     sharesocial.current.style.display = "none";
    //   } while (window.location.reload());
    // } else {
    //   sessionStorage.removeItem("reloadCount");
    //   // sharesocial.current.style.display = "block";
    // }
  }, []);

  const mostreaddata = [
    {
      id: 1,
      date: "May 08, 2021",
      title:
        "Applying for a Curfew/Movement/Emergency Pass during COVID-19 Lockdown",
    },
    {
      id: 2,
      date: "May 09, 2021",
      title: "State and National funds accepting donations for COVID-19",
    },
    {
      id: 3,
      date: "May 12, 2021",
      title: "10 Types of Financial Services Offered in India",
    },
  ];

  useEffect(() => {
    // to call the initialise google analytics funtion
    try {
      initGA();
      window.GA_INITIALIZED = true;
      // to save the number og visitors on this side
      logPageView();
    } catch (error) {}
  }, [window]);

  const getChallengeEventListData = () => {
    getArticlesDetailsType(props.location.state?.pageLink);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setPage(0);
    setPageStatus(true);
    getChallengeEventListData();
  };

  useEffect(() => {
    getTechnologyType();
    getFilterData();
    // getArticlesDetailsType();
    getChallengeEventListData();
  }, []);

  const getCategoryId = (Id) => {
    setCategory(Id);
  };

  return (
    <div className="ArticlesBlogpage">
      <Breadcrumb data={items} displayName={"Articles & Case Studies"} />

      <div className="bottom-section-list">
        <div className="common-listing blogcontainer">
          <div className="row blogrow">
            {/* <div className="col-3 sidebar">
                
              </div> */}
            <div className="col-xs-12 list-container">
              <div className="boxWithShadow">
                {/* <div className="social-icons-div">
                   <div>
                     <a href="#" class="fa fa-facebook"></a>
                     <a href="#" class="fa fa-twitter"></a>
                     <a href="#" class="fa fa-linkedin"></a>
                   </div>
                 </div> */}
                <div
                  ref={sharesocial}
                  className="reactSocialDiv"
                  // style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {/* <ShareSocial
                     title={"Share to"}
                     style={style}
                     url="https://uat.wastetowealth.gov.in/articles-blogpage"
                     socialTypes={["facebook", "twitter", "linkedin"]}
                   /> */}
                  <div className="sharetoheader">share</div>
                  <div
                    // style={{ width: "fit-content", position: "absolute" }}
                    className="reactsocialinner"
                  >
                    <FacebookShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                  <div className="blogpage-date-tag">
                    <time>
                      {articlesDetails?.data?.publish_date != null
                        ? articlesDetails?.data?.publish_date
                        : "NA"}
                    </time>
                  </div>
                </div>

                <h1
                  // ref={articlestitletext}
                  className="blogTitleName"
                  style={{
                    width:
                      articlestitletext?.current?.innerHTML.length > 41
                        ? "85%"
                        : "",
                    textAlign:
                      articlestitletext?.current?.innerHTML.length > 41
                        ? "center"
                        : "",
                  }}
                >
                  <span
                    ref={articlestitletext}
                    class="field field--name-title field--type-string field--label-hidden"
                  >
                    {articlesDetails?.data?.title}
                    {/* Article Testing Page Article Testing Page Article Testing
                    Page */}
                  </span>
                </h1>
                <div
                  className="blog-author flex"
                  style={{
                    width:
                      articlestitletext?.current?.innerHTML.length > 41
                        ? "85%"
                        : "",
                  }}
                >
                  <div className="flex">
                    {articlesDetails?.data?.author &&
                    articlesDetails?.data?.author.length != 0 ? (
                      <>
                        <span class="by">By:</span>
                        {articlesDetails?.data?.author.length != 0 ? (
                          articlesDetails?.data?.author.map(
                            (authorname, index) => (
                              <div class="blog-author-inner">
                                <div class="author-inner">
                                  <a href="#">
                                    {`${index ? ", " : ""}${
                                      authorname?.name
                                    }` || "NA"}
                                  </a>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div class="blog-author-inner">
                            <div class="author-inner">
                              <a href="#">{"NA"}</a>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {/* <div class="blog-author-inner">
                       <div class="author-inner">
                         <a href="#">
                           {props.location.state?.authorName || "NA"}
                         </a>
                       </div>
                     </div> */}
                  </div>
                </div>

                {/* <div className="blogpage-date-tag">
                   <time>
                     {articlesDetails?.data?.publish_date != null
                       ? articlesDetails?.data?.publish_date
                       : "NA"}
                   </time>
                 </div> */}

                {/* <OverView2
                   // MainPDF={kmDetails?.MainPDF}
                   banner_image={articlesDetails?.data?.banner_image}
                   // mainTitle={kmDetails?.Title}
                   // body={kmDetails?.DetailedDescription}
                   // category={kmDetails?.category}
                   // MainImage={kmDetails?.MainImage}
                   // GemLinks={kmDetails?.GemLink}
                   // suitable={kmDetails?.SpecialConditions}
                   // wasteSubTheme={kmDetails?.Subthemes}
                 /> */}
                {/* <div className="blog-author flex">
                   <div className="flex">
                     <span class="by">By:</span>
                     <div class="blog-author-inner">
                       <div class="author-inner">
                         <a href="#">
                           {props.location.state?.authorName || "NA"}
                         </a>
                       </div>
                     </div>
                   </div>
                 </div> */}

                <div class="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <p
                    class="text-align-justify articlesparajustified"
                    style={{ textAlign: "center" }}
                  >
                    {ReactHtmlParser(articlesDetails?.data?.body)}
                  </p>

                  {/* <p class="text-align-justify">
                     This has been co-authored by&nbsp;
                     <em>Mishika Nayyar and Bhamini Rathore.&nbsp;</em>
                   </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("redux states articles detail", state);
  return {
    currentInterventionList: state.ArticlesReducer.currentInterventionList.data,
    interventionTechnology: state.ArticlesReducer.interventionTechnology.data,
    filterData: state.ArticlesReducer.filterData,
    articlesDetails: state.ArticlesReducer.articlesDetails,
  };
};

export default connect(mapStateToProps, {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
  getArticlesDetailsType,
})(withRouter(ArticlesBlogpage));
