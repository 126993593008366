import { AnalyticsDashboardService } from './service';
import {
    ANALYTICS_DATA
} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';


export function getAnalytics() {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let analyticsData = await AnalyticsDashboardService.analyticsData();
        dispatch({
            type: ANALYTICS_DATA,
            payload: analyticsData
        })
        dispatch(isLoading(false))
    }
}