import ReactHtmlParser from "react-html-parser";
import { useEffect } from "react";
import dummyImg from "../../../../../assests/images/dummy_img.png";
import treewithline from "../../../../../assests/images/Event Graphic 02.svg";
import tweetsPlace from "../../../../../assests/images/live_tweets_place.jpg";
import "./style.scss";
// import { TwitterTimelineEmbed } from "react-twitter-embed";

const AboutEvent = (props) => {
  const { data, title } = props;
  console.log("agenda data", data);

  return (
    <div
      className="AboutEvent"
      style={{ paddingTop: "0px", paddingBottom: "0px" }}
    >
      <div className="container">
        <div className="container-wrap">
          {/* <h1
            className="section-heading-about-event"
            style={{
              fontWeight: "bold",
              marginBottom: "15px",
              //   marginTop: "50px",
              fontSize: "30px !important",
              fontFamily: "Chivo, sans-serif",
            }}
          >
            Agenda
          </h1> */}
          <div className="row">
            {/* <div className="col-sm-6 main-image">
              <div className="ticker-image yellow-ticker"></div>
              <img src={data?.image} />
            </div> */}
            <div className="col-sm-6 main-image-content">
              <div className="detail-list">
                {/* <h1>{ReactHtmlParser(data?.why_to_attend?.heading)}</h1> */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "15px",
                    fontSize: "30px",
                    fontFamily: "Chivo, sans-serif",
                    color: "black",
                  }}
                >
                  {"Event Agenda"}
                </p>
                <div className="agendamobileview">
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      height: "fit-content",
                      justifyContent: "left",
                      paddingBottom: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {/* Event Title */}
                    {data?.agenda_title}
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        // alignItems: "center",
                        height: "fit-content",
                        // justifyContent: "left",
                        textAlign: "justify",
                      }}
                    >
                      {/* The World Economic Forum engages the foremost
                          political, business, cultural, and other society
                          leaders to shape global, regional, and industry
                          agendas.With the background of India's 75 years of
                          Independence and 'Azadi ka Amrit Mohatsav', DPIIT has
                          taken the initiative to have a consolidated marquee
                          India presence at World Economic Forum. */}
                      {ReactHtmlParser(data?.agenda_description)}
                    </div>
                    <div
                      style={{
                        textAlign:
                          data?.agenda_thumbnail != "" ? "left" : "center",
                      }}
                    >
                      <a
                        className="green-btn downloadagendabtn"
                        style={{
                          marginTop: "10px",
                          fontSize: "24px",
                          background: "#183b63",
                        }}
                        // download
                        href={data?.agenda_pdf_Link[0]?.url}
                        target="_blank"
                      >
                        Download Agenda
                      </a>
                    </div>
                  </div>
                </div>
                <table
                  className="table  agendadesktopview"
                  style={{ border: "none" }}
                >
                  <thead>
                    <tr></tr>
                  </thead>
                  {console.log("data", data)}
                  <tbody>
                    <tr>
                      {data?.agenda_thumbnail != "" ? (
                        <td
                          style={{
                            // flexDirection: "row",
                            display: "flex",
                            justifyContent: "center",
                            height: "18.5em",
                            width: "28em",
                            border: "none",
                            paddingLeft: "0px",
                          }}
                        >
                          <div
                            className="newuserTooltip"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxWidth: "none",
                              objectFit: "contain",
                            }}
                          >
                            <img
                              src={`https://www.wastetowealth.gov.in${data?.agenda_thumbnail}`}
                              className="sidebar-dashboard-icon2"
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      <td
                        style={{
                          minWidth:
                            data?.agenda_thumbnail != "" ? "36rem" : "68.2rem",
                          paddingLeft: "0px",
                          // paddingRight: "0px",
                          border: "none",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            // alignItems: "center",
                            height: "fit-content",
                            justifyContent: "left",
                            paddingBottom: "10px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {/* Event Title */}
                          {data?.agenda_title}
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              // alignItems: "center",
                              height: "fit-content",
                              // justifyContent: "left",
                              textAlign: "justify",
                            }}
                          >
                            {/* The World Economic Forum engages the foremost
                          political, business, cultural, and other society
                          leaders to shape global, regional, and industry
                          agendas.With the background of India's 75 years of
                          Independence and 'Azadi ka Amrit Mohatsav', DPIIT has
                          taken the initiative to have a consolidated marquee
                          India presence at World Economic Forum. */}
                            {ReactHtmlParser(data?.agenda_description)}
                          </div>
                          <div
                            style={{
                              textAlign:
                                data?.agenda_thumbnail != ""
                                  ? "left"
                                  : "center",
                            }}
                          >
                            <a
                              className="green-btn downloadagendabtn"
                              style={{
                                marginTop: "10px",
                                fontSize: "24px",
                                background: "#183b63",
                              }}
                              // download
                              href={data?.agenda_pdf_Link[0]?.url}
                              target="_blank"
                            >
                              Download Agenda
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={treewithline} />
      </div>
    </div>
  );
};

export default AboutEvent;
