import { combineReducers } from 'redux';
import {
    CONTACT_US_DATA
} from '../../../constants/actionConstants';

const contactusData = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_US_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}

const ContactusReducer = combineReducers({
    contactusData
});

export default ContactusReducer;


