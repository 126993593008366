export const LanguageType = [
    {
        value: 'en',
        text: "English"
    },
    {
        value: 'hi',
        text: "Hindi"
    }
]


export const LanguageTypes =
{
    en: [
        {
            value: 'en',
            text: "English"
        },
        {
            value: 'hi',
            text: "Hindi"
        }
    ],
    hi: [
        {
            value: 'en',
            text: "अंग्रेज़ी"
        },
        {
            value: 'hi',
            text: "हिन्दी"
        }
    ],

}





