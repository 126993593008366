/**
 * Details page of challenge
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getChallengeEventDetails } from "../dependencies/action";
import { useEffect } from "react";
import Overview from "../dependencies/challengescomponent/Overview";
import Confidentiality from "../dependencies/challengescomponent/Confidentiality";
import EligibilityCriteria from "../dependencies/challengescomponent/EligibilityCriteria";
import SelectionCriteria from "../dependencies/challengescomponent/SelectionCriteria";
import References from "../dependencies/challengescomponent/References";
import EvaluationProcess from "../dependencies/challengescomponent/EvaluationProcess";
import Disclaimer from "../dependencies/challengescomponent/ChallengeDisclaimer";
import Breadcrumb from "../../common/Breadcrumbs";
import "./style.scss";

import { initGA, logPageView } from "../../../dependencies/utils/analytics";
const items = [
  { to: "/", label: "/" },
  { to: "", label: "What We Do" },
  { to: "/challenge-listing", label: "All Challenges", active: true },
];
// const items = [
//     { to: "/", label: "/" },
//     { to: "/past-projects", label: "What We Do" },
//     { to: "/past-projects", label: "Our Sentinels" },
//     {
//       to: "/past-projects",
//       label: "Past Projects",
//       active: true,
//     },
//   ];

const eligibilityCriteriaContent = [
  {
    id: 1,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 2,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 3,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
];

const selectionCriteriaContent = [
  {
    id: 1,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 2,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 3,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 4,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 5,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 6,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
];

const evaProcess = [
  {
    id: 1,
    img: "https://uat.wastetowealth.gov.in/s3fs/s3fs-public/2022-01/task.png",
    title: "Lorem ipsum dolor sit",
    body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime",
  },
  {
    id: 2,
    img: "https://uat.wastetowealth.gov.in/s3fs/s3fs-public/2022-01/task.png",
    title: "Lorem ipsum dolor sit",
    body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime",
  },
  {
    id: 3,
    img: "https://uat.wastetowealth.gov.in/s3fs/s3fs-public/2022-01/task.png",
    title: "Lorem ipsum dolor sit",
    body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime",
  },
  {
    id: 4,
    img: "https://uat.wastetowealth.gov.in/s3fs/s3fs-public/2022-01/task.png",
    title: "Lorem ipsum dolor sit",
    body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime",
  },
];

const referencesContent = [
  {
    id: 1,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, https://www.google.com",
  },
  {
    id: 2,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 3,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 4,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
  {
    id: 5,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, https://www.google.com",
  },
  {
    id: 6,
    title:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandaeconsequuntur",
  },
];

const ChallengeEventDetails = (props) => {
  const { getChallengeEventDetails, challengeEventDetails } = props;
  useEffect(() => {
    let str = window.location.pathname;
    getChallengeEventDetails(str.split("/")[2]);
  }, [getChallengeEventDetails]);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  return (
    <div className="challenge_event_details">
      <Breadcrumb
        data={items}
        applyNow={challengeEventDetails?.applynow}
        displayName={challengeEventDetails?.title}
        // displayName={props.location.state.title}
        type={"challenge"}
      />
      <Overview data={challengeEventDetails} />
      {challengeEventDetails?.sections?.eligibility_criteria &&
        challengeEventDetails?.sections?.eligibility_criteria?.entity &&
        challengeEventDetails?.sections?.eligibility_criteria?.entity.length >
          0 && (
          <EligibilityCriteria
            data={challengeEventDetails?.sections?.eligibility_criteria.entity}
          />
        )}
      {/* <EligibilityCriteria
        // data={challengeEventDetails?.sections?.eligibility_criteria}
        data={eligibilityCriteriaContent}
      /> */}

      {challengeEventDetails?.sections?.selection_criteria &&
        challengeEventDetails?.sections?.selection_criteria?.entity &&
        challengeEventDetails?.sections?.selection_criteria?.entity?.length >
          0 && (
          <SelectionCriteria
            data={challengeEventDetails?.sections?.selection_criteria.entity}
          />
        )}
      {/* <SelectionCriteria
        // data={challengeEventDetails?.sections?.selection_criteria}
        data={selectionCriteriaContent}
      /> */}

      {challengeEventDetails?.sections?.evaluation_process &&
        challengeEventDetails?.sections?.evaluation_process?.entity &&
        challengeEventDetails?.sections?.evaluation_process?.entity.length >
          0 && (
          <EvaluationProcess
            data={challengeEventDetails?.sections?.evaluation_process?.entity}
          />
        )}
      {challengeEventDetails?.sections?.confidentiality &&
        challengeEventDetails?.sections?.confidentiality?.entity &&
        challengeEventDetails?.sections?.confidentiality?.entity.length > 0 && (
          // <EvaluationProcess
          //   data={challengeEventDetails?.sections?.confidentiality?.entity}
          // />
          <Confidentiality
            data={challengeEventDetails?.sections?.confidentiality?.entity}
          />
        )}
      {/* <EvaluationProcess
        // data={challengeEventDetails?.sections?.evaluation_process}
        data={evaProcess}
      /> */}
      {/* <Confidentiality
        style={{ paddingTop: "0px" }}
        data={challengeEventDetails}
      /> */}
      {challengeEventDetails?.sections?.references &&
        challengeEventDetails?.sections?.references?.entity &&
        challengeEventDetails?.sections?.references?.entity.length > 0 && (
          <References
            // data={challengeEventDetails?.sections?.selection_criteria}
            data={challengeEventDetails?.sections?.references?.entity}
            sectionClass="awards-section"
          />
        )}

      {challengeEventDetails?.disclaimer && (
        <Disclaimer data={challengeEventDetails?.disclaimer} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    challengeEventDetails:
      state.ChallengeEventReducer.challengeEventDetails.data,
  };
};
export default connect(mapStateToProps, { getChallengeEventDetails })(
  withRouter(ChallengeEventDetails)
);
