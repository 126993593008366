import ReactHtmlParser from "react-html-parser";
import "./style.scss";
const SuitablityType = (props) => {
  const { data } = props
  // const data = [
  //   {
  //     id: "Smallest Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Hilly Regions",
  //     image:
  //       "https://uat.wastetowealth.gov.in/s3fs/2022-03/Agriculture%20Waste%20White.png",
  //   },
  //   {
  //     id: "Smallest Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Arid Regions",
  //     image:
  //       "https://uat.wastetowealth.gov.in/s3fs/2022-03/Agriculture%20Waste%20White.png",
  //   },
  //   {
  //     id: "Smallest Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Riverine",
  //     image:
  //       "https://uat.wastetowealth.gov.in/s3fs/2022-03/Agriculture%20Waste%20White.png",
  //   },
  //   {
  //     id: "Smallest Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Cold Areas",
  //     image:
  //       "https://uat.wastetowealth.gov.in/s3fs/2022-03/Agriculture%20Waste%20White.png",
  //   },
  //   {
  //     id: "Smallest Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Plains",
  //     image:
  //       "https://uat.wastetowealth.gov.in/s3fs/2022-03/Agriculture%20Waste%20White.png",
  //   },
  // ];
  return (
    <div>
          {data?.suitability_for_types_g.length > 0 ?
      <div className="SuitabilityTypeImpact">
        <div className="yellow-line" />
        <div className="blue-line" />
        <div className="green-line" />

        <div className="container">
          <div className="large-tree" />
          <div className="small-tree" />

          <div class="bin-car"></div>
          <div class="white-leaf"></div>
          <h1 className="suitable-section-heading">
            {ReactHtmlParser(
              "Suitable for types of Geographic Terrains and Climate"
            )}
          </h1>
          {/* <div className="row Suitable-image-bg">
                <p>sdfsdfsdf</p>
            </div> */}

          <div>
            <div
              className="row col-sm-12  Suitable-image-bg"
              style={{
                display: "flex",
                justifyContent: data?.suitability_for_types_g.length < 5 ? "flex-start" : "center",
                marginLeft: "1px",
              }}
            >
              {data &&
                Array.isArray(data.suitability_for_types_g) &&
                data?.suitability_for_types_g.length > 0 &&
                data?.suitability_for_types_g.map((value, index) => {
                  return (
                    <div className="row">
                      <div>
                        <div className="suitable-type-image-align-center">
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p className="suitable-type-heading">
                                {value?.label}
                              </p>
                            </div>
                            <img
                              src={value?.image}
                              alt="..."
                              className="suitable_type_image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
     :null}
      <div className="row suitable-type-graphics">
        <div class="recycle-small"></div>
        <div class="recycle two"></div>
      </div>
    </div>
  );
};

export default SuitablityType;
