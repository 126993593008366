import { HomeServices } from "./service";
import {
  CURRENT_INTERVENTION,
  QUOTES,
  FEATURED_TECHNOLOGY,
  IMPORTANT_CHALLENGES,
  BANNER,
  LATEST_NEWS,
  UPCOMMING_EVENTS,
  RUTAG_PAGE,
  RUTAG_PAGE_DETAILS,
} from "../../../constants/actionConstants";
import { isLoading } from "../../../dependencies/action/action";

export const getRutagData = (params) => (dispatch) => {
  dispatch(isLoading(true));
  return getBindData(params).then((data) => {
    const {
      // currentInterventionList,
      // quotes,
      // featuredTechnology,
      // importantChallenges,
      // latestNews,
      // upcommingEvents,
      // banner,
      rutagpage,
    } = data;
    // if (currentInterventionList) {
    //   dispatch({
    //     type: CURRENT_INTERVENTION,
    //     payload: currentInterventionList,
    //   });
    // }
    // if (quotes) {
    //   dispatch({
    //     type: QUOTES,
    //     payload: quotes,
    //   });
    // }
    if (rutagpage) {
      dispatch({
        type: RUTAG_PAGE,
        payload: rutagpage,
      });
    }
    // if (featuredTechnology) {
    //   dispatch({
    //     type: FEATURED_TECHNOLOGY,
    //     payload: featuredTechnology,
    //   });
    // }
    // if (importantChallenges) {
    //   dispatch({
    //     type: IMPORTANT_CHALLENGES,
    //     payload: importantChallenges,
    //   });
    // }
    // if (banner) {
    //   dispatch({
    //     type: BANNER,
    //     payload: banner,
    //   });
    // }
    // if (banner) {
    //   dispatch({
    //     type: LATEST_NEWS,
    //     payload: latestNews,
    //   });
    // }
    // if (banner) {
    //   dispatch({
    //     type: UPCOMMING_EVENTS,
    //     payload: upcommingEvents,
    //   });
    // }

    dispatch(isLoading(false));
  });
};

export const getRutagDetails = (url) => {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let getRutagDetails = await HomeServices.rutagDetails(url);

    dispatch({
      type: RUTAG_PAGE_DETAILS,
      payload: getRutagDetails,
    });
    dispatch(isLoading(false));
  };
};

async function getBindData() {
  // const currentInterventionList = await HomeServices.currentInervention().catch(
  //   (e) => {
  //     return { data: [], status: false };
  //   }
  // );
  // const quotes = await HomeServices.quotes().catch((e) => {
  //   return { data: [], status: false };
  // });
  const rutagpage = await HomeServices.rutagpage().catch((e) => {
    return { data: [], status: false };
  });
  // const featuredTechnology = await HomeServices.featuredTechnology().catch(
  //   (e) => {
  //     return { data: [], status: false };
  //   }
  // );
  // const importantChallenges = await HomeServices.importantChallenges().catch(
  //   (e) => {
  //     return { data: [], status: false };
  //   }
  // );
  // const banner = await HomeServices.banner().catch((e) => {
  //   return { data: [], status: false };
  // });
  // const latestNews = await HomeServices.latestNews().catch((e) => {
  //   return { data: [], status: false };
  // });
  // const upcommingEvents = await HomeServices.upcommingEvents().catch((e) => {
  //   return { data: [], status: false };
  // });

  return {
    // currentInterventionList,
    // quotes,
    rutagpage,
    // featuredTechnology,
    // importantChallenges,
    // latestNews,
    // upcommingEvents,
    // banner,
  };
}
