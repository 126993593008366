import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import "./style.scss";

const MainPage = (props) => {
	const { content: Content, pageClass } = props;
	const location = useLocation();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
    
	return (
		<div className={`main-page ${pageClass}`}>
			<Header />
			<div className="main-content clearfix">
				<Content />
			</div>
			{/* <UpperFooter /> */}
			<Footer />
		</div>
	);
};

export default MainPage;
