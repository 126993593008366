import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import IndiaMap from "./IndiaMap";
import Select from "react-select";
import { useEffect, useState } from "react";

const newMapArray = [
  {
    value: "2022",
    label: "Cohort 2",
  },
  {
    value: "2021",
    label: "Cohort 1",
  },
];

const AboutCohort = (props) => {
  // console.log("AboutCohot",props);
  const { data, description, heading, count, setMapYearSelected } = props;
  const [selectedyearFellows, setSelectedyearFellows] = useState(
    newMapArray[0]
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length < 3 ? data?.length : count,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: false,
  };

  useEffect(() => {
    setMapYearSelected(selectedyearFellows);
    console.log("data length", data?.length);
  }, [selectedyearFellows]);

  const yearArrayStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isSelected ? "#f4f4f4" : null,

        color: "#333333",
      };
    },

    control: (base, state) => ({
      ...base,
      background: "#fff",
      marginRight: "10px",
      height: "42px",
      fontSize: "15px",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#dddddd" : "#dddddd",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#2ba749" : "#dddddd",
      },
    }),
  };

  return (
    <div className="MeatTeam">
      <div className="about-cohort-dotted-bg">
        <div className="container">
          <div className="container-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="about-mentor-title col-lg-6">
                    <span>Fellowship’s Reach</span>
                  </div>
                  <div
                    className="about-mentor-title col-lg-6"
                    style={{ display: "flex", justifyContent: " flex-end" }}
                  >
                    <Select
                      className="fellowcompSelector"
                      placeholder="Cohort"
                      options={newMapArray}
                      styles={yearArrayStyles}
                      value={selectedyearFellows}
                      // value={yearArray[0]}
                      onChange={(e) => {
                        setSelectedyearFellows(e);
                        console.log(e.value);
                      }}
                    />
                  </div>
                </div>

                {/* <div className='partner-sub-heading'>subTitle</div> */}
                <div>
                  <IndiaMap data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCohort;
