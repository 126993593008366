import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import dummyMissionImage from "../../../../../assests/images/dummy_img.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SocialShare from "../../../../common/SocialShare";

const CitizenEngagements = (props) => {
  const { item, count, data, SectionId } = props;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    // slidesToShow:data?.engagement.length > 2 ? 3 : data?.engagement.length ,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [social] = useState({
    url: `${window.location.host}/citizen-corner/${SectionId}`,
    title: data?.heading,
    sepreater: "::",
    size: "2.5rem",
  });
  console.log("data?.engagement", data?.engagement);
  return (
    <div className="citizen-engagements custom-slider-nextPrev">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex quiz-header">
            <h2>{ReactHtmlParser(data?.heading)}</h2>
            <div className="citizen-social-share margin-left-auto">
              <SocialShare social={social} />
            </div>
          </div>
        </div>

        <Slider {...settings}>
          {data?.engagement.map((item, index) => {
            return (
              <div className="row">
                <div className="col">
                  <div className="item hover-zoom">
                    <div className="image">
                      <img src={item?.image} alt="" />
                    </div>
                    <div className="content">
                      {item?.eventType === "External" ? (
                        <a href={item.pagelink} target="_blank">
                          <h5>{ReactHtmlParser(item?.trimTitle)}</h5>
                        </a>
                      ) : (
                        <Link
                          href=""
                          to={`/fellowship-listing${item.pagelink}`}
                        >
                          <h5>{ReactHtmlParser(item?.trimTitle)}</h5>
                        </Link>
                      )}

                      {item.date && <span className="date">{item?.date}</span>}
                      {item.field_location && (
                        <span className="locations-info">
                          {item.field_location}
                        </span>
                      )}
                      {item?.eventType === "External" ? (
                        <a
                          href={item.pagelink}
                          className="link-goto-bottom"
                          target="_blank"
                        />
                      ) : (
                        <Link
                          to={`/fellowship-listing${item.pagelink}`}
                          className="link-goto-bottom"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default CitizenEngagements;
