import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUpperFooterData } from './dependencies/action';
import './style.scss';
import { useEffect } from 'react';
const UpperFooter = (props) => {
    const { getUpperFooterData, data } = props;
    useEffect(() => {
        getUpperFooterData()
    }, [getUpperFooterData])

    return (
        <div>
            <div className='upper-footer-container'>
                <div className='upper-footer-wrap main-container'>
                    {
                        data
                        && Array.isArray(data?.list)
                        && data?.list?.length > 0
                        && data?.list.map((value, index) => { 
                            return <a className="hover-zoom" key={index} href={value?.link} target="_blank">
                                <img src={value?.image} className='upper-footer-images' alt="" />
                            </a>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        data: state.UpperFooter.upperFooterData
    }
}
export default connect(mapStateToProps, { getUpperFooterData })(withRouter(UpperFooter));