import React, { useState, useEffect } from "react";
import "./style.scss";
import Scrollbar from "react-scrollbars-custom";
import sharefb from "../../../../../assests/svg/ic_fb.svg";
import sharetwiter from "../../../../../assests/svg/ic_twitter.svg";
import sharetinsta from "../../../../../assests/svg/ic_insta.svg";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const GallerySection2 = (props) => {
  const getVideoId = (url) => {
    // let videoId = url?.split(".be/")[1];
    // let ampersandPosition = videoId?.indexOf("&");
    // if (ampersandPosition !== -1) {
    // 	videoId = videoId?.substring(0, ampersandPosition);
    // }
    // return videoId;
    return url;
  };

  const { data } = props;
  const [isPlay, setIsPlay] = useState(false);
  const [isVideoGallery, setVideoGallery] = useState(true);
  const [videoTitle, setVideoTitle] = useState(
    data?.gallery_videos?.length !== 0 && data?.gallery_videos[0]?.title
  );
  const [imageTitle, setImageTitle] = useState(
    data?.gallery_images?.length !== 0 && data?.gallery_images[0]?.image_title
  );
  const [thumbImg, setThumbImg] = useState(
    data?.gallery_videos?.length !== 0 && data?.gallery_videos[0]?.thumbnail
  );
  const [imageThumb, setImageThumb] = useState(
    data?.gallery_images?.length !== 0 &&
      `https://uat.wastetowealth.gov.in${data?.gallery_images[0]?.url}`
  );
  const [activeNumber, setActiveNumber] = useState(1);
  const [ytVideoId, setVideoId] = useState(() =>
    getVideoId(
      data?.gallery_videos?.length !== 0 && data?.gallery_videos[0]?.url
    )
  );

  const [social, setSocial] = useState({
    url: data?.gallery_videos?.length !== 0 && data?.gallery_videos[0]?.url,
    title: "Wase To Wealth",
    sepreater: "::",
    size: "2.5rem",
  });

  const [nuVidLink, setNuvidlink] = useState(
    data?.gallery_videos[0]?.url.slice(32)
  );

  useEffect(() => {
    // const nuvidlink = data?.gallery_videos[0]?.url.slice(32);

    // setNuvidlink(nuvidlink);

    // console.log("nuvidlink", nuvidlink);
    // console.log("nuvidlink_slice", nuvidlink.slice(0, 5));

    setSocial({
      ...social,
      url: data?.gallery_videos?.length !== 0 && data?.gallery_videos[0]?.url,
    });
  }, [data?.list?.video[0]?.videoUrl]);

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
  };
  useEffect(() => {
    setImageTitle(
      data?.gallery_images?.length !== 0 && data?.gallery_images[0]?.image_title
    );
    setVideoTitle(
      data?.gallery_videos?.length !== 0 && data?.gallery_videos[0]?.title
    );
    setThumbImg(
      // data?.list?.video[0]?.image
      //   ? data?.list?.video[0]?.image
      //   : data?.list?.image[0]?.image
      data?.gallery_videos?.length !== 0 &&
        `https://uat.wastetowealth.gov.in${data?.gallery_videos[0]?.thumbnail}`
    );
    setImageThumb(
      data?.gallery_images?.length !== 0 &&
        `https://uat.wastetowealth.gov.in${data?.gallery_images[0]?.url}`
    );
    setVideoId(() => getVideoId(data?.gallery_videos[0]?.url));
    setVideoGallery(data?.gallery_videos?.length !== 0 ? true : false);
  }, [data]);
  // ytplayer = document.getElementById("movie_player");
  // ytplayer.getCurrentTime();

  const handleVideoThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setVideoTitle(title);
    setThumbImg(img);
    setIsPlay(false);
  };
  const handleImageThumb = (title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    setImageTitle(title);
    setImageThumb(img);
    setIsPlay(false);
  };
  return (
    <div className="gallery-section currentinterventionlistgallery">
      <div className="container">
        <div className="gallery-head d-flex">
          {data?.gallery_videos?.length !== 0 && (
            <button
              className="gallery-title mr-2"
              onClick={() => setVideoGallery(true)}
            >
              <h2
                className={isVideoGallery ? "active" : ""}
                style={{ color: "black" }}
              >
                Video Gallery
              </h2>
            </button>
          )}
          {data?.gallery_images?.length !== 0 && (
            <button
              className="gallery-title"
              onClick={() => setVideoGallery(false)}
            >
              <h2
                className={!isVideoGallery ? "active" : ""}
                style={{ color: "black" }}
              >
                Image Gallery
              </h2>
            </button>
          )}
          <div className="social-share-wrap">
            <span className="share-text mr-2" style={{ color: "black" }}>
              Share{" "}
            </span>
            <FacebookShareButton
              url={data?.gallery_videos[0]?.url || data?.gallery_images[0]?.url}
              quote={
                data?.gallery_videos[0]?.title || data?.gallery_images[0]?.title
              }
              sepreater={social.sepreater}
            >
              <img src={sharefb} alt="" width="32" />
            </FacebookShareButton>
            <TwitterShareButton
              url={data?.gallery_videos[0]?.url || data?.gallery_images[0]?.url}
              title={
                data?.gallery_videos[0]?.title ||
                data?.gallery_images[0]?.image_title
              }
              sepreater={social.sepreater}
            >
              <img src={sharetwiter} alt="" width="32" />
            </TwitterShareButton>
            <a
              href="https://www.instagram.com/wasteto.wealth/?hl=en"
              target="_blank"
            >
              <img src={sharetinsta} alt="" width="32" />
            </a>
          </div>
        </div>

        {isVideoGallery && data?.gallery_videos?.length !== 0 && (
          <div className="row">
            <div className="col-md-8">
              <div className="yt-wrapper">
                <div
                  className="gallery-count"
                  // style={{ color: "#0d70af" }}
                  style={{ color: "black" }}
                >
                  <span style={{ color: "black" }}>{activeNumber}&nbsp;</span>
                  /&nbsp;
                  {data?.gallery_videos?.length}
                </div>
                <div className="yt-inner">
                  {!isPlay && (
                    <div className="video-cover">
                      <img className="" src={thumbImg} alt={videoTitle} />
                      {/* <h3 className="video-title">{videoTitle}</h3> */}
                    </div>
                  )}
                  {isPlay && (
                    <iframe
                      id="player"
                      width="100%"
                      src={`https://www.youtube.com/embed/${data?.gallery_videos[0]?.url.slice(
                        32
                      )}`}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      rel="0"
                    ></iframe>
                  )}
                  {!isPlay && (
                    <button
                      id="play"
                      className="play-btn"
                      onClick={(e) => {
                        playVideo(e);
                        // setIsPlay(true);
                      }}
                    ></button>
                  )}
                </div>
              </div>
              <h3
                className="video-title"
                style={{ color: "black", marginTop: "10px" }}
              >
                {videoTitle}
              </h3>
            </div>
            <div className="col-md-4">
              {data?.gallery_videos?.length > 3 ? (
                <Scrollbar style={{ height: 444 }}>
                  <div className="video-list">
                    {data?.gallery_videos?.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          style={{ background: "transparent" }}
                          onClick={() =>
                            handleVideoThumb(
                              item.url,
                              item.title,
                              `https://uat.wastetowealth.gov.in${item.thumbnail}`,
                              index + 1
                            )
                          }
                        >
                          {/* <img
                            className="video-cover"
                            src={item.url}
                            alt="Video title"
                          /> */}
                          {/* <div className="video-cover">
                            <h3 className="video-title">{videoTitle}</h3>
                          </div> */}
                          <img
                            className="video-cover"
                            src={`https://uat.wastetowealth.gov.in${item.thumbnail}`}
                            alt="Video title"
                          />
                          <span className="thumb-play" />
                        </div>
                      );
                    })}
                  </div>
                </Scrollbar>
              ) : (
                <div className="video-list no-scroll">
                  {data?.gallery_videos?.map((item, index) => {
                    return (
                      <div
                        className={
                          activeNumber === index + 1
                            ? "video-thumb active"
                            : "video-thumb"
                        }
                        style={{ background: "transparent" }}
                        onClick={() =>
                          handleVideoThumb(
                            item.url,
                            item.title,
                            `https://uat.wastetowealth.gov.in${item.thumbnail}`,
                            index + 1
                          )
                        }
                      >
                        {/* <img
                          className="video-cover"
                          src={item.url}
                          alt="Video title"
                        /> */}
                        {/* <div className="video-cover">
                          <h3 className="video-title">{videoTitle}</h3>
                        </div> */}
                        <img
                          className="video-cover"
                          src={`https://uat.wastetowealth.gov.in${item.thumbnail}`}
                          alt="Video title"
                        />
                        <span className="thumb-play" />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        {!isVideoGallery && data?.gallery_images?.length !== 0 && (
          <div className="row">
            <div className="col-md-8">
              <div className="yt-wrapper">
                <div className="gallery-count" style={{ color: "black" }}>
                  <span style={{ color: "black" }}>{activeNumber}&nbsp;</span>
                  /&nbsp;
                  {data?.gallery_images?.length}
                </div>
                <div className="yt-inner">
                  <div className="video-cover">
                    <img className="" src={imageThumb} alt={imageTitle} />
                    {/* <h3 className="video-title">{imageTitle}</h3> */}
                  </div>
                </div>
                {/* <h3 className="video-title">{imageTitle}</h3> */}
              </div>
              <h3
                className="video-title"
                style={{ color: "black", marginTop: "10px" }}
              >
                {/* {data?.gallery_images[0]?.image_title} */}
                {imageTitle}
              </h3>
            </div>
            <div className="col-md-4">
              {data?.gallery_images?.length > 3 ? (
                <Scrollbar style={{ height: 444 }}>
                  <div className="video-list">
                    {data?.gallery_images?.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          style={{ background: "transparent" }}
                          onClick={() =>
                            handleImageThumb(
                              item.image_title,
                              `https://uat.wastetowealth.gov.in${item.url}`,
                              index + 1
                            )
                          }
                        >
                          {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                          <img
                            className="video-cover"
                            src={`https://uat.wastetowealth.gov.in${item.url}`}
                            alt="Video title"
                          />
                        </div>
                      );
                    })}
                  </div>
                </Scrollbar>
              ) : (
                <div className="video-list no-scroll">
                  {data?.gallery_images?.map((item, index) => {
                    return (
                      <div
                        className={
                          activeNumber === index + 1
                            ? "video-thumb active"
                            : "video-thumb"
                        }
                        style={{ background: "transparent" }}
                        onClick={() =>
                          handleImageThumb(
                            item.image_title,
                            `https://uat.wastetowealth.gov.in${item.url}`,
                            index + 1
                          )
                        }
                      >
                        {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                        <img
                          className="video-cover"
                          src={`https://uat.wastetowealth.gov.in${item.url}`}
                          alt="Video title"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GallerySection2;

// import React from "react";

// function GallerySection2() {
//   return <div>hellow</div>;
// }

// export default GallerySection2;
