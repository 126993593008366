import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import clockIcon from '../../../assests/svg/clockIcon.svg';
import locationIcon from '../../../assests/svg/locationIcon.svg';
import ic_cta from '../../../assests/svg/ic_cta_small.svg';
import './style.scss';
import {Link} from 'react-router-dom';

const LeftSideSlider = (props) => {
    const { data, heading } = props
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };
    return (
        <div className="featuredEvents">
            <div className='featured-event-card-heading'>{ReactHtmlParser(heading)}</div>
            <Slider {...settings}>
                {
                    data
                    && Array.isArray(data)
                    && data.length > 0
                    && data.map((value, index) => {
                        return (
                            <div className='featured-event-card'>
                                <img src={value?.image} className='featured-event-card-image' alt="" />
                                <div className='featured-event-card-text ellipsis-3'>
                                    {value?.trimTitle}
                                </div>
                                <div className='featured-event-card-content'>
                                    <div className='featured-event-card-content-container'>
                                        <img src={clockIcon} alt="" />
                                        <div className='featured-event-card-content-text'>{value?.date}</div>
                                    </div>
                                    <div className='featured-event-card-content-container margin-top-line'>
                                        <img src={locationIcon} alt="" />
                                        <div className='featured-event-card-content-text'>{value?.field_location}</div>
                                    </div>
                                </div>
                                <Link className="link-goto-bottom" to={`event-listing${value?.pagelink}`}> </Link>
                            </div>
                        )
                    })
                }


            </Slider>
           {/* <img src={ic_cta} className='cta_button' />*/}
        </div>
    );
}


LeftSideSlider.propTypes = {
    data: PropTypes.array,
    heading: PropTypes.string
}

LeftSideSlider.defaultProps = {
    data: [],
    heading: ''
}

export default LeftSideSlider;