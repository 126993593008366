import React, { useState, useLayoutEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import Slider from "react-slick";
import dummyMissionImage from "../../../../../assests/images/dummy_img.png";
import IECModal from "../IECModal";
import SocialShare from "../../../../common/SocialShare";
import { downloadFile } from "../../../../../dependencies/utils/download";
import { Parallax } from "react-scroll-parallax";
import { useController } from "react-scroll-parallax";

const IECMaterial = (props) => {
  const { parallaxController } = useController();
  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);
  const { item, count, data, SectionId } = props;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: true,
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let modal = ``;
  const [showModal, setShowModal] = useState(false);
  const [modalItem, setModalItem] = useState({});

  const dowloadPdf = (url) => {
    let array = url.split("/");
    downloadFile(url, array[4]);
  };

  const handleReadMore = (item) => {
    setModalItem(item);
    setShowModal(true);
    // modal = item ? `<IECModal title={${item.Title}} body={${item.body}} steps="item.steps" isOpen={showModal}
    //             onClose={() => {
    //                 setShowModal(false)
    //             }} />` : ""
  };
  const [social] = useState({
    url: `${window.location.host}/citizen-corner/${SectionId}`,
    title: data?.heading,
    sepreater: "::",
    size: "2.5rem",
  });
  return (
    <div className="IEC-material custom-slider-nextPrev">
      <Parallax className="large-tree" x={["-40px", "40px"]} tagOuter="div">
        <div className="large-tree-img" />
      </Parallax>
      <Parallax className="small-tree" x={["-40px", "40px"]} tagOuter="div">
        <div className="small-tree-img" />
      </Parallax>

      <div className="container">
        <div className="row">
          <div className="col-12 d-flex quiz-header">
            <h2>{ReactHtmlParser(data?.heading)}</h2>
            <div className="citizen-social-share margin-left-auto">
              <SocialShare social={social} />
            </div>
          </div>
        </div>

        <Slider {...settings}>
          {data?.iec_material.map((item, index) => {
            return (
              <div className="row">
                <div className="col">
                  <div className="item hover-zoom">
                    <div className="image">
                      <img src={item.Image} alt="" />
                    </div>

                    <h5>{item.Title}</h5>
                    <div className="action">
                      {/* <button onClick={()=>{
                                                    dowloadPdf(item?.PDF)
                                                }} className="download-btn"></button> */}
                      <a
                        href={item?.PDF}
                        target="_blank"
                        className="download-btn"
                      ></a>
                      <button
                        className="green-btn"
                        onClick={() => handleReadMore(item)}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <IECModal
          item={modalItem}
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default IECMaterial;
