import { policiyService } from './service';
import { isLoading } from '../../../dependencies/action/action';
import {
    POLICY_TYPE,
    POLICY_LIST,
    POLICY_TECHNOLOGY
} from '../../../constants/actionConstants';
import { toast } from "react-toastify";


export const getPolicyType = () => {
    return async (dispatch) => {
        let policyData = await policiyService.getPolicyType();
        dispatch({
            type: POLICY_TYPE,
            payload: policyData
        })
    }
} 

export const getPolicyList = (subtype, type, category) => {
    if(subtype=="guideline"){
        type='118'
        subtype=''
    }else{
        type='117'
    }
    return async (dispatch) => {
        dispatch(isLoading(true))
        let policyListData = await policiyService.getPolicyListing(subtype, type, category);
        dispatch({
            type: POLICY_LIST,
            payload: policyListData
        })
        dispatch(isLoading(false))
    }
} 

export const getPolicyTechnology = () => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        let policyTechnologyData = await policiyService.getPolicyTechnology();
        dispatch({
            type: POLICY_TECHNOLOGY,
            payload: policyTechnologyData
        })
        dispatch(isLoading(false))
    }
} 
