import { useEffect, Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getHeaderData, getSearchData } from "./dependencies/action";
import contactIcon from "../../../assests/icons/icon_call.png";
import lockIcon from "../../../assests/icons/icon_lock.png";
import arrowDownGreen from "../../../assests/icons/arrow_down_green.png";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.css";

import logo from "../../../assests/images/logo.png";
import logo2x from "../../../assests/images/logo@2x.png";
import logo3x from "../../../assests/images/logo@3x.png";
import logoPsa from "../../../assests/images/logoPsa.svg";
// import logoPsa2x from '../../../assests/images/logoPsa@2x.png'
// import logoPsa3x from '../../../assests/images/logoPsa@3x.png'
//import mainLogoSvg from "../../../assests/images/main-logo.svg";
import mainLogoSvg from "../../../assests/images/waste-to-wealth-logo.png";
// function useOutsideAlerter(ref) {
//     useEffect(() => {
//       /**
//        * Alert if clicked on outside of element
//        */
//       function handleClickOutside(event) {
//         if (ref.current && !ref.current.contains(event.target)) {
//           alert("You clicked outside of me!");
//         }
//       }
//       // Bind the event listener
//       document.addEventListener("mousedown", handleClickOutside);
//       return () => {
//         // Unbind the event listener on clean up
//         document.removeEventListener("mousedown", handleClickOutside);
//       };
//     }, [ref]);
//   }

const Header = (props) => {
  const { getHeaderData, headerData, getSearchData, searchData } = props;
  const [text, setText] = useState("");
  const [activeSubmenu, setActiveSubmenu] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [searchClass, setSearchClass] = useState("");
  const wrapperRef = useRef(null);
  const [currentPath, setCurrentPath] = useState("");
  // useOutsideAlerter(wrapperRef);

  useEffect(() => {
    console.log("headerData", headerData);
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSearchClass("");
      }
    }
    setCurrentPath(window.location.pathname);
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    getHeaderData();
    getSearchData();
  }, []);
  const showSubmenu = (e, menuId) => {
    e.stopPropagation();
    if (activeSubmenu == menuId) setActiveSubmenu("");
    else setActiveSubmenu(menuId);
  };

  const onTextChange = (e) => {
    // const { items } = this.state;
    let suggestions = [];
    const value = e.target.value;
    if (value.length > 0) {
      // const regex = new RegExp(`^${value}`, `i`);

      // suggestions =
      //     searchData &&
      //     Array.isArray(searchData) &&
      //     searchData.length > 0 &&
      //     searchData.filter((v) => regex.test(v?.name));
      // console.log("searchData", searchData)
      if (searchData && Array.isArray(searchData)) {
        for (let n in searchData) {
          if (
            searchData[n].name
              .toLowerCase()
              .replace(/[^A-Z0-9]+/gi, "")
              .includes(value.toLowerCase().replace(/[^A-Z0-9]+/gi, ""))
          )
            suggestions.push(searchData[n]);
        }
      }
    }
    setSuggestions(suggestions);
    setText(value);
  };

  const addSearch = () => {
    if (searchClass == "open-search") {
      setSearchClass("");
    } else {
      setSearchClass("open-search");
      document.querySelector(`.search-input`).focus();
    }
  };
  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="search-suggestion-list">
        {suggestions.map((e) => (
          <li>
            <Link to={e.url}>
              <span className="personName">{e.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <header className="header">
      <div className="container-fluid header-container">
        <div className="row no-gutters deskheaderw2w">
          <div className="col-6 col-sm-5 noPaddingRight header-logo-wrap new-logo-logoPsa-wrap">
            <a
              className="navbar-brand navbar-brand-custom logoPsa"
              href="https://www.psa.gov.in/"
              target="_blank"
            >
              <img alt="Brand" src={logoPsa} />
            </a>
            <Link className="navbar-brand navbar-brand-custom logo" to="/">
              <img alt="Brand" src={mainLogoSvg} />
            </Link>
          </div>
          <div className="col-6 col-sm-7 noPaddingLeft header-nav-bar-wrap">
            <div className="user-actions button-container-header">
              <div
                className={`TypeAheadDropDown ${searchClass}`}
                ref={wrapperRef}
              >
                <span
                  className="search-icon"
                  onClick={() => {
                    addSearch();
                  }}
                ></span>
                <input
                  autoComplete="off"
                  name="search"
                  autoFocus
                  onChange={onTextChange}
                  placeholder={"search"}
                  type="text"
                  className="search-input"
                  value={text}
                />
                {renderSuggestions()}
              </div>
            </div>
            <nav className="navbar navbar-expand-lg">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  {headerData?.headerMenu?.length > 0 &&
                    headerData?.headerMenu.map((data, indexValue) => {
                      return (
                        <Fragment key={indexValue}>
                          {data?.childrens?.length > 0 ? (
                            <li
                              className={`nav-item dropdown ${data?.childrens.map(
                                (subMenu, ind) => {
                                  return subMenu?.linkUrl == currentPath
                                    ? " active "
                                    : "";
                                }
                              )} ${data?.childrens.map(
                                (subMenu, ind) =>
                                  subMenu?.childrens &&
                                  subMenu?.childrens.map((subChildd, ind) => {
                                    return subChildd?.linkUrl == currentPath
                                      ? " active "
                                      : "subchild";
                                  })
                              )}`}
                            >
                              <Link
                                className="nav-link dropdown-toggle"
                                // to={data?.linkUrl}
                                to={data?.linkUrl}
                                // id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {data?.linkName}
                              </Link>
                              <div className="dropdown-menu">
                                <ul
                                  className="inner level-one"
                                  // aria-labelledby="navbarDropdown"
                                >
                                  {data?.childrens.map((submenue, index) => {
                                    return (
                                      <Fragment key={index}>
                                        {submenue?.childrens?.length > 0 ? (
                                          <li className="nav-item dropdown">
                                            <Link
                                              onClick={(e) => {
                                                if (
                                                  submenue?.linkName !==
                                                  "About Us"
                                                ) {
                                                  showSubmenu(
                                                    e,
                                                    `${indexValue}-${index}`
                                                  );
                                                }

                                                if (!submenue?.linkUrl) {
                                                  e.preventDefault();
                                                }
                                              }}
                                              className={`nav-link dropdown-toggle ${
                                                activeSubmenu ==
                                                `${indexValue}-${index}`
                                                  ? ``
                                                  : `active`
                                              }`}
                                              //   to={submenue?.linkUrl}
                                              to={{
                                                pathname: `${submenue?.linkUrl}`,
                                                // pathname: `#`,
                                                state: {
                                                  techName:
                                                    submenue?.linkName ===
                                                    "About Us"
                                                      ? "hello2"
                                                      : null,
                                                },
                                              }}
                                            >
                                              {submenue?.linkName}
                                            </Link>
                                            <ul
                                              className={`level-two ${
                                                activeSubmenu ==
                                                `${indexValue}-${index}`
                                                  ? ``
                                                  : `active`
                                              }`}
                                              data-ind={`${indexValue}-${index}`}
                                            >
                                              {submenue?.childrens.map(
                                                (submenue, key) => {
                                                  return (
                                                    <Fragment key={key}>
                                                      {
                                                        <li>
                                                          <Link
                                                            className="dropdown-item"
                                                            // style={{
                                                            //   background:
                                                            //     submenue?.linkName ===
                                                            //     "Past Projects"
                                                            //       ? "none"
                                                            //       : "auto",
                                                            // }}

                                                            to={{
                                                              pathname: `${submenue?.linkUrl}`,
                                                              state: {
                                                                techName:
                                                                  submenue?.linkName ===
                                                                  "Meet the Team"
                                                                    ? "hello"
                                                                    : null,
                                                              },
                                                            }}
                                                            // params={{
                                                            //   testvalue:
                                                            //     "hello",
                                                            // }}
                                                            onClick={() => {
                                                              console.log(
                                                                "clicked on meet the team"
                                                              );
                                                            }}
                                                          >
                                                            {/* {submenue?.linkName ===
                                                            "Past Projects"
                                                              ? ""
                                                              : submenue?.linkName} */}
                                                            {submenue?.linkName}
                                                          </Link>
                                                        </li>
                                                      }
                                                    </Fragment>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </li>
                                        ) : (
                                          <li>
                                            <Link
                                              className="dropdown-item new"
                                              to={submenue?.linkUrl}
                                              // style={{
                                              //   display:
                                              //     submenue?.linkName ===
                                              //       "Challenges" && "none",
                                              // }}
                                            >
                                              {submenue?.linkName}
                                            </Link>
                                          </li>
                                        )}
                                      </Fragment>
                                    );
                                  })}
                                </ul>
                              </div>
                            </li>
                          ) : (
                            <li
                              className={`"nav-item ${
                                data?.linkUrl === currentPath && "active"
                              }`}
                            >
                              <Link className="nav-link" to={data?.linkUrl}>
                                {data?.linkName}
                                <span className="sr-only">(current)</span>
                              </Link>
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                  <li
                    className={`${
                      currentPath == "/contact-us" ? "active" : ""
                    }`}
                  >
                    <Link to="/contact-us" className={`nav-link`}>
                      {headerData?.mainMenu?.length > 0 &&
                        headerData?.mainMenu[0]?.linkName}
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    headerData: state.HeaderReducer.headerData,
    searchData: state.HeaderReducer.searchData,
  };
};
export default connect(mapStateToProps, { getHeaderData, getSearchData })(
  withRouter(Header)
);
