import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const SimilarIntervention = (props) => {
  const { data } = props;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: true,
  };
  return (
    <div className="SimilarIntervention">
      {/* <div class="yellow-line"></div><div class="blue-line"></div><div class="green-line"></div> */}

      <div className="container">
        <div class="recycle-small"></div>
        <div class="recycle two"></div>
        <h1 className="deployedTechnologies-heading similar-heading">
          <span>Similar Projects</span>
        </h1>
        <Slider {...settings}>
          {data &&
            Array.isArray(data?.list) &&
            data?.list &&
            data?.list.map((value, index) => {
              return (
                <div className=" col-md-12 col-12 d-flex ci_card-body">
                  <div className="card ci_card hover-zoom">
                    <div className="card-img-wrap">
                      <button className="ci_card_tag">{value?.category}</button>
                      <img
                        className="card-img-top ci_card_img"
                        src={value?.image}
                        alt="Card cap"
                      />
                    </div>
                    <div className="ci_card_body">
                      <h5 className="card-title ci_card_title">
                        {ReactHtmlParser(value?.title)}
                      </h5>
                      <p className="ci_card_startdate">
                        Start Date:<span>&nbsp;{value?.date}</span>
                      </p>
                      <p className="card-text ci_card_desc ellipsis-5">
                        {ReactHtmlParser(value?.body)}{" "}
                      </p>
                      <Link
                        to={`/current-intervention-listing${value?.link}`}
                        className="link-goto-bottom"
                      ></Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default SimilarIntervention;
