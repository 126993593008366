import { isEmpty } from 'lodash'

export function validateUploadFile(file) {
    if (isEmpty(file) || !file.fileurl) {
        return { status: false, error: "Please select file", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateName(namevalue) {
    let nameRegex = /^(?![\s.]+$)[a-zA-Z\s. ]*$/;
    var name = namevalue.trim();
    // alert(name.length)
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter your name", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid name", hasError: true };
    } else if (name.length < 2) {
        return { status: false, error: "Please provide valid name", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateFirstName(name) {
    let nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
    // var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter your first name", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid first name", hasError: true };
    } else if (name.length < 2) {
        return { status: false, error: "Please provide valid first name", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateLastName(name) {
    let nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
    // var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter your last name", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid last name", hasError: true };
    } else if (name.length < 2) {
        return { status: false, error: "Please provide valid last name", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateURL(url) {
    let urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    //url = url.trim();

    if (url === "" || url === undefined || url === null) {
        return { status: false, error: "Please enter your url", hasError: true };
    } else if (!urlRegex.test(url)) {
        return { status: false, error: "Please provide valid url", hasError: true };
    } else if (url.length < 2) {
        return { status: false, error: "Please provide valid url", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateOther(value) {
    const text = value && value.trim();
    let numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    if (text === "" || text === undefined || text === null || text.length === 0) {

        return { status: false, error: "This field is required.", hasError: true };
    } else if (!numberRegex.test(text)) {
        return { status: false, error: "Please input valid text.", hasError: true }
    } else if (text.length < 2) {
        return { status: false, error: "Comment should not less than 2 character", hasError: true }
    } else if (text.length > 1000) {
        return { status: false, error: "Comment should not greater than 1000 character.", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

/* To handle email validation */
export function validateEmail(email) {
    let emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
    if (email === "" || email === undefined || email === null) {
        return { status: false, error: "Please enter email address.", hasError: true };
    } else if (!emailRegex.test(email)) {
        return { status: false, error: "Please enter valid email address.", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateSearch(value) {

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please enter value.", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

/* To handle user Name validation*/
export function validateUserName(name) {
    let nameRegex = /^[a-zA-Z]+$/;

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter username", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid username", hasError: true };
    } else if (name.length < 2) {
        return { status: false, error: "Please provide valid username", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}


export function validateGameCarddPin(value) {
    let nameRegex = /^[0-9]{4}$/;
    let name = value.trim();
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter value.", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please enter valid number", hasError: true };

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateCreditCard(value) {
    let nameRegex = /^[0-9]{4}$/;
    let name = value.trim();
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter value.", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please enter valid number", hasError: true };

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateDob(value) {

    var today = new Date();
    // today.setHours(0, 0, 0, 0);


    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please select dob.", hasError: true };
    }
    // if (value >= today) {
    //     //Do something..
    //     alert("Working!");
    // }
    // else if (!pattern.test(value)) {
    //     return { status: false, error: "Please provide valid dob", hasError: true };
    // }
    else {
        return { status: true, error: '', hasError: false };
    }
}


export function validateCreatedDate(value) {

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please select created Date.", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateDate(value) {

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please select Date.", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateFiled(value) {

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please enter value.", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateILandName(value) {

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please select ILand Name.", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateDropDown(value) {

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please select value.", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateContactName(name) {
    let nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;


    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter contact name", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide contact name", hasError: true };
    } else if (name.length < 2) {
        return { status: false, error: "Please provide contact name", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}


export function validateCompanyName(name) {
    let text=name.trim();
    let numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    if (text === "" || text === undefined || text === null) {
        return { status: false, error: "Please enter company name", hasError: true };
    } else if (!numberRegex.test(text)) {
        return { status: false, error: "Please input valid text.", hasError: true }
    } else if (text.length < 2) {
        return { status: false, error: "Please provide company name", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}


export function validateCompanyAddress(name) {
    let numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter company address", hasError: true };
    } else if (!numberRegex.test(name)) {
        return { status: false, error: "Please enter company address.", hasError: true }
    } else if (name.length < 2) {
        return { status: false, error: "Please enter company address", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}



export function validateAffilated(name) {
    let numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter valid text", hasError: true };
    } else if (!numberRegex.test(name)) {
        return { status: false, error: "Please input valid text.", hasError: true }
    } else if (name.length < 2) {
        return { status: false, error: "Please provide valid text", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validatePassword(password) {
    // let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;
    password = password.trim();

    if (password === "" || password === undefined || password === null) {
        return { status: false, error: "Please enter valid password.", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateUserNameLogin(name) {
    var nameRegex = /^[a-zA-Z0-9_]+$/;
    name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter username", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid username", hasError: true };
    } else if (name.length < 2) {
        return { status: false, error: "Please provide valid username", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateMobileNo(mobileNo) {
    var numberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (mobileNo === "") {
        return { status: true, error: "Please enter phone number.", hasError: true }
    }
    if (mobileNo === "" || mobileNo === undefined || mobileNo === null) {
        return { status: false, error: "Please enter phone number.", hasError: true }
    } else if (!numberRegex.test(mobileNo)) {
        return { status: false, error: "Please enter valid phone number.", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}




export function studentName(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    let nameFiledRegex = /^([^0-9]*)$/;
    // let nameFiledRegex = /^[a-zA-Z ]+$/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter name", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (!nameFiledRegex.test(name)) {
        return { status: false, error: "Please enter valid name.", hasError: true }
    } else if (name.length > 100) {
        return { status: false, error: "Name should not be greater than 100 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}



export function className(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter class name", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 50) {
        return { status: false, error: "Class Name should not be greater than 50 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}



export function validateAddress(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter addresss", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 500) {
        return { status: false, error: "Address should not be greater than 500 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateCity(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter city", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 100) {
        return { status: false, error: "City should not be greater than 100 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}


export function validatePincode(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    let numberRegex = /^([0-9]*$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter pincode", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (!numberRegex.test(name)) {
        return { status: false, error: "Should be number only.", hasError: true }
    } else if (name.length > 6) {
        return { status: false, error: "Pin should not be greater than 6 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}


export function validateSchoolName(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter name", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 150) {
        return { status: false, error: "Name should not be greater than 150 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

export function validate150TextArea(value) {
    const name=removeExtraSpace(value)

    let wordsCount = name && name.split(' ').length
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter text", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (wordsCount > 150) {
        return { status: false, error: "should not be greater than 150 words", hasError: true }

    }else {
        return { status: true, error: '', hasError: false };
    }
}


export function validateOrganizeDetailsText(value,radioValue){
    const name=removeExtraSpace(value)
if(radioValue==2){
    let wordsCount = name && name.split(' ').length
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter text", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (wordsCount > 100) {
        return { status: false, error: "should not be greater than 100 words", hasError: true }

    }else {
        return { status: true, error: '', hasError: false };
    }
}else{
    return { status: true, error: '', hasError: false };

}
}


export function validate100TextArea(value) {
    const name=removeExtraSpace(value)

    let wordsCount = name && name.split(' ').length
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter text", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (wordsCount > 100) {
        return { status: false, error: "should not be greater than 100 words", hasError: true }

    } else if (name.length > 700) {
        return { status: false, error: "should not be greater than 700 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validate250TextArea(value) {
    const name=removeExtraSpace(value)

    let wordsCount = name && name.split(' ').length
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter text", hasError: true };
    }
    else if (wordsCount > 250) {
        return { status: false, error: "should not be greater than 250 words", hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validate100TextAreaOtherInfo(value) {
    const name=removeExtraSpace(value);
    let wordsCount = name && name.split(' ').length
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name) {
        if (wordsCount > 100) {
            return { status: false, error: "should not be greater than 100 words", hasError: true }

        } else if (name.length > 700) {
            return { status: false, error: "should not be greater than 700 character", hasError: true }
        } else {
            return { status: true, error: '', hasError: false };
        }
    } else {
        return { status: true, error: '', hasError: false };
    }

}

export function validate200TextArea(value) {
    const name=removeExtraSpace(value);

    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    let wordsCount = name && name.split(' ').length
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter text", hasError: true };
    }
    else if (wordsCount > 200) {
        return { status: false, error: "should not be greater than 200 words", hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateInput(name) {

    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter valid Input", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 200) {
        return { status: false, error: "should not be greater than 200 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}


export function validateNmberOfDetails(name) {

    let nameRegex = /^[0-9]*$/;
    // var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter your number of peers", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please enter only numbers", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }


}
export function adharNumber(name) {

    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    let numberRegex = /^[0-9]*$/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter Aadhaar number", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (!numberRegex.test(name)) {
        return { status: false, error: "Should be number only.", hasError: true }
    } else if (name.length != 12) {
        return { status: false, error: "Aadhaar number should not be less than and greater than 12 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function accountNumber(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    let numberRegex = /^[0-9]*$/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter account number", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (!numberRegex.test(name)) {
        return { status: false, error: "Should be number only.", hasError: true }
    } else if (name.length > 50) {
        return { status: false, error: "Account Number should not be greater than 50 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}


export function bankNameValidation(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter bank name", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 150) {
        return { status: false, error: "Bank name should not be greater than 150 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function branchNameValidaton(name) {

    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter branch name", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 100) {
        return { status: false, error: "Branch name should not be greater than 100 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateTechnologyName(nameValue) {
    let name=nameValue.trim();
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter technology name", hasError: true };
    } else if (name.length > 100) {
        return { status: false, error: "Technology name should not be greater than 100 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }

}

export function validateOtherThematicArea(name, selectedOption) {

    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    console.log("Selected Tehcnology Name=", selectedOption);
    if (selectedOption === "131") {
        if (name === "" || name === undefined || name === null) {
            return { status: false, error: "Please enter thematic area", hasError: true };
        } else if (name.length > 100) {
            return { status: false, error: "Thematic area should not be greater than 100 character", hasError: true }
        } else {
            return { status: true, error: '', hasError: false };
        }
    } else {
        return { status: true, error: '', hasError: false };
    }
}



export function ifcValidation(name) {
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter IFSC Code", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (name.length > 20) {
        return { status: false, error: "IFSC Code Should not be greater than 20 character", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validatePhone(mobileNo) {

    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    // let numberRegex = /^[1-9][0-9]*$/;
    let numberRegex = /^[0-9]*$/;


    if (mobileNo === "" || mobileNo === undefined || mobileNo === null) {
        return { status: false, error: "Please enter phone number.", hasError: true }
    }
    // else if (!nameRegex.test(mobileNo)) {
    //     return { status: false, error: "Should not start or end  with  blank space.", hasError: true }
    // }
    else if (!numberRegex.test(mobileNo)) {
        return { status: false, error: "Should be number only.", hasError: true }
    } else if (mobileNo.length < 7) {
        return { status: false, error: "should not be less than 7 number", hasError: true }
    } else if (mobileNo.length > 15) {
        return { status: false, error: "should not be greater than 15 number", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}


export function validationRadio(name) {
    if (name === "" || name === undefined || name === null || name === false || name === 'false') {
        return { status: false, error: "This is required", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateCurrentDevelopment(name) {
    let nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
    // var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter current Development Details", hasError: true };
    }
    // else if (!nameRegex.test(name)) {
    //     return { status: false, error: "Please provide valid name", hasError: true };
    // }
    else if (name.length < 2) {
        return { status: false, error: "Please provide valid current Development Details", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateNationality(name) {
    let nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
    // var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter your nationality", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid nationality", hasError: true };
    } else if (name.length < 2) {
        return { status: false, error: "Please provide valid nationality", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
   else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }

}
export function validatetypeAndFrequencyOfReplacementTextArea(name, minCount = 0, maxCount = 0,systemReplacement) {
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(systemReplacement==2){
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
   else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
} else {
    return { status: true, error: '', hasError: false };
}

}


export function validateDetailsTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }

}

export function validatethreatsTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
  
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }

}

export function validateopportunitiesTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }

}

export function validatestrengthTechnoSTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateweeknessTechnoTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
//    alert(wordsCount)
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    // alert(name.trim().length)
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateTechDetailsTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validatecurrentDeploymentTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateTechnologyEndoresedTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateTechCertifiedTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateTechpatentedTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateMadeIninidaTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    
     if (name === "" || name === undefined || name === null) {

        
        return { status: false, error: "This field is required", hasError: true };
      
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateTechStepsTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateTechIOTextArea(name, minCount = 0, maxCount = 0) {
    // alert()
    let wordsCount = name && name.trim().split(' ').length
   
    let nameRegex = /^(?!\s)(?![\s\S]*\s$)/;
    if(name.trim().length==0){
        return { status: false, error: "This field is required", hasError: true }; 
    }
    else if (name === "" || name === undefined || name === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (maxCount > 0 && wordsCount >= maxCount) {
        return { status: false, error: `should not be greater than ${maxCount} words`, hasError: true }

    } else if (wordsCount < minCount && wordsCount > 0) {
        return { status: true, error: '', hasError: false };
        // return { status: false, error: `should not be less than ${minCount} words`, hasError: true }

    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateOtp(value) {
    let nameRegex = /^[0-9]{6}$/;
    let name = value.trim();
    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter value.", hasError: true };
    } else if (!nameRegex.test(name)) {
        return { status: false, error: "Please enter valid OTP", hasError: true };

    } else {
        return { status: true, error: '', hasError: false };
    }
}