import React, { useState, useEffect } from "react";
// import "./style.scss";

import "../../Events/dependencies/component/GallerySection2/style.scss";
import Scrollbar from "react-scrollbars-custom";
import sharefb from "../../../assests/svg/ic_fb.svg";
import sharetwiter from "../../../assests/svg/ic_twitter.svg";
import sharetinsta from "../../../assests/svg/ic_insta.svg";
import bottomLine from "../../../assests/images/Event_Graphic_03_updated.svg";
// import VideoPlayer from "react-video-js-player";
import ReactPlayer from "react-player/lazy";
import Select from "react-select";
import "./styles.scss";

import bannervidimage from "../../../assests/images/bannervidimage.jpg";
import { Player } from "video-react";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const newVidArray = [
  {
    value: "2022",
    label: "Cohort 2",
    isdisabled: true,
  },
  {
    value: "2021",
    label: "Cohort 1",
  },
  // {
  //   value: "Meghalaya",
  //   label: "Meghalaya",
  // },
];

const GallerySection3 = (props) => {
  const getVideoId = (url) => {
    return url;
  };

  const { data, firstvid, setVideoYearSelected } = props;
  const [isPlay, setIsPlay] = useState(false);
  const [isSSFGallery, setSSFGallery] = useState(true);
  const [mp4vidnull, setmp4vidnull] = useState(
    data && data[0].url != "" ? false : true
  );
  const [isVideoGallery, setVideoGallery] = useState(false);

  const [videoTitle, setVideoTitle] = useState(
    data && data.length !== 0 && data[0]?.title
  );
  const [imageTitle, setImageTitle] = useState(data && data[0].title);
  const [ssfTitle, setSSFTitle] = useState(data && data[0].title);
  const [ssfImg, setSSFImg] = useState(
    data && data[0].thumbnail != ""
      ? `https://www.wastetowealth.gov.in${data && data[0].thumbnail}`
      : bannervidimage
  );
  const [thumbImg, setThumbImg] = useState(
    `https://www.wastetowealth.gov.in${data ? data[0]?.thumbnail : ""}`
  );
  const [imageThumb, setImageThumb] = useState(
    data && data.length !== 0 && data && data[0].url
      ? data && data[0].url
      : `https://www.wastetowealth.gov.in${data && data[0].upload_video}`
  );
  const [activeNumber, setActiveNumber] = useState(1);

  const [ytVideoId, setVideoId] = useState(() =>
    getVideoId(data && data && data.length !== 0 && data && data[0].url)
  );

  const [social, setSocial] = useState({
    url:
      data?.length !== 0 && data && data[0].url
        ? data && data[0].url
        : `https://www.wastetowealth.gov.in${data && data[0].upload_video}`,

    title: "Wase To Wealth",
    sepreater: "::",
    size: "2.5rem",
  });
  const [newvid, setNewvid] = useState(
    data && data[0].url
      ? data && data[0].url
      : `https://www.wastetowealth.gov.in${data && data[0].upload_video}`
  );

  const [selectedyearVideo, setSelectedyearVideo] = useState(newVidArray[1]);

  useEffect(() => {
    setSocial({
      ...social,
      url:
        data?.length !== 0 && data && data[0].url
          ? data && data[0].url
          : `https://www.wastetowealth.gov.in${data && data[0].upload_video}`,
    });
  }, [data && data[0].url]);

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
  };

  const onPlayerPlay = () => {
    setIsPlay(true);
  };
  useEffect(() => {
    setImageTitle(data && data.length !== 0 && data && data[0].title);
    setVideoTitle(data && data.length !== 0 && data && data[0].title);
    setSSFTitle(data && data?.length !== 0 && data && data[0].title);
    setNewvid(
      data && data[0].url
        ? data[0].url
        : `https://www.wastetowealth.gov.in${data && data[0].upload_video}`
    );
    setSSFImg(
      data && data[0].thumbnail != ""
        ? `https://www.wastetowealth.gov.in${data && data[0].thumbnail}`
        : bannervidimage
    );
    setThumbImg(
      data ? `https://www.wastetowealth.gov.in${data && data[0].thumbnail}` : ""
    );
    setImageThumb(
      data && data.length !== 0 && data && data[0].url
        ? data[0].url
        : `https://www.wastetowealth.gov.in${data && data[0].upload_video}`
    );
    setVideoId(() =>
      getVideoId(
        data && data[0].url
          ? data[0].url
          : `https://www.wastetowealth.gov.in${data && data[0].upload_video}`
      )
    );
    setVideoGallery(data && data.length !== 0 ? true : false);
  }, [data]);

  const handleSSFThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setSSFTitle(title);
    setSSFImg(img);
    setIsPlay(false);
  };

  const handleVideoThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setVideoTitle(title);
    setThumbImg(img);
    setIsPlay(false);
  };
  const handleImageThumb = (title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    setImageTitle(title);
    setImageThumb(img);
    setIsPlay(false);
  };

  useEffect(() => {
    setVideoYearSelected(selectedyearVideo);
    console.log("data length", data?.length);
  }, [selectedyearVideo]);

  const yearArrayStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isSelected ? "#f4f4f4" : null,

        // color: "#333333",
        color: isDisabled ? "lightgrey" : "#333333",
      };
    },

    control: (base, state) => ({
      ...base,
      background: "#fff",
      marginRight: "10px",
      height: "42px",
      fontSize: "15px",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#dddddd" : "#dddddd",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#2ba749" : "#dddddd",
      },
    }),
  };
  return (
    <>
      <div
        className="gallery-section currentinterventionlistgallery2 2nd"
        // style={{ zIndex: 1 }}
        style={{ backgroundImage: "none" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {!data && (
                  <div className="about-mentor-title col-lg-6">
                    <span>Work done by the fellows.</span>
                  </div>
                )}
                {/* <div className="about-mentor-title col-lg-6">
                  <span>Work done by the fellows.</span>
                </div> */}
                <div
                  className="about-mentor-title col-md-12 cohortmentordropdown"
                  style={{ display: "flex", zIndex: 2, justifyContent: "end" }}
                >
                  <Select
                    className="fellowcompSelector"
                    placeholder="Cohort"
                    options={newVidArray}
                    styles={yearArrayStyles}
                    value={selectedyearVideo}
                    // value={yearArray[0]}
                    onChange={(e) => {
                      setSelectedyearVideo(e);
                      console.log(e.value);
                    }}
                    isOptionDisabled={(option) => option.isdisabled}
                  />
                </div>
              </div>

              {/* <div className='partner-sub-heading'>subtitles</div> */}
            </div>
          </div>
          <div className="gallery-head d-flex">
            {data?.length !== 0 && (
              <button
                style={{
                  display: data ? "block" : "none",
                }}
                className="gallery-title mr-2"
                onClick={() => {
                  setVideoGallery(false);
                  setSSFGallery(true);
                }}
              >
                <h2
                  className={isSSFGallery ? "active" : ""}
                  style={{ color: "black" }}
                >
                  {/* SSF Videos */}
                  {/* Meet Our Fellows */}
                  Work done by the fellows
                </h2>
              </button>
            )}

            <div className="social-share-wrap">
              <span className="share-text mr-2" style={{ color: "black" }}>
                Share{" "}
              </span>
              <FacebookShareButton
                url={
                  data && data[0].url
                    ? data && data[0].url
                    : data && data[0].url
                }
                quote={
                  data && data[0].title
                    ? data && data[0].title
                    : data && data[0].title
                }
                sepreater={social.sepreater}
              >
                <img src={sharefb} alt="" width="32" />
              </FacebookShareButton>
              <TwitterShareButton
                url={
                  data && data[0].url
                    ? data && data[0].url
                    : data && data[0].url
                }
                title={
                  data && data[0].title
                    ? data && data[0].title
                    : data && data[0].title
                }
                sepreater={social.sepreater}
              >
                <img src={sharetwiter} alt="" width="32" />
              </TwitterShareButton>
              <a
                href="https://www.instagram.com/wasteto.wealth/?hl=en"
                target="_blank"
              >
                <img src={sharetinsta} alt="" width="32" />
              </a>
            </div>
          </div>

          {isSSFGallery && data && data.length !== 0 && (
            <div className="row">
              <div className="col-md-8">
                <div className="yt-wrapper">
                  <div
                    className="gallery-count"
                    // style={{ color: "#0d70af" }}
                    style={{ color: "black" }}
                  >
                    <span style={{ color: "black" }}>{activeNumber}&nbsp;</span>
                    /&nbsp;
                    {data && data.length}
                  </div>
                  <div className="yt-inner">
                    {!isPlay && (
                      <div
                        className="video-cover"
                        style={{
                          position: "absolute",
                          zIndex: 1,
                        }}
                      >
                        <img
                          className=""
                          src={ssfImg}
                          alt={ssfTitle}
                          style={{
                            height: mp4vidnull === false ? "94%" : "100%",
                            borderRadius: "0px",
                          }}
                        />
                      </div>
                    )}
                    {/* { alert(mp4vidnull)} */}
                    {mp4vidnull === false ? (
                      <div className="reactplayernew">
                        <ReactPlayer
                          onPlay={onPlayerPlay}
                          controls={true}
                          url={newvid}
                        />
                      </div>
                    ) : (
                      <iframe
                        id="player"
                        width="100%"
                        src={newvid}
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                        rel="0"
                      ></iframe>
                    )}

                    {!isPlay && (
                      <button
                        id="play"
                        className="play-btn2"
                        onClick={(e) => {
                          playVideo(e);
                          setIsPlay(true);
                        }}
                      ></button>
                    )}
                  </div>
                </div>
                <h3
                  className="video-title"
                  style={{ color: "black", marginTop: "10px" }}
                >
                  {ssfTitle}
                </h3>
              </div>
              <div className="col-md-4">
                {data?.length > 3 ? (
                  <Scrollbar style={{ height: 444 }}>
                    <div className="video-list">
                      {data?.map((item, index) => {
                        return (
                          <div
                            className={
                              activeNumber === index + 1
                                ? "video-thumb active"
                                : "video-thumb"
                            }
                            style={{ background: "transparent" }}
                            onClick={() => {
                              handleSSFThumb(
                                item?.url
                                  ? item?.url != ""
                                    ? item?.url
                                    : ""
                                  : `https://www.wastetowealth.gov.in${item?.upload_video}`,
                                item.title,
                                `https://www.wastetowealth.gov.in${item.thumbnail}`,
                                // `https://www.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911_1.png`,
                                index + 1
                              );
                              setSSFImg(
                                item.thumbnail != ""
                                  ? `https://www.wastetowealth.gov.in${item.thumbnail}`
                                  : bannervidimage
                              );
                              setNewvid(
                                item?.url
                                  ? item?.url != ""
                                    ? item?.url
                                    : ""
                                  : `https://www.wastetowealth.gov.in${item?.upload_video}`
                              );

                              if (item?.url && item?.url != "") {
                                setmp4vidnull(true);
                              } else if (item?.upload_video != "") {
                                setmp4vidnull(false);
                              } else {
                                setmp4vidnull(true);
                              }
                            }}
                          >
                            <img
                              className="video-cover"
                              src={
                                item.thumbnail != ""
                                  ? `https://www.wastetowealth.gov.in${item.thumbnail}`
                                  : bannervidimage
                              }
                              alt="Video title"
                            />
                            <span className="thumb-play" />
                          </div>
                        );
                      })}
                    </div>
                  </Scrollbar>
                ) : (
                  <div className="video-list no-scroll">
                    {data?.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          style={{ background: "transparent" }}
                          onClick={() => {
                            handleSSFThumb(
                              item?.url
                                ? item?.url != ""
                                  ? item?.url
                                  : ""
                                : `https://www.wastetowealth.gov.in${item?.upload_video}`,
                              item.title,
                              `https://www.wastetowealth.gov.in${item.thumbnail}`,
                              // `https://www.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911_1.png`,
                              index + 1
                            );
                            setSSFImg(
                              item.thumbnail != ""
                                ? `https://www.wastetowealth.gov.in${item.thumbnail}`
                                : bannervidimage
                            );
                            setNewvid(
                              item?.url
                                ? item?.url != ""
                                  ? item?.url
                                  : ""
                                : `https://www.wastetowealth.gov.in${item?.upload_video}`
                            );
                          }}
                        >
                          <img
                            className="video-cover"
                            src={
                              item.thumbnail != ""
                                ? `https://www.wastetowealth.gov.in${item.thumbnail}`
                                : bannervidimage
                            }
                            alt="Video title"
                          />
                          <span className="thumb-play" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}

          {isVideoGallery && !isSSFGallery && data.length !== 0 && (
            <div className="row">
              <div className="col-md-8">
                <div className="yt-wrapper">
                  <div
                    className="gallery-count"
                    // style={{ color: "#0d70af" }}
                    style={{ color: "black" }}
                  >
                    <span style={{ color: "black" }}>{activeNumber}&nbsp;</span>
                    /&nbsp;
                    {data.length}
                  </div>
                  <div className="yt-inner">
                    {!isPlay && (
                      <div className="video-cover">
                        <img className="" src={thumbImg} alt={videoTitle} />
                        {/* <h3 className="video-title">{videoTitle}</h3> */}
                      </div>
                    )}
                    {isPlay && (
                      <iframe
                        id="player"
                        width="100%"
                        src={`${data[0]?.url}`}
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                        rel="0"
                      ></iframe>
                    )}
                    {!isPlay && (
                      <button
                        id="play"
                        className="play-btn"
                        onClick={(e) => {
                          playVideo(e);
                          // setIsPlay(true);
                        }}
                      ></button>
                    )}
                  </div>
                </div>
                <h3
                  className="video-title"
                  style={{ color: "black", marginTop: "10px" }}
                >
                  {videoTitle}
                </h3>
              </div>
              <div className="col-md-4">
                {data.length > 3 ? (
                  <Scrollbar style={{ height: 444 }}>
                    <div className="video-list">
                      {data.map((item, index) => {
                        return (
                          <div
                            className={
                              activeNumber === index + 1
                                ? "video-thumb active"
                                : "video-thumb"
                            }
                            style={{ background: "transparent" }}
                            onClick={() =>
                              handleVideoThumb(
                                item?.url
                                  ? item?.url != ""
                                    ? item?.url
                                    : ""
                                  : `https://www.wastetowealth.gov.in${item?.upload_video}`,
                                item.title,
                                `https://www.wastetowealth.gov.in${item.thumbnail}`,
                                index + 1
                              )
                            }
                          >
                            <img
                              className="video-cover"
                              src={`https://www.wastetowealth.gov.in${item.thumbnail}`}
                              alt="Video title"
                            />
                            <span className="thumb-play" />
                          </div>
                        );
                      })}
                    </div>
                  </Scrollbar>
                ) : (
                  <div className="video-list no-scroll">
                    {data.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          style={{ background: "transparent" }}
                          onClick={() =>
                            handleVideoThumb(
                              item?.url
                                ? item?.url != ""
                                  ? item?.url
                                  : ""
                                : `https://www.wastetowealth.gov.in${item?.upload_video}`,
                              item.title,
                              `https://www.wastetowealth.gov.in${item.thumbnail}`,
                              index + 1
                            )
                          }
                        >
                          <img
                            className="video-cover"
                            src={`https://www.wastetowealth.gov.in${item.thumbnail}`}
                            alt="Video title"
                          />
                          <span className="thumb-play" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}

          {!isVideoGallery && !isSSFGallery && data && data.length !== 0 && (
            <div className="row">
              <div className="col-md-8">
                <div className="yt-wrapper">
                  <div className="gallery-count" style={{ color: "black" }}>
                    <span style={{ color: "black" }}>{activeNumber}&nbsp;</span>
                    /&nbsp;
                    {data && data.length}
                  </div>
                  <div className="yt-inner">
                    <div className="video-cover">
                      <img className="" src={imageThumb} alt={imageTitle} />
                    </div>
                  </div>
                </div>
                <h3
                  className="video-title"
                  style={{ color: "black", marginTop: "10px" }}
                >
                  {imageTitle}
                </h3>
              </div>
              <div className="col-md-4">
                {data && data.length > 3 ? (
                  <Scrollbar style={{ height: 444 }}>
                    <div className="video-list">
                      {data &&
                        data.map((item, index) => {
                          return (
                            <div
                              className={
                                activeNumber === index + 1
                                  ? "video-thumb active"
                                  : "video-thumb"
                              }
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleImageThumb(
                                  item.image_title,
                                  item?.url
                                    ? item?.url
                                    : `https://www.wastetowealth.gov.in${item?.upload_video}`,
                                  index + 1
                                )
                              }
                            >
                              <img
                                className="video-cover"
                                src={
                                  item?.url
                                    ? item?.url
                                    : `https://www.wastetowealth.gov.in${item?.upload_video}`
                                }
                                alt="Video title"
                              />
                            </div>
                          );
                        })}
                    </div>
                  </Scrollbar>
                ) : (
                  <div className="video-list no-scroll">
                    {data &&
                      data.map((item, index) => {
                        return (
                          <div
                            className={
                              activeNumber === index + 1
                                ? "video-thumb active"
                                : "video-thumb"
                            }
                            style={{ background: "transparent" }}
                            onClick={() =>
                              handleImageThumb(
                                item.title,
                                item?.url
                                  ? item?.url
                                  : `https://www.wastetowealth.gov.in${item?.upload_video}`,
                                index + 1
                              )
                            }
                          >
                            <img
                              className="video-cover"
                              src={
                                item?.url
                                  ? item?.url
                                  : `https://www.wastetowealth.gov.in${item.upload_video}`
                              }
                              alt="Video title"
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: "-0.5%" }}>
        <img src={bottomLine} style={{ width: "100%" }} />
      </div>
    </>
  );
};

export default GallerySection3;
