import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import MainPage from "./components/MainPage";
import HomePage from "./components/HomePage";
import AboutUs from "./components/StaticPage/AboutUs";
import SwachhtaSarthiFellowship from "./components/SwachhtaSarthiFellowship/SwachhtaSarthi";
import ContactUs from "./components/ContactUs";
import ChallengeList from "./components/Challenges/list";
import ChallengeDetails from "./components/Challenges/details";
import EventsList from "./components/Events/list";
import EventsDetails from "./components/Events/details";
import CurrentInterventionsList from "./components/CurrentInterventions/list";
import ArticlesCaseStudiesList from "./components/ArticlesCaseStudies/list";
import ArticlesBlogpage from "./components/ArticlesCaseStudies/details";
import Rutagmainpage from "./components/Rutag";
import Rutagdetailspage from "./components/Rutag/details";
import UpcomingInterventions from "./components/UpcomingInterventions/list";
import PastProjects from "./components/PastProjects/list";
import PastInterventionsDetails from "./components/PastProjects/details";
import CurrentInterventionsDetails from "./components/CurrentInterventions/details";
import NotFound from "./components/NotFound";
import SubmitTechnology from "./components/SubmitTechnology";
import FellowshipForm from "./components/FellowshipForm";
import Fellowship from "./components/Fellowship";
import FellowshipListing from "./components/Fellowship/list";
import SuccessStories from "./components/SuccessStories/list";
import CitizenCorner from "./components/CitizenCorner";
import PartnerWithUs from "./components/PartnerWithUs";
import Policies from "./components/Policies";
import ValidatedTechnologiesList from "./components/ValidatedTechnologies/list";
import ValidatedTechnologiesDetails from "./components/ValidatedTechnologies/details";
import SuccessStoriesDetail from "./components/SuccessStories/details";
import Pledge from "./components/Pledge";
import ReadPledge from "./components/Pledge/ReadPledge";
import PledgeForm from "./components/Pledge/PledgeForm";
import CertificatePage from "./components/Pledge/CertificatePage";
import AnalyticsDashboard from "./components/AnalyticsDashboard";
import ScrollToTop from "./scrollToTop";
import ViewAllProjects from "./components/SwachhtaSarthiFellowship/dependencies/component/SSInitiatives/ViewAllProjects";
import ViewAllMentors from "./components/SwachhtaSarthiFellowship/dependencies/component/AboutMentor/ViewAllMentors";
import ViewAllFellows from "./components/SwachhtaSarthiFellowship/dependencies/component/MeetOurFellows/ViewAllFellows";

const AppRoutes = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route exact path="/" component={() => <MainPage content={HomePage} />} />
      <Route
        exact
        path="/about-us"
        component={() => <MainPage content={AboutUs} />}
      />
      <Route
        exact
        path="/submit-technology"
        component={() => <MainPage content={SubmitTechnology} />}
      />
      <Route
        exact
        path="/fellowship-form"
        component={() => <MainPage content={FellowshipForm} />}
      />
      <Route
        exact
        path="/citizen-corner"
        component={() => <MainPage content={CitizenCorner} />}
      />
      <Route
        exact
        path="/policies"
        component={() => <MainPage content={Policies} />}
      />
      <Route
        exact
        path="/validated-technologies-listing"
        component={() => <MainPage content={ValidatedTechnologiesList} />}
      />
      <Route
        exact
        path="/validated-technologies-listing/:url"
        component={() => <MainPage content={ValidatedTechnologiesDetails} />}
      />
      {/* <Route exact path="/validated-technologies-listing/:url" component={() => <MainPage content={ValidatedTechnologiesDetails} />} /> */}
      <Route
        exact
        path="/pledge/pledge-form/:url"
        component={() => <MainPage content={PledgeForm} />}
      />
      <Route
        exact
        path="/partner-with-us"
        component={() => <MainPage content={PartnerWithUs} />}
      />
      <Route
        exact
        path="/contact-us"
        component={() => <MainPage content={ContactUs} />}
      />
      <Route
        exact
        path="/challenge-listing"
        component={() => <MainPage content={ChallengeList} />}
      />
      <Route
        exact
        path="/challenge-listing/:url"
        component={() => <MainPage content={ChallengeDetails} />}
      />
      <Route
        exact
        path="/fellowship"
        component={() => <MainPage content={Fellowship} />}
      />

      {/* <Route exact path="/fellowship-listing" component={() => <MainPage content={FellowshipListing} />}/> */}
      <Route
        exact
        path="/fellowship-home"
        component={() => <MainPage content={SwachhtaSarthiFellowship} />}
      />
      <Route
        exact
        path="/fellowship-list"
        component={() => <MainPage content={FellowshipListing} />}
      />
      <Route
        exact
        path="/view-all-mentor"
        component={() => <MainPage content={ViewAllMentors} />}
      />
      <Route
        exact
        path="/view-all-fellows"
        component={() => <MainPage content={ViewAllFellows} />}
      />
      <Route
        exact
        path="/view-all-project"
        component={() => <MainPage content={ViewAllProjects} />}
      />
      <Route
        exact
        path="/event-listing"
        component={() => <MainPage content={EventsList} />}
      />
      <Route
        exact
        path="/event-listing/:url"
        component={() => <MainPage content={EventsDetails} />}
      />
      <Route
        exact
        path="/success-stories"
        component={() => <MainPage content={SuccessStories} />}
      />
      <Route
        exact
        path="/success-stories/:url"
        component={() => <MainPage content={SuccessStoriesDetail} />}
      />
      <Route
        exact
        path="/current-intervention-listing"
        component={() => <MainPage content={CurrentInterventionsList} />}
      />
      <Route
        exact
        path="/articles-case-studies"
        component={() => <MainPage content={ArticlesCaseStudiesList} />}
      />
      <Route
        exact
        path="/articles-case-studies/:url"
        component={() => <MainPage content={ArticlesBlogpage} />}
      />
      <Route
        exact
        path="/rutag"
        component={() => <MainPage content={Rutagmainpage} />}
      />
      <Route
        exact
        path="/rutag/:url"
        component={() => <MainPage content={Rutagdetailspage} />}
      />
      <Route
        exact
        path="/current-intervention-listing/:url"
        component={() => <MainPage content={CurrentInterventionsDetails} />}
      />
      {/* <Route exact path="/challenge-event-listing/details" component={() => <MainPage content={ChallengeEventDetails} />} /> */}
      <Route
        exact
        path="/fellowship-form"
        component={() => (
          <MainPage content={FellowshipForm} pageClass="fellowship-page" />
        )}
      />
      <Route
        exact
        path="/fellowship-home/:url"
        component={() => <MainPage content={AboutUs} />}
      />
      <Route
        exact
        path="/fellowship-listing/:url"
        component={() => (
          <MainPage content={Fellowship} pageClass="fellowship-page" />
        )}
      />
      {/* <Route exact path="/sb" component={() => <MainPage content={sb} />} /> */}

      <Route
        exact
        path="/pledge/:url"
        component={() => <MainPage content={Pledge} />}
      />
      <Route
        exact
        path="/pledge/read-pledge/:url"
        component={() => <MainPage content={ReadPledge} />}
      />
      <Route
        exact
        path="/upcoming-interventions"
        component={() => <MainPage content={UpcomingInterventions} />}
      />
      <Route
        exact
        path="/past-projects"
        component={() => <MainPage content={PastProjects} />}
      />
      <Route
        exact
        path="/past-projects/:url"
        component={() => <MainPage content={PastInterventionsDetails} />}
      />
      <Route
        exact
        path="/pledge-certificate"
        component={() => <MainPage content={CertificatePage} />}
      />
      <Route
        exact
        path="/upcoming-intervention-listing"
        component={() => <MainPage content={UpcomingInterventions} />}
      />
      <Route
        exact
        path="/analytics-dashboard"
        component={() => <MainPage content={AnalyticsDashboard} />}
      />
      <Route component={() => <MainPage content={NotFound} />} />
      <Redirect from="/home" to="/" />
    </Switch>
  </BrowserRouter>
);
export default AppRoutes;
