import { CurrentInterventionService } from "./service";
import {
  KM_LIST,
  KM_TECNOLOGY,
  KM_DETAILS,
  KM_FILTER_DATA,
  VALIDATION_LINK,
  VALIDATED_TECH_FILTERDATA,
} from "../../../constants/actionConstants";
import { isLoading } from "../../../dependencies/action/action";

export function getChallengeEventList(
  page,
  search,
  thematicArea,
  origin,
  readiness,
  recognized,
  state,
  country
) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let kmList = await CurrentInterventionService.getKmList(
      page,
      search,
      thematicArea,
      origin,
      readiness,
      recognized,
      state,
      country
    );
    dispatch({
      type: KM_LIST,
      payload: kmList,
    });
    dispatch(isLoading(false));
  };
}

export function getTechnologyType() {
  return async function (dispatch) {
    let technologyType = await CurrentInterventionService.getTechnologyType();
    dispatch({
      type: KM_TECNOLOGY,
      payload: technologyType,
    });
  };
}

export function getKmDetails(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let kmDetails = await CurrentInterventionService.getKmDetails(url);

    dispatch({
      type: KM_DETAILS,
      payload: kmDetails,
    });
    dispatch(isLoading(false));
  };
}

export function getFilterData() {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let filterData = await CurrentInterventionService.filterData();
    dispatch({
      type: KM_FILTER_DATA,
      payload: filterData,
    });
    dispatch(isLoading(false));
  };
}

export function getValidationLink() {
  return async function (dispatch) {
    let technologyLink = await CurrentInterventionService.getValidationLink();
    dispatch({
      type: VALIDATION_LINK,
      payload: technologyLink,
    });
  };
}
