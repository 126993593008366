import React, { useState, useEffect } from "react";
import "./style.scss";
import Scrollbar from "react-scrollbars-custom";
import sharefb from "../../../../../assests/svg/ic_fb.svg";
import sharetwiter from "../../../../../assests/svg/ic_twitter.svg";
import sharetinsta from "../../../../../assests/svg/ic_insta.svg";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import Slider from "react-slick";

const GallerySection = (props) => {
  const getVideoId = (url) => {
    // let videoId = url?.split(".be/")[1];
    // let ampersandPosition = videoId?.indexOf("&");
    // if (ampersandPosition !== -1) {
    // 	videoId = videoId?.substring(0, ampersandPosition);
    // }
    // return videoId;
    return url;
  };

  const settings = {
    dots: true,
    loop: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // {
      //   breakpoint: 767,
      //   settings: {
      //     variableWidth: false,
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 400,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  //   adaptiveHeight: false,
  //   autoplay: true,
  //   autoplaySpeed: 2500,
  // };
  const { data } = props;
  console.log(data?.files[0]?.url);
  const [isPlay, setIsPlay] = useState(false);
  const [isVideoGallery, setVideoGallery] = useState(false);
  const [videoTitle, setVideoTitle] = useState(); // useState(data !== undefined && data?.files[0] !== undefined && data?.files[0]?.title);
  const [imageTitle, setImageTitle] = useState(); //useState(data !== undefined && data?.list.image[0] !== undefined && data?.list.image[0]?.title);
  const [thumbImg, setThumbImg] = useState(
    data?.files[0] !== undefined && data?.files[0] && data?.files[0]?.url
  );
  const [imageThumb, setImageThumb] = useState(
    data?.files[0] !== undefined && data?.files[0] && data?.files[0]?.url
  );
  const [activeNumber, setActiveNumber] = useState(1);
  const [ytVideoId, setVideoId] = useState(() =>
    getVideoId(data?.files[0] !== undefined && data?.files[0]?.videoUrl)
  );

  const [social, setSocial] = useState({
    url: data?.files[0] !== undefined && data?.files[0]?.videoUrl,
    title: "Wase To Wealth",
    sepreater: "::",
    size: "2.5rem",
  });

  useEffect(() => {
    setSocial({
      ...social,
      url: data?.files[0] !== undefined && data?.files[0]?.videoUrl,
    });
  }, [data?.files[0]?.videoUrl]);

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
  };
  useEffect(() => {
    // setVideoTitle(data?.files[0]?.title ? data?.files[0]?.title : data?.list.image[0]?.title);
    // setThumbImg(data?.files[0]?.image ? data?.files[0]?.image : data?.list.image[0]?.image);
    // setImageThumb(data?.list.image[0] !== undefined && data?.list?.image[0].image);
    setVideoId(() => getVideoId(data?.files[0]?.videoUrl));
    setVideoGallery(false);
  }, [data]);
  // ytplayer = document.getElementById("movie_player");
  // ytplayer.getCurrentTime();

  const handleVideoThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setVideoTitle(title);
    setThumbImg(img);
    setIsPlay(false);
  };
  const handleImageThumb = (title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    setImageTitle(title);
    setImageThumb(img);
    setIsPlay(false);
  };
  return (
    <div
      className="gallery-section"
      style={{
        paddingBottom: "20px",
      }}
    >
      <div className="container">
        {isVideoGallery && data?.files[0] !== undefined && (
          <div className="column">
            <div className="col-md-12">
              <div className="yt-wrapper">
                <div className="gallery-count" style={{ color: "black" }}>
                  <span>{activeNumber}&nbsp;</span>
                  /&nbsp;
                  {data?.files.length}
                </div>
                <div className="yt-inner">
                  {!isPlay && (
                    <div className="video-cover">
                      <img className="" src={thumbImg} alt={videoTitle} />
                      <h3 className="video-title">{videoTitle}</h3>
                    </div>
                  )}
                  {isPlay && (
                    <iframe
                      id="player"
                      width="100%"
                      src={`${ytVideoId}${isPlay ? "?autoplay=1" : ""}`}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      rel="0"
                    ></iframe>
                  )}
                  {!isPlay && (
                    <button
                      id="play"
                      className="play-btn"
                      onClick={(e) => playVideo(e)}
                    ></button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {data?.list?.video?.length > 3 ? (
                <Scrollbar style={{ height: 444 }}>
                  <div className="video-list">
                    {data?.files.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          onClick={() =>
                            handleVideoThumb(
                              item.videoUrl,
                              item.title,
                              item.image,
                              index + 1
                            )
                          }
                        >
                          {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                          <img
                            className="video-cover"
                            src={item.image}
                            alt="Video title"
                          />
                          <span className="thumb-play" />
                        </div>
                      );
                    })}
                  </div>
                </Scrollbar>
              ) : (
                <div className="video-list no-scroll">
                  {data?.files.map((item, index) => {
                    return (
                      <div
                        className={
                          activeNumber === index + 1
                            ? "video-thumb active"
                            : "video-thumb"
                        }
                        onClick={() =>
                          handleVideoThumb(
                            item.videoUrl,
                            item.title,
                            item.url,
                            index + 1
                          )
                        }
                      >
                        {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                        <img
                          className="video-cover"
                          src={item.url}
                          alt="Video title"
                        />
                        <span className="thumb-play" />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        {!isVideoGallery && data?.files[0] !== undefined && (
          <div className="column">
            <div className="col-md-12 mobileresactivepic">
              <div className="yt-wrapper-gallery">
                <div className="gallery-count" style={{ color: "black" }}>
                  <span style={{ color: "black" }}>{activeNumber}&nbsp;</span>
                  /&nbsp;
                  {data?.files.length}
                </div>
                <div className="yt-inner">
                  <div className="video-cover">
                    <img className="" src={imageThumb} alt={imageTitle} />
                    <h3 className="video-title">{imageTitle}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="img-gallery-detail-row">
                {data?.files.length > 1 ? (
                  <Slider {...settings}>
                    {data?.files.map((value, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          onClick={() =>
                            handleImageThumb(
                              "",
                              value.url,
                              // "https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg",
                              index + 1
                            )
                          }
                        >
                          <div className="img-gallery-detail" key={index}>
                            <div className="img-gallery-margin">
                              <div
                                className="card img-gallery-card"
                                key={index}
                              >
                                <div className="row1 no-gutters img-gallery-card-row hover-zoom">
                                  <img
                                    className="video-cover"
                                    // style={{
                                    //   height: "148px",
                                    //   width: "107%",
                                    //   marginRight: "9px",
                                    // }}
                                    src={value.url}
                                    // src={
                                    //   "https://uat.wastetowealth.gov.in/s3fs/2021-07/Picture1.jpg"
                                    // }
                                    // src={"https://uat.wastetowealth.gov.in/s3fs/2022-03/14thFeb.jpg"}
                                    alt="Video title"
                                  />
                                  <div>
                                    <div className="col-lg-12">
                                      <div className="col-lg-12 img-gallery-card-body">
                                        <h5 className="card-title img-gallery-card-title clock-icon">
                                          {" "}
                                          {value?.date}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                ) : (
                  // 		<Slider {...settings}>
                  // {
                  // 		// <Scrollbar style={{ height: 444 }}>
                  // 			<div className="video-list">
                  // 				{data?.files.map((item, index) => {
                  // 					return (
                  // 						<div
                  // 							className={(activeNumber === (index + 1)) ? 'video-thumb active' : 'video-thumb' }
                  // 							onClick={() =>
                  // 								handleImageThumb(
                  // 									"item.title",
                  // 									// item.url,
                  // 									"https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg",
                  // 									index + 1
                  // 								)
                  // 							}
                  // 						>
                  // 							{/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                  // 							<img
                  // 								className="video-cover"
                  // 								src={"https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg"}
                  // 								alt="Video title"
                  // 							/>
                  // 						</div>
                  // 					);
                  // 				})}
                  // 			</div>
                  // 		// </Scrollbar>
                  // 			}
                  // 			</Slider>

                  <div className="video-list no-scroll">
                    {data?.files.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb col-md-4 active"
                              : "video-thumb col-md-4 "
                          }
                          onClick={() =>
                            handleImageThumb("item.title", item.url, index + 1)
                          }
                        >
                          {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                          <img
                            className="video-cover"
                            src={item.url}
                            alt="Video title"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GallerySection;
