import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const References = (props) => {
  const { data, sectionClass } = props;

  return (
    <div className={`selection-criteria ${sectionClass}`}>
      <div className="container">
        <div className="container-wrap">
          <div className="row">
            <div className="col-sm-3 offset-1 criteria-heading mobile-heading">
              <div className="margin-left-22">
                {/* {ReactHtmlParser(data?.title)} */}
                References
              </div>
            </div>
            <div className="col-sm-8 selection-criteria-plate">
              <div className="row">
                {data &&
                  Array.isArray(data) &&
                  data?.length > 0 &&
                  data?.map((value, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="criteria-tile col-sm-5"
                          style={{ height: "115px" }}
                        >
                          <div className="criteria-number critnum">
                            #{index + 1}
                            {/* <span>Criteria</span> */}
                          </div>
                          <div className="criteria-content">
                            {ReactHtmlParser(
                              value?.description.replace(
                                /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
                                '<a href="$1" target="_blank">$1</a>'
                              )
                            )}
                            <a href={value?.references_url} target="_blank">
                              {value?.references_url}
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="col-sm-3 offset-1 criteria-heading web-heading">
              <div className="margin-left-22">
                {/* {ReactHtmlParser(data?.title)} */}
                References
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
