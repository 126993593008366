import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import teamImage from "../../../../../assests/images/malvika_image.png";
import teamImage2 from "../../../../../assests/images/shailja.jpg";
import Slider from "react-slick";

const ManagementTeam = (props) => {
  const { data, description, heading, count } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length < 3 ? data?.length : count,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: false,
  };
  return (
    <div className="MeatTeam4">
      <div className="container">
        <div className="container-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-mentor-title">
                <span>Management Team</span>
              </div>

              <div className="partner-sub-heading">
                {ReactHtmlParser(description)}
              </div>
            </div>
          </div>
          <div
            className=" management-team-detail-row"
            style={{
              paddingBottom: "30px",
              width: "auto",
              marginLeft: "0%",
              marginRight: "0%",
              fontWeight: "bold",
              fontSize: "22px",
              paddingTop: "25px",
            }}
          >
            {/* <Slider {...settings}>
              {data &&
                Array.isArray(data) &&
                data.length > 0 &&
                data.map((value, index) => {
                  return (
                    <div className="management-team-detail" key={index}>
                      <div className="management-team-image-background">
                        <img
                          src={value?.image}
                          className="management-team-image"
                        />

                       
                      </div>
                      <div className="management-team-name">{value?.name}</div>
                      <div className="management-team-designation">
                        {value?.designation}
                      </div>
                    </div>
                  );
                })}
            </Slider> */}
            {/* <p>Contact Us:-</p> */}
            {/* <p>Email:- wastetowealth.team@investindia.org.in</p> */}
            <p>Email:- wastetowealthmission@investindia.org.in</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementTeam;
