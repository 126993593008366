import {
    studentName,
    className,
    validateDob,
    validateAddress,
    validateDropDown,
    validateCity,
    validatePincode,
    validateEmail,
    validate100TextArea,
    validate200TextArea,
    validate150TextArea,
    validate250TextArea,
    validatePhone,
    validateInput,
    adharNumber,
    accountNumber,
    bankNameValidation,
    ifcValidation,
    branchNameValidaton,
    validationRadio,
    validateSchoolName,
    validate100TextAreaOtherInfo,
    validateNmberOfDetails,
    validateMobileNo,
    validateCompanyName,
    validateOther,
    validateName,
    validateOrganizeDetailsText
} from '../../../dependencies/utils/validation';

const validation = {
    validateSeactionAForm: (params) => {
        const {
            name,
            phone,
            schoolClass,
            dob,
            address,
            state,
            city,
            pincode,
            email,
            schoolName,
            schoolAddress,
            schoolPhone,
            schoolEmail,
            wasteAbundance,
            furtherInitiatives,
            practicability,
            peerDifference,
            expectedOutput,
            mechanism,
            gender,
            mentorName,
            mentorNumber,
            mentorEmail,
            proposedWorkUnderFellowship,
            anyotherReleventInfo,


        } = params;


        let formStatus = {};
        const nameStatus = studentName(name);
        const schoolClassStatus = validateDropDown(schoolClass);
        const dobStatus = validateDob(dob);
        const addressStatus = validateAddress(address);
        const stateStatus = validateDropDown(state);
        const cityStatus = validateCity(city);
        const pincodeStatus = validatePincode(pincode)
        const emailStatus = validateEmail(email);
        const schoolNameStatus = validateSchoolName(schoolName)
        const schoolAddressStatus = validateAddress(schoolAddress)
        const schoolPhoneStatus = validatePhone(schoolPhone)
        const phoneStatus = validatePhone(phone)
        const schoolEmailStatus = validateEmail(schoolEmail)
        const wasteAbundanceStatus = validate100TextArea(wasteAbundance)
            // const activityStatus = validateDropDown(activity);
            // const activityFiledStatus = validateInput(activityFiled)
            // const workDoneDetailStatus = validate100TextArea(workDoneDetail)
            // const numberOfDetailsStatus = validateNmberOfDetails(numberOfDetails)
            // const innovativeSolutionStatus = validate100TextArea(innovativeSolution)
            // const acitivityOutcomeStatus = validate100TextArea(acitivityOutcome)
            // const socialAwarenessStatus = validate100TextArea(socialAwareness)
            // const otherInfoStatus = validate100TextAreaOtherInfo(otherInfo)
        const furtherInitiativesStatus = validate150TextArea(furtherInitiatives)
        const practicabilityStatus = validate100TextArea(practicability)
        const peerDifferenceStatus = validate100TextArea(peerDifference)
            // const otherWasteCategoryStatus = validate100TextArea(otherWasteCategory)

        const expectedOutputStatus = validate200TextArea(expectedOutput)

        const mechanismStatus = validate100TextArea(mechanism)
        const genderStatus = validationRadio(gender)
        const mentorNameStatus = validateName(mentorName)
        const mentorNumberStatus = validatePhone(mentorNumber)
        const mentorEmailStatus = validateEmail(mentorEmail)
        const proposedWorkUnderFellowshipStatus = validate100TextArea(proposedWorkUnderFellowship)
        const anyotherReleventInfoStatus = validate250TextArea(anyotherReleventInfo)
        formStatus = {
            name: nameStatus.error,
            schoolClass: schoolClassStatus.error,
            dob: dobStatus.error,
            address: addressStatus.error,
            state: stateStatus.error,
            city: cityStatus.error,
            pincode: pincodeStatus.error,
            email: emailStatus.error,
            schoolName: schoolNameStatus.error,
            schoolAddress: schoolAddressStatus.error,
            schoolPhone: schoolPhoneStatus.error,
            phone: phoneStatus.error,
            schoolEmail: schoolEmailStatus.error,
            wasteAbundance: wasteAbundanceStatus.error,
            // activity: activityStatus.error,
            // activityFiled: activityFiledStatus.error,
            //  workDoneDetail: workDoneDetailStatus.error,
            // innovativeSolution: innovativeSolutionStatus.error,
            // acitivityOutcome: acitivityOutcomeStatus.error,
            // socialAwareness: socialAwarenessStatus.error,
            // otherInfo: otherInfoStatus.error,
            furtherInitiatives: furtherInitiativesStatus.error,
            practicability: practicabilityStatus.error,
            peerDifference: peerDifferenceStatus.error,
            expectedOutput: expectedOutputStatus.error,

            mechanism: mechanismStatus.error,

            // presentRadio: presentRadioStatus.error,
            // socialRadio: socialRadioStatus.error,
            // engagementCommunity: engagementCommunityStatus.error,
            gender: genderStatus.error,
            // otherWasteCategory: otherWasteCategoryStatus.error,
            mentorName: mentorNameStatus.error,
            mentorNumber: mentorNumberStatus.error,
            mentorEmail: mentorEmailStatus.error,
            proposedWorkUnderFellowship: proposedWorkUnderFellowshipStatus.error,
            anyotherReleventInfo: anyotherReleventInfoStatus.error,


            hasErrors: (
                nameStatus.hasError ||
                schoolClassStatus.hasError ||
                dobStatus.hasError ||
                addressStatus.hasError ||
                stateStatus.hasError ||
                pincodeStatus.hasError ||
                emailStatus.hasError ||
                schoolNameStatus.hasError ||
                schoolAddressStatus.hasError ||
                schoolPhoneStatus.hasError ||
                phoneStatus.hasError ||
                schoolEmailStatus.hasError ||
                wasteAbundanceStatus.hasError
                // || activityStatus.hasError
                // || activityFiledStatus.hasError
                // || workDoneDetailStatus.hasError
                // || numberOfDetailsStatus.hasError
                // || innovativeSolutionStatus.hasError
                // || acitivityOutcomeStatus.hasError
                // || socialAwarenessStatus.hasError
                // || otherInfoStatus.hasError
                ||
                furtherInitiativesStatus.hasError ||
                practicabilityStatus.hasError ||
                peerDifferenceStatus.hasError ||
                expectedOutputStatus.hasError ||

                mechanismStatus.hasError
                // || adherNoStatus.hasError
                // || accountNoStatus.hasError
                // || AccHolderNameStatus.hasError
                // || confirmAccountNoStatus.hasError
                // || bankNameStatus.hasError
                // || branchName.hasError
                // || ifscStatus.hasError
                // || presentRadioStatus.hasError
                // || socialRadioStatus.hasError
                // || engagementCommunityStatus.hasError
                ||
                genderStatus.hasError
                // || otherWasteCategoryStatus.hasError
                ||
                mentorName.hasError ||
                mentorNumber.hasError ||
                mentorEmail.hasError ||
                proposedWorkUnderFellowship.hasError ||
                anyotherReleventInfo.hasError

            )
        };
        // debugger
        return formStatus;
    },




    validateSeactionBForm: (params) => {
        const {
            name,
            phone,
            schoolClass,
            dob,
            address,
            state,
            city,
            pincode,
            email,
            institutionName,
            institutionAddress,
            institutionContactNo,
            institutionEmail,
            wasteAbundance,
            furtherInitiatives,
            practicability,
            peerDifference,
            expectedOutputB,
            anticipateOutcome,
            mechanism,
            gender,
            proposedWorkUnderFellowship,
            anyotherReleventInfo
        } = params;
        // debugger

        let formStatus = {};
        const nameStatus = studentName(name);
        const schoolClassStatus = validateDropDown(schoolClass);
        const dobStatus = validateDob(dob);
        const addressStatus = validateAddress(address);
        const stateStatus = validateDropDown(state);
        const cityStatus = validateCity(city);
        const pincodeStatus = validatePincode(pincode)
        const emailStatus = validateEmail(email);
        const institutionNameStatus = validateSchoolName(institutionName)
        const institutionAddressStatus = validateAddress(institutionAddress)
        const institutionContactNoStatus = validatePhone(institutionContactNo)
        const phoneStatus = validatePhone(phone)
        const institutionEmailStatus = validateEmail(institutionEmail)
        const wasteAbundanceStatus = validate100TextArea(wasteAbundance)

        const furtherInitiativesStatus = validate150TextArea(furtherInitiatives)
        const practicabilityStatus = validate100TextArea(practicability)

        const peerDifferenceStatus = validate100TextArea(peerDifference)
        const expectedOutputBStatus = validate200TextArea(expectedOutputB)
        const anticipateOutcomeStatus = validate100TextArea(anticipateOutcome)
        const mechanismStatus = validate100TextArea(mechanism)
        const genderStatus = validationRadio(gender)
        const proposedWorkUnderFellowshipStatus = validate100TextArea(proposedWorkUnderFellowship)
        const anyotherReleventInfoStatus = validate250TextArea(anyotherReleventInfo)

        formStatus = {
            name: nameStatus.error,
            schoolClass: schoolClassStatus.error,
            dob: dobStatus.error,
            address: addressStatus.error,
            state: stateStatus.error,
            city: cityStatus.error,
            pincode: pincodeStatus.error,
            email: emailStatus.error,
            institutionName: institutionNameStatus.error,
            institutionAddress: institutionAddressStatus.error,
            institutionContactNo: institutionContactNoStatus.error,
            phone: phoneStatus.error,
            institutionEmail: institutionEmailStatus.error,
            wasteAbundance: wasteAbundanceStatus.error,
            furtherInitiatives: furtherInitiativesStatus.error,
            practicability: practicabilityStatus.error,
            peerDifference: peerDifferenceStatus.error,
            expectedOutputB: expectedOutputBStatus.error,
            anticipateOutcome: anticipateOutcomeStatus.error,
            mechanism: mechanismStatus.error,
            gender: genderStatus.error,
            proposedWorkUnderFellowship: proposedWorkUnderFellowshipStatus.error,
            anyotherReleventInfo: anyotherReleventInfoStatus.error,

            hasErrors: (
                nameStatus.hasError ||
                schoolClassStatus.hasError ||
                dobStatus.hasError ||
                addressStatus.hasError ||
                stateStatus.hasError ||
                pincodeStatus.hasError ||
                emailStatus.hasError ||
                institutionNameStatus.hasError ||
                institutionAddressStatus.hasError ||
                institutionContactNoStatus.hasError ||
                phoneStatus.hasError ||
                institutionEmailStatus.hasError ||
                wasteAbundanceStatus.hasError ||
                furtherInitiativesStatus.hasError ||
                practicabilityStatus.hasError ||
                peerDifferenceStatus.hasError ||
                expectedOutputB.hasError ||
                anticipateOutcomeStatus.hasError ||
                mechanismStatus.hasError ||
                genderStatus.hasError ||
                proposedWorkUnderFellowship.hasError ||
                anyotherReleventInfo.hasError

            )
        };
        return formStatus;
    },


    validateSeactionCForm: (params) => {
        const {
            PersonName,
            education,
            address,
            state,
            city,
            pincode,
            email,
            phone,
            wasteAbundance,
            activity,
            activityFiled,
            workDoneDetail,
            innovativeSolution,
            acitivityOutcome,
            socialAwareness,
            otherInfo,
            furtherInitiatives,
            practicability,
            peerDifference,
            expectedOutputC,
            anticipateOutcome,
            mechanism,
            presentRadio,
            socialRadio,
            engagementCommunity,
            gender,
            otherWasteCategory,
            applicantName,
            dob,
            prominentChallenges,
            organizeRadio,
            anyotherReleventInfo,
            organizeAnyProgramDetails,
        } = params;
        // debugger

        let formStatus = {};
        const PersonNameStatus = studentName(PersonName);
        const educationStatus = validateDropDown(education);
        const addressStatus = validateAddress(address);
        const stateStatus = validateDropDown(state);
        const cityStatus = validateCity(city);
        const pincodeStatus = validatePincode(pincode)
        const emailStatus = validateEmail(email);
        const phoneStatus = validatePhone(phone)
        const wasteAbundanceStatus = validate100TextArea(wasteAbundance)
        const activityStatus = validateDropDown(activity);
        const activityFiledStatus = validateInput(activityFiled)
        const workDoneDetailStatus = validate100TextArea(workDoneDetail)
        const innovativeSolutionStatus = validate100TextArea(innovativeSolution)
        const acitivityOutcomeStatus = validate100TextArea(acitivityOutcome)
        const socialAwarenessStatus = validate100TextArea(socialAwareness)
        const otherInfoStatus = validate100TextAreaOtherInfo(otherInfo)
        const furtherInitiativesStatus = validate150TextArea(furtherInitiatives)
        const practicabilityStatus = validate100TextArea(practicability)
        const peerDifferenceStatus = validate100TextArea(peerDifference)
        const anticipateOutcomeStatus = validate100TextArea(anticipateOutcome)
        const otherWasteCategoryStatus = validate100TextArea(otherWasteCategory)
        const expectedOutputCStatus = validate200TextArea(expectedOutputC)
        const mechanismStatus = validate100TextArea(mechanism)
        const presentRadioStatus = validationRadio(presentRadio)
        const socialRadioStatus = validationRadio(socialRadio)
        const genderStatus = validationRadio(gender)

        const engagementCommunityStatus = validate100TextArea(engagementCommunity)
        const dobStatus = validateDob(dob);
        const organizeRadioStatus = validationRadio(organizeRadio)

        const organizeAnyProgramDetailsStatus = validateOrganizeDetailsText(organizeAnyProgramDetails, organizeRadio)
            // }
        const applicantNameStatus = studentName(applicantName)
        const prominentChallengesStatus = validate100TextArea(prominentChallenges)
        const anyotherReleventInfoStatus = validate250TextArea(anyotherReleventInfo)

        formStatus = {
            PersonName: PersonNameStatus.error,
            education: educationStatus.error,
            address: addressStatus.error,
            state: stateStatus.error,
            city: cityStatus.error,
            pincode: pincodeStatus.error,
            email: emailStatus.error,
            phone: phoneStatus.error,
            wasteAbundance: wasteAbundanceStatus.error,
            activity: activityStatus.error,
            activityFiled: activityFiledStatus.error,
            workDoneDetail: workDoneDetailStatus.error,
            innovativeSolution: innovativeSolutionStatus.error,
            acitivityOutcome: acitivityOutcomeStatus.error,
            socialAwareness: socialAwarenessStatus.error,
            otherInfo: otherInfoStatus.error,
            furtherInitiatives: furtherInitiativesStatus.error,
            practicability: practicabilityStatus.error,
            peerDifference: peerDifferenceStatus.error,
            anticipateOutcome: anticipateOutcomeStatus.error,
            expectedOutputC: expectedOutputCStatus.error,
            mechanism: mechanismStatus.error,
            presentRadio: presentRadioStatus.error,
            socialRadio: socialRadioStatus.error,
            gender: genderStatus.error,
            otherWasteCategory: otherWasteCategoryStatus.error,

            engagementCommunity: engagementCommunityStatus.error,
            dob: dobStatus.error,
            organizeRadio: organizeRadioStatus.error,
            organizeAnyProgramDetails: organizeAnyProgramDetailsStatus.error,
            applicantName: applicantNameStatus.error,
            prominentChallenges: prominentChallengesStatus.error,
            anyotherReleventInfo: anyotherReleventInfoStatus.error,

            hasErrors: (
                PersonNameStatus.hasError ||
                educationStatus.hasError ||
                addressStatus.hasError ||
                stateStatus.hasError ||
                pincodeStatus.hasError ||
                emailStatus.hasError ||
                phoneStatus.hasError ||
                wasteAbundanceStatus.hasError ||
                activityStatus.hasError ||
                activityFiledStatus.hasError ||
                workDoneDetailStatus.hasError ||
                innovativeSolutionStatus.hasError ||
                acitivityOutcomeStatus.hasError ||
                socialAwarenessStatus.hasError ||
                otherInfoStatus.hasError ||
                furtherInitiativesStatus.hasError ||
                practicabilityStatus.hasError ||
                peerDifferenceStatus.hasError ||
                anticipateOutcomeStatus.hasError ||
                expectedOutputCStatus.hasError ||
                mechanismStatus.hasError ||
                presentRadioStatus.hasError ||
                socialRadioStatus.hasErro ||
                genderStatus.hasError ||
                otherWasteCategory.hasError ||
                engagementCommunityStatus.hasError ||
                dobStatus.hasError ||
                organizeRadioStatus.hasError ||
                organizeAnyProgramDetailsStatus.hasError ||
                applicantNameStatus.hasError ||
                prominentChallengesStatus.hasError ||
                anyotherReleventInfoStatus.hasError
            )
        };
        return formStatus;
    }


};


export default validation;