import React, { useState, useEffect } from 'react';
import InlineError from '../InlineError'
import * as FileType from 'file-type'
// const FileType = require('file-type');

// var mime = require('mime-types')

import { Z_STREAM_ERROR } from 'zlib';

const getFileSize = (file) => {
  return file.files[0].size / 1024 / 1024; // in MB
}

const FileUpload = (props) => {
  const {
    required,
    onUploadFile,
    id,
    maxFileSizeInMB,
    label,
    labelClass,
    name = "",
    error,
    allowedFiles = [],
    value,
    hasInvalidSubmitOccurred,
    onBlur,
    htmlFor
  } = props;


  const [fileError, setError] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [touched, setTouched] = useState(false);



  useEffect(() => {
    setError(error);
  }, [error])

  const handleInputBlur = (e, name) => {
    setTouched(true);
    if (onBlur) {
      onBlur(e, name);
    }
    return true;
  };

  const validateUploadFile = (file) => {
    const val = file && file.name || "";
    let regex = new RegExp("([a-zA-Z0-9\s_\!@#\$%\^\&*\\.\-\w():])+(" + allowedFiles.join('|') + ")$");

    if (val && !regex.test(val.toLowerCase())) {
      setError("Please upload files having extensions " + allowedFiles.join(', ') + " only.")
      return false;
    }
    return true;
  }

  const displayError = touched || hasInvalidSubmitOccurred;


  const onFileSelect = (event) => {

    const { target = {} } = event;
    const val = target.value || "";
    const name = target.name || "";
    const file = target.files[0];
    // console.log('file',file)
// let tt = mime.charset(file.name)

// console.log('file--',tt)

    if (validateUploadFile(file)) {
      if (target.files && file) {
        const size = getFileSize(target);
        if (maxFileSizeInMB && size > maxFileSizeInMB) {
          target.value = null;
          setError("File Size is too large. Allowed maximum size is  " + maxFileSizeInMB + " MB.");
          return;
        }
        setUploadFile(name);
        setError("")
        if (onUploadFile) {
          onUploadFile(file);
        }
      }
    } else {
      event.target.value = ""
    }
  }

  return (
    <React.Fragment>
      {
        label && <label
          htmlFor={htmlFor}
          className={`${labelClass} ${displayError && fileError ? 'error' : ''}`}
        >
          {label}
          {
            required
            && (
              <span className="required">&nbsp;*</span>
            )
          }
        </label>}
      <input
        id={id}
        onChange={onFileSelect}
        type="file"
        name={name}
        value={value}
        onBlur={handleInputBlur}
      />
      {
        displayError && fileError && (
          <div>
            <InlineError message={fileError} />
          </div>
        )
      }
    </React.Fragment>
  )
}

export default FileUpload;