export const API_URL = () => process.env.REACT_APP_BASE_URL;
export const CHALLENGE_API_URL = () => process.env.REACT_APP_CHALLENGE_API_ROOT;
export const USER_LIST = `${API_URL()}/users`;
export const UPLOAD_FILE = `${API_URL()}/file/upload/node/contact/field_attach_file`;
// export const SUBMIT_CONTACT_US = `${API_URL()}/api/v1/submit/contact`;
// export const SUBMIT_CONTACT_US = `https://prod-22.centralindia.logic.azure.com:443/workflows/22b490cf702d4ea1a7b8ef0a96a3895d/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=15HDSgjhbwjGOP1yPx9fwLx1IkhRW9Pet-KJc860WxA`;
export const CHALLANGE_EVENT_LISTING = `${API_URL()}/wtw/api/v1/challenge-listing?path_alias=/alias`;
export const CHALLANGE_EVENT_DETAILS = `${API_URL()}/wtw/api/v1/challenge-detail`;
export const HEADER_DATA = `${API_URL()}/wtw/api/v1/header`;
export const SEARCH_DATA = `${API_URL()}/wtw/api/v1/search-service`;
export const CURRENT_INTERVENTION = `${API_URL()}/wtw/api/v1/homepage-currentinterventions`;
export const QUOTES = `${API_URL()}/wtw/api/v1/homepage-quotes?language=en&path_alias=/home`;
export const FEATURED_TECHNOLOGY = `${API_URL()}/wtw/api/v1/homepage-technology`;
export const IMPORTANT_CHALLENGES = `${API_URL()}/wtw/api/v1/homepage-challengesupdates`;
export const BANNER = `${API_URL()}/wtw/api/v1/homepage-banner`;
export const FOOTER_DATA = `${API_URL()}/wtw/api/v1/footer`;
export const MAJOR_INVESTORS = `${API_URL()}/wtw/api/v1/homepage-major-investor?path_alias=/home`;
export const LATEST_NEWS = `${API_URL()}/wtw/api/v1/homepage-latest-news`;
export const UPCOMMING_EVENTS = `${API_URL()}/wtw/api/v1/homepage-upcoming-events`;
export const CONTACT_US_DATA = `${API_URL()}/api/v1/getintouch/contact`;
export const CHALLENGE_TECHNOLOGY = `${API_URL()}/wtw/api/v1/challenge-technology`;
export const CHALLENGE_CATEGORY = `${API_URL()}/wtw/api/v1/challenge-category`;
export const ABOUT_US = `${API_URL()}/wtw/api/v1/about-us?path_alias=/about-us`;
export const EVENT_LISTING = `${API_URL()}/wtw/api/v1/event-listing`;
export const EVENT_DETAILS = `${API_URL()}/wtw/api/v1/events-detail?path_alias=`;
export const EVENT_DETAILS2 = `${API_URL()}/wtw/api/v1/ssf-videos-listing?category=`;
export const FEATURED_EVENT = `${API_URL()}/wtw/api/v1/featured-event-listing`;
export const CURRENT_INTERVENTION_LIST = `${API_URL()}/wtw/api/v1/current-interventions-listing`;
export const ARTICLES_CASE_STUDIES_LIST = `${API_URL()}/wtw/api/v1/articles-case-studies`;
export const ARTICLES_CASE_STUDIES_TAG_LIST = `${API_URL()}/wtw/api/v1/articles-case-studies-tag-list`;
export const ARTICLES_CASE_STUDIES_TAGS = `${API_URL()}/wtw/api/v1/articles-case-studies-tags`;
export const ARTICLES_CASE_STUDIES_MOSTREAD = `${API_URL()}/wtw/api/v1/articles-case-studies-top-three`;
export const ARTICLES_CASE_STUDIES_DETAILS = `${API_URL()}/wtw/api/v1/articles-case-studies-detail`;
export const UPCOMING_INTERVENTION_LIST = `${API_URL()}/wtw/api/v1/upcoming-interventions-listing`;
export const INTERVENTION_TECNOLOGY = `${API_URL()}/wtw/api/v1/current-interventions-filter`;
export const UPCOMING_INTERVENTION_TECNOLOGY = `${API_URL()}/wtw/api/v1/upcoming-interventions-technology`;
export const CURRENT_INTERVENTION_DETAILS = `${API_URL()}/wtw/api/v1/interventions-detail?path_alias=`;
export const FILTER_DATA = `${API_URL()}/wtw/api/v1/interventions-filters`;
export const UPCOMING_INTERVENTION_FILTER_DATA = `${API_URL()}/wtw/api/v1/upcoming-interventions-filters`;
export const UPLOAD_FILE_EVENT = `${API_URL()}/file/upload/node/event_registration_form/field_attach_file`;
export const UPLOAD_FILE_EVENT2 = `${API_URL()}/fileupload`;

export const SUBMIT_REGISTRATION = `${API_URL()}/api/v1/submit/event_registration_form`;
export const FELLOW_SHIP_URL = `${API_URL()}/api/v1/getdetails/fellowshipform`;
export const SUBMIT_CATEGORY_FELLOwSHIP = `${API_URL()}/api/v1/submit/fellowship_submission`;
export const SUBMIT_CITIZEN_PLEDGE = `${API_URL()}/api/v1/submit/citizen_pledge_submission`;
export const BANK_UPLOAD_URL = `${API_URL()}/file/upload/node/fellowship_form/field_proof_of_banking_details`;
export const ENGAGEMENT_UPLOAD_URL = `${API_URL()}/file/upload/node/fellowship_form/field_proof_of_your_engagement`;
export const FELLOWSHIP_DETAILS = `${API_URL()}/wtw/api/v1/fellowship-detail?path_alias=`;
export const CITEZEN_CORNER = `${API_URL()}/wtw/api/v1/citizen-corner`;

export const SUBMIT_TECH_INFO = `${API_URL()}/api/v1/getdetails/submit_technology`;
export const SUBMIT_TECHNOLOGY_SUBMIT = `${API_URL()}/api/v1/submit/submit_technology_submission`;
export const TECH_IMG_UPLOAD_URL = `${API_URL()}/file/upload/node/submit_technology_form/field_upload_technology_photos`;
export const PARTNER_WITH_US = `${API_URL()}/wtw/api/v1/partner-with-us`;
export const SUCCESS_STORIES_LIST = `${API_URL()}/wtw/api/v1/success-stories-listing`;
export const SUCCESS_STORIES_TECNOLOGY = `${API_URL()}/wtw/api/v1/successstories-technology`;
export const SUCCESS_STORIES_DETAILS = `${API_URL()}/wtw/api/v1/success-stories-detail?path_alias=`;
export const SUCCESS_STORIES_FILTER_DATA = `${API_URL()}/wtw/api/v1/successstories-filters`;
export const FELLOWSHIP_INTERVENTION_LIST = `${API_URL()}/wtw/api/v1/fellowships-listing`;
export const FELLOWSHIP_INTERVENTION_TECNOLOGY = `${API_URL()}/wtw/api/v1/fellowship-categories`;
export const PLEDGE_DATA = `${API_URL()}/wtw/api/v1/citizen-corner?path_alias=`;
export const ANALYTICS_DASHBOARD_DATA = `${API_URL()}/api/v1/getdetails/analytics_dashboard`;
export const MAIL_OTP = `${API_URL()}/wtw/api/v1/mail-otp-service`;
export const POLICY_LISTING = `${API_URL()}/wtw/api/v1/km-policies?`;
export const POLICY_TYPE = `${API_URL()}/wtw/api/v1/policies-type`;
export const POLICY_TECHNOLOGY = `${API_URL()}/wtw/api/v1/policies-technology`;
export const VALIDATE_EMAIL = `${API_URL()}/wtw/api/v1/email-verification-service?email=`;

export const KM_TECHNOLOGY_LISTING = `${API_URL()}/wtw/api/v1/kmtechnology-listing`;
export const VALIDATION_LINK_LIST = `${API_URL()}/wtw/api/v1/validation-detail`;
// export const KM_FILTER_DATA = `${API_URL()}/wtw/api/v1/kmtechnology-filters`;
export const KM_TECNOLOGY_TYPE = `${API_URL()}/wtw/api/v1/kmtechnology-category`;
export const KM_DETAILS = `${API_URL()}/wtw/api/v1/validated-technology-detail?path_alias=`;
export const NUMBER_OF_VISITORS = `${API_URL()}/wtw/api/v1/google-analytics-service`;
export const SUBSCRIBE_MISSION = `${API_URL()}/wtw/api/v1/subscribe-to-mission?email=`;
export const VALIDATE_IMAGE = `${CHALLENGE_API_URL()}/document/mime_validate`;
export const SUBMIT_CONTACT_US = `${CHALLENGE_API_URL()}/validate/contact_us`;
export const SWACHHTA_SARTHI_FELLOWSHIP_HOME = `${API_URL()}/wtw/api/v1/fellowship-home?path_alias=/fellowship-home`;
export const ABOUT_COHORT_GET_STATE_DETAILS = `https://uat.wastetowealth.gov.in/fellowship-admin/v1/fellow/list`;
export const FELLOWSHIP_LATEST_NEWS = `${API_URL()}/wtw/api/v1/fellowship-latest-news`;

export const KM_FILTER_DATA = `${API_URL()}/wtw/api/v1/validated-technology-filters`;
export const VALIDATED_TECHNOLOGY_LISTING = `${API_URL()}/wtw/api/v1/validated-technology-listing`;
export const PAST_PROJECTS_LIST = `${API_URL()}/wtw/api/v1/past-project`;
export const PAST_PROJECTS_DETAILS = `${API_URL()}/wtw/api/v1/past-project-detail?path_alias=`;

export const SWACHHTA_SARTHI_COHORT_MAP = `${API_URL()}/wtw/api/v1/cohort-map-data?path_alias=`;
export const SWACHHTA_SARTHI_ABOUT_MENTOR = `${API_URL()}/wtw/api/v1/about-mentor?path_alias=`;
export const SWACHHTA_SARTHI_MEET_OUR_FELLOWS = `${API_URL()}/wtw/api/v1/meet_our_fellows?path_alias=`;
// export const FELLOWSHIP_INITATIVES = `${API_URL()}/wtw/api/v1/fellowships-initiatives`;
export const FELLOWSHIP_INITATIVES = `${API_URL()}/wtw/api/v1/fellowships-initiatives?category=`;
export const RUTAG_PAGE = `${API_URL()}/wtw/api/v1/rutag`;
export const RUTAG_PAGE_DETAILS = `${API_URL()}/wtw/api/v1/RutagRuralTechnology?path_alias=`;
