import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import teamImage2 from "../../../assests/images/monoranjan.png";
import Slider from "react-slick";

const MeatTeam = (props) => {
  const { data, description, heading, count } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length < 3 ? data?.length : count,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <div className=" bg-recent-news">
      {/* <div className="yellow-line" />
      <div className="blue-line" />
      <div className="green-line" /> */}
      <div className="MeatTeamContactUs">
        <div className="container">
          <div className="container-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="partner-heading-contact">
                  {ReactHtmlParser(heading)}
                </div>
                <div className="partner-sub-heading">
                  {ReactHtmlParser(description)}
                </div>
              </div>
            </div>
            <div className=" team-member-detail-contact-row">
              <div className="team-member-top">
                <div className="team-member-detail">
                  <img src={teamImage2} className="team-image" />
                  <div className="team-member-name">Dr. Monoranjan Mohanty</div>
                  <div className="team-member-designation">
                    Adviser/Scientist ‘G’,
                  </div>
                  <div className="team-member-designation">
                    Office of the Principal Scientific Advisor to the Government
                    of India
                  </div>
                </div>
              </div>
              <Slider {...settings}>
                {data &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((value, index) => {
                    return (
                      <div className="team-member-detail" key={index}>
                        <img
                          src={value?.image}
                          // src={
                          //   "https://uat.wastetowealth.gov.in/s3fs/2022-03/Screenshot%202022-03-25%20at%206.27.13%20PM.png"
                          // }
                          className="team-image"
                        />
                        <div className="team-member-name">{value?.name}</div>
                        <div className="team-member-designation">
                          {value?.designation}
                        </div>
                      </div>
                    );
                  })}

                {/* <div className='team-member-detail'>
                                <img src={teamImage} className='team-image' />
                                <div className='team-member-name'>Malvika Jain</div>
                                <div className='team-member-designation'>Associate, PM-STIAC Secretariat</div>
                            </div>
                            <div className='team-member-detail'>
                                <img src={teamImage} className='team-image' />
                                <div className='team-member-name'>Malvika Jain</div>
                                <div className='team-member-designation'>Associate, PM-STIAC Secretariat</div>
                            </div>
                            <div className='team-member-detail'>
                                <img src={teamImage} className='team-image' />
                                <div className='team-member-name'>Malvika Jain</div>
                                <div className='team-member-designation'>Associate, PM-STIAC Secretariat</div>
                            </div>
                            <div className='team-member-detail'>
                                <img src={teamImage} className='team-image' />
                                <div className='team-member-name'>Malvika Jain</div>
                                <div className='team-member-designation'>Associate, PM-STIAC Secretariat</div>
                            </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="MeatTeamMobile">
        <div className="container">
          <div className="container-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="partner-heading-contact">
                  {ReactHtmlParser(heading)}
                </div>
                <div className="partner-sub-heading">
                  {ReactHtmlParser(description)}
                </div>
              </div>
            </div>
            <div className=" team-member-detail-contact-row">
              <div className="team-member-top">
                <div className="team-member-detail">
                  <img src={teamImage2} className="team-image" />
                  <div className="team-member-name">
                    Dr. Shailja Vaidya Gupta
                  </div>
                  <div className="team-member-designation">
                    Mission Director
                  </div>
                </div>
              </div>
              <Slider {...mobsettings}>
                {data &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((value, index) => {
                    return (
                      <div className="team-member-detail" key={index}>
                        <img
                          src={value?.image}
                          // src={
                          //   "https://uat.wastetowealth.gov.in/s3fs/2022-03/Screenshot%202022-03-25%20at%206.27.13%20PM.png"
                          // }
                          className="team-image"
                        />
                        <div className="team-member-name">{value?.name}</div>
                        <div className="team-member-designation">
                          {value?.designation}
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div> */}

      <div className=" bg-recent-news meetTeam">
        <div className="yellow-line" />
        <div className="blue-line" />
        <div className="green-line" />
      </div>
    </div>
  );
};

export default MeatTeam;
