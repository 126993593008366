import { combineReducers } from "redux";
import {
  SWACHHTA_SARTHI_ABOUT_MENTOR,
  SWACHHTA_SARTHI_MEET_OUR_FELLOWS,
  SWACHHTA_SARTHI_COHORT_MAP,
  // SSF_MONTH,
} from "../../../constants/actionConstants";

const fellowshipMentor = (state = {}, action) => {
  switch (action.type) {
    case SWACHHTA_SARTHI_ABOUT_MENTOR:
      return { ...action.payload };
    default:
      return state;
  }
};
const fellowshipFellows = (state = {}, action) => {
  switch (action.type) {
    case SWACHHTA_SARTHI_MEET_OUR_FELLOWS:
      return { ...action.payload };
    default:
      return state;
  }
};
const fellowshipMap = (state = {}, action) => {
  switch (action.type) {
    case SWACHHTA_SARTHI_COHORT_MAP:
      return { ...action.payload };
    default:
      return state;
  }
};
// const fellowshipSSFmentor = (state = {}, action) => {
//   switch (action.type) {
//     case SSF_MONTH:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };

const FellowshipComponentsReducer = combineReducers({
  fellowshipMentor,
  fellowshipFellows,
  fellowshipMap,
  // fellowshipSSFmentor,
});

export default FellowshipComponentsReducer;
