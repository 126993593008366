import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";
import '../../../../../assests/slick/slick.css';
import '../../../../../assests/slick/slick-theme.css';
import './style.scss';

const EligibilityCriteria = (props) => {
    const { data } = props
    // console.log('datadata', data)
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="eligibility-criteria-challenge">
            <div className='container'>
                <div className='container-wrap'>
                    <div className='row'>
                        <div className='col-sm-3 criteria-heading eligible-heading'>
                            <div>
                                {ReactHtmlParser(data?.title)}
                            </div>
                        </div>
                        <div className='offset-1 col-sm-8 eligibility-criteria-plate'>
                            <div className='row'>
                                {
                                    data
                                    && Array.isArray(data?.entity)
                                    && data?.entity.length > 0
                                    && data?.entity.map((value, index) => {
                                        return <div key={index} className='criteria-tile eliglible-tile col-sm-5'>
                                            <div className='criteria-number eligible-number'>
                                                #{index + 1}
                                                <span>Criteria</span>
                                            </div>
                                            <div className='criteria-content'>
                                                {ReactHtmlParser(value?.title)}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

export default EligibilityCriteria;