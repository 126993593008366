import api from '../../../dependencies/utils/Api';
import {
    UPLOAD_FILE,
    SUBMIT_CONTACT_US,
    CONTACT_US_DATA,
    SUBSCRIBE_MISSION
} from "../../../constants/ApplicationUrl";

export const contactUsService = {
    uploadFile(params) {
        return api.POSTDATA(UPLOAD_FILE, params)
            .then((response) => {
                const { data, status } = response || {};
                if (status) {
                    return { data, status };
                } else {
                    return Promise.reject();
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    submitContactUs(params) {
        return api.POST(SUBMIT_CONTACT_US, params)
            .then((response) => {
                const { data, status } = response || {};
                return { data, status };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    contactUsData() {
        return api.GET(CONTACT_US_DATA)
            .then((response) => {
               
                const { data = { data } = {} } = response.data || {};
                return { data };
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    subscribeMission(email) {
        return api.GET(`${SUBSCRIBE_MISSION}${email}`)
            .then((response) => {
                return response?.data
            }).catch(e => {
                return Promise.reject(e);
            });
    }
}
