import api from "../../../dependencies/utils/Api";
import {
  CURRENT_INTERVENTION_LIST,
  INTERVENTION_TECNOLOGY,
  CURRENT_INTERVENTION_DETAILS,
  FILTER_DATA,
  ARTICLES_CASE_STUDIES_LIST,
  ARTICLES_CASE_STUDIES_TAG_LIST,
  ARTICLES_CASE_STUDIES_TAGS,
  ARTICLES_CASE_STUDIES_DETAILS,
  ARTICLES_CASE_STUDIES_MOSTREAD,
} from "../../../constants/ApplicationUrl";

export const ArticlesService = {
  getCurrentInterventionList(
    page,
    categoryId,
    city,
    search,
    usetype,
    subtheme,
    scale,
    condition,
    state,
    duration
  ) {
    return api
      .GET(
        `${CURRENT_INTERVENTION_LIST}?page=${page}&category=${categoryId}&city=${city}&search=${search}&usetype=${usetype}&subtheme=${subtheme}&scale=${scale}&condition=${condition}&state=${state}&duration=${duration}`
      )
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getTechnologyType() {
    return api
      .GET(INTERVENTION_TECNOLOGY)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getArticlesTagsType() {
    return api
      .GET(ARTICLES_CASE_STUDIES_TAGS)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  //   getArticlesTechnologyType() {
  //     return api
  //       .GET(ARTICLES_CASE_STUDIES_LIST)
  //       .then((response) => {
  //         const { data = { data } } = response.data || {};
  //         return { data };
  //       })
  //       .catch((e) => {
  //         return Promise.reject(e);
  //       });
  //   },

  getArticlesTechnology(
    page,
    categoryId,
    city,
    search,
    usetype,
    subtheme,
    scale,
    condition,
    state,
    duration,
    date,
    tag
  ) {
    return api
      .GET(
        `${ARTICLES_CASE_STUDIES_LIST}?page=${page}&category=${categoryId}&city=${city}&search=${search}&usetype=${usetype}&subtheme=${subtheme}&scale=${scale}&condition=${condition}&state=${state}&duration=${duration}&date=${date}&tag_id=${tag}`
      )
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getArticlesTagList(
    page,
    categoryId,
    city,
    search,
    usetype,
    subtheme,
    scale,
    condition,
    state,
    duration,
    date,
    tag
  ) {
    return api
      .GET(
        `${ARTICLES_CASE_STUDIES_TAG_LIST}?page=${page}&category=${categoryId}&city=${city}&search=${search}&usetype=${usetype}&subtheme=${subtheme}&scale=${scale}&condition=${condition}&state=${state}&duration=${duration}&date=${date}&tag_id=${tag}`
      )
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getArticlesDetails(path_alias) {
    return api
      .GET(`${ARTICLES_CASE_STUDIES_DETAILS}?path_alias=${path_alias}`)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getMostReadType() {
    return api
      .GET(ARTICLES_CASE_STUDIES_MOSTREAD)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getCurrentInterventionDetails(url) {
    return api
      .GET(`${CURRENT_INTERVENTION_DETAILS}/${url}`)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  filterData() {
    return api
      .GET(FILTER_DATA)
      .then((response) => {
        const { data: { responseCode, data } = {} } = response || {};
        if (responseCode === 200) {
          return data;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
};
