import ReactHtmlParser from 'react-html-parser';
import './style.scss';

const ExpectedImpact = (props) => {
    const { data } = props
    return (
        <div className="ExpectedImpact">
            <div className='container'>
            <div className="large-tree" />
            <div className="small-tree" />
            <div class="bin-car"></div>
            <div class="white-leaf"></div>
            <h1 className='section-heading'>{ReactHtmlParser(data?.heading)}</h1>
                <div className='row'>
                    <div className='col-12'>
                        {ReactHtmlParser(data?.left)}
                    </div>
                    
                </div>
                <div className="row">
                <div className='col-12'>
                        {ReactHtmlParser(data?.right)}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ExpectedImpact;