import React from 'react'
import { connect } from 'react-redux'
import './style.scss';
import loaderImg from '../../../assests/images/small-loader.gif';

let LoadingAnimation = props => {
    const { isLoading } = props
    return (
        <div>
            {isLoading &&
                <div className="loader-container">
                    <img src={loaderImg} alt="" className='loader-gif' />
                </div>

            }
        </div>
    )
};

LoadingAnimation = connect((store, oprops) => ({
    isLoading: store.loader.isLoading
})
)(LoadingAnimation)


export default LoadingAnimation;
