import Slider from "react-slick";
import './style.scss';
import '../../../../../assests/slick/slick.css';
import '../../../../../assests/slick/slick-theme.css';
import commaLeft from '../../../../../assests/icons/comma_right.svg';
import commaRight from '../../../../../assests/icons/comma_left.svg';
import twitterIcon from '../../../../../assests/icons/ic_twitter.svg';

const Quotes = (props) => {
    const { data } = props;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };


    return (
        <div className="slider-content clearfix">
            <div className='background-container-quotes main-container'>
                <div className='quotes-cover '>
                    <Slider {...settings}>
                        {
                            data
                            && Array.isArray(data.list)
                            && data.list.length > 0
                            && data.list.map((value, index) => {
                                return (<div className="quote-slide-wrap" key={index}>
                                    <div className='quotes-left-section'>
                                        <div className='quotes-text'>

                                            <span> 
                                                {/* <img width="25" src={commaRight} className='copy-comma-top copy-comma' alt="" /> */}
                                                {value?.quote}
                                                {/* <img width="25" src={commaLeft} className='copy-comma-bottom copy-comma' alt="" /> */}
                                                </span>

                                        </div>
                                    </div>
                                    <div className='quotes-image text-center'>
                                        <div class="quote-img-inner">
                                            <img src={value?.profile?.image} alt="" />
                                        </div>
                                        <div className='quotes-image-content'>
                                            <div className='quotes-writer-name'>
                                                <span>{value?.name}</span>
                                            </div>
                                            <div className='quotes-writer-designation'>
                                                <span>{value?.designation}</span>
                                            </div>
                                            <div className='quotes-writer-handle'>
                                                <a href={`https://twitter.com/${value?.handler}`} target="_blank">
                                                    <span><img width="17" src={twitterIcon} className='twitter-icon' alt="" /> {value?.handler}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Quotes;