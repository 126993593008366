import { combineReducers } from 'redux';
import {
    FELLOWHIP
} from '../../../constants/actionConstants';

const FelloshipData = (state = {}, action) => {
    switch (action.type) {
        case FELLOWHIP:
            return { ...action.payload };
        default:
            return state;
    }
}

const FelloshipReducer = combineReducers({
    FelloshipData
});

export default FelloshipReducer;


