import { connect } from 'react-redux';
import ModalHOC from "../../../../common/Modal";
import Slider from "react-slick";
import "./style.scss";


const ReadMoreModal = (props) => {
    const { data, className } = props;

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            }]
    };

    return (
        <div>
            <h3 className="title">
                test
            </h3>
            <div className="card mt-5 mb-5">
                <div className="card-body">
                    <div className="specs mb-4">
                        <span className="term">Location</span>
                        <span className="value">{"data?.Location"}</span>
                    </div>
                    <div className="specs mb-4">
                        <span className="term">Capacity</span>
                        <span className="value">{"data?.Capacity"}</span>
                    </div>
                    <div className="specs">
                        <span className="term">Client</span>
                        <span className="value">{data?.Client}</span>
                    </div>
                </div>
            </div>
            <div className="technology-intervention custom-slider-nextPrev mt-5">
                <div className='container'>
                    <div className="row">
                        <div className="col-12 d-flex quiz-header mb-2">
                            <h5>Technology Images</h5>
                        </div>
                    </div>
                
                    <Slider
                        {...settings}
                    >
                        {data?.TechnologyImages.map(item => (
                            <div className="row">
                                <div className="col">
                                    <div className="item hover-zoom">
                                        <div className="image">
                                            <img src={item?.TechnologyImages} />
                                        </div>
                                    </div>
                                    <p>{item?.Title}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
export default connect(null, {})(ModalHOC(ReadMoreModal));