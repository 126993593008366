import { StaticPageService } from './service'
import { ABOUT_US } from '../../../constants/actionConstants'
import { isLoading } from '../../../dependencies/action/action'

export function getAboutUs() {
  return async function (dispatch) {
    dispatch(isLoading(true))
    let getAboutUs = await StaticPageService.getAboutUs()
    dispatch({
      type: ABOUT_US,
      payload: getAboutUs,
    })
    dispatch(isLoading(false))
  }
}
