import api from '../../../dependencies/utils/Api';
import {
    CHALLANGE_EVENT_LISTING,
    CHALLANGE_EVENT_DETAILS,
    CHALLENGE_TECHNOLOGY,
    CHALLENGE_CATEGORY
} from "../../../constants/ApplicationUrl";

export const ChallengeService = {
    getChallengeEventList(page, categoryId, search,sortBy) {
        return api.GET(`${CHALLANGE_EVENT_LISTING}&page=${page}&category=${categoryId}&search_string=${search}&challenge_type=${sortBy}`)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getChallengeEventDetails(url) {
        return api.GET(`${CHALLANGE_EVENT_DETAILS}?path_alias=/${url}`)
            .then((response) => {
                const { data: { data } = {} } = response || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getChallengeTechnology() {
        return api.GET(CHALLENGE_TECHNOLOGY)
            .then((response) => {
                const { data: { data } = {} } = response || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getChallengeCategory() {
        return api.GET(CHALLENGE_CATEGORY)
            .then((response) => {
                const { data: { data } = {} } = response || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    }
}