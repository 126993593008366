// about mentor without dropdown

// import ReactHtmlParser from "react-html-parser";
// import "./style.scss";
// import profileImg from "../../../../../assests/images/profile-img.jpeg";
// import Slider from "react-slick";
// import { Link } from "react-router-dom";
// import react, { useState, useEffect } from "react";

// const AboutMentor = (props) => {
//   const { data, description, heading, count } = props;
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: data?.length < 3 ? data?.length : count,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 700,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//     adaptiveHeight: false,
//   };

//   useEffect(() => {
//     console.log("data length", data?.length);
//   });
//   return (
//     <div className="MeatTeam3">
//       <div className="container">
//         <div className="container-wrap">
//           <div className="row">
//             <div className="col-lg-12">
//               <div className="about-mentor-title">
//                 <span>About Mentors</span>
//               </div>
//               {/* <div className='partner-sub-heading'>subtitles</div> */}
//             </div>
//           </div>
//           <div className=" about-mentor-detail-row h-100">
//             <Slider {...settings} className="h-100">
//               {data &&
//                 Array.isArray(data) &&
//                 data.length > 0 &&
//                 data.map((value, index) => {
//                   return (
//                     <div
//                       className="about-mentor-detail slide h-100"
//                       key={index}
//                     >
//                       <div className="about-mentor-margin">
//                         <img
//                           src={value?.image ? value.image : profileImg}
//                           className="about-mentor-image"
//                         />
//                         <div className="about-mentor-name">{value?.name}</div>
//                         <div className="about-mentor-designation">
//                           {value?.state}
//                         </div>
//                         <div className="about-mentor-designation">
//                           {ReactHtmlParser(value?.current_job_position)}
//                         </div>
//                       </div>
//                     </div>
//                   );
//                 })}
//             </Slider>
//           </div>

//           <div className="view-all-wrap d-flex justify-content-end">
//             <Link
//               className="d-flex align-items-center green-circle-link"
//               to={{ pathname: "/view-all-mentor", state: { results: data } }}
//             >
//               {" "}
//               View All Mentors
//               <i className="circle-goto-link-green" />
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AboutMentor;

// about mentor with dropdown

import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import profileImg from "../../../../../assests/images/profile-img.jpeg";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import react, { useState, useEffect } from "react";
import Select from "react-select";

const newMentorArray = [
  {
    value: "2022",
    label: "Cohort 2",
    // isdisabled: true,
  },
  {
    value: "2021",
    label: "Cohort 1",
  },
  // {
  //   value: "Meghalaya",
  //   label: "Meghalaya",
  // },
];

const AboutMentor = (props) => {
  const { data, description, heading, count, setNewYearSelected } = props;
  const [selectedyearMentors, setSelectedyearMentors] = useState(
    // newMentorArray[1]
    newMentorArray[0]
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length < 3 ? data?.length : count,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: false,
  };

  useEffect(() => {
    setNewYearSelected(selectedyearMentors);
    console.log("data length", data?.length);
  }, [selectedyearMentors]);

  const yearArrayStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isSelected ? "#f4f4f4" : null,

        // color: "#333333",
        color: isDisabled ? "lightgrey" : "#333333",
      };
    },

    control: (base, state) => ({
      ...base,
      background: "#fff",
      marginRight: "10px",
      height: "42px",
      fontSize: "15px",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#dddddd" : "#dddddd",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#2ba749" : "#dddddd",
      },
    }),
  };

  return (
    <div className="MeatTeam3">
      <div className="container">
        <div className="container-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="about-mentor-title col-lg-6">
                  <span>About Mentors</span>
                </div>
                <div
                  className="about-mentor-title col-lg-6 cohortmentordropdown"
                  style={{ display: "flex", zIndex: 2 }}
                >
                  <Select
                    className="fellowcompSelector"
                    placeholder="Cohort"
                    options={newMentorArray}
                    styles={yearArrayStyles}
                    value={selectedyearMentors}
                    // value={yearArray[0]}
                    onChange={(e) => {
                      setSelectedyearMentors(e);
                      console.log(e.value);
                    }}
                    // isOptionDisabled={(option) => option.isdisabled}
                  />
                </div>
              </div>

              {/* <div className='partner-sub-heading'>subtitles</div> */}
            </div>
          </div>
          <div className=" about-mentor-detail-row h-100">
            <Slider {...settings} className="h-100">
              {data &&
                Array.isArray(data) &&
                data.length > 0 &&
                data.map((value, index) => {
                  return (
                    <div
                      className="about-mentor-detail slide h-100"
                      key={index}
                    >
                      <div className="about-mentor-margin">
                        <img
                          src={value?.image ? value.image : profileImg}
                          className="about-mentor-image"
                        />
                        <div className="about-mentor-name">{value?.name}</div>
                        <div className="about-mentor-designation">
                          {value?.state}
                        </div>
                        <div className="about-mentor-designation">
                          {ReactHtmlParser(value?.current_job_position)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>

          <div className="view-all-wrap d-flex justify-content-end">
            <Link
              className="d-flex align-items-center green-circle-link"
              to={{ pathname: "/view-all-mentor", state: { results: data } }}
            >
              {" "}
              View All Mentors
              <i className="circle-goto-link-green" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMentor;
