import { FellowShipService } from './service';
import { isLoading } from '../../../dependencies/action/action';
import {
    FELLOWHIP,
    PLEDGE_DATA
} from '../../../constants/actionConstants';
import { toast } from "react-toastify";

export const submitCitizenPledgeData = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await FellowShipService.submitCitizenPledgeData(params).then((response) => {
        dispatch(isLoading(false));
        const { status, data, code } = response || {}
       
        if (status === 200) {
            if (data.code === 400) {
                toast.error(data.message);
                return { status: false }
            } else {
                toast.success(data.message);
                return { status: true,data:data?.data };
            }

        } else {
            dispatch(isLoading(false));
            toast.error(data.message);
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
    })
}


export function getDropDownData() {
    return async function (dispatch) {
        let getDropData = await FellowShipService.getDropDownData();
        dispatch({
            type: FELLOWHIP,
            payload: getDropData
        })
    }
}

export function getPledgeData(url) {
    return async function (dispatch) {
        let getPledgeData = await FellowShipService.pledgeData(url)
        dispatch({
            type: PLEDGE_DATA,
            payload: getPledgeData
        })
    }
}


export const mailOtp = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await FellowShipService.mailOtp(params).then((response) => {
        dispatch(isLoading(false));
      if(response){
        if (response?.responseCode === 404) {
            toast.error(response?.message);
            return { status: false }
        } else  if (response?.responseCode === 403) {
            toast.error(response?.message);
            return { status: false }
        } {
            toast.success(response?.message);
            return { status: true,data:response?.data };
        }
      }else{
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
      }
                
    }).catch(e => {
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
    })

    
}



export const validateEmail = (params) => async (dispatch) => {

    dispatch(isLoading(true))
    return await FellowShipService.validateEmailId(params).then((response) => {
        dispatch(isLoading(false));
      if(response){
        if (response?.responseCode === 400) {
            toast.error(response?.message);
            return { status: false }
        } {
            // toast.success(response?.message);
            return { status: true,data:response?.data };
        }
      }else{
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
      }
                
    }).catch(e => {
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
    })

    
}