import { combineReducers } from "redux";
import HomeReducer from "../../components/HomePage/dependencies/reducer";
import RutagReducer from "../../components/Rutag/dependencies/reducer";
import ChallengeEventReducer from "../../components/Challenges/dependencies/reducer";
import HeaderReducer from "../../components/common/Header/dependencies/reducer";
import FooterReducer from "../../components/common/Footer/dependencies/reducer";
import UpperFooter from "../../components/common/UpperFooter/dependencies/reducer";
import ContactusReducer from "../../components/ContactUs/dependencies/reducer";
import StaticPageReducer from "../../components/StaticPage/dependencies/reducer";
import EventReducer from "../../components/Events/dependencies/reducer";
import CurrentInterventionReducer from "../../components/CurrentInterventions/dependencies/reducer";
import PastInterventionReducer from "../../components/PastProjects/dependencies/reducer";
import ArticlesReducer from "../../components/ArticlesCaseStudies/dependencies/reducer";
import UpcomingInterventionsReducer from "../../components/UpcomingInterventions/dependencies/reducer";
import FelloshipReducer from "../../components/FellowshipForm/dependencies/reducer";
import PledgeReducer from "../../components/Pledge/dependencies/reducer";
import loader from "./loader.reducer";
import FellowsihpReducer from "../../components/Fellowship/dependencies/reducer";
import LanguageReducer from "../../components/common/Breadcrumbs/dependencies/reducer";
import CitizenCornerReducer from "../../components/CitizenCorner/dependencies/reducer";
import SubmitTechReducer from "../../components/SubmitTechnology/dependencies/reducer";
import PartnerWithUsReducer from "../../components/PartnerWithUs/dependencies/reducer";
import SuccessStoriesReducer from "../../components/SuccessStories_bak/dependencies/reducer";
import AnalyticsDashboardReducer from "../../components/AnalyticsDashboard/dependencies/reducer";
import PolicyReducer from "../../components/Policies/dependencies/reducer";
import KnowledgeManagmentReducer from "../../components/ValidatedTechnologies/dependencies/reducer";
import SwachhtaSarthiHomeReducer from "../../components/SwachhtaSarthiFellowship/dependencies/reducer";
import FellowshipComponentsReducer from "../../components/SwachhtaSarthiFellowship/SwachhtaSarthi/reducer";

const appReducer = combineReducers({
  HomeReducer,
  loader,
  ChallengeEventReducer,
  HeaderReducer,
  FooterReducer,
  UpperFooter,
  ContactusReducer,
  StaticPageReducer,
  EventReducer,
  CurrentInterventionReducer,
  PastInterventionReducer,
  ArticlesReducer,
  UpcomingInterventionsReducer,
  PledgeReducer,
  FelloshipReducer,
  FellowsihpReducer,
  LanguageReducer,
  CitizenCornerReducer,
  SubmitTechReducer,
  PartnerWithUsReducer,
  SuccessStoriesReducer,
  AnalyticsDashboardReducer,
  PolicyReducer,
  KnowledgeManagmentReducer,
  SwachhtaSarthiHomeReducer,
  FellowshipComponentsReducer,
  RutagReducer,
});
export default appReducer;
