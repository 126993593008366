/**
 * This componet use for challenge list with filter
 */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getChallengeEventList,
  getChallengeTechnologyList,
  getChallengeCategoryList,
} from "../dependencies/action";
import LeftSideCategory from "../../../components/common/LeftSideCategory";
import InputField from "../../../components/common/InputField";
import Button from "../../../components/common/Button";
import Breadcrumb from "../../common/Breadcrumbs";
import ListWithPagination from "../../common/ListWithPagination";
import { SortBy } from "../dependencies/constant/searchConstant";
import ReactHtmlParser from "react-html-parser";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import GlobalDropDown from "../../common/GlobalDropDown";
import "./style.scss";
import "../.../../../../../node_modules/bootstrap-less/bootstrap/bootstrap.less";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const items = [
  { to: "/", label: "/" },
  { to: "", label: "What We Do" },
  { to: "/challenge-listing", label: "All Challenges", active: true },
];

const ChallengeEventListing = (props) => {
  const {
    getChallengeEventList,
    getChallengeTechnologyList,
    getChallengeCategoryList,
    challengeEventList,
    challengeTechnologyList,
    challengeCategoryList,
  } = props;
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [pageStatus, setPageStatus] = useState(false);
  const [sortBy, setSortBy] = useState("past_challenges");

  useEffect(() => {
    getChallengeEventList(page, categoryId, search, sortBy);
  }, [page, categoryId, sortBy]);

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setSearch(value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    getChallengeEventList(page, categoryId, search, sortBy);
  };
  useEffect(() => {
    getChallengeTechnologyList();
    getChallengeCategoryList();
  }, []);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);

  const changePageNumber = (pageNumber) => {
    setPageStatus(false);
    setPage((pageNumber - 1) * 7);
  };
  const getCategoryId = (Id) => {
    setCategoryId(Id);
  };
  const handleInput = (e, name) => {
    const { value } = e.target;
    setSortBy(value);
    setPage(0);
    setPageStatus(true);
  };
  return (
    <div className="challenge-list">
      <Breadcrumb data={items} displayName={"Challenge List"} />

      <div className="bottom-section-list">
        <div className="container common-listing">
          <div className="row">
            <div className="col-lg-3 sidebar">
              <div>
                <div className="category-box">
                  <span className="sub-title f19">
                    {ReactHtmlParser(challengeCategoryList?.heading)}
                  </span>
                  {challengeCategoryList &&
                    Array.isArray(challengeCategoryList.list) &&
                    challengeCategoryList.list.length > 0 &&
                    challengeCategoryList.list.map((value, index) => {
                      let Id = value?.id;
                      return (
                        <LeftSideCategory
                          onClickReply={() => {
                            getCategoryId(Id);
                          }}
                          className={
                            value?.id === categoryId ? "category-active" : ""
                          }
                          categoryName={value?.name}
                          count={value?.count}
                          key={index}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-9 list-container">
              <div className="boxWithShadow">
                <div className="row">
                  <div className="col-6">
                    <div className="search">
                      <form onSubmit={(e) => onSubmit(e)}>
                        <InputField
                          type="search"
                          name="search"
                          placeholder="Search Challenges"
                          value={search}
                          onChange={handleInputChange}
                          containerClass={"form-group"}
                          className="subscribe-input-field"
                        />
                        <div className="btn-group">
                          <Button
                            icon={
                              <img
                                src={searchIcon}
                                className="search-icon"
                                alt=""
                              />
                            }
                            type="submit"
                            text=""
                            className="list-submit-button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="event-dropdown">
                      <GlobalDropDown
                        label="Filter By"
                        containerStyle="form-group "
                        selectTagStyle="form-control dropdown-media"
                        onChange={handleInput}
                        options={SortBy}
                        value={sortBy}
                        name={"sortBy"}
                        defaultOptionDisable
                        error=""
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="common-listing-title">
                      {ReactHtmlParser(challengeEventList?.bannerheading)}
                    </div>
                  </div>
                </div>
                <ListWithPagination
                  data={challengeEventList?.list}
                  itemsCountPerPage={7}
                  totalItemsCount={challengeEventList?.totalCount}
                  pageRangeDisplayed={5}
                  checkStatus={pageStatus}
                  onChangePagination={changePageNumber}
                  buttonName={"Apply Now"}
                  nextUrl={"challenge-listing"}
                  type={"challenge-listing"}
                  sortByChallenge={"sortBy"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    challengeEventList: state.ChallengeEventReducer.challengeEventList.data,
    challengeTechnologyList:
      state.ChallengeEventReducer.challengeTechnologyList.data,
    challengeCategoryList:
      state.ChallengeEventReducer.challengeCategoryList.data,
  };
};

export default connect(mapStateToProps, {
  getChallengeEventList,
  getChallengeTechnologyList,
  getChallengeCategoryList,
})(withRouter(ChallengeEventListing));
