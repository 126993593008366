import { combineReducers } from "redux";
import {
  CURRENT_INTERVENTION_LIST,
  INTERVENTION_TECNOLOGY,
  CURRENT_INTERVENTION_DETAILS,
  FILTER_DATA,
  PAST_PROJECTS_LIST,
  PAST_PROJECTS_DETAILS,
} from "../../../constants/actionConstants";

const currentInterventionList = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_INTERVENTION_LIST:
      return { ...action.payload };
    default:
      return state;
  }
};

const interventionTechnology = (state = {}, action) => {
  switch (action.type) {
    case INTERVENTION_TECNOLOGY:
      return { ...action.payload };
    default:
      return state;
  }
};

const pastProjectsList = (state = {}, action) => {
  switch (action.type) {
    case PAST_PROJECTS_LIST:
      return { ...action.payload };
    default:
      return state;
  }
};

const pastProjectsDetails = (state = {}, action) => {
  switch (action.type) {
    case PAST_PROJECTS_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
};

const currentInterventionDetails = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_INTERVENTION_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
};

const filterData = (state = {}, action) => {
  switch (action.type) {
    case FILTER_DATA:
      return { ...action.payload };
    default:
      return state;
  }
};

const PastInterventionReducer = combineReducers({
  currentInterventionList,
  interventionTechnology,
  currentInterventionDetails,
  filterData,
  pastProjectsList,
  pastProjectsDetails,
});

export default PastInterventionReducer;
