import { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import AboutEvent from "../dependencies/component/AboutEvent";
import OverView from "../dependencies/component/OverView";
import GallerySection from "../dependencies/component/GallerySection2";
import ActAndPolicy from "./Acts&Policies";

import EventVideos from "../dependencies/component/EventVideos";
import MeatTeam from "../../StaticPage/dependencies/component/MeatTeam";
import BottomSlider from "../../common/BottomSlider";
import Breadcrumb from "../../common/Breadcrumbs";
import { EVENT_DETAILS } from "../../../constants/ApplicationUrl";
import { getEventDetails, getUpcommingEvents } from "../dependencies/action";

import { initGA, logPageView } from "../../../dependencies/utils/analytics";
import bottomLine from "../../../assests/images/Event Graphic 03.svg";

import "./style.scss";
// import ReactHtmlParser from 'react-html-parser';
const ChallengeEventDetails = (props) => {
  const { getEventDetails, getUpcommingEvents, eventDetails, upcommingEvents } =
    props;
  const myRefs = useRef([]);

  const [aboutEvent, setAboutEvent] = useState(true);
  const [speakers, setSpeakers] = useState(false);
  const [eventVideo, setEventVideo] = useState(false);
  const [agendaEvent, setAgendaEvent] = useState(false);
  const [eventCollateral, setEventCollateral] = useState(false);
  const [eventdata, seteventData] = useState(false);

  const items = [
    { to: "/", label: "/" },
    { to: "/event-listing", label: "All Events" },
  ];

  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: `${EVENT_DETAILS}/${"swachhta-saarthi-samaroh-2022"}`,
      headers: {
        "Content-Type": "application/json",
        // "Content-Disposition": 'file; filename="' + file.name + '"',
      },
      // data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("eventdetails response", response.data.data);
        seteventData(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    let str = window.location.pathname;
    getEventDetails(str.split("/")[2]);
    getUpcommingEvents();
  }, [getEventDetails]);
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });

  useEffect(() => {
    // localStorage.setItem("galleryContent", eventDetails?.event_gallery_images);
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  const checkScrollTop = () => {
    if (window.pageYOffset > 850 && window.pageYOffset < 1547) {
      setAboutEvent(false);
      setSpeakers(false);
      setEventVideo(false);
      setAgendaEvent(true);
      setEventCollateral(false);
    } else if (window.pageYOffset > 1547 && window.pageYOffset < 2117) {
      setAboutEvent(false);
      setSpeakers(true);
      setEventVideo(false);
      setAgendaEvent(false);
      setEventCollateral(false);
    } else if (window.pageYOffset > 2117 && window.pageYOffset < 2845) {
      setAboutEvent(false);
      setSpeakers(false);
      setEventVideo(true);
      setAgendaEvent(false);
      setEventCollateral(false);
    } else if (window.pageYOffset > 2845) {
      setAboutEvent(false);
      setSpeakers(false);
      setEventVideo(false);
      setAgendaEvent(false);
      setEventCollateral(true);
    } else {
      setAboutEvent(true);
      setSpeakers(false);
      setEventVideo(false);
      setAgendaEvent(false);
      setEventCollateral(false);
    }
  };
  const executeScroll = (data) => {
    myRefs.current[data].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="event-detail">
      <Breadcrumb
        data={items}
        displayName={
          eventDetails?.title ? eventDetails?.title : eventdata?.title
        }
        type={"event"}
        eventData={
          eventDetails?.aboutEvent
            ? eventDetails?.aboutEvent
            : eventdata?.aboutEvent
        }
        title={eventDetails?.title ? eventDetails?.title : eventdata?.title}
      />
      <div className="fixed-tabs event-tabs">
        <div className="container">
          <div className="container-wrap">
            <div className="row justify-content-center">
              {/* {eventDetails?.tabs?.about && ( */}
              <div className="col-lg-3">
                <span
                  className={
                    aboutEvent ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(0);
                    }}
                  >
                    {eventDetails?.tabs?.about?.toUpperCase()
                      ? eventDetails?.tabs?.about?.toUpperCase()
                      : eventdata?.tabs?.about?.toUpperCase()}
                  </a>
                </span>
              </div>
              {/* )} */}

              {/* {eventDetails?.tabs?.upcomingEvents && ( */}
              <div className="col-lg-3">
                <span
                  className={
                    agendaEvent ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(1);
                    }}
                  >
                    {/* {eventDetails?.tabs?.upcomingEvents?.toUpperCase()} */}
                    AGENDA
                  </a>
                </span>
              </div>
              {/* )} */}

              {/* {eventDetails?.tabs?.speakers && ( */}
              <div className="col-lg-3">
                <span
                  className={
                    speakers ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(2);
                    }}
                  >
                    {eventDetails?.tabs?.speakers?.toUpperCase()
                      ? eventDetails?.tabs?.speakers?.toUpperCase()
                      : eventdata?.tabs?.speakers?.toUpperCase()}
                  </a>
                </span>
              </div>
              {/* )} */}

              {/* {eventDetails?.tabs?.videos && ( */}
              <div className="col-lg-3">
                <span
                  className={
                    eventVideo ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(3);
                    }}
                  >
                    {/* {eventDetails?.tabs?.videos?.toUpperCase()} */}
                    GALLERY
                  </a>
                </span>
              </div>
              {/* )} */}
              <div className="col-lg-3">
                <span
                  className={
                    eventCollateral ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(4);
                    }}
                  >
                    {/* {eventDetails?.tabs?.videos?.toUpperCase()} */}
                    COLLATERAL
                  </a>
                </span>
              </div>
              {/* {eventDetails?.tabs?.upcomingEvents && (
                <div className="col-lg-3">
                  <span
                    className={
                      upcomingEvent ? "fixed-tab-name active" : "fixed-tab-name"
                    }
                  >
                    <a
                      onClick={() => {
                        executeScroll(3);
                      }}
                    >
                      {eventDetails?.tabs?.upcomingEvents?.toUpperCase()}
                    </a>
                  </span>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div
        id="about-event"
        ref={(el) => (myRefs.current[0] = el)}
        className="CurrentInterventionDetails currdetails"
      >
        <OverView
          data={
            eventDetails?.aboutEvent
              ? eventDetails?.aboutEvent
              : eventdata?.aboutEvent
          }
          mainTitle={
            eventDetails?.title ? eventDetails?.title : eventdata?.title
          }
          //   body={pastProjectsDetails?.data?.body}
          //   category={pastProjectsDetails?.data?.category}
          //   location={props.location.state?.location}
        />
        {/* <AboutEvent
          data={eventDetails?.aboutEvent}
          title={eventDetails?.title}
        /> */}
      </div>
      <div
        id="agenda-event"
        ref={(el) => (myRefs.current[1] = el)}
        style={{ marginTop: "-6%" }}
      >
        <AboutEvent
          data={eventDetails?.agenda ? eventDetails?.agenda : eventdata?.agenda}
          title={
            eventDetails?.agenda?.agenda_title
              ? eventDetails?.agenda?.agenda_title
              : eventdata?.agenda?.agenda_title
          }
        />
      </div>
      <div id="meat-team" ref={(el) => (myRefs.current[2] = el)}>
        {/* <div class="yellow-line" style={{ height: "30%" }}></div>
        <div class="blue-line" style={{ height: "30%", top: "none" }}></div>
        <div class="green-line" style={{ height: "50%" }}></div> */}
        <MeatTeam
          data={
            eventDetails?.Speakers?.speakers
              ? eventDetails?.Speakers?.speakers
              : eventdata?.Speakers?.speakers
          }
          heading={
            eventDetails?.Speakers?.heading
              ? eventDetails?.Speakers?.heading
              : eventdata?.Speakers?.heading
          }
          count={4}
        />
      </div>

      {/* <div id="event-video" ref={(el) => (myRefs.current[3] = el)}>

      </div> */}
      <div
        id="event-video"
        ref={(el) => (myRefs.current[3] = el)}
        className="gallery-wraper"
        style={{ marginTop: "5%" }}
      >
        {/* <div class="yellow-line" style={{ top: "-4%" }}>
          <img src={bottomLine} />
        </div> */}
        {/* <div class="yellow-line" style={{ height: "30%" }}></div>
        <div class="blue-line" style={{ height: "30%", top: "none" }}></div>
        <div class="green-line" style={{ height: "50%" }}></div> */}
        <div class="large-tree"></div>
        <div class="small-tree"></div>
        <div class="bin-car" style={{ right: "13%", bottom: "44em" }}></div>
        <div class="bulb"></div>
        {/* <div class="dotted-hexagon left"></div>
        <div class="dotted-hexagon right"></div> */}
        <GallerySection
          data={
            eventDetails?.event_gallery_images
              ? eventDetails?.event_gallery_images
              : eventdata?.event_gallery_images
          }
          firstvid={
            eventDetails?.event_gallery_images?.gallery_videos?.SSF_Videos &&
            eventDetails?.event_gallery_images?.gallery_videos?.SSF_Videos[0]
              ?.mp4_video
          }
        />
      </div>

      <div
        id="event-video"
        ref={(el) => (myRefs.current[4] = el)}
        className="col-md-9 list-container"
        style={{ maxWidth: "100%", marginTop: "0%", marginBottom: "40%" }}
      >
        <ActAndPolicy
          fieldData={
            eventDetails?.collateral?.collateral_upload
              ? eventDetails?.collateral?.collateral_upload
              : eventdata?.collateral?.collateral_upload
          }
          fieldLink={
            eventDetails?.collateral?.pdf_link
              ? eventDetails?.collateral?.pdf_link
              : eventdata?.collateral?.pdf_link
          }
          //   pressReleaseData={currentInterventionDetails?.field_press_release}
          // subtype={subtype}
          // data={policyListData}
          // onClickTab={handleChange}
        />
      </div>

      {/* {eventDetails?.eventVideos?.videos.length > 0 && (
        <EventVideos data={eventDetails?.eventVideos} />
      )} */}

      {/* <div id="upcomming-event" ref={(el) => (myRefs.current[3] = el)}>
        <AboutEvent
          data={eventDetails?.aboutEvent}
          title={eventDetails?.title}
        />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDetails: state.EventReducer.eventDetails.data,
    upcommingEvents: state.EventReducer.upcommingEvents,
  };
};
export default connect(mapStateToProps, {
  getEventDetails,
  getUpcommingEvents,
})(withRouter(ChallengeEventDetails));
