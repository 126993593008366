import ReactHtmlParser from 'react-html-parser';
import './style.scss';

const SelectionCriteria = (props) => {
    const { data, sectionClass } = props
    return (
        <div className={`selection-criteria ${sectionClass}`}
         >
            <div className='container'>
                <div className='container-wrap'>
                    <div className='row'>
                        <div className='col-sm-3 offset-1 criteria-heading mobile-heading'>
                            <div className='margin-left-22'>{ReactHtmlParser(data?.title)}</div>
                        </div>
                        <div className='col-sm-8 selection-criteria-plate'>
                            <div className='row'>
                                {
                                    data
                                    && Array.isArray(data?.entity)
                                    && data?.entity.length > 0
                                    && data?.entity.map((value, index) => {
                                        return <div key={index} className='criteria-tile col-sm-5'>
                                            <div className='criteria-number'>
                                                #{index + 1}
                                                <span>Criteria</span>
                                            </div>
                                            <div className='criteria-content'>
                                                {ReactHtmlParser(value?.title)}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-sm-3 offset-1 criteria-heading web-heading'>
                            <div className='margin-left-22'>{ReactHtmlParser(data?.title)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectionCriteria;