import api from '../../../dependencies/utils/Api';
import {
    ABOUT_US
} from "../../../constants/ApplicationUrl";

export const StaticPageService = {
    getAboutUs() {
        return api.GET(ABOUT_US)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
}