import React, { useState, useEffect } from "react";
import sharefb from "../../../assests/svg/ic_fb.svg";
import sharetwiter from "../../../assests/svg/ic_twitter.svg";
import sharetinsta from "../../../assests/svg/ic_insta.svg";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import "./style.scss";

const SocialShare = (props) => {
  const { social } = props;
  // console.log('socialsocial',social)
  return (
    <div className="social-share-wrap">
      <FacebookShareButton
        url={social.url}
        quote={social.title}
        sepreater={social.sepreater}
      >
        <img src={sharefb} alt="" width="32" />
      </FacebookShareButton>
      <TwitterShareButton
        url={social?.url}
        title={social.title}
        sepreater={social?.sepreater}
      >
        <img src={sharetwiter} alt="" width="32" />
      </TwitterShareButton>
      <a href="https://www.instagram.com/wasteto.wealth/?hl=en" target="_blank">
        <img src={sharetinsta} alt="" width="32" />
      </a>
      {/* <WhatsappShareButton // check locally with whatsapp 
							url={social.url}
							title={social.title}
							sepreater={social.sepreater}
						>
							<WhatsappIcon size={"2.5rem"} />
						</WhatsappShareButton> */}
    </div>
  );
};

export default SocialShare;
