import {
    validateName,
    validateDate,
    validateDropDown,
    validateCompanyName,
    validateURL,
    validateMobileNo,
    validateEmail,
    validateAddress,
    validateNationality,
    validateTextArea,
    validateNmberOfDetails,
    validateTechnologyName,
    validateOtherThematicArea,
    validateCompanyAddress,
    validateDetailsTextArea,
    validatethreatsTextArea,
    validateopportunitiesTextArea,
    validatestrengthTechnoSTextArea,
    validateweeknessTechnoTextArea,
    validateTechCertifiedTextArea,
    validateTechpatentedTextArea,
    validateMadeIninidaTextArea,
    validateTechStepsTextArea,
    validateTechIOTextArea,
    validatecurrentDeploymentTextArea,
    validateTechDetailsTextArea,
    validateTechnologyEndoresedTextArea,
    validatetypeAndFrequencyOfReplacementTextArea
} from '../../../dependencies/utils/validation';
const validation = {
    validateSubmitTechForm: (params) => {
        const {
            name,
            date,
            // technology,
            company,
            techDetails,
            currentDeployment,
            websiteURL,
            phone,
            email,
            address,
            // nationality,
            technologyReadiness,
            technologyEndorsed,
            productModular,
            remotelyMonitored,
            skilledPersonnel,
            geographicTerrains,
            technologyCertified,
            technologyPatented,
            // madeInIndia,
            techSteps,
            techInputOutput,
            techCivilStructuer,
            systemReplacement,
            technologyListed,
            // annualPowerCost,
            // annualConsumableCost,
            // annualMaintenance,
            // annualManCost,
            timeRequired,
            // detailsOfServicing,
            typeAndFrequencyOfReplacement,
            technologyName,
            otherThematicArea,
            productCompletelyManufactured,
            compAddress,
            // compWebsite,
            studEmail,
            detailsOfSupport,
            threats,
            opportunities,
            strengthTechno,
            weeknessTechno,
            limitationTechnology,
            // annualOpex
        } = params;
        let formStatus = {};

        const nameStatus = validateName(name);
        const dateStatus = validateDate(date);
        // const technologyStatus = validateDropDown(technology);
        const companyStatus = validateCompanyName(company);
        const techDetailsStatus = validateTechDetailsTextArea(techDetails, 100, 500);
        const currentDeploymentStatus = validatecurrentDeploymentTextArea(currentDeployment, 100, 500)
        const websiteURLStatus = validateURL(websiteURL);
        const phoneStatus = validateMobileNo(phone);
        const emailStatus = validateEmail(email);
        const addressStatus = validateAddress(address);
        // const nationalityStatus = validateNationality(nationality);
        const technologyReadinessStatus = validateDropDown(technologyReadiness);
        const technologyEndorsedStatus = validateTechnologyEndoresedTextArea(technologyEndorsed, 0, 200);
        const productModularStatus = validateDropDown(productModular);
        const remotelyMonitoredStatus = validateDropDown(remotelyMonitored);
        const skilledPersonnelStatus = validateDropDown(skilledPersonnel);
        const geographicTerrainsStatus = validateDropDown(geographicTerrains);
        const technologyCertifiedStatus = validateTechCertifiedTextArea(technologyCertified, 0, 200);
        const technologyPatentedStatus = validateTechpatentedTextArea(technologyPatented, 0, 200);
        // const madeInIndiaStatus = validateMadeIninidaTextArea(madeInIndia, 0, 100);
        const techStepsStatus = validateTechStepsTextArea(techSteps, 100, 3500);
        const techInputOutputStatus = validateTechIOTextArea(techInputOutput, 0, 200);
        const techCivilStructuerStatus = validateTextArea(techCivilStructuer, 50, 100);
        const systemReplacementStatus = validateDropDown(systemReplacement);
        const technologyListedStatus = validateDropDown(technologyListed);
        // const annualPowerCostStatus = validateNmberOfDetails(annualPowerCost);
        // const annualConsumableCostStatus = validateNmberOfDetails(annualConsumableCost);
        // const annualOpexStatus= validateNmberOfDetails(annualOpex);
        // const annualMaintenanceStatus = validateNmberOfDetails(annualMaintenance);
        // const annualManCostStatus = validateNmberOfDetails(annualManCost);
        const timeRequiredStatus = validateNmberOfDetails(timeRequired);
        // const detailsOfServicingStatus = validateTextArea(detailsOfServicing, 0, 200);
        const typeAndFrequencyOfReplacementStatus = validatetypeAndFrequencyOfReplacementTextArea(typeAndFrequencyOfReplacement, 0, 1500,systemReplacement);
        const technologyNameStatus = validateTechnologyName(technologyName);
        const otherThematicAreaStatus = validateOtherThematicArea(otherThematicArea, 0,100);
        const productCompletelyManufacturedStatus = validateTextArea(productCompletelyManufactured, 0, 100);
        const limitationTechnologyStatus = validateTextArea(limitationTechnology, 0, 200);

        const detailsOfSupportStatus = validateDetailsTextArea(detailsOfSupport, 1, 300);
        const threatsStatus = validatethreatsTextArea(threats, 1, 300);
        const opportunitiesStatus = validateopportunitiesTextArea(opportunities, 1, 300);
        const strengthTechnoStatus = validatestrengthTechnoSTextArea(strengthTechno, 1, 300);
        const weeknessTechnoStatus = validateweeknessTechnoTextArea(weeknessTechno, 1, 300);

        const studEmailStatus = validateEmail(studEmail);
        const compAddressStatus = validateCompanyAddress(compAddress);
        // const compWebsiteStatus = validateURL(compWebsite);



        formStatus = {
            name: nameStatus.error,
            date: dateStatus.error,
            // technology: technologyStatus.error,
            company: companyStatus.error,
            techDetails: techDetailsStatus.error,
            currentDeployment: currentDeploymentStatus.error,
            websiteURL: websiteURLStatus.error,
            phone: phoneStatus.error,
            email: emailStatus.error,
            address: addressStatus.error,
            // nationality: nationalityStatus.error,
            technologyReadiness: technologyReadinessStatus.error,
            technologyEndorsed: technologyEndorsedStatus.error,
            productModular: productModularStatus.error,
            remotelyMonitored: remotelyMonitoredStatus.error,
            skilledPersonnel: skilledPersonnelStatus.error,
            geographicTerrains: geographicTerrainsStatus.error,
            technologyCertified: technologyCertifiedStatus.error,
            technologyPatented: technologyPatentedStatus.error,
            // madeInIndia: madeInIndiaStatus.error,
            techSteps: techStepsStatus.error,
            techInputOutput: techInputOutputStatus.error,
            techCivilStructuer: techCivilStructuerStatus.error,
            systemReplacement: systemReplacementStatus.error,
            technologyListed: technologyListedStatus.error,
            // annualPowerCost: annualPowerCostStatus.error,
            // annualConsumableCost: annualConsumableCostStatus.error,
            // annualMaintenance: annualMaintenanceStatus.error,
            // annualManCost: annualManCostStatus.error,
            timeRequired: timeRequiredStatus.error,
            // detailsOfServicing: detailsOfServicingStatus.error,
            typeAndFrequencyOfReplacement: typeAndFrequencyOfReplacementStatus.error,
            technologyName: technologyNameStatus.error,
            otherThematicArea: otherThematicAreaStatus.error,
            productCompletelyManufactured: productCompletelyManufacturedStatus.error,
            detailsOfSupport: detailsOfSupportStatus.error,
            threats: threatsStatus.error,
            opportunities: opportunitiesStatus.error,
            strengthTechno: strengthTechnoStatus.error,
            weeknessTechno: weeknessTechnoStatus.error,
            studEmail: studEmailStatus.error,
            compAddress: compAddressStatus.error,
            // compWebsite: compWebsiteStatus.error,
            limitationTechnology:limitationTechnologyStatus.error,
            // annualOpex:annualOpexStatus.error,

            hasErrors: (
                nameStatus.hasError ||
                dateStatus.hasError ||
                // technologyStatus.hasError ||
                companyStatus.hasError ||
                techDetailsStatus.hasError ||
                currentDeploymentStatus.hasError ||
                websiteURLStatus.hasError ||
                phoneStatus.hasError ||
                emailStatus.hasError ||
                addressStatus.hasError ||
                // nationalityStatus.hasError ||
                technologyReadinessStatus.hasError ||
                technologyEndorsedStatus.hasError ||
                productModularStatus.hasError ||
                remotelyMonitoredStatus.hasError ||
                skilledPersonnelStatus.hasError ||
                geographicTerrainsStatus.hasError ||
                technologyCertifiedStatus.hasError ||
                technologyPatentedStatus.hasError ||
                // madeInIndiaStatus.hasError ||
                techStepsStatus.hasError ||
                techInputOutputStatus.hasError ||
                techCivilStructuerStatus.hasError ||
                systemReplacementStatus.hasError ||
                technologyListedStatus.hasError ||
                // annualPowerCostStatus.hasError ||
                // annualConsumableCostStatus.hasError ||
                // annualMaintenanceStatus.hasError ||
                // annualManCostStatus.hasError ||
                timeRequiredStatus.hasError ||
                // detailsOfServicingStatus.hasError ||
                typeAndFrequencyOfReplacementStatus.hasError ||
                technologyNameStatus.error ||
                otherThematicAreaStatus.error ||
                productCompletelyManufacturedStatus.error ||
                detailsOfSupportStatus.error ||
                threatsStatus.error ||
                opportunitiesStatus.error ||
                strengthTechnoStatus.error ||
                weeknessTechnoStatus.error ||
                studEmailStatus.error ||
                compAddressStatus.error ||
                // compWebsiteStatus.error ||
                limitationTechnologyStatus.error
                // annualOpexStatus.error

            )
        };
        return formStatus;
    }

};


export default validation;