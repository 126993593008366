import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { getAboutUs } from "../dependencies/action";
import OurMission from "../dependencies/component/OurMission";
import OurVision from "../dependencies/component/OurVision";
import OurReport from "../dependencies/component/OurReport";
import Partners from "../dependencies/component/Partners";
import MeatTeam from "../dependencies/component/MeatTeam";
import Breadcrumb from "../../common/Breadcrumbs";
import { useEffect, useState, useRef } from "react";
import "../style.scss";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const items = [
  { to: "/", label: "/" },
  { to: "/about-us", label: "About Us" },
];
const AboutUs = (props) => {
  console.log(props);
  const { getAboutUs, aboutUsData } = props;
  const myRefs = useRef([]);
  //   const location = useLocation();
  //   const { linkname } = location.state;

  const [ourmission, setOurmission] = useState(false);
  const [ourvision, setOurvision] = useState(false);
  const [meettheteam, setMeettheteam] = useState(false);
  const [partners, setPartners] = useState(false);
  const [portal, setPortal] = useState(false);
  //   const [techScroll, setTechScroll] = useState("");

  const executeScroll = (data) => {
    myRefs.current[data].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
    // myRefs.current[data].scrollIntoView({ behavior: "smooth"})

    // myRefs.current[data].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  };

  useEffect(() => {
    if (
      props.location.state !== undefined &&
      props.location.state.techName === "hello"
    ) {
      //   alert("clicked on meet the team");
      window.scrollTo({
        // top: 4138,
        top: 3995,
        behavior: "smooth",
      });
    } else if (
      props.location.state !== undefined &&
      props.location.state.techName === "hello2"
    ) {
      //   alert("clicked on about us");
      window.scrollTo({
        top: 261,
        behavior: "smooth",
      });
    } else {
      //   alert("no options");
      executeScroll(0);
    }

    getAboutUs();
  }, [props.location.state]);

  useEffect(() => {
    // to call the initialise google analytics funtion
    try {
      initGA();
      window.GA_INITIALIZED = true;
      // to save the number og visitors on this side
      logPageView();
    } catch (error) {}
  }, [window]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });
  const checkScrollTop = () => {
    // console.log("window.pageYOffset", window.pageYOffset);
    if (window.pageYOffset > 0 && window.pageYOffset < 850) {
      setMeettheteam(false);
      setOurvision(false);
      setOurmission(true);
      setPortal(false);
      setPartners(false);
    } else if (window.pageYOffset > 850 && window.pageYOffset < 1547) {
      setOurvision(true);
      setOurmission(false);
      setMeettheteam(false);
      setPartners(false);
      setPortal(false);
    } else if (window.pageYOffset > 1547 && window.pageYOffset < 3317) {
      setPartners(true);
      setOurvision(false);
      setOurmission(false);
      setMeettheteam(false);
      setPortal(false);
    } else if (window.pageYOffset > 3317 && window.pageYOffset < 3845) {
      setPortal(true);
      setPartners(false);
      setMeettheteam(false);
      setOurvision(false);
      setOurmission(false);
    } else if (window.pageYOffset > 3845) {
      setMeettheteam(true);
      setPortal(false);
      setOurvision(false);
      setOurmission(false);
      setPartners(false);
    }
  };

  return (
    <div className="about-us">
      <Breadcrumb data={items} displayName={"About Us"} />
      <div className="fixed-tabs">
        <div className="container">
          <div className="container-wrap">
            <div className="row justify-content-center">
              <div className="col-lg-3">
                <span
                  className={
                    ourmission ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(0);
                    }}
                  >
                    {aboutUsData?.tabs?.mission}
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    ourvision ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(1);
                    }}
                  >
                    {aboutUsData?.tabs?.vision}
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    partners ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(2);
                    }}
                  >
                    {aboutUsData?.tabs?.partners}
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    portal ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(3);
                    }}
                  >
                    {aboutUsData?.tabs?.portal}
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    meettheteam ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(4);
                    }}
                  >
                    {aboutUsData?.tabs?.team}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="ourmission" ref={(el) => (myRefs.current[0] = el)}>
        <OurMission data={aboutUsData?.our_mission} />
      </div>
      <div
        className="our-vision-bg"
        id="ourvision"
        ref={(el) => (myRefs.current[1] = el)}
      >
        {" "}
        <OurVision data={aboutUsData?.our_vision} />
      </div>
      <div
        className="partners-bg"
        id="partners"
        ref={(el) => (myRefs.current[2] = el)}
      >
        <Partners data={aboutUsData?.our_partners} />
      </div>
      <div
        className="our-portal-bg"
        id="portal"
        ref={(el) => (myRefs.current[3] = el)}
      >
        {" "}
        <OurReport data={aboutUsData?.Our_Portal} />
      </div>
      <div id="meettheteam" ref={(el) => (myRefs.current[4] = el)}>
        <MeatTeam
          data={aboutUsData?.meet_team?.members}
          heading={aboutUsData?.meet_team?.heading}
          description={aboutUsData?.meet_team?.description}
          count={3}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    aboutUsData: state.StaticPageReducer.aboutUs.data,
  };
};
export default connect(mapStateToProps, { getAboutUs })(withRouter(AboutUs));
