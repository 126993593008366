import { combineReducers } from 'redux';
import {
    FELLOWSHIP_DETAILS,
    CURRENT_INTERVENTION_LIST,
    INTERVENTION_TECNOLOGY,
} from '../../../constants/actionConstants';


const currentInterventionList = (state = {}, action) => {
    switch (action.type) {
        case CURRENT_INTERVENTION_LIST:
            return { ...action.payload };
        default:
            return state;
    }
}

const technologyType = (state = {}, action) => {
    switch (action.type) {
        case INTERVENTION_TECNOLOGY:
            return { ...action.payload };
        default:
            return state;
    }
}

const fellowshipDetails = (state = {}, action) => {
    switch (action.type) {
        case FELLOWSHIP_DETAILS:
            return { ...action.payload };
        default:
            return state;
    }
}
const FellowsihpReducer = combineReducers({
    currentInterventionList,
    technologyType,
    fellowshipDetails
});

export default FellowsihpReducer;


