import { contactUsService } from './service';
import { isLoading } from '../../../dependencies/action/action';
import { StaticPageService } from '../../StaticPage/dependencies/service'
import {
    CONTACT_US_DATA,
    ABOUT_US
} from '../../../constants/actionConstants';
import { toast } from "react-toastify";

export const uploadFile = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await contactUsService.uploadFile(params).then((response) => {
        dispatch(isLoading(false));
        if (response.status === 201) {
            return { status: true, attachfile: response.data.fid[0].value, filename: response.data.filename[0].value };

        } else {
            dispatch(isLoading(false));
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const submitContactUs = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await contactUsService.submitContactUs(params).then((response) => {
        dispatch(isLoading(false));
        const { status, data } = response || {}
        if (status === 200) {
            toast.success('Thank you for contacting us');
            return { status: true };
        } else {
            dispatch(isLoading(false));
            toast.error('Something Went Wrong');
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
    })
}

export function contactUsData() {
    return async function (dispatch) {
        let contactUsData = await contactUsService.contactUsData();
        dispatch({
            type: CONTACT_US_DATA,
            payload: contactUsData
        })
    }
} 


export const subscribeMission = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await contactUsService.subscribeMission(params).then((response) => {
        dispatch(isLoading(false));
        // debugger
        const { responseCode, message } = response || {}
        if (responseCode === 200) {
            toast.success(message);
            
        } else {
            dispatch(isLoading(false));
            toast.error(message);
           
        }
    }).catch(e => {
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
    })
}

export function getAboutUs() {
    return async function (dispatch) {
      dispatch(isLoading(true))
      let getAboutUs = await StaticPageService.getAboutUs()
      dispatch({
        type: ABOUT_US,
        payload: getAboutUs,
      })
      dispatch(isLoading(false))
    }
  }
  
