import api from '../../../dependencies/utils/Api';
import {
    FELLOWSHIP_DETAILS,
    FELLOWSHIP_INTERVENTION_LIST,
    FELLOWSHIP_INTERVENTION_TECNOLOGY
} from "../../../constants/ApplicationUrl";

export const FellowshipService = {
    
    getCurrentInterventionList(page, categoryId, city, search , subtheme, state ) {
        return api.GET(`${FELLOWSHIP_INTERVENTION_LIST}?page=${page}&category=${categoryId}&language=&search=${search}`)
            .then((response) => {
                const { data = { data } = {} } = response.data || {};
              
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getTechnologyType() {
        return api.GET(FELLOWSHIP_INTERVENTION_TECNOLOGY)
            .then((response) => {
                const { data = { data } = {} } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getFellowshipDetails(url, lang) {
        return api.GET(`${FELLOWSHIP_DETAILS}/${url}&language=${lang}`)
            .then((response) => {
                const { data: { data } = {} } = response || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    
}