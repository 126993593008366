/**
 * Current invervention details page
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DeployedTechnologies from "../dependencies/component/DeployedTechnologies";
import ExpectedImpact from "../dependencies/component/ExpectedImpact";
import SimilarIntervention from "../dependencies/component/SimilarInterventions";
import OverView from "../dependencies/component/OverView";
import Breadcrumb from "../../common/Breadcrumbs";
import GallerySection from "../dependencies/component/GallerySection2";
import ActAndPolicy from "./Acts&Policies";

import { getCurrentInterventionDetails } from "../dependencies/action";
import { useEffect, useState } from "react";
import SalientFeatures from "../dependencies/component/SalientFeatures";
import ViewPartner from "../dependencies/component/ViewPartner";
import "./style.scss";

import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const items = [
  { to: "/", label: "/" },
  { to: "/current-intervention-listing", label: "What We Do" },
  { to: "/current-intervention-listing", label: "Our Sentinels" },
  {
    to: "/current-intervention-listing",
    label: "Current Projects",
    active: true,
  },
];
const CurrentInterventionsDetails = (props) => {
  const { getCurrentInterventionDetails, currentInterventionDetails } = props;

  const [videodata, setVideodata] = useState({});
  useEffect(() => {
    // const vdeoData = localStorage.getItem("videoData");
    // console.log("vdeodata", JSON.parse(vdeoData));
    // setVideodata(JSON.parse(vdeoData));

    let str = window.location.pathname;
    getCurrentInterventionDetails(str.split("/")[2]);
  }, [window.location.pathname]);

  useEffect(() => {
    console.log(
      "current intevention gallery",
      currentInterventionDetails?.gallery
    );
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  return (
    <div className="CurrentInterventionDetails currdetails">
      <Breadcrumb
        data={items}
        displayName={currentInterventionDetails?.mainTitle}
        type={"currentintervention"}
      />

      <OverView
        data={currentInterventionDetails?.overView}
        mainTitle={currentInterventionDetails?.mainTitle}
        body={currentInterventionDetails?.body}
        category={currentInterventionDetails?.category}
      />

      {currentInterventionDetails?.salient_features &&
        currentInterventionDetails?.salient_features?.features && (
          <SalientFeatures
            data={currentInterventionDetails?.salient_features}
          />
        )}
      {currentInterventionDetails?.deployed_technologies &&
        currentInterventionDetails?.deployed_technologies?.list && (
          <DeployedTechnologies
            data={currentInterventionDetails?.deployed_technologies}
          />
        )}
      {currentInterventionDetails?.expected_data &&
        currentInterventionDetails?.expected_data?.heading !== null && (
          <ExpectedImpact data={currentInterventionDetails?.expected_data} />
        )}
      {/* {currentInterventionDetails?.similar_interventions &&
        currentInterventionDetails?.similar_interventions?.list && (
          <SimilarIntervention
            data={currentInterventionDetails?.similar_interventions}
          />
        )} */}
      {currentInterventionDetails?.gallery &&
        currentInterventionDetails?.gallery?.gallery_images.length !== 0 &&
        currentInterventionDetails?.gallery?.gallery_videos.length !== 0 && (
          <div className="gallery-wraper" style={{ marginTop: "5%" }}>
            <div class="yellow-line" style={{ height: "30%" }}></div>
            <div class="blue-line" style={{ height: "30%", top: "none" }}></div>
            <div class="green-line" style={{ height: "50%" }}></div>
            <div class="large-tree"></div>
            <div class="small-tree"></div>
            <div class="bin-car" style={{ right: "13%", bottom: "44em" }}></div>
            <div class="bulb"></div>
            <div class="dotted-hexagon left"></div>
            <div class="dotted-hexagon right"></div>
            <GallerySection data={currentInterventionDetails?.gallery} />
          </div>
        )}

      {currentInterventionDetails?.field_reports_upload &&
        currentInterventionDetails?.field_press_release &&
        currentInterventionDetails?.field_reports_upload.length !== 0 &&
        currentInterventionDetails?.field_press_release.length !== 0 && (
          <div
            className="col-md-9 list-container"
            style={{ maxWidth: "100%", marginTop: "5%", marginBottom: "20%" }}
          >
            <ActAndPolicy
              fieldData={currentInterventionDetails?.field_reports_upload}
              pressReleaseData={currentInterventionDetails?.field_press_release}
              // subtype={subtype}
              // data={policyListData}
              // onClickTab={handleChange}
            />
          </div>
        )}

      {currentInterventionDetails?.viewPartners &&
        currentInterventionDetails?.viewPartners?.list.length !== 0 && (
          <div className="partner-container" style={{ marginTop: "-10%" }}>
            {/* <div class="yellow-line"></div>
          <div class="blue-line"></div>
          <div class="green-line"></div> */}
            <div class="recycle two"></div>
            <ViewPartner data={currentInterventionDetails?.viewPartners} />
          </div>
        )}
      <div className="pressmaindiv" style={{ marginBottom: "3%" }}></div>
      <div className="pressmaindiv2" style={{ marginBottom: "0%" }}></div>
      {currentInterventionDetails?.similar_interventions &&
        currentInterventionDetails?.similar_interventions?.list && (
          <SimilarIntervention
            data={currentInterventionDetails?.similar_interventions}
          />
        )}
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("intervention details", state);
  return {
    currentInterventionDetails:
      state.CurrentInterventionReducer.currentInterventionDetails.data,
  };
};
export default connect(mapStateToProps, { getCurrentInterventionDetails })(
  withRouter(CurrentInterventionsDetails)
);
