import { FellowshipService } from './service';
import {
    FELLOWSHIP_DETAILS,
    CURRENT_INTERVENTION_LIST,
    INTERVENTION_TECNOLOGY,

} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';

// export const getFelloshipDetails = (params) => dispatch => {
//     dispatch(isLoading(true))
//     return getBindData(params).then((data) => {
//         const { challengeList } = data;
//         if (challengeList) {
//             dispatch({
//                 type: FELLOWSHIP_DETAILS,
//                 payload: challengeList
//             })
//         }
//         dispatch(isLoading(false))
//     })

// }



// async function getBindData({ pageNo }) {
//     const challengeList = await FellowshipService.getFellowshipDetails();
//     console.log("challengeList-======", challengeList)
//     return { challengeList }
// }

export function getChallengeEventList(page, categoryId, city, search, subtheme, state) {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let currentInterventionList = await FellowshipService.getCurrentInterventionList(page, categoryId, city, search ,subtheme, state);
        dispatch({
            type: CURRENT_INTERVENTION_LIST,
            payload: currentInterventionList
        })
        dispatch(isLoading(false))
    }
}

export function getTechnologyType() {
    return async function (dispatch) {
        let technologyType = await FellowshipService.getTechnologyType();
        dispatch({
            type: INTERVENTION_TECNOLOGY,
            payload: technologyType
        })
    }
}


export const getFelloshipDetails = (url, lang) => async (dispatch) =>{
    
    dispatch(isLoading(true)) 
    let fellowshipDetails = await FellowshipService.getFellowshipDetails(url, lang);

    dispatch({
        type: FELLOWSHIP_DETAILS,
        payload: fellowshipDetails
    })
    dispatch(isLoading(false))

}
