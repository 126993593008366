export const API_URL = () => process.env.REACT_APP_BASE_URL;
export const USER_LIST = `${API_URL()}/users`;
export const UPLOAD_FILE = `${API_URL()}/file/upload/node/contact/field_attach_file`;
export const SUBMIT_CONTACT_US = `${API_URL()}/api/v1/submit/contact`;
export const CHALLANGE_EVENT_LISTING = `${API_URL()}/wtw/api/v1/challenge-event-listing?path_alias=/alias`;
export const CHALLANGE_EVENT_DETAILS = `${API_URL()}/wtw/api/v1/challenge-event-detail`;
export const HEADER_DATA = `${API_URL()}/wtw/api/v1/header`;
export const CURRENT_INTERVENTION = `${API_URL()}/wtw/api/v1/homepage-currentinterventions`;
export const QUOTES = `${API_URL()}/wtw/api/v1/homepage-quotes?language=en&path_alias=/home`;
export const FEATURED_TECHNOLOGY = `${API_URL()}/wtw/api/v1/homepage-technology`;
export const BREADCRUMBS_DATA = `${API_URL()}/wtw/api/v1/`;
export const FOOTER_DATA = `${API_URL()}/wtw/api/v1/footer`;
export const MAJOR_INVESTORS = `${API_URL()}/wtw/api/v1/homepage-major-investor?path_alias=/home`;

export const FETCH_PROJECTS_LIST = `https://reqres.in/api/users?page=`;// FAKE MOCK REST API 

