import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import DeployedTechnologyModal from '../../../../CurrentInterventions/dependencies/component/Modal/index'
import './style.scss';
import dummyMissionImage from '../../../../../assests/images/dummy_img.png';

const TechnologyIntervention = (props) => {
    // const { item, count, data, SectionId } = props;
    const { data }= props;
    const [showModal, setShowModal] = useState(false)
    const [modalItem, setModalItem] = useState(false)
    const [titleValue, setTitleValue] = useState(false)

    const handleReadMore = (item,title) => {
        setModalItem(item)
        setShowModal(true)
        setTitleValue(title)
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    variableWidth: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,

                }
            },
            {
                breakpoint: 481,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            },
        ]
    };
    return (
        <div className="technology-intervention custom-slider-nextPrev">
            <div className="large-tree" />
            <div className="small-tree" />
            <div className='container'>
                <div className="row">
                    <div className="col-12 d-flex quiz-header">
                        <h2>{data?.heading}</h2>
                    </div>
                </div>
               
                <Slider
                    {...settings}
                >
                    {data && data?.list && data?.list.length>0 &&  data?.list.map(item => (
                        <div className="row">
                            <div className="col">
                                <div className="item hover-zoom">
                                    <div className="image">
                                        <img src={item.image} />
                                    </div>
                                    <h5>{item.title}</h5>
                                    {ReactHtmlParser(item.description)}
                                    <div className="action">
                                    <button className='green-btn tech-btn' onClick={() => handleReadMore(item?.deployData,item?.title)}>{item?.linkTxt} </button>

                                        {/* <Link to={`/validated-technologies-listing${item?.link}`} className="green-btn">{item?.linkTxt}</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                
            </div>
            <DeployedTechnologyModal
                heading={titleValue}
                item={modalItem}
                isOpen={showModal}
                onClose={() => {
                    setShowModal(false)
                }} />
        </div>
    );
}

export default TechnologyIntervention;