import axios from 'axios';

export const httpClient = axios.create();
httpClient.defaults.timeout = 5 * 60 * 1000;


const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
};

const configHeader = {
    headers: {
            'content-type':'application/octet-stream',
            
'Content-Disposition':'file'
    },
        responseType: 'stream',
    'content-type':'application/octet-stream'
}
const configData = {
    
    headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
        
    }
}
const downloadHeader = {
    headers: {
        'Accept': 'text/csv',
        "Access-Control-Allow-Origin": "*"

    }
}
class Api {
    POST(url, payload = {}, config = axiosConfig) {
        return httpClient.post(url, payload, config).catch(function (error) {
        });
    }

    GET(url, config = axiosConfig) {
        return httpClient.get(url, config).catch(function (error) {
        });
    }
    POSTDATA(url, payload = {}, config = configData) {
        return httpClient.post(url, payload, config).catch(function (error) {
        });
    }
    POSTFILEDATA(url, payload = {}, config = configHeader) {
        return httpClient.post(url, payload, {headers: {
            'content-type':'application/octet-stream',
'Content-Disposition':'file'
    },}).catch(function (error) {
        });
    }
    
    DELETE(url, config = axiosConfig) {
        return httpClient.delete(url, config).catch(function (error) {  
          
        });
    }
    DOWNLOADDATA(url, payload = {}, config = downloadHeader) {
        return httpClient.post(url, payload, config).catch(function (error) {
        })
    }

    DOWNLOADGET(url, config = downloadHeader) {
        return httpClient.get(url, config).catch(function (error) {
        })
    }
}

export default new Api();