import React, { useEffect, useState, useLayoutEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import Accordion from "react-tiny-accordion";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import faq from "../../../../../assests/svg/faq-image.svg";
import SocialShare from "../../../../common/SocialShare";
import { Parallax } from "react-scroll-parallax";
import { useController } from "react-scroll-parallax";

import "./style.scss";

const FAQs = (props) => {
  const { parallaxController } = useController();
  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);
  const { data, SectionId } = props;
  let selectedIndex = 0;
  const [social] = useState({
    url: `${window.location.host}/citizen-corner/${SectionId}`,
    title: data?.heading,
    sepreater: "::",
    size: "2.5rem",
  });
  return (
    <div className="faq-container">
      <div className="yellow-line" />
      <div className="blue-line" />
      <div className="green-line" />
      {/* <Parallax className="blue-line" y={['-15px', '3px']} tagOuter="div">
                <div className="blue-line-img" />
            </Parallax>
            <Parallax className="green-line" y={['-20px', '1px']} tagOuter="div">
                <div className="green-line-img" />
            </Parallax> */}
      <Parallax className="green-leaf" x={["-50px", "50px"]} tagOuter="div">
        <div className="green-leaf-img" />
      </Parallax>
      <Parallax className="white-leaf" y={["-50px", "60px"]} tagOuter="div">
        <div className="white-leaf-img" />
      </Parallax>
      <div className="boy-bottle" />
      <div className="girl" />
      <div className="recycle one animation-spin-2" />
      <div className="recycle two animation-spin-3" />
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex quiz-header">
            <h3>{ReactHtmlParser(data?.heading)}</h3>
            <div className="citizen-social-share margin-left-auto">
              <SocialShare social={social} />
            </div>
          </div>
        </div>
        <div className="row">
          {/*<div className="col-6">
                        <span className="faq-image">
                            <img src={faq} />
                        </span>
                    </div>*/}
          <div className="col">
            <div className="accrodions">
              <Accordion className="accordion">
                {data != undefined &&
                  data?.faq?.length > 0 &&
                  data?.faq.map((item, index) => {
                    return (
                      <div data-header={item.Title}>
                        <div
                          className={`content ${
                            item.Body && item.Image ? "img-content" : ""
                          }`}
                        >
                          {item.Image && <img src={item.Image} alt="" />}
                          {item.Body && (
                            <div className="faq-content">
                              {ReactHtmlParser(item.Body)}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </Accordion>
            </div>
            <div className="view-all-wrap d-flex justify-content-end">
              <a
                href={data?.faq_cta?.url}
                target={data?.faq_cta?.target}
                className="d-flex align-items-center green-circle-link"
              >
                {data?.faq_cta?.title}
                <i className="circle-goto-link-green" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
