import ReactHtmlParser from 'react-html-parser';
import './style.scss';
import dummyMissionImage from '../../../../../assests/images/dummy_img.png';

const OurVision = (props) => {
    const { data } = props
    return (
        <div className="OurVision">
            <div className='container'>
                <div className='container-wrap'>
                    <div className="row">
                        <div className="col-12">
                            <div className='vision-heading'>{ReactHtmlParser(data?.heading)}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <ul className='vision-content'>
                                {ReactHtmlParser(data?.body)}
                            </ul>
                        </div>
                        <div className='col-lg-6'>
                            <img src={data?.image} className='vision-image' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurVision;