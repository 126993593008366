import { HeaderService } from './service';
import {
    MAJOR_INVESTORS
} from '../../../../constants/actionConstants';
export const getUpperFooterData = () => async (dispatch) => {

    let upperFooterData = await HeaderService.getUpperFooter();
   
    dispatch({
        type: MAJOR_INVESTORS,
        payload: upperFooterData
    })
}