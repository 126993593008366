import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, useParams, Redirect } from "react-router-dom";
import InputField from "../../common/InputField";
import InputFieldDate from "../../common/InputField";
import GlobalDropDown from "../../common/GlobalDropDown";
import Button from "../../common/Button";
import Radio from "../../common/FormElements/Radio";
import Checkbox from "../../common/FormElements/Checkbox";
import { changeLanguage } from "../../../dependencies/utils/language/index";
import FormValidator from "../dependencies/formValidator";
import InlineError from "../../common/InlineError";
import SuccessModal from "../../common/SuccessModal";
import banner from "../../../assests/images/pledge-banner-dummy.png";
import {
  submitCitizenPledgeData,
  getPledgeData,
  validateEmail,
} from "../dependencies/action";
import * as DomParser from "dom-parser";
import ReadPledge from "../ReadPledge";
import { toast } from "react-toastify";
import CertificatePage from "../CertificatePage";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../dependencies/utils/constants";

import "../style.scss";

const CitizenPledgeCorner = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { url } = useParams();
  // const { categoryName, category, language, classList, stateList, wasteCategory, uploadFileBank, uploadFileEngage, submitCategory } = props;
  const {
    categoryName,
    category,
    validateEmail,
    language,
    submitCitizenPledgeData,
    getPledgeData,
    PledgeData,
    numberOfVistiors,
  } = props;
  const defaultFormData = {
    name: "",
    phone: "",
    disclaimer: 1,
    languageList: "",
    salutation: "",
    dob: "",
    // country: '',
    state: "",
    city: "",
    pincode: "",
    email: "",
    gender: "",
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [redirect, setRedirect] = useState(false);
  const [readPagePledge, setReadPagePledge] = useState(false);
  const [readPagePledgeDesc, setReadPagePledgeDesc] = useState("");
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] =
    useState(false);
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState(false);
  const [capthaError, setCapthaError] = useState(false);
  const recaptchaRef = useRef(null);
  const salutationList = [
    { value: "Mr", text: "Mr" },
    { value: "Mrs", text: "Mrs" },
    { value: "Shri", text: "Shri" },
    { value: "Smt", text: "Smt" },
    { value: "Kumari", text: "Kumari" },
    { value: "Dr", text: "Dr" },
    { value: "Master", text: "Master" },
    { value: "Miss", text: "Miss" },
  ];
  const languageList = [
    { value: "en", text: "English" },
    { value: "hi", text: "Hindi" },
  ];
  var parser = new DomParser();

  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };

  const validateInput = () => {
    const {
      name,
      phone,
      language,
      salutation,
      dob,
      state,
      city,
      pincode,
      email,
      gender,
    } = formData;
    const formStatus = FormValidator.citizenPledgeFormValidation({
      name,
      phone,
      language,
      salutation,
      dob,
      state,
      city,
      pincode,
      email,
      gender,
    });
    const errors = {
      name: formStatus.name,
      salutation: formStatus.salutation,
      language: formStatus.language,
      dob: formStatus.dob,
      state: formStatus.state,
      city: formStatus.city,
      pincode: formStatus.pincode,
      email: formStatus.email,
      phone: formStatus.phone,
      gender: formStatus.gender,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  let currentDate = new Date().toISOString().split("T")[0];

  const onBlur = () => {
    validateInput();
  };

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setFormData((data) => {
      return { ...data, [name]: value };
    });
  };

  const onSubmit = (e) => {
    // debugger
    e.preventDefault();

    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true);
    } else {
      const recaptchaValue = recaptchaRef.current.getValue();
      if (recaptchaValue === "") {
        setCapthaError(true);
      } else {
        setCapthaError(false);

        let data = { ...formData };
        let payload = {
          name: data.name,
          salutation: data.salutation,
          gender: data.gender,
          dob: data.dob,
          state: data.state,
          city: data.city,
          pincode: data.pincode,
          phone: data.phone,
          email: data.email,
          language: data.language,
          myGovAccount: data.disclaimer,
          pledgeAppliedFor: url,
          // secret:recaptchaValue
        };
        if (data.language === "hi") {
          setReadPagePledgeDesc(
            PledgeData &&
              PledgeData?.list?.length > 0 &&
              PledgeData?.list[0].PledgeinHindi
          );
        } else {
          setReadPagePledgeDesc(
            PledgeData &&
              PledgeData?.list?.length > 0 &&
              PledgeData?.list[0].PledgeinEnglish
          );
        }
        validateEmail({ emailId: data.email, secret: recaptchaValue }).then(
          (response) => {
            if (response.status === true) {
              setReadPagePledge(true);
              // resetCaptcha();
            }
          }
        );
      }

      // }
    }
  };

  const onBack = () => {
    setReadPagePledge(false);
  };

  const submitForm = (recaptchaValue) => {
    let data = { ...formData };
    let payload = {
      name: data.name,
      salutation: data.salutation,
      gender: data.gender,
      dob: data.dob,
      state: data.state,
      city: data.city,
      pincode: data.pincode,
      phone: data.phone,
      email: data.email,
      language: data.language,
      myGovAccount: data.disclaimer,
      pledgeAppliedFor: url,
      secret: recaptchaValue,
    };
    submitCitizenPledgeData(payload).then((response) => {
      if (response.status === true) {
        setCertificateData(response.data);
        setShowCertificate(true);
      }
    });
  };

  window.addEventListener("DOMContentLoaded", function (event) {
    setRedirect(true);
  });

  const redirectUrl = () => {
    return <Redirect to={`/pledge/${url}`} />;
  };
  return (
    <div className="form-lower-section">
      {/* <CertificatePage /> */}

      {redirect && redirectUrl()}
      {showCertificate ? (
        <CertificatePage
          Studentswhoarenoteligible={
            PledgeData?.list &&
            PledgeData?.list.length > 0 &&
            PledgeData?.list[0].Studentswhoarenoteligible
          }
          numberOfVistiors={numberOfVistiors}
          url={window.location.href}
          PledgeName={
            PledgeData?.list &&
            PledgeData?.list.length > 0 &&
            PledgeData?.list[0].PledgeName
          }
          pledgeCount={PledgeData?.pledgeCount}
          data={certificateData}
          bannerImage={
            PledgeData &&
            PledgeData?.list?.length > 0 &&
            PledgeData?.list[0].BannerImage
          }
        />
      ) : (
        ""
      )}
      {!showCertificate &&
        (readPagePledge ? (
          <ReadPledge
            numberOfVistiors={numberOfVistiors}
            readPagePledgeDesc={readPagePledgeDesc}
            onClickForm={submitForm}
            back={onBack}
            formData={formData}
            bannerImage={
              PledgeData &&
              PledgeData?.list?.length > 0 &&
              PledgeData?.list[0].BannerImage
            }
          />
        ) : (
          <div className="pledge-outer pledge-form">
            <div className="container">
              <div className="pledge-inner">
                <div className="banner-wrap">
                  <img
                    src={
                      PledgeData &&
                      PledgeData?.list?.length > 0 &&
                      PledgeData?.list[0].BannerImage
                    }
                  />
                </div>
                <div className="content">
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className="row m-3">
                      <div className="col">
                        <h3 className="sub-title text-center">
                          Enter Basic Details
                        </h3>
                      </div>
                    </div>
                    <hr className="style9"></hr>
                    <div className="row m-3">
                      <div className="col">
                        <p className="h5 form-section-heading">Name</p>
                        <span className="required">*</span>
                      </div>
                    </div>
                    <div className="row m-3">
                      <div className="col-lg-3">
                        <GlobalDropDown
                          defaultOptionText={"Select Now"}
                          required
                          name="salutation"
                          options={salutationList}
                          label={"Salutation"}
                          containerClass={"form-group"}
                          className="form-input-field"
                          value={formData.salutation}
                          onChange={handleInputChange}
                          error={error.salutation}
                          onBlur={onBlur}
                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                      </div>

                      <div className="col-lg-9">
                        <InputField
                          required
                          type="text"
                          name="name"
                          label={"Name of Applicant"}
                          placeholder={"Enter Your Name"}
                          containerClass={"form-group"}
                          className="form-input-field"
                          value={formData.name}
                          onChange={handleInputChange}
                          error={error.name}
                          onBlur={onBlur}
                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                      </div>
                    </div>
                    <div className="row m-3">
                      <div className="col-lg-6 mb-5 radio-container">
                        <p className="h5 form-section-heading">Gender</p>
                        <span className="required">*</span>
                        <br />
                        <label>
                          Gender <span className="required">*</span>
                        </label>
                        <div className="radio-box mt-3">
                          <Radio
                            type="radio"
                            label={"Male"}
                            value={"1"}
                            name="gender"
                            checked={formData.gender == 1}
                            onChange={handleInputChange}
                          />

                          <Radio
                            type="radio"
                            label="Female"
                            value={"2"}
                            name="gender"
                            checked={formData.gender == 2}
                            onChange={handleInputChange}
                          />

                          <Radio
                            type="radio"
                            label="Other"
                            value={"3"}
                            name="gender"
                            checked={formData.gender == 3}
                            onChange={handleInputChange}
                          />

                          {error.gender && (
                            <InlineError message={error.gender} />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <p className="h5 form-section-heading">Date of Birth</p>
                        <span className="required">*</span>
                        <br />
                        <InputFieldDate
                          required
                          type="date"
                          name="dob"
                          label="Date of Birth"
                          containerClass={"form-group"}
                          className="form-input-field dob-input"
                          value={formData.dob}
                          onChange={handleInputChange}
                          error={error.dob}
                          onBlur={onBlur}
                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                          max={currentDate}
                        />
                      </div>
                    </div>
                    <div className="row m-3">
                      <div className="col">
                        <p className="h5 form-section-heading">Other Details</p>
                        <span className="required">*</span>
                      </div>
                    </div>
                    <div className="row m-3">
                      {/* <div className="col-lg-3">
                                        <GlobalDropDown
                                            defaultOptionText="Select Country"
                                            required
                                            name="country"
                                            options={stateListMock}
                                            label={'Country'}
                                            containerClass={"form-group"}
                                            className='form-input-field'
                                            value={formData.country}
                                            onChange={handleInputChange}
                                            error={error.state}
                                            onBlur={onBlur}
                                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                        />
                                    </div> */}
                      <div className="col-lg-3">
                        <GlobalDropDown
                          defaultOptionText="Select State"
                          required
                          name="state"
                          options={PledgeData?.state}
                          label={"State"}
                          containerClass={"form-group"}
                          className="form-input-field"
                          value={formData.state}
                          onChange={handleInputChange}
                          error={error.state}
                          onBlur={onBlur}
                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                      </div>
                      <div className="col-lg-3">
                        <InputField
                          required
                          type="text"
                          name="city"
                          label={"Village/City"}
                          placeholder={"Enter Name"}
                          containerClass={"form-group"}
                          className="form-input-field"
                          value={formData.city}
                          onChange={handleInputChange}
                          error={error.city}
                          onBlur={onBlur}
                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                      </div>
                      <div className="col-lg-3">
                        <InputField
                          required
                          type="pincode"
                          name="pincode"
                          label={"Pincode"}
                          placeholder={"Pincode"}
                          containerClass={"form-group"}
                          placeholder={"pincode"}
                          className="form-input-field"
                          value={formData.pincode}
                          onChange={handleInputChange}
                          error={error.pincode}
                          onBlur={onBlur}
                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                      </div>
                    </div>
                    <div className="row m-3">
                      <div className="col-lg-6">
                        <div className="phone">
                          <InputField
                            required
                            type="text"
                            name="phone"
                            label={"Phone No."}
                            placeholder={"Phone No."}
                            containerClass={"form-group"}
                            className="form-input-field"
                            value={formData.phone}
                            onChange={handleInputChange}
                            error={error.phone}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="email">
                          <InputField
                            required
                            type="text"
                            name="email"
                            label={"Email ID"}
                            placeholder={"Email ID"}
                            containerClass={"form-group"}
                            className="form-input-field"
                            value={formData.email}
                            onChange={handleInputChange}
                            error={error.email}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-3">
                      <div className="col">
                        <p className="h5 form-section-heading">
                          {"Choose Language"}
                        </p>
                        <span className="required">*</span>
                      </div>
                    </div>
                    <div className="row  m-3">
                      <div className="col-lg-6">
                        <GlobalDropDown
                          defaultOptionText={"Select Now"}
                          required
                          name="language"
                          options={languageList}
                          label={"Choose Language"}
                          containerClass={"form-group"}
                          className="form-input-field"
                          value={formData.language}
                          onChange={handleInputChange}
                          error={error.language}
                          onBlur={onBlur}
                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                      </div>
                    </div>
                    <div className="row  m-3">
                      <div className="col-lg-6">
                        <div
                          className="captcha-field"
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
                          {capthaError && (
                            <div className="inline-error">
                              *{"This is required"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-12 col-lg-7'>
                                                <div className="captcha-field">
                                                    <ReCAPTCHA
                                                        ref={recaptchaRef}
                                                        sitekey={SITE_KEY}
                                                    />
                                                    {
                                                        capthaError && (
                                                            <InlineError message={'This is required'} />
                                                        )
                                                    }
                                                </div>
                                            </div> */}
                    {/* <div className="row m-3">
                                                <div className="col">
                                                    <div class="bottom-text p-3 mb-2 bg-light text-dark text-center"><span className="info-icon"></span>A Copy of Certificate issued will be sent to Email</div>
                                                </div>
                                            </div>
                                            <div className="row m-3">
                                                <div className="col">
                                                    <Checkbox
                                                        id="disclaimer"

                                                        label={'Details given above can be used to create a MyGov account on my behalf.'}
                                                        name="disclaimer"
                                                        value={formData.disclaimer === 1 ? 2 : 1}
                                                        onChange={handleInputChange}
                                                    />
                                                    {
                                                        error.disclaimer && (
                                                            <InlineError message={error.disclaimer} />
                                                        )
                                                    }
                                                </div>
                                            </div> */}
                    <div className="footer-btn-wrap d-flex">
                      <Link
                        to={"/pledge/reuse-old-clothes-pledge"}
                        // text={'Cancel'}
                        className="m-3 bordered-btn green-border"
                      >
                        Cancel
                      </Link>
                      <Button
                        type="submit"
                        text={"Read Pledge"}
                        className="green-btn"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="count-wrap d-flex align-item-center justify-content-center">
                <div className="call-no">
                  <span className="phoneCal-icon"></span>
                  <span className="number">{numberOfVistiors}</span>Visitors on
                  this portal
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    PledgeData: state?.PledgeReducer?.PledgeData?.data,
    numberOfVistiors: state.CitizenCornerReducer.numberOfVistiors?.data,
  };
};

export default connect(mapStateToProps, {
  submitCitizenPledgeData,
  validateEmail,
})(withRouter(CitizenPledgeCorner));
