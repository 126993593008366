import React, { useState, useEffect } from "react";
import "./style.scss";

import Slider from "react-slick";
const VideoGallery = (props) => {
  const getVideoId = (url) => {
    // debugger
    let videoId = url?.split("v=")[1];
    let ampersandPosition = videoId?.indexOf("&");
    if (ampersandPosition != -1) {
      videoId = videoId?.substring(0, ampersandPosition);
    }
    return videoId;
  };
  const { data } = props;
  const [isPlay, setIsPlay] = useState(false);
  const [videoTitle, setVideoTitle] = useState(); //data != undefined && data?.files[0] != undefined && data?.files[0]
  const [thumbImg, setThumbImg] = useState(
    data?.files[0] && data?.files[0]?.thumbnail_url
  );
  const [activeNumber, setActiveNumber] = useState("1");
  const [ytVideoId, setVideoId] = useState(() =>
    getVideoId(data?.files[0] && data?.files[0]?.video_url)
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [social, setSocial] = useState({
    url: data?.files[0] && data?.files[0]?.video_url,
    title: "Wase To Wealth",
    sepreater: "::",
    size: "2.5rem",
  });

  useEffect(() => {
    setSocial({ ...social, thumbnail_url: data?.files[0]?.video_url });
  }, [data?.files[0]?.video_url]);

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
  };
  useEffect(() => {
    setVideoTitle(data?.files[0]?.title);
    setThumbImg(data?.files[0]?.thumbnail_url);
    setVideoId(() => getVideoId(data?.files[0]?.video_url));
  }, [data]);
  // ytplayer = document.getElementById("movie_player");
  // ytplayer.getCurrentTime();

  const handleVideoThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setVideoTitle(title);
    setThumbImg(img);
    setIsPlay(false);
  };
  return (
    <div
      className="gallery-section videosection"
      // style={{
      //   paddingBottom: "0px",
      //   marginTop: "-8%",
      //   background: "transparent",
      // }}
    >
      <div className="container" style={{ backgroundColor: "transparent" }}>
        <div className="gallery-head d-flex">
          <h2>{data?.title}</h2>
        </div>
        <div className="column">
          <div className="col-12 mobileresponsiveactivevid">
            <div className="yt-wrapper mobilewrapperactivevid">
              <div className="gallery-count">
                <span>{activeNumber}&nbsp;</span>/&nbsp;
                {data?.files.length}
              </div>
              <div className="yt-inner">
                {!isPlay && (
                  <div className="video-cover">
                    <img className="" src={thumbImg} alt={videoTitle} />
                    <h3 className="video-title">{videoTitle}</h3>
                  </div>
                )}
                {isPlay && (
                  <iframe
                    id="player"
                    width="100%"
                    src={`https://www.youtube.com/embed/${ytVideoId}${
                      isPlay ? "?autoplay=1" : ""
                    }`}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    rel="0"
                  ></iframe>
                )}
                {!isPlay && (
                  <button
                    id="play"
                    className="play-btn"
                    onClick={(e) => playVideo(e)}
                  ></button>
                )}
              </div>
            </div>
          </div>
          <div className="img-gallery-detail-row desktopvideoslider">
            {data?.files?.length > 3 ? (
              <Slider {...settings}>
                {data?.files.map((value, index) => {
                  return (
                    <div
                      className={
                        activeNumber === index + 1
                          ? "video-thumb active"
                          : "video-thumb"
                      }
                      onClick={() =>
                        handleVideoThumb(
                          value.videoUrl,
                          value.thumbnail_url,
                          value.thumbnail_url,
                          // "https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg",
                          index + 1
                        )
                      }
                    >
                      <div className="img-gallery-detail" key={index}>
                        <div className="img-gallery-margin">
                          <div className="card img-gallery-card" key={index}>
                            <div className="row1 no-gutters img-gallery-card-row hover-zoom">
                              <img
                                className="video-cover"
                                style={{
                                  height: "148px",
                                  width: "107%",
                                  marginRight: "9px",
                                }}
                                src={value.thumbnail_url}
                                // src={"https://uat.wastetowealth.gov.in/s3fs/2022-03/14thFeb.jpg"}
                                alt="Video title"
                              />
                              <div>
                                <div className="col-lg-12">
                                  <div className="col-lg-12 img-gallery-card-body">
                                    <h5 className="card-title img-gallery-card-title clock-icon">
                                      {" "}
                                      {value?.date}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              //   <Scrollbar style={{ height: 444 }}>
              //         <div className="video-list">
              //             {data?.list.map((item, index) => {
              //                 return (
              //                     <div
              //                         className="video-thumb"
              //                         onClick={() =>
              //                             handleVideoThumb(
              //                                 item.videoUrl,
              //                                 item.title,
              //                                 item.image,
              //                                 index + 1
              //                             )
              //                         }
              //                     >
              //                         {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
              //                         <img
              //                             className="video-cover"
              //                             src={item.image}
              //                             alt="Video title"
              //                         />
              //                         <span className="thumb-play" />
              //                     </div>
              //                 );
              //             })}
              //         </div>
              //     </Scrollbar>

              <div
                className="video-list no-scroll row belowvidmobile"
                // style={{
                //   marginLeft: "0px",
                //   paddingRight: "20px",
                //   paddingLeft: "20px",
                // }}
              >
                {data?.files.map((item, index) => {
                  return (
                    <div
                      // className="video-thumb"
                      className={
                        activeNumber === index + 1
                          ? "video-thumb col-md-4 active belowvideoinner"
                          : "video-thumb col-md-4 belowvideoinner"
                      }
                      onClick={() =>
                        handleVideoThumb(
                          item.video_url,
                          item.title,
                          item.thumbnail_url,
                          index + 1
                        )
                      }
                      // style={{ margin: "0.25em" }}
                    >
                      {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                      <img
                        className="video-cover"
                        src={item.thumbnail_url}
                        alt="Video title"
                      />
                      <span className="thumb-play" />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="img-gallery-detail-row mobilevideoslider">
            {data?.files?.length > 3 ? (
              <Slider {...settings} style={{ marginTop: "-20%" }}>
                {data?.files.map((value, index) => {
                  return (
                    <div
                      className={
                        activeNumber === index + 1
                          ? "video-thumb active"
                          : "video-thumb"
                      }
                      onClick={() =>
                        handleVideoThumb(
                          value.videoUrl,
                          value.thumbnail_url,
                          value.thumbnail_url,
                          // "https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg",
                          index + 1
                        )
                      }
                    >
                      <div className="img-gallery-detail" key={index}>
                        <div className="img-gallery-margin">
                          <div className="card img-gallery-card" key={index}>
                            <div className="row1 no-gutters img-gallery-card-row hover-zoom">
                              <img
                                className="video-cover"
                                style={{
                                  height: "148px",
                                  width: "100%",
                                  marginRight: "9px",
                                }}
                                src={value.thumbnail_url}
                                // src={
                                //   "https://uat.wastetowealth.gov.in/s3fs/2021-07/Picture1.jpg"
                                // }
                                alt="Video title"
                              />
                              <div>
                                <div className="col-lg-12">
                                  <div className="col-lg-12 img-gallery-card-body">
                                    <h5 className="card-title img-gallery-card-title clock-icon">
                                      {" "}
                                      {value?.date}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div
                className="video-list no-scroll row belowvidmobile"
                // style={{
                //   marginLeft: "0px",
                //   paddingRight: "20px",
                //   paddingLeft: "20px",
                // }}
              >
                {data?.files.map((item, index) => {
                  return (
                    <div
                      // className="video-thumb"
                      className={
                        activeNumber === index + 1
                          ? "video-thumb col-md-4 active belowvideoinner"
                          : "video-thumb col-md-4 belowvideoinner"
                      }
                      onClick={() =>
                        handleVideoThumb(
                          item.video_url,
                          item.title,
                          item.thumbnail_url,
                          index + 1
                        )
                      }
                      // style={{ margin: "0.25em" }}
                    >
                      {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                      <img
                        className="video-cover"
                        src={item.thumbnail_url}
                        alt="Video title"
                      />
                      <span className="thumb-play" />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
