import React from "react";
import "./style.scss";
import ReactHtmlParser from "react-html-parser";

const Capex = (props) => {
  const { data } = props;
  console.log(data);
  // const OandMData = [
  //   {
  //     id: "Smallest Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Mumbai, Maharashtra",
  //     capacity: "Lorem Ipsum dolor",
  //   },
  //   {
  //     id: "Medium Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Tokyo, Japan",
  //     capacity: "Lorem Ipsum dolor",
  //   },
  //   {
  //     id: "Largest Unit",
  //     client: "Lorem Ipsum dolor",
  //     location: "Osaka, Japan",
  //     capacity: "Lorem Ipsum dolor",
  //   },
  // ];

  const costData = [
    {
      newdata: [
        {
          id: "Smallest Unit",
          unitcapacity: "00 lakhs",
          capexcost: "00 lakhs",
        },

        {
          id: "Medium Unit",
          unitcapacity: "00 lakhs",
          capexcost: "00 lakhs",
        },
        // {
        //   id: "Largest Unit",
        //   unitcapacity: "00 lakhs",
        //   capexcost: "00 lakhs",
        // },
      ],
      textPointers: [
        {
          id: 1,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 2,
          desc: "Ut enim ad minim veniam",
        },
        {
          id: 3,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
        {
          id: 4,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 5,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
        {
          id: 6,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 7,
          desc: "Ut enim ad minim veniam",
        },
        {
          id: 8,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
        {
          id: 9,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 10,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
      ],
    },
  ];
  const Getdata = (id, item, cost) => {
    return (
      <div
        class="col-sm-12 col-md-4 col-lg-4 innerdiv7rowcol"
        style={{
          padding: "5px",
        }}
      >
        <div
          className="innercapexcard"
          style={{
            backgroundColor: "transparent",
            color: "white",
          }}
        >
          {" "}
          {id}
        </div>
        <div className="btn green-btn innercapexcard">
          {" "}
          <span className="unit-capacity">
            <strong>Unit Capacity:</strong>
            {item}
          </span>
        </div>
        <div className="btn green-btn innercapexcard">
          {" "}
          <span className="unit-capacity">
            <strong>CapEx Cost:</strong>
            {cost}
          </span>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="container">
        <div className="row">
          {data?.capex && (
            <div className="col-12">
              <div className="validatedTechnologies-heading contact-heading">
                CapEx (INR Lakhs)
              </div>
            </div>
          )}
          {data?.capex && (
            <div className="row phase2innerdiv7new">
              {data?.capex.smallest != null ||
              data?.capex.medium != null ||
              data?.capex.largest != null ? (
                <div className="phase2col-sm-12 col-md-12 col-lg-12 innerdiv12col">
                  <div
                    className="well innerdiv12carddiv"
                    style={{
                      paddingBottom: "0px",
                      border: "0px",
                    }}
                  >
                    <div className="row capexdivrow">
                      {data?.capex &&
                        data?.capex.smallest != null &&
                        Getdata(
                          "Smallest Unit",
                          data?.capex.smallest.unit_capacity,
                          data?.capex.smallest.unit_cost
                        )}
                      {data?.capex &&
                        data?.capex.medium != null &&
                        Getdata(
                          "Medium Unit",
                          data?.capex.medium.unit_capacity,
                          data?.capex.medium.unit_cost
                        )}
                      {data?.capex &&
                        data?.capex.largest != null &&
                        Getdata(
                          "Largest Unit",
                          data?.capex.largest.unit_capacity,
                          data?.capex.largest.unit_cost
                        )}
                    </div>
                  </div>
                </div>
              ) : null}

              {data?.capex?.capex_desc && (
                <div
                  className="phase2col-sm-12 col-md-12 col-lg-12 innerdiv12col"
                  style={{
                    marginTop: "-6%",
                    zIndex: -1,
                  }}
                >
                  <div className="card innerdiv12colcard">
                    <div
                      className="divcenter2 capexmobilediv"
                      style={{
                        marginTop:
                          data?.capex.smallest != null ||
                          data?.capex.medium != null ||
                          data?.capex.largest != null
                            ? "0px"
                            : "40px",
                      }}
                    >
                      <div
                        className="well innerdiv12carddiv"
                        style={{
                          paddingTop:
                            data?.capex.smallest != null ||
                            data?.capex.medium != null ||
                            data?.capex.largest != null
                              ? "4%"
                              : "10px",
                        }}
                      >
                        <p className="content-sub-content">
                          {ReactHtmlParser(data?.capex?.capex_desc)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Capex;
