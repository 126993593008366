import { connect } from 'react-redux';
import ModalHOC from "../../common/Modal";


const Youtube = (props) => {
    const { videoUrl } = props;
    return (
        <div className="youtube">
            <iframe title="Home video" width="420" height="315"
                src={`${videoUrl}?autoplay=1`}
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
                allow="autoplay"
            >
            </iframe>
        </div>
    )
}
export default connect(null, {})(ModalHOC(Youtube));