/**
 * This component use for Breadcrumbs
 */
import homeIcon from "../../../assests/svg/ic_home.svg";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import EventForm from "../../../components/Events/form";
import GlobalDropDown from "../../common/GlobalDropDown";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setAppLanguage } from "./dependencies/action";
import icon from "../../../assests/icons/icon-language.svg";
import {
  LanguageType,
  LanguageTypes,
} from "../../FellowshipForm/dependencies/constant/dropdownConstant";
import "./style.scss";

const Breadcrumb = (props) => {
  const {
    data,
    displayName,
    title,
    eventData,
    type,
    setAppLanguage,
    applyNow,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  let history = useHistory();
  const eventRegistration = () => {
    setShowModal(true);
    setModalData({
      title: title,
      date: eventData?.date,
      location: eventData?.location,
      id: eventData?.id,
      pagelink: eventData?.pagelink,
    });
  };
  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setLanguage(value);
    localStorage.setItem("language", value);
    setAppLanguage(value);
    console.log("value", value);
  };
  useEffect(() => {
    setAppLanguage(language);
  }, [language]);

  const retriveApplication = () => {
    window.open("/challenge-app/applicant/retrieve", "_blank");
  };
  return (
    <div className="breadcrumb breadcrumb-bg">
      <div className="container">
        <EventForm
          data={modalData}
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />
        <div className="container-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-path">
                {data &&
                  Array.isArray(data) &&
                  data.map((value, key) => {
                    return (
                      <>
                        {/* {value?.to === "/" ? (
                          <Link to={value?.to}>
                            <img
                              src={homeIcon}
                              className="home-icon-breadcrumb"
                              alt=""
                            />
                          </Link>
                        ) : (
                          <Link
                            to={value?.to}
                            className={`breadcrumb-dash-text middle-dash ${
                              value?.active ? "active" : ""
                            } ${key == 1 ? "first" : ""}`}
                          >
                            {value?.label}
                          </Link>
                        )} */}
                        {value?.to === "/" &&
                        value?.to !=
                          "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022" ? (
                          <Link to={value?.to}>
                            <img
                              src={homeIcon}
                              className="home-icon-breadcrumb"
                              alt=""
                            />
                          </Link>
                        ) : value?.to ===
                            "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022" &&
                          window?.location?.pathname ===
                            "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022" ? (
                          <Link
                            to={value?.to}
                            className={`breadcrumb-dash-text middle-dash ${
                              value?.active ? "active" : ""
                            } ${key == 1 ? "first" : ""}`}
                            style={{
                              pointerEvents:
                                value?.active && value?.active === true
                                  ? "none"
                                  : "auto",
                            }}
                          >
                            {"Swachhta Saarthi Fellowship Cohort 2"}
                          </Link>
                        ) : (
                          <Link
                            to={value?.to}
                            className={`breadcrumb-dash-text middle-dash ${
                              value?.active ? "active" : ""
                            } ${key == 1 ? "first" : ""}`}
                            style={{
                              pointerEvents:
                                value?.active && value?.active === true
                                  ? "none"
                                  : "auto",
                            }}
                          >
                            {value?.label}
                          </Link>
                        )}
                      </>
                    );
                  })}
              </div>

              {displayName === "Swachhta Saarthi Fellowship" ? (
                <div
                  className="rows"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="breadcrumb-content"
                    className={
                      displayName === "Fellowship Details"
                        ? "breadcrumb-content large"
                        : "row breadcrumb-content col-lg-8"
                    }
                  >
                    {ReactHtmlParser(displayName)}
                    {displayName === "Swachhta Saarthi Fellowship" ? (
                      <>
                        <br></br>
                        <div style={{ fontSize: "14px", paddingTop: "10px" }}>
                          {" "}
                          "To recognize students, community members and sanitary
                          workers/self-help groups, and municipal/sanitary
                          workers who are engaged in tackling the enormous
                          challenges of waste management, scientifically and
                          sustainably."
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="row col-lg-6 applynowdiv"
                    style={{ textAlign: "right" }}
                  >
                    <a
                      // href="fellowship-listing/swachhta-saarthi-fellowship-cohort-2022"
                      href="#"
                    >
                      <button
                        onClick={() => {
                          localStorage.setItem("ssfcohort", "Fellowship 2022");
                          localStorage.setItem(
                            "fellowshipPagelink",
                            "/swachhta-saarthi-fellowship-cohort-2022"
                          );
                          localStorage.setItem(
                            "ssftitle",
                            "Swachhta Saarthi Fellowship Cohort 2022"
                          );
                          history.push({
                            pathname: `/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022`,
                            state: {
                              // date: date,
                              // cardTitle: cardTitle,
                              // mainTitle: mainTitle,
                              showDetailPage: false,
                              pageLink:
                                "/swachhta-saarthi-fellowship-cohort-2022",
                              // pageLink: value?.pagelink,
                              // pageLink: pageLink,
                              // fromModule: "directlyfromlist",
                            },
                          });
                        }}
                        // onClick={() =>
                        // 	alert("test")
                        // }
                        className="blue-apply-btn mr20 applynowbuttoninside "
                      >
                        {" "}
                        {/* SSF 2022 Results */}
                        SSF cohort 2 results
                      </button>
                    </a>
                    {/* <a href="fellowship-listing/swachhta-saarthi-fellowship-cohort-2022">
                      <button
                      
                        className="blue-apply-btn mr10 applynowbuttoninside "
                      >
                        {" "}
                        Apply Now
                      </button>
                    </a> */}
                  </div>
                </div>
              ) : (
                <div
                  className="breadcrumb-content"
                  className={
                    displayName === "Fellowship Details"
                      ? "breadcrumb-content large"
                      : "breadcrumb-content"
                  }
                >
                  {ReactHtmlParser(displayName)}
                </div>
              )}
            </div>

            {type === "event" || type === "currentintervention" ? (
              <div className="action-buttons  social-button-container-event">
                <div className="fb-icon icon-social"></div>
                <div className="twitter-icon icon-social"></div>
                <div className="insta-icon icon-social"></div>
                {/* <div className='yt-icon icon-social'></div> */}
                {/* {type === "event" && (
                  <div
                    className="green-btn"
                    onClick={() => {
                      eventRegistration();
                    }}
                  >
                    Register Now
                  </div>
                )} */}
              </div>
            ) : (
              ""
            )}

            {type === "successStoriesDetails" ? (
              <div className="action-buttons  social-button-container-event">
                <div className="fb-icon icon-social"></div>
                <div className="twitter-icon icon-social"></div>
                <div className="insta-icon icon-social"></div>
              </div>
            ) : (
              ""
            )}

            {type === "challenge" && (
              <div className="action-buttons">
                <button
                  className="green-border-btn white-border-btn"
                  onClick={() => retriveApplication()}
                >
                  Retrieve Application
                </button>
                {applyNow !== "" && (
                  <a
                    className="green-btn green-button-custom"
                    href={applyNow}
                    target="_blank"
                  >
                    Apply Now
                  </a>
                )}
              </div>
            )}
            {(window?.location?.pathname === "/fellowship" ||
              window?.location?.pathname === "/fellowship-form" ||
              window?.location?.pathname ===
                "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022") && (
              <div className="lang-select-container">
                <div className="selec-language clearfix">
                  <img src={icon} width="23" height="25" alt="" />
                  <GlobalDropDown
                    defaultOptionDisable
                    required
                    name="language"
                    options={LanguageTypes[language]}
                    // label={changeLanguage(language, 'education', 'label')}
                    containerClass={"language-dropdown-outer"}
                    className="language-dropdown"
                    value={language}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { setAppLanguage })(
  withRouter(Breadcrumb)
);
