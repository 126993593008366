import React, { useRef } from "react";
import logobar1 from "./detailsassets/logobar1.jpg";
import "./style.scss";
import Phase2comp10 from "./detailscomponents/Phase2comp10";

function Phase2bkup(props) {
  return (
    <div className="form-row phase2maindiv">
      <Phase2comp10 />
    </div>
  );
}

export default Phase2bkup;
