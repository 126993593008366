import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import Slider from "react-slick";
import rightSound from "../../../../../assests/audio/right-ans.mp3";
import wrongSound from "../../../../../assests/audio/wrong-ans.mp3";
import SocialShare from "../../../../common/SocialShare";
import { Parallax } from "react-scroll-parallax";
import { useController } from "react-scroll-parallax";

const CitizenQuizzes = (props) => {
  const { parallaxController } = useController();
  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);
  const { item, count, data, SectionId } = props;
  const settings = {
    dots: false,
    infinite: false,
    variableWidth: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          infinite: true,
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // lazyLoad: "ondemand"
  };
  const [social] = useState({
    url: `${window.location.host}/citizen-corner/${SectionId}`,
    title: data?.heading,
    sepreater: "::",
    size: "2.5rem",
  });
  const storedQuestion = localStorage.getItem("qIndex")
    ? JSON.parse(localStorage.getItem("qIndex"))
    : [];
  const [questionSet, SetQuestionSet] = useState([]);
  const [answeredQuestion, SetAnsweredQuestion] = useState([]);
  const [score, SetScore] = useState(0);
  let rightAudio = new Audio(rightSound);
  let wrongAudio = new Audio(wrongSound);
  let slider1 = useRef();

  const playEffect = (correctAns) => {
    if (correctAns) rightAudio.play();
    else wrongAudio.play();
  };

  const getQuestionSet = () => {
    const queSet = [];
    let counter = 0;
    data?.quizzes.forEach((item, index) => {
      if (counter < 5 && !storedQuestion.includes(index)) {
        item.key = index;
        queSet.push(item);
        counter++;
      }
    });
    if (storedQuestion.length > 15) {
      localStorage.clear();
      data?.quizzes.forEach((item, index) => {
        if (queSet.length < 5) {
          item.key = index;
          queSet.push(item);
        }
      });
    }
    SetQuestionSet(queSet);
  };

  useEffect(() => {
    getQuestionSet();
  }, [data]);

  const handleOption = (e, ans, rightAns, key) => {
    if (!answeredQuestion.includes(key)) {
      const getStored =
        localStorage.getItem("qIndex") == null
          ? []
          : JSON.parse(localStorage.getItem("qIndex"));
      SetAnsweredQuestion([...answeredQuestion, key]);
      e.target.classList.add(`${ans == rightAns ? "right-ans" : "wrong-ans"}`);
      if (ans == rightAns) {
        getStored.push(key);
        localStorage.setItem("qIndex", JSON.stringify(getStored));
        SetScore(score + 1);
        playEffect(true);
      } else playEffect(false);
      // let val = slider1
      // if (val) {
      //     setTimeout(() => {
      //         val.slickNext()
      //     }, 2000)
      // }
    } else return;
  };
  return (
    <div className="citizen-quizzes custom-slider-nextPrev">
      <Parallax className="large-tree" x={["20px", "-40px"]} tagOuter="div">
        <div className="large-tree-img" />
      </Parallax>
      <Parallax className="small-tree" x={["20px", "-40px"]} tagOuter="div">
        <div className="small-tree-img" />
      </Parallax>
      <Parallax className="bin-car" x={["-80px", "80px"]} tagOuter="div">
        <div className="bin-car-img" />
      </Parallax>
      <div className="playground" />

      <div className="container">
        <div className="row">
          <div className="col-12 d-flex quiz-header">
            <h2>{ReactHtmlParser(data?.heading)}</h2>
            <div className="citizen-social-share margin-left-auto">
              <SocialShare social={social} />
            </div>
          </div>
        </div>
        {answeredQuestion.length == 5 ? (
          <div className="row score-card d-flex justify-content-center">
            <Parallax
              className="green-balloon"
              y={["-80px", "80px"]}
              tagOuter="div"
            >
              <div className="green-balloon-img animation-pendulam-2" />
            </Parallax>
            <div className="score-wrap">
              <div className="bulb glow" />
              <span className="score-ttl">your Score</span>
              <span className="score">
                <strong>0{score}</strong> | 05
              </span>
            </div>
          </div>
        ) : (
          <Slider
            {...settings}
            ref={(slider) => {
              slider1 = slider;
            }}
          >
            {questionSet.map((que, index) => {
              return (
                <div className="row" key={que.key}>
                  <div className="question">
                    <span className="count">{`#${index + 1}`}</span>
                    <h3>{que.Title}</h3>
                    <ul className="options">
                      {que.Options.map((option, ind) => {
                        return (
                          <li
                            key={ind}
                            onClick={(e) =>
                              handleOption(e, option, que.Answer, que.key)
                            }
                          >
                            {option}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
            <div className="row extra-slide">
              <div className="question"></div>
            </div>
          </Slider>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default CitizenQuizzes;
