import ReactHtmlParser from 'react-html-parser';
import './style.scss';

const SectionTitle = (props) => {

    return (
        <h2 className="section-heading">
            {ReactHtmlParser(props?.heading)}
        </h2>
    )
}
export default SectionTitle;