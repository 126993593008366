/**
 * Current invervention details page
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DeployedTechnologies from "../dependencies/component/DeployedTechnologies";
import ExpectedImpact from "../dependencies/component/ExpectedImpact";
import SimilarIntervention from "../dependencies/component/SimilarInterventions";
import OverView from "../dependencies/component/OverView";
import Breadcrumb from "../../common/Breadcrumbs";
import GallerySection from "../dependencies/component/GallerySection2";
import ActAndPolicy from "./Acts&Policies";

// import { getCurrentInterventionDetails } from "../../CurrentInterventions/dependencies/action";
import { getPastProjectsDetails } from "../dependencies/action";
import { useEffect, useState } from "react";
import SalientFeatures from "../dependencies/component/SalientFeatures";
import ViewPartner from "../dependencies/component/ViewPartner";
import "./style.scss";

import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const items = [
  { to: "/", label: "/" },
  { to: "/past-projects", label: "What We Do" },
  { to: "/past-projects", label: "Our Sentinels" },
  {
    to: "/past-projects",
    label: "Past Projects",
    active: true,
  },
];

const staticData = [
  {
    id: 1,
    file_name: "Urban Drain Cleaning Removal Floating Debris",
    url: "https://uat.wastetowealth.gov.in/s3fs/s3fs-public/2022-06/Multiple%20Banner%20Image%202.jpg",
  },
  {
    id: 2,
    file_name: "Urban Drain Cleaning 2",
    url: "https://uat.wastetowealth.gov.in/s3fs/2021-10/faridabad.png",
  },
  {
    id: 3,
    file_name: "Urban Drain Cleaning 3",
    url: "https://uat.wastetowealth.gov.in/s3fs/2021-09/up1.jpg",
  },
  {
    id: 4,
    file_name: "Urban Drain Cleaning 4",
    url: "https://uat.wastetowealth.gov.in/s3fs/2021-10/Picture%20Sudhara.png",
  },
  {
    id: 5,
    file_name: "Urban Drain Cleaning 5",
    url: "https://uat.wastetowealth.gov.in/s3fs/2021-09/ss1.png",
  },
  {
    id: 6,
    file_name: "Urban Drain Cleaning 6",
    url: "https://uat.wastetowealth.gov.in/s3fs/2021-06/Vertiver%201_0.jpg",
  },
];
const PastInterventionsDetails = (props) => {
  const {
    getCurrentInterventionDetails,
    currentInterventionDetails,
    getPastProjectsDetails,
    pastProjectsDetails,
  } = props;

  // dividing array method
  //   const list = [1, 2, 3, 4, 5, 6];
  // const middleIndex = Math.ceil(list.length / 2);

  // const firstHalf = list.slice().splice(0, middleIndex);
  // const secondHalf = list.slice().splice(-middleIndex);

  // console.log(firstHalf);  // [1, 2, 3]
  // console.log(secondHalf); // [4, 5, 6]
  // console.log(list);       // [1, 2, 3, 4, 5, 6];

  useEffect(() => {
    // const vdeoData = localStorage.getItem("videoData");
    // console.log("vdeodata", JSON.parse(vdeoData));
    // setVideodata(JSON.parse(vdeoData));

    let str = window.location.pathname;
    console.log("str path", str.split("/"));
    // getCurrentInterventionDetails(str.split("/")[2]);
    // getCurrentInterventionDetails(
    //   "urban-drain-cleaning-removal-floating-debris-faridabad"
    // );

    getPastProjectsDetails(props.location.state?.pageLink);
    console.log("Past Projects Details", pastProjectsDetails);
  }, [window.location.pathname]);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  return (
    <div className="CurrentInterventionDetails pastprojectsmaindiv">
      <Breadcrumb
        data={items}
        // displayName={currentInterventionDetails?.mainTitle}
        displayName={pastProjectsDetails?.data?.title}
        type={"currentintervention"}
      />

      <OverView
        data={pastProjectsDetails?.data}
        mainTitle={pastProjectsDetails?.data?.title}
        body={pastProjectsDetails?.data?.body}
        category={pastProjectsDetails?.data?.category}
        location={props.location.state?.location}
      />
      {/* {pastProjectsDetails?.data && (
        <ExpectedImpact data={currentInterventionDetails?.expected_data} />
      )} */}
      <div className="project-impact">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 style={{ fontSize: "28px" }}>Outcome</h2>
              {/* {ReactHtmlParser(successStoriesData?.project_impact.left)}
              {ReactHtmlParser(successStoriesData?.project_impact.right)} */}
              <ul>
                {pastProjectsDetails?.data?.outcome.map((item, index) => (
                  <li key={index}>
                    <span>
                      <span>
                        <span>{item.outcome_body}</span>
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
              {/* <ul>
                {secondHalfData.map((item, index) => (
                  <li key={index}>
                    <span>
                      <span>
                        <span>{item.outcome_body}</span>
                      </span>
                    </span>
                  </li>
                ))}
              </ul> */}
            </div>
          </div>

          <div class="bin-car"></div>
        </div>
      </div>

      {/* {currentInterventionDetails?.salient_features &&
        currentInterventionDetails?.salient_features?.features && (
          <SalientFeatures
            data={currentInterventionDetails?.salient_features}
          />
        )}
      {currentInterventionDetails?.deployed_technologies &&
        currentInterventionDetails?.deployed_technologies?.list && (
          <DeployedTechnologies
            data={currentInterventionDetails?.deployed_technologies}
          />
        )}
      {currentInterventionDetails?.expected_data && (
        <ExpectedImpact data={currentInterventionDetails?.expected_data} />
      )}
      {currentInterventionDetails?.similar_interventions &&
        currentInterventionDetails?.similar_interventions?.list && (
          <SimilarIntervention
            data={currentInterventionDetails?.similar_interventions}
          />
        )} */}
      <div className="gallery-wraper" style={{ marginTop: "5%" }}>
        <div class="yellow-line"></div>
        <div class="blue-line"></div>
        <div class="green-line"></div>
        <div class="large-tree"></div>
        <div class="small-tree"></div>
        <div class="bulb"></div>
        <div class="dotted-hexagon left"></div>
        <div class="dotted-hexagon right"></div>
        <GallerySection
          // data={currentInterventionDetails?.gallery}
          staticData={pastProjectsDetails?.data?.gallery}
        />
      </div>
      <div
        className="col-md-9 list-container"
        style={{ maxWidth: "100%", marginTop: "5%", marginBottom: "5%" }}
      >
        <ActAndPolicy
          fieldData={pastProjectsDetails?.data?.Report_Upload}
          pressReleaseData={currentInterventionDetails?.field_press_release}
          // subtype={subtype}
          // data={policyListData}
          // onClickTab={handleChange}
        />
        {/* <CentralGuideline /> */}
      </div>

      {pastProjectsDetails?.data?.viewPartners?.list?.length !== 0 && (
        <div className="partner-container">
          {/* <div class="yellow-line"></div>
          <div class="blue-line"></div>
          <div class="green-line"></div> */}
          <div class="recycle two"></div>
          <ViewPartner data={pastProjectsDetails?.data?.viewPartners} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("intervention details", state);
  return {
    // currentInterventionDetails:
    //   state.CurrentInterventionReducer.currentInterventionDetails.data,
    pastProjectsDetails: state.PastInterventionReducer.pastProjectsDetails,
  };
};
export default connect(mapStateToProps, {
  // getCurrentInterventionDetails,
  getPastProjectsDetails,
})(withRouter(PastInterventionsDetails));
