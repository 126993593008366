import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import overgraphic from "../../../../../assests/images/event_graphic_long.svg";
import "./style.scss";

const Overview = (props) => {
  const { data, body, mainTitle, category, location } = props;

  console.log("data banner image", data?.banner_image);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const showInMapClicked = (lat, long) => {
    window.open("https://maps.google.com?q=" + lat + "," + long);
  };

  return (
    <div
      className="project-info eventoverview"
      style={{ paddingBottom: "0px" }}
    >
      <div className="container">
        <p
          style={{
            fontWeight: "bold",
            marginBottom: "15px",
            fontSize: "30px",
            fontFamily: "Chivo, sans-serif",
            color: "black",
          }}
        >
          {"About The Event"}
        </p>
        <div className="row">
          <div className="col-md-7" style={{ height: "22em" }}>
            <div class="dotted-sq"></div>

            <div className="image" style={{ height: "100%" }}>
              <Slider {...settings}>
                {data &&
                  Array.isArray(data?.banner_image) &&
                  data?.banner_image?.length > 0 &&
                  data?.banner_image?.map((value, index) => {
                    return <img src={`${value?.image}`} alt="" key={index} />;
                  })}
              </Slider>
              {/* <img src={data?.image} /> */}
              {/* <img
                src={
                  "https://uat.wastetowealth.gov.in/s3fs/s3fs-public/2022-06/download%20%281%29_0.jpg"
                }
              /> */}
            </div>
          </div>
          <div className="col-md-5 info" style={{ paddingRight: "20px" }}>
            <div class="green-bird"></div>
            <div class="white-leaf"></div>
            <h2 style={{ textAlign: "center", fontSize: "40px" }}>
              {mainTitle}
            </h2>
            <div
              className="detail-div"
              style={{
                marginBottom: "20px",
                background: "#4271bd",
                textAlign: "center",
              }}
            >
              <span
                // className="detail-date"
                style={{
                  fontSize: "18px",
                  fontFamily: "Chivo, sans-serif",
                  lineHeight: 1.2,
                  marginRight: "0px",
                  color: "#ffffff",
                  // background: "#4271bd",
                }}
              >
                {data?.date}
              </span>
              {/* <span
                className="detail-location"
                style={{
                  fontSize: "18px",
                  fontFamily: "Chivo, sans-serif",
                  lineHeight: 1.2,
                }}
              >
                Location: {data?.location}
              </span> */}
            </div>
            <div
              className="detail-div golocationbtn"
              data-toggle="tooltip"
              title="Go to Google Location"
              // data-toggle="tooltip"
              // title="Hooray!"
              style={{
                background: "#4271bd",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => showInMapClicked(data?.latitude, data?.longitude)}
            >
              {/* <span
                className="detail-date"
                style={{
                  fontSize: "18px",
                  fontFamily: "Chivo, sans-serif",
                  lineHeight: 1.2,
                }}
              >
                Date: {data?.date}
              </span> */}
              <span
                className="detail-location"
                style={{
                  fontSize: "18px",
                  fontFamily: "Chivo, sans-serif",
                  lineHeight: 1.2,
                  cursor: "pointer",
                }}
                data-toggle="tooltip"
                title="Go to Google Location"
              >
                <a href="#" style={{ color: "white" }}>
                  {data?.location}
                </a>
              </span>
            </div>
            {/* <ul>
              <li>
                {" "}
                <span className="start-date">Date:</span> {data?.date}
              </li>
            
              <li>
                {" "}
                <span className="location">Location:</span>{" "}
                {`${data?.location}`}
              </li>
             
            </ul> */}
          </div>
        </div>
        <div className="row" style={{ paddingTop: "20px" }}>
          <div
            className="col-12 pastprojoverviewbody"
            style={{ fontFamily: "'Chivo', sans-serif", fontSize: "18px" }}
          >
            {/* <h3> {ReactHtmlParser(mainTitle)}</h3> */}
            {ReactHtmlParser(data?.body)}

            {/* <div className="tag">
              
              <span className="green-bg">{category}</span>
            </div> */}
          </div>
        </div>
        <div>
          <img src={overgraphic} />
        </div>
        {/* <div class="large-tree"></div>
        <div class="small-tree"></div> */}
      </div>
    </div>
  );
};

export default Overview;
