import { submitTechService } from './service';
import { isLoading } from '../../../dependencies/action/action';
import {
    SUBMIT_TECH_DATA
} from '../../../constants/actionConstants';
import { toast } from "react-toastify";
import { TECH_IMG_UPLOAD_URL } from '../../../constants/ApplicationUrl';

export const uploadFileTech = (file) => async (dispatch) => {
    dispatch(isLoading(true))
        // var axios = require('axios');
        // var data = file;
        
        // var config = {
        //   method: 'post',
        //   url: TECH_IMG_UPLOAD_URL,
        //   headers: { 
        //     'Content-Type': 'application/octet-stream', 
        //     'Content-Disposition': 'file; filename="'+file.name+'"'
        //   },
        //   data : data
        // };
        
        // axios(config)
        // .then(function (response) {
        //     console.log(response)
        //     console.log(response.status)
        //                 // const { data, status } = response || {};
        //                 dispatch(isLoading(false));
        //         if (response.status === 201) {
        //             console.log("if")
        //             return response
        
        //         } else {
        //             dispatch(isLoading(false));
        //             console.log("else")
        //             return { status: false }
        //         }
        // }).catch(e => {
        //     dispatch(isLoading(false))
        //     toast.error('Something Went Wrong from action ');
        //     return { status: false }
        // });
    return await submitTechService.uploadFileTech(file).then((response) => {
        dispatch(isLoading(false));
        if (response.status === 201) {
            return response

        } else {
            dispatch(isLoading(false));
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const submitTechSubmit = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await submitTechService.submitTechSubmit(params).then((response) => {
        dispatch(isLoading(false));
        const { status, data } = response || {}
        if (status === 200) {
            toast.success(data.message);
            return { status: true };
        } else {
            dispatch(isLoading(false));
            toast.error(data.message);
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
    })
}

export const submitTechInfo = () => {
    return async (dispatch) => {
        let submitTechData = await submitTechService.submitTechInfo();
        dispatch({
            type: SUBMIT_TECH_DATA,
            payload: submitTechData
        })
    }
} 

