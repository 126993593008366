import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import InputField from "../../common/InputField";
import Button from "../../common/Button";
import ListWithPagination from "../../common/ListWithPagination";
import ReactHtmlParser from "react-html-parser";
import searchIcon from "../../../assests/svg/searchIcon.svg";
// import Pagination from "react-js-pagination";

import {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
  getValidationLink,
} from "../dependencies/action";
import LeftSideCategory from "../../common/LeftSideCategory";
import LeftSideLink from "../../common/LeftSideLink";
import GlobalDropDown from "../../common/GlobalDropDown";
import Breadcrumb from "../../common/Breadcrumbs";
import "./style.scss";
import "./cardStyle.css";
import "./responsiveStyles.css";
import Pagination from "react-js-pagination";

import { initGA, logPageView } from "../../../dependencies/utils/analytics";
const items = [
  { to: "/", label: "/" },
  { to: "/validated-technologies-listing", label: "Technology Repository" },
  {
    to: "/validated-technologies-listing",
    label: "Technology Listing",
    active: true,
  },
];

const CurrentInterventionsList = (props) => {
  const {
    getChallengeEventList,
    list,
    getTechnologyType,
    technology,
    getFilterData,
    filterData,
    getValidationLink,
    validationLink,
  } = props;
  const [page, setPage] = useState(0);
  const [categoryId, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [isMoreFilter, setMoreFilter] = useState(false);
  const handleShowMoreFiltere = () => setMoreFilter(!isMoreFilter);
  const [pageStatus, setPageStatus] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [data, setData] = useState([]);

  const defaultFormData = {
    search: "",
    thematic: "",
    origin: "",
    readiness: "",
    recognized: "",
    state: "",
    country: "",
  };

  const defaultFormData2 = {
    search: "",
    thematic: "",
    origin: "",
    readiness: "",
    recognized: "",
    state: "",
    country: "",
  };
  const [formData, setFormData] = useState(
    localStorage.getItem("formdata") &&
      JSON.parse(localStorage.getItem("formdata"))
      ? localStorage.getItem("formdata") &&
          JSON.parse(localStorage.getItem("formdata"))
      : defaultFormData
  );
  const [appliedformData, setAppliedFormData] = useState(defaultFormData2);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        // alert( "This page is reloaded");
        if (localStorage.getItem("redirectedfrom") == null) {
          // alert("rredirectedfrome null")
          localStorage.removeItem("selectedActivePage");
          localStorage.removeItem("actualpageNo");
          if (localStorage.getItem("selectedActivePage")) {
            // alert("sdfsdf "+Number(localStorage.getItem('selectedActivePage')))
            setActivePage(Number(localStorage.getItem("selectedActivePage")));
            setPage(Number(localStorage.getItem("actualpageNo")));
          } else {
            setActivePage(1);
          }
        } else {
          // alert("not null")
          if (localStorage.getItem("selectedActivePage")) {
            // alert("sdfsdf "+Number(localStorage.getItem('selectedActivePage')))
            setActivePage(Number(localStorage.getItem("selectedActivePage")));
            setPage(Number(localStorage.getItem("actualpageNo")));
          } else {
            setActivePage(1);
          }
          console.log(JSON.parse(localStorage.getItem("formdata")));
          setAppliedFormData(JSON.parse(localStorage.getItem("formdata")));
          let val = JSON.parse(localStorage.getItem("formdata"));
          setAppliedFormData((user) => {
            return { ...user, val };
          });
          setTimeout(() => {
            console.log(appliedformData);
          }, 1000);

          setTimeout(() => {
            console.log("this is the removed message from null");
            localStorage.removeItem("redirectedfrom");
          }, 4000);
        }
      } else {
        if (localStorage.getItem("redirectedfrom") == null) {
          localStorage.removeItem("selectedActivePage");
          localStorage.removeItem("actualpageNo");
          if (localStorage.getItem("selectedActivePage")) {
            setActivePage(Number(localStorage.getItem("selectedActivePage")));
            setPage(Number(localStorage.getItem("actualpageNo")));
          } else {
            setActivePage(1);
          }
        } else {
          if (localStorage.getItem("selectedActivePage")) {
            setActivePage(Number(localStorage.getItem("selectedActivePage")));
            setPage(Number(localStorage.getItem("actualpageNo")));
          } else {
            setActivePage(1);
          }
          console.log(JSON.parse(localStorage.getItem("formdata")));
          setTimeout(() => {
            console.log("this is the removed message from null");
            localStorage.removeItem("redirectedfrom");
          }, 4000);
        }
      }
      // localStorage.clear();
    }
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setPage((pageNumber - 1) * 6);
  };
  const changePageNumber = (pageNumber) => {
    // setActivePage(pageNumber);
    // alert(pageNumber)
    localStorage.setItem("selectedActivePage", pageNumber);
    localStorage.setItem("actualpageNo", (pageNumber - 1) * 10);
    setActivePage(pageNumber);
    setPageStatus(false);
    setPage((pageNumber - 1) * 10);
  };
  useEffect(() => {
    getChallengeEventListData();
  }, [page, categoryId]);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    console.log(name);
    console.log(value);
    if (name === "origin")
      setFormData((user) => {
        return { ...user, ["state"]: "" };
      });
    setFormData((user) => {
      return { ...user, ["country"]: "" };
    });

    setFormData((user) => {
      return { ...user, [name]: value };
    });

    console.log(formData);

    const newFormData = {
      ...formData,
      [name]: value,
    };

    // setAppliedFormData(newFormData);
    console.log(newFormData);
    localStorage.setItem("formdata", JSON.stringify(newFormData));
    console.log(JSON.parse(localStorage.getItem("formdata")));
    // fireAppliedData(newFormData);
    setAppliedFormData(newFormData);
  };

  // const fireAppliedData = (newFormData) => {
  //   setAppliedFormData(newFormData);
  // };

  const getChallengeEventListData = () => {
    // console.log("getChallengeEventListData", appliedformData);
    console.log("getChallengeEventListData2", formData);
    getChallengeEventList(
      page,
      formData.search,

      formData.thematic,
      formData.origin,

      formData.readiness,
      formData.recognized,
      formData.state,
      formData.country
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setPage(0);
    setPageStatus(true);
    getChallengeEventListData();
  };

  useEffect(() => {
    getTechnologyType();
    getFilterData();
    getValidationLink();
  }, []);

  const getCategoryId = (Id) => {
    setCategory(Id);
  };
  return (
    <div className="ValidatedTechnoligies">
      <Breadcrumb data={items} displayName={"Technology Repository"} />
      <div className="filter-row filter-row-common">
        {/*<div className='filter-row filter-row-style'>*/}
        <div className="container">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="row desktopFilterRow">
              <div className="col-md-1 filter-text">Filter by</div>
              <div className="user-type-dropdown col-2">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.thematicArea}
                  value={formData.thematic}
                  name={"thematic"}
                  defaultOptionText={"Select Thematic"}
                  error=""
                />
              </div>

              <div className="user-type-dropdown col-2">
                <GlobalDropDown
                  containerStyle="form-group"
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.origin}
                  value={formData.origin}
                  name={"origin"}
                  defaultOptionText={"Select Origin"}
                  error=""
                />
              </div>

              <div className="user-type-dropdown col-2">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.readiness}
                  value={formData.readiness}
                  name={"readiness"}
                  defaultOptionText={"Select Tech Readiness"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-2">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.recognized}
                  value={formData.recognized}
                  name={"recognized"}
                  defaultOptionText={"Recognized By"}
                  error=""
                />
              </div>
              {formData.origin === "150" ||
              formData.origin === "148" ||
              formData.origin === "" ? (
                <div className="user-type-dropdown col-2">
                  <GlobalDropDown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={filterData?.list?.state}
                    value={formData.state}
                    name={"state"}
                    defaultOptionText={"Select State"}
                    error=""
                  />
                </div>
              ) : (
                ""
              )}

              {formData.origin === "151" ||
                (formData.origin === "149" && (
                  <div className="user-type-dropdown col-2">
                    <GlobalDropDown
                      containerStyle="form-group "
                      selectTagStyle="form-control dropdown-media"
                      onChange={handleInputChange}
                      options={filterData?.list?.countries}
                      value={formData.country}
                      name={"country"}
                      defaultOptionText={"Select Country"}
                      error=""
                    />
                  </div>
                ))}
              <div className="col-md-1 apply-section">
                <Button
                  type="submit"
                  text="Apply"
                  className="green-btn filter-btn"
                />
              </div>
            </div>

            <div className="row mobileFilterRow">
              <div className="col-md-1 filter-text">Filter by</div>
              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.thematicArea}
                  value={formData.thematic}
                  name={"thematic"}
                  defaultOptionText={"Select Thematic"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group"
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.origin}
                  value={formData.origin}
                  name={"origin"}
                  defaultOptionText={"Select Origin"}
                  error=""
                />
              </div>

              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.readiness}
                  value={formData.readiness}
                  name={"readiness"}
                  defaultOptionText={"Select Tech Readiness"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.recognized}
                  value={formData.recognized}
                  name={"recognized"}
                  defaultOptionText={"Recognized By"}
                  error=""
                />
              </div>

              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.state}
                  value={formData.state}
                  name={"state"}
                  defaultOptionText={"Select State"}
                  error=""
                />
              </div>

              <div className="col-md-1 apply-section">
                <Button
                  type="submit"
                  text="Apply"
                  className="green-btn filter-btn"
                />
              </div>
            </div>

            {isMoreFilter && (
              <div className=" row no-gutters list_top_section pt20">
                <div className="user-type-dropdown pl0 col-2 select-box">
                  <GlobalDropDown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={filterData?.list?.Scale}
                    value={formData.scale}
                    name={"scale"}
                    defaultOptionText={"Select scale"}
                    error=""
                  />
                </div>
                <div className="user-type-dropdown  col-2 select-box">
                  <GlobalDropDown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={filterData?.list?.conditions}
                    value={formData.specialCondition}
                    name={"specialCondition"}
                    defaultOptionText={"Select Special Condition"}
                    error=""
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="bottom-section-list">
        <div className="container common-listing">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="col-11 container">
              <div
                className="boxWithShadow"
                style={{
                  marginBottom: "3%",
                  border: "1px solid lightblue",
                  paddingTop: "20px",
                  paddingBottom: "0px",
                }}
              >
                <h4 className="importantNoteHeader">Important Note</h4>
                <div className="techrepouppertext">
                  The technologies listed in this repository broadly fall under
                  two categories: Technologies which been evaluated by Waste to
                  Wealth Mission and Jal Jeevan Mission. These technologies have
                  been scientifically evaluated by Expert Committees comprising
                  of renowned subject experts from academia and industry. These
                  recommendations are based on scientific merits and only
                  suggestive in nature for deployment. All technical and
                  commercial information provided about the technologies are
                  only for information and general understanding of readers.
                  Users should not consider the technologies listed here as any
                  form of endorsement or recommendation by Waste to Wealth
                  Mission, Office of PSA, Invest India, Jal Jeevan Mission,
                  Department of Drinking Water and Sanitation (DDWS), and
                  Ministry of Jal Shakti, Government of India. There could be
                  many other similar technologies and users are advised to
                  select appropriate technologies as per their local needs and
                  procure them according to their procurement policies after
                  carrying out necessary due diligence.
                </div>
                <div className="techrepouppertext">
                  The repository also lists technologies identified by other
                  bodies such as Central Pollution Control Board, United Nations
                  Environment Programme, Tamil Nadu Urban Sanitation Support
                  Programme etc. The information provided for these technologies
                  have been sourced as is from the respective databases and
                  Waste to Wealth Mission, Office of PSA and Invest India cannot
                  be held liable for any incorrect information provided in these
                  databases.
                </div>
              </div>

              <div className="boxWithShadow">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="col-md-6" style={{ paddingLeft: "5%" }}>
                    <div className="common-listing-title">
                      <h3 style={{ color: "#183b63" }}>
                        {list?.list
                          ? `All Technologies (${
                              list?.totalCount > 0 ? list.totalCount : 0
                            })`
                          : ""}
                      </h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="search">
                      <form onSubmit={(e) => onSubmit(e)}>
                        <InputField
                          type="search"
                          name="search"
                          placeholder="Search Technology"
                          value={formData.search}
                          onChange={handleInputChange}
                          containerClass={"form-group"}
                          className="subscribe-input-field"
                        />
                        <div className="btn-group">
                          <Button
                            icon={
                              <img src={searchIcon} className="search-icon" />
                            }
                            type="submit"
                            text=""
                            className="list-submit-button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <ListWithPagination
                  data={list?.list}
                  itemsCountPerPage={10}
                  totalItemsCount={list?.totalCount}
                  pageRangeDisplayed={5}
                  activePageNo={activePage}
                  onChangePagination={changePageNumber}
                  // checkStatus={pageStatus}
                  nextUrl={"validated-technologies-listing"}
                  type={"validated-technologies"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.KnowledgeManagmentReducer.kmListing.data,
    technology: state.KnowledgeManagmentReducer.kmTechnology.data,
    filterData: state.KnowledgeManagmentReducer.filterData,
    validationLink: state.KnowledgeManagmentReducer.validationLink.data,
  };
};

export default connect(mapStateToProps, {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
  getValidationLink,
})(withRouter(CurrentInterventionsList));
