import { useRef } from "react";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Youtube from "../../Youtube";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import scrollArrow from "../../../../../assests/icons/scroll_arrow.svg";
import "./style.scss";
import Chart from "react-google-charts";
import bannerMobImage2 from "../../../../../assests/images/homapage-banner-mob-2.svg";
import bannerMobImage1 from "../../../../../assests/images/homapage-banner-mob-1.svg";
import { Player } from "video-react";
import slideLogo from "../../../../../assests/images/waste-to-wealth-02.png";
import bannervid3 from "../../../../../assests/video/bannervid3.mp4";
import bannervidimage from "../../../../../assests/images/bannervidimage.jpg";

const Banner = (props) => {
  const vidRef = useRef(null);
  const getVideoId = (url) => {
    let videoId = url?.split("v=")[1];
    let ampersandPosition = videoId?.indexOf("&");
    if (ampersandPosition != -1) {
      videoId = videoId?.substring(0, ampersandPosition);
    }
    return videoId;
  };
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const [bannerClass, setBannerClass] = useState("banner-wrap");
  const { data } = props;
  const [bannerTableData, setBannerTableData] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    pauseOnFocus: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  let columnNames = [];
  let rowData = [];
  const reformatTableData = (tableData) => {
    // debugger
    let columnArray = [];
    let longest = -1;
    if (tableData && tableData.StatisticalRows.length > 0) {
      tableData &&
        tableData.StatisticalRows.map((item) => {
          columnArray.push(item.StatisticalColumns);
        });

      for (let i = 0; i < columnArray.length; i++) {
        if (
          longest == -1 ||
          columnArray[i].length > columnArray[longest].length
        ) {
          longest = i;
        }
      }
      let body = "<strong>";
      let bodyEnd = "</strong>";
      try {
        columnArray[longest].map((column) =>
          columnNames.push(
            column.ColumnName.substring(
              column.ColumnName.indexOf(body) + body.length,
              column.ColumnName.indexOf(bodyEnd)
            )
          )
        );

        if (tableData && tableData.StatisticalRows.length > 0) {
          tableData.StatisticalRows.map((data) => {
            let obj = {};
            obj["name"] = data.RowName.substring(
              data.RowName.indexOf(body) + body.length,
              data.RowName.indexOf(bodyEnd)
            );
            let category = data.RowName.match(/\(([^)]+)\)/);
            if (category) {
              obj["category"] = category[1];
            }
            data.StatisticalColumns.map((col, index) => {
              for (let i = 0; i < 10; i++) {
                if (col.ColumnName.includes(columnNames[index + i])) {
                  obj[columnNames[index + i]] = col.StatisticalData.substring(
                    col.StatisticalData.indexOf(body) + body.length,
                    col.StatisticalData.indexOf(bodyEnd)
                  );
                  let columnname = col.StatisticalData.match(/\(([^)]+)\)/);
                  if (columnname) {
                    obj[`${columnNames[index + i]} Year`] = columnname[1];
                  } else obj[`${columnNames[index + i]} Year`] = "none";
                }
              }
            });
            rowData.push(obj);
          });
        }
      } catch (error) {}
    }
  };

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
    // vidRef.current.play();
  };

  const onPlayerPlay = () => {
    setIsPlay(true);
  };
  const setImageClass = (BannerImage) => {
    setBannerClass("banner-wrap-full");
    return (
      <div className="home-banner-outer">
        <div className="banner-bg">
          <div class="main-container banner-container clearfix slide-two d-flex align-items-center">
            <div
              className="image-banner_2"
              style={{ backgroundImage: `url(${BannerImage})` }}
            ></div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    data &&
      Array.isArray(data.list) &&
      data?.list.length > 0 &&
      data?.list.map((value, index) => {
        if (value?.type === "banner_statistical") setBannerTableData(value);
      });
  }, [data]);

  if (bannerTableData) {
    reformatTableData(bannerTableData);
  }

  return (
    <div className="banner-content clearfix">
      <div id="banner-wrap-id">
        <Youtube
          videoUrl={url}
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />

        <div className="home-banner-inner-wrap">
          <Slider {...settings}>
            {data &&
              Array.isArray(data.list) &&
              data?.list.length > 0 &&
              // && data?.list.slice(0, 1).map((value, index) => {
              data?.list.map((value, index) => {
                return (
                  <div>
                    {value?.type === "banner_video" && (
                      <div className="banner-wrap">
                        <div className="home-banner-inner-wrap">
                          <div className="home-banner-outer">
                            <div className="banner-bg">
                              <div class="main-container banner-container clearfix">
                                <div class="top-banner-block">
                                  <div className="yt-wrapper">
                                    <div className="yt-inner">
                                      {!isPlay && (
                                        <div className="video-cover">
                                          <img
                                            className=""
                                            src={value?.BannerImage}
                                            alt=""
                                          />
                                        </div>
                                      )}

                                      <Player
                                        playsInline
                                        poster={
                                          // index === 2
                                          //   ? bannervidimage
                                          //   : value?.BannerImage
                                          value?.BannerImage
                                        }
                                        src={
                                          // index === 2
                                          //   ? bannervid3
                                          //   : value?.BannerVideo
                                          value?.BannerVideo
                                        }
                                        onPlay={onPlayerPlay}
                                        fullscreen={true}
                                      />

                                      {/* <h3 className="video-title">{ReactHtmlParser(value?.BannerTitle)}</h3> */}
                                    </div>
                                  </div>
                                  <div className="banner-right-block">
                                    {/* <img src={slideLogo} /> */}
                                    {index === 2 ? (
                                      <p
                                        style={{
                                          // fontSize: "41px",
                                          fontSize: "22px",
                                          fontWeight: "bold",
                                          fontFamily: "Chivo, sans-serif",
                                          /* color: #000000; */
                                          lineHeight: 1,
                                          marginBottom: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        {/* SSF 2022 Showcase */}
                                        {/* Swachhta Saarthi Samaroh */}
                                        "One step closer to a zero-waste nation
                                        - Swachhta Saarthi Fellowship 2021-2022"
                                      </p>
                                    ) : (
                                      <img
                                        style={{ marginTop: "17px" }}
                                        src={slideLogo}
                                      />
                                    )}
                                    {index === 2 ? (
                                      <>
                                        <p>
                                          The 343 Swachhta Saarthis of Cohort
                                          2021-22 have so far impacted the lives
                                          of 64000+ people and conducted 800+
                                          awareness sessions/workshops across
                                          the nation. Have a glimpse of their
                                          amazing work here!
                                        </p>
                                        <a
                                          href="/fellowship-home"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "26px",
                                            /* color: #282c69; */
                                            fontFamily: "PT Serif, serif",
                                            fontWeight: 700,
                                          }}
                                        >
                                          To know more
                                        </a>
                                      </>
                                    ) : (
                                      <p>
                                        The Waste to Wealth Mission brings
                                        scientific processing of waste to the
                                        forefront to build a zero landfill and
                                        zero waste nation. The portal shall act
                                        as a common platform for technology
                                        providers, government stakeholders,
                                        urban local bodies, and users to seek
                                        relevant solutions.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {value?.type === "banner_textual_with_image" && (
                      <div className="banner-wrap">
                        <div className="home-banner-inner-wrap">
                          <div className="home-banner-outer">
                            <div className="banner-bg">
                              <div class="main-container banner-container clearfix slide-two d-flex align-items-center">
                                <div className="row d-flex align-items-center">
                                  {value?.BannerTitle != null &&
                                  value?.BannerSubtitle != null ? (
                                    <div>
                                      <div className="col-md-6">
                                        <div className="img-wrap">
                                          <img
                                            height="320"
                                            src={value?.BannerImage}
                                            alt=""
                                          />
                                          <span className="cat-tag">
                                            {value?.BannerTextonImage}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-6 pl-20 text-center">
                                        <h3 className="text-center">
                                          {ReactHtmlParser(value?.BannerTitle)}
                                        </h3>
                                        <div className="sub-title">
                                          {ReactHtmlParser(
                                            value?.BannerSubtitle
                                          )}
                                        </div>
                                        {value?.link && (
                                          <Link
                                            to={value?.BannerLink[0]?.url}
                                            className="green-btn"
                                            target={"_blank"}
                                          >
                                            {value?.BannerLink[0]?.title}
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-md-12">
                                      <div className="img-wrap">
                                        <img
                                          height="320"
                                          src={value?.BannerImage}
                                          alt=""
                                        />
                                        <span className="cat-tag">
                                          {value?.BannerTextonImage}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-md-6 pl-20 text-center">
                                    <h3 className="text-center">
                                      {ReactHtmlParser(value?.BannerTitle)}
                                    </h3>
                                    <div className="sub-title">
                                      {ReactHtmlParser(value?.BannerSubtitle)}
                                    </div>
                                    {value?.link && (
                                      <Link
                                        to={value?.BannerLink[0]?.url}
                                        className="green-btn"
                                        target={"_blank"}
                                      >
                                        {value?.BannerLink[0]?.title}
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {value?.type === "banner_statistical" && (
                      <>
                        {/* For Desktop view */}
                        <div
                          className="home-banner-outer bannerdesktop"
                          // style={{
                          //   backgroundColor: "#41b14c",
                          // }}
                        >
                          <div
                            className="banner-bg-bannerStat"
                            style={{
                              backgroundImage: `url(${value?.BannerImage})`,
                              // backgroundImage: `url(${newBanner})`,
                            }}
                          >
                            <div class="main-container banner-container clearfix slide-two d-flex align-items-center"></div>
                          </div>
                        </div>

                        {/* For mobile view */}
                        {/* <div className="banner-wrap1 bannermobile">
                          <div className="home-banner-outer1">
                            <div
                              className="banner-bg-bannerStat"
                              style={{
                                backgroundImage: `url(${value?.BannerImage})`,
                              }}
                            >
                              <div class="main-container banner-container clearfix slide-two d-flex align-items-center"></div>
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}

                    {value?.type === "banner_team" && (
                      <>
                        {/* For Desktop view */}
                        <div
                          className="home-banner-outer bannerdesktop"
                          style={{
                            backgroundColor: "#41b14c",
                          }}
                        >
                          <div
                            className="banner-bg-bannerStat"
                            style={{
                              backgroundImage: `url(${value?.BannerImage})`,
                              // backgroundImage: `url(${newBanner})`,
                            }}
                          >
                            <div class="main-container banner-container clearfix slide-two d-flex align-items-center"></div>
                          </div>
                        </div>

                        {/* For mobile view */}
                        {/* <div className="banner-wrap1 bannermobile">
                          <div className="home-banner-outer1">
                            <div
                              className="banner-bg-bannerStat"
                              style={{
                                backgroundImage: `url(${value?.BannerImage})`,
                              }}
                            >
                              <div class="main-container banner-container clearfix slide-two d-flex align-items-center"></div>
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}

                    {/* {
                                            value?.type === 'banner_statistical' &&
                                            <div className="home-banner-outer" >
                                                <div className="banner-bg">
                                                    <div class="main-container banner-container clearfix slide-two d-flex align-items-center">
                                                        <div className="image-banner_2" style={{ backgroundImage: `url(${value?.BannerImage})` }}>
                                                            <div className="mobile-image"></div>
                                                            <div className="banner-content">
                                                            </div>
                                                            {rowData.length >0 &&
                                                            <div className={`banner-table table-size-${rowData.length}`}>
                                                                <div className={`grid-row table-header divisions-${columnNames.length}`}>
                                                                    {columnNames.map(item =>
                                                                        <div className={`column-name divisions-${columnNames.length}`}>
                                                                            {item}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {rowData.map((row) => (
                                                                    <div className={`grid-row table-content divisions-${columnNames.length}`}>
                                                                        {columnNames.map((column, index) => (
                                                                            <div className={`column-name divisions-${columnNames.length}`}>
                                                                                {ReactHtmlParser(row[column])}
                                                                                {index !== 0 ? <span>{row[column] ? `${row[column + " Year"]}` : 'none'}</span> : <span>{row.category}</span>}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                    {value?.type === "banner_textual" && (
                      <div className="banner-wrap">
                        <div className="home-banner-inner-wrap">
                          <div className="home-banner-outer">
                            <div className="banner-bg">
                              <div class="main-container banner-container clearfix slide-two d-flex align-items-center">
                                <div
                                  className="image-banner_1"
                                  style={{
                                    backgroundImage: `url(${value?.BannerImage})`,
                                  }}
                                >
                                  <div className="mobile-image"></div>
                                  <div className="banner-content">
                                    <p className="banner-title">
                                      {ReactHtmlParser(value?.BannerTitle)}
                                    </p>
                                    <p className="banner-subtitle mb-4">
                                      {ReactHtmlParser(value?.BannerSubtitle)}
                                    </p>
                                    <Link
                                      to={value?.BannerLink[0].url}
                                      className="green-btn"
                                    >
                                      {value?.BannerLink[0].title}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </Slider>

          <div class="social-icon-wrap">
            {data &&
              Array.isArray(data?.socialIcons) &&
              data?.socialIcons?.length > 0 &&
              data?.socialIcons.map((socialData, socialIndex) => {
                return (
                  <div className="social-icon" key={socialIndex}>
                    <a href={socialData?.url} target="_blank">
                      <img
                        src={socialData?.image}
                        className={"social_image"}
                        alt=""
                      />
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Banner);
