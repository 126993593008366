// import { contactUsService } from './service';
import { isLoading } from '../../../../dependencies/action/action';
import {
    SELECT_LANGUAGE
} from '../../../../constants/actionConstants';
import { toast } from "react-toastify";



// export const submitContactUs = (params) => async (dispatch) => {
//     dispatch(isLoading(true))
//     return await contactUsService.submitContactUs(params).then((response) => {
//         dispatch(isLoading(false));
//         const { status, data } = response || {}
//         if (status === 200) {
//             toast.success(data.message);
//             return { status: true };
//         } else {
//             dispatch(isLoading(false));
//             toast.error(data.message);
//             return { status: false }
//         }
//     }).catch(e => {
//         dispatch(isLoading(false));
//         toast.error('Something Went Wrong');
//         return { status: false }
//     })
// }

export function setAppLanguage(value) {
    
    return async function (dispatch) {
        // let contactUsData = await contactUsService.contactUsData();
        dispatch({
            type: SELECT_LANGUAGE,
            payload: value
        })
    }
} 
