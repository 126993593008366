import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { useController } from "react-scroll-parallax";
const AboutFellowship = (props) => {
  const { data } = props;
  const { parallaxController } = useController();
  useEffect(() => {
    console.log(data?.images[0]);
  });
  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);
  return (
    <div className="blue-bg bg-about-fellowship custom-slider-nextPrev">
      <div className="yellow-line" style={{ zIndex: -1 }} />
      <div className="blue-line" style={{ zIndex: -1 }} />
      <div className="green-line" style={{ zIndex: -1 }} />

      {/* <Parallax className="dotted-hexagon left" y={['-30px', '30px']} tagOuter="div">
                    <div className="dotted-hexagon-img " />
                </Parallax>
                <Parallax className="dotted-hexagon right" y={['30px', '-30px']} tagOuter="div">
                    <div className="dotted-hexagon-img" />
                </Parallax>
                <div className="bulb glow" /> */}
      <div className="container">
        <div className=" green-leaf-another" />
        {/* <div className="row">
                        <div className="col-12 d-flex align-item-center margin-left-auto quiz-header">
                            <h2>{ReactHtmlParser(data?.heading)}</h2>
                        </div>
                    </div> */}
        <div className="row">
          <div className="col-lg-12">
            <div className="about-fellow-title">
              <span>About Fellowship</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="rowcls">
              <div className="columncls">
                <img src={data?.images[0]} className="about-fellow-image1" />
                <img src={data?.images[1]} className="about-fellow-image2" />
              </div>
              <div className="columncls">
                <img src={data?.images[2]} className="about-fellow-image3" />
                <img src={data?.images[3]} className="about-fellow-image4" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-fellow-content-title">
              {/* <span>{ReactHtmlParser(data?.heading)}</span> */}
            </div>
            <div className="about-fellow-content">
              <span>{ReactHtmlParser(data?.description)}</span>
              <div className="about-fellow-row row">
                {data?.pdf[0] && (
                  <div className="about-fellow-pdf-download">
                    <a
                      href={data?.pdf[0].pdf_file}
                      target="_blank"
                      className="download-btn"
                    >
                      <p className="pdf-icon"> {data?.pdf[0].pdf_title}.pdf</p>
                    </a>
                  </div>
                )}
                {data?.pdf[1] && (
                  <div className="about-fellow-pdf-download">
                    <p className="pdf-icon"> {data?.pdf[1].pdf_title}.pdf</p>
                  </div>
                )}
              </div>
              <a href="#" target="_blank" className="download-btn"></a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='row'> */}
      <div className="row-about-fellow">
        <div className="green-leaf " />
        <div className="large-tree " />
      </div>
      {/* </div> */}
    </div>
  );
};

export default AboutFellowship;
