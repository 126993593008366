import rightArrow from '../../../assests/icons/right-arrow.svg';
import PropTypes from 'prop-types';
import './style.scss';

const LeftSideCategory = ({ categoryName, count, onClickReply, className }) => {

    return (

        <div onClick={() => onClickReply()} className={`category-name ${className}`}>
            <img src={rightArrow} className='category-arrow' alt="" />
            <span className='category-name-text'>{categoryName}</span>
            <span className='category-count'>{count}</span>
        </div>
    )
}



LeftSideCategory.propTypes = {
    categoryName: PropTypes.string,
    count: PropTypes.number,
    className: PropTypes.string,
    onClickReply: PropTypes.func
}

LeftSideCategory.defaultProps = {
    categoryName: '',
    count: 0,
    className: 5,
    onClickReply: () => { },
}

export default LeftSideCategory;