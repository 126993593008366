/* eslint-disable jsx-a11y/anchor-is-valid */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getSwachhtaSarthiFellowship,
  getHomePageData,
  getFellowshipInitiatives,
  getSwachhtaSarthiMap,
  getSwachhtaSarthiMentor,
  getSwachhtaSarthiFellows,
} from "../dependencies/action";
import SSQuotes from "../dependencies/component/SSQuotes";
import AboutFellowship from "../dependencies/component/AboutFellowship";
import AboutMentor from "../dependencies/component/AboutMentor";
import MeetOurFellows from "../dependencies/component/MeetOurFellows";
import RecentNews from "../dependencies/component/RecentNews";
import ManagementTeam from "../dependencies/component/ManagementTeam";
import AboutCohort from "../dependencies/component/AboutCohort";
import WantToBeFellow from "../dependencies/component/WantToBeFellow";
import SSInitiatives from "../dependencies/component/SSInitiatives";
import Breadcrumb from "../../common/Breadcrumbs";
import { useEffect, useState, useRef } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import "../style.scss";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";
import GallerySection3 from "../SSFVIDEOS";
import { getEventDetails2 } from "../../../components/Events/dependencies/action";

const items = [
  { to: "/", label: "/" },
  { to: "/fellowship-home", label: "Fellowship" },
];

const SwachhtaSarthiFellowship = (props) => {
  const {
    getSwachhtaSarthiFellowship,
    aboutUsData,
    getHomePageData,
    latestNewsList,
    getFellowshipInitiatives,
    fellowshipInitiatives,
    eventDetails,
    getEventDetails2,
    getSwachhtaSarthiMap,
    fellowshipMapData,
    getSwachhtaSarthiMentor,
    fellowshipMentorData,
    fellowshipFellowsData,
    getSwachhtaSarthiFellows,
  } = props;
  const myRefs = useRef([]);

  const [ssquotes, setSSQutes] = useState(false);
  const [aboutfellowship, setAboutFellowship] = useState(false);
  const [wantobefellow, setWanttobeFellow] = useState(false);
  const [aboutmentors, setAboutMentors] = useState(false);
  const [meetourfellows, setmeetourfellows] = useState(false);

  const [aboutcohorts, setAboutCohort] = useState(false);
  const [ssinitiatives, setSSinitiaves] = useState(false);
  const [recentnews, setRecentNews] = useState(false);
  const [managementteam, setManagementTeam] = useState(false);
  const [ssfVideo, setSSfVideo] = useState(false);

  const [eventdata, seteventData] = useState(false);
  const [mapYearSelected, setMapYearSelected] = useState({
    value: "2022",
    label: "2022",
  });

  const [initYearSelected, setInitYearSelected] = useState({
    value: "2021",
    label: "2021",
  });

  const [videoYearSelected, setVideoYearSelected] = useState({
    value: "2021",
    label: "2021",
  });

  const [newYearSelected, setNewYearSelected] = useState({
    // value: "2021",
    // label: "2021",
    value: "2022",
    label: "2022",
  });

  const [newfellowyearSelected, setNewFellowyearSelected] = useState({
    value: "2022",
    label: "2022",
  });

  // useEffect(() => {
  //   getFellowshipInitiatives();
  // }, []);

  useEffect(() => {
    getSwachhtaSarthiFellowship();
  }, []);

  useEffect(() => {
    // getSwachhtaSarthiFellowship();
    getSwachhtaSarthiMap(
      mapYearSelected.value === "2022"
        ? "/fellowship-home-2022"
        : "/fellowship-home"
    );
  }, [mapYearSelected]);

  useEffect(() => {
    // getSwachhtaSarthiFellowship();
    getFellowshipInitiatives(
      initYearSelected.value === "2022"
        ? "Fellowship%202022"
        : "Fellowship%202021"
    );
  }, [initYearSelected]);

  useEffect(() => {
    // getSwachhtaSarthiFellowship();
    getSwachhtaSarthiMentor(
      newYearSelected.value === "2022"
        ? "/fellowship-home-2022"
        : "/fellowship-home"
    );
  }, [newYearSelected]);

  useEffect(() => {
    getSwachhtaSarthiFellows(
      newfellowyearSelected.value === "2022"
        ? "/swachhta-saarthi-fellowship-cohort-2022"
        : "/test-fellowship"
    );

    console.log(`selected value ${newfellowyearSelected.value}`);
  }, [newfellowyearSelected]);

  useEffect(() => {
    getHomePageData();
  }, [getHomePageData]);

  // useEffect(() => {
  //   getFellowshipInitiatives();
  // }, []);

  // useEffect(() => {
  //   let str = window.location.pathname;
  //   getEventDetails2(str.split("/")[2]);
  // }, [getEventDetails2]);

  useEffect(() => {
    let str = window.location.pathname;
    getEventDetails2(
      videoYearSelected.value === "2022"
        ? "Fellowship%202022"
        : "Fellowship%202021"
    );
  }, [videoYearSelected]);
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });
  useEffect(() => {
    checkScrollTop();
  }, []);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  // const checkScrollTop = () => {
  //   console.log("window.pageYOffset", window.pageYOffset);
  //   if (window.pageYOffset > 100 && window.pageYOffset < 640) {
  //     setSSQutes(true);
  //     setWanttobeFellow(false);
  //     setAboutFellowship(false);
  //     setAboutMentors(false);
  //     setAboutCohort(false);
  //     setSSinitiaves(false);
  //     setRecentNews(false);
  //     setManagementTeam(false);
  //     setSSfVideo(false);
  //   } else if (window.pageYOffset > 640 && window.pageYOffset < 1150) {
  //     setSSQutes(false);
  //     setAboutFellowship(false);
  //     setWanttobeFellow(false);
  //     setAboutMentors(false);
  //     setAboutCohort(true);
  //     setSSinitiaves(false);
  //     setRecentNews(false);
  //     setManagementTeam(false);
  //     setSSfVideo(false);
  //   } else if (window.pageYOffset > 1201 && window.pageYOffset < 1859) {
  //     setSSQutes(false);
  //     setAboutFellowship(true);
  //     setWanttobeFellow(false);
  //     setAboutMentors(false);
  //     setAboutCohort(false);
  //     setSSinitiaves(false);
  //     setRecentNews(false);
  //     setManagementTeam(false);
  //     setSSfVideo(false);
  //   } else if (window.pageYOffset > 1960 && window.pageYOffset < 2890) {
  //     setSSQutes(false);
  //     setWanttobeFellow(false);
  //     setAboutFellowship(false);
  //     setAboutMentors(false);
  //     setAboutCohort(false);
  //     setSSinitiaves(true);
  //     setSSfVideo(false);
  //     setRecentNews(false);
  //     setManagementTeam(false);
  //   } else if (window.pageYOffset > 2890 && window.pageYOffset < 3600) {
  //     setSSQutes(false);
  //     setWanttobeFellow(false);
  //     setAboutFellowship(false);
  //     setAboutMentors(false);
  //     setAboutCohort(false);
  //     setSSinitiaves(false);
  //     setSSfVideo(true);
  //     setRecentNews(false);
  //     setManagementTeam(false);
  //   } else if (window.pageYOffset > 3600 && window.pageYOffset < 4098) {
  //     setSSQutes(false);
  //     setWanttobeFellow(false);
  //     setAboutFellowship(false);
  //     setAboutMentors(false);
  //     setAboutCohort(false);
  //     setSSinitiaves(false);
  //     setSSfVideo(false);
  //     setRecentNews(true);
  //     setManagementTeam(false);
  //   } else if (window.pageYOffset > 4098 && window.pageYOffset < 4398) {
  //     setSSQutes(false);
  //     setWanttobeFellow(false);
  //     setAboutFellowship(false);
  //     setAboutMentors(true);
  //     setAboutCohort(false);
  //     setSSinitiaves(false);
  //     setSSfVideo(false);
  //     setRecentNews(false);
  //     setManagementTeam(false);
  //   } else if (window.pageYOffset > 4398 && window.pageYOffset < 4598) {
  //     setSSQutes(false);
  //     setWanttobeFellow(false);
  //     setAboutFellowship(false);
  //     setAboutMentors(false);
  //     setAboutCohort(false);
  //     setSSinitiaves(false);
  //     setSSfVideo(false);
  //     setRecentNews(false);
  //     setManagementTeam(true);
  //   }
  // };

  const checkScrollTop = () => {
    console.log("window.pageYOffset", window.pageYOffset);
    if (window.pageYOffset > 100 && window.pageYOffset < 640) {
      setSSQutes(true);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(false);
    } else if (window.pageYOffset > 596 && window.pageYOffset < 1150) {
      setSSQutes(false);
      setAboutCohort(true);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(false);
      // } else if (window.pageYOffset > 1201 && window.pageYOffset < 1859) {
    } else if (window.pageYOffset > 1201 && window.pageYOffset < 2090) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(true);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(false);
    } else if (window.pageYOffset > 2091 && window.pageYOffset < 2876) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(true);
      setWanttobeFellow(false);
      // setSSinitiaves(false);
      setSSinitiaves(true);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(false);
      // } else if (window.pageYOffset > 2550 && window.pageYOffset < 2800) {
    } else if (window.pageYOffset > 2091 && window.pageYOffset < 2876) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(true);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(false);
    } else if (window.pageYOffset > 2995 && window.pageYOffset < 3295) {
      // } else if (window.pageYOffset > 2878 && window.pageYOffset < 3600) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(true);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(false);
    } else if (window.pageYOffset > 3295 && window.pageYOffset < 4106) {
      // } else if (window.pageYOffset > 3642 && window.pageYOffset < 4224) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(true);
      // } else if (window.pageYOffset > 4305 && window.pageYOffset < 4598) {
    } else if (window.pageYOffset > 4106 && window.pageYOffset < 4649) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(false);
      setRecentNews(true);
      setAboutMentors(false);
      setManagementTeam(false);
      setmeetourfellows(false);
      // } else if (window.pageYOffset > 4684 && window.pageYOffset < 5209) {
    } else if (window.pageYOffset > 4749 && window.pageYOffset < 4900) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(true);
      setManagementTeam(false);
      setmeetourfellows(false);
      // } else if (window.pageYOffset > 5128 && window.pageYOffset < 5730) {
    } else if (window.pageYOffset > 4900 && window.pageYOffset < 5730) {
      setSSQutes(false);
      setAboutCohort(false);
      setAboutFellowship(false);
      // setWanttobeFellow(false);
      setSSinitiaves(false);
      setSSfVideo(false);
      setRecentNews(false);
      setAboutMentors(false);
      setManagementTeam(true);
      setmeetourfellows(false);
    }
  };

  const executeScroll = (data) => {
    console.log("executeScroll", myRefs.current[data]);
    if (data === 0) {
      myRefs.current[data].scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else {
      myRefs.current[data].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  return (
    <div className="about-us swchtamaindiv">
      <Breadcrumb data={items} displayName={"Swachhta Saarthi Fellowship"} />
      <div className="fixed-tabs">
        <div className="container-data">
          <div className="container-wrap">
            <div className="row justify-content-center">
              {/* <div className="col-lg-3">
                <span
                  className={
                    ssquotes ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(0);
                    }}
                  >
                    QUOTES
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    aboutcohorts ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(1);
                    }}
                  >
                    FELLOWSHIP'S REACH
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    aboutfellowship ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(2);
                    }}
                  >
                    ABOUT FELLOWSHIP
                  </a>
                </span>
              </div>
             
              <div className="col-lg-3">
                <span
                  className={
                    ssinitiatives ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(4);
                    }}
                  >
                    INITIATIVES
                  </a>
                </span>
              </div>

              <div className="col-lg-3">
                <span
                  className={
                    ssfVideo ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(5);
                    }}
                  >
                   
                    WORK DONE BY THE FELLOWS
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    recentnews ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(6);
                    }}
                  >
                    RECENT NEWS
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    aboutmentors ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(7);
                    }}
                  >
                    ABOUT MENTORS
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    managementteam ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(8);
                    }}
                  >
                    MANAGEMENT TEAM
                  </a>
                </span>
              </div> */}

              <div className="col-lg-1">
                <span
                  className={
                    ssquotes ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(0);
                    }}
                  >
                    QUOTES
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    aboutcohorts ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(1);
                    }}
                  >
                    {/* MEET OUR&nbsp;&nbsp;&nbsp; FELLOWS */}
                    FELLOWSHIP'S REACH
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    aboutfellowship ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(2);
                    }}
                  >
                    ABOUT FELLOWSHIP
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    ssinitiatives ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(4);
                    }}
                  >
                    {/* WANT TO BE A FELLOW? */}
                    INITIATIVES
                  </a>
                </span>
              </div>
              {/* <div className="col-lg-1">
                <span
                  className={
                    ssinitiatives ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(4);
                    }}
                  >
                    INITIATIVES
                  </a>
                </span>
              </div> */}
              <div className="col-lg-3">
                <span
                  className={
                    ssfVideo ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(5);
                    }}
                  >
                    {/* SSF VIDEOS */}
                    {/* MEET OUR&nbsp;&nbsp;&nbsp; FELLOWS */}
                    WORK DONE BY THE FELLOWS.
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    meetourfellows ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(6);
                    }}
                  >
                    MEET OUR FELLOWS
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    recentnews ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(7);
                    }}
                  >
                    RECENT NEWS
                  </a>
                </span>
              </div>
              <div className="col-lg-1">
                <span
                  className={
                    aboutmentors ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(8);
                    }}
                  >
                    ABOUT MENTORS
                  </a>
                </span>
              </div>
              <div className="col-lg-3">
                <span
                  className={
                    managementteam ? "fixed-tab-name active" : "fixed-tab-name"
                  }
                >
                  <a
                    onClick={() => {
                      executeScroll(9);
                    }}
                  >
                    {/* MANAGEMENT TEAM */}
                    MANAGEMENT TEAM
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ParallaxProvider>
        <div>
          <div id="ssquotes" ref={(el) => (myRefs.current[0] = el)}>
            <SSQuotes data={aboutUsData?.quotes} />
          </div>

          <div id="about-cohort" ref={(el) => (myRefs.current[1] = el)}>
            <AboutCohort
              setMapYearSelected={setMapYearSelected}
              data={
                // aboutUsData?.cohort_data
                fellowshipMapData?.data?.cohort_data
              }
            />
          </div>
          <div
            className="about-fellowship-bg"
            id="aboutfellowship"
            ref={(el) => (myRefs.current[2] = el)}
          >
            <AboutFellowship data={aboutUsData?.about_fellowship} />
          </div>
          {/* <div id="wanttobefellow" ref={(el) => (myRefs.current[3] = el)}>
            <WantToBeFellow data={aboutUsData?.quotes} />
          </div> */}
          <div
            id="swachhtaSarthiInitives"
            ref={(el) => (myRefs.current[4] = el)}
          >
            {/* <SSInitiatives
              data={fellowshipInitiatives}
              heading={aboutUsData?.meet_team?.heading}
              description={aboutUsData?.meet_team?.description}
              count={3}
            /> */}

            <SSInitiatives
              setInitYearSelected={setInitYearSelected}
              data={fellowshipInitiatives?.data?.list}
              heading={aboutUsData?.meet_team?.heading}
              description={aboutUsData?.meet_team?.description}
              count={3}
            />
          </div>
          <div id="ssfvideo" ref={(el) => (myRefs.current[5] = el)}>
            <GallerySection3
              setVideoYearSelected={setVideoYearSelected}
              data={
                eventDetails?.list[0]?.Gallery?.videos
                  ? eventDetails?.list[0]?.Gallery?.videos
                  : eventdata?.event_gallery_images
              }
              firstvid={eventDetails?.list[0]?.Gallery?.videos?.url}
            />
          </div>
          <div
            id="meettheteam meetourfellows"
            ref={(el) => (myRefs.current[6] = el)}
            style={{ marginTop: "5%" }}
          >
            <MeetOurFellows
              setNewFellowyearSelected={setNewFellowyearSelected}
              data={fellowshipFellowsData?.data?.mentor_team?.team_list}
              heading={fellowshipFellowsData?.data?.mentor_team?.heading}
              description={
                fellowshipFellowsData?.data?.mentor_team?.description
              }
              count={
                fellowshipFellowsData?.data?.mentor_team?.team_list.length > 5
                  ? 5
                  : fellowshipFellowsData?.data?.mentor_team?.team_list.length
              }
            />
          </div>
          <div id="recentnews" ref={(el) => (myRefs.current[7] = el)}>
            <RecentNews
              data={latestNewsList}
              count={latestNewsList.length > 3 ? 3 : latestNewsList.length}
            />
          </div>
          <div
            id="meettheteam"
            ref={(el) => (myRefs.current[8] = el)}
            style={{ marginTop: "5%" }}
          >
            {/* <AboutMentor
              data={aboutUsData?.mentor_team?.team_list}
              heading={aboutUsData?.mentor_team?.heading}
              description={aboutUsData?.mentor_team?.description}
              count={
                aboutUsData?.mentor_team?.team_list.length > 5
                  ? 5
                  : aboutUsData?.mentor_team?.team_list.length
              }
            /> */}
            <AboutMentor
              setNewYearSelected={setNewYearSelected}
              data={fellowshipMentorData?.data?.mentor_team?.team_list}
              heading={fellowshipMentorData?.data?.mentor_team?.heading}
              description={fellowshipMentorData?.data?.mentor_team?.description}
              count={
                fellowshipMentorData?.data?.mentor_team?.team_list.length > 5
                  ? 5
                  : fellowshipMentorData?.data?.mentor_team?.team_list.length
              }
            />
          </div>

          <div id="managementteam" ref={(el) => (myRefs.current[9] = el)}>
            <ManagementTeam
              data={aboutUsData?.management_team?.members}
              heading={aboutUsData?.management_team?.heading}
              description={aboutUsData?.management_team?.description}
              count={3}
            />
          </div>
        </div>
      </ParallaxProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state.HomeReducer.latestNewsList);
  console.log(state.StaticPageReducer);
  console.log("Initiative List=", state);
  return {
    aboutUsData: state.StaticPageReducer.aboutUs.data,
    latestNewsList: state.HomeReducer.latestNewsList,
    // fellowshipInitiatives:
    //   state.SwachhtaSarthiHomeReducer.fellowshipInitiatives.list,
    fellowshipInitiatives:
      state.SwachhtaSarthiHomeReducer.fellowshipInitiatives,
    fellowshipMapData: state.FellowshipComponentsReducer.fellowshipMap,
    fellowshipMentorData: state.FellowshipComponentsReducer.fellowshipMentor,
    fellowshipFellowsData: state.FellowshipComponentsReducer.fellowshipFellows,

    eventDetails: state.EventReducer.eventDetails2.data,
  };
};
export default connect(mapStateToProps, {
  getSwachhtaSarthiFellowship,
  getFellowshipInitiatives,
  getHomePageData,
  getEventDetails2,
  getSwachhtaSarthiMap,
  getSwachhtaSarthiMentor,
  getSwachhtaSarthiFellows,
})(withRouter(SwachhtaSarthiFellowship));
