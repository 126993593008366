import React, { useEffect } from "react";
import ResultImageCard from "../../common/ResultImageCard";
import "./style.scss";
import img from "../../../assests/images/dummy_img.png";
import Scrollbar from "react-scrollbars-custom";
import Button from "../../common/Button";
import { withRouter, Link, useHistory } from "react-router-dom";
const AllResults = (props) => {
  const { data, setShowDetailPage } = props;
  const history = useHistory();

  const downloadFile = (url, fileName) => {
    //  fileName = fileName+".pdf"
    //Create XMLHTTP Request.
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      let blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      let isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };

  useEffect(() => {
    console.log("result data", data);
  }, []);
  return (
    <div className="all-results">
      <div className="container">
        <div className="view-all-wrap d-flex justify-content-start mr-left20">
          <Link
            onClick={() => {
              history.push({
                // pathname: `/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022`,
                pathname: `/fellowship-home`,
                state: {
                  // date: date,
                  // cardTitle: cardTitle,
                  // mainTitle: mainTitle,
                  showDetailPage: true,
                  pageLink: "/swachhta-saarthi-fellowship-cohort-2022",
                  // pageLink: value?.pagelink,
                  // pageLink: pageLink,
                  // fromModule: "directlyfromlist",
                },
              });
              window.location.reload();

              // setShowDetailPage(true);
            }}
            className=" align-items-center green-circle-link"
            to={{
              state: {
                // date: date,
                // cardTitle: cardTitle,
                // mainTitle: mainTitle,
                pageLink: "/swachhta-saarthi-fellowship-cohort-2022",
                // pageLink: value?.pagelink,
                // pageLink: pageLink,
                // fromModule: "directlyfromlist",
              },

              pathname:
                "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022",
              // "/fellowship-home",
            }}
          >
            <i className="back-circle-goto-link-green" /> <p>Back</p>
          </Link>
        </div>
        <div className="d-flex justify-content-start all-result-ttl-wrap align-items-start">
          <div className="inner">
            <h2 className="section-title">
              {data?.title} <strong>({data?.count})</strong>
            </h2>
            <div className="disclaimer-text-result">
              After the overwhelming response received, these fellows have been
              selected from across 33 states subject to verification of
              documents:
            </div>
          </div>
          {/* <Button
            text="Download Result"
            onClick={() => {
              downloadFile(
                data?.pdf,
                "Swachhta Saarthi Fellowships Announced.pdf"
              );
            }}
            className="green-btn filter-btn"
          /> */}
          <a
            className="green-btn filter-btn"
            download
            href={`${data?.pdf}`}
            // href={"javascript:void(0)"}

            style={{ marginLeft: "-10%" }}
          >
            {" "}
            Download Result
          </a>
        </div>
        <div className="result-outer">
          {data?.list.length > 24 ? (
            <Scrollbar style={{ height: 490 }}>
              <div className="result-inner">
                {data?.list.map((item, index) => {
                  return (
                    <ResultImageCard
                      item={item}
                      key={index}
                      imgUrl={item?.image}
                      imgCaption={item?.name}
                    />
                  );
                })}
              </div>
            </Scrollbar>
          ) : (
            <div className="result-inner new">
              {data?.list.map((item, index) => {
                return (
                  <ResultImageCard
                    item={item}
                    imgUrl={item?.image}
                    imgCaption={item?.name}
                  />
                );
              })}
            </div>
          )}
          {/* <div className='disclaimer-text'>
                        *Subject to document submission and verification
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default AllResults;
