import { useState } from "react";
import * as moment from "moment";
import ReactHtmlParser from "react-html-parser";
import Button from "../../../../common/Button";
import { Route, withRouter, useHistory, Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import InlineError from "../../../../common/InlineError";
import FellowshipForm from "../../../../FellowshipForm";
import "./style.scss";

const Overview = (props) => {
  const { data, type, lang } = props;
  const [message, setMessage] = useState("");
  const [disabled, setDisable] = useState(false);

  let history = useHistory();

  const redirectToForm = () => {
    // props.history.push("/fellowship-form");
    history.push({
      pathname: "/fellowship-form",
      state: { date: data?.closing?.full_date },
    });
  };

  let countDownDate = new Date(data?.closing?.full_date).getTime();
  // Update the count down every 1 second
  let x = setInterval(function () {
    // debugger

    // Get today's date and time
    let now = new Date().getTime();

    // Find the distance between now and the count down date
    let distance = countDownDate - now;
    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x);
      setMessage(
        "The end date of the application is past, Please refer to the Start & End date of the Application"
      );
      setDisable(true);
    }
  }, 1000);
  return (
    <div className="overview-section">
      <div className="container">
        <div className="container-wrap">
          {/* <div className="view-all-wrap d-flex justify-content-start mr-left20">
            <Link
              className=" align-items-center green-circle-link"
              to={{ pathname: "/challenge-listing" }}
            >
              <i className="back-circle-goto-link-green" /> <p>Back</p>
            </Link>
          </div> */}
          <div className="row" style={{ paddingTop: "20px" }}>
            <div
              style={{ fontFamily: "'Chivo',sans-serif", fontSize: "43px" }}
              className="col-12"
            >
              <p>{data?.overview?.title}</p>
            </div>
            <div className="col-12 pastprojoverviewbody">
              {/* <h3> {ReactHtmlParser(mainTitle)}</h3> */}
              {ReactHtmlParser(data?.overview?.body)}
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                mollitia, molestiae quas vel sint commodi repudiandae
                consequuntur voluptatum laborum numquam blanditiis harum
                quisquam eius sed odit fugiat iusto fuga praesentium optio,
                eaque rerum! Provident similique accusantium nemo autem.
                Veritatis obcaecati tenetur iure eius earum ut molestias
                architecto voluptate aliquam nihil, eveniet aliquid culpa
                officia aut! Impedit sit sunt quaerat, odit, tenetur error,
                harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.
                Quo neque error repudiandae fuga? Ipsa laudantium molestias eos
                sapiente officiis modi at sunt excepturi expedita sint? Sed
                quibusdam recusandae alias error harum maxime adipisci amet
                laborum.
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                mollitia, molestiae quas vel sint commodi repudiandae
                consequuntur voluptatum laborum numquam blanditiis harum
                quisquam eius sed odit fugiat iusto fuga praesentium optio,
                eaque rerum! Provident similique accusantium nemo autem.
                Veritatis obcaecati tenetur iure eius earum ut molestias
                architecto voluptate aliquam nihil, eveniet aliquid culpa
                officia aut! Impedit sit sunt quaerat, odit, tenetur error,
                harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.
                Quo neque error repudiandae fuga? Ipsa laudantium molestias eos
                sapiente officiis modi at sunt excepturi expedita sint? Sed
                quibusdam recusandae alias error harum maxime adipisci amet
                laborum.
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                mollitia, molestiae quas vel sint commodi repudiandae
                consequuntur voluptatum laborum numquam blanditiis harum
                quisquam eius sed odit fugiat iusto fuga praesentium optio,
                eaque rerum! Provident similique accusantium nemo autem.
                Veritatis obcaecati tenetur iure eius earum ut molestias
                architecto voluptate aliquam nihil, eveniet aliquid culpa
                officia aut! Impedit sit sunt quaerat, odit, tenetur error,
                harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.
                Quo neque error repudiandae fuga? Ipsa laudantium molestias eos
                sapiente officiis modi at sunt excepturi expedita sint? Sed
                quibusdam recusandae alias error harum maxime adipisci amet
                laborum.
              </p> */}

              <div className="tag">
                {/* {category &&
                Array.isArray(category) &&
                category.length > 0 &&
                category.map((value, key) => {
                  return <span className="green-bg">{value?.title}</span>;
                })} */}
                {/* <span className="green-bg">{category}</span> */}
              </div>
            </div>
          </div>

          {/* <div
            style={{ paddingBottom: "20px" }}
            className="header-main-top important-note"
          >
            {" "}
            <strong>Important Note</strong>
            <br />
            Due to the large number of applications received for the Swachhta
            Saarthi Fellowship Cohort 2022, the evaluation process will take
            more time and results will now be announced on 7th August 2022, 2
            PM.
            <br />
            <strong>
              Thank you once again for the overwhelming response !
            </strong>
          </div> */}
          {/* <div className="row row-res">
            <div className="col-sm-5">
              <img
                src={data?.overview?.image}
                alt=""
                className="challenge-img"
              />
            </div>
            <div className="col-sm-4">
              <div className="challenge-heading">
                {" "}
                {ReactHtmlParser(data?.overview?.title)}
              </div>
              <div className="challenge-content">
                <ShowMoreText
                  lines={9}
                  more={lang == "hi" ? "और देखें" : "Show more"}
                  less={lang == "hi" ? "कम देखें" : "Show less"}
                  className="content-css"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  // width={}
                >
                  {ReactHtmlParser(data?.overview?.body)}
                </ShowMoreText>
              </div>
              {type === "fellowship" && (
                <Button
                  disabled={disabled}
                  onClick={() => {
                    redirectToForm();
                  }}
                  text={lang == "hi" ? "आवेदन करें" : "Apply Now"}
                  className="green-btn col-sm-7 filter-btn"
                />
              )}
              {type === "fellowship" && <InlineError message={message} />}
            </div>
            <div className="col-sm-3 timeline-section">
              <div className="timelines-box">
                <div className="time-line"></div>
                <div className="timeline-heading">
                  {lang == "hi" ? "समयसीमा" : "Timelines"}
                </div>
              
                <div className="timeline-plate row no-gutters">
                  <div className=" col-sm-3 ">
                    <div className="timeline-date blue-time">
                      {ReactHtmlParser(data?.start?.date)}
                    </div>
                  </div>
                  <div className="col-sm-9 date-details">
                    <div className="timeline-section-heading">
                      {lang == "hi"
                        ? "प्रारंभ दिनांक और समय"
                        : "Start Date and Time"}
                    </div>
                    <div className="timeline-section-content">
                      {moment(data?.start?.time, ["HH:mm"]).format("hh:mm A")}
                    </div>
                  </div>
                </div>
                <div className="timeline-plate row no-gutters">
                  <div className="col-sm-3 ">
                    <div className="timeline-date yellow-time">
                      {ReactHtmlParser(data?.closing?.date)}
                    </div>
                  </div>
                  <div className="col-sm-9 date-details">
                    <div className="timeline-section-heading">
                      {lang == "hi"
                        ? "अनुप्रयोग का समापन"
                        : "Closing of Application"}
                    </div>
                    <div className="timeline-section-content">
                      {moment(data?.closing?.time, ["HH:mm"]).format("hh:mm A")}
                    </div>
                  </div>
                </div>
                <div className="timeline-plate row no-gutters">
                  <div className=" col-sm-3">
                    <div className="timeline-date green-time">
                      {ReactHtmlParser(data?.announce?.date)}
                    </div>
                  </div>
                  <div className="col-sm-9 date-details">
                    <div className="timeline-section-heading">
                      {lang == "hi"
                        ? "परिणाम की घोषणा"
                        : "Announcemnet of Result"}
                    </div>
                    <div className="timeline-section-content">
                      {moment(data?.announce?.time, ["HH:mm"]).format(
                        "hh:mm A"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Overview);
