/** 
 * This  component use for Citizen corner page 
*/
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../common/Breadcrumbs';
import CitizenConnect from './dependencies/component/CitizenConnect';
import CitizenCornerSubpage from './dependencies/component/CitizenCornerSubpage';
import CitizenEngagements from './dependencies/component/CitizenEngagements';
import FAQs from './dependencies/component/FAQs';
import IECMaterial from './dependencies/component/IECMaterial';
import InterestingFacts from './dependencies/component/InterestingFacts';
import TakeAPledge from './dependencies/component/TakeAPledge';
import CitizenQuizzes from './dependencies/component/CitizenQuizzes';
import { getCitizenCorner, getNumberOfVistiors } from './dependencies/action';
import { initGA, logPageView } from '../../dependencies/utils/analytics'
import { ParallaxProvider } from 'react-scroll-parallax';
import './style.scss';

const items = [
  { to: '/', label: '/' },
  { to: '/citizen-corner', label: 'Engage with us' },
  { to: '/citizen-corner', label: `Citizen's Corner` },
]
const CitizenCorner = (props) => {


  const { getCitizenCorner, citizenCornerData, getNumberOfVistiors, numberOfVistiors } = props;
  const myRefs = useRef([]);
  const [citizenCorner, setCitizenCorner] = useState(false);
  const [citizenConnect, setCitizenConnect] = useState(false);
  const [citizenEngagements, setCitizenEngagements] = useState(false);
  const [fAQs, setFAQs] = useState(false);
  const [iECMaterial, setIECMaterial] = useState(false);
  const [interestingFacts, setInterestingFacts] = useState(false);
  const [takeAPledge, setITakeAPledge] = useState(false);
  useEffect(() => {
    getCitizenCorner();
    getNumberOfVistiors();
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
  })

  useEffect(() => {
    // to call the initialise google analytics funtion
    initGA()
    window.GA_INITIALIZED = true
    // to save the number og visitors on this side
    logPageView()
  }, [window])
  const checkScrollTop = () => {
    if (window.pageYOffset > 600 && window.pageYOffset < 1100) {
      setCitizenCorner(false)
      setCitizenConnect(false)
      setCitizenEngagements(false)
      setFAQs(false)
      setIECMaterial(false)
      setInterestingFacts(true)
      setITakeAPledge(false);
    } 
    else if (window.pageYOffset > 1100 && window.pageYOffset < 1600) {
      setCitizenCorner(true)
      setCitizenConnect(false)
      setCitizenEngagements(false)
      setFAQs(false)
      setIECMaterial(false)
      setInterestingFacts(false)
      setITakeAPledge(false);
    }
    else if (window.pageYOffset > 1600 && window.pageYOffset < 1950) {
      setCitizenCorner(false)
      setCitizenConnect(false)
      setCitizenEngagements(false)
      setFAQs(false)
      setIECMaterial(true)
      setInterestingFacts(false)
      setITakeAPledge(false);
    }
    else if (window.pageYOffset > 1950 && window.pageYOffset < 2400) {
      setCitizenCorner(false)
      setCitizenConnect(false)
      setCitizenEngagements(false)
      setFAQs(false)
      setIECMaterial(false)
      setInterestingFacts(false)
      setITakeAPledge(true);
    } else if (window.pageYOffset > 2400 && window.pageYOffset < 3150) {
      setCitizenCorner(false)
      setCitizenConnect(false)
      setCitizenEngagements(false)
      setFAQs(true)
      setIECMaterial(false)
      setInterestingFacts(false)
      setITakeAPledge(false);
    } else if (window.pageYOffset > 3150 && window.pageYOffset < 3600) {
      setCitizenCorner(false)
      setCitizenConnect(false)
      setCitizenEngagements(true)
      setFAQs(false)
      setIECMaterial(false)
      setInterestingFacts(false)
      setITakeAPledge(false);
    } else if (window.pageYOffset > 3600) {
      setCitizenCorner(false)
      setCitizenConnect(true)
      setCitizenEngagements(false)
      setFAQs(false)
      setIECMaterial(false)
      setInterestingFacts(false)
      setITakeAPledge(false);
    } else {
      setCitizenCorner(false)
      setCitizenConnect(false)
      setCitizenEngagements(false)
      setFAQs(false)
      setIECMaterial(false)
      setInterestingFacts(true)
      setITakeAPledge(false);
    }

  };

  const executeScroll = (data) => {
    myRefs.current[data].scrollIntoView({ behavior: "smooth", block: "nearest"})
  }
  return (

    <div className='CitizenCorner'>
      <Breadcrumb data={items} displayName={`Citizen's Corner`} />
      <div className='fixed-tabs'>
        <div className='container'>
          <div className='container-wrap'>
            <div className='row justify-content-center'>
              <ul>
                <li>
                  <span className={interestingFacts ? 'fixed-tab-name active' : 'fixed-tab-name'}>
                    <a onClick={() => { executeScroll(0) }}>{citizenCornerData?.tabs?.interesting_facts}</a>
                  </span>
                </li>
                <li>
                  <span className={citizenCorner ? 'fixed-tab-name active' : 'fixed-tab-name'}>
                    <a onClick={() => { executeScroll(1) }}>{citizenCornerData?.tabs?.citizen_quizzes}</a>
                  </span>
                </li>
                <li>
                  <span className={iECMaterial ? 'fixed-tab-name active' : 'fixed-tab-name'}>
                    <a onClick={() => { executeScroll(2) }}>{citizenCornerData?.tabs?.iec_material}</a>
                  </span>
                </li>
                <li>
                  <span className={takeAPledge ? 'fixed-tab-name active' : 'fixed-tab-name'}>
                    <a onClick={() => { executeScroll(3) }}>{citizenCornerData?.tabs?.take_a_pledge}</a>
                  </span>
                </li>
                <li>
                  <span className={fAQs ? 'fixed-tab-name active' : 'fixed-tab-name'}>
                    <a onClick={() => { executeScroll(4) }}>{citizenCornerData?.tabs?.faqs}</a>
                  </span>
                </li>
                <li>
                  <span className={citizenEngagements ? 'fixed-tab-name active' : 'fixed-tab-name'}>
                    <a onClick={() => { executeScroll(5) }}>{citizenCornerData?.tabs?.citizen_engagements}</a>
                  </span>
                </li>
                <li>
                  <span className={citizenConnect ? 'fixed-tab-name active' : 'fixed-tab-name'}>
                    <a onClick={() => { executeScroll(6) }}>{citizenCornerData?.tabs?.citizen_connect}</a>
                  </span>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <ParallaxProvider>
        <div id='citizenCornerSubpage'><CitizenCornerSubpage SectionId="#citizenCornerSubpage" data={citizenCornerData?.banner} /></div>
        <div className="interestingFacts" id='interestingFacts' ref={el => (myRefs.current[0] = el)}><InterestingFacts SectionId="#interestingFacts" data={citizenCornerData?.interesting_facts} /></div>
        <div className="citizenQuizzes" id='citizenQuizzes' ref={el => (myRefs.current[1] = el)}><CitizenQuizzes SectionId="#citizenQuizzes" data={citizenCornerData?.citizen_quizzes} /></div>
        <div id='iECMaterial' ref={el => (myRefs.current[2] = el)}><IECMaterial SectionId="#iECMaterial" data={citizenCornerData?.citizen_corner_iec_material} /></div>
        <div id='takeAPledge' ref={el => (myRefs.current[3] = el)}> <TakeAPledge SectionId="#takeAPledge" data={citizenCornerData?.citizen_pledge} pledgeCount={citizenCornerData?.pledgeCount} /></div>
        <div id='fAQs' ref={el => (myRefs.current[4] = el)}><FAQs SectionId="#fAQs" data={citizenCornerData?.citizen_corner_faq} /></div>
        <div id='citizenEngagements' ref={el => (myRefs.current[5] = el)}><CitizenEngagements SectionId="#citizenEngagements" data={citizenCornerData?.citizen_engagement} /></div>
        <div id='citizenConnect' ref={el => (myRefs.current[6] = el)}><CitizenConnect numberOfVistiors={numberOfVistiors} SectionId="#citizenConnect" data={citizenCornerData?.citizen_connect} /></div>
      </ParallaxProvider>

    </div>

  )
}
const mapStateToProps = (state) => {
  return {
    citizenCornerData: state.CitizenCornerReducer.citizenCorner,
    numberOfVistiors: state.CitizenCornerReducer.numberOfVistiors?.data
  }
}
export default connect(mapStateToProps, { getCitizenCorner, getNumberOfVistiors })(withRouter(CitizenCorner));