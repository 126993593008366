import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InputField from "../../common/InputField";
import InputFieldDate from "../../common/InputField";
import SelectDropdown from "../../common/FormElements/SelectDropdown";
import GlobalDropDown from "../../common/GlobalDropDown";
import TextAreaField from "../../common/TextAreaField";
import Button from "../../common/Button";
import Radio from "../../common/FormElements/Radio";
import { changeLanguage } from "../../../dependencies/utils/language/index";
import FileUpload from "../../common/FileUpload";
import FormValidator from "../dependencies/formValidator";
import InlineError from "../../common/InlineError";
import MultiSelectWithCheckbox from "../../common/FormElements/Multiselect";
import {
  uploadFileBank,
  uploadFileEngage,
  submitCategory,
} from "../dependencies/action";
import { validateFile } from "../../Events/dependencies/action";
import SuccessModal from "../../common/SuccessModal";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../dependencies/utils/constants";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Checkbox from "../../common/FormElements/Checkbox";
import {
  BANK_UPLOAD_URL,
  ENGAGEMENT_UPLOAD_URL,
  RESIDENT_UPLOAD_URL,
  UPLOAD_FILE_EVENT2,
} from "../../../constants/ApplicationUrl";

const wasteFocusArray = [
  {
    text: "Solid waste management",
    value: "Solid waste management",
  },
  {
    text: "Wastewater Management",
    value: "Wastewater Management",
  },
  {
    text: "Sanitation and hygiene",
    value: "Sanitation and hygiene",
  },
  {
    text: "Community Awareness",
    value: "Community Awareness",
  },
  {
    text: "Electronic Waste",
    value: "Electronic Waste",
  },
  {
    text: "Organic Waste",
    value: "Organic Waste",
  },
];

const CategoryC = (props) => {
  const {
    categoryName,
    category,
    language,
    classList,
    stateList,
    wasteCategory,
    uploadFileBank,
    uploadFileEngage,
    submitCategory,
    validateFile,
    cardTitle,
    pageLink,
  } = props;
  const defaultFormData = {
    PersonName: "",
    phone: "",
    education: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    email: "",
    institutionName: "",
    institutionAddress: "",
    institutionContactNo: "",
    institutionEmail: "",
    wasteAbundance: "",
    activity: "",
    activityFiled: "",
    workDoneDetail: "",
    engagementCommunity: "",
    innovativeSolution: "",
    acitivityOutcome: "",
    socialAwareness: "",
    otherInfo: "",
    furtherInitiatives: "",
    practicability: "",
    peerDifference: "",
    expectedOutputC: "",
    anticipateOutcome: "",
    gender: "",
    presentRadio: "",
    socialRadio: "",
    mechanism: "",
    otherWasteCategory: "",
    applicantName: "",
    dob: "",
    prominentChallenges: "",
    organizeRadio: "",
    organizeAnyProgramDetails: "",
    anyotherReleventInfo: "",
    wasteFocus: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] =
    useState(false);
  const [error, setError] = useState({});
  const [genderError, setGenderError] = useState(false);
  const [presentRadioError, setPresentRadioError] = useState(false);
  const [socialRadioError, setSocialRadioError] = useState(false);
  const [organizeRadioError, setOrganizeRadioError] = useState(false);
  const [engagementCommunityError, setEngagementCommunityError] =
    useState(false);
  const [uploadedFileEngageError, setUploadedFileEngageError] = useState("");
  const [uploadedFileBankError, setUploadedFileBankError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const recaptchaRef = useRef(null);
  const recaptchaRefengage = useRef(null);
  const recaptchaRef1 = useRef(null);
  const recaptchaRef2 = useRef(null);
  const [capthaError, setCapthaError] = useState(false);
  const [capthaErrorengage, setCapthaErrorengage] = useState(false);
  const [capthaError1, setCapthaError1] = useState(false);
  const [capthaError2, setCapthaError2] = useState(false);
  const [capthaErrorengageassist, setCapthaErrorengageassist] = useState(true);
  const [capthaError1assist, setCapthaError1assist] = useState(true);
  const [capthaError2assist, setCapthaError2assist] = useState(true);
  const [meghalayaState, setMeghalayaState] = useState([
    {
      text: "Meghalaya",
      value: "1",
    },
  ]);

  const [validinputData, setValidinputData] = useState(false);

  const [checked, setChecked] = useState(false);
  // const [checkedSenitation, setCheckedSenitation] = useState(false);
  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };

  const resetCaptchaengage = () => {
    recaptchaRefengage.current.reset();
  };

  const resetCaptcha1 = () => {
    recaptchaRef1.current.reset();
  };
  const resetCaptcha2 = () => {
    recaptchaRef2.current.reset();
  };

  useEffect(() => {
    console.log("cardTitle category C", cardTitle);
  }, []);
  const validateInput = () => {
    const {
      PersonName,
      phone,
      education,
      address,
      state,
      city,
      pincode,
      email,
      wasteAbundance,
      activity,
      activityFiled,
      workDoneDetail,
      innovativeSolution,
      acitivityOutcome,
      socialAwareness,
      otherInfo,
      furtherInitiatives,
      practicability,
      peerDifference,
      expectedOutputC,
      anticipateOutcome,
      mechanism,
      presentRadio,
      socialRadio,
      engagementCommunity,
      gender,
      otherWasteCategory,
      applicantName,
      dob,
      prominentChallenges,
      organizeRadio,
      anyotherReleventInfo,
      organizeAnyProgramDetails,
      wasteFocus,
    } = formData;
    const formStatus = FormValidator.validateSeactionCForm({
      PersonName,
      phone,
      education,
      address,
      state,
      city,
      pincode,
      email,
      wasteAbundance,
      activity: cardTitle !== "Meghalaya Fellowship" ? activity : null,
      activityFiled:
        cardTitle !== "Meghalaya Fellowship" ? activityFiled : null,
      workDoneDetail:
        cardTitle !== "Meghalaya Fellowship" ? workDoneDetail : null,
      innovativeSolution,
      acitivityOutcome,
      socialAwareness,
      otherInfo,
      furtherInitiatives,
      practicability,
      peerDifference,
      expectedOutputC,
      anticipateOutcome,
      mechanism,
      presentRadio,
      socialRadio,
      engagementCommunity,
      gender,
      otherWasteCategory,
      applicantName,
      dob,
      prominentChallenges,
      organizeRadio,
      anyotherReleventInfo,
      organizeAnyProgramDetails,
      wasteFocus: cardTitle === "Meghalaya Fellowship" ? wasteFocus : null,
    });
    const errors = {
      PersonName: formStatus.PersonName,
      education: formStatus.education,
      // dob: formStatus.dob,
      address: formStatus.address,
      country: formStatus.country,
      state: formStatus.state,
      city: formStatus.city,
      pincode: formStatus.pincode,
      email: formStatus.email,
      phone: formStatus.phone,
      wasteAbundance: formStatus.wasteAbundance,
      activity:
        cardTitle !== "Meghalaya Fellowship" ? formStatus.activity : null,
      activityFiled:
        cardTitle !== "Meghalaya Fellowship" ? formStatus.activityFiled : null,
      workDoneDetail:
        cardTitle !== "Meghalaya Fellowship" ? formStatus.workDoneDetail : null,
      innovativeSolution: formStatus.innovativeSolution,
      acitivityOutcome: formStatus.acitivityOutcome,
      socialAwareness: formStatus.socialAwareness,
      otherInfo: formStatus.otherInfo,
      furtherInitiatives: formStatus.furtherInitiatives,
      practicability: formStatus.practicability,
      peerDifference: formStatus.peerDifference,
      expectedOutputC: formStatus.expectedOutputC,
      anticipateOutcome: formStatus.anticipateOutcome,
      mechanism: formStatus.mechanism,
      presentRadio: formStatus.presentRadio,
      socialRadio: formStatus.socialRadio,
      engagementCommunity: formStatus.engagementCommunity,
      gender: formStatus.gender,
      otherWasteCategory: formStatus.otherWasteCategory,
      applicantName: formStatus.applicantName,
      dob: formStatus.dob,
      prominentChallenges: formStatus.prominentChallenges,
      organizeRadio: formStatus.organizeRadio,
      anyotherReleventInfo: formStatus.anyotherReleventInfo,
      organizeAnyProgramDetails: formStatus.organizeAnyProgramDetails,
      wasteFocus:
        cardTitle === "Meghalaya Fellowship" ? formStatus.wasteFocus : null,
    };

    setError(errors);
    return !formStatus.hasErrors;
  };
  let currentDate = new Date().toISOString().split("T")[0];

  const onBlur = () => {
    validateInput();
    // setValidinputData(validateInput());
  };

  const handleInputChange = (e, name) => {
    // validateInput()
    // debugger;
    const { value } = e.target;
    setFormData((data) => {
      return { ...data, [name]: value };
    });
  };

  const handleClose = () => {
    props.history.push(`/fellowship-listing${pageLink}`);
    setShowModal(false);
  };
  const CheckBeforeConfirm = () => {
    if (
      !validateInput() ||
      uploadedFileBank.length === 0 ||
      uploadedFileEngage.length === 0
    ) {
      setChecked(false);
      toast.error("Please Fill All Data Before Confirm");
    } else {
      setChecked(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !validateInput() ||
      uploadedFileBank.length === 0 ||
      uploadedFileEngage.length === 0
    ) {
      setHasInvalidSubmitOccurred(true);
      if (uploadedFileBank.length === 0) {
        setUploadedFileBankError("This is required");
      } else {
        setUploadedFileBankError("");
      }
      if (uploadedFileEngage.length === 0) {
        setUploadedFileEngageError("This is required");
      } else {
        setUploadedFileEngageError("");
      }
    } else {
      if (formData.organizeRadio == 2) {
        if (formData.organizeAnyProgramDetails.length <= 0) {
          const errors = {};
          toast.error("Please Enter Organize program Details");
        } else {
          if (checked == false) {
            toast.error("Please Confirm Disclaimer");
          }
          // else if (checkedSenitation == false) {
          //   toast.error("Please Confirm Disclaimer");
          // }
          else {
            const recaptchaValue = recaptchaRef.current.getValue();
            const recaptchaValueengage = recaptchaRefengage.current.getValue();
            const recaptchaValue1 = recaptchaRef1.current.getValue();
            const recaptchaValue2 = recaptchaRef2.current.getValue();
            if (recaptchaValue === "") {
              setCapthaError(true);
            } else if (recaptchaValueengage === "") {
              setCapthaErrorengage(true);
            } else if (recaptchaValue1 === "") {
              setCapthaError1(true);
            } else {
              setCapthaError(false);
              setCapthaErrorengage(false);
              setCapthaError1(false);
              let activity = formData.activity.split(",");

              let data = {
                ...formData,
                ...{ activity },
                ...{ category },
                ...{ language },
                ...{ uploadedFileBank },
                ...{ uploadedFileEngage },
                ...{ uploadedFileResidence },
                secret: recaptchaValue,
                request_for: "categoryC_form",
              };
              submitCategory(data).then((response) => {
                if (response.status === true) {
                  setShowModal(true);
                }
              });
            }
          }
        }
      } else {
        if (checked == false) {
          toast.error("Please Confirm Disclaimer");
        }
        // else if (checkedSenitation == false) {
        //   toast.error("Please Confirm Senitation Disclaimer");
        // }
        else {
          const recaptchaValue = recaptchaRef.current.getValue();
          if (recaptchaValue === "") {
            setCapthaError(true);
          } else {
            setCapthaError(false);
            let activity = formData.activity.split(",");
            let data = {
              ...formData,
              ...{ activity },
              ...{ category },
              ...{ language },
              ...{ uploadedFileBank },
              ...{ uploadedFileEngage },
              ...{ uploadedFileResidence },
              secret: recaptchaValue,
            };
            submitCategory(data).then((response) => {
              if (response.status === true) {
                setShowModal(true);
              }
            });
          }
        }
      }
    }
  };
  const [uploadedFileBank, setUploadedFileBank] = useState([]); //proofOfBankingDetails
  const [uploadedFileEngage, setUploadedFileEngage] = useState([]); // proofOfYourEngagement
  const [filesBank, setFilesBank] = useState([]); // proofOfBankingDetails
  const [filesEngage, setFilesEngage] = useState([]); // proofOfYourEngagement
  const [uploadedFileResidence, setUploadedFileResidence] = useState([]); // proofOfYourResidence
  const [filesResidence, setFilesResidence] = useState([]); // proofOfYourResidence

  const onUploadFileBank = (file, name) => {
    const dataValue = new FormData();
    dataValue.append("campaign", file);

    validateFile(dataValue).then((res) => {
      if (res) {
        if (uploadedFileBank.length < 3) {
          const data = new FormData();
          data.append("file", file);
          data.append("request_for", "categoryC_form");
          data.append("field_name", "field_proof_of_banking_details");
          data.append("attachfile_secret", recaptchaRef1.current.getValue());

          var axios = require("axios");

          var config = {
            method: "post",
            url: UPLOAD_FILE_EVENT2,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              if (response.status === 200) {
                console.log("if");
                setTimeout(() => {
                  recaptchaRef1.current.reset();
                  setCapthaError1assist(true);
                }, 1000);
                if (response?.data?.file_id) {
                  setFilesBank([...filesBank, response?.data?.file_name]);
                  setUploadedFileBank([
                    ...uploadedFileBank,
                    response?.data?.file_id,
                  ]);
                  setUploadedFileBankError("");
                } else {
                  // alert("something went wrong");
                  toast.error("something went wrong");
                }
              } else {
                console.log("else");
              }
            })
            .catch((e) => {
              toast.error("Something Went Wrong from submit tech index ");
              return { status: false };
            });
          // uploadFileBank(data).then(res => {
          //     if (res?.data?.file_id) {
          //         setFilesBank([...filesBank, res?.data?.file_name]);
          //         setUploadedFileBank([...uploadedFileBank, res?.data?.file_id])
          //         setUploadedFileBankError('')
          //     } else {
          //         alert("something went wrong");
          //     }
          // })
        } else {
          toast.error("You can upload maximum 3 files only");
        }
      }
    });
  };

  const onUploadFileEngage = (file, name) => {
    const dataValue = new FormData();
    dataValue.append("campaign", file);

    validateFile(dataValue).then((res) => {
      if (res) {
        if (uploadedFileEngage.length < 3) {
          const data = new FormData();
          data.append("file", file);
          data.append("request_for", "categoryC_form");
          data.append("field_name", "field_proof_of_your_engagement");
          data.append(
            "attachfile_secret",
            recaptchaRefengage.current.getValue()
          );

          var axios = require("axios");

          var config = {
            method: "post",
            url: UPLOAD_FILE_EVENT2,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              if (response.status === 201) {
                console.log("if");
                setTimeout(() => {
                  recaptchaRefengage.current.reset();
                  setCapthaErrorengageassist(true);
                }, 1000);
                if (response?.data?.file_id) {
                  setFilesEngage([...filesEngage, response?.data?.file_name]);
                  setUploadedFileEngage([
                    ...uploadedFileEngage,
                    response?.data?.file_id,
                  ]);
                  setUploadedFileEngageError("");
                } else {
                  // alert("something went wrong");
                  toast.error("something went wrong");
                }
              } else {
                console.log("else");
              }
            })
            .catch((e) => {
              toast.error("Something Went Wrong from submit tech index ");
              return { status: false };
            });
          // uploadFileEngage(data).then(res => {

          //     if (res?.data?.file_id) {
          //         setFilesEngage([...filesEngage, res?.data?.file_name]);
          //         setUploadedFileEngage([...uploadedFileEngage, res?.data?.file_id])
          //         setUploadedFileEngageError('')
          //     } else {
          //         // alert("something went wrong")
          //         toast.error("something went wrong");;
          //     }
          // })
        } else {
          // alert("only five file accepted")
          toast.error("You can upload maximum 3 files only");
        }
      }
    });
  };

  const onUploadFileResidence = (file, name) => {
    const dataValue = new FormData();
    dataValue.append("campaign", file);

    validateFile(dataValue).then((res) => {
      if (res) {
        if (uploadedFileResidence.length < 3) {
          const data = new FormData();
          data.append("file", file);
          data.append("request_for", "categoryC_form");
          data.append("field_name", "field_residence_proof");
          data.append("attachfile_secret", recaptchaRef2.current.getValue());

          var axios = require("axios");

          var config = {
            method: "post",
            url: UPLOAD_FILE_EVENT2,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              if (response.status === 200) {
                console.log("if");
                setTimeout(() => {
                  recaptchaRef2.current.reset();
                  setCapthaError2assist(true);
                }, 1000);
                if (response?.data?.file_id) {
                  setFilesResidence([
                    ...filesResidence,
                    response?.data?.file_name,
                  ]);
                  setUploadedFileResidence([
                    ...uploadedFileResidence,
                    response?.data?.file_id,
                  ]);
                  // setUploadedFileEngageError("");
                } else {
                  // alert("something went wrong");
                  toast.error("something went wrong");
                }
              } else {
                console.log("else");
              }
            })
            .catch((e) => {
              toast.error("Something Went Wrong from submit tech index ");
              return { status: false };
            });
          // uploadFileEngage(data).then(res => {

          //     if (res?.data?.file_id) {
          //         setFilesEngage([...filesEngage, res?.data?.file_name]);
          //         setUploadedFileEngage([...uploadedFileEngage, res?.data?.file_id])
          //         setUploadedFileEngageError('')
          //     } else {
          //         // alert("something went wrong")
          //         toast.error("something went wrong");;
          //     }
          // })
        } else {
          // alert("only five file accepted")
          toast.error("You can upload maximum 3 files only");
        }
      }
    });
  };

  const removeFile = (file, type) => {
    if (type === "bank") {
      let index = filesBank.indexOf(file);
      if (index > -1) {
        uploadedFileBank.splice(index, 1);
        filesBank.splice(index, 1);
        setFilesBank([...filesBank]);
        setUploadedFileBank([...uploadedFileBank]);
      }
    } else if (type === "residence") {
      let index = filesResidence.indexOf(file);
      if (index > -1) {
        uploadedFileResidence.splice(index, 1);
        filesResidence.splice(index, 1);
        setFilesResidence([...filesResidence]);
        setUploadedFileResidence([...uploadedFileResidence]);
      }
    } else {
      let index = filesEngage.indexOf(file);
      if (index > -1) {
        uploadedFileEngage.splice(index, 1);
        filesEngage.splice(index, 1);
        setFilesEngage([...filesEngage]);
        setUploadedFileEngage([...uploadedFileEngage]);
      }
    }
  };
  return (
    <div className="form-lower-section">
      {showModal && (
        <SuccessModal
          title={`Swachhta Saarthi Fellowship - Under ${categoryName}`}
          show
          closeModal={handleClose}
        />
      )}

      <div>
        <div className="form-container submit-tech-form">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-body">
              <div className="row">
                <div className="col-12">
                  <div className="bank-disclaimer">
                    {changeLanguage(language, "bankDisclaimer", "label")}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3 className="sub-title">
                    {changeLanguage(language, "sectionCHeader", "label")}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="person-text-title">
                    {`1. ${changeLanguage(language, "personName", "label")}`}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <InputField
                    required
                    type="text"
                    name="PersonName"
                    label={changeLanguage(language, "personText", "label")}
                    placeholder={changeLanguage(
                      language,
                      "personText",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.PersonName}
                    onChange={handleInputChange}
                    error={error.PersonName}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
                <div className="col-lg-6">
                  <InputField
                    required
                    type="text"
                    name="applicantName"
                    label={`2. ${changeLanguage(
                      language,
                      "applicantName",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "applicantName",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.applicantName}
                    onChange={handleInputChange}
                    error={error.applicantName}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
                <div className="col-lg-6">
                  <InputFieldDate
                    required
                    type="date"
                    name="dob"
                    label={`3. ${changeLanguage(language, "dob", "label")}`}
                    containerClass={"form-group"}
                    className="form-input-field dob-input"
                    value={formData.dob}
                    onChange={handleInputChange}
                    error={error.dob}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    max={currentDate}
                  />
                </div>
                <div className="col-lg-6">
                  <GlobalDropDown
                    required
                    name="education"
                    options={classList}
                    label={`4. ${changeLanguage(
                      language,
                      "education",
                      "label"
                    )}`}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.education}
                    onChange={handleInputChange}
                    error={error.education}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                {/* <div className='col-lg-6'>
                                    <InputFieldDate
                                        required
                                        type="date"
                                        name="dob"
                                        label={changeLanguage(language, 'dob', 'label')}
                                        containerClass={"form-group"}
                                        className='form-input-field dob-input'
                                        value={formData.dob}
                                        onChange={handleInputChange}
                                        error={error.dob}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                        max={currentDate}

                                    />
                                </div> */}
                <div className="col-lg-6 radio-container">
                  <label>
                    {`5. ${changeLanguage(language, "gender", "label")}`}{" "}
                    <span className="required">*</span>
                  </label>
                  <div className="radio-box">
                    <Radio
                      type="radio"
                      label={changeLanguage(language, "male", "label")}
                      value={"1"}
                      name="gender"
                      checked={formData.gender == 1}
                      onChange={handleInputChange}
                    />
                    <Radio
                      type="radio"
                      label={changeLanguage(language, "female", "label")}
                      value={"2"}
                      name="gender"
                      checked={formData.gender == 2}
                      onChange={handleInputChange}
                    />

                    <Radio
                      type="radio"
                      label={changeLanguage(language, "other", "label")}
                      value={"3"}
                      name="gender"
                      checked={formData.gender == 3}
                      onChange={handleInputChange}
                    />

                    {error.gender && <InlineError message={error.gender} />}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="address"
                    label={`6. ${changeLanguage(language, "address", "label")}`}
                    placeholder={changeLanguage(
                      language,
                      "address",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.address}
                    onChange={handleInputChange}
                    error={error.address}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row address-container">
                <div className="col-lg-12">
                  {/*<SelectDropdown
                                        required
                                        name="country"
                                        label={changeLanguage(language, 'country', 'label')}
                                        containerClass={"form-group"}
                                        className='form-input-field'
                                        value={formData.country}
                                        onChange={handleInputChange}
                                        error={''}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />*/}
                  <GlobalDropDown
                    required
                    name="state"
                    options={
                      cardTitle === "Meghalaya Fellowship"
                        ? meghalayaState
                        : stateList
                    }
                    label={`7. ${changeLanguage(language, "state", "label")}`}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.state}
                    onChange={handleInputChange}
                    error={error.state}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                  <InputField
                    required
                    type="text"
                    name="city"
                    label={`8. ${changeLanguage(language, "city", "label")}`}
                    containerClass={"form-group"}
                    placeholder={changeLanguage(
                      language,
                      "city",
                      "placeholder"
                    )}
                    className="form-input-field"
                    value={formData.city}
                    onChange={handleInputChange}
                    error={error.city}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                  <InputField
                    required
                    type="pincode"
                    name="pincode"
                    label={`9. ${changeLanguage(language, "pincode", "label")}`}
                    containerClass={"form-group"}
                    placeholder={changeLanguage(
                      language,
                      "pincode",
                      "placeholder"
                    )}
                    className="form-input-field"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    error={error.pincode}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>

                <div className="col-lg-6">
                  <div className="phone">
                    <InputField
                      required
                      type="number"
                      name="phone"
                      label={`10. ${changeLanguage(language, "tel", "label")}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "tel",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.phone}
                      onChange={handleInputChange}
                      error={error.phone}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="email">
                    <InputField
                      required
                      type="text"
                      name="email"
                      label={`11. ${changeLanguage(
                        language,
                        "email",
                        "label"
                      )}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "email",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={error.email}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row">
                                <div className="col">
                                    <h3 className="sub-title">College/University/institution Contact Details</h3>
                                </div>
                            </div> */}
              {/* <div className='row'>
                                <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="institutionName"
                                        label={changeLanguage(language, 'institutionName', 'label')}
                                        value=""
                                        placeholder={changeLanguage(language, 'institutionName', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field'
                                        value={formData.institutionName}
                                        onChange={handleInputChange}
                                        error={error.institutionName}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <TextAreaField
                                        required
                                        type="text"
                                        name="institutionAddress"
                                        label={changeLanguage(language, 'institutionAddress', 'label')}
                                        placeholder={changeLanguage(language, 'institutionAddress', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.institutionAddress}
                                        onChange={handleInputChange}
                                        error={error.institutionAddress}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='phone'>
                                        <InputField
                                            required
                                            type="text"
                                            name="institutionContactNo"
                                            label={changeLanguage(language, 'institutionContact', 'label')}
                                            containerClass={"form-group"}
                                            placeholder={changeLanguage(language, 'institutionContact', 'placeholder')}
                                            className='form-input-field'
                                            value={formData.institutionContactNo}
                                            onChange={handleInputChange}
                                            error={error.institutionContactNo}
                                            onBlur={onBlur}
                                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="email">
                                        <InputField
                                            required
                                            type="text"
                                            name="institutionEmail"
                                            label={changeLanguage(language, 'institutionEmail', 'label')}
                                            containerClass={"form-group"}
                                            placeholder={changeLanguage(language, 'institutionEmail', 'placeholder')}
                                            className='form-input-field'
                                            value={formData.institutionEmail}
                                            onChange={handleInputChange}
                                            error={error.institutionEmail}
                                            onBlur={onBlur}
                                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                        />
                                    </div>
                                </div>
                            </div> */}
              {cardTitle === "Meghalaya Fellowship" ? (
                <div className="row">
                  <div className="col">
                    <GlobalDropDown
                      required
                      name="wasteFocus"
                      options={wasteFocusArray}
                      label={
                        "12. Choose a waste focus area for your 1 year long project"
                      }
                      containerClass={"form-group"}
                      className="form-input-field"
                      value={formData.wasteFocus}
                      onChange={handleInputChange}
                      error={error.wasteFocus}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="wasteAbundance"
                    label={
                      cardTitle === "Meghalaya Fellowship"
                        ? "13. A brief on the waste focus areas selected in your vicinity/college/university/community (solid waste management,wastewater management, sanitation and hygiene, organic waste, community awareness, and electronic waste) and prominent challenges."
                        : changeLanguage(language, "wasteAbundance", "label")
                    }
                    placeholder={
                      cardTitle === "Meghalaya Fellowship"
                        ? "(solid waste management,wastewater management, sanitation and hygiene, organic waste, community awareness, and electronic waste) and prominent challenges."
                        : changeLanguage(
                            language,
                            "wasteAbundance",
                            "placeholder"
                          )
                    }
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.wasteAbundance}
                    onChange={handleInputChange}
                    error={error.wasteAbundance}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              {/* <div className='row'>
                                <div className='col'>
                                    <TextAreaField
                                        required
                                        type="text"
                                        name="briefWriteUp"
                                        label={changeLanguage(language, 'briefWriteUp', 'label')}
                                        placeholder={changeLanguage(language, 'briefWriteUp', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.briefWriteUp}
                                        onChange={handleInputChange}
                                        error={error.briefWriteUp}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>

                            </div> */}
              <div className="row">
                <div className="col">
                  <h3 className="sub-title">
                    {changeLanguage(language, "wordDoneSum", "label")}
                  </h3>
                </div>
              </div>
              {cardTitle !== "Meghalaya Fellowship" ? (
                <div className="row multiple-selection-box">
                  <div className="col-lg-6">
                    <MultiSelectWithCheckbox
                      required
                      label={changeLanguage(language, "wasteCategory", "label")}
                      name="activity"
                      options={wasteCategory}
                      value={formData.activity}
                      onChange={handleInputChange}
                      error={error.activity}
                      touched={hasInvalidSubmitOccurred}
                      valid={hasInvalidSubmitOccurred}
                      onBlur={onBlur}
                    />
                    {/* <SelectDropdown
                                          required
                                          name="activity"
                                          label={changeLanguage(language, 'wasteCategory', 'label')}
                                          containerClass={"form-group"}
                                          placeholder={'Smith'}
                                          className='form-input-field'
                                          value={formData.activity}
                                          onChange={handleInputChange}
                                          error={error.activity}
                                          onBlur={onBlur}
                                          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                      /> */}
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      required
                      type="text"
                      name="activityFiled"
                      label={changeLanguage(
                        language,
                        "activityStatus",
                        "label"
                      )}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "activityStatus",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.activityFiled}
                      onChange={handleInputChange}
                      error={error.activityFiled}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="otherWasteCategory"
                    label={
                      cardTitle === "Meghalaya Fellowship"
                        ? "14. Please specify any previous activities carried out with respect to the selected waste focus area. (max 200 words)."
                        : changeLanguage(
                            language,
                            "otherWasteCategory",
                            "label"
                          )
                    }
                    placeholder={changeLanguage(
                      language,
                      "otherWasteCategory",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.otherWasteCategory}
                    onChange={handleInputChange}
                    error={error.otherWasteCategory}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              {cardTitle !== "Meghalaya Fellowship" ? (
                <div className="row">
                  <div className="col">
                    <TextAreaField
                      required
                      type="text"
                      name="workDoneDetail"
                      label={changeLanguage(
                        language,
                        "workDoneDetail",
                        "label"
                      )}
                      placeholder={changeLanguage(
                        language,
                        "workDoneDetail",
                        "placeholder"
                      )}
                      containerClass={"form-group"}
                      className="form-input-field fellow-text-box"
                      value={formData.workDoneDetail}
                      onChange={handleInputChange}
                      error={error.workDoneDetail}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="prominentChallenges"
                    label={`15. ${changeLanguage(
                      language,
                      "workDoneDetail1",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "workDoneDetail1",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.prominentChallenges}
                    onChange={handleInputChange}
                    error={error.prominentChallenges}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <label>
                    {`16. ${changeLanguage(
                      language,
                      "engagementCommunity",
                      "label"
                    )}`}{" "}
                    <span className="required">*</span>
                  </label>
                  <div className="decision-options">
                    <Radio
                      type="engagementCommunity"
                      label={changeLanguage(language, "yes", "label")}
                      value={"2"}
                      name="engagementCommunity"
                      checked={formData.engagementCommunity == 2}
                      onChange={handleInputChange}
                    />
                    <Radio
                      type="engagementCommunity"
                      label={changeLanguage(language, "no", "label")}
                      value={"1"}
                      name="engagementCommunity"
                      checked={formData.engagementCommunity == 1}
                      onChange={handleInputChange}
                    />
                  </div>
                  {error.engagementCommunity && (
                    <InlineError message={error.engagementCommunity} />
                  )}
                </div>
                {/* <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="numberOfDetails"
                                        label={changeLanguage(language, 'numberOfDetails', 'label')}
                                        value=""
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'numberOfDetails', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.numberOfDetails}
                                        onChange={handleInputChange}
                                        error={error.numberOfDetails}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div> */}
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="innovativeSolution"
                    // label={`17. ${changeLanguage(
                    //   language,
                    //   "innovativeSolution",
                    //   "label"
                    // )}`}
                    label={
                      cardTitle === "Meghalaya Fellowship"
                        ? "17. Innovative solution applied / being applied in converting waste to value in focus waste area selected (max 100 words)"
                        : changeLanguage(
                            language,
                            "innovativeSolution",
                            "label"
                          )
                    }
                    placeholder={changeLanguage(
                      language,
                      "innovativeSolution",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.innovativeSolution}
                    onChange={handleInputChange}
                    error={error.innovativeSolution}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="acitivityOutcome"
                    // label={`18. ${changeLanguage(
                    //   language,
                    //   "acitivityOutcome",
                    //   "label"
                    // )}`}
                    label={
                      cardTitle === "Meghalaya Fellowship"
                        ? "18. Outcome of the activities in focus waste area selected so far (max 100 words)"
                        : changeLanguage(language, "acitivityOutcome", "label")
                    }
                    placeholder={changeLanguage(
                      language,
                      "acitivityOutcome",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.acitivityOutcome}
                    onChange={handleInputChange}
                    error={error.acitivityOutcome}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div>
                <label>
                  {`19. ${changeLanguage(
                    language,
                    "socialAwareness",
                    "label"
                  )}`}
                  <span className="required">*</span>
                </label>
                <div className="col decision-options">
                  <Radio
                    type="radio"
                    label={changeLanguage(language, "yes", "label")}
                    value={"2"}
                    name="socialRadio"
                    checked={formData.socialRadio == 2}
                    onChange={handleInputChange}
                  />

                  <Radio
                    type="radio"
                    label={changeLanguage(language, "no", "label")}
                    value={"1"}
                    name="socialRadio"
                    checked={formData.socialRadio == 1}
                    onChange={handleInputChange}
                  />
                </div>
                {error.socialRadio && (
                  <InlineError message={error.socialRadio} />
                )}
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="socialAwareness"
                    label={`20. ${changeLanguage(
                      language,
                      "socialAwarenessDetails",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "socialAwarenessDetails",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.socialAwareness}
                    onChange={handleInputChange}
                    error={error.socialAwareness}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div>
                <label>
                  {`21. ${changeLanguage(
                    language,
                    "organizeAnyProgram",
                    "label"
                  )}`}
                  <span className="required">*</span>
                </label>
                <div className="col decision-options">
                  <Radio
                    type="radio"
                    label={changeLanguage(language, "yes", "label")}
                    value={"2"}
                    name="organizeRadio"
                    checked={formData.organizeRadio == 2}
                    onChange={handleInputChange}
                  />

                  <Radio
                    type="radio"
                    label={changeLanguage(language, "no", "label")}
                    value={"1"}
                    name="organizeRadio"
                    checked={formData.organizeRadio == 1}
                    onChange={handleInputChange}
                  />
                </div>
                {error.organizeRadio && (
                  <InlineError message={error.organizeRadio} />
                )}
              </div>
              {formData.organizeRadio == 2 && (
                <div className="row">
                  <div className="col">
                    <TextAreaField
                      required
                      type="text"
                      name="organizeAnyProgramDetails"
                      label={changeLanguage(
                        language,
                        "organizeAnyProgramDetails",
                        "label"
                      )}
                      placeholder={changeLanguage(
                        language,
                        "organizeAnyProgramDetails",
                        "placeholder"
                      )}
                      containerClass={"form-group"}
                      className="form-input-field fellow-text-box"
                      value={formData.organizeAnyProgramDetails}
                      onChange={handleInputChange}
                      error={error.organizeAnyProgramDetails}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              )}
              <div>
                <span>
                  {`22. ${changeLanguage(language, "presentRadio", "label")}`}
                  <span className="required">*</span>
                </span>
                <div className="col decision-options">
                  <Radio
                    type="radio"
                    label={changeLanguage(language, "yes", "label")}
                    value={"2"}
                    name="presentRadio"
                    checked={formData.presentRadio == 2}
                    onChange={handleInputChange}
                  />

                  <Radio
                    type="radio"
                    label={changeLanguage(language, "no", "label")}
                    value={"1"}
                    name="presentRadio"
                    checked={formData.presentRadio == 1}
                    onChange={handleInputChange}
                  />
                </div>
                {error.presentRadio && (
                  <InlineError message={error.presentRadio} />
                )}
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    // required
                    type="text"
                    name="otherInfo"
                    label={
                      cardTitle === "Meghalaya Fellowship"
                        ? "23. Any other information (max 200 words)."
                        : changeLanguage(language, "otherInfo", "label")
                    }
                    containerClass={"form-group"}
                    placeholder={changeLanguage(
                      language,
                      "otherInfo",
                      "placeholder"
                    )}
                    className="form-input-field"
                    value={formData.otherInfo}
                    onChange={handleInputChange}
                    error={error.otherInfo}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className="captcha-field">
                  <ReCAPTCHA
                    ref={recaptchaRefengage}
                    sitekey={SITE_KEY}
                    onChange={() => {
                      setCapthaErrorengageassist(false);
                    }}
                  />
                  {capthaErrorengage && (
                    <InlineError message={"This is required"} />
                  )}
                </div>
              </div>
              <div className="row upload-file-catc">
                <div
                  className="col"
                  style={{
                    pointerEvents:
                      capthaErrorengageassist === true ? "none" : "auto",
                    opacity: capthaErrorengageassist === true ? 0.5 : 1,
                    // borderColor:
                    //   capthaErrorassist === true ? "lightgrey" : "#2ba749",
                    // color: capthaErrorassist === true ? "lightgrey" : "#2ba749",
                  }}
                >
                  <FileUpload
                    maxFileSizeInMB={3}
                    htmlFor="uploadFileInput"
                    labelClass="contactUploadFile green-border-btn"
                    onUploadFile={(file) =>
                      onUploadFileEngage(file, "proofOfYourEngagement")
                    }
                    allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    error={uploadedFileEngageError}
                    onBlur={onBlur}
                    id="uploadFileInput"
                    visbility="hidden"
                    label="Attach File"
                  />
                  <div className="overlay">
                    <i className="upload-file-icon"></i>
                    <div>
                      <h6 required>
                        {changeLanguage(
                          language,
                          "workSummaryFileText",
                          "placeholder"
                        )}
                        <span className="required">*</span>{" "}
                      </h6>
                      <span>
                        {changeLanguage(
                          language,
                          "workSummaryFileText",
                          "label"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                {filesEngage.map((file) => (
                  <p key={file}>
                    {file}{" "}
                    <span
                      onClick={() => {
                        removeFile(file, "engagement");
                      }}
                    >
                      X
                    </span>
                  </p>
                ))}
              </div>
              <div className="row">
                <div className="col">
                  <h3 className="sub-title">
                    {changeLanguage(language, "actionPlan", "label")}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="furtherInitiatives"
                    label={`24. ${changeLanguage(
                      language,
                      "furtherInitiatives_C",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "furtherInitiatives_C",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.furtherInitiatives}
                    onChange={handleInputChange}
                    error={error.furtherInitiatives}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="practicability"
                    label={`25. ${changeLanguage(
                      language,
                      "practicability",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "practicability",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.practicability}
                    onChange={handleInputChange}
                    error={error.practicability}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="peerDifference"
                    label={`26. ${changeLanguage(
                      language,
                      "peerDifference",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "peerDifference",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.peerDifference}
                    onChange={handleInputChange}
                    error={error.peerDifference}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="expectedOutputC"
                    label={`27. ${changeLanguage(
                      language,
                      "expectedOutput_C",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "expectedOutput_C",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.expectedOutputC}
                    onChange={handleInputChange}
                    error={error.expectedOutputC}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="anticipateOutcome"
                    label={`28. ${changeLanguage(
                      language,
                      "anticipateOutcome",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "anticipateOutcome",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.anticipateOutcome}
                    onChange={handleInputChange}
                    error={error.anticipateOutcome}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="mechanism"
                    label={`29. ${changeLanguage(
                      language,
                      "mechanism",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "mechanism",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.mechanism}
                    onChange={handleInputChange}
                    error={error.mechanism}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div>
                <TextAreaField
                  required
                  type="text"
                  name="anyotherReleventInfo"
                  label={`30. ${changeLanguage(
                    language,
                    "anyotherReleventInfo",
                    "label"
                  )}`}
                  containerClass={"form-group"}
                  placeholder={changeLanguage(
                    language,
                    "anyotherReleventInfo",
                    "placeholder"
                  )}
                  className="form-input-field"
                  value={formData.anyotherReleventInfo}
                  onChange={handleInputChange}
                  error={error.anyotherReleventInfo}
                  onBlur={onBlur}
                  hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
              </div>
              <div className="col-12 col-lg-7">
                <div className="captcha-field">
                  <ReCAPTCHA
                    ref={recaptchaRef1}
                    sitekey={SITE_KEY}
                    onChange={() => {
                      setCapthaError1assist(false);
                    }}
                  />
                  {capthaError1 && <InlineError message={"This is required"} />}
                </div>
              </div>
              <div className="row upload-file-catc">
                <div
                  className="col"
                  style={{
                    pointerEvents:
                      capthaError1assist === true ? "none" : "auto",
                    opacity: capthaError1assist === true ? 0.5 : 1,
                    // borderColor:
                    //   capthaErrorassist === true ? "lightgrey" : "#2ba749",
                    // color: capthaErrorassist === true ? "lightgrey" : "#2ba749",
                  }}
                >
                  <FileUpload
                    maxFileSizeInMB={3}
                    htmlFor="uploadFileInput1"
                    labelClass="green-border-btn"
                    onUploadFile={(file) =>
                      onUploadFileBank(file, "proofOfBankingDetails")
                    }
                    allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    error={uploadedFileBankError}
                    onBlur={onBlur}
                    id="uploadFileInput1"
                    visbility="hidden"
                    label="Attach File"
                  />
                  <div className="overlay">
                    <i className="upload-file-icon"></i>
                    <div>
                      <h6 required>
                        {changeLanguage(
                          language,
                          "bankCatCFileText",
                          "placeholder"
                        )}
                        <span className="required">*</span>
                      </h6>
                      <span>
                        {changeLanguage(language, "bankCatCFileText", "label")}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {filesBank &&
                  filesBank.length > 0 &&
                  filesBank.map((file) => (
                    <p>
                      {file}{" "}
                      <span
                        onClick={() => {
                          removeFile(file, "bank");
                        }}
                      >
                        X
                      </span>
                    </p>
                  ))}
              </div>
              {/* {validinputData ===true && uploadedFileBank.length !== 0 && uploadedFileEngage.length !== 0 ? */}

              {cardTitle === "Meghalaya Fellowship" ? (
                <>
                  <div className="col-12 col-lg-7">
                    <div className="captcha-field">
                      <ReCAPTCHA
                        ref={recaptchaRef2}
                        sitekey={SITE_KEY}
                        onChange={() => {
                          setCapthaError2assist(false);
                        }}
                      />
                      {/* {capthaError && <InlineError message={"This is required"} />} */}
                    </div>
                  </div>
                  <div className="row upload-file">
                    <div
                      className="col"
                      style={{
                        pointerEvents:
                          capthaError2assist === true ? "none" : "auto",
                        opacity: capthaError2assist === true ? 0.5 : 1,
                        // borderColor:
                        //   capthaErrorassist === true ? "lightgrey" : "#2ba749",
                        // color: capthaErrorassist === true ? "lightgrey" : "#2ba749",
                      }}
                    >
                      <FileUpload
                        maxFileSizeInMB={3}
                        htmlFor="uploadFileInput"
                        labelClass="contactUploadFile green-border-btn"
                        onUploadFile={(file) =>
                          onUploadFileResidence(file, "proofOfYourResidence")
                        }
                        allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                        //  hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        //  error={uploadedFileEngageError}
                        //  onBlur={onBlur}
                        id="uploadFileInput"
                        visbility="hidden"
                        label="Attach File"
                      />

                      <div className="overlay">
                        <i className="upload-file-icon"></i>
                        <div>
                          <h6 required className="attachheading">
                            {/* {changeLanguage(
                            language,
                            "bankFileText",
                            "placeholder"
                          )}{" "} */}
                            Attach residence proof
                            {/* <span className="required">*</span> */}
                          </h6>
                          <span>
                            {/* {changeLanguage(language, "bankFileText", "label")} */}
                            Please attach address proof which proves you are a
                            resident of Meghalaya (.pdf, .png, .jpg, .jpeg) -
                            max 3MB.
                          </span>
                        </div>
                      </div>
                    </div>
                    {filesResidence &&
                      filesResidence.length > 0 &&
                      filesResidence.map((file) => (
                        <p>
                          {file}{" "}
                          <span
                            onClick={() => {
                              removeFile(file, "residence");
                            }}
                          >
                            X
                          </span>
                        </p>
                      ))}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-12 mr20 categoryconfirm">
                <Checkbox
                  id="disclaimer"
                  label={changeLanguage(language, "disclaimer1", "label")}
                  name="disclaimer"
                  value={checked}
                  onChange={() => setChecked(!checked)}
                />
              </div>
              {/* <div className="col-12 mr20">
                <Checkbox
                  id="disclaimer"
                  label={changeLanguage(language, "disclaimer2", "label")}
                  name="disclaimer"
                  value={checkedSenitation}
                  onChange={() => setCheckedSenitation(!checkedSenitation)}
                />
              </div> */}
              {/* : null

} */}
              <div className="col-12 col-lg-7">
                <div className="captcha-field">
                  <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
                  {capthaError && <InlineError message={"This is required"} />}
                </div>
              </div>
            </div>

            <div className="form-footer">
              <div className="row footer-btn-wrap">
                <div className="bttn-group col-lg-12 d-flex justify-content-end">
                  <Link
                    className="bordered-btn green-border formcancel"
                    to={`/fellowship-listing${pageLink}`}
                  >
                    {changeLanguage(language, "cancel", "label")}
                  </Link>
                  <Button
                    type="submit"
                    text={changeLanguage(language, "submitApp", "label")}
                    className="form-submit-button"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  uploadFileBank,
  uploadFileEngage,
  submitCategory,
  validateFile,
})(withRouter(CategoryC));
