import { EventService } from "./service";
import {
  EVENT_LISTING,
  FEATURED_EVENT,
  EVENT_DETAILS,
  EVENT_DETAILS2,
  UPCOMMING_EVENTS,
} from "../../../constants/actionConstants";
import { isLoading } from "../../../dependencies/action/action";
import { toast } from "react-toastify";

export function getEventList(page, sortBy) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let eventList = await EventService.getEventList(page, sortBy);
    dispatch({
      type: EVENT_LISTING,
      payload: eventList,
    });
    dispatch(isLoading(false));
  };
}

export function getEventDetails2(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let eventDetails2 = await EventService.getEventDetails2(url);
    dispatch({
      type: EVENT_DETAILS2,
      payload: eventDetails2,
    });
    dispatch(isLoading(false));
  };
}

export function getFeaturedEvent() {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let faturedEvent = await EventService.getFeaturedEvent();
    dispatch({
      type: FEATURED_EVENT,
      payload: faturedEvent,
    });
    dispatch(isLoading(false));
  };
}

export function getEventDetails(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let eventDetails = await EventService.getEventDetails(url);
    dispatch({
      type: EVENT_DETAILS,
      payload: eventDetails,
    });
    dispatch(isLoading(false));
  };
}

export function getUpcommingEvents() {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let upcommingEvents = await EventService.upcommingEvents();
    dispatch({
      type: UPCOMMING_EVENTS,
      payload: upcommingEvents,
    });
    dispatch(isLoading(false));
  };
}

export const uploadFile = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  return await EventService.uploadFile(params)
    .then((response) => {
      dispatch(isLoading(false));
      if (response.status === 201) {
        return {
          status: true,
          attachfile: response.data.fid[0].value,
          filename: response.data.filename[0].value,
        };
      } else {
        dispatch(isLoading(false));
        return { status: false };
      }
    })
    .catch((e) => {
      dispatch(isLoading(false));
      return { status: false };
    });
};

export const validateFile = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  return await EventService.validateFile(params)
    .then((response) => {
      dispatch(isLoading(false));

      if (response) {
        return response;
      } else {
        toast.error("Uploaded file format is not supported");
        return response;
      }
    })
    .catch((e) => {
      dispatch(isLoading(false));
      return { status: false };
    });
};
export const submitEventRegistration = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  return await EventService.submitEventRegistartion(params)
    .then((response) => {
      dispatch(isLoading(false));
      const { status, data } = response || {};
      if (status === 200) {
        toast.success(data.message);
        return { status: true };
      } else {
        dispatch(isLoading(false));
        toast.error(data.message);
        return { status: false };
      }
    })
    .catch((e) => {
      dispatch(isLoading(false));
      toast.error("Something Went Wrong");
      return { status: false };
    });
};
