import { combineReducers } from 'redux';
import {
    SUBMIT_TECH_DATA
} from '../../../constants/actionConstants';

const submitTechInfo = (state = {}, action) => {
    switch (action.type) {
        case SUBMIT_TECH_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}

const SubmitTechReducer = combineReducers({
    submitTechInfo
});

export default SubmitTechReducer;


