import { HeaderService } from './service';
import {
FOOTER_DATA
} from '../../../../constants/actionConstants';
export const getHeaderData = () => async (dispatch) => {

    let headerData = await HeaderService.getHeader()
    dispatch({
        type: FOOTER_DATA,
        payload: headerData
    })
}