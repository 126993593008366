import { combineReducers } from 'redux';
import {
    POLICY_TYPE,
    POLICY_LIST,
    POLICY_TECHNOLOGY
} from '../../../constants/actionConstants';

const policyType = (state = {}, action) => {
    switch (action.type) {
        case POLICY_TYPE:
            return { ...action.payload };
        default:
            return state;
    }
}
const policyListData = (state = {}, action) => {
    switch (action.type) {
        case POLICY_LIST:
            return { ...action.payload };
        default:
            return state;
    }
}
const policyTechnology = (state = {}, action) => {
    switch (action.type) {
        case POLICY_TECHNOLOGY:
            return { ...action.payload };
        default:
            return state;
    }
}
const PolicyReducer = combineReducers({
    policyType,
    policyListData,
    policyTechnology
});

export default PolicyReducer;


