import { combineReducers } from 'redux';
import {
    MAJOR_INVESTORS
} from '../../../../constants/actionConstants';

const upperFooterData = (state = {}, action) => {
    switch (action.type) {
        case MAJOR_INVESTORS:
            return { ...action.payload };
        default:
            return state;
    }
}
const UpperFooter = combineReducers({
    upperFooterData
});

export default UpperFooter;


