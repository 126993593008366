import { combineReducers } from 'redux';
import {
    HEADER_DATA,
    SEARCH_DATA
} from '../../../../constants/actionConstants';

const headerData = (state = {}, action) => {
    switch (action.type) {
        case HEADER_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}


const searchData = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_DATA:
            return [ ...action.payload ];
        default:
            return state;
    }
}
const HeaderReducer = combineReducers({
    headerData,
    searchData
});

export default HeaderReducer;


