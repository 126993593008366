import React, { useState, useLayoutEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import Slider from "react-slick";
import dummyMissionImage from "../../../../../assests/images/dummy_img.png";
import { Parallax } from "react-scroll-parallax";
import SocialShare from "../../../../common/SocialShare";
import { useController } from "react-scroll-parallax";

const InterestingFacts = (props) => {
  const { parallaxController } = useController();
  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);
  const { item, count, data, SectionId } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [social] = useState({
    url: `${window.location.host}/citizen-corner/${SectionId}`,
    title: data?.heading,
    sepreater: "::",
    size: "2.5rem",
  });
  return (
    <div className="interesting-facts clearfix custom-slider-nextPrev">
      <div className="yellow-line" />
      <div className="blue-line" />
      <div className="green-line" />
      {/* <Parallax className="blue-line" y={["-20px", "3px"]} tagOuter="div">
        <div className="blue-line-img" />
      </Parallax>
      <Parallax className="green-line" y={["-25px", "1px"]} tagOuter="div">
        <div className="green-line-img" />
      </Parallax> */}
      <Parallax className="dustbin" x={["-15px", "15px"]} tagOuter="div">
        <div className="dustbin-img" />
      </Parallax>
      <Parallax className="large-tree" x={["-40px", "40px"]} tagOuter="div">
        <div className="large-tree-img" />
      </Parallax>
      <Parallax className="small-tree" x={["-40px", "40px"]} tagOuter="div">
        <div className="small-tree-img" />
      </Parallax>
      <div className="container">
        <Parallax className="green-bird" x={["-40px", "40px"]} tagOuter="div">
          <div className="green-bird-img" />
        </Parallax>

        <h3>{ReactHtmlParser(data?.heading)}</h3>
        <div className="citizen-social-share">
          <SocialShare social={social} />
        </div>
        <Slider {...settings}>
          {data?.facts.map((slide, index) => {
            return (
              <div className="row" key={index}>
                <div className="item">
                  <div className="two-section">
                    {slide[0] && slide[0].Image && (
                      <div className="facts-container">
                        {slide[0] && slide[0].Image && (
                          <img src={slide[0].Image} alt="" />
                        )}
                        <div className="overlay one">
                          {slide[0] && slide[0].Title && (
                            <div className="count-outer">
                              <span className="count">{slide[0].Title}</span>
                            </div>
                          )}
                          <div className="info">
                            {slide[0] &&
                              slide[0].Body &&
                              ReactHtmlParser(slide[0].Body)}
                          </div>
                        </div>
                      </div>
                    )}
                    {slide[1] && slide[1].Image && (
                      <div className="facts-container">
                        {slide[1] && slide[1].Image && (
                          <img src={slide[1].Image} alt="" />
                        )}
                        <div className="overlay two">
                          {slide[1] && slide[1].Title && (
                            <div className="count-outer">
                              <span className="count">{slide[1].Title}</span>
                            </div>
                          )}
                          <div className="info">
                            {slide[1] &&
                              slide[1].Body &&
                              ReactHtmlParser(slide[1].Body)}
                          </div>
                        </div>
                      </div>
                    )}
                    {slide[2] && slide[2].Image && (
                      <div
                        className={`facts-container ${
                          slide[2].Title === "#3" ? "full-height" : ""
                        }`}
                      >
                        {slide[2] && slide[2].Image && (
                          <img src={slide[2].Image} alt="" />
                        )}
                        <div className="overlay three">
                          {slide[2] && slide[2].Title && (
                            <div className="count-outer">
                              <span className="count">{slide[2].Title}</span>
                            </div>
                          )}
                          <div className="info">
                            {slide[2] &&
                              slide[2].Body &&
                              ReactHtmlParser(slide[2].Body)}
                          </div>
                        </div>
                      </div>
                    )}
                    {slide[3] && slide[3].Image && (
                      <div className="facts-container">
                        {slide[3] && slide[3].Image && (
                          <img src={slide[3].Image} alt="" />
                        )}
                        <div className="overlay three">
                          {slide[3] && slide[3].Title && (
                            <div className="count-outer">
                              <span className="count">{slide[3].Title}</span>
                            </div>
                          )}
                          <div className="info">
                            {slide[3] &&
                              slide[3].Body &&
                              ReactHtmlParser(slide[3].Body)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default InterestingFacts;
