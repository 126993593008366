export const dropdownValue = {
    yesNo:[
        {
            text:"Yes",
            value:"2"
        },
        {
            text:"No",
            value:"1"
        },
    ],
    yesNoInput:[
        {
            text:"Yes",
            value:"setInputTrue",
            input:true
        },
        {
            text:"No",
            value:"1",
            input:false
        },
    ],
    geographic: [
        {
            text: "Hilly regions",
            value: "1"
        },
        {
            text: "Arid regions",
            value: "2"
        },
        {
            text: "Riverine",
            value: "3"
        },
        {
            text: "Cold Areas",
            value: "4"
        }
    ],
    technologyReadiness: [
        {
            text: "Proof of Concept",
            value: "1"
        },
        {
            text: "Pilot",
            value: "2"
        },
        {
            text: "Product Deployed",
            value: "3"
        },
        
    ],
    skillType: [
        {
            text: "Skilled",
            value: "1"
        },
        {
            text: "Semiskilled",
            value: "2"
        },
        {
            text: "Unskilled",
            value: "3"
        },

    ],
    skillType: [
        {
            text: "Skilled",
            value: "1"
        },
        {
            text: "Semiskilled",
            value: "2"
        },
        {
            text: "Unskilled",
            value: "3"
        },

    ],

    // yesNoInput:[
    //     {
    //         text:"Municipal Plastic Waste",
    //         value:"Municipal Plastic Waste",
    //         input:true
    //     }, {
    //         text:"Plastic Waste",
    //         value:"Plastic Waste",
    //         input:false
    //     }, {
    //         text:"Wastewater",
    //         value:"Wastewater",
    //         input:false
    //     }, {
    //         text:" E-waste",
    //         value:" E-waste",
    //         input:false
    //     }, {
    //         text:" E-waste",
    //         value:" E-waste",
    //         input:false
    //     }, {
    //         text:"Biomedical Waste",
    //         value:"Biomedical Waste",
    //         input:false
    //     }, {
    //         text:"Biomedical Waste",
    //         value:"Biomedical Waste",
    //         input:false
    //     },
        
    // ],
}