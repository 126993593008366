import { combineReducers } from 'redux';
import {
    SELECT_LANGUAGE
} from '../../../../constants/actionConstants';

const siteLanguage = (state = "", action) => {
    switch (action.type) {
        case SELECT_LANGUAGE:
            return action.payload;
        default:
            return state;
    }
}

const LanguageReducer = combineReducers({
    siteLanguage
});

export default LanguageReducer;


