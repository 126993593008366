import { combineReducers } from 'redux';
import {
    CITEZEN_CORNER,
    NUMBER_OF_VISITORS
} from '../../../constants/actionConstants';

const citizenCorner = (state = {}, action) => {
    switch (action.type) {
        case CITEZEN_CORNER:
            return { ...action.payload };
        default:
            return state;
    }
}

const numberOfVistiors = (state = {}, action) => {
    switch (action.type) {
        case NUMBER_OF_VISITORS:
            return { ...action.payload };
        default:
            return state;
    }
}

const CitizenCornerReducer = combineReducers({
    numberOfVistiors,
    citizenCorner

});

export default CitizenCornerReducer;


