import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import dummyMissionImage from "../../../../../assests/images/dummy_img.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SocialShare from "../../../../common/SocialShare";

const CitizenEngagements = (props) => {
  const { item, count, data, SectionId } = props;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 481,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [social] = useState({
    url: `${window.location.host}/citizen-corner/${SectionId}`,
    title: data?.heading,
    sepreater: "::",
    size: "2.5rem",
  });
  return (
    <div
      className="citizen-engagements custom-slider-nextPrev citiZen"
      style={{ marginTop: "5%" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex quiz-header">
            <h2>{ReactHtmlParser(data?.heading)}</h2>
          </div>
        </div>

        <Slider {...settings}>
          {data &&
            data?.list &&
            data?.list.map((item, index) => {
              return (
                <div className="row">
                  <div className="col">
                    <div className="item hover-zoom">
                      <div className="image">
                        <button className="ci_card_tag">
                          {item?.category}
                        </button>
                        <img src={item.image} />
                      </div>
                      <div className="content">
                        <h5>{item.title}</h5>
                        {item.date && (
                          <span className="date">{item?.date}</span>
                        )}
                        {item.field_location && (
                          <span className="locations-info">
                            {item?.field_location}
                          </span>
                        )}
                        <Link
                          to={`/current-intervention-listing${item?.link}`}
                          className="link-goto-bottom"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default CitizenEngagements;
