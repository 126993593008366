import api from '../../../../dependencies/utils/Api';
import {
    FOOTER_DATA
} from "../../../../constants/ApplicationUrl";

export const HeaderService = {
    getHeader() {
        return api.GET(FOOTER_DATA)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response || {};
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }

            }).catch(e => {
                return Promise.reject(e);
            });
    },
}
