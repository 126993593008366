import api from "../../../dependencies/utils/Api";
import {
  QUOTES,
  SWACHHTA_SARTHI_FELLOWSHIP_HOME,
  LATEST_NEWS,
  ABOUT_COHORT_GET_STATE_DETAILS,
  FELLOWSHIP_LATEST_NEWS,
  FELLOWSHIP_INITATIVES,
  SWACHHTA_SARTHI_COHORT_MAP,
  SWACHHTA_SARTHI_ABOUT_MENTOR,
  SWACHHTA_SARTHI_MEET_OUR_FELLOWS,
} from "../../../constants/ApplicationUrl";

export const StaticPageService = {
  getAboutUs() {
    return api
      .GET(SWACHHTA_SARTHI_FELLOWSHIP_HOME)
      .then((response) => {
        console.log(response.data);
        const { data = { data } } = response.data || {};

        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  quotes() {
    return api
      .GET(`${QUOTES}`)
      .then((response) => {
        const { data: { responseCode, data } = {} } = response;
        if (responseCode === 200) {
          return data;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getMapFellowship(url) {
    return api
      .GET(`${SWACHHTA_SARTHI_COHORT_MAP}${url}`)
      .then((response) => {
        const { data = { data } } = response.data || {};

        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  latestNews() {
    return api
      .GET(FELLOWSHIP_LATEST_NEWS)
      .then((response) => {
        console.log(response);
        const { data: { responseCode, data } = {} } = response || {};
        if (responseCode === 200) {
          return data;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  // getFellowshipInitiatives() {
  //   return api
  //     .GET(FELLOWSHIP_INITATIVES)
  //     .then((response) => {
  //       console.log(response);
  //       const { data: { responseCode, data } = {} } = response || {};
  //       if (responseCode === 200) {
  //         return data;
  //       } else {
  //         return Promise.reject(response.data);
  //       }
  //     })
  //     .catch((e) => {
  //       return Promise.reject(e);
  //     });
  // },

  getFellowshipInitiatives(url) {
    return api
      .GET(`${FELLOWSHIP_INITATIVES}${url}`)
      .then((response) => {
        // console.log(response);
        // const { data: { responseCode, data } = {} } = response || {};
        // if (responseCode === 200) {
        //   return data;
        // } else {
        //   return Promise.reject(response.data);
        // }
        const { data = { data } } = response.data || {};

        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getFellowsFellowship(url) {
    return api
      .GET(`${SWACHHTA_SARTHI_MEET_OUR_FELLOWS}${url}`)
      .then((response) => {
        const { data = { data } } = response.data || {};

        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getMentorFellowship(url) {
    return api
      .GET(`${SWACHHTA_SARTHI_ABOUT_MENTOR}${url}`)
      .then((response) => {
        const { data = { data } } = response.data || {};

        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  getaboutcohortStateDetails(params) {
    console.log(params);
    return (
      api
        .POST(ABOUT_COHORT_GET_STATE_DETAILS, params)
        // return api.POST(SUBMIT_CONTACT_US, params)
        .then((response) => {
          try {
            console.log(response.data);
            const { data = { data } } = response.data || {};

            return { data };
          } catch (error) {}
        })
        .catch((e) => {
          return Promise.reject(e);
        })
    );
  },
};
