import { combineReducers } from 'redux';
import {
    FOOTER_DATA
} from '../../../../constants/actionConstants';

const footerData = (state = {}, action) => {
    switch (action.type) {
        case FOOTER_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}
const FooterReducer = combineReducers({
    footerData
});

export default FooterReducer;


