import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import DeployedTechnologyModal from '../Modal/index'
import './style.scss';

const DeployedTechnologies = (props) => {
    const { data } = props
    const [showModal, setShowModal] = useState(false)
    const [modalItem, setModalItem] = useState(false)
    const [titleValue, setTitleValue] = useState(false)

    const handleReadMore = (item,title) => {
        setModalItem(item)
        setShowModal(true)
        setTitleValue(title)
    }
    return (
        <div className="DeployedTechnologies">
            <div className='container'>
                <div className='container-wrap'>
                    <h1 className='deployedTechnologies-heading'>{ReactHtmlParser(data?.heading)}</h1>
                    <div className='row tech-card-container'>
                        {
                            data
                            && data?.list
                            && Array.isArray(data?.list)
                            && data?.list.map((value, index) => {
                                return (
                                    <div className='col-sm-3 tech-card' key={index}>
                                        <div className='row'>
                                            <img src={value?.image} className='tech-card-image col-sm-12' alt="" />
                                            <div className='tech-card-text col-sm-12'>
                                                <div className='heading-of-card'>{ReactHtmlParser(value?.title)}</div>
                                                <div className='tech-card-desc ellipsis-2'>{ReactHtmlParser(value?.description)}</div>
                                                {/* <Link to={`/validated-technologies-listing${value?.link}`}> */}
                                                <button className='green-btn tech-btn' onClick={() => handleReadMore(value?.deployData,value?.title)}>{value?.linkTxt} </button>
                                                {/* </Link> */}

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <DeployedTechnologyModal
                heading={titleValue}
                item={modalItem}
                isOpen={showModal}
                onClose={() => {
                    setShowModal(false)
                }} />
        </div>
    );
}

export default DeployedTechnologies;