import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import ModalHOC from "../../../../common/Modal";
import clockIcon from "../../../../../assests/icons/time.svg";
import locationIcon from "../../../../../assests/icons/location.svg";
import "./style.scss";


const ReadMoreModal = (props) => {
    const { data, className } = props;
    return (
        <div className="read-more-modal">
            <h3 className="title">
                {data?.title}
            </h3>
            <div className="time-location">
                <div className="ellipsis-1"><img src={clockIcon} />&nbsp;{data?.date}</div>
                <div className="ellipsis-1"><img src={locationIcon} />&nbsp;{data?.field_location} {data?.location}</div>
            </div>
            {ReactHtmlParser(data?.body)}
        </div>
    )
}
export default connect(null, {})(ModalHOC(ReadMoreModal));