import ReactHtmlParser from 'react-html-parser';
import './style.scss';
import dummyMissionImage from '../../../../../assests/images/dummy_img.png';

const CitizenConnect = (props) => {
    const { data, numberOfVistiors } = props
    return (
        <div className="citizen-connect">
            <div className='container'>
                {data &&
                    <div className="connect-msg">
                        <h2>{ReactHtmlParser(data)}</h2>
                    </div>}
                <div className="call-no">
                    <span className="phoneCal-icon"></span>
                    <span className="number">{numberOfVistiors}</span>Visitors on this portal
                </div>
            </div>
        </div>
    );
}

export default CitizenConnect;