import { HeaderService } from './service';
import {
    HEADER_DATA,
    SEARCH_DATA
} from '../../../../constants/actionConstants';
export const getHeaderData = () => async (dispatch) => {

    let headerData = await HeaderService.getHeader()
    dispatch({
        type: HEADER_DATA,
        payload: headerData
    })
}

export const getSearchData = () => async (dispatch) => {

    let searchData = await HeaderService.getSearch()
    dispatch({
        type: SEARCH_DATA,
        payload: searchData
    })
}