import React from "react";
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./dependencies/store/index";
import { Provider } from "react-redux";

const root = createRoot(document.getElementById("root"));
// ReactDOM.render(
//   // <React.StrictMode> // </React.StrictMode>
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   // document.getElementById('root')
// );

root.render(
  // <React.StrictMode> // </React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
