/**
 * This  component use for list of current intervention list with filter
 */
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import InputField from "../../../components/common/InputField";
import Button from "../../../components/common/Button";
import ListWithPagination from "../../common/ListWithPagination";
import ReactHtmlParser from "react-html-parser";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import placeholderAuthor from "../../../assests/images/placeholder_author.png";
import {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
  getArticlesTechnologyType,
  getArticlesTagsType,
  // getArticlesArchivesType,
  getArticlesDetailsType,
  getArticlesTagList,
  getMostReadType,
} from "../dependencies/action";
import LeftSideCategory from "../../../components/common/LeftSideCategory";
import GlobalDropDown from "../../common/GlobalDropDown";
import Breadcrumb from "../../common/Breadcrumbs";
import "./style.scss";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const items = [
  // { to: "/", label: "/" },
  // { to: "/current-intervention-listing", label: "What We Do" },
  // { to: "/current-intervention-listing", label: "Our Sentinels" },
  // {
  //   to: "/current-intervention-listing",
  //   label: "Current Projects",
  //   active: true,
  // },

  { to: "/", label: "/" },
  { to: "/articles-case-studies", label: "Resources" },
  {
    to: "/articles-case-studies",
    label: "Articles & Case Studies",
    active: true,
  },
];

const ArticlesCaseStudiesList = (props) => {
  const {
    getChallengeEventList,
    currentInterventionList,
    getTechnologyType,
    interventionTechnology,
    getFilterData,
    filterData,
    articlesTechnology,
    getArticlesTechnologyType,
    getArticlesTagList,
    getArticlesTagsType,
    tagsTechnology,
    // getArticlesArchivesType,
    // archivesTechnology,
    articlesDetails,
    getArticlesDetailsType,
    getMostReadType,
    mostReadType,
  } = props;
  const [page, setPage] = useState(0);
  const [categoryId, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [pageStatus, setPageStatus] = useState(false);
  const [isMoreFilter, setMoreFilter] = useState(false);
  const handleShowMoreFiltere = () => setMoreFilter(!isMoreFilter);
  const [archiveDate, setArchiveDate] = useState("2022-04");
  const [monthName, setMonthName] = useState();
  const [yearNew, setYearnew] = useState([]);
  const defaultFormData = {
    state: "",
    city: "",
    projectTimeline: "",
    useType: "",
    subTheme: "",
    scale: "",
    specialCondition: "",
    search: "",
  };
  const [formData, setFormData] = useState(defaultFormData);

  const changePageNumber = (pageNumber) => {
    setPageStatus(false);
    setPage((pageNumber - 1) * 6);
  };
  const yearnew = [];
  useEffect(() => {
    console.log("articlesTechnology", articlesTechnology);
    console.log("tagsTechnology", tagsTechnology);
    getChallengeEventListData();

    const monthname = [
      // "January",
      // "February",
      // "March",
      // "April",
      // "May",
      // "June",
      // "July",
      // "August",
      // "September",
      // "October",
      // "November",
      // "December",
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    const d2 = new Date();
    let cm = d2.getMonth();
    let sy = d2.getFullYear() - 4;
    let ey = d2.getFullYear();
    const monthnew = [];

    for (var i = ey; i >= sy; i--) {
      var endMonth = i != ey ? 11 : cm;
      var startMon = 0;
      const monthItem = [];
      //year
      console.log(i);

      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        const monthItemObj = {
          id: displayMonth,
          name: monthname[displayMonth - 1],
        };
        console.log("displayMonth", displayMonth);
        //month
        console.log("monthname", monthname[displayMonth - 1]);
        // monthnew.push(monthname[displayMonth - 1]);
        monthItem.push(monthItemObj);
      }
      const newyear = {
        year: i,
        month: monthItem,
      };
      console.log("monthItem", monthItem);
      yearnew.push(newyear);
      console.log("yearnew", yearnew);
      setYearnew(yearnew);
    }
  }, [page, categoryId]);
  const handleInputChange = (e, name) => {
    const { value } = e.target;

    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var d = new Date();
  var currentMonth = months[d.getMonth()];
  console.log("current month", currentMonth);

  const mostreaddata = [
    {
      id: 1,
      date: "May 08, 2021",
      title:
        "Applying for a Curfew/Movement/Emergency Pass during COVID-19 Lockdown",
    },
    {
      id: 2,
      date: "May 09, 2021",
      title: "State and National funds accepting donations for COVID-19",
    },
    {
      id: 3,
      date: "May 12, 2021",
      title: "10 Types of Financial Services Offered in India",
    },
  ];

  const calendardata = [
    {
      number: "One",
      year: "2023",
      month: [
        { id: "01", name: "JAN" },
        { id: "02", name: "FEB" },
        { id: "03", name: "MAR" },
      ],
    },
    {
      number: "Two",
      year: "2022",
      month: [
        { id: "01", name: "JAN" },
        { id: "02", name: "FEB" },
        { id: "03", name: "MAR" },
        { id: "04", name: "APR" },
        { id: "05", name: "MAY" },
        { id: "06", name: "JUN" },
        { id: "07", name: "JUL" },
        { id: "08", name: "AUG" },
        { id: "09", name: "SEP" },
        { id: "10", name: "OCT" },
        { id: "11", name: "NOV" },
        { id: "12", name: "DEC" },
      ],
    },
    {
      number: "Three",
      year: "2021",
      month: [
        { id: "01", name: "JAN" },
        { id: "02", name: "FEB" },
        { id: "03", name: "MAR" },
        { id: "04", name: "APR" },
        { id: "05", name: "MAY" },
        { id: "06", name: "JUN" },
        { id: "07", name: "JUL" },
        { id: "08", name: "AUG" },
        { id: "09", name: "SEP" },
        { id: "10", name: "OCT" },
        { id: "11", name: "NOV" },
        { id: "12", name: "DEC" },
      ],
    },
    {
      number: "Four",
      year: "2020",
      month: [
        { id: "01", name: "JAN" },
        { id: "02", name: "FEB" },
        { id: "03", name: "MAR" },
        { id: "04", name: "APR" },
        { id: "05", name: "MAY" },
        { id: "06", name: "JUN" },
        { id: "07", name: "JUL" },
        { id: "08", name: "AUG" },
        { id: "09", name: "SEP" },
        { id: "10", name: "OCT" },
        { id: "11", name: "NOV" },
        { id: "12", name: "DEC" },
      ],
    },
    {
      number: "Five",
      year: "2019",
      month: [
        { id: "01", name: "JAN" },
        { id: "02", name: "FEB" },
        { id: "03", name: "MAR" },
        { id: "04", name: "APR" },
        { id: "05", name: "MAY" },
        { id: "06", name: "JUN" },
        { id: "07", name: "JUL" },
        { id: "08", name: "AUG" },
        { id: "09", name: "SEP" },
        { id: "10", name: "OCT" },
        { id: "11", name: "NOV" },
        { id: "12", name: "DEC" },
      ],
    },
    // {
    //   number: "Five",
    //   year: "2018",
    //   month: [
    //     { id: "01", name: "JAN" },
    //     { id: "02", name: "FEB" },
    //     { id: "03", name: "MAR" },
    //     { id: "04", name: "APR" },
    //     { id: "05", name: "MAY" },
    //     { id: "06", name: "JUN" },
    //     { id: "07", name: "JUL" },
    //     { id: "08", name: "AUG" },
    //     { id: "09", name: "SEP" },
    //     { id: "10", name: "OCT" },
    //     { id: "11", name: "NOV" },
    //     { id: "12", name: "DEC" },
    //   ],
    // },
  ];

  useEffect(() => {
    // to call the initialise google analytics funtion
    try {
      initGA();
      window.GA_INITIALIZED = true;
      // to save the number og visitors on this side
      logPageView();
    } catch (error) {}
  }, [window]);

  const getChallengeEventListData = (date, tagId) => {
    getArticlesTechnologyType(
      page,
      categoryId,
      formData.city,
      formData.search,
      formData.useType,
      formData.subTheme,
      formData.scale,
      formData.specialCondition,
      formData.state,
      formData.projectTimeline,
      // formData.language,
      // formData.search,
      // formData.date
      date || 0,
      tagId || 0
    );
  };
  const getArticlesTagListData = (date, tagId) => {
    getArticlesTagList(
      page,
      categoryId,
      formData.city,
      formData.search,
      formData.useType,
      formData.subTheme,
      formData.scale,
      formData.specialCondition,
      formData.state,
      formData.projectTimeline,
      // formData.language,
      // formData.search,
      // formData.date
      date || 0,
      tagId || 0
    );
  };

  // const getChallengeArchivesData = (date) => {
  //   getArticlesArchivesType(date);
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    setPage(0);
    setPageStatus(true);
    getChallengeEventListData();
  };

  useEffect(() => {
    getArticlesTechnologyType();
    getTechnologyType();
    getFilterData();
    getChallengeEventListData();
    // getArticlesArchivesType();
    getArticlesTagsType();
    getMostReadType();
    // getArticlesArchivesType();
    // getArticlesDetailsType();
  }, []);

  const getCategoryId = (Id) => {
    setCategory(Id);
  };
  return (
    <div className="ArticlesCaseStudies">
      <Breadcrumb data={items} displayName={"Articles & Case Studies"} />

      <div className="bottom-section-list">
        <div className="container common-listing">
          <div className="row">
            <div className="col-3 sidebar">
              <div className="category-box archivemonth">
                <h3 className="sub-title f19">
                  {/* {ReactHtmlParser(interventionTechnology?.heading)} */}
                  {"Archive"}
                </h3>

                <Accordion allowZeroExpanded>
                  {/* {calendardata.map((item, index) => ( */}
                  {yearNew.map((item, index) => (
                    <AccordionItem key={index}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{item.year}</AccordionItemButton>
                      </AccordionItemHeading>
                      {item.month.map((month, index) => (
                        <AccordionItemPanel
                          className="accordion__panel archivePanel"
                          style={{ display: "inline-block", cursor: "pointer" }}
                          onClick={() => {
                            console.log(`${item.year}-${month.id}`);

                            getChallengeEventListData(
                              `${item.year}-${month.id}`
                            );
                            // getChallengeEventListData(
                            //   `${item.year}-${month.id}`
                            // );

                            // setArchiveDate(`${item.year}-${month.id}`);
                          }}
                        >
                          {month.name}
                        </AccordionItemPanel>
                      ))}
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
              <div className="category-box">
                <h3 className="sub-title f19">
                  {/* {ReactHtmlParser(interventionTechnology?.heading)} */}
                  {"Popular Tags"}
                </h3>
                <ul>
                  {tagsTechnology &&
                    Array.isArray(tagsTechnology.list) &&
                    tagsTechnology.list.length > 0 &&
                    tagsTechnology.list.map((value, index) => {
                      return (
                        <li
                          className="tagslist"
                          style={{
                            display: "inline-block",
                          }}
                        >
                          <span
                            className="individualtag"
                            onClick={() => {
                              getArticlesTagListData("", value?.id);
                            }}
                          >
                            {value?.tag}
                          </span>
                        </li>
                      );
                    })}
                </ul>

                {/* <ul>
                  {articlesTechnology.list.map((value, index) => (
                    <li
                      className="tagslist"
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <span
                        className="individualtag"
                        // onClick={() => {
                        //   getCategoryId(Id);
                        // }}
                      >
                        {value?.title}
                      </span>
                    </li>
                  ))}
                </ul> */}
              </div>
              <div className="category-box">
                <h3 className="sub-title f19">
                  {/* {ReactHtmlParser(interventionTechnology?.heading)} */}
                  {"Most Read"}
                </h3>
                <ul>
                  {/* {interventionTechnology &&
                    Array.isArray(interventionTechnology.list) &&
                    interventionTechnology.list.length > 0 &&
                    interventionTechnology.list.map((value, index) => {
                      let Id = value?.id;
                      return (
                        <li
                          className="blogListItem"
                         
                        >
                         
                          <div>
                            <span>
                              <div className="blog-post-date">May 08, 2021</div>
                              <div className="blog-post-title">
                                <a href="/bip/resources/applying-curfewmovementemergency-pass-during-covid-19-lockdown">
                                  Applying for a Curfew/Movement/Emergency Pass
                                  during COVID-19 Lockdown
                                </a>
                              </div>
                              <div className="blog-author"></div>
                            </span>
                          </div>
                        </li>
                     
                     );
                    })} */}
                  {mostReadType &&
                    Array.isArray(mostReadType?.list) &&
                    mostReadType?.list.length > 0 &&
                    mostReadType?.list.map((value, index) => (
                      <li className="blogListItem mostreadblogs" key={index}>
                        <div>
                          <span>
                            <div className="blog-post-date">
                              {moment(value?.publish_date).format(
                                "MMM Do YYYY"
                              )}
                            </div>
                            <div className="blog-post-title">
                              <Link
                                // href="/bip/resources/
                                // applying-curfewmovementemergency-pass-during-covid-19-lockdown"
                                to={{
                                  pathname: `/${"articles-case-studies"}${
                                    value?.pagelink
                                  }`,

                                  state: {
                                    // blogName: value?.title,
                                    // blogText: value?.body,
                                    // blogDate: moment(
                                    //   value?.publish_date
                                    // ).format("MMM Do YYYY"),
                                    // blogAuthor: value?.author,
                                    // // authorImage: placeholderAuthor,
                                    // authorName: value?.author[0]?.name,
                                    pageLink: value?.pagelink,
                                  },
                                }}
                              >
                                {value.title}
                              </Link>
                            </div>
                            <div className="blog-author">
                              <div className="view-header">
                                {/* <div className="view-header-img">
                                  <img
                                    src={placeholderAuthor}
                                    width="130"
                                    height="130"
                                    alt="Invest India"
                                    loading="lazy"
                                    typeof="Image"
                                    class="image-style-avtar"
                                  />
                                </div> */}

                                <div class="below-view-by">By:</div>
                                {/* <span className="view-header-span">By:</span> */}
                              </div>
                              <div className="view-content">
                                {value?.author.length != 0 ? (
                                  value?.author.map((authoritem, index) => (
                                    <div className="blog-name" key={index}>
                                      <div>
                                        <span>
                                          <div className="name">
                                            <a href="#">{authoritem?.name}</a>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="blog-name">
                                    <div>
                                      <span>
                                        <div className="name">
                                          <a href="#">{"NA"}</a>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {/* <div className="blog-name">
                                  <div>
                                    <span>
                                      <div className="name">
                                        <a href="#">
                                          {value?.author[0]?.name || "NA"}
                                        </a>
                                      </div>
                                    </span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </span>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>

              {/* <Link
                 to="/validated-technologies-listing"
                 className="col-12 green-btn filter-btn"
               >
                 Waste To Wealth Technology Repository
               </Link> */}
            </div>
            <div className="col-9 list-container">
              <div className="boxWithShadow">
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-listing-title">
                      {/* {ReactHtmlParser(currentInterventionList?.heading)} */}

                      <h3 style={{ color: "rgb(24, 59, 99)" }}>
                        {`Latest Articles (${articlesTechnology?.list.length})`}
                      </h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="search">
                      <form onSubmit={(e) => onSubmit(e)}>
                        <InputField
                          type="search"
                          name="search"
                          placeholder="Search Articles"
                          value={formData.search}
                          onChange={handleInputChange}
                          containerClass={"form-group"}
                          className="subscribe-input-field"
                        />
                        <div className="btn-group">
                          <Button
                            icon={
                              <img src={searchIcon} className="search-icon" />
                            }
                            type="submit"
                            text=""
                            className="list-submit-button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <ListWithPagination
                  data={articlesTechnology?.list}
                  itemsCountPerPage={5}
                  totalItemsCount={articlesTechnology?.list?.length}
                  pageRangeDisplayed={5}
                  onChangePagination={changePageNumber}
                  checkStatus={pageStatus}
                  nextUrl={"articles-case-studies"}
                  type={"articles-case"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("redux states", state);
  return {
    currentInterventionList: state.ArticlesReducer.currentInterventionList.data,
    interventionTechnology: state.ArticlesReducer.interventionTechnology.data,
    articlesTechnology: state.ArticlesReducer.articlesTechnology.data,
    tagsTechnology: state.ArticlesReducer.tagsTechnology.data,
    filterData: state.ArticlesReducer.filterData,
    mostReadType: state.ArticlesReducer.mostReadType.data,
  };
};

export default connect(mapStateToProps, {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
  getArticlesTechnologyType,
  getArticlesTagsType,
  // getArticlesArchivesType,
  getArticlesDetailsType,
  // getArticlesChallengeEventList,
  getMostReadType,
  getArticlesTagList,
})(withRouter(ArticlesCaseStudiesList));
