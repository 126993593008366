import ReactHtmlParser from 'react-html-parser';
import './style.scss';

const SectionDescription = (props) => {

    return (
        <div className="section-description">
            {ReactHtmlParser(props?.description)}
        </div>
    )
}
export default SectionDescription;