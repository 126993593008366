/**
 * This  component use for list of current intervention list with filter
 */
import { connect } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import plaseholderrutag from "../assets/placeholderrutag.jpg";
import ReactHtmlParser from "react-html-parser";
// import OverView2 from "../dependencies/component/OverView2";
// import { ShareSocial } from "react-share-social";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

// import InputField from "../../../components/common/InputField";
// import Button from "../../../components/common/Button";
// import ReactHtmlParser from "react-html-parser";
// import searchIcon from "../../../assests/svg/searchIcon.svg";

import { getRutagDetails } from "../dependencies/action";

// import LeftSideCategory from "../../../components/common/LeftSideCategory";
// import GlobalDropDown from "../../common/GlobalDropDown";
import Breadcrumb from "../../common/Breadcrumbs";
import "./style.scss";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const style = {
  background: "none",
  borderRadius: 0,
  border: 0,
  color: "transparent",
  padding: "0px",
  boxShadow: "0px",
  // display: "none",
};

const RutagDetailspage = (props) => {
  const { getRutagDetails, rutagDetailsData } = props;
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [categoryId, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [pageStatus, setPageStatus] = useState(false);
  const [isMoreFilter, setMoreFilter] = useState(false);
  const [newpathlast, setnewpathlast] = useState();
  const [newpathlastsplit, setnewpathlastsplit] = useState();
  const handleShowMoreFiltere = () => setMoreFilter(!isMoreFilter);
  const defaultFormData = {
    state: "",
    city: "",
    projectTimeline: "",
    useType: "",
    subTheme: "",
    scale: "",
    specialCondition: "",
    search: "",
  };
  const [formData, setFormData] = useState(defaultFormData);

  const sharesocial = useRef();
  const articlestitletext = useRef();

  const changePageNumber = (pageNumber) => {
    setPageStatus(false);
    setPage((pageNumber - 1) * 6);
  };
  // useEffect(() => {
  //   getRutagDetails(`${location.state?.pagelink}`);
  // }, []);
  const handleInputChange = (e, name) => {
    const { value } = e.target;

    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };

  useEffect(() => {
    console.log("location.state", location.state);
    console.log("rutag details pathname", window.location.pathname);

    const newpathnamearray = window.location.pathname.split("/");

    console.log("newpathnamearray", newpathnamearray);

    const newpathlast = newpathnamearray[newpathnamearray.length - 1];

    const newpathlastsplit = newpathlast.split("-").join(" ");
    getRutagDetails(`/${newpathlast}`);
    console.log("newpathlast", newpathlast);
    console.log("newpathlast", newpathlastsplit);
    setnewpathlast(newpathlast);
    setnewpathlastsplit(newpathlastsplit);
    console.log("rutagDetailsData", rutagDetailsData.data);
  }, []);

  const items = [
    // { to: "/", label: "/" },
    // { to: "/current-intervention-listing", label: "What We Do" },
    // { to: "/current-intervention-listing", label: "Our Sentinels" },
    // {
    //   to: "/current-intervention-listing",
    //   label: "Current Projects",
    //   active: true,
    // },

    { to: "/", label: "/" },
    { to: "/rutag", label: "Resources" },
    { to: "/rutag", label: "RuTAG" },
    {
      to: `/rutag${location.state?.pagelink}`,
      label: rutagDetailsData.data?.list?.title,
      active: true,
    },
  ];

  const mostreaddata = [
    {
      id: 1,
      date: "May 08, 2021",
      title:
        "Applying for a Curfew/Movement/Emergency Pass during COVID-19 Lockdown",
    },
    {
      id: 2,
      date: "May 09, 2021",
      title: "State and National funds accepting donations for COVID-19",
    },
    {
      id: 3,
      date: "May 12, 2021",
      title: "10 Types of Financial Services Offered in India",
    },
  ];

  useEffect(() => {
    // to call the initialise google analytics funtion
    try {
      initGA();
      window.GA_INITIALIZED = true;
      // to save the number og visitors on this side
      logPageView();
    } catch (error) {}
  }, [window]);

  // const getChallengeEventListData = () => {
  //   getArticlesDetailsType(props.location.state?.pageLink);
  // };
  const onSubmit = (e) => {
    e.preventDefault();
    setPage(0);
    setPageStatus(true);
    // getChallengeEventListData();
  };

  const getCategoryId = (Id) => {
    setCategory(Id);
  };

  return (
    <div className="Rutagdetailspage">
      <Breadcrumb
        data={items}
        displayName={rutagDetailsData.data?.list?.title}
      />

      <div className="bottom-section-list">
        <div className="common-listing blogcontainer">
          <div className="row blogrow">
            {/* <div className="col-3 sidebar">
                
              </div> */}
            <div className="col-xs-12 list-container">
              <div className="boxWithShadow">
                {/* <div className="social-icons-div">
                   <div>
                     <a href="#" class="fa fa-facebook"></a>
                     <a href="#" class="fa fa-twitter"></a>
                     <a href="#" class="fa fa-linkedin"></a>
                   </div>
                 </div> */}
                {/* <div
                  ref={sharesocial}
                  className="reactSocialDiv"
                  // style={{ display: "flex", justifyContent: "flex-end" }}
                >
                 
                  <div className="sharetoheader">share</div>
                  <div
                    // style={{ width: "fit-content", position: "absolute" }}
                    className="reactsocialinner"
                  >
                    <FacebookShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                  <div className="blogpage-date-tag">
                    <time>
                      {articlesDetails?.data?.publish_date != null
                        ? articlesDetails?.data?.publish_date
                        : "NA"}
                    </time>
                  </div>
                </div> */}

                <h1
                  // ref={articlestitletext}
                  className="blogTitleName"
                  style={{
                    width:
                      // articlestitletext?.current?.innerHTML.length > 41
                      //   ? "85%"
                      //   : "",
                      "100%",
                    textAlign:
                      // articlestitletext?.current?.innerHTML.length > 41
                      //   ? "center"
                      //   : "",
                      "center",
                  }}
                >
                  <span
                    ref={articlestitletext}
                    class="field field--name-title field--type-string field--label-hidden"
                  >
                    {/* {articlesDetails?.data?.title} */}
                    {/* Article Testing Page Article Testing Page Article Testing
                    Page */}
                    {rutagDetailsData.data?.list?.title}
                  </span>
                </h1>
                <div
                  className="blog-author flex"
                  style={{
                    width:
                      // articlestitletext?.current?.innerHTML.length > 41
                      //   ? "85%"
                      //   : "",
                      "100%",
                  }}
                >
                  <div className="flex">
                    {/* {articlesDetails?.data?.author &&
                    articlesDetails?.data?.author.length != 0 ? (
                      <>
                        <span class="by">Developed by:</span>
                        {articlesDetails?.data?.author.length != 0 ? (
                          articlesDetails?.data?.author.map(
                            (authorname, index) => (
                              <div class="blog-author-inner">
                                <div class="author-inner">
                                  <a href="#">
                                    {`${index ? ", " : ""}${
                                      authorname?.name
                                    }` || "NA"}
                                  </a>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div class="blog-author-inner">
                            <div class="author-inner">
                              <a href="#">{"NA"}</a>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )} */}
                    <div className="row">
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                        }}
                      >
                        <span class="by">Developed by:</span>
                        <div class="blog-author-inner">
                          <div class="author-inner">
                            <a href="#">
                              {/* {`${index ? ", " : ""}${
                                      authorname?.name
                                    }` || "NA"} */}
                              {/* Lorem Ipsum */}
                              {rutagDetailsData.data?.list?.Developed_by}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                        }}
                      >
                        <span class="by">Sector Catered:</span>
                        <div class="blog-author-inner">
                          <div class="author-inner">
                            <a href="#">
                              {/* {`${index ? ", " : ""}${
                                      authorname?.name
                                    }` || "NA"} */}
                              {/* Lorem Ipsum */}
                              {rutagDetailsData.data?.list?.Sector_Catered}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div class="blog-author-inner">
                       <div class="author-inner">
                         <a href="#">
                           {props.location.state?.authorName || "NA"}
                         </a>
                       </div>
                     </div> */}
                  </div>
                </div>

                {/* <div className="blogpage-date-tag">
                   <time>
                     {articlesDetails?.data?.publish_date != null
                       ? articlesDetails?.data?.publish_date
                       : "NA"}
                   </time>
                 </div> */}

                {/* <OverView2
                   // MainPDF={kmDetails?.MainPDF}
                   banner_image={articlesDetails?.data?.banner_image}
                   // mainTitle={kmDetails?.Title}
                   // body={kmDetails?.DetailedDescription}
                   // category={kmDetails?.category}
                   // MainImage={kmDetails?.MainImage}
                   // GemLinks={kmDetails?.GemLink}
                   // suitable={kmDetails?.SpecialConditions}
                   // wasteSubTheme={kmDetails?.Subthemes}
                 /> */}
                {/* <div className="blog-author flex">
                   <div className="flex">
                     <span class="by">By:</span>
                     <div class="blog-author-inner">
                       <div class="author-inner">
                         <a href="#">
                           {props.location.state?.authorName || "NA"}
                         </a>
                       </div>
                     </div>
                   </div>
                 </div> */}

                <div class="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <p
                    class="text-align-justify articlesparajustified"
                    style={{ textAlign: "left" }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    {ReactHtmlParser(rutagDetailsData.data?.list?.description)}
                  </p>

                  <p
                    class="text-align-justify articlesparajustified"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      paddingBottom: "1%",
                      display:
                        rutagDetailsData.data?.list?.salient_feature === null &&
                        "none",
                    }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    {"Salient Features"}
                  </p>
                  <p
                    class="text-align-justify articlesparajustified"
                    style={{
                      textAlign: "left",
                      display:
                        rutagDetailsData.data?.list?.salient_feature === null &&
                        "none",
                    }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    {ReactHtmlParser(
                      rutagDetailsData.data?.list?.salient_feature
                    )}
                  </p>
                  <div
                    className="card-deck"
                    style={{
                      paddingBottom:
                        rutagDetailsData.data?.list?.image.length > 0 && "10%",
                    }}
                  >
                    {rutagDetailsData.data?.list?.image.map((item, index) => (
                      <div className="card newcardrutagdetails">
                        <img
                          className="card-img-top newcardrutagdetailsimg"
                          src={item?.image}
                          alt="Card image cap"
                        />
                        <p
                          class="text-align-justify articlesparajustified"
                          style={{
                            textAlign: "left",
                            fontStyle: "italic",
                            display: item?.image_note === "" && "none",
                          }}
                        >
                          {item?.image_note}
                        </p>
                      </div>
                    ))}
                  </div>

                  {rutagDetailsData.data?.list?.technology_details.map(
                    (item, index) => (
                      <>
                        <p
                          class="text-align-justify articlesparajustified"
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "30px",
                            paddingBottom: "1%",
                          }}
                        >
                          {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                          {item?.title}
                        </p>

                        <p
                          class="text-align-justify articlesparajustified"
                          style={{ textAlign: "left" }}
                        >
                          {ReactHtmlParser(item?.Description)}
                        </p>
                        {/* {item?.image.map((item2, index) => (
                          <div
                            style={{
                              paddingBottom: "30px",
                            }}
                          >
                            <img src={item2?.image} />
                          </div>
                        ))} */}

                        <div
                          className="card-deck "
                          style={{
                            paddingBottom: item?.image.length > 0 && "7%",
                          }}
                        >
                          {item?.image.map((item2, index) => (
                            <div className="card newcardrutagdetails">
                              <img
                                className="card-img-top newcardrutagdetailsimg"
                                src={item2?.image}
                                alt="Card image cap"
                              />
                              <p
                                class="text-align-justify articlesparajustified"
                                style={{
                                  textAlign: "left",
                                  fontStyle: "italic",
                                  display: item2?.image_note === "" && "none",
                                }}
                              >
                                {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                                {item2?.image_note}
                              </p>
                            </div>
                          ))}
                        </div>
                      </>
                    )
                  )}
                  {/* <p
                    class="text-align-justify articlesparajustified"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      paddingBottom: "1%",
                    }}
                  >
                    {"Major Drawbacks of Existing Vending Carts"}
                  </p> */}

                  {/* <p
                    class="text-align-justify articlesparajustified"
                    style={{ textAlign: "left" }}
                  >

                    <ul>
                      <li>
                        {
                          "Existing pushcarts used by street vendors/hawkers are exposed to sunlight, rain, heat, dust– leading to product degradation & spoilage."
                        }
                      </li>
                      <li>
                        {
                          "Storage, health, comfort, usability, muscular-skeletal disorders, lack of technology assistance, value added features and climatic issues etc."
                        }
                      </li>
                    </ul>
                  </p> */}

                  {/* <div
                    style={{
                      paddingBottom: "30px",
                    }}
                  >
                    <img src={plaseholderrutag} />
                  </div> */}

                  <p
                    class="text-align-justify articlesparajustified"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      paddingBottom: "1%",
                    }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    {rutagDetailsData.data?.list?.cost_of_key}
                  </p>

                  <p
                    class="text-align-justify articlesparajustified"
                    style={{
                      textAlign: "left",
                      // paddingBottom: "0px",
                      borderBottom: "solid 1px #2ba749",
                    }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    {ReactHtmlParser(
                      rutagDetailsData.data?.list?.cost_of_value
                    )}
                  </p>

                  <p
                    class="text-align-justify articlesparajustified"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      paddingBottom: "1%",
                      paddingTop: "30px",
                    }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    {"Contact Details"}
                  </p>

                  <p
                    class="text-align-justify articlesparajustified"
                    style={{ textAlign: "left", paddingBottom: "0px" }}
                  >
                    {/* RuTAG IIT Bombay */}
                    {rutagDetailsData?.data?.list?.contact_details?.name}
                  </p>
                  <p
                    class="text-align-justify articlesparajustified"
                    style={{ textAlign: "left", paddingBottom: "0px" }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    E-mail:{" "}
                    {rutagDetailsData?.data?.list?.contact_details?.emails.map(
                      (item, index) => (
                        <>
                          <a href={`mailto: ${item?.value}`}>{item?.value}</a>
                          <span
                            style={{
                              display:
                                index ===
                                  rutagDetailsData?.data?.list?.contact_details?.emails?.lastIndexOf(
                                    rutagDetailsData?.data?.list
                                      ?.contact_details?.emails[
                                      rutagDetailsData?.data?.list
                                        ?.contact_details?.emails?.length - 1
                                    ]
                                  ) && "none",
                            }}
                          >
                            {","}{" "}
                          </span>
                        </>
                      )
                    )}
                    {/* <a href="mailto: iitb.rutag@gmail.com">
                      iitb.rutag@gmail.com
                    </a>
                    ,{" "}
                    <a href="mailto: rupali.khanolkar@iitb.ac.in">
                      rupali.khanolkar@iitb.ac.in
                    </a> */}
                  </p>
                  <p
                    class="text-align-justify articlesparajustified"
                    style={{ textAlign: "left" }}
                  >
                    {/* {ReactHtmlParser(articlesDetails?.data?.body)} */}
                    Web:{" "}
                    {/* <a
                      href="http://www.ctara.iitb.ac.in/en/rutag"
                      target="_blank"
                    >
                      http://www.ctara.iitb.ac.in/en/rutag
                    </a> */}
                    {rutagDetailsData?.data?.list?.contact_details?.url.map(
                      (item, index) => (
                        <>
                          <a href={`${item?.uri}`} target="_blank">
                            {item?.title}
                          </a>
                          <span
                            style={{
                              display:
                                index ===
                                  rutagDetailsData?.data?.list?.contact_details?.url?.lastIndexOf(
                                    rutagDetailsData?.data?.list
                                      ?.contact_details?.url[
                                      rutagDetailsData?.data?.list
                                        ?.contact_details?.url?.length - 1
                                    ]
                                  ) && "none",
                            }}
                          >
                            {","}{" "}
                          </span>
                        </>
                      )
                    )}
                  </p>

                  {/* <p class="text-align-justify">
                     This has been co-authored by&nbsp;
                     <em>Mishika Nayyar and Bhamini Rathore.&nbsp;</em>
                   </p> */}
                </div>
              </div>
              <div
                className="rutagdetailsbackbtn"
                style={{
                  paddingTop: "20px",
                }}
              >
                <Link
                  className="green-btn mr20"
                  to={{
                    pathname: `/rutag`,
                    // state: {

                    //   pageLink: value?.pagelink,
                    // },
                  }}
                  style={{
                    fontSize: "18px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("redux states articles detail", state);
  return {
    // currentInterventionList: state.ArticlesReducer.currentInterventionList.data,
    // interventionTechnology: state.ArticlesReducer.interventionTechnology.data,
    // filterData: state.ArticlesReducer.filterData,
    // articlesDetails: state.ArticlesReducer.articlesDetails,
    rutagDetailsData: state.RutagReducer.rutagDetails,
  };
};

export default connect(mapStateToProps, {
  getRutagDetails,
})(withRouter(RutagDetailspage));
