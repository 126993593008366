import ReactHtmlParser from "react-html-parser";
import { useState } from "react";
import Slider from "react-slick";
// import "./slickslider.css";
import "./style2.scss";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import SSQuotes from "../../../../SwachhtaSarthiFellowship/dependencies/component/SSQuotes";
import SlickSlider from "./SlickSlider";

const Overview = (props) => {
  const { data } = props;
  let [expanded, setExpanded] = useState(false);
  const handleReadMore = () => {
    setExpanded(!expanded);
  };

  const settings = {
    // dots: true,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // adaptiveHeight: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    //   arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const mobsettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   adaptiveHeight: true,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   // arrows: false,
  // };

  return (
    <div className="blue-validated-bg bg-validated-overview">
      <div className="yellow-line" />
      <div className="blue-line" />
      <div className="green-line" />
      <div className="col-lg-12 view-image">
        <div className="slidercontainerdesk">
          <Slider {...settings}>
            {data &&
              Array.isArray(data?.image_slider) &&
              data?.image_slider.length > 0 &&
              data?.image_slider.map((value, index) => {
                return (
                  <img
                    src={value.image}
                    // src={
                    //   "https://uat.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911_0.png"
                    // }
                    className="validate-details-img"
                    style={{ width: "100%", height: 300, borderRadius: 10 }}
                  />
                );
              })}
          </Slider>
        </div>

        {/* <div className="slidercontainermobile">
          <Slider {...mobsettings} className="techSlider techmobile">
            {data &&
              Array.isArray(data?.image_slider) &&
              data?.image_slider.length > 0 &&
              data?.image_slider.map((value, index) => {
                return (
                  <img
                    src={value.image}
                    // src={
                    //   "https://uat.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911_0.png"
                    // }
                    className="validate-details-img"
                    style={{ width: "100%", height: 300, borderRadius: 10 }}
                  />
                );
              })}
          </Slider>
        </div>*/}
      </div>
      <div>
        <div className="green-leaf-validated-tech" />
        <div className="row-validated-tech-graphics">
          <div className="green-leaf " />
          <div className="large-tree " />
        </div>
      </div>
    </div>
  );
};

export default Overview;
