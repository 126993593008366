import { connect } from 'react-redux';
import ModalHOC from "../../../../common/Modal";
import Slider from "react-slick";
import "./style.scss";


const ImageModal = (props) => {
    const { data, className } = props;
    return (
        <div>
           <img src={data} />
        </div>
    )
}
export default connect(null, {})(ModalHOC(ImageModal));