import React from "react";
import ModalHOC from "../../../../common/Modal"
import ReactHtmlParser from "react-html-parser"
import "./style.scss"
import Scrollbar from "react-scrollbars-custom";

const DeployedTechnologyModal = props => {
    const { item, heading } = props;
    return (
        <div className="deployed-technology-Modal">
            <div className="deployed-technology-head">
                <h2>{ReactHtmlParser(heading)} </h2>
            </div>
            <div className="deployed-technology-subhead">
                <ul>
                    <li>Sentinel: <span>{item[0]?.sentinelData}</span></li>
                    <li>Technology Provider: <span>{item[0]?.technologyProvData}</span> </li>
                    <li>Status: <span>{item[0]?.statusData}</span></li>
                </ul>
            </div>
            <div className="deployed-technology-body">
                <h3>{item[0]?.advantagesHeading}</h3>
                <ul>
                    {
                        item && item[0] && item[0]?.advantagesData
                        && item[0]?.advantagesData.map((value, index) => {
                            return (
                                <li>{value?.advantage}</li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="deployed-technology-images">
                {
                    item && item[0]?.image1 &&
                    <div className="deployed-technology-img">
                        <img src={item[0]?.image1} />
                    </div>

                }
                {
                    item && item[0]?.image2 &&
                    <div className="deployed-technology-img">
                        <img src={item && item[0]?.image2} />
                    </div>
                }

            </div>
        </div>
    )
}

export default ModalHOC(DeployedTechnologyModal);
