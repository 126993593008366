import React, { useState, useRef, useEffect } from "react";

import ReactHtmlParser from "react-html-parser";
import SuccessModal from "../../../../common/SuccessModal";
import ReadMoreModal from "../../../../../components/ValidatedTechnologies/dependencies/component/ReadMoreModal";
import "./style.scss";
import Slider from "react-slick";

import imagefile from "../../../../../assests/svg/image_file.svg";
import pdffile from "../../../../../assests/svg/pdf_file.svg";
import pptfile from "../../../../../assests/svg/ppt_file.svg";
import documentFile from "../../../../../assests/svg/doc_file.svg";
import GallerySection from "../../../dependencies/component/GallerySection";
import VideoGallery from "../../../dependencies/component/VideoGallery";
const MoreInformation = (props) => {
  const { data } = props;

  const [heading, setHeading] = useState("");
  const [modaldata, setModalData] = useState([]);
  // const [newImage, setImage] = useState(data?.photographs?.files[0]?.url);
  const [newImage, setImage] = useState(
    "https://uat.wastetowealth.gov.in/s3fs/2021-07/Picture1.jpg"
  );
  const [newVideo, setVideo] = useState(
    "https://uat.wastetowealth.gov.in/s3fs/2021-07/Picture1.jpg"
  );

  useEffect(() => {
    console.log(data?.photographs?.files);
  }, []);

  const imageRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const newsettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleClose = () => {
    props.history.push("/fellowship");
    setShowModal(false);
  };
  const getFileExtension = (url) => {
    let filename = url;
    let extension = filename.split(".").pop();
    if (
      extension == "jpeg" ||
      extension == "png" ||
      extension == "gif" ||
      extension == "jpg" ||
      extension == "svg"
    ) {
      return <img src={imagefile} className="more-info-image" />;
    } else if (extension == "pdf") {
      return <img src={pdffile} className="more-info-pdf" />;
    } else if (extension == "pptx") {
      return <img src={pptfile} className="more-info-ppt" />;
    } else {
      return <img src={documentFile} className="more-info-image" />;
    }
  };
  const Moreinfo = [
    {
      heading: "Waste to Wealth Certificate",
      img: data?.wtwCertificate ? data?.wtwCertificate.icon : null,
      files: data?.wtwCertificate ? data?.wtwCertificate.files : null,
      // files: [],
    },
    {
      heading: "Technology Brief",
      img: data?.technologyBrief ? data?.technologyBrief.icon : null,
      files: data?.technologyBrief ? data?.technologyBrief.files : null,
      // files: [],
    },
    {
      heading: "Presentation",
      img: data?.presentation ? data?.presentation.icon : null,
      files: data?.presentation ? data?.presentation.files : null,
      // files: [],
    },
    {
      heading: "Test Reports",
      img: data?.testReports ? data?.testReports.icon : null,
      files: data?.testReports ? data?.testReports.files : null,
      // files: [],
    },
    {
      heading: "Experience Certificates",
      img: data?.experienceCertificates
        ? data?.experienceCertificates.icon
        : null,
      files: data?.experienceCertificates
        ? data?.experienceCertificates.files
        : null,
      // files: [],
    },
    {
      heading: "Photographs",
      img: data?.photographs ? data?.photographs.icon : null,
      files: data?.photographs ? data?.photographs.files : null,
      // files: [],
    },
    {
      heading: "Videos",
      img: data?.photographs ? data?.photographs.icon : null,
      files: data?.photographs ? data?.photographs.files : null,
      // files: [],
    },
  ];
  const GetFilesList = (datafield) => {
    return datafield.files.map((item, index) => (
      <div className="modal-body" key={index} style={{ padding: "0px" }}>
        <li className="list-group-item d-flex justify-content-between align-items-start list-group-item-action">
          <span className="badge rounded-pill" style={{ width: "20%" }}>
            <div className="Image-display">{getFileExtension(item?.url)}</div>
          </span>
          <div className="ms-2 me-auto" style={{ margin: "auto" }}>
            <a
              href={`https://uat.wastetowealth.gov.in${item?.url}`}
              target="_blank"
              style={{
                marginLeft:
                  item?.url.split(".").pop() == "pptx" ? "-47%" : "0px",
                wordBreak: "break-all",
              }}
              className="urlLink"
            >
              {item?.description ? item?.description : item?.file_name}
            </a>
          </div>
        </li>
      </div>
    ));
  };

  const videos = [
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-07/Picture1.jpg",
    },
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-10/faridabad.png",
    },
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-09/WhatsApp%20Image%202021-08-03%20at%2018.34.07.jpeg",
    },
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-10/Picture%20Sudhara_0.png",
    },
  ];

  const photographs = [
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-07/Picture1.jpg",
    },
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-10/faridabad.png",
    },
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-09/WhatsApp%20Image%202021-08-03%20at%2018.34.07.jpeg",
    },
    {
      img: "https://uat.wastetowealth.gov.in/s3fs/2021-10/Picture%20Sudhara_0.png",
    },
  ];

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div className="MoreInformation">
        <div className="yellow-line" />
        <div className="blue-line" />
        <div className="green-line" />

        <div className="container">
          <div className="large-tree" />
          <div className="small-tree" />

          <div class="bin-car"></div>
          <div class="white-leaf"></div>
          <h1 className="moreInfo-section-heading">
            {ReactHtmlParser("More Information")}
          </h1>

          <div
            className="moreInfomodal"
            style={{
              width:
                Moreinfo[0]?.files?.length == 0 ||
                Moreinfo[1]?.files?.length == 0 ||
                Moreinfo[2]?.files?.length == 0 ||
                Moreinfo[3]?.files?.length == 0 ||
                Moreinfo[4]?.files?.length == 0 ||
                Moreinfo[5]?.files?.length == 0 ||
                Moreinfo[6]?.files?.length == 0
                  ? "fit-content"
                  : "auto",
            }}
            // style={{ marginLeft: "7.5px" }}
          >
            <div
              // className="row phase2innerdiv14"
              // className="card-deck"
              className="abovemoreinfo"
            >
              {Moreinfo.map((item, index) => (
                <>
                  <div
                    className="card text-center moreInfomodalcard"
                    style={{
                      display: item?.files?.length > 0 ? "block" : "none",
                      width: "8em",

                      margin: "10px",
                    }}
                    // style={{ backgroundColor: "transparent" }}
                  >
                    <p
                      className="card-text"
                      style={{
                        paddingBottom:
                          item.heading === "Presentation"
                            ? "19px"
                            : item.heading === "Technology Brief" &&
                              Moreinfo[0]?.files?.length > 0 &&
                              Moreinfo[5]?.files?.length == 0 &&
                              Moreinfo[6]?.files?.length == 0
                            ? "19px"
                            : item.heading === "Test Reports"
                            ? "19px"
                            : item.heading === "Experience Certificates" &&
                              Moreinfo[0]?.files?.length > 0 &&
                              Moreinfo[5]?.files?.length == 0 &&
                              Moreinfo[6]?.files?.length == 0
                            ? "19px"
                            : item.heading === "Photographs"
                            ? "19px"
                            : item.heading === "Videos"
                            ? "19px"
                            : "0px",
                        fontWeight: "bold",
                        paddingLeft:
                          item.heading === "Technology Brief" ? "10%" : "0px",
                        paddingRight:
                          item.heading === "Technology Brief" ? "10%" : "0px",
                      }}
                    >
                      {item.heading}
                    </p>
                    <div
                      className="card-body text-center newCard"
                      data-toggle={"modal"}
                      data-target={"#exampleModalCenter"}
                      onClick={() => {
                        setHeading(item.heading);
                        if (item.heading === "Photographs") {
                          setModalData(null);
                        } else if (item.heading === "Videos") {
                          setModalData(null);
                        } else {
                          setModalData(item.data);
                        }
                      }}
                    >
                      <img
                        src={item.img}
                        // src={
                        //   "https://uat.wastetowealth.gov.in/s3fs/2022-03/Certificate.jpg"
                        // }
                        alt="..."
                        className="innerdiv14img"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div
              className="modal fade"
              id="exampleModalCenter"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              data-backdrop="false"
            >
              <div
                className={
                  heading === "Photographs"
                    ? "modal-dialog modal-dialog-centered modal-lg picvidmodal"
                    : heading === "Videos"
                    ? "modal-dialog modal-dialog-centered modal-lg picvidmodal"
                    : "modal-dialog modal-dialog-centered othermodal"
                }
                // style={{
                //   width:
                //     heading === "Photographs"
                //       ? "60%"
                //       : heading === "Videos"
                //       ? "60%"
                //       : "83%",
                // }}
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="exampleModalCenterTitle"
                      style={{
                        fontWeight: "bold",

                        fontSize: "30px",
                        fontFamily: ' "Chivo", sans-serif',
                      }}
                    >
                      {heading}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  {heading === "Photographs" ? (
                    <>
                      <div className="card-deck photoModal">
                        <div
                          className="ecommerce-gallery photoModalinner1"
                          data-mdb-zoom-effect="true"
                          data-mdb-auto-height="true"
                        >
                          <div className="row py-3 shadow-5 photoModalinner2">
                            <div className="col-12 mb-1">
                              <div className="lightbox">
                                <div>
                                  <GallerySection data={data?.photographs} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : heading === "Videos" ? (
                    <>
                      <div className="card-deck photoModal">
                        <div
                          className="ecommerce-gallery photoModalinner1"
                          data-mdb-zoom-effect="true"
                          data-mdb-auto-height="true"
                        >
                          <div className="row py-3 shadow-5 photoModalinner2">
                            <div className="col-12 mb-1">
                              <div className="lightbox">
                                <div>
                                  <VideoGallery data={data?.videos} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      // style={{
                      //   overflow: "auto",
                      //   height: "310px",
                      // }}
                      className="normalModal"
                    >
                      {heading === "Waste to Wealth Certificate"
                        ? GetFilesList(data?.wtwCertificate)
                        : heading === "Technology Brief"
                        ? GetFilesList(data?.technologyBrief)
                        : heading === "Presentation"
                        ? GetFilesList(data?.presentation)
                        : heading === "Test Reports"
                        ? GetFilesList(data?.testReports)
                        : heading === "Experience Certificates"
                        ? GetFilesList(data?.experienceCertificates)
                        : ""}
                    </div>
                  )}
                  {/* <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row moreInfo-type-graphics">
        <div class="recycle-small"></div>
        <div class="recycle two"></div>
      </div>
    </div>
  );
};

export default MoreInformation;
