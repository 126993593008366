import react, { useState, useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const staticImageData = [
  {
    image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture1.png",
  },
  {
    image: "https://uat.wastetowealth.gov.in/s3fs/2021-09/meit.png",
  },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/31.png",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture4.png",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture5.png",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture61.jpg",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture71.jpg",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture24_0.png",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture22.jpg",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture23.jpg",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture24.png",
  //   },
  //   {
  //     image: "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture27.png",
  //   },
];

const Partners = (props) => {
  const [imgRecognizedHeight, setImgRecognizedHeight] = useState();
  const [imgRecognizedWidth, setImgRecognizedWidth] = useState();
  const recognizedimg = useRef();

  const { data } = props;
  return (
    <div className="Partners">
      <div className="container">
        <div className="container-wrap">
          <div className="row partner-logo-heading-container">
            <div className=" col-lg-12">
              <div className="partner-heading">
                {ReactHtmlParser(data?.heading ?? "Endorsing Agencies")}
              </div>
              <div className="partner-sub-heading">
                {ReactHtmlParser(
                  data?.description ? data?.description : data?.subtitle
                )}
              </div>
            </div>
          </div>
          <div className="row partner-logo-group-container">
            {data &&
              Array.isArray(data?.partners) &&
              data?.partners.length > 0 &&
              data?.partners.map((value, index) => {
                return (
                  <div
                    className={`col-lg-3 partner-logo-container ${
                      (index + 1) % 4 === 0 ||
                      data?.partners?.length === index + 1
                        ? "no-border-need"
                        : ""
                    }`}
                    key={index}
                  >
                    <a href={value?.link} target="_blank">
                      <img
                        // ref={recognizedimg}
                        // onLoad={() => {
                        //   alert(recognizedimg.current.naturalWidth);
                        //   console.log(
                        //     "width",
                        //     recognizedimg.current.naturalWidth
                        //   );
                        //   setImgRecognizedWidth(
                        //     recognizedimg.current.naturalWidth
                        //   );
                        //   console.log(
                        //     "height",
                        //     recognizedimg.current.naturalHeight
                        //   );
                        //   setImgRecognizedHeight(
                        //     recognizedimg.current.naturalHeight
                        //   );
                        // }}
                        src={value?.image}
                        alt={value}
                        className="partner-logo"
                      />
                    </a>
                  </div>
                );
              })}
            {/* {staticImageData &&
              Array.isArray(data?.partners) &&
              staticImageData.length > 0 &&
              staticImageData.map((value, index) => {
                return (
                  <div
                    className={`col-lg-3 partner-logo-container ${
                      (index + 1) % 4 === 0 ||
                      staticImageData.length === index + 1
                        ? "no-border-need"
                        : ""
                    }`}
                    key={index}
                  >
                    <a href={"#"} target="_blank">
                      <img
                        ref={recognizedimg}
                        onLoad={() => {
                          console.log(
                            "width",
                            recognizedimg.current.naturalWidth
                          );
                          setImgRecognizedWidth(
                            recognizedimg.current.naturalWidth
                          );
                          console.log(
                            "height",
                            recognizedimg.current.naturalHeight
                          );
                          setImgRecognizedHeight(
                            recognizedimg.current.naturalHeight
                          );
                        }}
                        src={value?.image}
                        alt={""}
                        className="partner-logo"
                        style={{
                          maxWidth:
                            imgRecognizedWidth > imgRecognizedHeight
                              ? "160px"
                              : imgRecognizedWidth < imgRecognizedHeight
                              ? "160px"
                              : "142px",
                          maxHeight:
                            imgRecognizedHeight > imgRecognizedWidth
                              ? "100%"
                              : imgRecognizedHeight == imgRecognizedWidth
                              ? "100%"
                              : "auto",
                          marginTop:
                            imgRecognizedWidth > imgRecognizedHeight
                              ? "3%"
                              : "0%",
                        }}
                      />
                    </a>
                  </div>
                );
              })} */}
            {/* <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture1.png"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={"https://uat.wastetowealth.gov.in/s3fs/2021-09/meit.png"}
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={"https://uat.wastetowealth.gov.in/s3fs/2021-08/31.png"}
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture4.png"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture5.png"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture61.jpg"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture71.jpg"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture24_0.png"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture22.jpg"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture23.jpg"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture24.png"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div>
            <div
              className={`col-lg-3 partner-logo-container 
                   
                    `}
              // key={index}
            >
              <a href={"#"} target="_blank">
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-08/Picture27.png"
                  }
                  alt={""}
                  className="partner-logo"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
