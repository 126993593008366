import api from '../../../dependencies/utils/Api';
import {
    FELLOW_SHIP_URL,
    SUBMIT_CATEGORY_FELLOwSHIP,
    BANK_UPLOAD_URL,
    ENGAGEMENT_UPLOAD_URL
} from "../../../constants/ApplicationUrl";

export const FellowShipService = {
    getDropDownData(params) {
        return api.GET(FELLOW_SHIP_URL)
            .then((response) => {

                const { data = { data } = {} } = response.data || {};
                return { data };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    uploadFile(params, url) {
        return api.POSTDATA(url, params)
            .then((response) => {
                const { data, status } = response || {};
                if (status) {
                    return { data, status };
                } else {
                    return Promise.reject();
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    uploadFileBank(params) {
        
        return api.POSTDATA(BANK_UPLOAD_URL, params)
            .then((response) => {
                const { data, status } = response || {};
                if (status) {
                    return { data, status };
                } else {
                    return Promise.reject();
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    uploadFileEngage(params) {
        return api.POSTDATA(ENGAGEMENT_UPLOAD_URL, params)
            .then((response) => {
                const { data, status } = response || {};
                if (status) {
                    return { data, status };
                } else {
                    return Promise.reject();
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    submitCatagory(params) {
        return api.POST(SUBMIT_CATEGORY_FELLOwSHIP, params)
            .then((response) => {
                const { data, status } = response || {};
                return { data, status };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    // contactUsData() {
    //     return api.GET(CONTACT_US_DATA)
    //         .then((response) => {

    //             const { data = { data } = {} } = response.data || {};
    //             return { data };
    //         }).catch(e => {
    //             return Promise.reject(e);
    //         });
    // }
}
