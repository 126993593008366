import { combineReducers } from 'redux';
import {
    KM_LIST,
    KM_TECNOLOGY,
    KM_DETAILS,
    KM_FILTER_DATA,
    VALIDATION_LINK
} from '../../../constants/actionConstants';

const kmListing = (state = {}, action) => {
    switch (action.type) {
        case KM_LIST:
            return { ...action.payload };
        default:
            return state;
    }
}

const kmTechnology = (state = {}, action) => {
    switch (action.type) {
        case KM_TECNOLOGY:
            return { ...action.payload };
        default:
            return state;
    }
}
const kmDetails = (state = {}, action) => {
    switch (action.type) {
        case KM_DETAILS:
            return { ...action.payload };
        default:
            return state;
    }
}

const filterData = (state = {}, action) => {
    switch (action.type) {
        case KM_FILTER_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}



const validationLink = (state = {}, action) => {
    switch (action.type) {
        case VALIDATION_LINK:
            return { ...action.payload };
        default:
            return state;
    }
}
const KnowledgeManagmentReducer = combineReducers({
    kmListing,
    kmTechnology,
    kmDetails,
    filterData,
    validationLink
});

export default KnowledgeManagmentReducer;


