import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import { getHomePageData } from "./dependencies/action";
import Banner from "./dependencies/component/Banner";
import Quotes from "./dependencies/component/Quotes";
import FeaturedTechnology from "./dependencies/component/FeaturedTechnology";
import CurrentIntervention from "./dependencies/component/CurrentIntervention";
import ImportantChallenges from "./dependencies/component/ImportantChallenges";
import NewsEvent from "./dependencies/component/NewsEvent";
import "./style.scss";
import { initGA, logPageView } from "../../dependencies/utils/analytics";
import FloatingAction from "../../FloatingAction";

const HomePage = (props) => {
  const {
    getHomePageData,
    currentInterventionList,
    quotesList,
    featuredTechnologyList,
    importantChallengesList,
    latestNewsList,
    upcommingEventsList,
    bannerList,
  } = props;
  useEffect(() => {
    getHomePageData();
  }, [getHomePageData]);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);

  const meta = {
    title: "Waste to Wealth",
    description:
      "The Waste to Wealth Mission brings scientific processing of waste to the forefront to build a zero landfill and zero waste nation. The portal shall act as a common platform for technology providers, government stakeholders, urban local bodies, and users to seek relevant solutions.",
    canonical: "https://www.wastetowealth.gov.in/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Waste to Wealth,Waste,Wealth,waste,wealth,Waste to Wealth Mission,Swachh Bharat Mission,W2W Missions",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="home-content clearfix">
        <Banner data={bannerList} />
        <div className="d-flex ci_card-body">
          <marquee className="marquee-label">
            {" "}
            Actively looking for technologies addressing plastic waste
            management in hilly terrain. To see more details{" "}
            <a href="https://www.wastetowealth.gov.in/submit-technology">
              click here
            </a>
          </marquee>
        </div>
        <Quotes data={quotesList} />
        {/* <FeaturedTechnology data={featuredTechnologyList} /> */}
        <CurrentIntervention data={currentInterventionList} />
        {/* <ImportantChallenges data={importantChallengesList} /> */}
        <div className="policy-message">
          <div className="container">
            <p>
              To know more about government policies in the waste management
              domain, <Link to={"/policies"}>click here </Link>
            </p>
          </div>
        </div>
        <div className="news-events">
          <NewsEvent
            latestNews={latestNewsList}
            upcommingEvents={upcommingEventsList}
          />
        </div>
        <FloatingAction />
      </div>
    </DocumentMeta>
  );
};

const mapStateToProps = (state) => {
  return {
    currentInterventionList: state.HomeReducer.currentInerventionList,
    quotesList: state.HomeReducer.quotesList,
    featuredTechnologyList: state.HomeReducer.featuredTechnologyList,
    importantChallengesList: state.HomeReducer.importantChallengesList,
    bannerList: state.HomeReducer.bannerList,
    latestNewsList: state.HomeReducer.latestNewsList,
    upcommingEventsList: state.HomeReducer.upcommingEventsList,
  };
};
export default connect(mapStateToProps, { getHomePageData })(
  withRouter(HomePage)
);
