import React from "react";
import ModalHOC from "../../../../common/Modal"
import ReactHtmlParser from "react-html-parser"
import "./style.scss"
import Scrollbar from "react-scrollbars-custom";

const IECModal = props => {
    const { item } = props;
  
    return (
        <div className="IECModal">

            {item && item.Title &&
                <h3>{item.Title}</h3>}
            {item && item.Body &&
                <div className="desc">{ReactHtmlParser(item.Body)}</div>}
            {item && item.CitizenIECMaterialSteps &&
                <ul className="steps-wrap">
                    {
                        item?.CitizenIECMaterialSteps.map((item, index) => {
                            return (
                                <li>

                                    {
                                        item.Title && item.Image &&
                                        <div className="img-thumb-wrap">
                                            <span className="step-count">{item.Title}</span>
                                            <img className="thumb-img" src={item.Image} alt="" />
                                        </div>
                                    }
                                    {
                                        item.Title && !item.Image &&
                                        <span className="step-count step-only">{item.Title}</span>
                                    }

                                    <div className="step-content">
                                        {ReactHtmlParser(item.Body)}
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>}

        </div>
    )
}

export default ModalHOC(IECModal);
