import React from "react";
import "./style.scss";
import ReactHtmlParser from "react-html-parser";

const AnnualOandM = (props) => {
  const { data } = props;

  const OandMData = [
    {
      id: "Smallest Unit",
      client: "Lorem Ipsum dolor",
      location: "Mumbai, Maharashtra",
      capacity: "Lorem Ipsum dolor",
    },
    {
      id: "Medium Unit",
      client: "Lorem Ipsum dolor",
      location: "Tokyo, Japan",
      capacity: "Lorem Ipsum dolor",
    },
    {
      id: "Largest Unit",
      client: "Lorem Ipsum dolor",
      location: "Osaka, Japan",
      capacity: "Lorem Ipsum dolor",
    },
  ];

  const costData = [
    {
      newdata: [
        {
          id: "Smallest Unit",
          capex: "00 lakhs",
          opex: "00 lakhs",
        },

        {
          id: "Medium Unit",
          capex: "00 lakhs",
          opex: "00 lakhs",
        },
        {
          id: "Largest Unit",
          capex: "00 lakhs",
          opex: "00 lakhs",
        },
      ],
      textPointers: [
        {
          id: 1,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 2,
          desc: "Ut enim ad minim veniam",
        },
        {
          id: 3,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
        {
          id: 4,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 5,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
        {
          id: 6,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 7,
          desc: "Ut enim ad minim veniam",
        },
        {
          id: 8,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
        {
          id: 9,
          desc: "Lorem ips dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut",
        },
        {
          id: 10,
          desc: "nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
        },
      ],
    },
  ];
  const GetOMCostData = (headiing, value) => {
    return (
      <div className="phase2col-sm-12 col-md-12 col-lg-12 innerdiv12col">
        <p className="oandMHeading">{headiing}</p>
        <div
          className="well innerdiv12carddiv"
          style={{
            paddingBottom: "0px",
            border: "0px",
          }}
        >
          <div className="row capexdivrow">
            <div
              class="col-sm-12 col-md-4 col-lg-4 innerdiv7rowcol"
              style={{
                padding: "5px",
              }}
            >
              <div className="btn green-btn innercapexcard">
                {" "}
                <span className="unit-capacity">
                  <strong>Unit Capacity:</strong>
                  {value.total_capex}
                </span>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-4 col-lg-4 innerdiv7rowcol"
              style={{
                padding: "5px",
              }}
            >
              <div className="btn green-btn innercapexcard">
                {" "}
                <span className="unit-capacity">
                  <strong>Power Cost :</strong>
                  {value.power_cost}
                </span>
              </div>
            </div>

            <div
              class="col-sm-12 col-md-4 col-lg-4 innerdiv7rowcol"
              style={{
                padding: "5px",
              }}
            >
              <div className="btn green-btn innercapexcard">
                {" "}
                <span className="unit-capacity">
                  <strong>Consumables Cost :</strong>
                  {value.consumables_cost}
                </span>
              </div>
            </div>

            <div
              class="col-sm-12 col-md-4 col-lg-4 innerdiv7rowcol"
              style={{
                padding: "5px",
              }}
            >
              <div className="btn green-btn innercapexcard">
                {" "}
                <span className="unit-capacity">
                  <strong>Manpower Cost :</strong>
                  {value.manpower_cost}
                </span>
              </div>
            </div>

            <div
              class="col-sm-12 col-md-4 col-lg-4 innerdiv7rowcol"
              style={{
                padding: "5px",
              }}
            >
              <div className="btn green-btn innercapexcard">
                {" "}
                <span className="unit-capacity">
                  <strong>Maintainance Cost :</strong>
                  {value.maintenance_cost}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {data?.annualOMCost && (
            <div className="col-12">
              <div className="validatedTechnologies-heading contact-heading">
                Annual O&M Cost (INR Lakhs)
              </div>
            </div>
          )}
          <div className="row phase2innerdiv7new">
            {data?.annualOMCost &&
              data?.annualOMCost.smallest != null &&
              GetOMCostData("Smallest Unit", data?.annualOMCost.smallest)}
            {data?.annualOMCost &&
              data?.annualOMCost.medium != null &&
              GetOMCostData("Medium Unit", data?.annualOMCost.medium)}
            {data?.annualOMCost &&
              data?.annualOMCost.largest != null &&
              GetOMCostData("Largest Unit", data?.annualOMCost.largest)}
          </div>
          {data?.annualOMCost?.annual_o_m_desc && (
            <div
              className="phase2col-sm-12 col-md-12 col-lg-12 innerdiv12col oandmtextbox"
              // style={{
              //   marginTop: "-6%",
              //   marginLeft:"11px",
              //   zIndex: -1,
              // }}
            >
              <div className="card innerdiv12colcard">
                <div
                  className="divcenter2 oandmmobilediv"
                  style={{
                    marginTop:
                      data?.capex.smallest != null ||
                      data?.capex.medium != null ||
                      data?.capex.largest != null
                        ? "0px"
                        : "40px",
                  }}
                >
                  <div
                    className="well innerdiv12carddiv"
                    style={{
                      paddingTop:
                        data?.annualOMCost.smallest != null ||
                        data?.annualOMCost.medium != null ||
                        data?.annualOMCost.largest != null
                          ? "4%"
                          : "10px",
                    }}
                  >
                    <p className="content-sub-content">
                      {ReactHtmlParser(data?.annualOMCost?.annual_o_m_desc)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

// {data?.annualOMCost?.annual_o_m_desc &&
//   <div
//     className="phase2col-sm-12 col-md-12 col-lg-12 innerdiv12col"
//     style={{
//       marginTop: "-6%",
//       zIndex: -1,
//     }}
//   >
//     <div className="card innerdiv12colcard">
//       <div className="divcenter2">
//         <div
//           className="well innerdiv12carddiv"
//           style={{ paddingTop: "4%" }}>
//               <p className="content-sub-content">{ ReactHtmlParser(data?.annualOMCost?.annual_o_m_desc)}</p>
//         </div>
//       </div>
//     </div>
//   </div>}
// </div>
export default AnnualOandM;
