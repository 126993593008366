import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const ViewPartner = (props) => {
  const { data } = props;
  console.log(data);
  return (
    <div className="Partners">
      <div className="container">
        <div className="container-wrap">
          <div className="row partner-logo-heading-container">
            <div className=" col-lg-12">
              <div className="partner-heading pastprojPartners">
                {ReactHtmlParser(data?.heading ?? "Endorsing Agencies")}
                {/* {"Our Partners"} */}
              </div>
              <div className="partner-sub-heading">
                {ReactHtmlParser(
                  data?.description ? data?.description : data?.subtitle
                )}
              </div>
            </div>
          </div>
          <div className="row partner-logo-group-container">
            {data &&
              Array.isArray(data.list) &&
              data.list.length > 0 &&
              data.list.map((value, index) => {
                return (
                  <div
                    className={`col-lg-3 partner-logo-container ${
                      (index + 1) % 4 === 0 || data?.list?.length === index + 1
                        ? "no-border-need"
                        : ""
                    }`}
                    key={index}
                  >
                    <a href={value} target="_blank">
                      <img src={value} alt={value} className="partner-logo" />
                    </a>
                  </div>
                );
              })}
            {/* <div className={`col-lg-3 partner-logo-container `}>
              <a
                href={"https://uat.wastetowealth.gov.in/s3fs/2021-09/desmi.png"}
                target="_blank"
              >
                <img
                  src={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-09/desmi.png"
                  }
                  alt={
                    "https://uat.wastetowealth.gov.in/s3fs/2021-09/desmi.png"
                  }
                  className="partner-logo"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPartner;
