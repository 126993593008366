import { combineReducers } from 'redux';
import {
    EVENT_LISTING,
    FEATURED_EVENT,
    EVENT_DETAILS,
    EVENT_DETAILS2,
    UPCOMMING_EVENTS,
} from '../../../constants/actionConstants';

const eventList = (state = {}, action) => {
    switch (action.type) {
        case EVENT_LISTING:
            return { ...action.payload };
        default:
            return state;
    }
}

const featuredTechnology = (state = {}, action) => {
    switch (action.type) {
        case FEATURED_EVENT:
            return { ...action.payload };
        default:
            return state;
    }
}

const eventDetails = (state = {}, action) => {
    switch (action.type) {
        case EVENT_DETAILS:
            return { ...action.payload };
        default:
            return state;
    }
}

const eventDetails2 = (state = {}, action) => {
    switch (action.type) {
        case EVENT_DETAILS2:
            return { ...action.payload };
        default:
            return state;
    }
}

const upcommingEvents = (state = {}, action) => {
    switch (action.type) {
        case UPCOMMING_EVENTS:
            return { ...action.payload };
        default:
            return state;
    }
}


const EventReducer = combineReducers({
    eventList,
    featuredTechnology,
    eventDetails,
    upcommingEvents,
    eventDetails2
});

export default EventReducer;


