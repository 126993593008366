import { combineReducers } from 'redux';
import {
    SUCCESS_STORIES_LIST,
    SUCCESS_STORIES_TECNOLOGY,
    SUCCESS_STORIES_DETAILS,
    SUCCESS_STORIES_FILTER_DATA
} from '../../../constants/actionConstants';

const successStoryList = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_STORIES_LIST:
            return { ...action.payload };
        default:
            return state;
    }
}

const successTechnology = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_STORIES_TECNOLOGY:
            return { ...action.payload };
        default:
            return state;
    }
}
const successStoriesDetails = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_STORIES_DETAILS:
            return { ...action.payload };
        default:
            return state;
    }
}

const filterData = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_STORIES_FILTER_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}

const SuccessStoriesReducer = combineReducers({
    successStoryList,
    successTechnology,
    successStoriesDetails,
    filterData
});

export default SuccessStoriesReducer;


