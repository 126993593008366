import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Checkbox = (props) => {
    const {
        id,
        containerClass, value,
        error, onChange, type, label, required, name,
         placeholder, checked,
        disabled, onBlur, getRef = "", className, labelClass, ...rest
    } = props;

   const handleCheckClick = () => {
    let checkbox = document.getElementById(id);
    checkbox.checked = !checkbox.checked;
   }

    return (
        <div
            className={`inner-wrap checkbox-btn clearfix ${containerClass || ''}`}
            onClick={() => handleCheckClick}

        >

            <input
                id={id}
                ref={getRef}
                name={name}
                disabled={disabled}
                type='checkbox'
                value={value}
                className={`input-element ${className}`}
                onChange={e => onChange(e, name)}
                placeholder={placeholder}
                checked={checked}
                {...rest}
            />
            <i className="custom-checkbox"></i>
            {
                label &&
                <label
                    htmlFor={id}
                    className={labelClass}
                >
                    {label}

                </label>
            }
        </div>
    );

}


Checkbox.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    getRef: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    className: PropTypes.string,
    labelClass: PropTypes.string,
    containerClass: PropTypes.string
};

Checkbox.defaultProps = {
    disabled: false,
    id: '',
    value: '',
    error: '',
    onChange: () => { },
    type: '',
    label: '',
    required: false,
    name: '',
    mask: '',
    placeholder: '',
    containerClass: '',
    onBlur: () => { },
    getRef: () => { },
    className: '',
    labelClass: '',
};

export default Checkbox;