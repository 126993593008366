import react, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

const sourceData = [
  {
    data: "http://www.google.com",
    // data: "Lorem ips dolor sit amet, consectetur adipiscing elit, sed do eiusmodtempor incididunt ut labore et dolore magna aliqua. Ut enim ad minimveniam, quis nostrud exercitation ullamco laboris nisi ut aliquip exea commodo consequat. Duis aute irure dolor in reprehenderit involuptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteursint occaecat cupidatat non proident, sunt in culpa qui officiadeserunt mollit anim id est laborum.",
  },
];

// import "./style.scss";
const Source = (props) => {
  const { data, content, heading } = props;

  const { sourcetext, setSourcetext } = useState("");
  return (
    <div className="content-detail">
      <div className="container">
        <h1 className="suitable-section-heading">{ReactHtmlParser(heading)}</h1>
        {content &&
        content !== null &&
        content !== "undefined" &&
        (content.slice(0, 4) === "http" || content.slice(0, 4) === "www.") ? (
          <p className="content-sub-content">
            <a href={content} target="_blank">
              {content}
            </a>
          </p>
        ) : (
          <p className="content-sub-content">{content}</p>
        )}
      </div>
    </div>
  );
};

export default Source;
