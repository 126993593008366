import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Breadcrumb from "../../common/Breadcrumbs";
import { getSuccessStoriesDetails } from "../dependencies/action";
import { useEffect, useState } from "react";
import GallerySection from "../dependencies/component/GallerySection";
import CitizenEngagements from "../dependencies/component/CitizenEngagements";
import TechnologyIntervention from "../dependencies/component/TechnologyIntervention";
import Partners from "../../StaticPage/dependencies/component/Partners";

import dummyImg from "../../../assests/images/dummy_img.png";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";

import "./style.scss";

const items = [
  { to: "/", label: "/" },
  { to: "/success-stories", label: "What We Do" },
  { to: "/success-stories", label: "Success Stories" },
];
const SuccessStoriesDetails = (props) => {
  const { getSuccessStoriesDetails, successStoriesDetails } = props;
  let successStoriesData = null;
  useEffect(() => {
    let str = window.location.pathname;
    getSuccessStoriesDetails(str.split("/")[2]);
  }, [successStoriesData]);

  useEffect(() => {
    let videoData = successStoriesData?.gallery;

    localStorage.setItem("videoData", JSON.stringify(videoData));
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  if (successStoriesDetails) successStoriesData = successStoriesDetails[0];

  return (
    <div className="success-stories">
      <Breadcrumb
        data={items}
        displayName={successStoriesData?.mainTitle}
        type={"successStoriesDetails"}
      />

      {successStoriesData && (
        <div className="project-info">
          <div class="yellow-line" style={{ marginTop: "-5%" }}></div>
          <div class="blue-line" style={{ marginTop: "-5%" }}></div>
          <div class="green-line" style={{ marginTop: "-5%" }}></div>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div class="dotted-sq"></div>
                <div className="image">
                  <img
                    src={successStoriesData?.overView?.sliderImage[0].image}
                  />
                </div>
              </div>
              <div className="col-md-5 info">
                <div class="green-bird"></div>
                <div class="white-leaf"></div>
                <h2>Overview</h2>
                <ul>
                  <li>
                    {" "}
                    <span className="start-date">Start Date</span>:{" "}
                    {successStoriesData.overView.startDate}
                  </li>
                  <li>
                    {" "}
                    <span className="end-date">End Date</span>:{" "}
                    {successStoriesData.overView.endDate}
                  </li>
                  <li>
                    {" "}
                    <span className="location">Location</span>:{" "}
                    {successStoriesData.overView.location}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3>Project Background</h3>
                {ReactHtmlParser(successStoriesData?.body)}
                <div className="tag">
                  {successStoriesData?.category &&
                    successStoriesData?.category.length > 0 &&
                    successStoriesData?.category.map((category) => (
                      <span className="content-tag">{category?.title}</span>
                    ))}
                </div>
              </div>
            </div>
            <div class="dustbin"></div>
            <div class="large-tree"></div>
            <div class="small-tree"></div>
          </div>
        </div>
      )}

      <div className="project-impact">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Project Impact</h2>
              {ReactHtmlParser(successStoriesData?.project_impact.left)}
              {ReactHtmlParser(successStoriesData?.project_impact.right)}
            </div>
          </div>

          <div class="bin-car"></div>
        </div>
      </div>

      {successStoriesData &&
        successStoriesData?.technologies_interventions &&
        successStoriesData?.technologies_interventions?.list &&
        successStoriesData?.technologies_interventions?.list.length > 0 && (
          <TechnologyIntervention
            data={successStoriesData?.technologies_interventions}
          />
        )}
      {(successStoriesData?.gallery?.list?.video ||
        successStoriesData?.gallery?.list?.video) && (
        <div className="gallery-wraper">
          <div class="yellow-line"></div>
          <div class="blue-line"></div>
          <div class="green-line"></div>
          <div class="large-tree"></div>
          <div class="small-tree"></div>
          <div class="bulb"></div>
          <div class="dotted-hexagon left"></div>
          <div class="dotted-hexagon right"></div>
          <GallerySection data={successStoriesData?.gallery} />
        </div>
      )}

      {successStoriesData?.ourPartners && (
        <div className="partner-container">
          <div class="yellow-line"></div>
          <div class="blue-line"></div>
          <div class="green-line"></div>
          <div class="girl"></div>
          <div class="recycle two"></div>
          <Partners data={successStoriesData?.ourPartners} />
        </div>
      )}

      {successStoriesData?.mainTitle === "Swachhta Saarthi Fellowship"
        ? ""
        : successStoriesData?.similar_interventions &&
          successStoriesData?.similar_interventions?.list?.length > 0 && (
            <CitizenEngagements
              data={successStoriesData?.similar_interventions}
            />
          )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    successStoriesDetails:
      state.SuccessStoriesReducer.successStoriesDetails.data,
  };
};
export default connect(mapStateToProps, { getSuccessStoriesDetails })(
  withRouter(SuccessStoriesDetails)
);
