import api from '../../../dependencies/utils/Api';
import {
    SUBMIT_TECHNOLOGY_SUBMIT,
    SUBMIT_TECH_INFO,
    TECH_IMG_UPLOAD_URL
} from "../../../constants/ApplicationUrl";
import axios from 'axios';
export const httpClient = axios.create();
httpClient.defaults.timeout = 5 * 60 * 1000;

export const submitTechService = {
    uploadFileTech(params) {
// var axios = require('axios');
// var data = file;

// var config = {
//   method: 'post',
//   url: TECH_IMG_UPLOAD_URL,
//   headers: { 
//     'Content-Type': 'application/octet-stream', 
//     'Content-Disposition': 'file; filename="'+file.name+'"'
//   },
//   data : data
// };

// axios(config)
// .then(function (response) {
  
//                 const { data, status } = response || {};
//                 if (status=== 200 ||status === 201) {
//                     console.log(status,"if")
//                     return { data, status };
//                 } else {
//                     console.log(status,"else")
//                     return Promise.reject();
//                 }
// }).catch(e => {
//     return Promise.reject(e);
// });
    
        return api.POSTDATA(TECH_IMG_UPLOAD_URL, params)
            .then((response) => {
                const { data, status } = response || {};
                if (status) {
                    return { data, status };
                } else {
                    return Promise.reject();
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    submitTechSubmit(params) {
        return api.POST(SUBMIT_TECHNOLOGY_SUBMIT, params)
            .then((response) => {
                const { data, status } = response || {};
                return { data, status };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    submitTechInfo() {
        return api.GET(SUBMIT_TECH_INFO)
            .then((response) => {
               
                const { data = { data } = {} } = response.data || {};
                return { data };
            }).catch(e => {
                return Promise.reject(e);
            });
    }
}
