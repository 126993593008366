import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import './style.scss';
const SuccessModal = (props)=>{

    return(
            <div className="col-12">
                <Modal className="success-modal" show={props.show} onHide={() => props.closeModal}
                       size = "md"
                       centered>
                    <Modal.Body>
                       <span className="title">Success!</span>
                       <p>Your application for fellowship “<strong>{props.title}</strong>” submitted successfully</p>
                        <i className="success-tick"></i>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="green-btn" onClick={() => props.closeModal()}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>
        </div>)
}

export default SuccessModal;