import ReactHtmlParser from "react-html-parser";
// import "./style.scss";
const ContactDetails = (props) => {
  const { data, content, heading } = props;
  return (
    <div className="bg-about-validated-tech custom-slider-nextPrev">
      <div className="yellow-line" />
      <div className="blue-line" />
      <div className="green-line" />
      <div
        className="container"
        // className="validated-tech-container"
        //   ref={contactdiv}
      >
        <div className="row">
          <div className="col-12">
            <div className="validatedTechnologies-heading contact-heading">
              Contact Details
            </div>
          </div>
          <div className="row col-12">
            <div className="col-lg-4 col-sm-12">
              <label className="contact-detail-heading">Email </label>
              <div className="box_Border">
                <span>{props.data?.contact_detail?.email}</span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <label className="contact-detail-heading">Phone Number </label>
              <div className="box_Border">
                <span>{props.data?.contact_detail?.phone_no}</span>
              </div>
            </div>
            {props.data?.contact_detail?.website && (
              <div className="col-lg-4 col-sm-12">
                <label className="contact-detail-heading">Website </label>
                <div className="box_Border">
                  <span>{props.data?.contact_detail?.website}</span>
                </div>
              </div>
            )}
          </div>

          <div className="col-12">
            <label className="contact-detail-heading">Address </label>
            <div className="box_Border_address">
              <span>
                {ReactHtmlParser(props.data?.contact_detail?.address)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-validated-tech-graphics">
        <div class="recycle-small"></div>
        <div class="recycle two"></div>
      </div>
    </div>
  );
};

export default ContactDetails;
//   <div className="validated-tech-container" ref={contactdiv}>
//   <div className="row">
//     <div className="col-12">
//       <div className="validatedTechnologies-heading contact-heading">
//         Contact Details
//       </div>
//     </div>
//     <div className="row col-12">
//       <div className="col-4">
//         <label className="contact-detail-heading">Email </label>
//         <div className="box_Border">
//           <span>{kmDetails?.contact_detail?.email}</span>
//         </div>
//       </div>
//       <div className="col-4">
//         <label className="contact-detail-heading">Phone Number </label>
//         <div className="box_Border">
//           <span>{kmDetails?.contact_detail?.phone_no}</span>
//         </div>
//       </div>
//       {kmDetails?.contact_detail?.website && (
//         <div className="col-4">
//           <label className="contact-detail-heading">Website </label>
//           <div className="box_Border">
//             <span>{kmDetails?.contact_detail?.website}</span>
//           </div>
//         </div>
//       )}
//     </div>

//     <div className="col-12">
//       <label className="contact-detail-heading">Address </label>
//       <div className="box_Border_address">
//         <span>
//           {ReactHtmlParser(kmDetails?.contact_detail?.address)}
//         </span>
//       </div>
//     </div>
//   </div>
// </div>
