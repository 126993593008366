import { PartnerWithUsService } from './service';
import {
    PARTNER_WITH_US_DETAILS

} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';


export const getPartnerWithUs = (param) => async (dispatch) =>{
    
    dispatch(isLoading(true)) 
    let getPartnerWithUs = await PartnerWithUsService.getpartnerWithUsDetails();

    dispatch({
        type: PARTNER_WITH_US_DETAILS,
        payload: getPartnerWithUs
    })
    dispatch(isLoading(false))

}
