import React, { useState, useLayoutEffect, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import './style.scss';
import dummyMissionImage from '../../../../../assests/images/dummy_img.png';
import Slider from "react-slick";
import SocialShare from "../../../../common/SocialShare";
import { Parallax } from 'react-scroll-parallax';
import { useController } from 'react-scroll-parallax';


const CitizenCornerSubpage = (props) => {
    const { parallaxController } = useController();
    useLayoutEffect(() => {
        const handler = () => parallaxController.update();
        window.addEventListener('load', handler);
        return () => window.removeEventListener('load', handler);
    }, [parallaxController]);
    useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
    }, [])
    // const handleScroll = (event) => {
    //     console.log("event", event)
    // }
    const { item, count, data, SectionId } = props;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const [social] = useState({
        url: `${window.location.protocol}//${window.location.host}/citizen-corner/${SectionId}`,
        title: data?.heading,
        sepreater: "::",
        size: "2.5rem",
    });

    return (
        <div className="green-background">
            <div className='container'>
                <Parallax className="dotted-sq" y={['-20px', '20px']} tagOuter="div">
                    <div className="dotted-sq-img" />
                </Parallax>
                <Parallax className="green-leaf" y={['-40px', '40px']} tagOuter="div" >
                    <div className="green-leaf-img" />
                </Parallax>
                <Parallax className="white-leaf" x={['30px', '-40px']} tagOuter="div" >
                    <div className="white-leaf-img" />
                </Parallax>
                <div className="d-flex citizen-social-share">
                    <div className="margin-left-auto">
                        <SocialShare social={social} />
                    </div>
                </div>

                <Slider
                    {...settings}
                >
                    {
                        data?.data.map((item, index) => {
                            return (
                                <div className="row" key={index}>
                                    <div className='col-md-6'>
                                        <div className="image hover-zoom">
                                            <img src={item.image.url} className='vision-image' alt={item.image.alt} />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="info">
                                            <span className="tag">{item.heading}</span>
                                            <h2>{ReactHtmlParser(item.subtitle1)}</h2>
                                            {ReactHtmlParser(item.subtitle2)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </Slider>

            </div>
        </div >
    );
}

export default CitizenCornerSubpage;