import { combineReducers } from 'redux';
import {
    ANALYTICS_DATA,
} from '../../../constants/actionConstants';

const analyticsData = (state = {}, action) => {
    switch (action.type) {
        case ANALYTICS_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}

const AnalyticsDashboardReducer = combineReducers({
    analyticsData,
});

export default AnalyticsDashboardReducer;


