import { combineReducers } from "redux";
import {
  CURRENT_INTERVENTION,
  QUOTES,
  FEATURED_TECHNOLOGY,
  IMPORTANT_CHALLENGES,
  BANNER,
  LATEST_NEWS,
  UPCOMMING_EVENTS,
  RUTAG_PAGE,
  RUTAG_PAGE_DETAILS,
} from "../../../constants/actionConstants";

// const currentInerventionList = (state = [], action) => {
//   switch (action.type) {
//     case CURRENT_INTERVENTION:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };

const rutagDetails = (state = {}, action) => {
  switch (action.type) {
    case RUTAG_PAGE_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
};

// const quotesList = (state = [], action) => {
//   switch (action.type) {
//     case QUOTES:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };

const rutagList = (state = [], action) => {
  switch (action.type) {
    case RUTAG_PAGE:
      return { ...action.payload };
    default:
      return state;
  }
};

// const featuredTechnologyList = (state = [], action) => {
//   switch (action.type) {
//     case FEATURED_TECHNOLOGY:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };

// const importantChallengesList = (state = [], action) => {
//   switch (action.type) {
//     case IMPORTANT_CHALLENGES:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };
// const bannerList = (state = [], action) => {
//   switch (action.type) {
//     case BANNER:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };

// const latestNewsList = (state = [], action) => {
//   switch (action.type) {
//     case LATEST_NEWS:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };

// const upcommingEventsList = (state = [], action) => {
//   switch (action.type) {
//     case UPCOMMING_EVENTS:
//       return { ...action.payload };
//     default:
//       return state;
//   }
// };

const RutagReducer = combineReducers({
  // currentInerventionList,
  // quotesList,
  // featuredTechnologyList,
  // importantChallengesList,
  // bannerList,
  rutagList,
  rutagDetails,
  // latestNewsList,
  // upcommingEventsList,
});

export default RutagReducer;
