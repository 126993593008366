import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import ModalHOC from "../../common/Modal";
import InputField from "../../../components/common/InputField";
import FileUpload from "../../../components/common/FileUpload";
import FormValidator from "../dependencies/formValidator";
import Button from "../../common/Button";
import { toast } from "react-toastify";
import {
  uploadFile,
  validateFile,
  submitEventRegistration,
} from "../dependencies/action";
import { SITE_KEY } from "../../../dependencies/utils/constants";
import {
  UPLOAD_FILE_EVENT,
  UPLOAD_FILE_EVENT2,
} from "../../../constants/ApplicationUrl";
import ReCAPTCHA from "react-google-recaptcha";
import InlineError from "../../common/InlineError";

import "./style.scss";

const EventForm = (props) => {
  const {
    data,
    uploadFile,
    validateFile,
    submitEventRegistration,
    onClose,
    isOpen,
  } = props;
  const [isOpenModal, setOpenModal] = useState(false);

  const onCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };
  const defaultFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    affilated: "",
    fileInfo: null,
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] =
    useState(false);
  const [displayFileName, setDisplayFileName] = useState("");
  const [fileName, setFileName] = useState("");
  // const [capthaError1, setCapthaError1] = useState(false);
  const [capthaErrorassist, setCapthaError1assist] = useState(true);
  const [capthaError, setCapthaError] = useState(false);
  const [removefileButton, setRemoveFileButton] = useState(false);
  const [uploadedFileEvent, setUploadedFileEvent] = useState([]);
  const [filesEvent, setFilesEvent] = useState([]);
  const recaptchaRef = useRef(null);
  const recaptchaRef1 = useRef(null);

  const validateInput = () => {
    const { firstName, lastName, email, phone, affilated } = formData;
    const formStatus = FormValidator.validateEventRegistrationForm({
      firstName,
      lastName,
      email,
      phone,
      affilated,
    });

    const errors = {
      firstName: formStatus.firstName,
      lastName: formStatus.lastName,
      email: formStatus.email,
      phone: formStatus.phone,
      affilated: formStatus.affilated,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const resetCaptcha1 = () => {
    recaptchaRef1.current.reset();
  };
  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };

  const onBlur = () => {
    validateInput();
  };
  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true);
    } else {
      // const recaptchaValue1 = recaptchaRef1.current.getValue();
      const recaptchaValue = recaptchaRef.current.getValue();
      if (recaptchaValue === "") {
        setCapthaError(true);
      } else {
        setCapthaError(false);
        // setCapthaError1(false);

        submitEventRegistration({
          ...formData,
          ...{ uploadedFileEvent },
          ...{
            eventName: data?.title,
            eventLink: data?.pagelink,
            event: data?.id,
            location: data?.location,
            time: data?.date,
            secret: recaptchaValue,
            request_for: "event_registration_form",
            // attachfile_secret: recaptchaValue1,
          },
        }).then((data) => {
          if (data.status) {
            setFormData(defaultFormData);
            setDisplayFileName("");
            setFileName("");
            resetCaptcha1();
            resetCaptcha();
            onCloseModal();
          }
        });
      }
    }
  };

  const handleReset = () => {
    setRemoveFileButton(false);
    setFileName("");
    setDisplayFileName("");
    delete formData.fileInfo.attachfile;
    delete formData.fileInfo.filename;
    delete formData.fileInfo.fileurl;
  };
  // const onUploadFile = (file) => {

  //     const data = new FormData();
  //     data.append('file', file)
  //     uploadFile(data).then((data) => {
  //         if (data.status) {
  //             delete data.status
  //             setRemoveFileButton(true);
  //             setFormData(d => {
  //                 return { ...d, fileInfo: data };
  //             });
  //             setDisplayFileName(data.filename)
  //         }
  //     }, (error) => {

  //     });
  // }

  const onUploadFile = (file, name) => {
    const dataValue = new FormData();
    dataValue.append("campaign", file);

    validateFile(dataValue).then((res) => {
      if (res) {
        if (uploadedFileEvent.length < 3) {
          const data = new FormData();
          data.append("file", file);
          data.append("request_for", "event_registartion_form");
          data.append("attachfile_secret", recaptchaRef1.current.getValue());

          // uploadFile(data).then(res => {
          //     if (res?.status) {
          //         setFilesEvent([...filesEvent, res?.filename]);
          //         setUploadedFileEvent([...uploadedFileEvent, {attachfile:res?.attachfile}])
          //     } else {
          //         toast.error("something went wrong");
          //     }
          // })

          var axios = require("axios");

          var config = {
            method: "post",
            url: UPLOAD_FILE_EVENT2,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              console.log(response);
              if (response.status === 200) {
                //   console.log("if");
                let MyDate = new Date();
                let MyDateString;
                setTimeout(() => {
                  recaptchaRef1.current.reset();
                  setCapthaError1assist(true);
                }, 1000);

                MyDateString =
                  MyDate.getFullYear() +
                  "-" +
                  ("0" + (MyDate.getMonth() + 1)).slice(-2);
                let fileUrl = `/s3fs/s3fs-public/${MyDateString}/${response?.data?.file_name}`;
                //   setFilesEvent([
                //     ...filesEvent,
                //     response?.data?.filename[0]["value"],
                //   ]);
                //   setUploadedFileEvent([
                //     ...uploadedFileEvent,
                //     {
                //       attachfile: response?.data?.filename[0]["value"],
                //       filename: response?.data?.filename[0]["value"],
                //       fileurl: fileUrl,
                //     },
                //   ]);
                console.log(response?.data);
                setFilesEvent([...filesEvent, response?.data?.file_name]);
                setUploadedFileEvent([
                  ...uploadedFileEvent,
                  response?.data?.file_id,
                ]);
              } else {
                console.log("else");
              }
            })
            .catch((e) => {
              toast.error("Something Went Wrong from submit tech index ");
              return { status: false };
            });
        } else {
          toast.error("You can upload maximum 3 files only");
        }
      }
    });
  };

  const removeFile = (file, type) => {
    let index = filesEvent.indexOf(file);
    if (index > -1) {
      uploadedFileEvent.splice(index, 1);
      filesEvent.splice(index, 1);
      setFilesEvent([...filesEvent]);
      setUploadedFileEvent([...uploadedFileEvent]);
    }
  };
  return (
    <div className="event-form form-data">
      <div className="row no-gutters">
        <div className="form-upper-section col-12 col-sm-6">
          <div className="row">
            <div className="form-heading col-12">
              Event <span>Registration</span>
            </div>
            <div className="col-12">
              <h1 className="form-name">{data?.title}</h1>
              <span className="detail-date form-detail">
                Date: {data?.date}
              </span>
              <span className="detail-location">
                Location: {data?.location}
              </span>
            </div>
          </div>
        </div>
        <div className="form-bottom-section  col-12 col-sm-6">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="col-12">
              <InputField
                required
                type="text"
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                error={error.firstName}
                containerClass={"form-group"}
                placeholder={"John"}
                onBlur={onBlur}
                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                className="form-input-field"
              />
            </div>
            <div className="col-12">
              <InputField
                required
                type="text"
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                error={error.lastName}
                containerClass={"form-group"}
                onBlur={onBlur}
                placeholder={"Smith"}
                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                className="form-input-field"
              />
            </div>
            <div className="col-12">
              <InputField
                required
                type="text"
                name="affilated"
                label="Affiliation"
                value={formData.affilated}
                onChange={handleInputChange}
                error={error.affilated}
                placeholder={"Enter Affiliation"}
                containerClass={"form-group"}
                onBlur={onBlur}
                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                className="form-input-field"
              />
            </div>
            <div className="col-12">
              <InputField
                required
                type="text"
                name="email"
                label="Email Id"
                value={formData.email}
                onChange={handleInputChange}
                error={error.email}
                placeholder={"abc@gmail.com"}
                containerClass={"form-group"}
                onBlur={onBlur}
                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                className="form-input-field"
              />
            </div>
            <div className="col-12">
              <InputField
                required
                type="text"
                name="phone"
                label="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                error={error.phone}
                containerClass={"form-group"}
                placeholder={"+91-987xxxxx21"}
                onBlur={onBlur}
                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                className="form-input-field"
              />
            </div>
            <div className="col-12">
              {/* <InputField
                                required
                                type="text"
                                name="upload"
                                label="Upload File"
                                value={formData.upload}
                                onChange={handleInputChange}
                                error={error.upload}
                                placeholder={'Attach a file'}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                className='form-input-field'
                            /> */}
              <div className="input-field-container">
                <label className="upload-label">
                  Upload File
                  <br />{" "}
                  <span className="file-format">(pdf, png, jpg, jpeg)</span>
                </label>
              </div>
              <div className="captcha-field" style={{ marginLeft: "-5%" }}>
                <ReCAPTCHA
                  ref={recaptchaRef1}
                  sitekey={SITE_KEY}
                  onChange={() => {
                    setCapthaError1assist(false);
                  }}
                />
                {/* {capthaError1 && <InlineError message={"This is required"} />} */}
              </div>
              <FileUpload
                htmlFor="uploadFileInput"
                maxFileSizeInMB={3}
                labelClass="contactUploadFile"
                onUploadFile={(file) => onUploadFile(file, "contactUploadFile")}
                allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                // error={error.fileInfo}
                // value={fileName}
                onBlur={onBlur}
                id="uploadFileInput"
                visbility="hidden"
                label=""
              />
              {filesEvent.map((file) => (
                <p key={file}>
                  {file}{" "}
                  <span
                    onClick={() => {
                      removeFile(file, "engagement");
                    }}
                  >
                    X
                  </span>
                </p>
              ))}

              {/* <FileUpload
                                onUploadFile={onUploadFile}
                                allowedFiles={[".pdf", ".png", ".jpg"]}
                                error={error.fileInfo}
                                label='Upload Document'
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

                            /> */}
              <label
                htmlFor="uploadFileInput"
                className="bordered-btn w-auto green-border"
                style={{
                  pointerEvents: capthaErrorassist === true ? "none" : "auto",
                  borderColor:
                    capthaErrorassist === true ? "lightgrey" : "#2ba749",
                  color: capthaErrorassist === true ? "lightgrey" : "#2ba749",
                }}
              >
                Attach File
              </label>
              <div className="fileNameWrap clearfix">
                {removefileButton && displayFileName && (
                  <div className="fileName">
                    {displayFileName}
                    <span className="remove-file" onClick={handleReset}>
                      X
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="bttn-group col-sm-12 d-flex align-items-center justify-content-center">
                <div className="captcha-field">
                  <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
                  {capthaError && <InlineError message={"This is required"} />}
                </div>
                <Button
                  type="submit"
                  text="Submit"
                  className="submit-button green-btn"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default connect(null, {
  uploadFile,
  validateFile,
  submitEventRegistration,
})(ModalHOC(EventForm));
