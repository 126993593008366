import api from "../../../dependencies/utils/Api";
import {
  SUBMIT_CITIZEN_PLEDGE,
  PLEDGE_DATA,
  MAIL_OTP,
  VALIDATE_EMAIL,
} from "../../../constants/ApplicationUrl";

export const FellowShipService = {
  submitCitizenPledgeData(params) {
    return api
      .POST(SUBMIT_CITIZEN_PLEDGE, params)
      .then((response) => {
        const { data, status } = response || {};
        return { data, status };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  pledgeData(url) {
    return api
      .GET(`${PLEDGE_DATA}/${url}`)
      .then((response) => {
        const { data = ({ data } = {}) } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  mailOtp(params) {
    const paramObject = {
      email: params.email,
      secret: params.secret,
      otp: params.otp,
    };
    return api
      .POST(`${MAIL_OTP}`, paramObject)
      .then((response) => {
        return response?.data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  validateEmailId(params) {
    const { emailId, secret } = params;
    return api
      .GET(`${VALIDATE_EMAIL}${emailId}&secret=${secret}`)
      .then((response) => {
        return response?.data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
};
