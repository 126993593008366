import './style.scss';

const EventData = (props) => {
    const { capturedEvent, pastEvent } = props
    return (
        <div className="eventData">
            <div className='event-data-main'>
                <div className='eventDataText'>
                    Captured Events
                <div className='capturedeventnumber'>{capturedEvent}</div>
                </div>
                <div className='eventDataText'>
                    Past Events
                <div className='capturedeventnumber'>{pastEvent}</div>
                </div>
            </div>
        </div>
    );
}

export default EventData;
