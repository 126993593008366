import { FellowShipService } from './service';
import { isLoading } from '../../../dependencies/action/action';
import {
    FELLOWHIP
} from '../../../constants/actionConstants';
import { toast } from "react-toastify";

export const uploadFileBank = (params) => async (dispatch) => {
    // var allCookies = document.cookie.split(';');
    // console.log(allCookies)
    // document.cookie = "";
    dispatch(isLoading(true))
    return await FellowShipService.uploadFileBank(params).then((response) => {
        dispatch(isLoading(false));
        if (response.status === 201) {
            return response

        } else {
            dispatch(isLoading(false));
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}
export const uploadFileEngage = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await FellowShipService.uploadFileEngage(params).then((response) => {
        dispatch(isLoading(false));
        if (response.status === 201) {
            return response

        } else {
            dispatch(isLoading(false));
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const submitCategory = (params) => async (dispatch) => {
    // console.log(params)
    dispatch(isLoading(true))
    return await FellowShipService.submitCatagory(params).then((response) => {
        dispatch(isLoading(false));
        const { status, data, code } = response || {}
        if (status === 200) {
            if (data.code === 400) {
                toast.error(data.message);
                return { status: false }
            } else {
                toast.success(data.message);
                return { status: true };
            }

        } else {
            dispatch(isLoading(false));
            toast.error(data.message);
            return { status: false }
        }
    }).catch(e => {
        dispatch(isLoading(false));
        toast.error('Something Went Wrong');
        return { status: false }
    })
}

// export function contactUsData() {
//     return async function (dispatch) {
//         let contactUsData = await contactUsService.contactUsData();
//         dispatch({
//             type: CONTACT_US_DATA,
//             payload: contactUsData
//         })
//     }
// } 


export function getDropDownData() {
    return async function (dispatch) {
        let getDropData = await FellowShipService.getDropDownData();
        dispatch({
            type: FELLOWHIP,
            payload: getDropData
        })
    }
} 