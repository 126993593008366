import { PastInterventionService } from "./service";
import {
  CURRENT_INTERVENTION_LIST,
  INTERVENTION_TECNOLOGY,
  CURRENT_INTERVENTION_DETAILS,
  FILTER_DATA,
  PAST_PROJECTS_LIST,
  PAST_PROJECTS_DETAILS,
} from "../../../constants/actionConstants";
import { isLoading } from "../../../dependencies/action/action";

export function getChallengeEventList(
  page,
  categoryId,
  city,
  search,
  subtheme,
  state,
  date
) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let currentInterventionList =
      await PastInterventionService.getCurrentInterventionList(
        page,
        categoryId,
        city,
        search,
        subtheme,
        state,
        date
      );
    dispatch({
      type: CURRENT_INTERVENTION_LIST,
      payload: currentInterventionList,
    });
    dispatch(isLoading(false));
  };
}

export function getPastProjectList() {
  return async function (dispatch) {
    let pastProjectsList = await PastInterventionService.getPastProjectList();
    dispatch({
      type: PAST_PROJECTS_LIST,
      payload: pastProjectsList,
    });
  };
}

export function getPastProjectsDetails(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let pastProjectsDetails =
      await PastInterventionService.getPastProjectsDetails(url);

    dispatch({
      type: PAST_PROJECTS_DETAILS,
      payload: pastProjectsDetails,
    });
    dispatch(isLoading(false));
  };
}

export function getTechnologyType() {
  return async function (dispatch) {
    let technologyType = await PastInterventionService.getTechnologyType();
    dispatch({
      type: INTERVENTION_TECNOLOGY,
      payload: technologyType,
    });
  };
}

export function getCurrentInterventionDetails(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let currentInterventionDetails =
      await PastInterventionService.getCurrentInterventionDetails(url);

    dispatch({
      type: CURRENT_INTERVENTION_DETAILS,
      payload: currentInterventionDetails,
    });
    dispatch(isLoading(false));
  };
}

export function getFilterData() {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let filterData = await PastInterventionService.filterData();
    dispatch({
      type: FILTER_DATA,
      payload: filterData,
    });
    dispatch(isLoading(false));
  };
}
