import { CitizenCornerServices } from './service';
import {
    CITEZEN_CORNER,
    NUMBER_OF_VISITORS
} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';

export const getCitizenCorner = () => {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let getCitizenCorner = await CitizenCornerServices.getCitizenCorner();
        dispatch({
            type: CITEZEN_CORNER,
            payload: getCitizenCorner?.data
        })
        dispatch(isLoading(false))
    }
    
}

export const getNumberOfVistiors = () => {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let numberOfVistiors = await CitizenCornerServices.getNumberOfVistiors();
        dispatch({
            type: NUMBER_OF_VISITORS,
            payload: numberOfVistiors
        })
        dispatch(isLoading(false))
    }
    
}