import { combineReducers } from 'redux';
import {
    FELLOWHIP,
    PLEDGE_DATA
} from '../../../constants/actionConstants';

const FelloshipData = (state = {}, action) => {
    switch (action.type) {
        case FELLOWHIP:
            return { ...action.payload };
        default:
            return state;
    }
}

const PledgeData = (state = {}, action) => {
    switch (action.type) {
        case PLEDGE_DATA:
            return { ...action.payload };
        default:
            return state;
    }
}


const PledgeReducer = combineReducers({
    FelloshipData,
    PledgeData
});

export default PledgeReducer;


