import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const Disclaimer = (props) => {
  const { data } = props;
  return (
    <div className="disclaimer">
      <div className="container">
        <div className="container-wrap">
          <div
            className="disclaimer-heading"
            style={{ fontFamily: "'Chivo',sans-serif", fontSize: "43px" }}
          >
            Disclaimer
          </div>
          <div className="disclaimer-text pastprojoverviewbody">
            {/* Point of contact: <b>Ms. Malyaj Varmani/ Ms. Malvika Jain</b> 
                    <br/>
                    For queries write to us at: <b>sbub@investindia.org.in</b> */}
            {ReactHtmlParser(data)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
