import ReactHtmlParser from 'react-html-parser';
import './style.scss';
import dummyMissionImage from '../../../../../assests/images/dummy_img.png';

const OurMission = (props) => {
    const { data } = props
    return (
        <div className="OurMission">
            <div className='container'>
                <div className='container-wrap'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img src={data?.image} className='mission-image' alt="" />
                        </div>
                        <div className='col-lg-6 mission-container'>
                            <div className='mission-text'>
                                <span className='mission-tag'>{data?.tag} </span>
                                <div className='mission-heading'>
                                    {data?.title}
                                </div>
                                <div className='mission-detail'>
                                    {ReactHtmlParser(data?.body)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurMission;