import api from "../../../dependencies/utils/Api";
import {
  KM_DETAILS,
  KM_TECHNOLOGY_LISTING,
  KM_FILTER_DATA,
  KM_TECNOLOGY_TYPE,
  VALIDATION_LINK_LIST,
  VALIDATED_TECH_FILTERDATA,
  VALIDATED_TECHNOLOGY_LISTING,
} from "../../../constants/ApplicationUrl";

export const CurrentInterventionService = {
  getKmList(page, search, thematicArea, origin, readiness, recognized, state,country) {
    return api
      .GET(
        `${VALIDATED_TECHNOLOGY_LISTING}?page=${page}&thematicArea=${thematicArea}&origin=${origin}&readiness=${readiness}&search=${search}&recognized=${recognized}&state=${state}&country=${country}`
      )
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getTechnologyType() {
    return api
      .GET(KM_TECNOLOGY_TYPE)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getKmDetails(url) {
    return api
      .GET(`${KM_DETAILS}/${url}`)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  filterData() {
    return api
      .GET(KM_FILTER_DATA)
      .then((response) => {
        const { data: { responseCode, data } = {} } = response || {};
        if (responseCode === 200) {
          return data;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getValidationLink() {
    return api
      .GET(VALIDATION_LINK_LIST)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
};
