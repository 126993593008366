import React, { useState, useEffect } from "react";
import "./style.scss";
import Scrollbar from "react-scrollbars-custom";
import sharefb from "../../../../../assests/svg/ic_fb.svg";
import sharetwiter from "../../../../../assests/svg/ic_twitter.svg";
import sharetinsta from "../../../../../assests/svg/ic_insta.svg";
import bottomLine from "../../../../../assests/images/Event_Graphic_03_updated.svg";
import bannervidimage from "../../../../../assests/images/bannervidimage.jpg";

// import VideoPlayer from "react-video-js-player";
import ReactPlayer from "react-player/lazy";

import { Player } from "video-react";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const GallerySection2 = (props) => {
  const getVideoId = (url) => {
    // let videoId = url?.split(".be/")[1];
    // let ampersandPosition = videoId?.indexOf("&");
    // if (ampersandPosition !== -1) {
    // 	videoId = videoId?.substring(0, ampersandPosition);
    // }
    // return videoId;
    return url;
  };

  const { data, firstvid } = props;
  const [isPlay, setIsPlay] = useState(false);
  const [isSSFGallery, setSSFGallery] = useState(true);
  const [mp4vidnull, setmp4vidnull] = useState(
    data?.gallery_videos?.SSF_Videos &&
      data?.gallery_videos.SSF_Videos[0].mp4_video != ""
      ? false
      : true
  );
  const [isVideoGallery, setVideoGallery] = useState(false);

  const [videoTitle, setVideoTitle] = useState(
    data?.gallery_videos?.Event_Videos &&
      data?.gallery_videos?.Event_Videos?.length !== 0 &&
      data?.gallery_videos?.Event_Videos[0]?.title
  );
  const [imageTitle, setImageTitle] = useState(
    data?.gallery_images && data?.gallery_images[0]?.image_title
  );
  const [ssfTitle, setSSFTitle] = useState(
    data?.gallery_videos?.SSF_Videos &&
      data?.gallery_videos?.SSF_Videos[0]?.title
  );
  const [ssfImg, setSSFImg] = useState(
    // data?.gallery_videos?.SSF_Videos?.length !== 0 &&
    //   `http://uat.wastetowealth.gov.in${data?.gallery_videos?.SSF_Videos[0]?.thumbnail}`

    data?.gallery_videos?.SSF_Videos &&
      data?.gallery_videos.SSF_Videos[0].thumbnail != ""
      ? `${
          data?.gallery_videos?.SSF_Videos &&
          data?.gallery_videos.SSF_Videos[0].thumbnail
        }`
      : bannervidimage
  );
  const [thumbImg, setThumbImg] = useState(
    `${
      data?.gallery_videos?.Event_Videos
        ? data?.gallery_videos?.Event_Videos[0]?.thumbnail
        : ""
    }`
  );
  const [imageThumb, setImageThumb] = useState(
    data?.gallery_images?.length !== 0 && `${data?.gallery_images[0]?.url}`
  );
  const [activeNumber, setActiveNumber] = useState(1);

  const [ytVideoId, setVideoId] = useState(() =>
    getVideoId(
      data?.gallery_videos?.Event_Videos &&
        data?.gallery_videos?.Event_Videos?.length !== 0 &&
        data?.gallery_videos?.Event_Videos[0]?.url
    )
  );

  const [social, setSocial] = useState({
    url:
      data?.gallery_videos?.SSF_Videos?.length !== 0 &&
      `${
        data?.gallery_videos?.SSF_Videos &&
        data?.gallery_videos.SSF_Videos[0].mp4_video
      }`,
    title: "Wase To Wealth",
    sepreater: "::",
    size: "2.5rem",
  });
  const [newvid, setNewvid] = useState(
    data?.gallery_videos?.SSF_Videos
      ? data?.gallery_videos.SSF_Videos[0].mp4_video != ""
        ? data?.gallery_videos.SSF_Videos[0].mp4_video
        : data?.gallery_videos.SSF_Videos[0].url
      : ""
  );
  // const [newutubevid, setNewutubevid] = useState(
  //   `${data?.gallery_videos?.SSF_Videos[0]?.url}`
  // );
  // const [nuVidLink, setNuvidlink] = useState(data?.videos[0]?.url.slice(32));

  useEffect(() => {
    // const nuvidlink = data?.gallery_videos[0]?.url.slice(32);

    // setNuvidlink(nuvidlink);

    // console.log("nuvidlink", nuvidlink);
    // console.log("nuvidlink_slice", nuvidlink.slice(0, 5));

    setSocial({
      ...social,
      url:
        data?.gallery_videos?.SSF_Videos?.length != 0
          ? `${
              data?.gallery_videos?.SSF_Videos &&
              data?.gallery_videos?.SSF_Videos[0]?.mp4_video
            }`
          : "",
    });
  }, [
    data?.gallery_videos?.SSF_Videos?.length != 0
      ? `${
          data?.gallery_videos?.SSF_Videos &&
          data?.gallery_videos?.SSF_Videos[0]?.mp4_video
        }`
      : "",
  ]);

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
  };

  const onPlayerPlay = () => {
    setIsPlay(true);
  };
  useEffect(() => {
    setImageTitle(
      data?.gallery_images?.length !== 0 && data?.gallery_images[0]?.image_title
    );
    setVideoTitle(
      data?.gallery_videos?.Event_Videos &&
        data?.gallery_videos?.Event_Videos?.length !== 0 &&
        data?.gallery_videos?.Event_Videos[0]?.title
    );
    setSSFTitle(
      data?.gallery_videos?.SSF_Videos?.length !== 0 &&
        data?.gallery_videos?.SSF_Videos &&
        data?.gallery_videos.SSF_Videos[0].title
    );
    setNewvid(
      data?.gallery_videos?.SSF_Videos
        ? data?.gallery_videos.SSF_Videos[0].mp4_video != ""
          ? `${
              data?.gallery_videos?.SSF_Videos &&
              data?.gallery_videos.SSF_Videos[0].mp4_video
            }`
          : data?.gallery_videos?.SSF_Videos[0]?.url
        : ""
    );
    setSSFImg(
      // data?.list?.video[0]?.image
      //   ? data?.list?.video[0]?.image
      //   : data?.list?.image[0]?.image
      // data?.gallery_videos?.SSF_Videos?.length !== 0
      //   ? `http://uat.wastetowealth.gov.in${data?.gallery_videos?.SSF_Videos[0]?.thumbnail}`
      //   : ""

      data?.gallery_videos?.SSF_Videos
        ? data?.gallery_videos.SSF_Videos[0].thumbnail != ""
          ? `${
              data?.gallery_videos?.SSF_Videos &&
              data?.gallery_videos.SSF_Videos[0].thumbnail
            }`
          : bannervidimage
        : ""
    );
    setThumbImg(
      // data?.list?.video[0]?.image
      //   ? data?.list?.video[0]?.image
      //   : data?.list?.image[0]?.image
      data?.gallery_videos?.Event_Videos
        ? `${data?.gallery_videos?.Event_Videos[0]?.thumbnail}`
        : ""
    );
    setImageThumb(
      data?.gallery_images?.length !== 0 && `${data?.gallery_images[0]?.url}`
    );
    setVideoId(() =>
      getVideoId(
        data?.gallery_videos?.Event_Videos &&
          data?.gallery_videos?.Event_Videos[0]?.url
      )
    );
    setVideoGallery(
      data?.gallery_videos?.Event_Videos &&
        data?.gallery_videos?.Event_Videos?.length !== 0
        ? true
        : false
    );
  }, [data]);
  // ytplayer = document.getElementById("movie_player");
  // ytplayer.getCurrentTime();

  const handleSSFThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setSSFTitle(title);
    setSSFImg(img);
    setIsPlay(false);
  };

  const handleVideoThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setVideoTitle(title);
    setThumbImg(img);
    setIsPlay(false);
  };
  const handleImageThumb = (title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    setImageTitle(title);
    setImageThumb(img);
    setIsPlay(false);
  };
  return (
    <>
      <div
        className="gallery-section currentinterventionlistgallery2 1st"
        // style={{ zIndex: 1 }}
        style={{ backgroundImage: "none" }}
      >
        <div className="container">
          <div className="gallery-head d-flex">
            {data?.gallery_videos?.SSF_Videos?.length !== 0 && (
              <button
                style={{
                  display: data?.gallery_videos?.SSF_Videos ? "block" : "none",
                }}
                className="gallery-title mr-2"
                onClick={() => {
                  setVideoGallery(false);
                  setSSFGallery(true);
                }}
              >
                <h2
                  className={isSSFGallery ? "active" : ""}
                  style={{ color: "black" }}
                >
                  {/* SSF Videos */}
                  Meet Our Fellows
                </h2>
              </button>
            )}
            {data?.gallery_videos?.Event_Videos?.length !== 0 && (
              <button
                style={{
                  display: data?.gallery_videos?.Event_Videos
                    ? "block"
                    : "none",
                }}
                className="gallery-title mr-2"
                onClick={() => {
                  setVideoGallery(true);
                  setSSFGallery(false);
                }}
              >
                <h2
                  className={isVideoGallery && !isSSFGallery ? "active" : ""}
                  style={{ color: "black" }}
                >
                  Event Videos
                </h2>
              </button>
            )}
            {data?.gallery_images?.length !== 0 && (
              <button
                className="gallery-title"
                onClick={() => {
                  setVideoGallery(false);
                  setSSFGallery(false);
                }}
              >
                <h2
                  className={!isVideoGallery && !isSSFGallery ? "active" : ""}
                  style={{ color: "black" }}
                >
                  SSF images
                </h2>
              </button>
            )}
            <div className="social-share-wrap">
              <span className="share-text mr-2" style={{ color: "black" }}>
                Share{" "}
              </span>
              <FacebookShareButton
                url={newvid}
                quote={ssfTitle}
                sepreater={social.sepreater}
              >
                <img src={sharefb} alt="" width="32" />
              </FacebookShareButton>
              <TwitterShareButton
                url={newvid}
                title={ssfTitle}
                sepreater={social.sepreater}
              >
                <img src={sharetwiter} alt="" width="32" />
              </TwitterShareButton>
              <a
                href="https://www.instagram.com/wasteto.wealth/?hl=en"
                target="_blank"
              >
                <img src={sharetinsta} alt="" width="32" />
              </a>
            </div>
          </div>

          {isSSFGallery && data?.gallery_videos?.SSF_Videos?.length !== 0 && (
            <div className="row">
              <div className="col-md-8">
                <div className="yt-wrapper">
                  <div
                    className="gallery-count"
                    // style={{ color: "#0d70af" }}
                    style={{ color: "black" }}
                  >
                    <span style={{ color: "black" }}>{activeNumber}&nbsp;</span>
                    /&nbsp;
                    {data?.gallery_videos?.SSF_Videos?.length}
                  </div>
                  <div className="yt-inner">
                    {!isPlay && (
                      <div
                        className="video-cover"
                        style={{
                          position: "absolute",
                          zIndex: 1,
                        }}
                        // onClick={(e) => {
                        //   playVideo(e);
                        //   setIsPlay(true);
                        // }}
                      >
                        <img
                          className=""
                          src={ssfImg}
                          alt={ssfTitle}
                          style={{
                            height: mp4vidnull === false ? "94%" : "100%",
                            borderRadius: "0px",
                          }}
                        />
                      </div>
                    )}
                    {/* {newvid != "" ? (
                      <>
                       
                        <div className="reactplayernew">
                          <ReactPlayer
                            onPlay={onPlayerPlay}
                            controls={true}
                            url={newvid}
                            // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                          />
                        </div>
                      </>
                    ) : (
                      <iframe
                        id="player"
                        width="100%"
                        src={`https://www.youtube.com/watch?v=ysz5S6PUM-U`}
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                        rel="0"
                      ></iframe>
                    )} */}
                    {mp4vidnull === false ? (
                      <div className="reactplayernew">
                        <ReactPlayer
                          onPlay={onPlayerPlay}
                          controls={true}
                          url={newvid}
                          // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        />
                      </div>
                    ) : (
                      <iframe
                        id="player"
                        width="100%"
                        // height="388px"
                        src={newvid}
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                        rel="0"
                      ></iframe>
                    )}

                    {!isPlay && (
                      <button
                        id="play"
                        className="play-btn2"
                        onClick={(e) => {
                          playVideo(e);
                          setIsPlay(true);
                        }}
                      ></button>
                    )}
                  </div>
                </div>
                <h3
                  className="video-title"
                  style={{ color: "black", marginTop: "10px" }}
                >
                  {ssfTitle}
                </h3>
              </div>
              <div className="col-md-4">
                {data?.gallery_videos?.SSF_Videos?.length > 3 ? (
                  <Scrollbar style={{ height: 444 }}>
                    <div className="video-list">
                      {data?.gallery_videos?.SSF_Videos?.map((item, index) => {
                        return (
                          <div
                            className={
                              activeNumber === index + 1
                                ? "video-thumb active"
                                : "video-thumb"
                            }
                            style={{ background: "transparent" }}
                            onClick={() => {
                              handleSSFThumb(
                                item.mp4_video,
                                item.title,
                                `${item.thumbnail}`,
                                // `https://www.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911_1.png`,
                                index + 1
                              );
                              setSSFImg(
                                item.thumbnail != ""
                                  ? `${item.thumbnail}`
                                  : bannervidimage
                              );
                              setNewvid(
                                item?.mp4_video != ""
                                  ? `${item?.mp4_video}`
                                  : item?.url
                              );

                              if (item?.mp4_video != "") {
                                setmp4vidnull(false);
                              } else {
                                setmp4vidnull(true);
                              }
                            }}
                          >
                            {/* <img
                            className="video-cover"
                            src={item.url}
                            alt="Video title"
                          /> */}
                            {/* <div className="video-cover">
                            <h3 className="video-title">{videoTitle}</h3>
                          </div> */}
                            <img
                              className="video-cover"
                              src={
                                item.thumbnail != ""
                                  ? `${item.thumbnail}`
                                  : bannervidimage
                              }
                              // src={`https://www.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911_1.png`}
                              alt="Video title"
                            />
                            <span className="thumb-play" />
                          </div>
                        );
                      })}
                    </div>
                  </Scrollbar>
                ) : (
                  <div className="video-list no-scroll">
                    {data?.gallery_videos?.SSF_Videos?.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          style={{ background: "transparent" }}
                          onClick={() => {
                            handleSSFThumb(
                              item.mp4_video,
                              item.title,
                              `${item.thumbnail}`,
                              // `https://www.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911_1.png`,
                              index + 1
                            );
                            setSSFImg(
                              item.thumbnail != ""
                                ? `${item.thumbnail}`
                                : bannervidimage
                            );
                            setNewvid(
                              item?.mp4_video != ""
                                ? `${item?.mp4_video}`
                                : item?.url
                            );
                          }}
                        >
                          {/* <img
                          className="video-cover"
                          src={item.url}
                          alt="Video title"
                        /> */}
                          {/* <div className="video-cover">
                          <h3 className="video-title">{videoTitle}</h3>
                        </div> */}
                          <img
                            className="video-cover"
                            // src={`http://uat.wastetowealth.gov.in${item.thumbnail}`}
                            src={
                              item.thumbnail != ""
                                ? `${item.thumbnail}`
                                : bannervidimage
                            }
                            alt="Video title"
                          />
                          <span className="thumb-play" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}

          {isVideoGallery &&
            !isSSFGallery &&
            data?.gallery_videos?.Event_Videos?.length !== 0 && (
              <div className="row">
                <div className="col-md-8">
                  <div className="yt-wrapper">
                    <div
                      className="gallery-count"
                      // style={{ color: "#0d70af" }}
                      style={{ color: "black" }}
                    >
                      <span style={{ color: "black" }}>
                        {activeNumber}&nbsp;
                      </span>
                      /&nbsp;
                      {data?.gallery_videos?.Event_Videos?.length}
                    </div>
                    <div className="yt-inner">
                      {!isPlay && (
                        <div className="video-cover">
                          <img className="" src={thumbImg} alt={videoTitle} />
                          {/* <h3 className="video-title">{videoTitle}</h3> */}
                        </div>
                      )}
                      {isPlay && (
                        <iframe
                          id="player"
                          width="100%"
                          src={`${data?.gallery_videos?.Event_Videos[0]?.url}`}
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          rel="0"
                        ></iframe>
                      )}
                      {!isPlay && (
                        <button
                          id="play"
                          className="play-btn"
                          onClick={(e) => {
                            playVideo(e);
                            // setIsPlay(true);
                          }}
                        ></button>
                      )}
                    </div>
                  </div>
                  <h3
                    className="video-title"
                    style={{ color: "black", marginTop: "10px" }}
                  >
                    {videoTitle}
                  </h3>
                </div>
                <div className="col-md-4">
                  {data?.gallery_videos?.Event_Videos?.length > 3 ? (
                    <Scrollbar style={{ height: 444 }}>
                      <div className="video-list">
                        {data?.gallery_videos?.Event_Videos?.map(
                          (item, index) => {
                            return (
                              <div
                                className={
                                  activeNumber === index + 1
                                    ? "video-thumb active"
                                    : "video-thumb"
                                }
                                style={{ background: "transparent" }}
                                onClick={() =>
                                  handleVideoThumb(
                                    item.url,
                                    item.title,
                                    `${item.thumbnail}`,
                                    index + 1
                                  )
                                }
                              >
                                {/* <img
                            className="video-cover"
                            src={item.url}
                            alt="Video title"
                          /> */}
                                {/* <div className="video-cover">
                            <h3 className="video-title">{videoTitle}</h3>
                          </div> */}
                                <img
                                  className="video-cover"
                                  src={`${item.thumbnail}`}
                                  alt="Video title"
                                />
                                <span className="thumb-play" />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </Scrollbar>
                  ) : (
                    <div className="video-list no-scroll">
                      {data?.gallery_videos?.Event_Videos?.map(
                        (item, index) => {
                          return (
                            <div
                              className={
                                activeNumber === index + 1
                                  ? "video-thumb active"
                                  : "video-thumb"
                              }
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleVideoThumb(
                                  item.url,
                                  item.title,
                                  `${item.thumbnail}`,
                                  index + 1
                                )
                              }
                            >
                              {/* <img
                          className="video-cover"
                          src={item.url}
                          alt="Video title"
                        /> */}
                              {/* <div className="video-cover">
                          <h3 className="video-title">{videoTitle}</h3>
                        </div> */}
                              <img
                                className="video-cover"
                                src={`${item.thumbnail}`}
                                alt="Video title"
                              />
                              <span className="thumb-play" />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

          {!isVideoGallery &&
            !isSSFGallery &&
            data?.gallery_images?.length !== 0 && (
              <div className="row">
                <div className="col-md-8">
                  <div className="yt-wrapper">
                    <div className="gallery-count" style={{ color: "black" }}>
                      <span style={{ color: "black" }}>
                        {activeNumber}&nbsp;
                      </span>
                      /&nbsp;
                      {data?.gallery_images?.length}
                    </div>
                    <div className="yt-inner">
                      <div className="video-cover">
                        <img className="" src={imageThumb} alt={imageTitle} />
                        {/* <h3 className="video-title">{imageTitle}</h3> */}
                      </div>
                    </div>
                    {/* <h3 className="video-title">{imageTitle}</h3> */}
                  </div>
                  <h3
                    className="video-title"
                    style={{ color: "black", marginTop: "10px" }}
                  >
                    {/* {data?.gallery_images[0]?.image_title} */}
                    {imageTitle}
                  </h3>
                </div>
                <div className="col-md-4">
                  {data?.gallery_images?.length > 3 ? (
                    <Scrollbar style={{ height: 444 }}>
                      <div className="video-list">
                        {data?.gallery_images?.map((item, index) => {
                          return (
                            <div
                              className={
                                activeNumber === index + 1
                                  ? "video-thumb active"
                                  : "video-thumb"
                              }
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleImageThumb(
                                  item.image_title,
                                  `${item.url}`,
                                  index + 1
                                )
                              }
                            >
                              {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                              <img
                                className="video-cover"
                                src={`${item.url}`}
                                alt="Video title"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Scrollbar>
                  ) : (
                    <div className="video-list no-scroll">
                      {data?.gallery_images?.map((item, index) => {
                        return (
                          <div
                            className={
                              activeNumber === index + 1
                                ? "video-thumb active"
                                : "video-thumb"
                            }
                            style={{ background: "transparent" }}
                            onClick={() =>
                              handleImageThumb(
                                item.image_title,
                                `${item.url}`,
                                index + 1
                              )
                            }
                          >
                            {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                            <img
                              className="video-cover"
                              src={`${item.url}`}
                              alt="Video title"
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
      <div style={{ marginTop: "-0.5%" }}>
        <img src={bottomLine} style={{ width: "100%" }} />
      </div>
    </>
  );
};

export default GallerySection2;

// import React from "react";

// function GallerySection2() {
//   return <div>hellow</div>;
// }

// export default GallerySection2;
