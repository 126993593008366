import ReactHtmlParser from 'react-html-parser';
import './style.scss';
import Pdf from '../../../../../assests/file/SSF_Details.pdf';

const Disclaimer = (props) => {
    const { data } = props
    return (
        <div className="disclaimer">
            <div className='container'>
                <div className='container-wrap'>
                <div className="pdf-background">
        <p>For more information on terms and conditions of the fellowship   
                                <a> </a>  <a
                                    href={Pdf}
                                    // ="https://www.orimi.com/pdf-test.pdf"
                                    target='_blank'
                                  >
                                   click here
                                  </a>
                                  </p>
                                </div>
                    <div className='disclaimer-text'>
                    Point of contact: <b>Ms. Malyaj Varmani/ Ms. Malvika Jain</b> 
                    <br/>
                    For queries write to us at: <b>contact.wastetowealth@investindia.org.in</b>
                        {/* {ReactHtmlParser(data)} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Disclaimer;