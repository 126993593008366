import { combineReducers } from 'redux';
import {
    ABOUT_US
} from '../../../constants/actionConstants';

const aboutUs = (state = {}, action) => {
    switch (action.type) {
        case ABOUT_US:
            return { ...action.payload };
        default:
            return state;
    }
}
const StaticPageReducer = combineReducers({
    aboutUs
});

export default StaticPageReducer;


