import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'

const InlineError = props => { return <div className="inline-error">*{props.message}</div> }

InlineError.propTypes = {
    message: PropTypes.string,
}
InlineError.defaultProps = {
    message: '',
}

export default InlineError;