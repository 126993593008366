import React, { Fragement, useLayoutEffect, useRef, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import "./style.scss";

const MultiSelectWithCheckbox = (props) => {
	const inputRef = useRef(null);
	const [isRefAdded, setInputRef] = useState(false);
	const {
		label,
		options,
		placeholder,
		onChange,
		name,
		valid,
		touched,
		error,
		required,
		value,
		onBlur,
	} = props;
	const classes = ["label"];
	const [blured, setBlured] = useState(false);
	
	const showError = valid || blured;
	let errorSpan = null;
	if (required && error && !value && valid) {
		classes.push("is-invalid");
		errorSpan = <span className=" d-block inline-error">{error}</span>;
	}
	
	const optionsFormatter =
	options && options.length
	? options.map((option) => ({
		label: option?.text,
		value: option?.value,
	}))
	: [];
	
	const labelForValue = options?.filter((opt) => {
		return opt.value === value;
	})[0]?.text;
	
	const valueFromatter = value
	? value.split(",").map((value) => ({ label: labelForValue, value }))
	: [];
	
	const inputChangeHandler = (valueArray) => {
		
		const value = valueArray.map((item) => item.value).join();
		const e = { target: { value } };
		
		onChange(e, name);
	};
	// const handleInputBlur = () => {
	// 	if (onBlur) {
	// 		if (value === "" || value === undefined || value === null) {
	// 			const e = { target: { value: '' } };
	// 			onBlur(e, name);
	// 		}
	// 	}
	// }
	// useLayoutEffect(() => {
	// 	if (!isRefAdded) {
	// 		setInputRef(true);
	// 		const multiselect = inputRef.current;
	// 		multiselect.children[0].children[0].addEventListener(
	// 			"blur",
	// 			(event) => {
	// 				if (!value) {
	// 					const e = { currentTarget: { value: "" } };
	// 					onBlur(e, name);
	// 				}
	// 			}
	// 		);
	// 	}
	// });
	return (
		<div className={`multi-select ${required && error && !value && valid ? "error" : ""}`}>
			<div className={classes.join(" ")}>
				{label}
				{required && <span className="required">&nbsp;*</span>}
			</div>
			<div className="custom-multiselect" ref={inputRef}>
				<ReactMultiSelectCheckboxes
					maxWidth={200}
					width={200}
					isClearable={true}
					placeholderButtonLabel={placeholder}
					options={optionsFormatter}
					value={valueFromatter}
					onChange={inputChangeHandler}
				// onBlur={e => handleInputBlur(e, name)} hideSearch
				/>
				{errorSpan}
			</div>
		</div>
	);
};

export default MultiSelectWithCheckbox;
