import {
    validateFirstName,
    validateLastName,
    validateEmail,
    validateMobileNo,
    validateCompanyName,
    validateOther
} from '../../../dependencies/utils/validation';

const validation = {
    validateContactUsForm: (params) => {
        const { firstName, lastName, email, phone, company, queryData } = params;
        let formStatus = {};
        const firstNameStatus = validateFirstName(firstName);
        const lastNameStatus = validateLastName(lastName);
        const emailStatus = validateEmail(email);
        const phoneStatus = validateMobileNo(phone);
        const companyStatus = validateCompanyName(company);
        const queryDataStatus = validateOther(queryData);

        formStatus = {
            firstName: firstNameStatus.error,
            lastName: lastNameStatus.error,
            email: emailStatus.error,
            phone: phoneStatus.error,
            company : companyStatus.error,
            queryData : queryDataStatus.error,
            hasErrors: (
                firstNameStatus.hasError
                || lastNameStatus.hasError
                || emailStatus.hasError
                || phoneStatus.hasError
                || companyStatus.hasError
                || queryDataStatus.hasError
            )
        };
        return formStatus;
    },

    validateSubscribeForm: (params) => {
        
        const { subscribeemail} = params;
        let formStatus = {};
        const subscribeemailStatus = validateEmail(subscribeemail);

        formStatus = {
            subscribeemail: subscribeemailStatus.error,
            hasErrors: (
                 subscribeemailStatus.hasError
            )
        };
        return formStatus;
    }
};


export default validation;
