import React, { useEffect, useState, useLayoutEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import Slider from "react-slick";
import dummyMissionImage from "../../../../../assests/images/dummy_img.png";
import { Link } from "react-router-dom";
import SocialShare from "../../../../common/SocialShare";
import { Parallax } from "react-scroll-parallax";
import { useController } from "react-scroll-parallax";

const TakeAPledge = (props) => {
  const { parallaxController } = useController();
  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);
  const { item, count, data, SectionId, pledgeCount } = props;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: true,
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [social] = useState({
    url: `${window.location.host}/citizen-corner/${SectionId}`,
    title: data?.heading,
    sepreater: "::",
    size: "2.5rem",
  });
  return (
    <div className="blue-bg take-a-pledge custom-slider-nextPrev">
      <div className="yellow-line" />
      <div className="blue-line" />
      <div className="green-line" />
      <Parallax
        className="dotted-hexagon left"
        y={["-30px", "30px"]}
        tagOuter="div"
      >
        <div className="dotted-hexagon-img " />
      </Parallax>
      <Parallax
        className="dotted-hexagon right"
        y={["30px", "-30px"]}
        tagOuter="div"
      >
        <div className="dotted-hexagon-img" />
      </Parallax>
      <div className="bulb glow" />
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex align-item-center margin-left-auto quiz-header">
            <h2>{ReactHtmlParser(data?.heading)}</h2>
            <div className="citizen-social-share margin-left-auto">
              <SocialShare social={social} />
            </div>
          </div>
        </div>

        <Slider {...settings}>
          {data?.pledge.map((item, index) => {
            return (
              <div className="row">
                <div className="col">
                  <div className="item hover-zoom">
                    <div className="image">
                      <img src={item.Image} alt="" />
                    </div>

                    <h5>{item.Title}</h5>
                    {item.IstheCTAInternalorExternal == "Internal" ? (
                      <Link
                        className="take-pledge-btn"
                        to={`pledge${item.InternalPledgeCTA}`}
                      >
                        <span className="hand-icon"></span>Take Pledge, Now
                      </Link>
                    ) : (
                      <a
                        target="_blank"
                        href={item.ExternalPledgeCTA}
                        className="take-pledge-btn"
                      >
                        <span className="hand-icon"></span>Take Pledge, Now
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="pledge-counter text-center">
          <strong>{pledgeCount}</strong> Pledge Already Taken
        </div>
      </div>
    </div>
  );
};

export default TakeAPledge;
