import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// import applyIcon from "./assets/images/apply.png";
// import loginIcon from "./assets/images/login.png";
// import gethelpIcon from "./assets/images/get-help.png";
// import contactusIcon from "./assets/images/contacts.png";
// import faqsIcon from "./assets/images/faqs.png";
import quickButton from "./assests/images/Quick Action Button.svg";
import "./floatingstyle.scss";
// import ApplytoBecome from './assets/images/get-mentor.png';
import { Container, Button, Link } from "react-floating-action-button";
function FloatingAction() {
  const history = useHistory();
  const [sideShow, setSideShow] = useState(false);
  // const getSlideShow = () => {
  //   setSideShow(!sideShow);
  // };
  return (
    <div>
      <div
        className="top-btn green-btn2 fab-container"
        onClick={() => {
          history.push("/event-listing/swachhta-saarthi-showcase-2022");
        }}
        style={{ display: "none" }}
      >
        Click Here For SSF Samaroh 2022
      </div>
    </div>
  );
}

export default FloatingAction;
