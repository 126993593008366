import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../common/Breadcrumbs";
import arrowPlusIcon from "../../assests/icons/accordian-plus-icon.svg";
import arrowMinusIcon from "../../assests/icons/accordian-minus-icon.svg";
import arrowLeft from "../../assests/icons/arrow-left-tech.png";
import arrowRight from "../../assests/icons/arrow-right-tech.png";
import techLogo1 from "../../assests/images/tech-logo1.png";
import techLogo2 from "../../assests/images/tech-logo2.png";
import techLogo3 from "../../assests/images/tech-logo3.png";
import techLogo4 from "../../assests/images/tech-logo4.png";

import "./style.scss";
import Slider from "react-slick";
import { getPartnerWithUs } from "./dependencies/action";
import ReactHtmlParser from "react-html-parser";


import { initGA, logPageView } from '../../dependencies/utils/analytics'
const items = [
	{ to: "/", label: "/" },
	{ to: "/partner-with-us", label: "Technology Stakeholders" },
];

const PartnerWithUs = (props) => {
	const { getPartnerWithUs, partnerWithUsDetails } = props;
	const { data } = partnerWithUsDetails;
	useEffect(() => {
		getPartnerWithUs();
	}, [getPartnerWithUs]);


    useEffect(() => {
        try {
          initGA()
          window.GA_INITIALIZED = true
          logPageView()
        } catch (error) {
          
        }
        }, [window])
	const [isMoreFilter, setMoreFilter] = useState(false);
	const [id, setId] = useState(null);
	let defaultOpenValue = [];
	if (data && data[0]?.PartnerwithUs.length > 0){
		for (let i = 0; i < data[0]?.PartnerwithUs.length; i++){
			defaultOpenValue.push(false)
		}
	}
	const [isOpen, setIsOpen] = useState(defaultOpenValue)

	const handleShowMoreData = (_id) => {
		if (id !== _id) {
			setId(_id);
		} 
		isOpen[_id] = !isOpen[_id]
		// else {
		// 	setId(null);
		// }
		setMoreFilter(!isMoreFilter);
	};
	const [isMoreData, setFilter] = useState(false);
	const handleShowData = () => setFilter(!isMoreData);
	const [MoreData, setMoreData] = useState(false);
	const handleData = () => setMoreData(!MoreData);
	const [showMore, showMoreData] = useState(false);
	const handleMoreData = () => showMoreData(!showMore);

	const settings = {
		dots: false,
		// infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		rows: 2,
		slidesPerRow: 2,
		infinite: false,
	};
	// console.log('partnerpartner',partner.PartnerLogos)

	return (
		<div className="PartnerWithUs">
			<Breadcrumb data={items} displayName={(data && data.length && data[0].Title) || "Technology Stakeholders"} />
			<div className="partners-with-us-main-content tech-inventors-main">
				<div className="container">
					<div className="row pb80">
						{data && data.length && data[0]?.PartnerwithUs.map((partner, i) => {
							return (
								<React.Fragment>
									<div className="col-lg-6">
										<div className=" partners-card-head">
											<h3>
												{" "}
												<strong>
													{ReactHtmlParser(partner?.Heading)}
												</strong>
											</h3>
										</div>
										<div className=" partners-card">
											{ReactHtmlParser(partner?.Description)}
											<button
												className=" less-more-btn"
												onClick={() => handleShowMoreData(i)}
											>
												
												{isOpen[i] ? "Less" : "More"}{" "}
												{isOpen[i] ? (
													<img src={arrowMinusIcon} alt={""} />
												) : (
													<img src={arrowPlusIcon} alt={""} />
												)}
											</button>
											{isOpen[i] && (
												<div>
													<Slider {...settings}>
														{Array.isArray(partner?.PartnerLogos) && partner?.PartnerLogos.map(
															(logo, logoIndex) => {
																return (
																	<div
																		// className="partner-boxes"
																		key={logoIndex}
																	>
																		<div>
																			<img
																				src={logo}
																				alt={logo}
																			/>
																		</div>
																	</div>
																);
															}
														)}
													</Slider>
												</div>
											)}
										</div>
									</div>
								</React.Fragment>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	partnerWithUsDetails: state.PartnerWithUsReducer.partnerWithUSDetails,
});
export default connect(mapStateToProps, { getPartnerWithUs })(
	withRouter(PartnerWithUs)
);
