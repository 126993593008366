import api from '../../../dependencies/utils/Api';
import {
    POLICY_LISTING,
    POLICY_TYPE,
    POLICY_TECHNOLOGY,
} from "../../../constants/ApplicationUrl";

export const policiyService = {

    getPolicyListing(subtype, type, category) {
        // debugger
        return api.GET(`${POLICY_LISTING}subtype=${subtype}&type=${type}&category=${category}`)
            .then((response) => {

                const { data = { data } = {} } = response.data || {};
                return { data };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getPolicyType() {
        return api.GET(POLICY_TYPE)
            .then((response) => {
                const { data = { data } = {} } = response?.data || {};
                return { data };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getPolicyTechnology() {
        return api.GET(POLICY_TECHNOLOGY)
            .then((response) => {
                const { data = { data } = {} } = response?.data || {};
                return { data };
            }).catch(e => {
                return Promise.reject(e);
            });
    }
}
