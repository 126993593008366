/**
 * This  component use for sumit the contact use call the thir party api by QMS
 */
import { useState, useRef, useEffect } from "react";
import InputField from "../common/InputField";
import TextAreaField from "../common/TextAreaField";
import Button from "../common/Button";
import FileUpload from "../common/FileUpload";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormValidator from "./dependencies/formValidator";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../dependencies/utils/constants";
import InlineError from "../common/InlineError";
import { validateFile } from "../Events/dependencies/action";
import {
  uploadFile,
  submitContactUs,
  subscribeMission,
  contactUsData,
  getAboutUs,
} from "./dependencies/action";
import MeatTeam from "./MeatTeam";
import "./style.scss";
import homeIcon from "../../assests/icons/home.svg";
import emailIcon from "../../assests/icons/emailIcon.svg";
import phoneIcon from "../../assests/svg/phone-icon.svg";
import { toast } from "react-toastify";
import { initGA, logPageView } from "../../dependencies/utils/analytics";

import { UPLOAD_FILE_EVENT2 } from "../../constants/ApplicationUrl";

const ContactUs = (props) => {
  const {
    uploadFile,
    submitContactUs,
    subscribeMission,
    contactUsData,
    contactUsValue,
    validateFile,
    getAboutUs,
    aboutUsData,
  } = props;

  const defaultFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    queryData: "",
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [subscribeemail, setSubscribeemail] = useState("");
  const [errorSubscribeemail, setErrorSubscribeemail] = useState("");
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] =
    useState(false);
  const [hasInvalidSubmitOccurredSub, setHasInvalidSubmitOccurredSub] =
    useState(false);
  const [fileName, setFileName] = useState("");
  const [displayFileName, setDisplayFileName] = useState("");
  const [capthaError, setCapthaError] = useState(false);
  const [capthaError1, setCapthaError1] = useState(false);
  // const [capthaError2, setCapthaError2] = useState(false);
  const [capthaErrorassist, setCapthaError1assist] = useState(true);
  const [removefileButton, setRemoveFileButton] = useState(false);
  const [uploadedFileEvent, setUploadedFileEvent] = useState([]);
  const [filesEvent, setFilesEvent] = useState([]);
  const recaptchaRef1 = useRef(null);
  const recaptchaRef2 = useRef(null);

  const recaptchaRef = useRef(null);

  const validateInput = () => {
    const { firstName, lastName, email, phone, company, queryData } = formData;
    const formStatus = FormValidator.validateContactUsForm({
      firstName,
      lastName,
      email,
      phone,
      company,
      queryData,
    });
    const errors = {
      firstName: formStatus.firstName,
      lastName: formStatus.lastName,
      email: formStatus.email,
      phone: formStatus.phone,
      company: formStatus.company,
      queryData: formStatus.queryData,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };

  useEffect(() => {
    getAboutUs();
  }, []);

  const validateSubscribeInput = () => {
    // debugger
    const formStatus = FormValidator.validateSubscribeForm({
      subscribeemail,
    });
    const errors = {
      subscribeemail: formStatus.subscribeemail,
    };
    setErrorSubscribeemail(errors);
    return !formStatus.hasErrors;
  };
  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };
  const resetCaptcha2 = () => {
    recaptchaRef1.current.reset();
  };

  const onMailSubmit = (e) => {
    e.preventDefault();
    if (!validateSubscribeInput()) {
      setHasInvalidSubmitOccurredSub(true);
    } else {
      const recaptchaValue1 = recaptchaRef1.current.getValue();

      if (!recaptchaValue1) {
        setCapthaError1(true);
      } else {
        const subscribeObject = {
          email: subscribeemail,
          secret: recaptchaValue1,
        };
        setCapthaError1(false);
        subscribeMission(subscribeObject).then((data) => {
          setSubscribeemail("");
        });
      }
    }
  };

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  const onSubmit = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true);
    } else {
      const recaptchaValue = recaptchaRef.current.getValue();
      // const recaptchaValue2 = recaptchaRef2.current.getValue();
      if (recaptchaValue === "") {
        setCapthaError(true);
      } else {
        setCapthaError(false);
        // setCapthaError2(false);
        let file = {};
        let finalArray = uploadedFileEvent.map(function (obj) {
          return `${obj.fileurl}`;
        });

        let reqData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          mobileNumber: formData.phone,
          description: formData.queryData,
          companyName: formData.company,
          file: finalArray && finalArray.length > 0 ? finalArray : [],
          secret: recaptchaValue,
          request_for: "contact_us",
        };

        submitContactUs(reqData).then((data) => {
          if (data.status) {
            setFormData(defaultFormData);
            setFilesEvent([]);
            setFileName("");
            resetCaptcha();
            resetCaptcha2();
          }
        });
      }
    }
  };
  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };

  const handleSubscribeChange = (e, name) => {
    const { value } = e?.target;
    if (value !== undefined) {
      setSubscribeemail(value);
    }
  };
  const onUploadFile = (file, name) => {
    const dataValue = new FormData();
    dataValue.append("campaign", file);

    validateFile(dataValue).then((res) => {
      if (res) {
        if (uploadedFileEvent.length < 3) {
          const data = new FormData();
          data.append("file", file);
          data.append("request_for", "contact_us");
          data.append("field_name", "field_attach_file");
          data.append("attachfile_secret", recaptchaRef2.current.getValue());
          var axios = require("axios");

          var config = {
            method: "post",
            url: UPLOAD_FILE_EVENT2,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              if (response.status === 200) {
                console.log("if");
                let MyDate = new Date();
                let MyDateString;
                setTimeout(() => {
                  recaptchaRef2.current.reset();
                  setCapthaError1assist(true);
                }, 1000);
                MyDateString =
                  MyDate.getFullYear() +
                  "-" +
                  ("0" + (MyDate.getMonth() + 1)).slice(-2);
                // let fileUrl = `/s3fs/${MyDateString}/${response?.data?.file_name}`;
                let fileUrl = `/${MyDateString}/${response?.data?.file_name}`;
                setFilesEvent([...filesEvent, response?.data?.file_name]);
                setUploadedFileEvent([
                  ...uploadedFileEvent,
                  {
                    attachfile: response?.data?.file_name,
                    filename: response?.data?.file_name,
                    fileurl: fileUrl,
                  },
                ]);
              } else {
                console.log("else");
              }
            })
            .catch((e) => {
              toast.error("Something Went Wrong from submit tech index ");
              return { status: false };
            });
          // uploadFile(data).then(res => {
          //     if (res?.status) {
          //         let MyDate = new Date();
          //         let MyDateString;

          //         MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2)
          //         let fileUrl = `/s3fs/s3fs-public/${MyDateString}/${res?.filename}`
          //         setFilesEvent([...filesEvent, res?.filename]);
          //         setUploadedFileEvent([...uploadedFileEvent, { attachfile: res?.attachfile, filename: res?.filename, fileurl: fileUrl }])
          //     } else {

          //         toast.error("something went wrong");
          //     }
          // })
        } else {
          toast.error("You can upload maximum 3 files only");
        }
      }
    });
  };

  const removeFile = (file, type) => {
    let index = filesEvent.indexOf(file);
    if (index > -1) {
      uploadedFileEvent.splice(index, 1);
      filesEvent.splice(index, 1);
      setFilesEvent([...filesEvent]);
      setUploadedFileEvent([...uploadedFileEvent]);
    }
  };
  const handleReset = () => {
    setRemoveFileButton(false);
    setFileName("");
    setDisplayFileName("");
    delete formData.attachfile;
    delete formData.filename;
    delete formData.fileurl;
  };

  const onBlur = (e, name) => {
    validateInput();
    validateSubscribeInput();
  };
  useEffect(() => {
    contactUsData();
  }, []);
  return (
    <div className="contact-us">
      <div className="page-upper-section">
        <div className="main-container align-margin mt13 clearfix">
          <div className="breadcrumb-contact-us">
            <img src={homeIcon} className="home-icon-contact" />
            <div className="breadcrumb-text">Contact Us</div>
            <div className="contact-us-heading">Contact Us</div>
          </div>
          <div className="subscribe-box clearfix">
            <div className="subscribe-box-heading">
              Subscribe to the Mission
            </div>
            <form
              onSubmit={(e) => onMailSubmit(e)}
              style={{ paddingBottom: "15px" }}
            >
              <InputField
                required
                type="text"
                name="subscribeemail"
                placeholder="Enter your email address"
                value={subscribeemail}
                onChange={handleSubscribeChange}
                error={errorSubscribeemail.subscribeemail}
                containerClass={"form-group"}
                onBlur={onBlur}
                hasInvalidSubmitOccurred={setHasInvalidSubmitOccurredSub}
                className="subscribe-input-field"
              />
              <div
                className="captcha-field"
                style={{ marginTop: "-10px", marginBottom: "10px" }}
              >
                <ReCAPTCHA ref={recaptchaRef1} sitekey={SITE_KEY} />
                {capthaError1 && (
                  <div className="inline-error">*{"This is required"}</div>
                )}
              </div>
              <Button
                type="submit"
                text="Subscribe"
                className="subscribe-submit-button"
              />
            </form>
          </div>
        </div>
      </div>
      <div className="form-lower-section">
        <div className="main-container align-margin">
          <div className="row">
            <div className="col-lg-7">
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6">
                    <InputField
                      required
                      type="text"
                      name="firstName"
                      label="First Name"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      error={error.firstName}
                      containerClass={"form-group"}
                      placeholder={"John"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      required
                      type="text"
                      name="lastName"
                      label="Last Name"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      error={error.lastName}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      placeholder={"Smith"}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <InputField
                      required
                      type="text"
                      name="email"
                      label="Email Id"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={error.email}
                      placeholder={"abc@gmail.com"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      required
                      type="text"
                      name="phone"
                      label="Phone Number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      error={error.phone}
                      containerClass={"form-group"}
                      placeholder={"+91-987xxxxx21"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <InputField
                      required
                      type="text"
                      name="company"
                      label="Company Name"
                      value={formData.company}
                      onChange={handleInputChange}
                      error={error.company}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      placeholder={"ABC Industries Pvt. Ltd."}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <TextAreaField
                      required
                      type="text"
                      name="queryData"
                      label="Query Data"
                      value={formData.queryData}
                      onChange={handleInputChange}
                      error={error.queryData}
                      containerClass={"form-group"}
                      placeholder={"Please provide brief description"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field form-text-field"
                    />
                  </div>
                </div>
                <div className="row row-captcha">
                  <div className="col-12 col-lg-5">
                    <div
                      className="captcha-field"
                      style={{ marginTop: "-10px", marginBottom: "10px" }}
                    >
                      <ReCAPTCHA
                        ref={recaptchaRef2}
                        sitekey={SITE_KEY}
                        onChange={() => {
                          setCapthaError1assist(false);
                        }}
                      />
                      {/* {capthaError2 && (
                         <div className="inline-error">
                           *{"This is required"}
                         </div>
                       )} */}
                    </div>
                    <label>(PDF, JPEG, JPG and PNG)</label>
                    <FileUpload
                      htmlFor="uploadFileInput"
                      labelClass="contactUploadFile green-border-btn"
                      onUploadFile={(file) =>
                        onUploadFile(file, "contactUploadFile")
                      }
                      allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      // error={error.fileInfo}
                      // value={fileName}
                      onBlur={onBlur}
                      id="uploadFileInput"
                      visbility="hidden"
                      label=""
                    />
                    {filesEvent.map((file) => (
                      <p key={file}>
                        {file}{" "}
                        <span
                          onClick={() => {
                            removeFile(file, "engagement");
                          }}
                        >
                          X
                        </span>
                      </p>
                    ))}

                    <label
                      htmlFor="uploadFileInput"
                      className="bordered-btn w-auto green-border"
                      style={{
                        pointerEvents:
                          capthaErrorassist === true ? "none" : "auto",
                        borderColor:
                          capthaErrorassist === true ? "lightgrey" : "#2ba749",
                        color:
                          capthaErrorassist === true ? "lightgrey" : "#2ba749",
                      }}
                    >
                      Attach File
                    </label>
                  </div>
                </div>
                <div className="col-12 col-lg-7" style={{ paddingLeft: "0px" }}>
                  <div className="captcha-field">
                    <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
                    {capthaError && (
                      <InlineError message={"This is required"} />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="bttn-group col-lg-12">
                    <Button
                      type="submit"
                      text="Submit"
                      className="form-submit-button"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-5">
              <div className="map-section">
                <span className="map-section-heading">
                  {contactUsValue?.data?.heading}
                </span>
                <p>
                  <img src={emailIcon} className="email-icon " />
                  <span
                    className="contact-text emailtext"
                    style={{ fontSize: "12px" }}
                  >
                    {/* {contactUsValue?.data?.email} */}
                    contact[dot]wastetowealth[at]investindia[dot]org[dot]in
                  </span>
                </p>
                <p>
                  <img
                    src={phoneIcon}
                    className="email-icon contact-text-margin"
                    style={{ marginLeft: "-11px" }}
                  />
                  <span className="contact-text ">
                    {contactUsValue?.data?.phone}
                  </span>
                </p>
                <iframe
                  className="location col-lg-12"
                  src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8330.839695579092!2d${contactUsValue?.data?.latitude}!3d${contactUsValue?.data?.longitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x214e4ef2f44d556e!2sInvest+India!5e0!3m2!1sen!2sin!4v1531930315663`}
                  allowfullscreen
                  frameborder="0"
                  style={{ border: "0" }}
                  width="100%"
                  height="290"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div id="meettheteam">
          <div className="MoreInformation">
            <div className="container row">
              <div className="large-tree" />
              <div className="small-tree" />

              <div class="bin-car"></div>
              <div class="white-leaf"></div>
            </div>
          </div>
          <MeatTeam
            data={aboutUsData?.meet_team?.members}
            heading={aboutUsData?.meet_team?.heading}
            description={aboutUsData?.meet_team?.description}
            count={3}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contactUsValue: state.ContactusReducer.contactusData,
    aboutUsData: state.StaticPageReducer.aboutUs.data,
  };
};
export default connect(mapStateToProps, {
  uploadFile,
  getAboutUs,
  submitContactUs,
  subscribeMission,
  contactUsData,
  validateFile,
})(withRouter(ContactUs));
