import ReactGA from 'react-ga';

// Tracking ID
export const GA_TRACKING_ID = "UA-195288825-1";

// Initialse the google analytics
export const initGA = () => {
    ReactGA.initialize(GA_TRACKING_ID)
}

// number of users visite on this site
export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
}
export const logEvent = (category = '', action = '', label = '') => {
    if (category && action) {
        ReactGA.event({ category, action, label })
    }
}
export const logException = (description = '', fatal = false) => {
    if (description) {
        ReactGA.exception({ description, fatal })
    }
}