import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InlineError from '../InlineError';
import './style.scss';


const GlobalDropDown = (props) => {

    const {
        containerStyle,
        label,
        required,
        options,
        onChange,
        selectTagStyle,
        name,
        value,
        onBlur,
        hasInvalidSubmitOccurred,
        error,
        disabled,
        containerClass,
        defaultOptionText,
        defaultOptionDisable,
        ...rest
    } = props;

    const [touched, setTouched] = useState(false);

    const handleBlur = (e, name) => {
        setTouched(true);
        if (onBlur) {
            onBlur(e, name);
        }
    };
    const truncate = (str) => str && str.length > 60 ? `${str.substring(0, 59)}...` : str;

    const displayError = touched || hasInvalidSubmitOccurred;

    return (
        <div className={`global-select ${containerStyle} ${containerClass} ${displayError && error ? 'error' : ''}`}>
            {
                label &&
                <label
                    htmlFor=""
                >
                    {label}
                    {
                        required
                        && (
                            <span className="required">&nbsp;*</span>
                        )
                    }
                </label>}
            <select
                name={name}
                className={selectTagStyle}
                onChange={e => onChange(e, name)}
                onBlur={e => handleBlur(e, name)}
                value={value ? value : ''}
                disabled={disabled}
                {...rest}
            >
                {!defaultOptionDisable &&
                    <option value=''>{defaultOptionText ? defaultOptionText : 'Select'}</option>
                }

                {
                    options
                    && options.length > 0
                    && options.map((val, index) => {
                        return (
                            <option className="select-options" title={val.text} value={val.value} key={label + index}>{truncate(val.text)}</option>
                        )
                    })
                }
            </select>
            {
                displayError && error && (
                    <InlineError
                        message={error}
                    />
                )
            }
        </div>
    )
}

GlobalDropDown.propTypes = {
    value: PropTypes.any,
    error: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    hasInvalidSubmitOccurred: PropTypes.bool,
    containerStyle: PropTypes.string,
    selectTagStyle: PropTypes.string,
    defaultOptionDisable: PropTypes.bool
};

GlobalDropDown.defaultProps = {
    disabled: false,
    value: '',
    error: '',
    onChange: () => { },
    label: '',
    required: false,
    name: '',
    containerStyle: '',
    onBlur: () => { },
    hasInvalidSubmitOccurred: false,
    selectTagStyle: '',
    defaultOptionDisable: false
};
export default GlobalDropDown;