import { ChallengeService } from './service';
import {
    CHALLANGE_EVENT_LISTING,
    CHALLANGE_EVENT_DETAILS,
    CHALLENGE_TECHNOLOGY,
    CHALLENGE_CATEGORY
} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';

export function getChallengeEventList(page, categoryId, search,sortBy) {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let challengeEventList = await ChallengeService.getChallengeEventList(page, categoryId, search,sortBy);
        dispatch({
            type: CHALLANGE_EVENT_LISTING,
            payload: challengeEventList
        })
        dispatch(isLoading(false))
    }
}
export function getChallengeEventDetails(url) {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let challengeEventDetails = await ChallengeService.getChallengeEventDetails(url);
        dispatch({
            type: CHALLANGE_EVENT_DETAILS,
            payload: challengeEventDetails
        })
        dispatch(isLoading(false))
    }
}

export function getChallengeTechnologyList(url) {
    return async function (dispatch) {
        let challengeTechnology = await ChallengeService.getChallengeTechnology();
        dispatch({
            type: CHALLENGE_TECHNOLOGY,
            payload: challengeTechnology
        })
    }
}

export function getChallengeCategoryList() {
    return async function (dispatch) {
        let challengeCategory = await ChallengeService.getChallengeCategory();
        dispatch({
            type: CHALLENGE_CATEGORY,
            payload: challengeCategory
        })
    }
}