import React, { useState, useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import Slider from "react-slick";
import ImageModal from "../ImageModal";
import dummyMissionImage from "../../../../../assests/images/main-logo.png";

// import { findFlagUrlByIso2Code } from "country-flags-svg";
const TechnologyIntervention = (props) => {
  // const { item, count, data, SectionId } = props;
  console.log(props);
  const { data, country, flagurl } = props;
  const [img, setimg] = useState("");
  const [show, setShow] = useState(false);
  const [imgHeight, setImgHeight] = useState();
  const [imgWidth, setImgWidth] = useState();
  const [imgRecognizedHeight, setImgRecognizedHeight] = useState();
  const [imgRecognizedWidth, setImgRecognizedWidth] = useState();
  const orgimg = useRef();
  const recognizedimg = useRef();

  // const [countryCode, setCountryCode] = useState(data?.country?.country_code);
  // console.log(data?.country?.country_code);
  // let flagPic = findFlagUrlByIso2Code(data?.country?.country_code?data?.country?.country_code:"IN");
  // alert(flagurl)
  // useEffect(() => {
  //     setTimeout( function() {
  //       setCountryCode(data?.country?.country_code)
  //   }, 500 );

  //   })

  // console.log(flagPic)
  return (
    <div className="want-to-fellow-bg">
      <div className="container"></div>
      <div className="mt20 align_center">
        <div
          class="row container"
          style={{
            width: "100%",
            marginTop: 15,
          }}
        >
          <div
            class="col-sm-12 col-md-6 col-lg-3"
            style={{ paddingRight: 0, paddingLeft: 0, marginTop: "20px" }}
          >
            <div className="card_validate_tech">
              <div>
                <div className="heading-align-center">
                  <span className="validated_tech_details">
                    Technology Name
                  </span>
                </div>
                <div className="technology-box-main">
                  <div className="box-align-center">
                    <span>{data?.tech_name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data?.tech_thermatic_area &&
            data?.tech_thermatic_area.length > 0 &&
            data?.tech_thermatic_area.map((item, index) => (
              <div
                class="col-sm-12 col-md-6 col-lg-3"
                style={{ paddingRight: 0, paddingLeft: 0, marginTop: "20px" }}
                key={index}
              >
                <div className="card_validate_tech">
                  <div>
                    <div className="heading-align-center">
                      <span className="validated_tech_details">
                        Technology Thematic Area
                      </span>
                    </div>

                    <div className="technology-box">
                      <div className="thematic-image-align-center">
                        <img
                          style={{
                            width: "auto",
                            height: "100px",
                            justifyContent: "center",
                            margin: "5px",
                          }}
                          // src={"https://uat.wastetowealth.gov.in/s3fs/2022-03/Agri%20Waste.png"}

                          src={item?.image}
                          alt="..."
                          className="validated_tech_image"
                        />
                      </div>

                      <div className="heading-align-center">
                        <span className="validated_tech_details">
                          {item.label}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div
            class="col-sm-12 col-md-6 col-lg-3"
            style={{ paddingRight: 0, paddingLeft: 0, marginTop: "20px" }}
          >
            <div className="card_validate_tech">
              <div style={{ textAlign: "center" }}>
                <div className="heading-align-center">
                  <span className="validated_tech_details">
                    Company/Institution/ Organization
                  </span>
                </div>
                <div className="technology-box">
                  <div className="thematic-image-align-center">
                    <div className="white-logo-box">
                      <img
                        ref={orgimg}
                        onLoad={() => {
                          console.log("width", orgimg.current.naturalWidth);
                          setImgWidth(orgimg.current.naturalWidth);
                          console.log("height", orgimg.current.naturalHeight);
                          setImgHeight(orgimg.current.naturalHeight);
                        }}
                        style={{
                          width: imgWidth > imgHeight ? "70%" : "auto",
                          height:
                            imgHeight > imgWidth
                              ? "100%"
                              : imgHeight == imgWidth
                              ? "100%"
                              : "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "5px",
                          objectFit: "cover",
                        }}
                        // src={
                        //   "https://uat.wastetowealth.gov.in/s3fs/2022-03/unnamed_%281%2911.png"
                        // }
                        src={data?.org_icon}
                        // src={
                        //   "https://www.wastetowealth.gov.in/s3fs/2022-03/image001_64.png"
                        // }
                        // src={item?.image}
                        alt="..."
                        className="validated_tech_image mobileimgresponsive"
                      />
                    </div>
                  </div>

                  <div className="heading-align-center">
                    <span className="validated_tech_details_company">
                      {data?.org_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div
            class="col-sm-12 col-md-6 col-lg-3"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <div
              class="card"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <p className="validated_tech_details">Recognized by</p>
                <div
                  className="well"
                  style={{
                    height: "140px",
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#183b63",
                  }}
                >
                  {data?.recognized ? (
                    <div>
                      <img
                        style={{
                          width: "50%",
                          height: "auto",
                        }}
                        src={data?.recognized && data?.recognized.icon}
                        alt="..."
                        className="validated_tech_image"
                      />
                      <div style={{ height: "100px" }}>
                        {data?.recognized.name}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        */}

          <div
            class="col-sm-12 col-md-6 col-lg-3"
            style={{ paddingRight: 0, paddingLeft: 0, marginTop: "20px" }}
          >
            <div className="card_validate_tech">
              <div style={{ textAlign: "center" }}>
                <div className="heading-align-center">
                  <span className="validated_tech_details">
                    Technology Readiness Level
                  </span>
                </div>

                <div className="technology-box-main">
                  <div className="box-align-center">
                    <span>{data?.readiness_level}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data?.recognized.name && (
            <div
              class="col-sm-12 col-md-6 col-lg-3"
              style={{ paddingRight: 0, paddingLeft: 0, marginTop: "20px" }}
            >
              <div className="card_validate_tech">
                <div style={{ textAlign: "center" }}>
                  <div className="heading-align-center">
                    <span className="validated_tech_details">
                      Recognized by
                    </span>
                  </div>
                  <div className="technology-box">
                    <div className="thematic-image-align-center">
                      <div className="white-logo-box">
                        <img
                          ref={recognizedimg}
                          onLoad={() => {
                            console.log(
                              "width",
                              recognizedimg.current.naturalWidth
                            );
                            setImgRecognizedWidth(
                              recognizedimg.current.naturalWidth
                            );
                            console.log(
                              "height",
                              recognizedimg.current.naturalHeight
                            );
                            setImgRecognizedHeight(
                              recognizedimg.current.naturalHeight
                            );
                          }}
                          style={{
                            width:
                              imgRecognizedWidth > imgRecognizedHeight
                                ? "75%"
                                : "auto",
                            height:
                              imgRecognizedHeight > imgRecognizedWidth
                                ? "100%"
                                : imgRecognizedHeight == imgRecognizedWidth
                                ? "100%"
                                : "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "5px",
                            objectFit: "cover",
                          }}
                          //  src={ "https://uat.wastetowealth.gov.in/s3fs/2022-03/Partners%201.jpg"}
                          src={data?.recognized.icon}
                          // src={dummyMissionImage}
                          alt="..."
                          className="validated_tech_image"
                        />
                      </div>
                    </div>

                    <div className="heading-align-center">
                      <span className="validated_tech_details_company">
                        {data?.recognized.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {flagurl && (
            <div
              class="col-sm-12 col-md-6 col-lg-3"
              style={{ paddingRight: 0, paddingLeft: 0, marginTop: "20px" }}
            >
              <div className="card_validate_tech">
                <div style={{ textAlign: "center" }}>
                  <div className="heading-align-center">
                    <span className="validated_tech_details">
                      Country of Origin
                    </span>
                  </div>
                  <div className="technology-box">
                    <div className="thematic-image-align-center">
                      <div className="white-logo-box">
                        <img
                          className="validated_tech_image"
                          alt=".."
                          src={
                            // "http://purecatamphetamine.github.io/country-flag-icons/3x2/"+data?.country?.country_code+".svg"
                            // `https://purecatamphetamine.github.io/country-flag-icons/3x2/${data?.country?.country_code}.svg`
                            // findFlagUrlByIso2Code(countryCode?countryCode:"IN")
                            flagurl
                          }
                        />
                        {/* <img
                      style={{ width: "100px", height: "auto" ,alignItems:"center",justifyContent: 'center', margin: "5px" }}
                      //  src={ "https://uat.wastetowealth.gov.in/s3fs/2022-03/Partners%201.jpg"}
                      src={data?.flag}
                      // src={item?.image}
                      alt="..."
                      className="validated_tech_image"
                    /> */}
                      </div>
                    </div>

                    <div className="heading-align-center">
                      <span className="validated_tech_details_company">
                        {data?.country?.country_name
                          ? data?.country?.country_name
                          : data?.country?.country}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TechnologyIntervention;
