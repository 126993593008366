import React from "react";
import "./style.scss";
import placeholder from "../../../assests/images/placeholder_author.png";

const ResultImageCard = (props) => {
  const { imgUrl, imgCaption, item } = props;
  console.log("item result card", item);
  let nameTitle = imgCaption.split("(");
  return (
    // <div className={`resutl-image-card new ${imgUrl ? "" : "without-image"}`}>
    <div className={`resutl-image-card new without-image`}>
      {/* {imgUrl && <img src={imgUrl} />} */}

      {nameTitle && nameTitle.length > 0 && (
        <span className="img-caption" title={nameTitle[0]}>
          {imgUrl && imgUrl != "" && imgUrl != null ? (
            <img src={`${imgUrl}`} />
          ) : (
            <img src={`${placeholder}`} />
          )}
          {nameTitle[0]} <br />{" "}
          {nameTitle[1] ? `(${nameTitle[1]}` : nameTitle[1]}
          <br /> {item?.gender && item?.gender}
          <br /> {item?.ssf_number && item?.ssf_number}
          <br /> {item?.category && item?.category}
          <br />{" "}
        </span>
      )}
    </div>
  );
};

export default ResultImageCard;
