import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import { getAnalytics } from './dependencies/action';
import submitIcon from '../../assests/icons/submit-blue.svg'
import mailIcon from '../../assests/icons/mail-pink.svg'
import applicationIcon from '../../assests/svg/ic_message.svg'
import Breadcrumb from "../common/Breadcrumbs";
import { Chart } from "react-google-charts";
import './style.scss';



import { initGA, logPageView } from '../../dependencies/utils/analytics'
const items = [
    { to: '/', label: '/' },
    { to: '/analytics-dashboard', label: 'AnalyticsDashboard' },
];

const AnalyticsDashboard = (props) => {

    const { getAnalytics, analyticsData : { list } } = props;

    useEffect(() => {
        getAnalytics();
    },[]);

    const getGraphData = () => {
        let graphData = [['Activiites', 'Published', 'Unpublished']];
        if(list) {
            if(list.ActivitiesAnalytics['Fellowships']) { 
                graphData.push(['Fellowships', parseInt(list.ActivitiesAnalytics['Fellowships'].Published), list.ActivitiesAnalytics['Fellowships'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Fellowships'].Unpublished) : 0]) 
            }

            if(list.ActivitiesAnalytics['Current Interventions']) { 
                graphData.push(['Current Interventions', parseInt(list.ActivitiesAnalytics['Current Interventions'].Published), list.ActivitiesAnalytics['Current Interventions'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Current Interventions'].Unpublished) : 0]) 
            }

            if(list.ActivitiesAnalytics['Upcoming Interventions']) { 
                graphData.push(['Upcoming Interventions', parseInt(list.ActivitiesAnalytics['Upcoming Interventions'].Published), list.ActivitiesAnalytics['Upcoming Interventions'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Upcoming Interventions'].Unpublished) : 0]) 
            }

            if(list.ActivitiesAnalytics['Interventions']) { 
                graphData.push(['Interventions', parseInt(list.ActivitiesAnalytics['Interventions'].Published), list.ActivitiesAnalytics['Interventions'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Interventions'].Unpublished) : 0]) 
            }

            if(list.ActivitiesAnalytics['Success Stories']) { 
                graphData.push(['Success Stories', parseInt(list.ActivitiesAnalytics['Success Stories'].Published), list.ActivitiesAnalytics['Success Stories'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Success Stories'].Unpublished) : 0]) 
            }

            if(list.ActivitiesAnalytics['Events']) { 
                graphData.push(['Events', parseInt(list.ActivitiesAnalytics['Events'].Published), list.ActivitiesAnalytics['Events'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Events'].Unpublished) : 0]) 
            }

            if(list.ActivitiesAnalytics['Challenges']) { 
                graphData.push(['Challenges', parseInt(list.ActivitiesAnalytics['Challenges'].Published), list.ActivitiesAnalytics['Challenges'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Challenges'].Unpublished) : 0]) 
            }

            if(list.ActivitiesAnalytics['Knowledge Management']) { 
                graphData.push(['Knowledge Management', parseInt(list.ActivitiesAnalytics['Knowledge Management'].Published), list.ActivitiesAnalytics['Knowledge Management'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Knowledge Management'].Unpublished) : 0]) 
            }
        }
     
        return graphData;
    }

    useEffect(() => {
        try {
          initGA()
          window.GA_INITIALIZED = true
          logPageView()
        } catch (error) {
          
        }
        }, [window])

    const getPieData = () => {
        let pieData = [['Activity', 'Contribution']];
        if(list) {
            
            if(list.ActivitiesAnalytics['Upcoming Interventions']) { 
                pieData.push(['Upcoming Interventions', parseInt(list.ActivitiesAnalytics['Upcoming Interventions'].Published) + (list.ActivitiesAnalytics['Upcoming Interventions'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Upcoming Interventions'].Unpublished) : 0)]) 
            }

            if(list.ActivitiesAnalytics['Current Interventions']) { 
                pieData.push(['Current Interventions', parseInt(list.ActivitiesAnalytics['Current Interventions'].Published) + (list.ActivitiesAnalytics['Current Interventions'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Current Interventions'].Unpublished) : 0)]) 
            }

            if(list.ActivitiesAnalytics['Interventions']) { 
                pieData.push(['Interventions', parseInt(list.ActivitiesAnalytics['Interventions'].Published) + (list.ActivitiesAnalytics['Interventions'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Interventions'].Unpublished) : 0)]) 
            }

            if(list.ActivitiesAnalytics['Success Stories']) { 
                pieData.push(['Success Stories', parseInt(list.ActivitiesAnalytics['Success Stories'].Published) + (list.ActivitiesAnalytics['Success Stories'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Success Stories'].Unpublished) : 0)]) 
            }

            if(list.ActivitiesAnalytics['Fellowships']) { 
                pieData.push(['Fellowships', parseInt(list.ActivitiesAnalytics['Fellowships'].Published) + (list.ActivitiesAnalytics['Fellowships'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Fellowships'].Unpublished) : 0)]) 
            }

            if(list.ActivitiesAnalytics['Events']) { 
                pieData.push(['Events', parseInt(list.ActivitiesAnalytics['Events'].Published) + (list.ActivitiesAnalytics['Events'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Events'].Unpublished) : 0)]) 
            }

            if(list.ActivitiesAnalytics['Challenges']) { 
                pieData.push(['Challenges', parseInt(list.ActivitiesAnalytics['Challenges'].Published) + (list.ActivitiesAnalytics['Challenges'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Challenges'].Unpublished) : 0)]) 
            }

            if(list.ActivitiesAnalytics['Knowledge Management']) { 
                pieData.push(['Knowledge Management', parseInt(list.ActivitiesAnalytics['Knowledge Management'].Published) + (list.ActivitiesAnalytics['Knowledge Management'].Unpublished ?  parseInt(list.ActivitiesAnalytics['Knowledge Management'].Unpublished) : 0)]) 
            }
        }
      
        return pieData;
    }

    return (
        <div className="analytics-dashboard">
            <Breadcrumb data={items} displayName={'W2W Analytics Dashboard'} />
            <div className="bottom-section-list">
                <div className="container"> 
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="activities-analytics">
                                <div className="row graph-header p-4">
                                    <h3>Activities Analytics</h3>
                                </div>
                                <Chart
                                    width={"100%"}
                                    height={400}
                                    chartType="ColumnChart"
                                    loader={<div>Loading Chart</div>}
                                    data={getGraphData()}
                                    options={{
                                    // title: 'Activities Analytics',
                                    titleTextStyle: {
                                        fontName: 'Chivo',
                                        fontSize: 19,
                                    },
                                    fontSize: 13,
                                    chartArea: { width: '90%' },
                                    hAxis: {
                                        minValue: 0,
                                    },
                                    vAxis: {
                                        maxValue: 200,
                                        minValue: 0,
                                    },
                                    legend: {
                                        position: 'top',
                                        alignment: 'end',
                                        labels: {
                                            usePointStyle: true,
                                          },
                                    },
                                    tooltip: {
                                        showColorCode: true,
                                    },
                                    bar: {
                                        groupWidth: '35%',
                                    },
                                    colors: ['#7a55af', '#aae5b7'],
                                    }}
                                    legendToggle
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 submissions-report">
                        <div className="col-6 pl-4">
                            <div className="activity-distribution">
                                <div className="row pie-chart-header">
                                    <h3>Activity Distribution</h3>
                                    <span className="count">{list?.ActivityDistribution}</span>
                                </div>
                                <Chart
                                    width={'100%'}
                                    height={'283px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={getPieData()}
                                    options={{
                                        // title: 'Activity Distribution',
                                        titleTextStyle: {
                                            fontSize: 20,
                                        },
                                        chartArea: { 
                                            // left: '5%',
                                            // top: '30%',
                                            height: 230,
                                            width: '95%', 
                                        },
                                        legend: {
                                            position: 'labeled',
                                            maxLines: 1,
                                            textStyle: {
                                                fontSize: 13,
                                                bold: true,
                                            },
                                        },
                                        colors: ['#345ac3', '#fcb747', '#4dd1e3', '#ffa30d', '#6ede87', '#9e91ff', '#ff6c77'],
                                        pieHole: 0.4,
                                        sliceVisibilityThreshold :0,
                                        pieSliceText: 'none',
                                    }}
                                    legendToggle
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                        <div className="col-6 pl-4">
                            <div className="col-12 report-title">
                                <h3>Submissions Report</h3>
                            </div>
                            <div className="col-12 submit-technologies mb-3">
                                <div className="round-icon blue"><img src={submitIcon} alt=""/></div>
                                <h3 className="">Submit Technologies</h3>
                                <span className="count">{list?.SubmissionsReport['Submit Technologies']}</span>
                            </div>
                            <div className="col-12 contact-us-queries pl-3 mb-3">
                                <div className="round-icon pink"><img src={mailIcon} alt=""/></div>
                                <h3 className="">Contact Us Queries</h3>
                                <span className="count">{list?.SubmissionsReport['Contact Us Queries']}</span>
                            </div>
                            <div className="col-12 contact-us-queries pl-3">
                                <div className="round-icon blue"><img src={applicationIcon} alt=""/></div>
                                <h3 className="">Fellowship Applications</h3>
                                <span className="count">{list?.SubmissionsReport['Fellowship Applications']}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        analyticsData: state.AnalyticsDashboardReducer.analyticsData,
    }
};

const mapDispatchToProps = (dispatch) => ({
	getAnalytics: bindActionCreators(getAnalytics, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalyticsDashboard));