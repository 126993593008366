/**
 * This  component use for to display the recognise fellow
 */
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import { Link } from 'react-router-dom'

import './style.scss'
import MentorList from '../MentorList'
import Breadcrumb from '../../../../../common/Breadcrumbs'

import { initGA, logPageView } from '../../../../../../dependencies/utils/analytics'

const ViewAllMentors = props => {
  const items = [
    { to: '/', label: '/' },
    { to: '/fellowship-home', label: 'Fellowship' },
    // { to: '/view-all-mentor', label: 'About Mentor', active: false },
  ]
  const [val, setval] = useState(
    props.location.state.results ? props.location.state.results : ''
  )

  useEffect(() => {
    try {
      initGA()
      window.GA_INITIALIZED = true
      logPageView()
    } catch (error) {
      
    }
    }, [window])
  return (
    <div className="analytics-dashboard">
      <Breadcrumb data={items} displayName={'About Mentors'} />
      <div className="Mentor-list">
        <div className="discussion-right-block">
          <div className="view-all-wrap d-flex justify-content-start mr-left20">
            <Link
              className=" align-items-center green-circle-link"
              to={{ pathname: '/fellowship-home' }}
            >
              <i className="back-circle-goto-link-green" /> <p>Back</p>
            </Link>
          </div>

          <MentorList data={val} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    val: state?.val?.val,
  }
}

export default connect(mapStateToProps)(withRouter(ViewAllMentors))
