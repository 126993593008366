import api from '../../../dependencies/utils/Api';
import {
    CITEZEN_CORNER,
    NUMBER_OF_VISITORS
} from "../../../constants/ApplicationUrl";

export const CitizenCornerServices = {
    getCitizenCorner() {
        return api.GET(CITEZEN_CORNER)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getNumberOfVistiors() {
        return api.GET(NUMBER_OF_VISITORS)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
}