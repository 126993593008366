import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumbs";

import Button from '../../common/Button';
import banner from "../../../assests/images/pledge-banner-dummy.png"
import "../style.scss";
import * as DomParser from "dom-parser";
import InlineError from '../../common/InlineError';

import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from '../../../dependencies/utils/constants';

import { initGA, logPageView } from '../../../dependencies/utils/analytics'
const items = [
    { to: "/", label: "/" },
    { to: "/validated-technologies-listing", label: "Knowledge Repository" },
    {
        to: "/validated-technologies-listing",
        label: "Validated Technologies",
        active: true,
    },
];


const ReadPledge = props => {
    const { url, numberOfVistiors } = useParams()

    const { onClickForm, onClickReadAgain, bannerImage, readPagePledgeDesc, back } = props;
    const [htmlData, setHtmlData] = useState('')
    const [redirect, setRedirect] = useState(false);
    const [capthaError, setCapthaError] = useState(false);

    useEffect(() => {
        setHtmlData(readPagePledgeDesc)
        window.scrollTo(0, 0)
    }, [readPagePledgeDesc])
    let parser = new DomParser();
    let dom = parser.parseFromString(htmlData);
    let listData = dom.getElementsByTagName('LI');
    const readAgain = async () => {
        await setHtmlData('')
        await setHtmlData(readPagePledgeDesc)
        window.scrollTo(0, 0)
    }
    const recaptchaRef = useRef(null);
    window.addEventListener("DOMContentLoaded", function (event) {
        setRedirect(true)
    });

    useEffect(() => {
        try {
          initGA()
          window.GA_INITIALIZED = true
          logPageView()
        } catch (error) {
          
        }
        }, [window])
    const redirectUrl = () => {
        return <Redirect to={`/pledge/${url}`} />
    }
    const finalSubmitForm = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue === '') {
            setCapthaError(true)
        } else {
            setCapthaError(false)
            onClickForm(recaptchaValue)
        }
    }
    return (
        <div className="pledge-outer read-pledge">
            { redirect && redirectUrl()}
            <div className="container">
                <div className="pledge-inner">
                    <div className="banner-wrap">
                        <img src={bannerImage} />
                    </div>
                    <div className='content'>
                        <button onClick={back} className="green-btn back-button">Edit details</button>
                        <ul className="bullet-list">
                            {
                                listData.map((item, ind) => {
                                    let animationDelay = 0.5;
                                    animationDelay = 0.5 + ind / 10
                                    // let listStyle = { "animation-delay": (0.7 + ind / 10) + "s" };
                                    return (
                                        <li className="" >
                                            {listData[ind]?.innerHTML.split("").map(function (char, index) {
                                                // const style = { "animation-delay": (ind + (index - 1) / 10) + "s" };
                                                return <span
                                                    aria-hidden="true"
                                                    key={index}
                                                >
                                                    {char}
                                                </span>;
                                            })}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className='col-12 col-lg-7'>
                            <div className="captcha-field">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={SITE_KEY}
                                />
                                {
                                    capthaError && (
                                        <InlineError message={'This is required'} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="footer-btn-wrap d-flex">
                        <Link to="" className="bordered-btn green-border">I Can Not Take This Pledge</Link>
                        <a
                            onClick={() => { finalSubmitForm() }}
                            className='green-btn'
                        >I Have Read The Pledge</a>
                        {/* <Link to={'/pledge/pledge-form/waste-segregation-pledge'} className="green-btn">I Need to Read The Pledge Again</Link> */}
                        <a
                            onClick={readAgain}
                            className='green-btn'
                        >I Need to Read The Pledge Again</a>
                    </div>
                </div>
                <div className="count-wrap d-flex align-item-center justify-content-center">
                    <div className="call-no">
                        <span className="phoneCal-icon"></span>
                        <span className="number">{numberOfVistiors}</span>Visitors on this portal
                </div>
                </div>
            </div>
        </div>
    )
}
export default ReadPledge;