import { SuccessStoryService } from './service';
import {
    SUCCESS_STORIES_LIST,
    SUCCESS_STORIES_TECNOLOGY,
    SUCCESS_STORIES_DETAILS,
    SUCCESS_STORIES_FILTER_DATA
} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';

export function getSuccessStoriesList(page, categoryId, city, search,usetype,subtheme,scale,condition,state,duration) {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let successStoryList = await SuccessStoryService.getSuccessStoryList(page, categoryId, city, search,usetype,subtheme,scale,condition,state,duration);
        dispatch({
            type: SUCCESS_STORIES_LIST,
            payload: successStoryList
        })
        dispatch(isLoading(false))
    }
}

export function getTechnologyType() {
    return async function (dispatch) {
        let technologyType = await SuccessStoryService.getTechnologyType();
        dispatch({
            type: SUCCESS_STORIES_TECNOLOGY,
            payload: technologyType
        })
    }
}



export function getSuccessStoriesDetails(url) {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let successStoryDetails = await SuccessStoryService.getSuccessStoryDetails(url);
        
        dispatch({
            type: SUCCESS_STORIES_DETAILS,
            payload: successStoryDetails
        })
        dispatch(isLoading(false))
    }
}

export function getFilterData() {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let filterData = await SuccessStoryService.filterData();
        dispatch({
            type: SUCCESS_STORIES_FILTER_DATA,
            payload: filterData
        })
        dispatch(isLoading(false))
    }
}