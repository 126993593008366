import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../common/Breadcrumbs";
import ActAndPolicy from "./Acts&Policies";
import CentralGuideline from "./CentralGuidelines";
import {
  getPolicyType,
  getPolicyList,
  getPolicyTechnology,
} from "./dependencies/action";
import Checkbox from "../common/FormElements/Checkbox";
import LeftSideCategory from "../common/LeftSideCategory";

import { initGA, logPageView } from "../../dependencies/utils/analytics";

import "./style.scss";

const items = [
  { to: "/", label: "/" },
  { to: "/policies", label: "Resources" },
  { to: "/policies", label: "Policies", active: true },
];

const Policies = (props) => {
  const [subtype, setSubtype] = useState("acts");
  const [categoryId, setCategory] = useState("");
  const [type, setType] = useState(null);
  const {
    getPolicyType,
    policyType,
    getPolicyList,
    policyListData,
    getPolicyTechnology,
    policyTechnology,
  } = props;
  useEffect(() => {
    getPolicyType();
    getPolicyTechnology();
  }, []);

  const getPolicyId = (id, type) => {
    if (type === 0) {
      setSubtype("acts");
    } else {
      setSubtype("");
    }
    setType(id);
  };

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);

  useEffect(() => {
    if (type) {
      getPolicyList(subtype, type, categoryId);
    }
  }, [type, subtype, categoryId]);

  useEffect(() => {
    if (policyType?.list?.length > 0) {
      setType(policyType?.list[0].id);
    }
  }, [policyType]);

  const handleChange = (data) => {
    setSubtype(data);
  };
  const getCategoryId = (Id) => {
    setCategory(Id);
  };
  return (
    <div className="policies">
      <Breadcrumb data={items} displayName={"Policies"} />
      <div className="policies-main-container">
        {/*    <div className="tab-row shadow">
                    <ul className="tab-container nav nav-tabs">
                        {
                            policyType
                            && Array.isArray(policyType?.list)
                            && policyType?.list.length > 0
                            && policyType?.list.map((value, index) => {
                                return <li onClick={() => getPolicyId(value?.id,index)} className="tab tab-active policies-act nav-item"><a href='javascript:void(0)' className={`nav-link ${index === 0 && 'active'}`} data-toggle="tab">{value?.name} <span>({value?.count})</span></a></li>
                            })
                        }
                    </ul>
                </div> */}
        <div className="container common-listing">
          <div className="row">
            <div className="col-md-3 sidebar">
              <div className="category-box">
                <h3 className="sub-title f19">{policyTechnology?.heading}</h3>
                <ul>
                  {policyTechnology &&
                    Array.isArray(policyTechnology?.list) &&
                    policyTechnology?.list?.length > 0 &&
                    policyTechnology?.list.map((value, index) => {
                      let Id = value?.id;
                      return (
                        <li>
                          <LeftSideCategory
                            onClickReply={() => {
                              getCategoryId(Id);
                            }}
                            className={
                              value?.id === categoryId ? "category-active" : ""
                            }
                            categoryName={value?.name}
                            count={value?.count}
                            key={index}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-md-9 list-container">
              <ActAndPolicy
                subtype={subtype}
                data={policyListData}
                onClickTab={handleChange}
              />
              {/* <CentralGuideline /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    policyType: state?.PolicyReducer?.policyType?.data,
    policyListData: state?.PolicyReducer?.policyListData?.data,
    policyTechnology: state?.PolicyReducer?.policyTechnology?.data,
  };
};
export default connect(mapStateToProps, {
  getPolicyType,
  getPolicyList,
  getPolicyTechnology,
})(withRouter(Policies));
