import React, { useEffect, useState } from "react";

import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const DeploymentDetails = (props) => {
  const { data } = props;
  const [stateName, setStateName] = useState("Maharashtra");

  useEffect(() => {
    console.log("deploy details", data?.deployment_details);
  }, []);

  const deployData = [
    {
      id: 1,
      client: "Lorem Ipsum dolor",
      location: "Mumbai, Maharashtra",
      capacity: "Lorem Ipsum dolor",
    },
    {
      id: 2,
      client: "Lorem Ipsum dolor",
      location: "Tokyo, Japan",
      capacity: "Lorem Ipsum dolor",
    },
    {
      id: 3,
      client: "Lorem Ipsum dolor",
      location: "Osaka, Japan",
      capacity: "Lorem Ipsum dolor",
    },
  ];

  return (
    <div>
         {data?.deployment_details.length>0 ?
    <div className="SalientFeatures2">
   
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="validatedTechnologies-heading contact-heading">
              Deployment Details
            </div>
          </div>
          <div className="row phase2innerdiv7" style={{ width: "100%" }}>
            {data?.deployment_details.map((item, index) => (
              <div
                class="col-sm-12 col-md-12 col-lg-12 innerdiv7col "
                // key={index}
              >
                {item?.name !== null ? (
                  <div class="card innerdiv7card">
                    <div className="divcenter">
                      <div className="well innerdiv7innerdiv2">
                        <div className="row innerdiv7row">
                          <div class="col-sm-12 col-md-4 col-lg-4 innerdiv7rowcol">
                            <div class="card innerdiv7rowcolcard">
                              <div className="linestyle1">Client</div>
                              <div className="linestyle2">{item?.name}</div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-4 col-lg-6 innerdiv7rowcol">
                            <div class="card innerdiv7rowcolcard">
                              <div className="linestyle1">Location</div>
                              <div className="linestyle2">{`${item?.city}, ${item?.state?item?.state:stateName} ${item?.country?", " +item?.country:""}`}</div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-4 col-lg-2 innerdiv7rowcol">
                            <div class="card innerdiv7rowcolcard">
                              <div className="linestyle1">Capacity</div>
                              <div className="linestyle2">{item?.capacity}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="deployed-details">
        <div className="container"></div>
      </div>
    </div>
       :""}
    </div>
  );
};

export default DeploymentDetails;
