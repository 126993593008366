import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import InputField from "../../../components/common/InputField";
import Button from "../../../components/common/Button";
import ListWithPagination from "../../common/ListWithPagination";
import ReactHtmlParser from "react-html-parser";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
  getPastProjectList,
} from "../dependencies/action";
import LeftSideCategory from "../../../components/common/LeftSideCategory";
import GlobalDropDown from "../../common/GlobalDropDown";
import Breadcrumb from "../../common/Breadcrumbs";
import ReadMoreModal from "../dependencies/component/ReadMoreModal";
import * as moment from "moment";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";
import "./style.scss";
const items = [
  { to: "/", label: "/" },
  { to: "/past-projects", label: "What We Do" },
  { to: "/past-projects", label: "Our Sentinels" },
  {
    to: "/past-projects",
    label: "Past Projects",
    active: true,
  },
];

const pastProjectsStatic = [
  {
    list: [
      {
        id: "1",
        title: "Decentralized COVID-19 Waste Incinerator, Faridabad",
        image: "https://www.wastetowealth.gov.in/s3fs/2021-09/byxar.jpg",
        time: "01 Jul-30 Sep,2022",
        category: "Biomedical Waste",
        location: "(Buxar) Bihar",
      },
      {
        id: "2",
        title: "Decentralized solid and liquid waste management",
        image:
          "https://www.wastetowealth.gov.in/s3fs/2021-09/shutterstock_270394166.jpg",
        time: "10 Nov-30 Dec,2022",
        category: "Waste Water",
        location: "(Dombivli) Mumbai",
      },
    ],
  },
];

const PastProjects = (props) => {
  const {
    // getChallengeEventList,
    // currentInterventionList,
    getTechnologyType,
    interventionTechnology,
    getFilterData,
    filterData,
    getPastProjectList,
    pastProjectsList,
  } = props;
  const [page, setPage] = useState(0);
  const [categoryId, setCategory] = useState("");
  const [isviewPartner, setviewPartner] = useState(false);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [partnerData, setPartnerData] = useState(null);
  const [pageStatus, setPageStatus] = useState(false);
  const defaultFormData = {
    state: "",
    city: "",
    subTheme: "",
    search: "",
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [techType, setTechType] = useState([]);

  const changePageNumber = (pageNumber) => {
    setPageStatus(false);
    setPage((pageNumber - 1) * 6);
  };
  useEffect(() => {
    // const data = [
    //   {
    //     id: 1,
    //     name: "Banana",
    //     category: "Food",
    //   },
    //   {
    //     id: 2,
    //     name: "Milk",
    //     category: "Food",
    //   },
    //   {
    //     id: 3,
    //     name: "Phone",
    //     category: "Technology",
    //   },
    // ];

    // const result = [
    //   ...new Set(
    //     data.map((item) => {
    //       return item.category;
    //     })
    //   ),
    // ];

    // const categories = data.reduce((acc, cur) => {
    //   acc[cur.category] = (acc[cur.category] || 0) + 1;
    //   return acc;
    // }, {});

    // let arrayResult = Object.keys(categories).map((key) => ({
    //   // id: Number(key),
    //   name: key,
    //   count: categories[key],
    // }));

    // console.log("test categories", categories);
    // console.log("test array", arrayResult);

    // console.log("test result", result);

    // const techtypeArray = [];

    const techtypeArray = [
      ...new Set(
        pastProjectsList?.data?.list.map((item) => {
          // return item.category;
          return item.category;
        })
      ),
    ];

    const techcategories = techtypeArray.reduce((acc, cur) => {
      acc[cur] = (acc[cur] || 0) + 1;
      return acc;
    }, {});

    let techArrayResult = Object.keys(techcategories).map((key) => ({
      // id: Number(key),
      name: key,
      count: techcategories[key],
    }));

    setTechType(techArrayResult);
    console.log("techArrayResult", techArrayResult);

    // pastProjectsList?.data?.list.map((item) => {
    //   const techObject = {
    //     name: item.category,
    //     count: 2,
    //   };

    //   techtypeArray.push(techObject);
    // });
    // setTechType(techtypeArray);
    // getChallengeEventListData();
    getPastProjectList();
    console.log("pastProjectsList", pastProjectsList);
  }, [page, categoryId, techType]);

  const handleInputChange = (e, name) => {
    const { value } = e.target;

    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };

  //   const getChallengeEventListData = () => {
  //     let date = moment().format("DD-MM-YYYY");
  //     getChallengeEventList(
  //       page,
  //       categoryId,
  //       formData.city,
  //       formData.search,
  //       formData.subTheme,
  //       formData.state,
  //       date
  //     );
  //   };

  const onSubmit = (e) => {
    e.preventDefault();
    setPageStatus(true);
    // getChallengeEventListData();
  };

  useEffect(() => {
    getTechnologyType();
    getFilterData();
  }, []);

  const getCategoryId = (Id) => {
    setCategory(Id);
  };

  const modalToggler = (data) => {
    setModalData(data);
    setShow(true);
  };

  const toggleViewPartner = (data) => {
    setPartnerData(data);
    setviewPartner(!isviewPartner);
  };

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);

  const staticCategory = [
    {
      id: "1",
      name: "All",
      count: 2,
    },
    {
      id: "2",
      name: "Biomedical Waste",
      count: 1,
    },
    {
      id: "3",
      name: "Waste Water",
      count: 1,
    },
  ];

  return (
    <div className="UpcomingInterventions">
      <Breadcrumb data={items} displayName={"Past Projects"} />
      <div className="filter-row filter-row-common">
        {/*<div className='filter-row filter-row-style'>*/}
        <div className="container">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="row">
              <div className="col-md-1 filter-text">Filter by</div>
              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.subThemes}
                  value={formData.TechType}
                  name={"TechType"}
                  defaultOptionText={"Select Technology types"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-2 select-box">
                {/*<GlobalDropDown
                                                containerStyle="form-group "
                                                selectTagStyle="form-control dropdown-media"
                                                onChange={handleInputChange}
                                                options={''}
                                                value={formData.city}
                                                name={'city'}
                                                defaultOptionText={'Select City'}
                                                error=""
                                            />*/}
                <InputField
                  containerStyle="form-group "
                  onChange={handleInputChange}
                  options={""}
                  value={formData.city}
                  name={"city"}
                  placeholder={"Enter City / Locality"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.state}
                  value={formData.state}
                  name={"state"}
                  defaultOptionText={"Select State"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-2 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.subThemes}
                  value={formData.subTheme}
                  name={"subTheme"}
                  defaultOptionText={"Select WasteTheme"}
                  error=""
                />
              </div>

              <div className="col-1">
                <Button
                  // icon={(<img src={searchIcon} className='search-icon' />)}
                  type="submit"
                  text="Apply"
                  className="green-btn filter-btn"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bottom-section-list">
        <div className="container common-listing past-interventions">
          <div className="row">
            <div className="col-md-3 sidebar">
              <div className="category-box">
                <h3 className="sub-title f19">
                  {/* {ReactHtmlParser(interventionTechnology?.heading)} */}
                  {"Technology Type"}
                </h3>
                <ul>
                  {/* {interventionTechnology &&
                    Array.isArray(interventionTechnology.list) &&
                    interventionTechnology.list.length > 0 &&
                    interventionTechnology.list.map((value, index) => {
                      let Id = value?.id;
                      return (
                        <li>
                          <LeftSideCategory
                            onClickReply={() => {
                              getCategoryId(Id);
                            }}
                            className={
                              value?.id === categoryId ? "category-active" : ""
                            }
                            categoryName={value?.name}
                            count={value?.count}
                            key={index}
                          />
                        </li>
                      );
                    })} */}
                  {techType && techType.length !== 0 && (
                    <li style={{ pointerEvents: "none" }}>
                      <LeftSideCategory
                        // onClickReply={() => {
                        //   getCategoryId(Id);
                        // }}
                        // className={
                        //   value?.id === categoryId ? "category-active" : ""
                        // }
                        categoryName={"All"}
                        count={techType.length}
                        // key={index}
                      />
                    </li>
                  )}

                  {techType &&
                    techType.length !== 0 &&
                    techType.map((value, index) => (
                      <li key={index} style={{ pointerEvents: "none" }}>
                        <LeftSideCategory
                          // onClickReply={() => {
                          //   getCategoryId(Id);
                          // }}
                          // className={
                          //   value?.id === categoryId ? "category-active" : ""
                          // }
                          categoryName={value.name}
                          count={value.count}
                          // key={index}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-md-9 list-container">
              <div className="boxWithShadow">
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-listing-title">
                      {/* {ReactHtmlParser(currentInterventionList?.heading)} */}
                      {"Past Projects"}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="search">
                      <form onSubmit={(e) => onSubmit(e)}>
                        <InputField
                          type="search"
                          name="search"
                          placeholder="Search Projects"
                          value={formData.search}
                          onChange={handleInputChange}
                          containerClass={"form-group"}
                          className="subscribe-input-field"
                        />
                        <div className="btn-group">
                          <Button
                            icon={
                              <img src={searchIcon} className="search-icon" />
                            }
                            type="submit"
                            text=""
                            className="list-submit-button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <ListWithPagination
                  //   data={currentInterventionList?.list}
                  data={pastProjectsList?.data?.list}
                  itemsCountPerPage={6}
                  //   totalItemsCount={currentInterventionList?.totalCount}
                  totalItemsCount={pastProjectsList?.data?.list?.length}
                  pageRangeDisplayed={5}
                  onChangePagination={changePageNumber}
                  checkStatus={pageStatus}
                  nextUrl={"current-intervention-listing"}
                  modalToggle={modalToggler}
                  showPartner={isviewPartner}
                  partnerData={partnerData}
                  isViewPartner={toggleViewPartner}
                  type={"past-projects"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReadMoreModal
        data={modalData}
        className="read-more-modal-content"
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // currentInterventionList:
    //   state.UpcomingInterventionsReducer.currentInterventionList.data,
    interventionTechnology:
      state.UpcomingInterventionsReducer.interventionTechnology.data,
    filterData: state.UpcomingInterventionsReducer.filterData,
    pastProjectsList: state.PastInterventionReducer.pastProjectsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  //   getChallengeEventList: bindActionCreators(getChallengeEventList, dispatch),
  getTechnologyType: bindActionCreators(getTechnologyType, dispatch),
  getFilterData: bindActionCreators(getFilterData, dispatch),
  getPastProjectList: bindActionCreators(getPastProjectList, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PastProjects));
