import api from '../../../../dependencies/utils/Api';
import {
    MAJOR_INVESTORS
} from "../../../../constants/ApplicationUrl";

export const HeaderService = {
    getUpperFooter() {
        try {
            
        return api.GET(MAJOR_INVESTORS)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response;
                return data;
            }).catch(e => {
                return Promise.reject(e);
            });

        } catch (error) {
            
        }
    },
}
