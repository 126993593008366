import React, { useState, userEffects } from "react";
import India from "@svg-maps/india";
import { SVGMap } from "react-svg-map";
// import "react-svg-map/lib/index.css";
import "./style.scss";
import { ABOUT_COHORT_GET_STATE_DETAILS } from "../../../../../constants/ApplicationUrl";
import api from "../../../../../dependencies/utils/Api";
import ReactHtmlParser from "react-html-parser";
function IndiaMap(props) {
  const { data } = props;
  const [stateCode, setStateCode] = useState("");
  const [stateName, setStateName] = useState("");
  const [displayState, setDisplayState] = useState("");
  const [query, setQuery] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateDetails, setStateDetails] = useState([]);
  const state = [
    { index: 0, id: "ap", color_code: "#9B7BD8", state: "Andhra Pradesh" },
    { index: 1, id: "ar", color_code: "#5386C5", state: "Arunachal Pradesh" },
    { index: 2, id: "as", color_code: "#E2D4A7", state: "Assam" },
    { index: 3, id: "br", color_code: "#2EA748", state: "Bihar" },
    { index: 4, id: "ct", color_code: "#9B7BD8", state: "Chhattisgarh" },
    { index: 5, id: "ga", color_code: "#5386C5", state: "Goa" },
    { index: 6, id: "gj", color_code: "#E2D4A7", state: "Gujarat" },
    { index: 7, id: "hr", color_code: "#2EA748", state: "Haryana" },
    { index: 8, id: "hp", color_code: "#9B7BD8", state: "Himachal Pradesh" },
    { index: 9, id: "jh", color_code: "#5386C5", state: "Jharkhand" },
    { index: 10, id: "ka", color_code: "#E2D4A7", state: "Karnataka" },
    { index: 11, id: "kl", color_code: "#2EA748", state: "Kerala" },
    { index: 12, id: "mp", color_code: "#9B7BD8", state: "Madhya Pradesh" },
    { index: 13, id: "mh", color_code: "#5386C5", state: "Maharashtra" },
    { index: 14, id: "mn", color_code: "#E2D4A7", state: "Manipur" },
    { index: 15, id: "ml", color_code: "#2EA748", state: "Meghalaya" },
    { index: 16, id: "mz", color_code: "#9B7BD8", state: "Mizoram" },
    { index: 17, id: "nl", color_code: "#5386C5", state: "Nagaland" },
    { index: 18, id: "or", color_code: "#E2D4A7", state: "Odisha" },
    { index: 19, id: "pb", color_code: "#2EA748", state: "Punjab" },
    { index: 20, id: "rj", color_code: "#9B7BD8", state: "Rajasthan" },
    { index: 21, id: "sk", color_code: "#5386C5", state: "Sikkim" },
    { index: 22, id: "tn", color_code: "#E2D4A7", state: "Tamil Nadu" },
    { index: 23, id: "tg", color_code: "#2EA748", state: "Telangana" },
    { index: 24, id: "tr", color_code: "#9B7BD8", state: "Tripura" },
    { index: 25, id: "ut", color_code: "#5386C5", state: "Uttarakhand" },
    { index: 26, id: "up", color_code: "#E2D4A7", state: "Uttar Pradesh" },
    { index: 27, id: "wb", color_code: "#2EA748", state: "West Bengal" },
    {
      index: 28,
      id: "an",
      color_code: "#9B7BD8",
      state: "Andaman and Nicobar Islands",
    },
    { index: 29, id: "ch", color_code: "#5386C5", state: "Chandigarh" },
    {
      index: 30,
      id: "dn",
      color_code: "#E2D4A7",
      state: "Dadra and Nagar Haveli",
    },
    { index: 31, id: "dd", color_code: "#2EA748", state: "Daman and Diu" },
    { index: 32, id: "dl", color_code: "#9B7BD8", state: "Delhi" },
    { index: 33, id: "jk", color_code: "#5386C5", state: "Jammu and Kashmir" },
    { index: 34, id: "jk", color_code: "#E2D4A7", state: "Ladakh" },
    { index: 35, id: "ld", color_code: "#2EA748", state: "Lakshadweep" },
    { index: 36, id: "py", color_code: "#9B7BD8", state: "Puducherry" },
  ];
  let stateColorCode =
    stateCode != ""
      ? state
          .filter((currentState) => currentState.id === stateCode)
          .map((item) => item.color_code)
      : "#5386C5";
  let randColor =
    stateName != ""
      ? state
          .filter((currentState) => currentState.state === stateName)
          .map((item) => item.color_code)
      : "#5386C5";

  function handleclick(id, stateName, index) {
    // alert(id);
    setStateCode(id);
    setStateName(stateName);
    setDisplayState(stateName);
    // ("#indiamap").onLocationClick();
    // onLocationClick();
    setSelectedState(id);
    onLocaionSelected(id, stateName, index);
  }
  function onLocationClick(event) {
    setStateCode(event.target.id);
    setStateName(event.target.getAttribute("name"));
    // console.log(event.target);

    setDisplayState(event.target.getAttribute("name"));
    onLocaionSelected(event.target.id, event.target.getAttribute("name"));
    getaboutcohortStateDetails(event.target.getAttribute("name"));
  }
  function onLocationMouseOver(event) {
    setStateCode(event.target.id);
    // console.log('onLocationMouseOver', stateName)
    // console.log('onLocationMouseOver', randColor)
    // setDisplayState(event.target.getAttribute("name"))
  }

  function onLocaionSelected(id, stateName) {
    state.forEach((currentState) => {
      if (currentState.id === id) {
        let element = document.getElementById(currentState.id);
        if (element != null) {
          element.setAttribute("aria-checked", true);
          if (id == "jk") element.setAttribute("tabindex", "2");
          getaboutcohortStateDetails(stateName);
        }
      } else {
        let element = document.getElementById(currentState.id);
        if (element != null) {
          element.setAttribute("aria-checked", false);
        }
      }
    });
  }

  const mapColor = ["#9B7BD8", "#E2D4A7", "#5386C5", "#2EA748"];
  // const randColor = mapColor[Math.floor(Math.random() * mapColor.length)]

  function getaboutcohortStateDetails(stateName) {
    let params = {
      searchField: "",
      pageFilter: {
        currentPage: 0,
        includeCount: true,
        pageSize: 30,
        sortField: "fellowName",
        sortOrder: "ASC",
      },
      filter: [
        {
          key: "studentCategory",
          value: ["CATEGORY_A", "CATEGORY_B", "CATEGORY_C"],
        },
        {
          key: "state",
          value: [stateName],
        },
      ],
    };
    console.log(params);
    return api
      .POST(ABOUT_COHORT_GET_STATE_DETAILS, params)
      .then((response) => {
        try {
          console.log(response.data.data);
          setStateDetails(response.data.data);
          // console.log(data)

          return response.data.data;
        } catch (error) {}
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  function isonLocationSelected(event) {}
  function onmouseenter() {}
  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="row">
          <div className="col-lg-3 statelist-center">
            <div className="map-state-margin">
              <div className="about-cohort-section">
                {/* <div className="title-wrap">
                                    <h2 className='about-cohort-title'></h2>
                                </div> */}
                <div className="about-cohort-card-tray">
                  <input
                    placeholder="Search State"
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  {state
                    .filter((post) => {
                      if (query === "") {
                        return post;
                      } else if (
                        post.state.toLowerCase().includes(query.toLowerCase())
                      ) {
                        return post;
                      }
                    })
                    .map((post, index) => (
                      <div className="box" key={index}>
                        <div
                          className={`hover-statelist-zoom-${(index + 4) % 4}`}
                        >
                          <div className="row about-cohort-card-wrap">
                            <p
                              class=".p"
                              className={``}
                              onClick={() =>
                                handleclick(post.id, post.state, post.index)
                              }
                            >
                              {post.state}
                            </p>
                            <span
                              className="square-box"
                              onClick={() =>
                                handleclick(post.id, post.state, post.index)
                              }
                            ></span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* <div className="map-state-margin">
            <div class="session-textchat">
              <div class="past-messages">
                {state.map(c =>
                  <div className="state-name">
                    <span onMouseEnter={onmouseenter} onClick={() => handleclick(c.id, c.state)}>{c.state}</span>

                  </div>)}
              </div>
            </div>
          </div>
        */}
          </div>

          <div className="col-lg-6 map-view">
            <p>{displayState}</p>
            <SVGMap
              id="indiamap"
              map={India}
              isLocationSelected={isonLocationSelected}
              onLocationClick={onLocationClick}
              onLocationMouseOver={onLocationMouseOver}
            />
          </div>
          <div className="col-lg-3 state-details">
            <div>
              {stateName && (
                <div className="india-map-margin">
                  <div className="row no-gutters india-map-card-row hover-zoom">
                    <div className="col-lg-12 india-map-card-body-details">
                      <h5>{stateName}</h5>

                      {data
                        .filter((item) => item.state_name === stateName)
                        .map((item) => {
                          return (
                            <>
                              <p key={item.state_description}>
                                {item.state_description
                                  ? ReactHtmlParser(item.state_description)
                                  : "The total number of Swachhta Saarthi representatives from this state are :"}
                              </p>
                              <h3>
                                {item.fellow_count > 0 ? item.fellow_count : 0}
                              </h3>
                              {item?.fellow_pdf_file && (
                                <div className="student-pdf-download">
                                  <a
                                    href={item?.fellow_pdf_file["pdf_file"]}
                                    target="_blank"
                                    className="download-btn"
                                  >
                                    {item?.fellow_pdf_file["pdf_file"] && (
                                      <p className="pdf-icon">
                                        {" "}
                                        {item?.fellow_pdf_file["pdf_title"]}
                                      </p>
                                    )}
                                  </a>
                                </div>
                              )}
                            </>
                          );
                        })}

                      {/* <h5>{stateDetails.length}</h5> */}
                      {/* <p>Students</p> */}
                    </div>
                    {/* <div className="student-pdf-download">
                      <p className="pdf-icon"> Details of Students</p>
                    </div> */}
                    {/* <img className="card-img india-map-card-image" src={evnbg} alt="Suresh Dasari Card" /> */}
                  </div>
                  <div></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .svg-map {
          width: 90%;
          height: auto;
          stroke: rgb(231, 231, 231);
          stroke-width: 1;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .svg-map__location {
          fill: #0d70af;
          cursor: pointer;
        }

        .svg-map__location:focus,
        .svg-map__location:hover {
          fill: ${stateColorCode};
          outline: 0;
          -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
        }

        // .svg-map__location[tabindex="2"] {
        //   fill: #f4bc44; }
        // .svg-map__location[aria-checked=true] {
        //   fill: #2ba749; }
        .svg-map__location[aria-checked="true"] {
          fill: ${randColor};
          outline: 0;
          -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
        }

        // .svg-map__location[tabindex="0"] {
        //   fill: #a72b77;
        //   outline: 0;
        //   -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        //   filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .9));
        // }
        svg-map__location[aria-checked="false"] {
          fill: #3570ab;
          cursor: pointer;
        }
      `}</style>
    </>
  );
}

export default IndiaMap;
