import downloadIcon from "../../../assests/icons/download.svg";
import ReactHtmlParser from 'react-html-parser';

const ActAndPolicy = (props) => {
    const { onClickTab, data, subtype } = props;
    const downloadFile = (url, fileName) => {
        //  fileName = fileName+".pdf"
        //Create XMLHTTP Request.
        let req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "blob";
        req.onload = function () {
            //Convert the Byte Data to BLOB object.
            let blob = new Blob([req.response], { type: "application/octetstream" });

            //Check the Browser type and download the File.
            let isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                let url = window.URL || window.webkitURL;
                let link = url.createObjectURL(blob);
                let a = document.createElement("a");
                a.setAttribute("download", fileName);
                a.setAttribute("href", link);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        };
        req.send();
    }
    return (
        <div className="container common-listing tab-pane active actsAndPolicies">
            <div className='row'>
                <div className="w-100">
                    <div className="boxWithShadow ">
                        {
                            (subtype === 'acts' || subtype === 'policies' ||subtype === 'guideline') &&
                            <div className="tab-row">
                                <ul className="tab-container nav nav-tabs">
                                    <li className="tab tab-cative policies-act nav-item"><a onClick={() => onClickTab('acts')} href='javascript:void(0)' data-toggle="tab" className="nav-link active">ACTS/RULES</a></li>
                                    <li className="tab policies-act nav-item"><a onClick={() => onClickTab('policies')} href='javascript:void(0)' data-toggle="tab" className="nav-link">POLICIES/REPORTS</a></li>
                                    <li className="tab policies-act nav-item"><a onClick={() => onClickTab('guideline')} href='javascript:void(0)' data-toggle="tab" className="nav-link">Central Guidelines</a></li>
                                </ul>
                            </div>
                        }


                        <div className="tab-content">
                            <ul className="card-listing tab-pane actsPage active">

                                {
                                    data
                                    && Array.isArray(data?.list)
                                    && data?.list?.length > 0
                                    && data?.list.map((value, index) => {
                                        return (
                                            <li key={index}>
                                                <h3 className="card-title"><span>{index + 1}.</span> {value?.title}</h3>
                                                {
                                                    value?.body
                                                    &&
                                                    <div className="card-text-box">
                                                        {ReactHtmlParser(value?.body)}
                                                    </div>
                                                }
                                                <div className="card-box">
                                                    {
                                                        value
                                                        && Array.isArray(value?.pdfLinks)
                                                        && value?.pdfLinks?.length > 0
                                                        && value?.pdfLinks.map((val, ind) => {
                                                            return (
                                                                <div className="story-card card-fix" key={ind}>
                                                                    {/* <div className="card-content ">{val?.name}</div> */}
                                                                    <a href={val?.link} target="_blank" type="button" className="green-btn mr20 btn-width"><img src={downloadIcon} alt="logo" className="img-size" />Download</a>
                                                                </div>
                                                            )
                                                        })

                                                    }
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                                {
                                    data
                                    && Array.isArray(data)
                                    && data.length === 0
                                    && <div className="no-records">No results found</div>
                                }




                            </ul>


                        </div>
                    </div>
                    {data && <div className="disclaimer">{ReactHtmlParser(data.disclaimer)}</div>}
                    
                </div>
            </div>
        </div>
    );
}

// const mapStateToProps = (state) => {
//
// }
export default ActAndPolicy;