import Slider from "react-slick";
import "./style.scss";
import "../../../../../assests/slick/slick.css";
import "../../../../../assests/slick/slick-theme.css";
import commaLeft from "../../../../../assests/icons/comma_right.svg";
import commaRight from "../../../../../assests/icons/comma_left.svg";
import twitterIcon from "../../../../../assests/icons/ic_twitter.svg";
import { useEffect, useState, useRef } from "react";

const SSQuotes = (props) => {
  const { data } = props;
  console.log("data" + data);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <div className="ssQuotes-slider-content clearfix">
      <div className="ssquotes-background-container-quotes main-container">
        <div className="ssquotes-cover ">
          <div className="ssquotes-banner-wrap"></div>
          <Slider {...settings}>
            {data &&
              Array.isArray(data) &&
              data.length > 0 &&
              data.map((value, index) => {
                return (
                  <div className="ssquote-slide-wrap" key={index}>
                    <div className="ssquotes-left-section">
                      <div className="ssquotes-text">
                        <span>
                          {/* <img width="25" src={commaRight} className='copy-comma-top copy-comma' alt="" /> */}
                          {value?.quote}
                          {/* <img width="25" src={commaLeft} className='copy-comma-bottom copy-comma' alt="" /> */}
                        </span>
                      </div>
                    </div>
                    <div className="ssquotes-image text-center">
                      <div class="ssquote-img-inner">
                        <img src={value?.image} alt="" />
                      </div>
                      <div className="ssquotes-image-content">
                        <div className="ssquotes-writer-name">
                          <span>{value?.name}</span>
                        </div>
                        <div className="ssquotes-writer-designation">
                          <span>{value?.designation}</span>
                        </div>
                        <div className="ssquotes-writer-handle">
                          <a
                            href={`https://twitter.com/${value?.handler}`}
                            target="_blank"
                          >
                            <span>
                              <img
                                width="17"
                                src={twitterIcon}
                                className="twitter-icon"
                                alt=""
                              />{" "}
                              {value?.handler}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SSQuotes;
