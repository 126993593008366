import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InputField from "../../common/InputField";
import InputFieldDate from "../../common/InputField";
import SelectDropdown from "../../common/FormElements/SelectDropdown";
import GlobalDropDown from "../../common/GlobalDropDown";
import TextAreaField from "../../common/TextAreaField";
import Button from "../../common/Button";
import Radio from "../../common/FormElements/Radio";
import { changeLanguage } from "../../../dependencies/utils/language/index";
import FileUpload from "../../common/FileUpload";
import FormValidator from "../dependencies/formValidator";
import InlineError from "../../common/InlineError";
import MultiSelectWithCheckbox from "../../common/FormElements/Multiselect";
import { FellowShipService } from "../dependencies/service";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../dependencies/utils/constants";
import SuccessModal from "../../common/SuccessModal";
import {
  uploadFileBank,
  uploadFileEngage,
  submitCategory,
} from "../dependencies/action";
import { validateFile } from "../../Events/dependencies/action";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Checkbox from "../../common/FormElements/Checkbox";
import {
  BANK_UPLOAD_URL,
  ENGAGEMENT_UPLOAD_URL,
  RESIDENT_UPLOAD_URL,
  UPLOAD_FILE_EVENT2,
} from "../../../constants/ApplicationUrl";

const wasteFocusArray = [
  {
    text: "Solid waste management",
    value: "Solid waste management",
  },
  {
    text: "Wastewater Management",
    value: "Wastewater Management",
  },
  {
    text: "Sanitation and hygiene",
    value: "Sanitation and hygiene",
  },
  {
    text: "Community Awareness",
    value: "Community Awareness",
  },
  {
    text: "Electronic Waste",
    value: "Electronic Waste",
  },
  {
    text: "Organic Waste",
    value: "Organic Waste",
  },
];

const CategoryA = (props) => {
  const {
    categoryName,
    validateFile,
    category,
    language,
    classList,
    stateList,
    wasteCategory,
    uploadFileBank,
    uploadFileEngage,
    submitCategory,
    cardTitle,
    pageLink,
  } = props;
  const defaultFormData = {
    name: "",
    phone: "",
    schoolClass: "",
    dob: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    email: "",
    schoolName: "",
    schoolAddress: "",
    schoolPhone: "",
    schoolEmail: "",
    wasteAbundance: "",
    furtherInitiatives: "",
    practicability: "",
    peerDifference: "",
    expectedOutput: "",
    gender: "",
    mechanism: "",
    mentorName: "",
    mentorNumber: "",
    mentorEmail: "",
    proposedWorkUnderFellowship: "",
    anyotherReleventInfo: "",
    wasteFocus: "",
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [checked, setChecked] = useState(false);
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] =
    useState(false);
  const [error, setError] = useState({});
  const [genderError, setGenderError] = useState(false);
  const [uploadedFileBankError, setUploadedFileBankError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [categoryA, setCategoryA] = useState(true);
  const recaptchaRef = useRef(null);
  const recaptchaRef1 = useRef(null);
  const recaptchaRef2 = useRef(null);
  const [capthaError, setCapthaError] = useState(false);
  const [capthaError1, setCapthaError1] = useState(false);
  const [capthaError2, setCapthaError2] = useState(false);

  const [capthaError1assist, setCapthaError1assist] = useState(true);
  const [capthaError2assist, setCapthaError2assist] = useState(true);
  const [meghalayaState, setMeghalayaState] = useState([
    {
      text: "Meghalaya",
      value: "1",
    },
  ]);
  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };
  const resetCaptcha1 = () => {
    recaptchaRef1.current.reset();
  };
  const resetCaptcha2 = () => {
    recaptchaRef2.current.reset();
  };

  useEffect(() => {
    console.log("cardTitle category A", cardTitle);
    console.log("category A statelist", stateList);

    // (solid waste management, wastewater
    //   management, sanitation and hygiene, community awareness, and electronic waste)
  }, []);
  const validateInput = () => {
    const {
      name,
      phone,
      schoolClass,
      dob,
      address,
      state,
      city,
      pincode,
      email,
      schoolName,
      schoolAddress,
      schoolPhone,
      schoolEmail,
      wasteAbundance,
      furtherInitiatives,
      practicability,
      peerDifference,
      expectedOutput,
      mechanism,
      gender,
      mentorName,
      mentorNumber,
      mentorEmail,
      proposedWorkUnderFellowship,
      anyotherReleventInfo,
      wasteFocus,
    } = formData;
    const formStatus = FormValidator.validateSeactionAForm({
      name,
      phone,
      schoolClass,
      dob,
      address,
      state,
      city,
      pincode,
      email,
      schoolName,
      schoolAddress,
      schoolPhone,
      schoolEmail,
      wasteAbundance,
      furtherInitiatives,
      practicability,
      peerDifference,
      expectedOutput,
      mechanism,
      gender,
      mentorName,
      mentorNumber,
      mentorEmail,
      proposedWorkUnderFellowship,
      anyotherReleventInfo,
      wasteFocus: cardTitle === "Meghalaya Fellowship" ? wasteFocus : null,
    });
    const errors = {
      name: formStatus.name,
      phone: formStatus.phone,
      schoolClass: formStatus.schoolClass,
      dob: formStatus.dob,
      address: formStatus.address,
      country: formStatus.country,
      state: formStatus.state,
      city: formStatus.city,
      pincode: formStatus.pincode,
      email: formStatus.email,
      schoolName: formStatus.schoolName,
      schoolAddress: formStatus.schoolAddress,
      schoolPhone: formStatus.schoolPhone,
      schoolEmail: formStatus.schoolEmail,
      wasteAbundance: formStatus.wasteAbundance,
      // activity: formStatus.activity,
      // activityFiled: formStatus.activityFiled,
      furtherInitiatives: formStatus.furtherInitiatives,
      practicability: formStatus.practicability,
      peerDifference: formStatus.peerDifference,
      expectedOutput: formStatus.expectedOutput,
      mechanism: formStatus.mechanism,
      gender: formStatus.gender,
      // otherWasteCategory: formStatus.otherWasteCategory,
      mentorName: formStatus.mentorName,
      mentorNumber: formStatus.mentorNumber,
      mentorEmail: formStatus.mentorEmail,
      proposedWorkUnderFellowship: formStatus.proposedWorkUnderFellowship,
      anyotherReleventInfo: formStatus.anyotherReleventInfo,
      wasteFocus:
        cardTitle === "Meghalaya Fellowship" ? formStatus.wasteFocus : null,
    };

    setError(errors);
    return !formStatus.hasErrors;
  };
  let currentDate = new Date().toISOString().split("T")[0];

  const onBlur = () => {
    validateInput();
  };

  const handleInputChange = (e, name) => {
    // validateInput()
    // debugger;
    const { value } = e.target;
    setFormData((data) => {
      return { ...data, [name]: value };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // if (!validateInput() ){
    if (!validateInput() || uploadedFileBank.length === 0) {
      setHasInvalidSubmitOccurred(true);
      if (uploadedFileBank.length === 0) {
        setUploadedFileBankError("This is required");
      } else {
        setUploadedFileBankError("");
      }
    } else {
      if (checked == false) {
        toast.error("Please Confirm Disclaimer");
      } else {
        const recaptchaValue = recaptchaRef.current.getValue();
        const recaptchaValue1 = recaptchaRef1.current.getValue();
        const recaptchaValue2 = recaptchaRef2.current.getValue();
        if (recaptchaValue === "") {
          setCapthaError(true);
        } else if (recaptchaValue1 === "") {
          setCapthaError1(true);
        } else {
          setCapthaError(false);
          setCapthaError1(false);
          // let activity = formData.activity.split(',');
          let data = {
            ...formData,
            ...{ category },
            ...{ language },
            ...{ uploadedFileBank },
            ...{ uploadedFileResidence },
            secret: recaptchaValue,
            request_for: "categoryA_form",
          };

          submitCategory(data).then((response) => {
            if (response.status === true) {
              setShowModal(true);
              resetCaptcha();
            }
          });
        }
      }
    }
    //     const recaptchaValue = recaptchaRef.current.getValue();
    //     if (recaptchaValue === '') {
    //         setCapthaError(true)
    //     } else {
    //         setCapthaError(false)
    //         submitContactUs(formData).then((data) => {
    //             if (data.status) {
    //                 setFormData(defaultFormData);
    //                 setDisplayFileName('');
    //                 setFileName('');
    //                 resetCaptcha();
    //             }

    //         });

    //     }
    // }
  };
  const [uploadedFileBank, setUploadedFileBank] = useState([]); //proofOfBankingDetails
  const [uploadedFileResidence, setUploadedFileResidence] = useState([]); // proofOfYourResidence
  const [filesBank, setFilesBank] = useState([]); // proofOfBankingDetails
  const [filesResidence, setFilesResidence] = useState([]); // proofOfYourResidence

  const onUploadFileBank = (file, name) => {
    const dataValue = new FormData();
    dataValue.append("campaign", file);

    validateFile(dataValue).then((res) => {
      if (res) {
        if (uploadedFileBank.length < 3) {
          const data = new FormData();
          data.append("file", file);
          data.append("request_for", "categoryA_form");
          data.append("field_name", "field_proof_of_banking_details");
          data.append("attachfile_secret", recaptchaRef1.current.getValue());
          var axios = require("axios");

          var config = {
            method: "post",
            url: UPLOAD_FILE_EVENT2,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              if (response.status === 200) {
                console.log("if");
                setTimeout(() => {
                  recaptchaRef1.current.reset();
                  setCapthaError1assist(true);
                }, 1000);
                if (response?.data?.file_id) {
                  setFilesBank([...filesBank, response?.data?.file_name]);
                  setUploadedFileBank([
                    ...uploadedFileBank,
                    response?.data?.file_id,
                  ]);
                  setUploadedFileBankError("");
                } else {
                  // alert("something went wrong");
                  toast.error("something went wrong");
                }
              } else {
                console.log("else");
              }
            })
            .catch((e) => {
              toast.error("Something Went Wrong from submit tech index ");
              return { status: false };
            });
          // uploadFileBank(data).then(res => {
          //     if (res?.data?.fid[0]["value"]) {
          //         setFilesBank([...filesBank, res?.data?.filename[0]["value"]]);
          //         setUploadedFileBank([...uploadedFileBank, res?.data?.fid[0]?.value])
          //         setUploadedFileBankError('')
          //     } else {
          //         // alert("something went wrong");
          //         toast.error("something went wrong");
          //     }
          // })
        } else {
          toast.error("You can upload maximum 3 files only");
          // alert("only five file accepted")
        }
      }
    });
  };

  const onUploadFileResidence = (file, name) => {
    const dataValue = new FormData();
    dataValue.append("campaign", file);

    validateFile(dataValue).then((res) => {
      if (res) {
        if (uploadedFileResidence.length < 3) {
          const data = new FormData();
          data.append("file", file);
          data.append("request_for", "categoryA_form");
          data.append("field_name", "field_residence_proof");
          data.append("attachfile_secret", recaptchaRef2.current.getValue());
          var axios = require("axios");

          var config = {
            method: "post",
            url: UPLOAD_FILE_EVENT2,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              if (response.status === 200) {
                console.log("if");
                setTimeout(() => {
                  recaptchaRef2.current.reset();
                  setCapthaError2assist(true);
                }, 1000);
                if (response?.data?.file_id) {
                  setFilesResidence([
                    ...filesResidence,
                    response?.data?.file_name,
                  ]);
                  setUploadedFileResidence([
                    ...uploadedFileResidence,
                    response?.data?.file_id,
                  ]);
                  // setUploadedFileEngageError("");
                } else {
                  // alert("something went wrong");
                  toast.error("something went wrong");
                }
              } else {
                console.log("else");
              }
            })
            .catch((e) => {
              toast.error("Something Went Wrong from submit tech index ");
              return { status: false };
            });
          // uploadFileEngage(data).then(res => {

          //     if (res?.data?.fid[0]["value"]) {
          //         setFilesEngage([...filesEngage, res?.data?.filename[0]["value"]]);
          //         setUploadedFileEngage([...uploadedFileEngage, res?.data?.fid[0]?.value])
          //         setUploadedFileEngageError('')
          //     } else {
          //         // alert("something went wrong")
          //         toast.error("something went wrong");;
          //     }
          // })
        } else {
          // alert("only five file accepted")
          toast.error("You can upload maximum 3 files only");
        }
      }
    });
  };

  const removeFile = (file, type) => {
    if (type === "bank") {
      let index = filesBank.indexOf(file);
      if (index > -1) {
        uploadedFileBank.splice(index, 1);
        filesBank.splice(index, 1);
        setFilesBank([...filesBank]);
        setUploadedFileBank([...uploadedFileBank]);
      }
    } else {
      let index = filesResidence.indexOf(file);
      if (index > -1) {
        uploadedFileResidence.splice(index, 1);
        filesResidence.splice(index, 1);
        setFilesResidence([...filesResidence]);
        setUploadedFileResidence([...uploadedFileResidence]);
      }
    }
  };
  const handleClose = () => {
    props.history.push(`/fellowship-listing${pageLink}`);
    setShowModal(false);
  };
  return (
    <div className="form-lower-section">
      {showModal && (
        <SuccessModal
          title={`Swachhta Saarthi Fellowship - Under ${categoryName}`}
          show
          closeModal={handleClose}
        />
      )}

      <div>
        <div className="form-container submit-tech-form">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-body">
              <div className="row">
                <div className="col-12">
                  <div className="bank-disclaimer">
                    {changeLanguage(language, "bankDisclaimer", "label")}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3 className="sub-title">
                    {changeLanguage(language, "studentPerInfo", "label")}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <InputField
                    required
                    type="text"
                    name="name"
                    label={`1. ${changeLanguage(language, "name", "label")}`}
                    placeholder={changeLanguage(
                      language,
                      "name",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={error.name}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
                <div className="col-lg-6">
                  <GlobalDropDown
                    required
                    name="schoolClass"
                    options={classList}
                    label={`2. ${changeLanguage(language, "class", "label")}`}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.schoolClass}
                    onChange={handleInputChange}
                    error={error.schoolClass}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <InputFieldDate
                    required
                    type="date"
                    name="dob"
                    label={`3. ${changeLanguage(language, "dob", "label")}`}
                    containerClass={"form-group"}
                    className="form-input-field dob-input"
                    value={formData.dob}
                    onChange={handleInputChange}
                    error={error.dob}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    max={currentDate}
                  />
                </div>
                <div className="col-lg-6 radio-container">
                  <label>
                    {`4. ${changeLanguage(language, "gender", "label")}`}{" "}
                    <span className="required">*</span>
                  </label>
                  <div className="radio-box">
                    <Radio
                      type="radio"
                      label={changeLanguage(language, "male", "label")}
                      value={"1"}
                      name="gender"
                      checked={formData.gender == 1}
                      onChange={handleInputChange}
                    />

                    <Radio
                      type="radio"
                      label={changeLanguage(language, "female", "label")}
                      value={"2"}
                      name="gender"
                      checked={formData.gender == 2}
                      onChange={handleInputChange}
                    />

                    <Radio
                      type="radio"
                      label={changeLanguage(language, "other", "label")}
                      value={"3"}
                      name="gender"
                      checked={formData.gender == 3}
                      onChange={handleInputChange}
                    />

                    {error.gender && <InlineError message={error.gender} />}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="address"
                    label={`5. ${changeLanguage(language, "address", "label")}`}
                    placeholder={changeLanguage(
                      language,
                      "address",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.address}
                    onChange={handleInputChange}
                    error={error.address}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row address-container">
                <div className="col-lg-12">
                  {/*<SelectDropdown
                                        required
                                        name="country"
                                        label={changeLanguage(language, 'country', 'label')}
                                        containerClass={"form-group"}
                                        className='form-input-field'
                                        value={formData.country}
                                        onChange={handleInputChange}
                                        error={''}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />*/}
                  <GlobalDropDown
                    required
                    name="state"
                    options={
                      cardTitle === "Meghalaya Fellowship"
                        ? meghalayaState
                        : stateList
                    }
                    label={`6. ${changeLanguage(language, "state", "label")}`}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.state}
                    onChange={handleInputChange}
                    error={error.state}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                  <InputField
                    required
                    type="text"
                    name="city"
                    label={`7. ${changeLanguage(language, "city", "label")}`}
                    containerClass={"form-group"}
                    placeholder={changeLanguage(
                      language,
                      "city",
                      "placeholder"
                    )}
                    className="form-input-field"
                    value={formData.city}
                    onChange={handleInputChange}
                    error={error.city}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                  <InputField
                    required
                    type="pincode"
                    name="pincode"
                    label={`8. ${changeLanguage(language, "pincode", "label")}`}
                    containerClass={"form-group"}
                    placeholder={changeLanguage(
                      language,
                      "pincode",
                      "placeholder"
                    )}
                    className="form-input-field"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    error={error.pincode}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>

                <div className="col-lg-6">
                  <div className="phone">
                    <InputField
                      required
                      type="number"
                      name="phone"
                      label={`9. ${changeLanguage(language, "tel", "label")}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "tel",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.phone}
                      onChange={handleInputChange}
                      error={error.phone}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="email">
                    <InputField
                      required
                      type="text"
                      name="email"
                      label={`10. ${changeLanguage(
                        language,
                        "email",
                        "label"
                      )}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "email",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={error.email}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3 className="sub-title">
                    {changeLanguage(
                      language,
                      "schoolConactDetail",
                      "placeholder"
                    )}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <InputField
                    required
                    type="text"
                    name="schoolName"
                    label={`11. ${changeLanguage(
                      language,
                      "schoolName",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "schoolName",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field"
                    value={formData.schoolName}
                    onChange={handleInputChange}
                    error={error.schoolName}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="schoolAddress"
                    label={`12. ${changeLanguage(
                      language,
                      "schoolAddress",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "schoolAddress",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.schoolAddress}
                    onChange={handleInputChange}
                    error={error.schoolAddress}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="phone">
                    <InputField
                      required
                      type="number"
                      name="schoolPhone"
                      label={`13. ${changeLanguage(
                        language,
                        "schoolContact",
                        "label"
                      )}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "schoolContact",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.schoolPhone}
                      onChange={handleInputChange}
                      error={error.schoolPhone}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="email">
                    <InputField
                      required
                      type="text"
                      name="schoolEmail"
                      label={`14. ${changeLanguage(
                        language,
                        "schoolEmail",
                        "label"
                      )}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "schoolEmail",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.schoolEmail}
                      onChange={handleInputChange}
                      error={error.schoolEmail}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <InputField
                      required
                      type="text"
                      name="mentorName"
                      label={`15. ${changeLanguage(
                        language,
                        "mentorName",
                        "label"
                      )}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "mentorName",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.mentorName}
                      onChange={handleInputChange}
                      error={error.mentorName}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <InputField
                      required
                      type="number"
                      name="mentorNumber"
                      label={`16. ${changeLanguage(
                        language,
                        "mentorNumber",
                        "label"
                      )}`}
                      containerClass={"form-group"}
                      placeholder={changeLanguage(
                        language,
                        "mentorNumber",
                        "placeholder"
                      )}
                      className="form-input-field"
                      value={formData.mentorNumber}
                      onChange={handleInputChange}
                      error={error.mentorNumber}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              </div>
              <div>
                <InputField
                  required
                  type="text"
                  name="mentorEmail"
                  label={`17. ${changeLanguage(
                    language,
                    "mentorEmail",
                    "label"
                  )}`}
                  containerClass={"form-group"}
                  placeholder={changeLanguage(
                    language,
                    "mentorEmail",
                    "placeholder"
                  )}
                  className="form-input-field"
                  value={formData.mentorEmail}
                  onChange={handleInputChange}
                  error={error.mentorEmail}
                  onBlur={onBlur}
                  hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
              </div>
              {cardTitle === "Meghalaya Fellowship" ? (
                <div className="row">
                  <div className="col">
                    <GlobalDropDown
                      required
                      name="wasteFocus"
                      options={wasteFocusArray}
                      label={
                        "18. Choose a waste focus area for your 1 year long project"
                      }
                      containerClass={"form-group"}
                      className="form-input-field"
                      value={formData.wasteFocus}
                      onChange={handleInputChange}
                      error={error.wasteFocus}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="wasteAbundance"
                    label={
                      cardTitle === "Meghalaya Fellowship"
                        ? "19. A brief on the waste focus areas selected in your vicinity/college/university/community (solid waste management,wastewater management, sanitation and hygiene, organic waste, community awareness, and electronic waste) and prominent challenges."
                        : changeLanguage(language, "wasteAbundance", "label")
                    }
                    placeholder={
                      cardTitle === "Meghalaya Fellowship"
                        ? "(solid waste management,wastewater management, sanitation and hygiene, organic waste, community awareness, and electronic waste) and prominent challenges."
                        : changeLanguage(
                            language,
                            "wasteAbundance",
                            "placeholder"
                          )
                    }
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.wasteAbundance}
                    onChange={handleInputChange}
                    error={error.wasteAbundance}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div>
                <TextAreaField
                  required
                  type="text"
                  name="proposedWorkUnderFellowship"
                  label={`20. ${changeLanguage(
                    language,
                    "proposedWorkUnderFellowship",
                    "label"
                  )}`}
                  containerClass={"form-group"}
                  placeholder={changeLanguage(
                    language,
                    "proposedWorkUnderFellowship",
                    "placeholder"
                  )}
                  className="form-input-field"
                  value={formData.proposedWorkUnderFellowship}
                  onChange={handleInputChange}
                  error={error.proposedWorkUnderFellowship}
                  onBlur={onBlur}
                  hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
              </div>
              {/* <div className="row">
                                <div className="col">
                                    <h3 className="sub-title">{changeLanguage(language, 'wordDoneSum', 'label')}</h3>
                                </div>
                            </div>
                            <div className='row multiple-selection-box'>
                                <div className='col-lg-6'>
                                    <MultiSelectWithCheckbox
                                        required
                                        label={changeLanguage(language, 'wasteCategory', 'label')}
                                        name="activity"
                                        options={wasteCategory}
                                        value={formData.activity}
                                        onChange={handleInputChange}
                                        error={error.activity}
                                        touched={hasInvalidSubmitOccurred}
                                        valid={hasInvalidSubmitOccurred}
                                        onBlur={onBlur}
                                    />

                                </div>
                                <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="activityFiled"
                                        label={changeLanguage(language, 'activityStatus', 'label')}
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'activityStatus', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.activityFiled}
                                        onChange={handleInputChange}
                                        error={error.activityFiled}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <TextAreaField
                                        required
                                        type="text"
                                        name="otherWasteCategory"
                                        label={changeLanguage(language, 'otherWasteCategory', 'label')}
                                        placeholder={changeLanguage(language, 'otherWasteCategory', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.otherWasteCategory}
                                        onChange={handleInputChange}
                                        error={error.otherWasteCategory}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <TextAreaField
                                        required
                                        type="text"
                                        name="workDoneDetail"
                                        label={changeLanguage(language, 'workDoneDetail', 'label')}
                                        placeholder={changeLanguage(language, 'workDoneDetail', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.workDoneDetail}
                                        onChange={handleInputChange}
                                        error={error.workDoneDetail}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label>{changeLanguage(language, 'engagementCommunity', 'label')} <span className="required">*</span></label>
                                    <div className="decision-options">
                                        <Radio
                                            type="engagementCommunity"
                                            label={changeLanguage(language, 'yes', 'label')}
                                            value={'2'}
                                            name='engagementCommunity'
                                            checked={formData.engagementCommunity == 2}
                                            onChange={handleInputChange}
                                        />
                                        <Radio
                                            type="engagementCommunity"
                                            label={changeLanguage(language, 'no', 'label')}
                                            value={'1'}
                                            name='engagementCommunity'
                                            checked={formData.engagementCommunity == 1}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {
                                        error.engagementCommunity
                                        && <InlineError message={error.engagementCommunity} />
                                    }
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <TextAreaField
                                        required
                                        type="text"
                                        name="innovativeSolution"
                                        label={changeLanguage(language, 'innovativeSolution', 'label')}
                                        value=""
                                        placeholder={changeLanguage(language, 'innovativeSolution', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.innovativeSolution}
                                        onChange={handleInputChange}
                                        error={error.innovativeSolution}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <TextAreaField
                                        required
                                        type="text"
                                        name="acitivityOutcome"
                                        label={changeLanguage(language, 'acitivityOutcome', 'label')}
                                        placeholder={changeLanguage(language, 'acitivityOutcome', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.acitivityOutcome}
                                        onChange={handleInputChange}
                                        error={error.acitivityOutcome}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

                                    />
                                </div>
                            </div>
                            <div>
                                <label>
                                    {changeLanguage(language, 'socialAwareness', 'label')} <span className="required">*</span>
                                </label>
                                <div className='col decision-options'>


                                    <Radio
                                        type="radio"
                                        label={changeLanguage(language, 'yes', 'label')}
                                        value={'2'}
                                        name='socialRadio'
                                        checked={formData.socialRadio == 2}
                                        onChange={handleInputChange}
                                    />

                                    <Radio
                                        type="radio"
                                        label={changeLanguage(language, 'no', 'label')}
                                        value={'1'}
                                        name='socialRadio'
                                        checked={formData.socialRadio == 1}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {
                                    error.socialRadio && (
                                        <InlineError message={error.socialRadio} />
                                    )
                                }
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <TextAreaField
                                        required
                                        type="text"
                                        name="socialAwareness"
                                        label={changeLanguage(language, 'socialAwarenessDetails', 'label')}
                                        placeholder={changeLanguage(language, 'socialAwarenessDetails', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.socialAwareness}
                                        onChange={handleInputChange}
                                        error={error.socialAwareness}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                            </div>

                            <div>
                                <span>
                                    {changeLanguage(language, 'presentRadio', 'label')} <span className="required">*</span>
                                </span>
                                <div className='col decision-options'>
                                    <Radio
                                        type="radio"
                                        label={changeLanguage(language, 'yes', 'label')}
                                        value={'2'}
                                        name='presentRadio'
                                        checked={formData.presentRadio == 2}
                                        onChange={handleInputChange}
                                    />

                                    <Radio
                                        type="radio"
                                        label={changeLanguage(language, 'no', 'label')}
                                        value={'1'}
                                        name='presentRadio'
                                        checked={formData.presentRadio == 1}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {
                                    error.presentRadio && (
                                        <InlineError message={error.presentRadio} />
                                    )
                                }
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <TextAreaField

                                        type="text"
                                        name="otherInfo"
                                        label={changeLanguage(language, 'otherInfo', 'label')}
                                        placeholder={changeLanguage(language, 'otherInfo', 'placeholder')}
                                        containerClass={"form-group"}
                                        className='form-input-field fellow-text-box'
                                        value={formData.otherInfo}
                                        onChange={handleInputChange}
                                        error={error.otherInfo}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                            </div>
                            <div className='row upload-file'>
                                <div className='col'>
                                    <FileUpload
                                        required
                                        maxFileSizeInMB={3}
                                        htmlFor="uploadFileInput"
                                        labelClass="contactUploadFile green-border-btn"
                                        onUploadFile={(file) => onUploadFileEngage(file, "proofOfYourEngagement")}
                                        allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                        error={uploadedFileEngageError}
                                        label=''

                                        onBlur={onBlur}
                                        id="uploadFileInput"
                                        visbility="hidden"
                                        label="Attach File"
                                    />
                                    <div className='overlay'>
                                        <i className="upload-file-icon"></i>
                                        <div>
                                            <h6 required>{changeLanguage(language, 'workSummaryFileText', 'placeholder')} <span className="required">*</span></h6>
                                            <span>{changeLanguage(language, 'workSummaryFileText', 'label')}</span>
                                        </div>
                                    </div>

                                </div>
                                {filesEngage.map(file => <p key={file}>{file} <span onClick={() => { removeFile(file, 'engagement') }}>X</span></p>)}

                            </div>*/}

              <div className="row">
                <div className="col">
                  <h3 className="sub-title">
                    {changeLanguage(language, "actionPlan", "label")}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="furtherInitiatives"
                    label={
                      cardTitle === "Meghalaya Fellowship" && categoryA
                        ? "21. What initiatives would you like to propose in your selected waste focus area to work on during the year with one or two specific objectives? (max 150 words)"
                        : changeLanguage(
                            language,
                            "furtherInitiatives",
                            "label"
                          )
                    }
                    placeholder={changeLanguage(
                      language,
                      "furtherInitiatives",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.furtherInitiatives}
                    onChange={handleInputChange}
                    error={error.furtherInitiatives}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="practicability"
                    label={`22. ${changeLanguage(
                      language,
                      "practicability",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "practicability",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.practicability}
                    onChange={handleInputChange}
                    error={error.practicability}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="peerDifference"
                    label={`23. ${changeLanguage(
                      language,
                      "peerDifference",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "peerDifference",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.peerDifference}
                    onChange={handleInputChange}
                    error={error.peerDifference}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="expectedOutput"
                    label={`24. ${changeLanguage(
                      language,
                      "expectedOutput",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "expectedOutput",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.expectedOutput}
                    onChange={handleInputChange}
                    error={error.expectedOutput}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaField
                    required
                    type="text"
                    name="mechanism"
                    label={`25. ${changeLanguage(
                      language,
                      "mechanism",
                      "label"
                    )}`}
                    placeholder={changeLanguage(
                      language,
                      "mechanism",
                      "placeholder"
                    )}
                    containerClass={"form-group"}
                    className="form-input-field fellow-text-box"
                    value={formData.mechanism}
                    onChange={handleInputChange}
                    error={error.mechanism}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                  />
                </div>
              </div>
              {/*    <div className="row">
                                <div className="col-12">
                                    <div className="bank-disclaimer">
                                        {changeLanguage(language, 'bankDisclaimer', 'label')}
                                    </div>
                                </div>
                            </div>
                           <div className="row">


                               <div className="col">
                                    <h3 className="sub-title">{changeLanguage(language, 'bankHeading', 'label')}</h3>
                                </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="adherNo"
                                        label={changeLanguage(language, 'adherNo', 'label')}
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'adherNo', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.adherNo}
                                        onChange={handleInputChange}
                                        error={error.adherNo}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                     <InputField
                                        required
                                        type="text"
                                        name="AccHolderName"
                                        label={changeLanguage(language, 'AccHolderName', 'label')}
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'AccHolderName', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.AccHolderName}
                                        onChange={handleInputChange}
                                        error={error.AccHolderName}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                            </div>
                             <div className='row'>
                                <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="accountNo"
                                        label={changeLanguage(language, 'accountNo', 'label')}
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'accountNo', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.accountNo}
                                        onChange={handleInputChange}
                                        error={error.accountNo}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="confirmAccountNo"
                                        label={changeLanguage(language, 'confirmAccountNo', 'label')}
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'confirmAccountNo', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.confirmAccountNo}
                                        onChange={handleInputChange}
                                        error={error.confirmAccountNo}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="bankName"
                                        label={changeLanguage(language, 'bankName', 'label')}
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'bankName', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.bankName}
                                        onChange={handleInputChange}
                                        error={error.bankName}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="branchName"
                                        label={changeLanguage(language, 'branchName', 'label')}
                                        value=""
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'branchName', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.branchName}
                                        onChange={handleInputChange}
                                        error={error.branchName}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>
                            </div>
                            div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <InputField
                                        required
                                        type="text"
                                        name="ifsc"
                                        label={changeLanguage(language, 'ifsc', 'label')}
                                        value=""
                                        containerClass={"form-group"}
                                        placeholder={changeLanguage(language, 'ifsc', 'placeholder')}
                                        className='form-input-field'
                                        value={formData.ifsc}
                                        onChange={handleInputChange}
                                        error={error.ifsc}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                </div>

                            </div>
                             */}
              <div>
                <TextAreaField
                  required
                  type="text"
                  name="anyotherReleventInfo"
                  label={`26. ${changeLanguage(
                    language,
                    "anyotherReleventInfo",
                    "label"
                  )}`}
                  containerClass={"form-group"}
                  placeholder={changeLanguage(
                    language,
                    "anyotherReleventInfo",
                    "placeholder"
                  )}
                  className="form-input-field"
                  value={formData.anyotherReleventInfo}
                  onChange={handleInputChange}
                  error={error.anyotherReleventInfo}
                  onBlur={onBlur}
                  hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
              </div>
              <div className="col-12 col-lg-7">
                <div className="captcha-field">
                  <ReCAPTCHA
                    ref={recaptchaRef1}
                    sitekey={SITE_KEY}
                    onChange={() => {
                      setCapthaError1assist(false);
                    }}
                  />
                  {capthaError1 && <InlineError message={"This is required"} />}
                </div>
              </div>
              <div className="row upload-file">
                <div
                  className="col"
                  style={{
                    pointerEvents:
                      capthaError1assist === true ? "none" : "auto",
                    opacity: capthaError1assist === true ? 0.5 : 1,
                    // borderColor:
                    //   capthaErrorassist === true ? "lightgrey" : "#2ba749",
                    // color: capthaErrorassist === true ? "lightgrey" : "#2ba749",
                  }}
                >
                  <FileUpload
                    htmlFor="uploadFileInput1"
                    maxFileSizeInMB={3}
                    labelClass="green-border-btn"
                    onUploadFile={(file) =>
                      onUploadFileBank(file, "proofOfBankingDetails")
                    }
                    allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    error={uploadedFileBankError}
                    onBlur={onBlur}
                    id="uploadFileInput1"
                    visbility="hidden"
                    label="Attach File"
                  />

                  <div className="overlay">
                    <i className="upload-file-icon"></i>
                    <div>
                      <h6 required className="attachheading">
                        {changeLanguage(
                          language,
                          "bankFileText",
                          "placeholder"
                        )}{" "}
                        <span className="required">*</span>
                      </h6>
                      <span>
                        {changeLanguage(language, "bankFileText", "label")}
                      </span>
                    </div>
                  </div>
                </div>
                {filesBank &&
                  filesBank.length > 0 &&
                  filesBank.map((file) => (
                    <p>
                      {file}{" "}
                      <span
                        onClick={() => {
                          removeFile(file, "bank");
                        }}
                      >
                        X
                      </span>
                    </p>
                  ))}
              </div>

              {cardTitle === "Meghalaya Fellowship" ? (
                <>
                  <div className="col-12 col-lg-7">
                    <div className="captcha-field">
                      <ReCAPTCHA
                        ref={recaptchaRef2}
                        sitekey={SITE_KEY}
                        onChange={() => {
                          setCapthaError2assist(false);
                        }}
                      />
                      {/* {capthaError && <InlineError message={"This is required"} />} */}
                    </div>
                  </div>
                  <div className="row upload-file">
                    <div
                      className="col"
                      style={{
                        pointerEvents:
                          capthaError2assist === true ? "none" : "auto",
                        opacity: capthaError2assist === true ? 0.5 : 1,
                        // borderColor:
                        //   capthaErrorassist === true ? "lightgrey" : "#2ba749",
                        // color: capthaErrorassist === true ? "lightgrey" : "#2ba749",
                      }}
                    >
                      <FileUpload
                        maxFileSizeInMB={3}
                        htmlFor="uploadFileInput"
                        labelClass="contactUploadFile green-border-btn"
                        onUploadFile={(file) =>
                          onUploadFileResidence(file, "proofOfYourResidence")
                        }
                        allowedFiles={[".pdf", ".png", ".jpg", ".jpeg"]}
                        //  hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        //  error={uploadedFileEngageError}
                        //  onBlur={onBlur}
                        id="uploadFileInput"
                        visbility="hidden"
                        label="Attach File"
                      />

                      <div className="overlay">
                        <i className="upload-file-icon"></i>
                        <div>
                          <h6 required className="attachheading">
                            {/* {changeLanguage(
                            language,
                            "bankFileText",
                            "placeholder"
                          )}{" "} */}
                            Attach residence proof
                            {/* <span className="required">*</span> */}
                          </h6>
                          <span>
                            {/* {changeLanguage(language, "bankFileText", "label")} */}
                            Please attach address proof which proves you are a
                            resident of Meghalaya (.pdf, .png, .jpg, .jpeg) -
                            max 3MB.
                          </span>
                        </div>
                      </div>
                    </div>
                    {filesResidence &&
                      filesResidence.length > 0 &&
                      filesResidence.map((file) => (
                        <p>
                          {file}{" "}
                          <span
                            onClick={() => {
                              removeFile(file, "residence");
                            }}
                          >
                            X
                          </span>
                        </p>
                      ))}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-12 mr20 categoryconfirm">
                <Checkbox
                  id="disclaimer"
                  label={changeLanguage(language, "disclaimer1", "label")}
                  name="disclaimer"
                  value={checked == true ? 1 : 2}
                  onChange={() => setChecked(!checked)}
                />
              </div>
              <div className="col-12 col-lg-7">
                <div className="captcha-field">
                  <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
                  {capthaError && <InlineError message={"This is required"} />}
                </div>
              </div>
            </div>
            <div className="form-footer">
              <div className="row footer-btn-wrap">
                <div className="bttn-group col-lg-12 d-flex justify-content-end">
                  <Link
                    className="bordered-btn green-border formcancel"
                    to={`/fellowship-listing${pageLink}`}
                  >
                    {changeLanguage(language, "cancel", "label")}
                  </Link>
                  <Button
                    type="submit"
                    text={changeLanguage(language, "submitApp", "label")}
                    className="form-submit-button"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  uploadFileBank,
  validateFile,
  uploadFileEngage,
  submitCategory,
})(withRouter(CategoryA));
