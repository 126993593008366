import api from '../../../dependencies/utils/Api';
import {
    PARTNER_WITH_US,
} from "../../../constants/ApplicationUrl";

export const PartnerWithUsService = {
    
    getpartnerWithUsDetails() {
        return api.GET(`${PARTNER_WITH_US}`)
            .then((response) => {
                const { data: { data } = {} } = response || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    
}