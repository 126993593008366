import api from '../../../../dependencies/utils/Api';
import {
    HEADER_DATA,
    SEARCH_DATA
} from "../../../../constants/ApplicationUrl";

export const HeaderService = {
    getHeader() {
        return api.GET(HEADER_DATA)
            .then((response) => {
                const { headerMenu, mainMenu, siteLogo } = response?.data || {};
                return { headerMenu, mainMenu, siteLogo };
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    getSearch() {
        return api.GET(SEARCH_DATA)
            .then((response) => {
                const  data  = response?.data?.data || [];
                return data;
            }).catch(e => {
                return Promise.reject(e);
            });
    },
}
