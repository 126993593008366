import { connect } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";
import { getFelloshipDetails } from "./dependencies/action";
import { useEffect, useState } from "react";
import Overview from "../Challenges/dependencies/component/Overview";
import EligibilityCriteria from "../Challenges/dependencies/component/EligibilityCriteria";
import SelectionCriteria from "../Challenges/dependencies/component/SelectionCriteria";
import EvaluationProcess from "../Challenges/dependencies/component/EvaluationProcess";
import Disclaimer from "../Challenges/dependencies/component/Disclaimer";
import Breadcrumb from "../common/Breadcrumbs";
import Button from "../common/Button";
import "./style.scss";
import AllResults from "./AllResults";
import { initGA, logPageView } from "../../dependencies/utils/analytics";

import GallerySection from "./GallerySection";

const Fellowship = (props) => {
  const location = useLocation();
  const items = [
    { to: "/", label: "/" },
    {
      to: "/fellowship-home",
      label: "Swachhta Saarthi Fellowship",
      // label: "SSF Fellowship",
    },
    // { to: "/fellowship-list", label: "SSF Cohorts" },
    {
      to: `${window.location.pathname}`,
      // label: localStorage.getItem("ssftitle"),
      label: location.state?.mainTitle || localStorage.getItem("ssftitle"),
      active: true,
    },
    // { to: '/fellowship-list', label: 'Fellowship Listing' },
  ];
  const { getFelloshipDetails, fellowshipDetails, newLanguage } = props;
  const [language, setlanguage] = useState(localStorage.getItem("language"));

  const [showDetailPage, setShowDetailPage] = useState(
    // location.state?.showDetailPage != undefined ? false : true
    false
  );
  // alert(location.state?.showDetailPage)
  // console.log(getFelloshipDetails)
  // console.log(fellowshipDetails)
  // console.log(newLanguage)
  // console.log(getFelloshipDetails)
  console.log(
    "location showdetailpage",
    location.state?.showDetailPage && location.state?.showDetailPage
  );
  console.log("fellowship pathname", window.location.pathname);
  console.log("fellowship direction state", localStorage.getItem("ssfcohort"));
  console.log(
    "fellowship direction pagelink",
    localStorage.getItem("fellowshipPagelink")
  );
  let str =
    window.location.pathname ||
    `/fellowship-listing${localStorage.getItem("fellowshipPagelink")}`;
  useEffect(() => {
    console.log("window pagelink", window.location.pathname);
    console.log(
      "localstorage pagelink",
      localStorage.getItem("fellowshipPagelink")
    );

    console.log("splitted link", str.split("/")[2]);

    if (!str.split("/")[2]) {
      props.history.push(
        `/fellowship-listing${localStorage.getItem("fellowshipPagelink")}`
      );
    }
    getFelloshipDetails(str.split("/")[2], newLanguage);
    setlanguage(newLanguage);
  }, [getFelloshipDetails, newLanguage]);
  useEffect(() => {
    getFelloshipDetails(str.split("/")[2], language);
  }, []);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  return (
    <div>
      {fellowshipDetails !== undefined && (
        <Breadcrumb
          data={items}
          displayName={fellowshipDetails?.title || location.state?.mainTitle}
        />
      )}
      {/* {fellowshipDetails !== undefined &&
        fellowshipDetails?.sections?.all_result?.list?.length > 0 && (
          <div className="fellowship-btnwrap-outer">
            <div className="container d-flex fellowship-btnwrap">
              <Button
                text={
                  showDetailPage ? "Show Result" : "View Fellowship Details"
                }
                onClick={() => {
                  setShowDetailPage(!showDetailPage);
                }}
                className="green-btn filter-btn"
              />
            </div>
          </div>
        )} */}
      {localStorage.getItem("ssfcohort") !== "Meghalaya Fellowship" && (
        <div className="fellowship-btnwrap-outer" style={{ display: "none" }}>
          <div className="container d-flex fellowship-btnwrap">
            <Button
              text={showDetailPage ? "Show Result" : "View Fellowship Details"}
              onClick={() => {
                setShowDetailPage(!showDetailPage);
              }}
              className="green-btn filter-btn"
            />
          </div>
        </div>
      )}

      {showDetailPage === true && (
        <>
          <Overview
            data={fellowshipDetails || location.state?.data}
            type="fellowship"
            lang={language}
            cardTitle={localStorage.getItem("ssfcohort")}
            pageLink={localStorage.getItem("fellowshipPagelink")}
            pageTitle={fellowshipDetails?.title || location.state?.mainTitle}
          />
          <EligibilityCriteria
            data={
              fellowshipDetails?.sections?.eligibility_criteria ||
              location.state?.data?.sections?.eligibility_criteria
            }
            cardTitle={localStorage.getItem("ssfcohort")}
          />
          <SelectionCriteria
            data={
              fellowshipDetails?.sections?.selection_criteria ||
              location.state?.data?.sections?.selection_criteria
            }
            cardTitle={localStorage.getItem("ssfcohort")}
          />
          <EvaluationProcess
            data={
              fellowshipDetails?.sections?.evaluation_process ||
              location.state?.data?.sections?.evaluation_process
            }
          />
          <SelectionCriteria
            data={
              fellowshipDetails?.sections?.awards ||
              location.state?.data?.sections?.awards
            }
            sectionClass="awards-section"
          />
        </>
      )}

      {fellowshipDetails?.sections &&
        fellowshipDetails?.sections?.all_result &&
        fellowshipDetails?.sections?.all_result?.list &&
        fellowshipDetails?.sections?.all_result.list.length > 0 &&
        showDetailPage === false && (
          <>
            <AllResults
              data={
                fellowshipDetails?.sections?.all_result ||
                location.state?.data?.sections?.all_result
              }
            />
          </>
        )}
      {/* <GallerySection data={fellowshipDetails?.sections?.gallery} /> */}
      <Disclaimer
        data={fellowshipDetails?.disclaimer || location.state?.data?.disclaimer}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fellowshipDetails: state.FellowsihpReducer.fellowshipDetails.data,
    newLanguage: state.LanguageReducer.siteLanguage,
  };
};
export default connect(mapStateToProps, { getFelloshipDetails })(
  withRouter(Fellowship)
);
