import downloadIcon from "../../../../assests/icons/download.svg";
import { useEffect } from "react";

import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const testdata = [
  {
    name: "test 1",
    url: "https://uat.wastetowealth.gov.in/s3fs/2022-06/test.pdf",
  },
  {
    name: "test 2",
    url: "https://uat.wastetowealth.gov.in/s3fs/2022-06/test.pdf",
  },
];

const ActAndPolicy = (props) => {
  console.log("press and reports props", props);
  const { onClickTab, data, subtype, fieldData, pressReleaseData, fieldLink } =
    props;
  console.log("fieldData props", fieldData);

  // console.log("fieldData name length", fieldData[0].name.length);
  // console.log("fieldData name length", fieldData[1].name.length);
  // const lastArrayelement = fieldData?.slice(-1)[0];
  // console.log("lastArrayelement", lastArrayelement);
  const downloadFile = (url, fileName) => {
    //  fileName = fileName+".pdf"
    //Create XMLHTTP Request.
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      let blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      let isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);
  return (
    <div className=" common-listing tab-pane active actsAndPolicies">
      <div className="row">
        <div className="w-100">
          <div className="boxWithShadow currentpolicyreports container reportsmaindiv event">
            {/* {fieldData?.length !== 0 && (
              <div
                className="row"
                style={{ float: "right", marginRight: "1%" }}
              >
                <div class="large-tree"></div>
                <div class="small-tree" style={{ marginTop: "20%" }}></div>
              </div>
            )} */}

            <div
              className="row"
              style={{
                justifyContent: "space-between",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              <div
                className="tab-content col-md-8 col-sm-12"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <div>
                  {(fieldData?.length !== 0 || fieldLink?.length !== 0) && (
                    <h1
                      class="section-heading-release"
                      style={{ fontSize: "30px", fontWeight: "bold" }}
                    >
                      <p style={{ fontSize: "30px", fontWeight: "bold" }}>
                        Collateral
                      </p>
                    </h1>
                  )}
                </div>
                <ul className="card-listing tab-pane actsPage active currentintpolicy">
                  {fieldData?.length !== 0 &&
                    fieldData?.map((value, index) => (
                      <>
                        <div
                          className="row"
                          style={{
                            // borderBottom:
                            //   index === fieldData.indexOf(fieldData?.slice(-1)[0])
                            //     ? "none"
                            //     : "2px inset #e6e6e6",
                            marginLeft: "0px",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                            background: "white",
                          }}
                        >
                          <li
                            style={{
                              paddingLeft: "0px",
                              borderBottom: "none",
                              width: "85%",
                              background: "#183b63",
                              // marginBottom: "10px",
                            }}
                            className="col-md-9 col-sm-12"
                          >
                            <h3
                              className="card-title"
                              style={{ fontSize: "18px", marginBottom: "10px" }}
                            >
                              {/* <span style={{ color: "white" }}>
                                {index + 1}.
                              </span>{" "} */}
                              <a
                                href={value.url}
                                target="_blank"
                                style={{
                                  pointerEvents: "none",
                                  color: "white",
                                  textTransform: "capitalize",
                                }}
                              >
                                {value.name}
                              </a>
                            </h3>
                          </li>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              zIndex: 1,
                              // marginRight: value.name.length > 49 ? "" : "30% ",
                              flexShrink: "unset",
                              width: "unset",
                              maxWidth: "unset",
                              paddingRight: "unset",
                              paddingLeft: "unset",
                              marginTop: "unset",
                            }}
                          >
                            <a
                              className="green-btn mr20 collateraldownloadbtn"
                              download
                              href={value.url}
                              // href={"javascript:void(0)"}
                              // target="_blank"
                              // Content-Disposition="attachment"
                              // filename={value.url}
                              // to={{
                              //   pathname: `/${"past-projects"}${value?.pagelink}`,
                              //   state: {
                              //     // blogName: value?.title,
                              //     // blogText: value?.body,
                              //     // blogDate: moment(value?.publish_date).format(
                              //     //   "MMM Do YYYY"
                              //     // ),
                              //     // blogAuthor: value?.author,
                              //     // blogImage: value?.thumbnail_link,
                              //     // authorImage: placeholderAuthor,
                              //     // authorName: value?.author[0]?.name,
                              //     pageLink: value?.pagelink,
                              //     location: `${value?.city}, ${value?.state}`,
                              //   },
                              // }}
                              // to={"#"}
                              // style={{ marginLeft: "-10%" }}
                            >
                              {" "}
                              Download
                            </a>
                          </div>
                        </div>
                      </>
                    ))}
                  {fieldLink?.length !== 0 &&
                    fieldLink?.map((value, index) => (
                      <>
                        <div
                          className="row"
                          style={{
                            // borderBottom:
                            //   index === fieldData.indexOf(fieldData?.slice(-1)[0])
                            //     ? "none"
                            //     : "2px inset #e6e6e6",
                            marginLeft: "0px",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                            background: "white",
                          }}
                        >
                          <li
                            style={{
                              paddingLeft: "0px",
                              borderBottom: "none",
                              width: "85%",
                              background: "#183b63",
                              // marginBottom: "10px",
                            }}
                            className="col-md-9 col-sm-12"
                          >
                            <h3
                              className="card-title"
                              style={{ fontSize: "18px", marginBottom: "10px" }}
                            >
                              {/* <span style={{ color: "white" }}>
                                {index + 1}.
                              </span>{" "} */}
                              <a
                                href={value.url}
                                target="_blank"
                                style={{
                                  pointerEvents: "none",
                                  color: "white",
                                  textTransform: "capitalize",
                                }}
                              >
                                {value.name}
                              </a>
                            </h3>
                          </li>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              zIndex: 1,
                              // marginRight: value.name.length > 49 ? "" : "30% ",
                            }}
                          >
                            <a
                              className="green-btn mr20 collateraldownloadbtn"
                              // download
                              // href={value.url}
                              href={"javascript:void(0)"}
                              // target="_blank"
                              // Content-Disposition="attachment"
                              // filename={value.url}
                              // to={{
                              //   pathname: `/${"past-projects"}${value?.pagelink}`,
                              //   state: {
                              //     // blogName: value?.title,
                              //     // blogText: value?.body,
                              //     // blogDate: moment(value?.publish_date).format(
                              //     //   "MMM Do YYYY"
                              //     // ),
                              //     // blogAuthor: value?.author,
                              //     // blogImage: value?.thumbnail_link,
                              //     // authorImage: placeholderAuthor,
                              //     // authorName: value?.author[0]?.name,
                              //     pageLink: value?.pagelink,
                              //     location: `${value?.city}, ${value?.state}`,
                              //   },
                              // }}
                              // to={"#"}
                              // style={{ marginLeft: "-10%" }}
                            >
                              {" "}
                              Download
                            </a>
                          </div>
                        </div>
                      </>
                    ))}
                </ul>
                {/* <ul>
                  {fieldLink?.length !== 0 &&
                    fieldLink?.map((value, index) => (
                      <>
                        <div
                          className="row"
                          style={{
                            // borderBottom:
                            //   index === fieldData.indexOf(fieldData?.slice(-1)[0])
                            //     ? "none"
                            //     : "2px inset #e6e6e6",
                            marginLeft: "0px",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                            background: "white",
                          }}
                        >
                          <li
                            style={{
                              paddingLeft: "0px",
                              borderBottom: "none",
                              width: "85%",
                              background: "#183b63",
                              // marginBottom: "10px",
                            }}
                            className="col-9"
                          >
                            <h3
                              className="card-title"
                              style={{ fontSize: "18px", marginBottom: "10px" }}
                            >
                              <span style={{ color: "white" }}>
                                {index + 1}.
                              </span>{" "}
                              <a
                                href={value.url}
                                target="_blank"
                                style={{
                                  pointerEvents: "none",
                                  color: "white",
                                  textTransform: "capitalize",
                                }}
                              >
                                {value.name}
                              </a>
                            </h3>
                          </li>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              zIndex: 1,
                              // marginRight: value.name.length > 49 ? "" : "30% ",
                            }}
                          >
                            <a
                              className="green-btn mr20"
                              // download
                              // href={value.url}
                              href={"javascript:void(0)"}
                              // target="_blank"
                              // Content-Disposition="attachment"
                              // filename={value.url}
                              // to={{
                              //   pathname: `/${"past-projects"}${value?.pagelink}`,
                              //   state: {
                              //     // blogName: value?.title,
                              //     // blogText: value?.body,
                              //     // blogDate: moment(value?.publish_date).format(
                              //     //   "MMM Do YYYY"
                              //     // ),
                              //     // blogAuthor: value?.author,
                              //     // blogImage: value?.thumbnail_link,
                              //     // authorImage: placeholderAuthor,
                              //     // authorName: value?.author[0]?.name,
                              //     pageLink: value?.pagelink,
                              //     location: `${value?.city}, ${value?.state}`,
                              //   },
                              // }}
                              // to={"#"}
                              style={{ marginLeft: "-10%" }}
                            >
                              {" "}
                              Download
                            </a>
                          </div>
                        </div>
                      </>
                    ))}
                </ul> */}
              </div>
              <div
                style={{
                  marginTop: "-3px",
                  flexShrink: "unset",
                  width: "unset",
                  maxWidth: "unset",
                  paddingRight: "unset",
                  paddingLeft: "unset",
                  marginTop: "unset",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    // marginBottom: "41px",
                    fontSize: "30px",
                    fontFamily: "Chivo, sans-serif",
                    color: "black",
                    // marginTop: "15px",
                  }}
                >
                  {"Live Tweets"}
                </p>

                <div className="twitter-stack-header">
                  <div
                    className="tweets-header"
                    style={{
                      // paddingRight: "5px",
                      color: "transparent",
                    }}
                  >
                    Waste to Wealth Latest Tweets
                  </div>
                </div>
                <div
                  style={{
                    width: "330px",
                    height: "270px",
                    overflow: "scroll",
                    overflowX: "hidden",
                    border: "lightgray 1px solid",
                    borderBottomLeftRadius: "6px",
                    borderBottomRightRadius: "6px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    marginTop: "-5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      height: "fit-content",
                      // justifyContent: "left",
                      textAlign: "justify",
                    }}
                  >
                    {/* <p>
                                "Lorem Ipsum is simply dummy text of the
                                printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever
                                since the 1500s."
                              </p> */}
                    {/* <img src={tweetsPlace} /> */}
                    <div className="twitter-embed" style={{ width: "100%" }}>
                      <a
                        className="twitter-timeline"
                        data-theme="light"
                        data-tweet-limit="5"
                        data-chrome="noheader nofooter noborders"
                        href="https://twitter.com/wasteto_wealth"
                      >
                        Loading Tweets..
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            class="yellow-line"
            style={{ zIndex: 1, position: "relative", top: "5%" }}
          ></div>
          <div
            class="blue-line"
            style={{ zIndex: 1, position: "relative" }}
          ></div>
          <div
            class="green-line"
            style={{
              position: "relative",
              top: "-12%",
              // marginBottom: "-10px",
              zIndex: 1,
            }}
          ></div> */}
          {/* <div className="pressmaindiv" style={{ marginBottom: "5%" }}></div> */}

          <div className="pressmaindiv" style={{ marginBottom: "3%" }}></div>
          <div className="pressmaindiv2" style={{ marginBottom: "4%" }}></div>
          <div
            className="boxWithShadow currentpolicyreports container"
            // style={{ marginTop: "3%" }}
            style={{ display: "none" }}
          >
            {pressReleaseData?.length !== 0 && (
              <>
                {/* <div
                  className="pressmaindiv"
                  style={{ marginBottom: "5%" }}
                ></div> */}
                <div
                  className="row"
                  style={{ float: "right", marginRight: "1%" }}
                >
                  <div class="large-tree"></div>
                  <div class="small-tree" style={{ marginTop: "20%" }}></div>
                </div>
                <h1 class="section-heading-release">
                  <p>Press Release</p>
                </h1>
              </>
            )}

            <div className="tab-content">
              <ul className="card-listing tab-pane actsPage active currentintpolicypress">
                {pressReleaseData?.map((value, index) => (
                  <li
                    style={{
                      paddingLeft: "0px",
                      borderBottom:
                        index ===
                        pressReleaseData.indexOf(pressReleaseData?.slice(-1)[0])
                          ? "none"
                          : "2px inset #e6e6e6",
                    }}
                  >
                    <h3 className="card-title">
                      {/* <span>{index + 1}.</span>{" "} */}
                      <a
                        href={value.url}
                        target="_blank"
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {value.name}
                      </a>
                    </h3>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div className="pressmaindiv2" style={{ marginBottom: "5%" }}></div> */}
          {/* {pressReleaseData?.length !== 0 && (
            <>
              <div
                class="yellow-line"
                style={{ zIndex: 1, position: "relative", top: "5%" }}
              ></div>
              <div
                class="blue-line"
                style={{ zIndex: 1, position: "relative", top: "auto" }}
              ></div>
              <div
                class="green-line"
                style={{
                  position: "relative",
                  top: "-12%",
                  // marginBottom: "5%",
                  zIndex: 1,
                }}
              ></div>
            </>
          )} */}

          {data && (
            <div className="disclaimer">
              {ReactHtmlParser(data?.disclaimer)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => {
//
// }
export default ActAndPolicy;
