import { connect } from "react-redux";
import react, { useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import TechnologyIntervention from "../dependencies/component/TechnologyIntervention";
import OverView from "../dependencies/component/OverView";
import ContentDetail from "../details/detailscomponents/ContentDetail";
import DeploymentDetails from "./detailscomponents/DeploymentDetails";
import SuitablityType from "./detailscomponents/SuitablityType";
import MoreInformation from "./detailscomponents/MoreInformation";
import ContactDetails from "./detailscomponents/ContactDetails";
import Source from "./detailscomponents/Source";
import Capex from "./detailscomponents/Capex";
import AnnualOandM from "./detailscomponents/AnnualOandM";
import Breadcrumb from "../../common/Breadcrumbs";
import { getKmDetails } from "../dependencies/action";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

import dummyImg from "../../../assests/images/dummy_img.png";
import "./style.scss";

import { initGA, logPageView } from "../../../dependencies/utils/analytics";
import logobar1 from "./detailsassets/logobar1.jpg";
import Phase2bkup from "./Phase2bkup";
import { useState } from "react";

import { findFlagUrlByIso2Code } from "country-flags-svg";
const items = [
  { to: "/", label: "/" },
  { to: "/validated-technologies-listing", label: "Technology Repository" },
  {
    to: window.location.pathname
      ? window.location.pathname
      : "/validated-technologies-listing",
    label: "Technology Details",
    // state: { techName: props.location.state.techName },
    active: true,
  },
];

const ValidatedTechnologyDetails = (props) => {
  // console.log(props.location.state.techName)
  const phase2div = useRef();
  const readmorebtn = useRef();
  const contactdiv = useRef();
  const contactdiv2 = useRef();
  const [contactVisible, setContactVisible] = useState(true);

  const [readMore, setReadMore] = useState(false);

  const [finishStatus, setfinishStatus] = useState(false);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      // alert("finishStatus")
      localStorage.setItem("redirectedfrom", "FromDetailPage");
      setfinishStatus(true);
      props.history.push("/validated-technologies-listing");
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  useEffect(() => {
    // phase2div.current.style.display = "none";
  }, []);

  const { getKmDetails, kmDetails } = props;
  useEffect(() => {
    // alert(window.location.pathname)
    let str = window.location.pathname;
    getKmDetails(str.split("/")[2]);
    console.log("capexdata", kmDetails?.capex);
  }, []);

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  return (
    <div className="ValidatedTechnologiesDetails">
      <div className="bg-about-validated-tech custom-slider-nextPrev">
        <Breadcrumb
          data={items}
          displayName={"Technology Repository"}
          type={"validatedTechnology"}
        />
        <div>
          <div className="common-listing-title col-md-12">
            {kmDetails?.head_line && ReactHtmlParser(kmDetails?.head_line)}
          </div>
        </div>

        <OverView
          MainPDF={kmDetails?.MainPDF}
          data={kmDetails}
          mainTitle={kmDetails?.Title}
          body={kmDetails?.DetailedDescription}
          category={kmDetails?.category}
          MainImage={kmDetails?.MainImage}
          GemLinks={kmDetails?.GemLink}
          suitable={kmDetails?.SpecialConditions}
          wasteSubTheme={kmDetails?.Subthemes}
        />
        {kmDetails && (
          <TechnologyIntervention
            data={kmDetails}
            // country={kmDetails?.country.country_code}
            flagurl={
              kmDetails?.country?.country_code != null
                ? findFlagUrlByIso2Code(kmDetails?.country?.country_code)
                : ""
            }
          />
        )}
        {readMore == false && <ContactDetails data={kmDetails} />}
        <div
          className="form-row"
          style={{
            width: "100%",
            backgroundColor: "#e1f3e7",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "14%",
            marginTop: "20px",
          }}
        >
          <p>
            <div
              className="btn green-btn"
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {" "}
              {readMore == false ? "Read More" : "Read Less"}
            </div>
          </p>
        </div>
        {readMore == true && (
          <div>
            {kmDetails?.tech_desc && (
              <ContentDetail
                data={kmDetails}
                type="BriefTechnology"
                content={kmDetails?.tech_desc}
                heading={"Brief Technology Description"}
              />
            )}
            {kmDetails?.capacities_available && (
              <ContentDetail
                data={kmDetails}
                type="CapacitiesAvailable"
                heading={"Capacities Available"}
                content={kmDetails?.capacities_available}
              />
            )}
            {kmDetails?.suitability_for_types_g && (
              <SuitablityType data={kmDetails} />
            )}

            {kmDetails?.ideal_work_condition && (
              <ContentDetail
                data={kmDetails}
                type="IdealWorkingCondition"
                heading={"Ideal Working Condition"}
                content={kmDetails?.ideal_work_condition}
              />
            )}
            {(kmDetails?.deployment_details &&
              kmDetails?.deployment_details?.length == 0) ||
            (kmDetails?.deployment_details &&
              kmDetails?.deployment_details[0].capacity == null &&
              kmDetails?.deployment_details[1].capacity == null &&
              kmDetails?.deployment_details[2].capacity == null) ? null : (
              <DeploymentDetails data={kmDetails} />
            )}

            {kmDetails?.gov_clients && (
              <ContentDetail
                data={kmDetails}
                type="GovernmentClients"
                heading={"Government Clients"}
                content={kmDetails?.gov_clients}
              />
            )}
            {kmDetails?.typical_area_requirement && (
              <ContentDetail
                data={kmDetails}
                type="TypicalAreaReq"
                heading={"Typical Area Requirement (m2)"}
                content={kmDetails?.typical_area_requirement}
              />
            )}

            {kmDetails?.capex &&
            kmDetails?.capex.capex_desc == null &&
            kmDetails?.capex.largest == null &&
            kmDetails?.capex.medium == null &&
            kmDetails?.capex.smallest == null ? null : (
              <Capex data={kmDetails} />
            )}

            {kmDetails?.annualOMCost &&
            kmDetails?.annualOMCost.annual_o_m_desc == null &&
            kmDetails?.annualOMCost.largest == null &&
            kmDetails?.annualOMCost.medium == null &&
            kmDetails?.annualOMCost.smallest == null ? null : (
              <AnnualOandM data={kmDetails} />
            )}
            {/* <Phase2bkup /> */}
            {kmDetails?.wtwCertificate?.files?.length == 0 &&
            kmDetails?.technologyBrief?.files?.length == 0 &&
            kmDetails?.presentation?.files?.length == 0 &&
            kmDetails?.testReports?.files?.length == 0 &&
            kmDetails?.experienceCertificates?.files?.length == 0 &&
            kmDetails?.photographs?.files?.length == 0 &&
            kmDetails?.videos?.files?.length == 0 ? null : (
              <MoreInformation data={kmDetails} />
            )}

            <ContactDetails data={kmDetails} />
            {kmDetails?.source && (
              <Source
                data={kmDetails}
                type="Source"
                heading={"Source"}
                content={kmDetails?.source}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    kmDetails: state.KnowledgeManagmentReducer.kmDetails.data,
  };
};
export default connect(mapStateToProps, { getKmDetails })(
  withRouter(ValidatedTechnologyDetails)
);
