import { CurrentInterventionService } from './service';
import {
    CURRENT_INTERVENTION_LIST,
    INTERVENTION_TECNOLOGY,
    CURRENT_INTERVENTION_DETAILS,
    FILTER_DATA
} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';

export function getChallengeEventList(page, categoryId, city, search, subtheme, state,date) {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let currentInterventionList = await CurrentInterventionService.getCurrentInterventionList(page, categoryId, city, search ,subtheme, state,date);
        dispatch({
            type: CURRENT_INTERVENTION_LIST,
            payload: currentInterventionList
        })
        dispatch(isLoading(false))
    }
}

export function getTechnologyType() {
    return async function (dispatch) {
        let technologyType = await CurrentInterventionService.getTechnologyType();
        dispatch({
            type: INTERVENTION_TECNOLOGY,
            payload: technologyType
        })
    }
}



export function getCurrentInterventionDetails(url) {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let currentInterventionDetails = await CurrentInterventionService.getCurrentInterventionDetails(url);
        
        dispatch({
            type: CURRENT_INTERVENTION_DETAILS,
            payload: currentInterventionDetails
        })
        dispatch(isLoading(false))
    }
}

export function getFilterData() {
    return async function (dispatch) {
        dispatch(isLoading(true))
        let filterData = await CurrentInterventionService.filterData();
        dispatch({
            type: FILTER_DATA,
            payload: filterData
        })
        dispatch(isLoading(false))
    }
}