import { HomeServices } from './service';
import {
    CURRENT_INTERVENTION,
    QUOTES,
    FEATURED_TECHNOLOGY,
    IMPORTANT_CHALLENGES,
    BANNER,
    LATEST_NEWS,
    UPCOMMING_EVENTS
} from '../../../constants/actionConstants';
import { isLoading } from '../../../dependencies/action/action';

export const getHomePageData = (params) => dispatch => {
    dispatch(isLoading(true))
    return getBindData(params).then((data) => {
        const { currentInterventionList, quotes, featuredTechnology, importantChallenges, latestNews, upcommingEvents, banner } = data;
        if (currentInterventionList) {
            dispatch({
                type: CURRENT_INTERVENTION,
                payload: currentInterventionList
            })
        }
        if (quotes) {
            dispatch({
                type: QUOTES,
                payload: quotes
            })
        }
        if (featuredTechnology) {
            dispatch({
                type: FEATURED_TECHNOLOGY,
                payload: featuredTechnology
            })
        }
        if (importantChallenges) {
            dispatch({
                type: IMPORTANT_CHALLENGES,
                payload: importantChallenges
            })
        }
        if (banner) {
            dispatch({
                type: BANNER,
                payload: banner
            })
        }
        if (banner) {
            dispatch({
                type: LATEST_NEWS,
                payload: latestNews
            })
        }
        if (banner) {
            dispatch({
                type: UPCOMMING_EVENTS,
                payload: upcommingEvents
            })
        }

        dispatch(isLoading(false))
    })

}



async function getBindData() {
    const currentInterventionList = await HomeServices.currentInervention().catch(e => { return { data: [], status: false } });
    const quotes = await HomeServices.quotes().catch(e => { return { data: [], status: false } });
    const featuredTechnology = await HomeServices.featuredTechnology().catch(e => { return { data: [], status: false } });
    const importantChallenges = await HomeServices.importantChallenges().catch(e => { return { data: [], status: false } });
    const banner = await HomeServices.banner().catch(e => { return { data: [], status: false } });
    const latestNews = await HomeServices.latestNews().catch(e => { return { data: [], status: false } });
    const upcommingEvents = await HomeServices.upcommingEvents().catch(e => { return { data: [], status: false } });

    return { currentInterventionList, quotes, featuredTechnology, importantChallenges, latestNews, upcommingEvents, banner }
}