import downloadIcon from "../../../../assests/icons/download.svg";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const ActAndPolicy = (props) => {
  console.log("press and reports props", props);
  const { onClickTab, data, subtype, fieldData, pressReleaseData } = props;
  // console.log("fieldData name length", fieldData[0].name.length);
  // console.log("fieldData name length", fieldData[1].name.length);
  // const lastArrayelement = fieldData?.slice(-1)[0];
  // console.log("lastArrayelement", lastArrayelement);
  const downloadFile = (url, fileName) => {
    //  fileName = fileName+".pdf"
    //Create XMLHTTP Request.
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      let blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      let isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };
  return (
    <div className=" common-listing tab-pane active actsAndPolicies">
      <div className="row">
        <div className="w-100">
          <div className="boxWithShadow currentpolicyreports container reportsmaindiv">
            {fieldData?.length !== 0 && (
              <div
                className="row"
                style={{ float: "right", marginRight: "1%" }}
              >
                <div class="large-tree"></div>
                <div class="small-tree" style={{ marginTop: "20%" }}></div>
              </div>
            )}

            {fieldData?.length !== 0 && (
              <h1 class="section-heading-release">
                <p>Reports</p>
              </h1>
            )}

            <div className="tab-content">
              <ul className="card-listing tab-pane actsPage active currentintpolicy">
                {fieldData?.map((value, index) => (
                  <>
                    <div
                      className="row"
                      style={{
                        // borderBottom:
                        //   index === fieldData.indexOf(fieldData?.slice(-1)[0])
                        //     ? "none"
                        //     : "2px inset #e6e6e6",
                        marginLeft: "0px",
                        justifyContent: "space-between",
                      }}
                    >
                      <li style={{ paddingLeft: "0px", borderBottom: "none" }}>
                        <h3 className="card-title">
                          <span>{index + 1}.</span>{" "}
                          <a
                            href={value.url}
                            target="_blank"
                            style={{
                              pointerEvents: "none",
                              color: "#3570ab",
                              textTransform: "capitalize",
                            }}
                          >
                            {value.name}
                          </a>
                        </h3>
                      </li>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // marginRight: value.name.length > 49 ? "" : "30% ",
                        }}
                      >
                        <a
                          className="green-btn mr20"
                          download
                          href={value.url}
                          // target="_blank"
                          // Content-Disposition="attachment"
                          // filename={value.url}
                          // to={{
                          //   pathname: `/${"past-projects"}${value?.pagelink}`,
                          //   state: {
                          //     // blogName: value?.title,
                          //     // blogText: value?.body,
                          //     // blogDate: moment(value?.publish_date).format(
                          //     //   "MMM Do YYYY"
                          //     // ),
                          //     // blogAuthor: value?.author,
                          //     // blogImage: value?.thumbnail_link,
                          //     // authorImage: placeholderAuthor,
                          //     // authorName: value?.author[0]?.name,
                          //     pageLink: value?.pagelink,
                          //     location: `${value?.city}, ${value?.state}`,
                          //   },
                          // }}
                          // to={"#"}
                        >
                          {" "}
                          Download
                        </a>
                      </div>
                    </div>
                  </>
                ))}
              </ul>
            </div>
          </div>
          {/* <div
            class="yellow-line"
            style={{ zIndex: 1, position: "relative", top: "5%" }}
          ></div>
          <div
            class="blue-line"
            style={{ zIndex: 1, position: "relative" }}
          ></div>
          <div
            class="green-line"
            style={{
              position: "relative",
              top: "-12%",
              // marginBottom: "-10px",
              zIndex: 1,
            }}
          ></div> */}
          {/* <div className="pressmaindiv" style={{ marginBottom: "5%" }}></div> */}

          <div className="pressmaindiv" style={{ marginBottom: "3%" }}></div>
          <div className="pressmaindiv2" style={{ marginBottom: "4%" }}></div>
          <div
            className="boxWithShadow currentpolicyreports container"
            // style={{ marginTop: "3%" }}
          >
            {pressReleaseData?.length !== 0 && (
              <>
                {/* <div
                  className="pressmaindiv"
                  style={{ marginBottom: "5%" }}
                ></div> */}
                <div
                  className="row"
                  style={{ float: "right", marginRight: "1%" }}
                >
                  <div class="large-tree"></div>
                  <div class="small-tree" style={{ marginTop: "20%" }}></div>
                </div>
                <h1 class="section-heading-release">
                  <p>Press Release</p>
                </h1>
              </>
            )}

            <div className="tab-content">
              <ul className="card-listing tab-pane actsPage active currentintpolicypress">
                {pressReleaseData?.map((value, index) => (
                  <li
                    style={{
                      paddingLeft: "0px",
                      borderBottom:
                        index ===
                        pressReleaseData.indexOf(pressReleaseData?.slice(-1)[0])
                          ? "none"
                          : "2px inset #e6e6e6",
                    }}
                  >
                    <h3 className="card-title">
                      <span>{index + 1}.</span>{" "}
                      <a
                        href={value.url}
                        target="_blank"
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {value.name}
                      </a>
                    </h3>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div className="pressmaindiv2" style={{ marginBottom: "5%" }}></div> */}
          {pressReleaseData?.length !== 0 && (
            <>
              <div
                class="yellow-line"
                style={{ zIndex: 1, position: "relative", top: "5%" }}
              ></div>
              <div
                class="blue-line"
                style={{ zIndex: 1, position: "relative", top: "auto" }}
              ></div>
              <div
                class="green-line"
                style={{
                  position: "relative",
                  top: "-12%",
                  // marginBottom: "5%",
                  zIndex: 1,
                }}
              ></div>
            </>
          )}

          {data && (
            <div className="disclaimer">
              {ReactHtmlParser(data?.disclaimer)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => {
//
// }
export default ActAndPolicy;
