import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import "./style.scss";
import { getHeaderData } from "./dependencies/action";
import UpperFooter from "../UpperFooter";

// import logoMainFooter from '../../../assets/images/footer_logo.png';
// import arrowRight from '../../../assets/icons/arrow-right.png';
import emailIcon from "../../../assests/svg/ic_email.svg";
import phoneIcon from "../../../assests/svg/ic_phone.svg";

import rightArrow from "../../../assests/svg/ic_arrow-right.svg";
//import logo from '../../../assests/images/main-logo.svg';
import logo from "../../../assests/images/waste-to-wealth-logo.png";
import logo2x from "../../../assests/images/logo@2x.png";
import logo3x from "../../../assests/images/logo@3x.png";
import ReactHtmlParser from "react-html-parser";
import globeIcon from "../../../assests/icons/globe.svg";

const Footer = (props) => {
  const { getHeaderData, footerData } = props;
  useEffect(() => {
    getHeaderData();
  }, [getHeaderData]);
  return (
    <footer className="page-footer">
      <UpperFooter />
      <div className="container">
        <div className="footer-head-row">
          <div className="col-4 col-sm-3 d-flex align-items-center">
            <span className="logoSmall">
              <img alt="Brand" src={logo} alt="" />
            </span>
          </div>
          <div className="col-8 col-sm-6 col-lg-5 col-xl-4 ml-auto">
            <Link
              to={
                footerData &&
                footerData?.footerBecomePartners &&
                footerData?.footerBecomePartners?.url
              }
              className="green-btn footer-button"
            >
              {footerData?.footerBecomePartners?.name}
              <img src={rightArrow} alt="" />
            </Link>
          </div>
        </div>
        <div className="row mobile-row footer-detail-row">
          {footerData &&
            Array.isArray(footerData?.footerMain) &&
            footerData?.footerMain?.length > 0 &&
            footerData?.footerMain.map((value, index) => {
              return (
                <div className={`col-md-3 row-count-${index}`} key={index}>
                  <h5 className="footer-heading">{value?.linkName}</h5>
                  <ul className="list-unstyled mb-0">
                    {value &&
                      Array.isArray(value?.childrens) &&
                      value.childrens.length > 0 &&
                      value.childrens.map((menu, index) => {
                        return (
                          <li className="mb-0" key={index}>
                            <a
                              className="footer-sub-text-link"
                              href={menu && menu?.linkUrl}
                            >
                              {menu?.linkName}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
          <div className="col-md-3 contact-col">
            <h5 className="footer-heading contact-underline">Contact Us</h5>
            <ul className="list-unstyled mb-0">
              <li className="d-flex align-items-center">
                <span className="email-icon">
                  <img width="35" src={emailIcon} alt="" />
                </span>
                {/* <img src={emailIcon} alt="" /> */}
                <div className="footer-sub-text-link-contact">
                  {footerData?.footerContactDetail?.email}
                </div>
              </li>
              {/* <li>
                                <span className="phone-icon"><img width="37" src={phoneIcon} alt="" /></span>
                                
                                <a href="#!" className="footer-sub-text-link-contact"> {footerData?.footerContactDetail?.phoneNumber}</a>
                            </li> */}
              <li className="d-flex align-items-center">
                <span className="globe-icon"></span>
                <a
                  href={footerData?.footerContactDetail?.url}
                  className="footer-sub-text-link-contact"
                >
                  {" "}
                  {footerData?.footerContactDetail?.url}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-3 col-lg-4">
              <span className="copyright-text-footer">
                {ReactHtmlParser(footerData?.footerCR?.copyright)}
              </span>
            </div>
            <div className="col-12 col-md-4 col-lg-4 ml-auto tnc-wrap d-flex">
              <a href={footerData?.footerCR?.privacyPolicy} target="_blank">
                Privacy Policy
              </a>
              <a href={footerData?.footerCR?.TnC} target="_blank">
                Terms of Use & Disclaimer
              </a>
            </div>
            <div className="col-12 col-md-5 col-lg-4  ml-auto social-media-col">
              <h5 className="footer-heading contact-underline footer-main-alignment follow-us-text">
                Follow Us:
              </h5>
              {footerData &&
                Array.isArray(footerData?.socialicons) &&
                footerData?.socialicons.length > 0 &&
                footerData?.socialicons.map((value, index) => {
                  return (
                    <a href={value?.url} key={index} target="_blank">
                      <img src={value?.image} alt="" />
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
const mapStateToProps = (state) => {
  return {
    footerData: state.FooterReducer.footerData,
  };
};
export default connect(mapStateToProps, { getHeaderData })(withRouter(Footer));
