import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const EvaluationProcess = (props) => {
  const { data, className } = props;
  const stepClass = ["one-step", "two-step", "three-step", "four-step"];
  return (
    <div className={`evaluation-process ${className}`}>
      <div className="container">
        <div className="container-wrap">
          <div
            className="evaluation-heading"
            style={{ fontFamily: "'Chivo',sans-serif", fontSize: "43px" }}
          >
            {/* {ReactHtmlParser(data?.title)} */}
            Evaluation Process & Submission
          </div>
          <div className="row">
            {data &&
              Array.isArray(data) &&
              data?.length > 0 &&
              data?.map((value, index) => {
                return (
                  <div key={index} className="col-sm-3 evaluation-step-card">
                    <div className="evaluation-card">
                      <div>
                        <div
                          className={
                            index == 3
                              ? "divider-end"
                              : index == 1 || index == 2
                              ? "divider"
                              : "divider-start"
                          }
                        ></div>
                        <div className="evaluation-step col-sm-5">
                          <div
                            className={`step-number ${stepClass[index]}`}
                          ></div>
                          <img src={`${value?.link}`} alt="" />
                        </div>
                      </div>
                      <div className="evaluation-step-content">
                        <div className="evaluation-title">
                          {ReactHtmlParser(value?.title)}
                        </div>
                        <div className="evaluation-content">
                          {ReactHtmlParser(value?.body)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluationProcess;
