import api from "../../../dependencies/utils/Api";
import {
  CURRENT_INTERVENTION_LIST,
  INTERVENTION_TECNOLOGY,
  CURRENT_INTERVENTION_DETAILS,
  FILTER_DATA,
} from "../../../constants/ApplicationUrl";

export const CurrentInterventionService = {
  getCurrentInterventionList(
    page,
    categoryId,
    city,
    search,
    usetype,
    subtheme,
    scale,
    condition,
    state,
    duration
  ) {
    return api
      .GET(
        `${CURRENT_INTERVENTION_LIST}?page=${page}&category=${categoryId}&city=${city}&search=${search}&usetype=${usetype}&subtheme=${subtheme}&scale=${scale}&condition=${condition}&state=${state}&duration=${duration}`
      )
      .then((response) => {
        const { data = { data } } = response?.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getTechnologyType() {
    return api
      .GET(INTERVENTION_TECNOLOGY)
      .then((response) => {
        const { data = { data } } = response?.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getCurrentInterventionDetails(url) {
    return api
      .GET(`${CURRENT_INTERVENTION_DETAILS}/${url}`)
      .then((response) => {
        const { data = { data } } = response?.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  filterData() {
    return api
      .GET(FILTER_DATA)
      .then((response) => {
        const { data: { responseCode, data } = {} } = response || {};
        if (responseCode === 200) {
          return data;
        } else {
          return Promise.reject(response?.data);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
};
