import { combineReducers } from 'redux';
import {
    CHALLANGE_EVENT_LISTING,
    CHALLANGE_EVENT_DETAILS,
    CHALLENGE_TECHNOLOGY,
    CHALLENGE_CATEGORY
} from '../../../constants/actionConstants';

const challengeEventList = (state = {}, action) => {
    switch (action.type) {
        case CHALLANGE_EVENT_LISTING:
            return { ...action.payload };
        default:
            return state;
    }
}
const challengeEventDetails = (state = {}, action) => {
    switch (action.type) {
        case CHALLANGE_EVENT_DETAILS:
            return { ...action.payload };
        default:
            return state;
    }
}

const challengeTechnologyList = (state = {}, action) => {
    switch (action.type) {
        case CHALLENGE_TECHNOLOGY:
            return { ...action.payload };
        default:
            return state;
    }
}
const challengeCategoryList = (state = {}, action) => {
    switch (action.type) {
        case CHALLENGE_CATEGORY:
            return { ...action.payload };
        default:
            return state;
    }
}
const ChallengeEventReducer = combineReducers({
    challengeEventList,
    challengeEventDetails,
    challengeTechnologyList,
    challengeCategoryList
});

export default ChallengeEventReducer;


