// import logo from './logo.svg';
import "./App.css";
// import "./appmaintainance.css";
import AppRoutes from "./routes.js";
import Loader from "./components/common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <ToastContainer
        autoClose={5000}
      />
      <AppRoutes />
      <Loader />
    </div>

    // maintainance screen
    // <div class="container">
    //   <div class="box">
    //     <div class="animation">
    //       <div class="one spin-one"></div>
    //       <div class="two spin-two"></div>
    //       <div class="three spin-one"></div>
    //     </div>
    //     <h1 style={{fontSize:"20px"}}>Under maintenance</h1>
    //     <div  style={{fontSize:"30px",width:"100%",justifyContent:"center"}}>
    //     <p  style={{fontSize:"30px",paddingLeft:"50px",paddingRight:"50px",justifyContent:"center"}}>
    //       Waste to Wealth portal is under maintenance and upgrade. We will be
    //       back soon.
    //     </p>
    //     <p>
    //       <a
    //         href="javascript:void(0)"
    //         target="_blank"
    //         style={{
    //           color:"#f6921e",
    //           textDecoration: "none",
    //           pointerEvents: "none",
    //           fontSize:"30px"
    //         }}
    //       >
    //         Please continue to visit us.
    //       </a>
    //     </p>
    //     </div>
    //   </div>
    // </div>
  );
}

export default App;
