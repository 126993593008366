/**
 * This   component use for to display the list of recognise fellow
 */

import profileImg from "../../../../../../assests/images/profile-img.jpeg";
import ReactHtmlParser from "react-html-parser";
import "./index.scss";

const MentorList = (props) => {
  const { data } = props;

  console.log("Mentor List=>", props);
  return (
    <div className="fellows-wrapper">
      <div className="fellows-block">
        <div className="row">
          {data &&
            data.length > 0 &&
            data.map((value, index) => {
              return (
                <div className="col-md-2" key={index}>
                  <div className="about-mentor-detail" key={index}>
                    <div className="about-fellows-margin-all">
                      <img
                        src={value?.image ? value.image : profileImg}
                        className="about-mentor-image"
                      />
                      <div className="about-fellows-name">{value?.name}</div>
                      <div className="about-fellows-designation">
                        {value?.category}
                      </div>
                      <div className="about-mentor-designation">
                        {ReactHtmlParser(value?.current_job_position)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MentorList;
