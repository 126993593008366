import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import envalopIcon from "../../assests/svg/circle-envalop.svg";
import downloadIcon from "../../assests/svg/circle-download.svg";
import { getPledgeData } from "./dependencies/action";
import { getNumberOfVistiors } from "../CitizenCorner/dependencies/action";
import CertificatePage from "./CertificatePage";
import SendOtp from "./Modal/SendOtp";
import "./style.scss";

import { initGA, logPageView } from "../../dependencies/utils/analytics";

const Pledge = (props) => {
  const { url } = useParams();
  const { getPledgeData, PledgeData, getNumberOfVistiors, numberOfVistiors } =
    props;
  const [showModal, setShowModal] = useState(false);
  const [certificateData, setCertificateData] = useState("");
  const [pledgeCount, setPledgeCount] = useState(0);
  const [showCertificate, setShowCertificate] = useState(false);
  const [dataType, setDataType] = useState("");

  useEffect(() => {
    getPledgeData(url);
  }, [url]);

  const certificateValue = (data) => {
    if (data) {
      setCertificateData(data);
      setShowCertificate(true);
      setShowModal(false);
    }
  };

  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  const modalData = (data) => {
    setDataType(data);
    setShowModal(!showModal);
  };
  useEffect(() => {
    getNumberOfVistiors();
  }, []);

  return (
    <div className="pledge-outer">
      <SendOtp
        dataType={dataType}
        getData={certificateValue}
        onClose={() => {
          setShowModal(false);
        }}
        isOpen={showModal}
        className="send-otp-modal-content"
      />
      {showCertificate ? (
        <CertificatePage
          Studentswhoarenoteligible={
            PledgeData?.list &&
            PledgeData?.list.length > 0 &&
            PledgeData?.list[0].Studentswhoarenoteligible
          }
          numberOfVistiors={numberOfVistiors}
          url={window.location.href}
          PledgeName={
            PledgeData?.list &&
            PledgeData?.list.length > 0 &&
            PledgeData?.list[0].PledgeName
          }
          pledgeCount={PledgeData?.pledgeCount}
          data={certificateData}
          bannerImage={
            PledgeData &&
            PledgeData?.list?.length > 0 &&
            PledgeData?.list[0].BannerImage
          }
        />
      ) : (
        <div className="container">
          <div className="pledge-inner">
            <div className="banner-wrap">
              <img
                src={
                  PledgeData &&
                  PledgeData?.list?.length > 0 &&
                  PledgeData?.list[0].BannerImage
                }
              />
            </div>
            <div className="content">
              <div className="take-pledge-wrap text-center">
                <Link
                  to={`pledge-form/${url}`}
                  className="green-btn take-pledge-btn"
                >
                  {" "}
                  <i className="hand-icon" />
                  Take Pledge
                </Link>
              </div>
              <h3 className="commitment">
                If already taken Pledge, Get the Certificate of Commitment
              </h3>
              <div className="get-certificate-wrap">
                <button
                  onClick={() => {
                    modalData("mail");
                  }}
                  className="bordered-btn green-border email"
                >
                  <img width="68" src={envalopIcon} />
                  Send Certificate to your Email | Mobile
                </button>
                <button
                  onClick={() => {
                    modalData("download");
                  }}
                  to=""
                  className="bordered-btn green-border download"
                >
                  <img width="68" src={downloadIcon} />
                  Download Certificate
                </button>
              </div>
            </div>
          </div>
          <div className="count-wrap d-flex align-item-center justify-content-center">
            <div className="call-no">
              <span className="phoneCal-icon"></span>
              <span className="number">{numberOfVistiors}</span>Visitors on this
              portal
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    PledgeData: state?.PledgeReducer?.PledgeData?.data,
    numberOfVistiors: state.CitizenCornerReducer.numberOfVistiors?.data,
  };
};

export default connect(mapStateToProps, { getPledgeData, getNumberOfVistiors })(
  withRouter(Pledge)
);
