import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import SectionTitle from '../../../../common/SectionTitle';
import SectionDescription from '../../../../common/SectionDescription';
import './style.scss';

const CurrentIntervention = (props) => {
    const { data } = props
    return (
        <div className="current-intervention-content clearfix">
            <div className='ci_wrap main-container'>
                <div className="clearfix section-title-wrap">
                    <div className="title-wrap">
                        <SectionTitle heading={ReactHtmlParser(data?.heading)} />
                        <SectionDescription description={ReactHtmlParser(data.subtitle)} />
                    </div>
                    <div className="title-right-block">
                        <Link to="current-intervention-listing">
                            <button type="button" className="green-btn">View All</button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    {
                        data
                        && Array.isArray(data.list)
                        && data.list.length > 0
                        && data.list.map((value, index) => {
                            return <Fragment key={index}>
                                <div className="col-12 col-sm-6 col-md-4 mb20 d-flex">

                                    <div className="card ci_card hover-zoom">
                                        <div className="card-img-wrap">

                                            <button className='ci_card_tag'>{value?.tag} </button>
                                            {/*<button className={`ci_card_tag ci_card_tag_${index+1}`}>{value?.tag} </button>*/}
                                            <img className="card-img-top ci_card_img" src={value?.image} alt="Card cap" />
                                        </div>
                                        <div className="ci_card_body">
                                            <h5 className="card-title ci_card_title">{value?.title}</h5>
                                            <p className='ci_card_startdate'>Start Date:<span>&nbsp;{value?.date}</span></p>
                                            <p className="card-text ci_card_desc ellipsis-3"> {ReactHtmlParser(value?.body)}</p>
                                            {
                                                index === 2 ?
                                                    <Link to={`success-stories/su-dhara-community-engagement-vertiver`} className="link-goto-bottom">
                                                    </Link>
                                                    :
                                                    <Link to={`current-intervention-listing${value?.pagelink}`} className="link-goto-bottom">
                                                    </Link>

                                            }


                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default CurrentIntervention;