import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import "./style.scss";
const ContentDetail = (props) => {
  const { data, content, heading, type } = props;
  const value = {
    list: [
      {
        thumbnail: "https://www.wastetowealth.gov.in/s3fs/2021-04/psa.svg",
      },
      {
        thumbnail:
          "https://www.wastetowealth.gov.in/s3fs/2021-04/invest-india_0.png",
      },
      {
        thumbnail: "https://www.wastetowealth.gov.in/s3fs/2021-04/psa.svg",
      },
      {
        thumbnail:
          "https://www.wastetowealth.gov.in/s3fs/2021-04/invest-india_0.png",
      },
    ],
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      // {
      //   breakpoint: 767,
      //   settings: {
      //     variableWidth: false,
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };
  return (
    <div className="content-detail">
      <div className="container">
        <h1 className="suitable-section-heading">{ReactHtmlParser(heading)}</h1>

        <p className="content-sub-content">
          {ReactHtmlParser(content)}
          {type === "GovernmentClients" && (
            <div className=" gov-client-detail-row">
              <Slider {...settings}>
                {data?.gov_logo &&
                  Array.isArray(data?.gov_logo) &&
                  data?.gov_logo.length > 0 &&
                  data?.gov_logo.map((value, index) => {
                    return (
                      <div className="gov-client-detail" key={index}>
                        <div className="gov-client-margin">
                          <div className="card gov-client-card" key={index}>
                            <div className="row no-gutters gov-client-card-row">
                              <div className="gov-client-card-image-holder">
                                <img
                                  className="card-img gov-client-card-image"
                                  src={value?.image}
                                  // src={
                                  //   "https://uat.wastetowealth.gov.in/s3fs/2022-03/GettyImages-1078103724-576x486_0.jpeg"
                                  // }
                                />
                              </div>
                              <div>
                                <div className="col-lg-12">
                                  <div className="col-lg-12 gov-client-card-body">
                                    <h5 className="card-title gov-client-card-title clock-icon">
                                      {" "}
                                      {value?.date}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          )}
          {/* Lorem ips dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia
                        deserunt mollit anim id est laborum. */}
        </p>
      </div>
    </div>
  );
};

export default ContentDetail;
