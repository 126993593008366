/**
 * This  component use for list of current intervention list with filter
 */
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import InputField from "../../../components/common/InputField";
import Button from "../../../components/common/Button";
import ListWithPagination from "../../common/ListWithPagination";
import ReactHtmlParser from "react-html-parser";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
} from "../dependencies/action";
import LeftSideCategory from "../../../components/common/LeftSideCategory";
import GlobalDropDown from "../../common/GlobalDropDown";
import Breadcrumb from "../../common/Breadcrumbs";
import "./style.scss";
import { initGA, logPageView } from "../../../dependencies/utils/analytics";

const items = [
  { to: "/", label: "/" },
  { to: "/current-intervention-listing", label: "What We Do" },
  { to: "/current-intervention-listing", label: "Our Sentinels" },
  {
    to: "/current-intervention-listing",
    label: "Current Projects",
    active: true,
  },
];

const CurrentInterventionsList = (props) => {
  const {
    getChallengeEventList,
    currentInterventionList,
    getTechnologyType,
    interventionTechnology,
    getFilterData,
    filterData,
  } = props;
  const [page, setPage] = useState(0);
  const [categoryId, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [pageStatus, setPageStatus] = useState(false);
  const [isMoreFilter, setMoreFilter] = useState(false);
  const handleShowMoreFiltere = () => setMoreFilter(!isMoreFilter);
  const defaultFormData = {
    state: "",
    city: "",
    projectTimeline: "",
    useType: "",
    subTheme: "",
    scale: "",
    specialCondition: "",
    search: "",
  };
  const [formData, setFormData] = useState(defaultFormData);

  const changePageNumber = (pageNumber) => {
    setPageStatus(false);
    setPage((pageNumber - 1) * 6);
  };
  useEffect(() => {
    getChallengeEventListData();
  }, [page, categoryId]);
  const handleInputChange = (e, name) => {
    const { value } = e.target;

    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };

  useEffect(() => {
    // to call the initialise google analytics funtion
    try {
      initGA();
      window.GA_INITIALIZED = true;
      // to save the number og visitors on this side
      logPageView();
    } catch (error) {}
  }, [window]);

  const getChallengeEventListData = () => {
    getChallengeEventList(
      page,
      categoryId,
      formData.city,
      formData.search,
      formData.useType,
      formData.subTheme,
      formData.scale,
      formData.specialCondition,
      formData.state,
      formData.projectTimeline
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setPage(0);
    setPageStatus(true);
    getChallengeEventListData();
  };

  useEffect(() => {
    getTechnologyType();
    getFilterData();
  }, []);

  const getCategoryId = (Id) => {
    setCategory(Id);
  };
  return (
    <div className="CurrentInterventions">
      <Breadcrumb data={items} displayName={"Current Projects"} />
      <div className="filter-row filter-row-common">
        <div className="container">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="row">
              <div className="col-md-1 filter-text">Filter by</div>
              <div className="user-type-dropdown col-2 select-box">
                <InputField
                  containerStyle="form-group "
                  onChange={handleInputChange}
                  options={""}
                  value={formData.city}
                  name={"city"}
                  placeholder={"Enter City/Village"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-md-2 col-sm-4 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.state}
                  value={formData.state}
                  name={"state"}
                  defaultOptionText={"Select State"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-md-2 col-sm-4 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.Timelines}
                  value={formData.projectTimeline}
                  name={"projectTimeline"}
                  defaultOptionText={"Select Project Timeline"}
                  error=""
                />
              </div>
              <div className="user-type-dropdown col-md-2 col-sm-4 select-box">
                <GlobalDropDown
                  containerStyle="form-group "
                  selectTagStyle="form-control dropdown-media"
                  onChange={handleInputChange}
                  options={filterData?.list?.useType}
                  value={formData.useType}
                  name={"useType"}
                  defaultOptionText={"Select Use Type"}
                  error=""
                />
              </div>

              <div className="col-md-1 apply-section">
                <Button
                  type="submit"
                  text="Apply"
                  className="green-btn filter-btn"
                />

                <div
                  className={`toggle-section ${isMoreFilter ? "active" : ""}`}
                  onClick={handleShowMoreFiltere}
                >
                  <div className="toggle-btn">
                    {isMoreFilter ? "Less" : "More"}
                  </div>
                  <div className="toggle-arrow "></div>
                </div>
              </div>
            </div>
            {isMoreFilter && (
              <div className=" row no-gutters list_top_section">
                <div className="col-md-1 filter-text"></div>
                <div className="user-type-dropdown col-2 select-box">
                  <GlobalDropDown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={filterData?.list?.subThemes}
                    value={formData.subTheme}
                    name={"subTheme"}
                    defaultOptionText={"Select Sub Theme"}
                    error=""
                  />
                </div>
                <div className="user-type-dropdown  col-2 select-box">
                  <GlobalDropDown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={filterData?.list?.Scale}
                    value={formData.scale}
                    name={"scale"}
                    defaultOptionText={"Select scale"}
                    error=""
                  />
                </div>
                <div className="user-type-dropdown  col-2 select-box">
                  <GlobalDropDown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={filterData?.list?.conditions}
                    value={formData.specialCondition}
                    name={"specialCondition"}
                    defaultOptionText={"Select Special Condition"}
                    error=""
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="bottom-section-list">
        <div className="container common-listing">
          <div className="row">
            <div className="col-3 sidebar">
              <div className="category-box">
                <h3 className="sub-title f19 ">
                  {ReactHtmlParser(interventionTechnology?.heading)}
                </h3>
                <ul>
                  {interventionTechnology &&
                    Array.isArray(interventionTechnology.list) &&
                    interventionTechnology.list.length > 0 &&
                    interventionTechnology.list.map((value, index) => {
                      let Id = value?.id;
                      return (
                        <li>
                          <LeftSideCategory
                            onClickReply={() => {
                              getCategoryId(Id);
                            }}
                            className={
                              value?.id === categoryId ? "category-active" : ""
                            }
                            categoryName={value?.name}
                            count={value?.count}
                            key={index}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>
              <Link
                to="/validated-technologies-listing"
                className="col-12 green-btn filter-btn"
              >
                Waste To Wealth Technology Repository
              </Link>
            </div>
            <div className="col-9 list-container">
              <div className="boxWithShadow">
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-listing-title currintheading">
                      {ReactHtmlParser(currentInterventionList?.heading)}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="search">
                      <form onSubmit={(e) => onSubmit(e)}>
                        <InputField
                          type="search"
                          name="search"
                          placeholder="Search Projects"
                          value={formData.search}
                          onChange={handleInputChange}
                          containerClass={"form-group"}
                          className="subscribe-input-field"
                        />
                        <div className="btn-group">
                          <Button
                            icon={
                              <img src={searchIcon} className="search-icon" />
                            }
                            type="submit"
                            text=""
                            className="list-submit-button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <ListWithPagination
                  data={currentInterventionList?.list}
                  itemsCountPerPage={5}
                  totalItemsCount={currentInterventionList?.totalCount}
                  pageRangeDisplayed={5}
                  onChangePagination={changePageNumber}
                  checkStatus={pageStatus}
                  nextUrl={"current-intervention-listing"}
                  type={"current-intervention"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentInterventionList:
      state.CurrentInterventionReducer.currentInterventionList.data,
    interventionTechnology:
      state.CurrentInterventionReducer.interventionTechnology.data,
    filterData: state.CurrentInterventionReducer.filterData,
  };
};

export default connect(mapStateToProps, {
  getChallengeEventList,
  getTechnologyType,
  getFilterData,
})(withRouter(CurrentInterventionsList));
