import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import InputField from '../../../components/common/InputField'
import Button from '../../../components/common/Button'
import ListWithPagination from '../../common/ListWithPagination'
import ReactHtmlParser from 'react-html-parser'
import searchIcon from '../../../assests/svg/searchIcon.svg'

import {
  getChallengeEventList,
  getTechnologyType,
} from '../dependencies/action'
import LeftSideCategory from '../../../components/common/LeftSideCategory'
import GlobalDropDown from '../../common/GlobalDropDown'
import Breadcrumb from '../../common/Breadcrumbs'
import './style.scss'
import { initGA, logPageView } from '../../../dependencies/utils/analytics'

const items = [
  { to: '/', label: '/' },
  { to: '/fellowship-home', label: 'Knowledge Repository' },
  { to: '/fellowship-home', label: 'Fellowship', active: true },
]

const FellowshipListing = props => {
  const {
    getChallengeEventList,
    currentInterventionList,
    getTechnologyType,
    interventionTechnology,
    filterData,
  } = props
  const [page, setPage] = useState(0)
  const [categoryId, setCategory] = useState('')
  const [isviewPartner, setviewPartner] = useState(false)
  const [show, setShow] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [partnerData, setPartnerData] = useState(null)
  const defaultFormData = {
    state: '',
    city: '',
    subTheme: '',
    search: '',
  }
  const [formData, setFormData] = useState(defaultFormData)

  const changePageNumber = pageNumber => {
    setPage((pageNumber - 1) * 6)
  }
  useEffect(() => {
    getChallengeEventListData()
  }, [page, categoryId])

  const handleInputChange = (e, name) => {
    const { value } = e.target

    setFormData(user => {
      return { ...user, [name]: value }
    })
  }

  const getChallengeEventListData = () => {
    getChallengeEventList(
      page,
      categoryId,
      formData.city,
      formData.search,
      formData.subTheme,
      formData.state
    )
  }

  const onSubmit = e => {
    e.preventDefault()
    getChallengeEventListData()
  }

  useEffect(() => {
    getTechnologyType();
    console.log(currentInterventionList)
    // getFilterData();
  }, [])

  useEffect(() => {
    try {
      initGA()
      window.GA_INITIALIZED = true
      logPageView()
    } catch (error) {
      
    }
    }, [window])
  const getCategoryId = Id => {
    setCategory(Id)
  }

  const modalToggler = data => {
    setModalData(data)
    setShow(true)
  }

  const toggleViewPartner = data => {
    setPartnerData(data)
    setviewPartner(!isviewPartner)
  }

  return (
    <div className="FellowshipListing">
      <Breadcrumb data={items} displayName={'Fellowship Listing'} />

      <div className="bottom-section-list">
        <div className="container common-listing fellowship-listing">
          <div className="view-all-wrap d-flex justify-content-start mr-left20">
            <Link
              className=" align-items-center green-circle-link"
              to={{ pathname: '/fellowship-home' }}
            >
              <i className="back-circle-goto-link-green" /> <p>Back</p>
            </Link>
          </div>
          <div className="row">
            <div className="col-md-3 sidebar">
              <div className="category-box">
                <h3 className="sub-title f19">
                  {ReactHtmlParser(interventionTechnology?.heading)}
                </h3>
                <ul>
                  {interventionTechnology &&
                    Array.isArray(interventionTechnology.list) &&
                    interventionTechnology.list.length > 0 &&
                    interventionTechnology.list.map((value, index) => {
                      let Id = value?.id
                      return (
                        <li>
                          <LeftSideCategory
                            onClickReply={() => {
                              getCategoryId(Id)
                            }}
                            className={
                              value?.id === categoryId ? 'category-active' : ''
                            }
                            categoryName={value?.name}
                            count={value?.count}
                            key={index}
                          />
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
            <div className="col-md-9 list-container">
              <div className="boxWithShadow">
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-listing-title">Fellowship</div>
                  </div>

                  <div className="col-md-6">
                    <div className="search">
                      <form onSubmit={e => onSubmit(e)}>
                        <InputField
                          type="search"
                          name="search"
                          placeholder="Search Fellowship"
                          value={formData.search}
                          onChange={handleInputChange}
                          containerClass={'form-group'}
                          className="subscribe-input-field"
                        />
                        <div className="btn-group">
                          <Button
                            icon={
                              <img src={searchIcon} className="search-icon" />
                            }
                            type="submit"
                            text=""
                            className="list-submit-button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <ListWithPagination
                  data={currentInterventionList?.list}
                  itemsCountPerPage={5}
                  totalItemsCount={currentInterventionList?.totalCount}
                  pageRangeDisplayed={5}
                  onChangePagination={changePageNumber}
                  // buttonName={'Apply Now'}
                  nextUrl={'fellowship-listing'}
                  modalToggle={modalToggler}
                  showPartner={isviewPartner}
                  partnerData={partnerData}
                  isViewPartner={toggleViewPartner}
                  type={'fellowship'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentInterventionList:
      state.UpcomingInterventionsReducer.currentInterventionList.data,
    interventionTechnology:
      state.UpcomingInterventionsReducer.interventionTechnology.data,
    filterData: state.UpcomingInterventionsReducer.filterData,
  }
}

const mapDispatchToProps = dispatch => ({
  getChallengeEventList: bindActionCreators(getChallengeEventList, dispatch),
  getTechnologyType: bindActionCreators(getTechnologyType, dispatch),
  // getFilterData: bindActionCreators(getFilterData, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FellowshipListing))
