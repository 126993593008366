import { StaticPageService } from "./service";
import { ABOUT_US } from "../../../constants/actionConstants";
import { isLoading } from "../../../dependencies/action/action";
import {
  CURRENT_INTERVENTION,
  QUOTES,
  FEATURED_TECHNOLOGY,
  IMPORTANT_CHALLENGES,
  BANNER,
  LATEST_NEWS,
  UPCOMMING_EVENTS,
  ABOUT_COHORT_GET_STATE_DETAILS,
  FELLOWSHIP_INITIATIVES,
  SWACHHTA_SARTHI_COHORT_MAP,
  SWACHHTA_SARTHI_ABOUT_MENTOR,
  SWACHHTA_SARTHI_MEET_OUR_FELLOWS,
} from "../../../constants/actionConstants";

export function getSwachhtaSarthiFellowship() {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let getSwachhtaSarthiFellowship = await StaticPageService.getAboutUs();
    dispatch({
      type: ABOUT_US,
      payload: getSwachhtaSarthiFellowship,
    });
    dispatch(isLoading(false));
  };
}

export function getSwachhtaSarthiMap(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let getSwachhtaSarthiMap = await StaticPageService.getMapFellowship(url);

    dispatch({
      type: SWACHHTA_SARTHI_COHORT_MAP,
      payload: getSwachhtaSarthiMap,
    });
    dispatch(isLoading(false));
  };
}

export function getSwachhtaSarthiMentor(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let getSwachhtaSarthiMentor = await StaticPageService.getMentorFellowship(
      url
    );

    dispatch({
      type: SWACHHTA_SARTHI_ABOUT_MENTOR,
      payload: getSwachhtaSarthiMentor,
    });
    dispatch(isLoading(false));
  };
}

export function getSwachhtaSarthiFellows(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let getSwachhtaSarthiFellows = await StaticPageService.getFellowsFellowship(
      url
    );

    dispatch({
      type: SWACHHTA_SARTHI_MEET_OUR_FELLOWS,
      payload: getSwachhtaSarthiFellows,
    });
    dispatch(isLoading(false));
  };
}

// export function getFellowshipInitiatives() {
//   return async function (dispatch) {
//     let getFellowshipInitiatives =
//       await StaticPageService.getFellowshipInitiatives();
//     dispatch({
//       type: FELLOWSHIP_INITIATIVES,
//       payload: getFellowshipInitiatives,
//     });
//   };
// }

export function getFellowshipInitiatives(url) {
  return async function (dispatch) {
    // let getFellowshipInitiatives =
    //   await StaticPageService.getFellowshipInitiatives();
    // dispatch({
    //   type: FELLOWSHIP_INITIATIVES,
    //   payload: getFellowshipInitiatives,
    // });

    dispatch(isLoading(true));
    let getFellowshipInitiatives =
      await StaticPageService.getFellowshipInitiatives(url);

    dispatch({
      type: FELLOWSHIP_INITIATIVES,
      payload: getFellowshipInitiatives,
    });
    dispatch(isLoading(false));
  };
}

export function getAboutCohortStateDetails() {
  let params = {
    searchField: "",
    pageFilter: {
      currentPage: 0,
      includeCount: true,
      pageSize: 30,
      sortField: "fellowName",
      sortOrder: "ASC",
    },
    filter: [
      {
        key: "studentCategory",
        value: ["CATEGORY_A", "CATEGORY_B", "CATEGORY_C"],
      },
      {
        key: "state",
        value: ["Maharashtra"],
      },
    ],
  };
  return async function (dispatch) {
    dispatch(isLoading(true));
    let getAboutCohortStateDetails =
      await StaticPageService.getaboutcohortStateDetails(params);
    dispatch({
      type: ABOUT_COHORT_GET_STATE_DETAILS,
      payload: getAboutCohortStateDetails,
    });
    dispatch(isLoading(false));
  };
}

export const getHomePageData = (params) => (dispatch) => {
  dispatch(isLoading(true));
  return getBindData(params).then((data) => {
    const { quotes, latestNews } = data;

    if (quotes) {
      dispatch({
        type: QUOTES,
        payload: quotes,
      });
    }

    if (latestNews) {
      dispatch({
        type: LATEST_NEWS,
        payload: latestNews,
      });
    }

    dispatch(isLoading(false));
  });
};

async function getBindData() {
  // const currentInterventionList = await HomeServices.currentInervention().catch(e => { return { data: [], status: false } });
  const quotes = await StaticPageService.quotes().catch((e) => {
    return { data: [], status: false };
  });
  // const aboutcohort = await StaticPageService.getaboutcohortStateDetails().catch(e => { return { data: [], status: false } });
  // const featuredTechnology = await HomeServices.featuredTechnology().catch(e => { return { data: [], status: false } });
  // const importantChallenges = await HomeServices.importantChallenges().catch(e => { return { data: [], status: false } });
  // const banner = await HomeServices.banner().catch(e => { return { data: [], status: false } });
  const latestNews = await StaticPageService.latestNews().catch((e) => {
    return { data: [], status: false };
  });

  const fellowshipInitiatives =
    await StaticPageService.getFellowshipInitiatives().catch((e) => {
      return { data: [], status: false };
    });
  // const upcommingEvents = await HomeServices.upcommingEvents().catch(e => { return { data: [], status: false } });

  return { quotes, latestNews, fellowshipInitiatives };
}
