import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import CategoryA from "./CategoryA";
import CategoryB from "./CategoryB";
import CategoryC from "./CategoryC";
import Radio from "../common/FormElements/Radio";
import GlobalDropDown from "../common/GlobalDropDown";
import { getDropDownData } from "./dependencies/action";
import "./style.scss";
import ReactHtmlParser from "react-html-parser";
import Breadcrumb from "../common/Breadcrumbs";
import {
  LanguageType,
  LanguageTypes,
} from "./dependencies/constant/dropdownConstant";
import icon from "../../assests/icons/icon-language.svg";
import { Link } from "react-router-dom";

import { initGA, logPageView } from "../../dependencies/utils/analytics";
const items = [
  { to: "/", label: "/" },
  { to: "/fellowship", label: "Fellowship Details" },
];

const FellowshipForm = (props) => {
  const { getDropDownData, fellowshipData, newLanguage } = props;

  const location = useLocation();

  const [language, setLanguage] = useState("");
  const [category, setCategory] = useState("");
  const [formValue, setformValue] = useState();
  const [categoryName, setCategoryName] = useState();
  const [date, setDate] = useState("");
  // const handleInputChange = (e, name) => {
  //     const { value } = e.target;
  //     setLanguage(value);
  //     localStorage.setItem('language', value)
  // }

  // console.log('fellowshipData',fellowshipData)
  useEffect(() => {
    try {
      initGA();
      window.GA_INITIALIZED = true;
      logPageView();
    } catch (error) {}
  }, [window]);
  const handleInputChangeTab = (e, name, index, label) => {
    const { value } = e.target;
    setCategory(value);
    setformValue(index);
    setCategoryName(label);
  };
  useEffect(() => {
    // setCategory(fellowshipData?.list?.fellowship_category[0]?.value)
    setLanguage(newLanguage);
  }, [newLanguage]);

  useEffect(() => {
    // console.log('some_value',location.state.date); // result: 'some_value'
    if (location?.state?.date) {
      setDate(location.state.date);
    } else {
      props.history.push(
        "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022"
      );
    }
  }, [location]);

  useEffect(() => {
    getDropDownData();
  }, []);
  let countDownDate = new Date(date).getTime();
  // Update the count down every 1 second
  let x = setInterval(function () {
    // Get today's date and time
    let now = new Date().getTime();

    // Find the distance between now and the count down date
    let distance = countDownDate - now;
    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x);
      props.history.push(
        "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022"
      );
      // return <Redirect to='/fellowship'  />
      // document.getElementById("demo").innerHTML = "EXPIRED";
    }
  }, 1000);
  return (
    <>
      <Breadcrumb
        data={items}
        displayName={"Swachhta Saarthi Fellowship Form"}
      />
      <div className="fellowship-form">
        <div className="container fellowship-form-container">
          <div className="select-category">
            <div className="view-all-wrap d-flex justify-content-start mr-left20">
              <Link
                className=" align-items-center green-circle-link"
                to={{
                  pathname:
                    "/fellowship-listing/swachhta-saarthi-fellowship-cohort-2022",
                }}
              >
                <i className="back-circle-goto-link-green" /> <p>Back</p>
              </Link>
            </div>

            {/*<div className="selec-language clearfix">
                            <img src={icon} width="23" height="25" />
                            <GlobalDropDown
                                defaultOptionDisable
                                required
                                name="language"
                                options={LanguageTypes[language]}
                                // label={changeLanguage(language, 'education', 'label')}
                                containerClass={"language-dropdown-outer"}
                                className='language-dropdown'
                                value={language}
                                onChange={handleInputChange}
                            />
    </div>*/}

            <div className="category-container">
              <h2>Please help us, with your fellowship category ?</h2>
              <div className="row">
                {fellowshipData &&
                  fellowshipData?.list &&
                  fellowshipData?.list?.fellowship_category.map(
                    (data, index) => {
                      return (
                        <label
                          for={`category${index}`}
                          className={`col-md-4 col-sm-12 category-card ${
                            formValue == index ? "active" : ""
                          }`}
                        >
                          <Radio
                            id={`category${index}`}
                            type="radio"
                            label={data?.text}
                            value={data?.value}
                            name="category"
                            index={index}
                            checked={category === data?.value}
                            onChange={handleInputChangeTab}
                          />
                          <div className="text">
                            {ReactHtmlParser(data?.description)}
                          </div>
                        </label>
                      );
                    }
                  )}
                <div className="col-12 text-center lang-note">
                  For any other language, feel free to reach out to us at{" "}
                  <p>contact.wastetowealth@investindia.org.in</p>
                </div>
              </div>
            </div>
            {/* <FellowshipFormTab tabData={fellowshipData?.list?.fellowship_category} /> */}
          </div>
          {formValue === 0 && (
            <CategoryA
              categoryName={categoryName}
              category={category}
              language={language}
              classList={fellowshipData?.list?.Category_A}
              stateList={fellowshipData?.list?.state}
              wasteCategory={fellowshipData?.list?.fellowship_waste_category}
            />
          )}
          {formValue === 1 && (
            <CategoryB
              categoryName={categoryName}
              category={category}
              language={language}
              classList={fellowshipData?.list?.Category_B}
              stateList={fellowshipData?.list?.state}
              wasteCategory={fellowshipData?.list?.fellowship_waste_category}
            />
          )}
          {formValue === 2 && (
            <CategoryC
              categoryName={categoryName}
              category={category}
              language={language}
              classList={fellowshipData?.list?.Category_C}
              stateList={fellowshipData?.list?.state}
              wasteCategory={fellowshipData?.list?.fellowship_waste_category}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fellowshipData: state.FelloshipReducer.FelloshipData.data,
    newLanguage: state.LanguageReducer.siteLanguage,
  };
};

export default connect(mapStateToProps, { getDropDownData })(
  withRouter(FellowshipForm)
);
