import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InlineError from '../InlineError';
import './style.scss';

const TextareaField = (props) => {
  const {
    containerClass, value,
    error, onChange, label, required, name,
    hasInvalidSubmitOccurred, placeholder,
    disabled, onBlur, getRef = "", className, rows, labelClass, ...rest
  } = props;

  const [touched, setTouched] = useState(false);

  const handleInputBlur = (e, name) => {
    setTouched(true);
    if (onBlur) {
      onBlur(e, name);
    }
  };


  const displayError = touched || hasInvalidSubmitOccurred;

  return (
    <div
      className={`input-field-container textarea-container ${containerClass || ''} ${displayError && error ? 'error' : ''}`}
    >
      <div className="inner-wrap">
        {label &&
          <label
            htmlFor=""
            className={`mb-15 ${labelClass}`}
          >
            {label}
            {
              required
              && (
                <span className="required">&nbsp;*</span>
              )
            }
          </label>}
        {
          <textarea
            ref={getRef}
            name={name}
            disabled={disabled}
            value={value}
            className={`input-element ${value ? 'filled' : ''} ${className} ${displayError && error ? 'error' : ''}`}
            onChange={e => onChange(e, name)}
            onBlur={e => handleInputBlur(e, name)}
            placeholder={placeholder}
            rows={rows}
            {...rest}
          />
        }
      </div>
      {
        displayError && error && (
          <InlineError message={error} />
        )
      }
    </div>
  );

}


TextareaField.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  hasInvalidSubmitOccurred: PropTypes.bool,
  getRef: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string
};

TextareaField.defaultProps = {
  disabled: false,
  value: '',
  error: '',
  onChange: () => { },
  label: '',
  required: false,
  name: '',
  mask: '',
  placeholder: '',
  containerClass: '',
  onBlur: () => { },
  hasInvalidSubmitOccurred: false,
  getRef: () => { },
  className: '',
  labelClass: '',
};

export default TextareaField;