import { combineReducers } from 'redux';
import {
    PARTNER_WITH_US_DETAILS,
} from '../../../constants/actionConstants';


const partnerWithUSDetails = (state = {}, action) => {
    switch (action.type) {
        case PARTNER_WITH_US_DETAILS:
            return { ...action.payload };
        default:
            return state;
    }
}
const PartnerWithUsDetail = combineReducers({
    partnerWithUSDetails
});

export default PartnerWithUsDetail;


