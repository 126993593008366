/**
 * This  component use for display the list with pagination
 */
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Link, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import clockIcon from "../../../assests/icons/time.svg";
import calendar_list_icon from "../../../assests/icons/calendar_list_icon.png";
import locationIcon from "../../../assests/icons/location.svg";
import placeholderAuthor from "../../../assests/images/placeholder_author.png";

import "./style.scss";
import moment from "moment";

const ListWithPagination = (props) => {
  const {
    checkStatus,
    activePageNo,
    data,
    itemsCountPerPage,
    totalItemsCount,
    pageRangeDisplayed,
    onChangePagination,
    buttonName,
    nextUrl,
    type,
    onClickEvent,
    modalToggle,
    isViewPartner,
    showPartner,
    history,
    sortByChallenge,
  } = props;

  const authornames = [
    { id: "1630", name: "Neque porro ", image: "/s3fs/2022-04/abc.jpg" },
    { id: "1631", name: "maximus ", image: "/s3fs/2022-04/abc.jpg" },
  ];

  const [activePage, setActivePage] = useState();
  const [partnerData, setPartnerData] = useState(null);
  let isPartnerView = false;
  let partnerIndex = null;
  // alert(activePageNo)
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    onChangePagination(pageNumber);
  };
  // useEffect(()=>{
  // 	alert(moment(new Date()).isAfter("July 24, 2021 23:59:59","year") )

  // })
  const toggleViewPartner = (index) => {
    let allCards = document.getElementsByClassName("view-partner-logos");
    let button = document.getElementsByClassName("partner-toggle-view");
    // console.log(allCards.length, index);
    isPartnerView = !isPartnerView;
    // console.log(isPartnerView);
    for (var i = 0, len = allCards.length; i < len; i++) {
      if (isPartnerView === true) {
        allCards[index].style.display = "flex";
        button[index].classList.add("active");
      } else {
        allCards[index].style.display = "none";
        button[index].classList.remove("active");
      }
    }

    setPartnerData(data);
  };

  useEffect(() => {
    console.log("validatedtechlist", props);
    if (checkStatus) {
      setActivePage(1);
    }
  }, [checkStatus, isPartnerView]);
  useEffect(() => {
    // alert("activePageNo useeffect"+activePageNo)
    if (activePageNo) {
      setActivePage(activePageNo);
    }
  }, [activePageNo]);
  let historyStore = useHistory();

  const formApplyValidation = (date) => {
    historyStore.push({
      pathname: "/fellowship-form",
      state: { date: date },
    });
  };
  console.log(data);

  return (
    <>
      <ul className="card-listing">
        {data && Array.isArray(data) && data.length > 0 ? (
          data.map((value, index) => {
            // console.log(data);
            let bodyText = value?.field_short_description;
            if (!bodyText) {
              if (type === "articles-case") {
                bodyText = value?.short_description;
              } else if (type === "past-projects") {
                bodyText = value?.card_Short_desc;
              } else {
                bodyText = value?.body;
              }
            }
            return (
              <li key={index}>
                <div className="story-card">
                  {type === "articles-case" ? (
                    <Link
                      className={
                        type === "validated-technologies"
                          ? "card-image2"
                          : "card-image"
                      }
                      // to={`/${nextUrl}${value?.pagelink}`}
                      // to={`/articles-blogpage`}
                      to={{
                        // pathname: `/articles-blogpage`,
                        pathname: `/${"articles-case-studies"}${
                          value?.pagelink
                        }`,
                        state: {
                          // blogName: value?.title,
                          // blogText: value?.body,
                          // blogDate: moment(value?.publish_date).format(
                          //   "MMM Do YYYY"
                          // ),
                          // blogAuthor: value?.author,
                          // blogImage: value?.thumbnail_link,
                          // authorImage: placeholderAuthor,
                          // authorName: value?.author[0]?.name,
                          pageLink: value?.pagelink,
                        },
                      }}
                    >
                      <div>
                        {value?.category && (
                          <button className="ci_card_tag">
                            {" "}
                            {value?.category}
                          </button>
                        )}
                        <img
                          className="card-img"
                          src={value?.thumbnail_link}
                          // src={
                          //   "https://uat.wastetowealth.gov.in/s3fs/2021-06/Picture6_4.jpg"
                          // }
                          alt="Card"
                        />
                      </div>
                    </Link>
                  ) : type === "past-projects" ? (
                    <div
                      className={
                        type === "validated-technologies"
                          ? "card-image2"
                          : "card-image"
                      }
                    >
                      {value?.category && (
                        <button className="ci_card_tag">
                          {" "}
                          {value?.category}
                        </button>
                      )}
                      {type === "validated-technologies" ? (
                        <img
                          className="card-img"
                          src={value?.thumbnail_image}
                          alt="Card"
                        />
                      ) : (
                        <img
                          className="card-img"
                          src={`https://www.wastetowealth.gov.in${value?.image}`}
                          alt="Card"
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={
                        type === "validated-technologies"
                          ? "card-image2"
                          : "card-image"
                      }
                    >
                      {value?.category && (
                        <button className="ci_card_tag">
                          {" "}
                          {value?.category}
                        </button>
                      )}
                      {type === "validated-technologies" ? (
                        <img
                          className="card-img"
                          src={value?.thumbnail_image}
                          alt="Card"
                        />
                      ) : type === "event" ? (
                        <img
                          className="card-img"
                          src={value?.thumb_image}
                          alt="Card"
                        />
                      ) : (
                        <img
                          className="card-img"
                          src={value?.image}
                          alt="Card"
                        />
                      )}
                    </div>
                  )}

                  <div
                    className="card-content"
                    style={{
                      height: type === "past-projects" ? "14em" : "",
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        maxHeight: type === "past-projects" ? "100%" : "",
                        paddingBottom: type === "past-projects" ? "20px" : "",
                        height: type === "past-projects" ? "100%" : "",
                      }}
                    >
                      {type === "articles-case" ? (
                        <>
                          <Link
                            className="card-text"
                            // to={`/${nextUrl}${value?.pagelink}`}
                            // to={`/articles-blogpage`}
                            to={{
                              pathname: `/${"articles-case-studies"}${
                                value?.pagelink
                              }`,
                              state: {
                                // blogName: value?.title,
                                // blogText: value?.body,
                                // blogDate: moment(value?.publish_date).format(
                                //   "MMM Do YYYY"
                                // ),
                                // blogAuthor: value?.author,
                                // blogImage: value?.thumbnail_link,
                                // authorImage: placeholderAuthor,
                                // authorName: value?.author[0]?.name,
                                pageLink: value?.pagelink,
                              },
                            }}
                          >
                            <h4>{value?.title}</h4>
                          </Link>
                          {value?.author && value?.author.length != 0 && (
                            <div className="blog-author flex">
                              <div
                                className="flex"
                                style={{ display: "inherit", width: "100%" }}
                              >
                                {/* <div class="img">
                                <img src={props.location.state?.authorImage} />
                              </div> */}

                                <span class="below-view-by-listauthor">
                                  By:
                                </span>
                                <div style={{ marginLeft: "1%" }}>
                                  {value?.author.length != 0 ? (
                                    value?.author.map((authoritem, index) => (
                                      <>
                                        <div
                                          className="below-blog-name-listauthor"
                                          key={index}
                                        >
                                          <div>
                                            <span>
                                              <div className="below-blog-contentname">
                                                {/* <a href="#">{`${authoritem?.name},`}</a> */}
                                                <a href="#">
                                                  {`${index ? ", " : ""}${
                                                    authoritem?.name
                                                  }`}{" "}
                                                </a>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ))
                                  ) : (
                                    <div className="below-blog-name-listauthor">
                                      <div>
                                        <span>
                                          <div className="below-blog-contentname">
                                            {/* <a href="#">{`${authoritem?.name},`}</a> */}
                                            <a href="#">{"NA"}</a>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <h4 className="card-text">{value?.title}</h4>
                      )}
                      {type !== "validated-technologies" &&
                      value?.date &&
                      type !== "past-projects" ? (
                        <div className="time-location">
                          <div className="ellipsis-1">
                            <img src={clockIcon} />
                            &nbsp;{value?.date}
                          </div>
                          {(value?.location || value?.field_location) && (
                            <div className="ellipsis-1">
                              <img src={locationIcon} />
                              &nbsp;{value?.field_location} {value?.location}
                            </div>
                          )}
                        </div>
                      ) : type !== "validated-technologies" &&
                        type === "articles-case" &&
                        value?.publish_date ? (
                        <div className="time-location">
                          <div className="ellipsis-1">
                            {/* <img src={clockIcon} /> */}
                            <img src={calendar_list_icon} />
                            &nbsp;
                            {moment(value?.publish_date).format(
                              "MMM Do YYYY"
                            ) || "N/A"}
                            {/* &nbsp;{"24 Feb,2021 - 23 Feb,2022"} */}
                          </div>
                        </div>
                      ) : type !== "validated-technologies" &&
                        type === "past-projects" ? (
                        <div className="time-location">
                          <div className="ellipsis-1">
                            <img src={clockIcon} />
                            &nbsp;{value?.date}
                          </div>

                          {value?.city && (
                            <div className="ellipsis-1">
                              <img src={locationIcon} />
                              {/* &nbsp;{value?.date} */}
                              &nbsp;{`${value?.city}, ${value?.state}`}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {type !== "validated-technologies" &&
                        bodyText &&
                        (type === "upcoming-interventions" ? (
                          " "
                        ) : type === "articles-case" ? (
                          <>
                            <p>{ReactHtmlParser(bodyText)}</p>
                            {/* <p className="belowParagraph">
                             {"By: "}
                             <span>
                               {"Strategic Investment Research Unit (SIRU)"}
                             </span>
                           </p> */}
                            {/* <div className="belowinfodiv">
                               <div className="below-view-header">
                                 <div class="below-view-img">
                                  
                                 </div>
                                 <div class="below-view-by">By:</div>
                               </div>
                               <div className="below-view-content">
                                
 
                                 {value?.author.length != 0 ? (
                                   value?.author.map((authoritem, index) => (
                                     <div
                                       className="below-blog-name"
                                       key={index}
                                     >
                                       <div>
                                         <span>
                                           <div className="below-blog-contentname">
                                             <a href="#">{`${authoritem?.name}`}</a>
                                           </div>
                                         </span>
                                       </div>
                                     </div>
                                   ))
                                 ) : (
                                   ""
                                 )}
                               </div>
                             </div> */}
                          </>
                        ) : type === "past-projects" ? (
                          <p>{ReactHtmlParser(bodyText)}</p>
                        ) : type !== "validated-technologies" &&
                          type !== "past-projects" &&
                          type !== "articles-case" &&
                          type !== "current-intervention" ? (
                          <div
                            // style={{ height: "96px" }}
                            className="safaricurrint fellowship-listing-card-body"
                          >
                            <p>{ReactHtmlParser(bodyText)}</p>
                          </div>
                        ) : (
                          // ""

                          <p>{ReactHtmlParser(bodyText)}</p>
                        ))}
                      {type === "validated-technologies" ? (
                        <div>
                          <p className="validate_tech_heading">
                            Technology Name -{" "}
                            <span>{value?.technology_name} </span>
                          </p>
                          <p className="validate_tech_heading">
                            Company/Organisation/Institution Name -{" "}
                            <span>{value?.organization_name} </span>
                          </p>
                          <p className="validate_tech_heading">
                            Source -{" "}
                            {value?.source &&
                            value?.source !== null &&
                            value?.source !== "undefined" &&
                            (value?.source.slice(0, 4) === "http" ||
                              value?.source.slice(0, 4) === "www.") ? (
                              <span className="content-sub-content">
                                <a href={value?.source} target="_blank">
                                  {value?.source}
                                </a>
                              </span>
                            ) : (
                              <span className="content-sub-content">
                                {value?.source}
                              </span>
                            )}{" "}
                          </p>

                          <Link
                            className="green-btn mr20 listcardbtn"
                            to={{
                              pathname: `/${nextUrl}${value?.pagelink}`,
                              state: { techName: value?.title },
                            }}
                            //  to={`/${nextUrl}${value?.pagelink}`}
                          >
                            {" "}
                            Read More
                          </Link>
                        </div>
                      ) : type === "articles-case" ? (
                        <Link
                          className="green-btn mr20"
                          to={{
                            pathname: `/${"articles-case-studies"}${
                              value?.pagelink
                            }`,
                            state: {
                              // blogName: value?.title,
                              // blogText: value?.body,
                              // blogDate: moment(value?.publish_date).format(
                              //   "MMM Do YYYY"
                              // ),
                              // blogAuthor: value?.author,
                              // blogImage: value?.thumbnail_link,
                              // authorImage: placeholderAuthor,
                              // authorName: value?.author[0]?.name,
                              pageLink: value?.pagelink,
                            },
                          }}
                        >
                          {" "}
                          Read More
                        </Link>
                      ) : type === "past-projects" ? (
                        <Link
                          className="green-btn mr20"
                          to={{
                            pathname: `/${"past-projects"}${value?.pagelink}`,
                            state: {
                              // blogName: value?.title,
                              // blogText: value?.body,
                              // blogDate: moment(value?.publish_date).format(
                              //   "MMM Do YYYY"
                              // ),
                              // blogAuthor: value?.author,
                              // blogImage: value?.thumbnail_link,
                              // authorImage: placeholderAuthor,
                              // authorName: value?.author[0]?.name,
                              pageLink: value?.pagelink,
                              location: `${value?.city}, ${value?.state}`,
                            },
                          }}
                          // to={"/past-projects"}
                        >
                          {" "}
                          Read More
                        </Link>
                      ) : (
                        ""
                      )}

                      {type !== "fellowship" && (
                        <div className="dFlex">
                          {type === "event" ? (
                            value?.eventType === "Internal" ? (
                              <Link
                                className="bordered-btn green-border mr20"
                                to={`/${nextUrl}${value?.pagelink}`}
                              >
                                {" "}
                                More Details
                              </Link>
                            ) : (
                              <a
                                className="bordered-btn green-border mr20"
                                href={`${value?.pagelink}`}
                                target="_blank"
                              >
                                {" "}
                                More Details
                              </a>
                            )
                          ) : ["current-intervention"].includes(type) ? (
                            <Link
                              className="green-btn mr20"
                              to={`/${nextUrl}${value?.pagelink}`}
                            >
                              {" "}
                              Read More
                            </Link>
                          ) : // <div> </div>
                          type === "success-stories" ? (
                            <Link
                              className="bordered-btn green-border mr20"
                              to={`/${nextUrl}${value?.pagelink}`}
                            >
                              {" "}
                              More Details
                            </Link>
                          ) : type === "upcoming-interventions" ? (
                            ""
                          ) : sortByChallenge === "current_challenges" ? (
                            <Link
                              className="bordered-btn green-border mr20"
                              to={`/${nextUrl}${value?.pagelink}`}
                            >
                              {" "}
                              More Details
                            </Link>
                          ) : (
                            ""
                          )}

                          {type === "event" ? (
                            value?.eventType === "Internal" ? (
                              <a
                                className="green-btn mr20"
                                onClick={() => {
                                  onClickEvent(
                                    value?.trimTitle,
                                    value?.date,
                                    value?.field_location,
                                    value?.id,
                                    value?.pagelink
                                  );
                                }}
                                style={{
                                  visibility: "hidden",
                                }}
                              >
                                {buttonName}
                              </a>
                            ) : (
                              ""
                            )
                          ) : (
                            type === "challenge-listing" &&
                            value?.applynow !== null && (
                              <>
                                <a
                                  className="green-btn mr20"
                                  href={value?.applynow}
                                  target="_blank"
                                >
                                  {buttonName}
                                </a>
                                {/* <a
                                   className="green-btn mr20"
                                   href="javascript:void(0)"
                                   target="_blank"
                                 >
                                   {"Read More"}
                                 </a> */}
                                <Link
                                  className="green-btn mr20"
                                  to={{
                                    pathname: `/${"challenge-listing"}${
                                      value?.pagelink
                                    }`,
                                    state: {
                                      // blogName: value?.title,
                                      // blogText: value?.body,
                                      // blogDate: moment(value?.publish_date).format(
                                      //   "MMM Do YYYY"
                                      // ),
                                      // blogAuthor: value?.author,
                                      // blogImage: value?.thumbnail_link,
                                      // authorImage: placeholderAuthor,
                                      // authorName: value?.author[0]?.name,
                                      pageLink: value?.pagelink,
                                      title: value?.title,
                                      // location: `${value?.city}, ${value?.state}`,
                                    },
                                  }}
                                  // to="javascript:void(0)"
                                  // to={"/past-projects"}
                                >
                                  {" "}
                                  Read More
                                </Link>
                              </>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {type === "upcoming-intervention" && (
                    <div className="upcoming-interventions-buttons">
                      <button
                        className="bordered-btn green-border mr20"
                        onClick={() => modalToggle(value)}
                      >
                        {" "}
                        Read More <div className="ml-2 green-plus-icon"></div>
                      </button>
                      <button
                        className={`btn mr20 partner-toggle-view toggle-section ${
                          isPartnerView === true ? "active" : ""
                        }`}
                        onClick={() => toggleViewPartner(index)}
                      >
                        {" "}
                        View Partners <div className="ml-2 toggle-arrow "></div>
                      </button>
                    </div>
                  )}
                  {type === "fellowship" &&
                    (console.log(value?.pagelink),
                    (
                      <div className="upcoming-interventions-buttons">
                        <Link
                          className="bordered-btn green-border mr20"
                          to={`/fellowship-listing` + value?.pagelink}
                          // to={`/fellowship`}
                          onClick={() => {
                            localStorage.setItem(
                              "ssfcohort",
                              value?.SSFCohortFilter
                            );
                          }}
                        >
                          {" "}
                          More Details
                        </Link>
                        {/* {moment(new Date()).isAfter(value?.end_date) ? null : (
                           <button
                             onClick={() => formApplyValidation(value?.end_date)}
                             className="green-btn mr20"
                           >
                             {" "}
                             Apply Now
                           </button>
                         )} */}
                      </div>
                    ))}
                </div>
                <div className="view-partner-logos">
                  {/* {value.viewPartners &&
                     value.viewPartners.map((partner) => (
                       <img src={partner} alt="partner" />
                     ))} */}

                  {type === "upcoming-intervention"
                    ? value?.viewPartners && value?.viewPartners?.length > 1
                      ? value?.viewPartners?.map((partner) => (
                          <div
                            className="col-md-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              border: "2px solid lightgray",
                              alignItems: "center",
                              padding: "5px",
                            }}
                          >
                            <img src={partner} alt="partner" />
                          </div>
                        ))
                      : value?.viewPartners?.map((partner) => (
                          <div
                            className="col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              border: "2px solid lightgray",
                              alignItems: "center",
                              padding: "5px",
                            }}
                          >
                            <img src={partner} alt="partner" />
                          </div>
                        ))
                    : value?.viewPartners &&
                      value?.viewPartners?.map((partner) => (
                        <img src={partner} alt="partner" />
                      ))}
                </div>
              </li>
            );
          })
        ) : (
          <div className="no-records">No Records Found</div>
        )}
      </ul>
      <div className="pagination-box">
        {totalItemsCount && totalItemsCount > 0 ? (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={handlePageChange.bind(this)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

ListWithPagination.propTypes = {
  itemsCountPerPage: PropTypes.number,
  totalItemsCount: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  data: PropTypes.array,
  onChangePagination: PropTypes.func,
};

ListWithPagination.defaultProps = {
  itemsCountPerPage: 3,
  totalItemsCount: 0,
  pageRangeDisplayed: 5,
  data: [],
  onChangePagination: () => {},
};

export default withRouter(ListWithPagination);
