/**
 * This  modal component use for to display the image in slider
 */
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import Modal from '../../../../../common/Modal'
import { API_URL } from '../../../../../../constants/constants/ApplicationUrl'

import { Player } from 'video-react'
import 'video-react/dist/video-react.css'

const ImageSlider = props => {
  const { imageList, onClose, deleteReconizedFellow } = props
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  const [isPlay, setIsPlay] = useState(false)

  const onPlayerPlay = () => {
    setIsPlay(true)
  }

  return (
    <div className="gallery-modal">
      <Slider {...settings}>
        {imageList &&
          imageList.length > 0 &&
          imageList.map((value, index) => {
            let array = value.split('.')
            return (
              <div key={index}>
                {array[1] === 'mp4' ? (
                  <Player
                    playsInline
                    src={`${value}`}
                    onPlay={onPlayerPlay}
                    fullscreen={true}
                  />
                ) : (
                  <img src={`${value}`} />
                )}
              </div>
            )
          })}
      </Slider>
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(mapStateToProps)(withRouter(Modal(ImageSlider)))
