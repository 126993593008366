import React, { useState, useEffect } from "react";
import "./style.scss";
import Scrollbar from "react-scrollbars-custom";
import sharefb from "../../../../../assests/svg/ic_fb.svg";
import sharetwiter from "../../../../../assests/svg/ic_twitter.svg";
import sharetinsta from "../../../../../assests/svg/ic_insta.svg";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const GallerySection = (props) => {
  const getVideoId = (url) => {
    // let videoId = url?.split(".be/")[1];
    // let ampersandPosition = videoId?.indexOf("&");
    // if (ampersandPosition !== -1) {
    // 	videoId = videoId?.substring(0, ampersandPosition);
    // }
    // return videoId;
    return url;
  };
  const { data } = props;
  const [isPlay, setIsPlay] = useState(false);
  const [isVideoGallery, setVideoGallery] = useState(true);
  const [videoTitle, setVideoTitle] = useState(
    data !== undefined &&
      data?.list.video[0] !== undefined &&
      data?.list.video[0]?.title
  );
  const [imageTitle, setImageTitle] = useState(
    data !== undefined &&
      data?.list.image[0] !== undefined &&
      data?.list.image[0]?.title
  );
  const [thumbImg, setThumbImg] = useState(
    data?.list.video[0] !== undefined &&
      data?.list.video[0] &&
      data?.list[0]?.video.image
  );
  const [imageThumb, setImageThumb] = useState(
    data?.list.image[0] !== undefined && data?.list[0]?.image.image
  );
  const [activeNumber, setActiveNumber] = useState(1);
  const [ytVideoId, setVideoId] = useState(() =>
    getVideoId(
      data?.list.video[0] !== undefined && data?.list.video[0]?.videoUrl
    )
  );

  const [social, setSocial] = useState({
    url: data?.list.video[0] !== undefined && data?.list.video[0]?.videoUrl,
    title: "Wase To Wealth",
    sepreater: "::",
    size: "2.5rem",
  });

  useEffect(() => {
    setSocial({
      ...social,
      url: data?.list.video[0] !== undefined && data?.list.video[0]?.videoUrl,
    });
  }, [data?.list.video[0]?.videoUrl]);

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
  };
  useEffect(() => {
    setVideoTitle(
      data?.list.video[0]?.title
        ? data?.list.video[0]?.title
        : data?.list.image[0]?.title
    );
    setThumbImg(
      data?.list.video[0]?.image
        ? data?.list.video[0]?.image
        : data?.list.image[0]?.image
    );
    setImageThumb(
      data?.list.image[0] !== undefined && data?.list?.image[0].image
    );
    setVideoId(() => getVideoId(data?.list.video[0]?.videoUrl));
    setVideoGallery(data?.list.video[0] !== undefined ? true : false);
  }, [data]);
  // ytplayer = document.getElementById("movie_player");
  // ytplayer.getCurrentTime();

  const handleVideoThumb = (videoUrl, title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    const videoId = getVideoId(videoUrl);
    setVideoId(videoId);
    setVideoTitle(title);
    setThumbImg(img);
    setIsPlay(false);
  };
  const handleImageThumb = (title, img, activeNumber) => {
    setActiveNumber(activeNumber);
    setImageTitle(title);
    setImageThumb(img);
    setIsPlay(false);
  };
  return (
    <div className="gallery-section">
      <div className="container">
        <div className="gallery-head d-flex">
          {data?.list.video[0] !== undefined && (
            <button
              className="gallery-title mr-2"
              onClick={() => setVideoGallery(true)}
            >
              <h2 className={isVideoGallery ? "active" : ""}>Video Gallery</h2>
            </button>
          )}
          {data?.list.image[0] !== undefined && (
            <button
              className="gallery-title"
              onClick={() => setVideoGallery(false)}
            >
              <h2 className={!isVideoGallery ? "active" : ""}>Image Gallery</h2>
            </button>
          )}
          <div className="social-share-wrap">
            <span className="share-text mr-2">Share </span>
            <FacebookShareButton
              url={social.url}
              quote={social.title}
              sepreater={social.sepreater}
            >
              <img src={sharefb} alt="" width="32" />
            </FacebookShareButton>
            <TwitterShareButton
              url={social.url}
              title={social.title}
              sepreater={social.sepreater}
            >
              <img src={sharetwiter} alt="" width="32" />
            </TwitterShareButton>
            <a
              href="https://www.instagram.com/wasteto.wealth/?hl=en"
              target="_blank"
            >
              <img src={sharetinsta} alt="" width="32" />
            </a>
            {/* <WhatsappShareButton // check locally with whatsapp 
							url={social.url}
							title={social.title}
							sepreater={social.sepreater}
						>
							<WhatsappIcon size={"2.5rem"} />
						</WhatsappShareButton> */}
          </div>
        </div>

        {isVideoGallery && data?.list.video[0] !== undefined && (
          <div className="row">
            <div className="col-md-8">
              <div className="yt-wrapper">
                <div className="gallery-count">
                  <span>{activeNumber}&nbsp;</span>/&nbsp;
                  {data?.list.video.length}
                </div>
                <div className="yt-inner">
                  {!isPlay && (
                    <div className="video-cover">
                      <img className="" src={thumbImg} alt={videoTitle} />
                      <h3 className="video-title">{videoTitle}</h3>
                    </div>
                  )}
                  {isPlay && (
                    <iframe
                      id="player"
                      width="100%"
                      src={`${ytVideoId}${isPlay ? "?autoplay=1" : ""}`}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      rel="0"
                    ></iframe>
                  )}
                  {!isPlay && (
                    <button
                      id="play"
                      className="play-btn"
                      onClick={(e) => playVideo(e)}
                    ></button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {data?.list?.video?.length > 3 ? (
                <Scrollbar style={{ height: 444 }}>
                  <div className="video-list">
                    {data?.list.video.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          onClick={() =>
                            handleVideoThumb(
                              item.videoUrl,
                              item.title,
                              item.image,
                              index + 1
                            )
                          }
                        >
                          {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                          <img
                            className="video-cover"
                            src={item.image}
                            alt="Video title"
                          />
                          <span className="thumb-play" />
                        </div>
                      );
                    })}
                  </div>
                </Scrollbar>
              ) : (
                <div className="video-list no-scroll">
                  {data?.list.video.map((item, index) => {
                    return (
                      <div
                        className={
                          activeNumber === index + 1
                            ? "video-thumb active"
                            : "video-thumb"
                        }
                        onClick={() =>
                          handleVideoThumb(
                            item.videoUrl,
                            item.title,
                            item.image,
                            index + 1
                          )
                        }
                      >
                        {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                        <img
                          className="video-cover"
                          src={item.image}
                          alt="Video title"
                        />
                        <span className="thumb-play" />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        {!isVideoGallery && data?.list.image[0] !== undefined && (
          <div className="row">
            <div className="col-md-8">
              <div className="yt-wrapper">
                <div className="gallery-count">
                  <span>{activeNumber}&nbsp;</span>/&nbsp;
                  {data?.list.image.length}
                </div>
                <div className="yt-inner">
                  <div className="video-cover">
                    <img className="" src={imageThumb} alt={imageTitle} />
                    <h3 className="video-title">{imageTitle}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {data?.list?.image?.length > 3 ? (
                <Scrollbar style={{ height: 444 }}>
                  <div className="video-list">
                    {data?.list.image.map((item, index) => {
                      return (
                        <div
                          className={
                            activeNumber === index + 1
                              ? "video-thumb active"
                              : "video-thumb"
                          }
                          onClick={() =>
                            handleImageThumb(item.title, item.image, index + 1)
                          }
                        >
                          {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                          <img
                            className="video-cover"
                            src={item.image}
                            alt="Video title"
                          />
                        </div>
                      );
                    })}
                  </div>
                </Scrollbar>
              ) : (
                <div className="video-list no-scroll">
                  {data?.list.image.map((item, index) => {
                    return (
                      <div
                        className={
                          activeNumber === index + 1
                            ? "video-thumb active"
                            : "video-thumb"
                        }
                        onClick={() =>
                          handleImageThumb(item.title, item.image, index + 1)
                        }
                      >
                        {/*<img className="video-cover" src="https://img.youtube.com/vi/0kRDM7BpOkk/hqdefault.jpg" alt="Video title" />*/}
                        <img
                          className="video-cover"
                          src={item.image}
                          alt="Video title"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GallerySection;
