import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import viewAll from "../../../../../assests/icons/next_icon.png";
import "./style.scss";
const NewsEvent = (props) => {
  const { latestNews, upcommingEvents } = props;
  return (
    <div className="latest-news-content clearfix">
      <div className="li_container">
        <div className="li_wrap main-container">
          <div className="row no-gutters">
            <div className=" col-md-6 col-lg-6 d-flex">
              <div className="latest-news-section">
                <div className="title-wrap">
                  <h2 className="latest-news-title">
                    {ReactHtmlParser(latestNews?.title)}
                  </h2>
                  {/* <Link to="/" classNam="green-circle-link">
                                    Media Center
                                    <i className="circle-goto-link-green" />
                                </Link> */}
                </div>
                <div className="news-group">
                  {latestNews &&
                    Array.isArray(latestNews?.list) &&
                    latestNews?.list.length > 0 &&
                    latestNews?.list.map((value, index) => {
                      return (
                        <div className="card latest-news-card" key={index}>
                          <div className="row no-gutters latest-news-card-row hover-zoom">
                            <div className="col-4 col-sm-3 latest-news-card-image-holder">
                              <img
                                className="card-img latest-news-card-image"
                                src={value?.thumbnail}
                                alt="Suresh Dasari Card"
                              />
                            </div>
                            <div className="col-8 col-sm-8">
                              <div className="latest-news-card-body">
                                <h5 className="card-title latest-news-card-title clock-icon">
                                  {" "}
                                  {value?.date}
                                </h5>
                                {value?.newsType === "Internal" ? (
                                  <Link to={value?.pagelink}>
                                    <p className="card-text latest-news-card-text">
                                      {value?.title}
                                    </p>
                                  </Link>
                                ) : (
                                  <a href={value?.pagelink} target="_blank">
                                    <p className="card-text latest-news-card-text">
                                      {value?.title}
                                    </p>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="upcoming-events-section">
                <div className="title-wrap">
                  <h2 className="upcoming-events-title">
                    {ReactHtmlParser(upcommingEvents.title)}
                  </h2>
                </div>
                <div className="upcoming-events-card-tray">
                  {upcommingEvents &&
                    Array.isArray(upcommingEvents.list) &&
                    upcommingEvents.list.length > 0 &&
                    upcommingEvents.list.map((value, index) => {
                      return (
                        <div className="upcoming-events-card  hover-zoom">
                          <div className="row no-gutters upcoming-events-card-wrap">
                            <div className="col-5">
                              <div className="event-img-wrap">
                                <img
                                  className=""
                                  src={value?.thumb_image}
                                  alt="Card"
                                />
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="event-content-outer">
                                {value?.eventType === "Internal" ? (
                                  <Link to={`event-listing${value?.pagelink}`}>
                                    <h5 className="card-text latest-news-card-text upcoming-events-card-title">
                                      {value?.trimTitle}
                                    </h5>
                                  </Link>
                                ) : (
                                  <a href={value?.pagelink} target="_blank">
                                    <h5 className="card-text latest-news-card-text upcoming-events-card-title">
                                      {value?.trimTitle}
                                    </h5>
                                  </a>
                                )}

                                <div className="event-info-wrap">
                                  <h5 className="card-title latest-news-card-title clock-icon">
                                    {value?.date}
                                  </h5>
                                  <h5 className="card-title latest-news-card-title location-icon">
                                    {value?.field_location}
                                  </h5>
                                </div>
                                {value?.eventType === "Internal" ? (
                                  <Link
                                    to={`event-listing${value?.pagelink}`}
                                    className="link-goto-bottom"
                                  ></Link>
                                ) : (
                                  <a
                                    href={value?.pagelink}
                                    className="link-goto-bottom"
                                    target="_blank"
                                  ></a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsEvent;
