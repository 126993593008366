import api from '../../../dependencies/utils/Api';
import {
    CURRENT_INTERVENTION_DETAILS,
    UPCOMING_INTERVENTION_LIST,
    UPCOMING_INTERVENTION_TECNOLOGY,
    UPCOMING_INTERVENTION_FILTER_DATA,
} from "../../../constants/ApplicationUrl";

export const CurrentInterventionService = {
    getCurrentInterventionList(page, categoryId, city, search , subtheme, state,date ) {
        return api.GET(`${UPCOMING_INTERVENTION_LIST}?page=${page}&date=${date}&category=${categoryId}&city=${city}&search=${search}&subtheme=${subtheme}&state=${state}`)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getTechnologyType() {
        return api.GET(UPCOMING_INTERVENTION_TECNOLOGY)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getCurrentInterventionDetails(url) {
        return api.GET(`${CURRENT_INTERVENTION_DETAILS}/${url}`)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    filterData() {
        return api.GET(UPCOMING_INTERVENTION_FILTER_DATA)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response || {};
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            })
    }

}