import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
// import './style.scss';

const Overview = (props) => {
  const { data, body, mainTitle, category, location } = props;

  console.log("data banner image", data?.banner_image);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div className="project-info">
      <div className="container">
        <div className="row">
          <div className="col-md-7" style={{ height: "22em" }}>
            <div class="dotted-sq"></div>
            <div className="image" style={{ height: "100%" }}>
              <Slider {...settings}>
                {data &&
                  Array.isArray(data?.banner_image) &&
                  data?.banner_image?.length > 0 &&
                  data?.banner_image?.map((value, index) => {
                    return (
                      <img
                        src={`https://uat.wastetowealth.gov.in${value?.image}`}
                        alt=""
                        key={index}
                      />
                    );
                  })}
              </Slider>
              {/* <img
                                src={data
                                    && data?.sliderImage
                                    && data?.sliderImage.length > 0
                                    && data?.sliderImage[0].image}
                            /> */}
            </div>
          </div>
          <div className="col-md-5 info">
            <div class="green-bird"></div>
            <div class="white-leaf"></div>
            <h2>Overview</h2>
            <ul>
              <li>
                {" "}
                <span className="start-date">Start Date:</span>{" "}
                {data?.start_date}
              </li>
              <li>
                {" "}
                <span className="end-date">End Date:</span> {data?.end_date}
              </li>

              <li>
                {" "}
                <span className="location">Location:</span>{" "}
                {`${data?.city}, ${data?.state}`}
              </li>
              {/* <li>
                {" "}
                <span className="status">Status:</span> {data?.Project_status}
              </li> */}
            </ul>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "20px" }}>
          <div
            className="col-12 pastprojoverviewbody"
            style={{ fontFamily: "'Chivo', sans-serif", fontSize: "16px" }}
          >
            {/* <h3> {ReactHtmlParser(mainTitle)}</h3> */}
            {ReactHtmlParser(body)}

            <div className="tag">
              {/* {category &&
                Array.isArray(category) &&
                category.length > 0 &&
                category.map((value, key) => {
                  return <span className="green-bg">{value?.title}</span>;
                })} */}
              <span className="green-bg">{category}</span>
            </div>
          </div>
        </div>
        <div class="large-tree"></div>
        <div class="small-tree"></div>
      </div>
    </div>
  );
};

export default Overview;
