import {
    validateFirstName,
    validateLastName,
    validateEmail,
    validateMobileNo,
    validateCompanyName,
    validateUploadFile
} from '../../../dependencies/utils/validation';

const validation = {
 
    validateEventRegistrationForm: (params) => {
        const { firstName, lastName, email, phone, affilated,fileInfo } = params;
        let formStatus = {};
        const firstNameStatus = validateFirstName(firstName);
        const lastNameStatus = validateLastName(lastName);
        const emailStatus = validateEmail(email);
        const phoneStatus = validateMobileNo(phone);
        const affilatedStatus = validateCompanyName(affilated);

        formStatus = {
            firstName: firstNameStatus.error,
            lastName: lastNameStatus.error,
            email: emailStatus.error,
            phone: phoneStatus.error,
            affilated : affilatedStatus.error,
            hasErrors: (
                firstNameStatus.hasError
                || lastNameStatus.hasError
                || emailStatus.hasError
                || phoneStatus.hasError
                || affilatedStatus.hasError
            )
        };
        return formStatus;
    }


};


export default validation;
