import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EventData from '../dependencies/component/EventData';
import LeftSideSlider from '../../common/LeftSideSlider';
import Breadcrumb from "../../common/Breadcrumbs";
import ListWithPagination from '../../common/ListWithPagination';
import { getEventList, getFeaturedEvent } from '../dependencies/action';
import GlobalDropDown from '../../common/GlobalDropDown';
import { SortBy } from '../dependencies/constant/searchConstant';
import EventForm from '../form';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';

import { initGA, logPageView } from '../../../dependencies/utils/analytics'
const items = [
    { to: '/', label: '/' },
    { to: '/event-listing', label: 'Engage with us' },
    { to: '/event-listing', label: 'All Events', active: true },
]
const EventListing = (props) => {
    const { getEventList, eventList, getFeaturedEvent, featuredTechnology } = props;
    const [page, setPage] = useState(0);
    const [sortBy, setSortBy] = useState('upcomming_events');
    const [showModal, setShowModal] = useState(false);

    const [pageStatus, setPageStatus] = useState(false)
    const [modalData, setModalData] = useState({});
    useEffect(() => {
        getEventList(page, sortBy)
    }, [page, sortBy])
    useEffect(() => {
        getFeaturedEvent()

    }, [])

  useEffect(() => {
    try {
      initGA()
      window.GA_INITIALIZED = true
      logPageView()
    } catch (error) {
      
    }
    }, [window])
    const changePageNumber = (pageNumber) => {
        setPageStatus(false)
        setPage((pageNumber - 1) * 5)
    }
    const handleInput = (e, name) => {
        const { value, } = e.target;
        setSortBy(value)
        setPage(0)
        setPageStatus(true)
    }
    const eventRegistration = (title, date, location, id, pagelink) => {
        setShowModal(true)
        setModalData({ title: title, date: date, location: location, id: id, pagelink: pagelink })
    }
    return (
        <div className='featured-event-list'>
            <Breadcrumb data={items} displayName={'All Events'} />
            <div className='page-lower-section'>
                <div className='container common-listing'>
                    <EventForm
                        data={modalData}
                        isOpen={showModal}
                        onClose={() => {
                            setShowModal(false)
                        }}
                    />
                    <div className='row featured-event-list-section'>
                        <div className='col-lg-4'>
                            <div className="challenges-left-section">
                                <LeftSideSlider heading={featuredTechnology?.heading} data={featuredTechnology?.list} />
                                <EventData capturedEvent={featuredTechnology?.completedEvents} pastEvent={featuredTechnology?.pastEvents} />
                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className="challenges-right-section">
                                <div className="right-section-box">
                                    <div className='challenges-right-section-heading'>{ReactHtmlParser(eventList?.bannerheading)}</div>
                                    <div className="event-dropdown">
                                        <GlobalDropDown
                                            label='Filter By'
                                            containerStyle="form-group "
                                            selectTagStyle="form-control dropdown-media"
                                            onChange={handleInput}
                                            options={SortBy}
                                            value={sortBy}
                                            name={'sortBy'}
                                            defaultOptionDisable
                                            error=""
                                        />
                                    </div>
                                   
                                    <ListWithPagination
                                        data={eventList?.list}
                                        itemsCountPerPage={5}
                                        totalItemsCount={eventList?.totalCount}
                                        pageRangeDisplayed={5}
                                        onChangePagination={changePageNumber}
                                        checkStatus={pageStatus}
                                        onClickEvent={eventRegistration}
                                        buttonName={'Event Registration'}
                                        nextUrl={'event-listing'}
                                        type={'event'}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        eventList: state.EventReducer.eventList.data,
        featuredTechnology: state.EventReducer.featuredTechnology.data,
        filterData: state.EventReducer.filterData
    }
}

export default connect(mapStateToProps, { getEventList, getFeaturedEvent })(withRouter(EventListing));