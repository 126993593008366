import { useState, useRef } from "react";
import { connect } from "react-redux";
import ModalHOC from "../../common/Modal";
import InputField from "../../common/InputField";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../dependencies/utils/constants";

import Button from "../../common/Button";
import {
  validateEmail,
  validateOtp,
} from "../../../dependencies/utils/validation";
import "./style.scss";
import { mailOtp } from "../dependencies/action";

const SendOtp = (props) => {
  const { mailOtp, getData, dataType } = props;
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpFrom, setOtpFrom] = useState(false);
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] =
    useState(false);
  const [error, setError] = useState({});
  const [capthaError1, setCapthaError1] = useState(false);
  const [capthaError, setCapthaError] = useState(false);
  const [otpError, setOtpError] = useState({});
  const recaptchaRef1 = useRef(null);
  const recaptchaRef = useRef(null);
  const handleInputChange = (e, name) => {
    const { value } = e.target;
    if (name === "otp") {
      setOtp(value);
    } else {
      setEmail(value);
    }
  };

  const validateInput = () => {
    const emailStatus = validateEmail(email);
    const errors = {
      email: emailStatus.error,
    };
    setError(errors);
    return !emailStatus.hasError;
  };

  const onBlur = () => {
    validateInput();
  };
  const onSubmitEmail = (e) => {
    e.preventDefault();
    const recaptchaValue1 = recaptchaRef1?.current?.getValue();
    console.log("recaptchaValue1", recaptchaValue1);
    // localStorage.setItem("secret1", recaptchaValue1);
    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true);
    } else {
      if (!recaptchaValue1) {
        setCapthaError1(true);
      } else {
        setCapthaError1(false);
        // handleSubmit(inputValue, props.email, recaptchaValue1);
        let params = {
          email: email,
          secret: recaptchaValue1,
          // otp: otp,
        };
        mailOtp(params).then((response) => {
          if (response.status === true) {
            setOtpFrom(true);
          }
        });
      }
    }
  };
  const validateInputOtp = () => {
    const otpStatus = validateOtp(otp);
    const errors = {
      otp: otpStatus.error,
    };
    setOtpError(errors);
    return !otpStatus.hasError;
  };

  const onSubmitOtp = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef?.current?.getValue();
    console.log("recaptchaValue", recaptchaValue);
    if (!validateInputOtp()) {
      setHasInvalidSubmitOccurred(true);
    } else {
      if (!recaptchaValue) {
        setCapthaError(true);
      } else {
        setCapthaError(false);
        let params = {
          email: email,
          otp: otp,
          type: dataType,
          secret: recaptchaValue,
        };
        console.log("recaptcha params", params);
        mailOtp(params).then((response) => {
          if (response.status === true) {
            getData(response.data);
          } else {
            getData(false);
          }
        });
      }
    }
  };

  return (
    <div className="send-otp">
      <h3 className="header mb-5">Please verify your email</h3>
      <form onSubmit={(e) => onSubmitEmail(e)}>
        <InputField
          disabled={otpFrom}
          required
          type="text"
          name="email"
          label={"Email ID"}
          placeholder={"Email ID"}
          containerClass={"form-group"}
          className="form-input-field"
          value={email}
          onChange={handleInputChange}
          error={error.email}
          onBlur={onBlur}
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        />
        {!otpFrom && (
          <div
            className="captcha-field"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <ReCAPTCHA ref={recaptchaRef1} sitekey={SITE_KEY} />
            {capthaError1 && (
              <div className="inline-error">*{"This is required"}</div>
            )}
          </div>
        )}
        {!otpFrom && (
          <Button type="submit" text={"Submit"} className="green-btn" />
        )}
      </form>
      {otpFrom && (
        <>
          <form onSubmit={(e) => onSubmitOtp(e)}>
            <InputField
              required
              type="text"
              name="otp"
              label={"Otp"}
              placeholder={"Otp"}
              containerClass={"form-group"}
              className="form-input-field"
              value={otp}
              onChange={handleInputChange}
              error={otpError.otp}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
            />
            <div
              className="captcha-field"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
              {capthaError && (
                <div className="inline-error">*{"This is required"}</div>
              )}
            </div>
            <Button type="submit" text={"Submit"} className="green-btn" />
          </form>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { mailOtp })(ModalHOC(SendOtp));
