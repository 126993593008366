import { useRef } from "react";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Youtube from "../../Youtube";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import scrollArrow from "../../../../../assests/icons/scroll_arrow.svg";
import "./style.scss";
import Chart from "react-google-charts";
import bannerMobImage2 from "../../../../../assests/images/homapage-banner-mob-2.svg";
import bannerMobImage1 from "../../../../../assests/images/homapage-banner-mob-1.svg";
import { Player } from "video-react";
import slideLogo from "../../../../../assests/images/waste-to-wealth-02.png";
import bannervid3 from "../../../../../assests/video/bannervid3.mp4";
import bannervidimage from "../../../../../assests/images/bannervidimage.jpg";

const Banner = (props) => {
  const vidRef = useRef(null);
  const getVideoId = (url) => {
    let videoId = url?.split("v=")[1];
    let ampersandPosition = videoId?.indexOf("&");
    if (ampersandPosition != -1) {
      videoId = videoId?.substring(0, ampersandPosition);
    }
    return videoId;
  };
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const [bannerClass, setBannerClass] = useState("banner-wrap");
  const { data } = props;
  const [bannerTableData, setBannerTableData] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const settings = {
    draggable: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    pauseOnFocus: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  let columnNames = [];
  let rowData = [];
  const reformatTableData = (tableData) => {
    // debugger
    let columnArray = [];
    let longest = -1;
    if (tableData && tableData.StatisticalRows.length > 0) {
      tableData &&
        tableData.StatisticalRows.map((item) => {
          columnArray.push(item.StatisticalColumns);
        });

      for (let i = 0; i < columnArray.length; i++) {
        if (
          longest == -1 ||
          columnArray[i].length > columnArray[longest].length
        ) {
          longest = i;
        }
      }
      let body = "<strong>";
      let bodyEnd = "</strong>";
      try {
        columnArray[longest].map((column) =>
          columnNames.push(
            column.ColumnName.substring(
              column.ColumnName.indexOf(body) + body.length,
              column.ColumnName.indexOf(bodyEnd)
            )
          )
        );

        if (tableData && tableData.StatisticalRows.length > 0) {
          tableData.StatisticalRows.map((data) => {
            let obj = {};
            obj["name"] = data.RowName.substring(
              data.RowName.indexOf(body) + body.length,
              data.RowName.indexOf(bodyEnd)
            );
            let category = data.RowName.match(/\(([^)]+)\)/);
            if (category) {
              obj["category"] = category[1];
            }
            data.StatisticalColumns.map((col, index) => {
              for (let i = 0; i < 10; i++) {
                if (col.ColumnName.includes(columnNames[index + i])) {
                  obj[columnNames[index + i]] = col.StatisticalData.substring(
                    col.StatisticalData.indexOf(body) + body.length,
                    col.StatisticalData.indexOf(bodyEnd)
                  );
                  let columnname = col.StatisticalData.match(/\(([^)]+)\)/);
                  if (columnname) {
                    obj[`${columnNames[index + i]} Year`] = columnname[1];
                  } else obj[`${columnNames[index + i]} Year`] = "none";
                }
              }
            });
            rowData.push(obj);
          });
        }
      } catch (error) {}
    }
  };

  const playVideo = (e) => {
    e.preventDefault();
    setIsPlay(true);
    // vidRef.current.play();
  };

  const onPlayerPlay = () => {
    setIsPlay(true);
  };
  const setImageClass = (BannerImage) => {
    setBannerClass("banner-wrap-full");
    return (
      <div className="home-banner-outer">
        <div className="banner-bg">
          <div class="main-container banner-container clearfix slide-two d-flex align-items-center">
            <div
              className="image-banner_2"
              style={{ backgroundImage: `url(${BannerImage})` }}
            ></div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    data &&
      Array.isArray(data.list) &&
      data?.list.length > 0 &&
      data?.list.map((value, index) => {
        if (value?.type === "banner_statistical") setBannerTableData(value);
      });
  }, [data]);

  if (bannerTableData) {
    reformatTableData(bannerTableData);
  }

  return (
    <div className="banner-content-rutag clearfix">
      <div id="banner-wrap-id">
        <Youtube
          videoUrl={url}
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />

        <div className="home-banner-inner-wrap">
          <Slider {...settings}>
            <div>
              <div
                className="banner-wrap 4th"
                style={{
                  background: `url(${
                    props.rutagdata && props.rutagdata[0]?.banner_image
                  })`,
                }}
              >
                <div className="home-banner-inner-wrap">
                  <div className="home-banner-outer">
                    <div className="banner-bg">
                      <div class="main-container banner-container clearfix">
                        <div class="top-banner-block">
                          <div
                            className="banner-right-block"
                            style={{ visibility: "hidden" }}
                          >
                            {/* <img src={slideLogo} /> */}

                            <img
                              style={{ marginTop: "17px" }}
                              src={slideLogo}
                            />

                            <p>
                              The Waste to Wealth Mission brings scientific
                              processing of waste to the forefront to build a
                              zero landfill and zero waste nation. The portal
                              shall act as a common platform for technology
                              providers, government stakeholders, urban local
                              bodies, and users to seek relevant solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>

          {/* <div class="social-icon-wrap">
            {data &&
              Array.isArray(data?.socialIcons) &&
              data?.socialIcons?.length > 0 &&
              data?.socialIcons.map((socialData, socialIndex) => {
                return (
                  <div className="social-icon" key={socialIndex}>
                    <a href={socialData?.url} target="_blank">
                      <img
                        src={socialData?.image}
                        className={"social_image"}
                        alt=""
                      />
                    </a>
                  </div>
                );
              })}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Banner);
