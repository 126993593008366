import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './style.scss';
import { useState, useEffect } from 'react';

const ModalHOC = (WrapperComponent) => {
    const CustomModal = (props) => {
        const { isOpen, onClose, label, style, onOpenModal, className } = props;
        const [isOpenModal, setOpenModal] = useState(false);
        
        useEffect(() => {
            setOpenModal(isOpen);
        }, [isOpen])
        const onCloseModal = () => {
            if (onClose) {
                onClose()
            }
            setOpenModal(false)
        }
        return (
            <Modal
                isOpen={isOpenModal}
                onRequestClose={onClose}
                onAfterOpen={onOpenModal}
                contentClassName={className && className}
                className={className && className}
                closeTimeoutMS={500}
                style={style}
                scrollable={true}
                contentLabel={label}
                shouldCloseOnOverlayClick={true}
                ariaHideApp={false}
            >
                <div className="modal-container">
                    <span className='close-modal' onClick={onCloseModal}><b>X</b></span>
                    <WrapperComponent
                        {...props}
                    />
                </div>
            </Modal>
        );
    }

    CustomModal.propTypes = {
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onOpenModal: PropTypes.func,
        style: PropTypes.object,
        label: PropTypes.string,
    };

    CustomModal.defaultProps = {
        isOpen: false,
        onClose: () => { },
        onOpenModal: () => { },
        style: {},
        label: ""
    };

    return CustomModal;
}
export default ModalHOC;
