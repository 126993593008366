import downloadIcon from "../../../../assests/icons/download.svg";
import ReactHtmlParser from "react-html-parser";
import { withRouter, Link } from "react-router-dom";
import "./style.scss";

const ActAndPolicy = (props) => {
  console.log("press and reports props", props);
  const { onClickTab, data, subtype, fieldData, pressReleaseData } = props;

  const evenObject = fieldData?.filter((e, i) => (i % 2) - 1);
  const evenObjectIndexes = fieldData?.indexOf(
    fieldData?.filter((e, i) => (i % 2) - 1)
  );
  console.log("evenObject", evenObject);
  console.log("evenObject indexes", evenObjectIndexes);
  const downloadFile = (url, fileName) => {
    //  fileName = fileName+".pdf"
    //Create XMLHTTP Request.
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      let blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      let isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };
  return (
    <div className="common-listing tab-pane active actsAndPolicies">
      <div className="row">
        <div className="w-100">
          <div className="boxWithShadow currentpolicyreports container ">
            <div className="row" style={{ float: "right", marginRight: "1%" }}>
              <div class="large-tree"></div>
              <div class="small-tree" style={{ marginTop: "20%" }}></div>
            </div>
            <h1 class="section-heading-release">
              <p>Reports</p>
            </h1>

            <div className="tab-content">
              <ul className="card-listing tab-pane actsPage active pastprojlinks">
                {fieldData?.map((value, index) => {
                  const fielddataodd = fieldData?.filter((e, i) => (i % 2) - 1);
                  console.log("fielddataodd", fielddataodd);
                  return (
                    <>
                      <div
                        className="row"
                        style={{
                          // borderBottom: "2px inset #e6e6e6",
                          marginLeft: "0px",
                          justifyContent: "space-between",
                          // background:
                          //   index ===
                          //   fieldData.indexOf(
                          //     fieldData?.filter((e, i) => (i % 2) - 1)
                          //   )
                          //     ? "#E1F3E7"
                          //     : "",
                        }}
                      >
                        <li style={{ paddingLeft: "0px", borderBottom: "0px" }}>
                          <h3 className="card-title">
                            <span>{index + 1}.</span>{" "}
                            <a
                              href={value.url}
                              target="_blank"
                              style={{
                                pointerEvents: "none",
                                color: "#3570ab",
                                textTransform: "capitalize",
                              }}
                            >
                              {value.name}
                            </a>
                          </h3>
                        </li>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginRight: value.name.length > 49 ? "" : "30%",
                          }}
                        >
                          <a
                            className="green-btn mr20"
                            download
                            href={value.url}
                            // target="_self"
                            // Content-Disposition="attachment"
                            // filename={value.url}
                            // to={{
                            //   pathname: `/${"past-projects"}${value?.pagelink}`,
                            //   state: {
                            //     // blogName: value?.title,
                            //     // blogText: value?.body,
                            //     // blogDate: moment(value?.publish_date).format(
                            //     //   "MMM Do YYYY"
                            //     // ),
                            //     // blogAuthor: value?.author,
                            //     // blogImage: value?.thumbnail_link,
                            //     // authorImage: placeholderAuthor,
                            //     // authorName: value?.author[0]?.name,
                            //     pageLink: value?.pagelink,
                            //     location: `${value?.city}, ${value?.state}`,
                            //   },
                            // }}
                            // to={"#"}
                          >
                            {" "}
                            Download
                          </a>
                        </div>
                      </div>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="pressmaindiv" style={{ marginBottom: "3%" }}></div>
          <div className="pressmaindiv2" style={{ marginBottom: "0%" }}></div>
          {/* <div className="boxWithShadow currentpolicyreports">
            <h1 class="section-heading-release">
              <p>Press Release</p>
            </h1>

            <div className="tab-content">
              <ul className="card-listing tab-pane actsPage active">
                {pressReleaseData?.map((value, index) => (
                  <li style={{ paddingLeft: "0px" }}>
                    <h3 className="card-title">
                      <span>{index + 1}</span>{" "}
                      <a href={value.url} target="_blank">
                        {value.name}
                      </a>
                    </h3>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
          {data && (
            <div className="disclaimer">
              {ReactHtmlParser(data?.disclaimer)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => {
//
// }
export default ActAndPolicy;
