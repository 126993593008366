import api from "../../../dependencies/utils/Api";
import {
  EVENT_LISTING,
  FEATURED_EVENT,
  EVENT_DETAILS,
  EVENT_DETAILS2,
  UPCOMMING_EVENTS,
  UPLOAD_FILE_EVENT,
  SUBMIT_REGISTRATION,
  VALIDATE_IMAGE,
} from "../../../constants/ApplicationUrl";

export const EventService = {
  getEventList(page, sortBy) {
    return api
      .GET(`${EVENT_LISTING}?page=${page}&event_type=${sortBy}`)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getFeaturedEvent() {
    return api
      .GET(FEATURED_EVENT)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  getEventDetails(url) {
    return api
      .GET(`${EVENT_DETAILS}/${url}`)
      .then((response) => {
        const { data = { data } } = response.data || {};
        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  // getEventDetails2(url) {
  //   console.log({ EVENT_DETAILS2 }, "testing url");
  //   return api
  //     .GET(`${EVENT_DETAILS2}`)

  //     .then((response) => {
  //       const { data = { data } } = response.data || {};
  //       console.log(data, "data testing");
  //       return { data };
  //     })
  //     .catch((e) => {
  //       return Promise.reject(e);
  //     });
  // },

  getEventDetails2(url) {
    return api
      .GET(`${EVENT_DETAILS2}${url}`)
      .then((response) => {
        const { data = { data } } = response.data || {};

        return { data };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  upcommingEvents() {
    return api
      .GET(UPCOMMING_EVENTS)
      .then((response) => {
        const { data: { responseCode, data } = {} } = response || {};
        if (responseCode === 200) {
          return data;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  uploadFile(params) {
    return api
      .POSTDATA(UPLOAD_FILE_EVENT, params)
      .then((response) => {
        const { data, status } = response || {};
        if (status) {
          return { data, status };
        } else {
          return Promise.reject();
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  validateFile(params) {
    return api
      .POSTDATA(VALIDATE_IMAGE, params)
      .then((response) => {
        return response?.data?.success;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },

  submitEventRegistartion(params) {
    return api
      .POST(SUBMIT_REGISTRATION, params)
      .then((response) => {
        const { data, status } = response || {};
        return { data, status };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
};
