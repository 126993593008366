import ReactHtmlParser from "react-html-parser";
import "./style.scss";

const ViewPartner = (props) => {
  const { data } = props;
  console.log(data);
  return (
    <div className="Partners">
      <div className="container">
        <div className="container-wrap">
          <div className="row partner-logo-heading-container">
            <div className=" col-lg-12">
              <div className="partner-heading currentIntPartners">
                {ReactHtmlParser(data?.heading ?? "Endorsing Agencies")}
              </div>
              <div className="partner-sub-heading">
                {ReactHtmlParser(
                  data?.description ? data?.description : data?.subtitle
                )}
              </div>
            </div>
          </div>
          <div className="row partner-logo-group-container">
            {data &&
              Array.isArray(data.list) &&
              data.list.length > 0 &&
              data.list.map((value, index) => {
                return (
                  <div
                    className={`col-lg-3 partner-logo-container ${
                      (index + 1) % 4 === 0 ||
                      data?.partners?.length === index + 1
                        ? "no-border-need"
                        : ""
                    }`}
                    key={index}
                  >
                    <a href={value} target="_blank">
                      <img src={value} alt={value} className="partner-logo" />
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPartner;
