import { Link } from 'react-router-dom';
import './style.scss';

const NotFound = () => (
  <div className='not-found'>
      <div className='not-found-image'></div>
      <div className='error-text-container'>
    <div className='error-top-text'>Error</div>
      <div className='error-bottom-text'>Page Not Found</div>
      </div>
  </div>
);

export default NotFound;