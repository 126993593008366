import api from '../../../dependencies/utils/Api';
import {
    CURRENT_INTERVENTION,
    QUOTES,
    FEATURED_TECHNOLOGY,
    IMPORTANT_CHALLENGES,
    BANNER,
    LATEST_NEWS,
    UPCOMMING_EVENTS
} from "../../../constants/ApplicationUrl";


export const HomeServices = {
    currentInervention() {
        return api.GET(`${CURRENT_INTERVENTION}`)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response;
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    quotes() {
        return api.GET(`${QUOTES}`)
            .then((response) => {

                const { data: { responseCode, data } = {} } = response;
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    featuredTechnology() {
        return api.GET(FEATURED_TECHNOLOGY)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response;
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    importantChallenges() {
        return api.GET(IMPORTANT_CHALLENGES)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response;
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    banner() {
        return api.GET(BANNER)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response;
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    latestNews() {
        return api.GET(LATEST_NEWS)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response || {};
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    upcommingEvents() {
        return api.GET(UPCOMMING_EVENTS)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response || {};
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            })
    }
}