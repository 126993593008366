import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import InputField from '../../../components/common/InputField';
import Button from '../../../components/common/Button';
import ListWithPagination from '../../common/ListWithPagination';
import ReactHtmlParser from 'react-html-parser';
import searchIcon from '../../../assests/svg/searchIcon.svg';
import { getSuccessStoriesList, getTechnologyType, getFilterData } from '../dependencies/action';
import LeftSideCategory from '../../../components/common/LeftSideCategory';
import GlobalDropDown from '../../common/GlobalDropDown';
import Breadcrumb from "../../common/Breadcrumbs";
import { initGA, logPageView } from '../../../dependencies/utils/analytics'

import './style.scss';
const items = [
    { to: '/', label: '/' },
    { to: '/success-stories', label: 'Success Stories' },
]

const SuccessStories = (props) => {
    const { getSuccessStoriesList, successStoryList, getTechnologyType, successTechnology, getFilterData, filterData } = props
    const [page, setPage] = useState(0);
    const [categoryId, setCategory] = useState('');
    const [city, setCity] = useState('');
    const [isMoreFilter, setMoreFilter] = useState(false)
    const handleShowMoreFiltere = () => setMoreFilter(!isMoreFilter);
    const [pageStatus, setPageStatus] = useState(false)
    const defaultFormData = {
        state: '',
        city: '',
        projectTimeline: '',
        useType: '',
        subTheme: '',
        scale: '',
        specialCondition: '',
        search: ''
    }
    const [formData, setFormData] = useState(defaultFormData);


    const changePageNumber = (pageNumber) => {
        setPageStatus(false)
        setPage((pageNumber - 1) * 3)
    }
    useEffect(() => {

        getSuccessListData()
    }, [page, categoryId])

  useEffect(() => {
    try {
      initGA()
      window.GA_INITIALIZED = true
      logPageView()
    } catch (error) {
      
    }
    }, [window])
    const handleInputChange = (e, name) => {
        const { value, } = e.target;

        setFormData((user) => {
            return { ...user, [name]: value }
        });
    }

    const getSuccessListData = () => {
        getSuccessStoriesList(
            page,
            categoryId,
            formData.city,
            formData.search,
            formData.useType,
            formData.subTheme,
            formData.scale,
            formData.specialCondition,
            formData.state,
            formData.projectTimeline
        );
    }
    const onSubmit = (e) => {
        e.preventDefault();
        setPage(0)
        setPageStatus(true)
        getSuccessListData()
    }

    useEffect(() => {
        getTechnologyType()
        getFilterData()
    }, [])

    const getCategoryId = (Id) => {
        setCategory(Id)
    }
    return (
        <div className='SuccessStories'>
            <Breadcrumb data={items} displayName={'Success Stories'} />
            <div className='filter-row filter-row-common'>
                <div className='container'>
                    <form onSubmit={e => onSubmit(e)}>
                        <div className='row'>
                            <div className='col-md-1 filter-text'>Filter by</div>
                            <div className="user-type-dropdown col-md-2 select-box">
                                <InputField containerStyle="form-group "
                                    onChange={handleInputChange}
                                    options={''}
                                    value={formData.city}
                                    name={'city'}
                                    placeholder={'Enter City/Village'}
                                    error=""
                                />
                            </div>
                            <div className="user-type-dropdown col-md-2 select-box">
                                <GlobalDropDown
                                    containerStyle="form-group "
                                    selectTagStyle="form-control dropdown-media"
                                    onChange={handleInputChange}
                                    options={filterData?.list?.state}
                                    value={formData.state}
                                    name={'state'}
                                    defaultOptionText={'Select State'}
                                    error=""
                                />
                            </div>
                            <div className="user-type-dropdown col-md-2 select-box">
                                <GlobalDropDown
                                    containerStyle="form-group "
                                    selectTagStyle="form-control dropdown-media"
                                    onChange={handleInputChange}
                                    options={filterData?.list?.Timelines}
                                    value={formData.projectTimeline}
                                    name={'projectTimeline'}
                                    defaultOptionText={'Select Project Timeline'}
                                    error=""
                                />
                            </div>
                            <div className="user-type-dropdown col-md-2 select-box">
                                <GlobalDropDown
                                    // label='SortBy'
                                    containerStyle="form-group "
                                    selectTagStyle="form-control dropdown-media"
                                    onChange={handleInputChange}
                                    options={filterData?.list?.subThemes}
                                    value={formData.subTheme}
                                    name={'subTheme'}
                                    defaultOptionText={'Select Sub Themes'}
                                    error=""
                                />
                            </div>
                            <div className='col-md-1 text-center'>
                                <Button
                                    // icon={(<img src={searchIcon} className='search-icon' />)}
                                    type="submit"
                                    text="Apply"
                                    className='green-btn filter-btn'
                                />

                                {/* <div className={`col-sm-5 toggle-section ${isMoreFilter ? 'active' : ''}`} onClick={handleShowMoreFiltere}>
                                            <div className='toggle-btn'>{isMoreFilter ? 'Less' : 'More'}</div>
                                            <div className='toggle-arrow '></div>
                                        </div> */}
                            </div>
                        </div>

                    </form>
                </div>

            </div>

            <div className="bottom-section-list">
                <div className='container common-listing'>
                    <div className="row">
                        <div className='col-3 sidebar'>
                            <div className="category-box">
                                <h3 className="sub-title f19">{ReactHtmlParser(successTechnology?.heading)}</h3>
                                <ul>

                                    {
                                        successTechnology
                                        && Array.isArray(successTechnology.list)
                                        && successTechnology.list.length > 0
                                        && successTechnology.list.map((value, index) => {
                                            let Id = value?.id
                                            return <li><LeftSideCategory onClickReply={() => {
                                                getCategoryId(Id)
                                            }} className={value?.id === categoryId ? 'category-active' : ''}
                                                categoryName={value?.name} count={value?.count}
                                                key={index} /> </li>
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className='col-9 list-container'>
                            <div className="boxWithShadow">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="common-listing-title"> {ReactHtmlParser(successStoryList?.heading)}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="search">
                                            <form onSubmit={e => onSubmit(e)}>
                                                <InputField

                                                    type="search"
                                                    name="search"
                                                    placeholder="Search Success Stories"
                                                    value={formData.search}
                                                    onChange={handleInputChange}
                                                    containerClass={"form-group"}
                                                    className='subscribe-input-field'
                                                />
                                                <div className="btn-group">
                                                    <Button
                                                        icon={(<img src={searchIcon} className='search-icon' />)}
                                                        type="submit"
                                                        text=""
                                                        className='list-submit-button'
                                                    />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                </div>

                                <ListWithPagination
                                    data={successStoryList?.list}
                                    itemsCountPerPage={3}
                                    totalItemsCount={successStoryList?.totalCount}
                                    pageRangeDisplayed={5}
                                    onChangePagination={changePageNumber}
                                    checkStatus={pageStatus}
                                    nextUrl={'success-stories'}
                                    type={'success-stories'}
                                />


                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        successStoryList: state.SuccessStoriesReducer.successStoryList.data,
        successTechnology: state.SuccessStoriesReducer.successTechnology.data,
        filterData: state.SuccessStoriesReducer.filterData
    }
}

export default connect(mapStateToProps, {
    getSuccessStoriesList,
    getTechnologyType,
    getFilterData
})(withRouter(SuccessStories));