import { combineReducers } from "redux";
import {
  CURRENT_INTERVENTION_LIST,
  INTERVENTION_TECNOLOGY,
  CURRENT_INTERVENTION_DETAILS,
  FILTER_DATA,
  ARTICLES_CASE_STUDIES_LIST,
  ARTICLES_CASE_STUDIES_TAG_LIST,
  ARTICLES_CASE_STUDIES_TAGS,
  ARTICLES_CASE_STUDIES_DETAILS,
  ARTICLES_CASE_STUDIES_MOSTREAD,
} from "../../../constants/actionConstants";

const currentInterventionList = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_INTERVENTION_LIST:
      return { ...action.payload };
    default:
      return state;
  }
};

const interventionTechnology = (state = {}, action) => {
  switch (action.type) {
    case INTERVENTION_TECNOLOGY:
      return { ...action.payload };
    default:
      return state;
  }
};

const tagsTechnology = (state = {}, action) => {
  switch (action.type) {
    case ARTICLES_CASE_STUDIES_TAGS:
      return { ...action.payload };
    default:
      return state;
  }
};

const articlesTechnology = (state = {}, action) => {
  switch (action.type) {
    case ARTICLES_CASE_STUDIES_LIST:
      return { ...action.payload };
      case ARTICLES_CASE_STUDIES_TAG_LIST:
        return { ...action.payload };
    default:
      return state;
  }
};

const articlesDetails = (state = {}, action) => {
  switch (action.type) {
    case ARTICLES_CASE_STUDIES_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
};

const currentInterventionDetails = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_INTERVENTION_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
};

const mostReadType = (state = {}, action) => {
  switch (action.type) {
    case ARTICLES_CASE_STUDIES_MOSTREAD:
      return { ...action.payload };
    default:
      return state;
  }
};

const filterData = (state = {}, action) => {
  switch (action.type) {
    case FILTER_DATA:
      return { ...action.payload };
    default:
      return state;
  }
};

const ArticlesReducer = combineReducers({
  currentInterventionList,
  interventionTechnology,
  currentInterventionDetails,
  filterData,
  articlesTechnology,
  tagsTechnology,
  mostReadType,
  articlesDetails,
});

export default ArticlesReducer;
