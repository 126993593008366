import { useState, useEffect } from 'react'
import './style.scss'
const ReadMore = ({ text, length = 20 }) => {
  const [showLess, setShowLess] = useState(true)

  if (text.length < length) {
    return <p>{text}</p>
  }

  return (
    <div className="read_more">
      <p
        dangerouslySetInnerHTML={{
          __html: showLess ? `${text.slice(0, length)}...` : text,
        }}
      ></p>
      <a className="view-more" onClick={() => setShowLess(!showLess)}>
        &nbsp;View {showLess ? 'More' : 'Less'}
      </a>
    </div>
  )
}

export default ReadMore
