import { useState, useRef, useEffect } from "react";
// import InputField from '../common/FormElements/InputField';
import SelectDropdown from "../common/FormElements/SelectDropdown";
import ReactHtmlParser from "react-html-parser";
import TextAreaField from "../common/TextAreaField";
import Button from "../common/Button";
import InputField from "../common/InputField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormValidator from "./dependencies/formValidator";
import ReCAPTCHA from "react-google-recaptcha";
import InlineError from "../common/InlineError";
import { SITE_KEY } from "../../dependencies/utils/constants";
import {
  submitTechSubmit,
  submitTechInfo,
  uploadFileTech,
} from "./dependencies/action";
import { validateFile } from "../Events/dependencies/action";
import "./style.scss";
import homeIcon from "../../assests/icons/home.svg";
import SuccessModal from "../common/SuccessModal";
import emailIcon from "../../assests/icons/emailIcon.svg";
import Breadcrumb from "../common/Breadcrumbs";
import phoneIcon from "../../assests/svg/phone-icon.svg";
import { Link } from "react-router-dom";
import { dropdownValue } from "./dependencies/constant";
import MultiSelect from "../common/FormElements/Multiselect";
import FileUpload from "../common/FileUpload";
import { toast } from "react-toastify";
import { initGA, logPageView } from "../../dependencies/utils/analytics";
import {
  TECH_IMG_UPLOAD_URL,
  UPLOAD_FILE_EVENT2,
} from "../../constants/ApplicationUrl";

const items = [
  { to: "/", label: "/" },
  { to: "/submit-technology", label: "What We Do" },
  { to: "/submit-technology", label: "Submit Technology", active: true },
];

const SubmitTechnology = (props) => {
  let currentDate = new Date().toISOString().split("T")[0];
  const [inputList, setInputListTest] = useState([
    { firstName: "", lastName: "" },
  ]);

  // handle input change
  const handleInputChangeTest = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputListTest(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickTest = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputListTest(list);
  };

  useEffect(() => {
    // to call the initialise google analytics funtion
    try {
      initGA();
      window.GA_INITIALIZED = true;
      // to save the number og visitors on this side
      logPageView();
    } catch (error) {}
  }, [window]);

  // handle click event of the Add button
  const handleAddClickTest = () => {
    setInputListTest([...inputList, { firstName: "", lastName: "" }]);
  };
  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };
  const {
    uploadFile,
    validateFile,
    submitTechSubmit,
    submitTechInfo,
    technologyData,
    uploadFileTech,
  } = props;
  const defaultFormData = {
    name: "",
    date: currentDate,
    // technology: '',
    company: "",
    techDetails: "",
    currentDeployment: "",
    websiteURL: "",
    compAddress: "",
    // compWebsite:'',
    studEmail: "",
    limitationTechnology: "",
    phone: "",
    mail: "",
    address: "",
    nationality: "",
    technologyReadiness: "",
    technologyEndorsed: "",
    productModular: "",
    remotelyMonitored: "",
    skilledPersonnel: "",
    geographicTerrains: "",
    technologyCertified: "",
    technologyPatented: "",
    madeInIndia: "",
    techSteps: "",
    techInputOutput: "",
    techCivilStructuer: "",
    systemReplacement: "",
    technologyListed: "",
    annualPowerCost: "",
    annualConsumableCost: "",
    annualMaintenance: "",
    annualManCost: "",
    timeRequired: "",
    detailsOfServicing: "",
    technologyName: "",
    otherThematicArea: "",
    productCompletelyManufactured: "",
    detailsOfSupport: "",
    threats: "",
    opportunities: "",
    strengthTechno: "",
    weeknessTechno: "",
    typeAndFrequencyOfReplacement: "",
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [technologyPhotosVideos, setTechnologyPhotosVideos] = useState([]);
  const [filesTech, setFilesTech] = useState([]);
  const [uploadedFileTechError, setUploadedFileTechError] = useState("");
  const recaptchaRef = useRef(null);
  const recaptchaRef1 = useRef(null);
  const [capthaError, setCapthaError] = useState(false);
  const [capthaError1, setCapthaError1] = useState(false);
  const [capthaErrorassist, setCapthaError1assist] = useState(true);

  // add and remove handlers
  const [smallest_area, setInputList] = useState([{ sUnit: "", mUnit: "" }]);
  const [medium_area, setInputList2] = useState([{ sUnit: "", mUnit: "" }]);
  const [largest_area, setInputList3] = useState([{ sUnit: "", mUnit: "" }]);
  const [smallest_power, setInputList4] = useState([{ sUnit: "", mUnit: "" }]);
  const [medium_power, setInputList5] = useState([{ sUnit: "", mUnit: "" }]);
  const [largest_power, setinputList6] = useState([{ sUnit: "", mUnit: "" }]);
  const [smallest_water, setinputList7] = useState([{ sUnit: "", mUnit: "" }]);
  const [medium_water, setinputList8] = useState([{ sUnit: "", mUnit: "" }]);
  const [largest_water, setinputList9] = useState([{ sUnit: "", mUnit: "" }]);
  const [smallest_capex, setinputList10] = useState([{ sUnit: "", mUnit: "" }]);
  const [medium_capex, setinputList11] = useState([{ sUnit: "", mUnit: "" }]);
  const [largest_capex, setinputList12] = useState([{ sUnit: "", mUnit: "" }]);
  const [smallest_operation, setinputList13] = useState([
    {
      sUnit: "",
      mUnit: "",
      consumableCost: "",
      manpowerCost: "",
      maintenanceCost: "",
      opexCost: "",
      error: "Please Enter Unit Capacity",
    },
  ]);
  const [medium_operation, setinputList14] = useState([
    {
      sUnit: "",
      mUnit: "",
      consumableCost: "",
      manpowerCost: "",
      maintenanceCost: "",
      opexCost: "",
    },
  ]);
  const [largest_operation, setinputList15] = useState([
    {
      sUnit: "",
      mUnit: "",
      consumableCost: "",
      manpowerCost: "",
      maintenanceCost: "",
      opexCost: "",
    },
  ]);
  // handle input change
  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target;
    const list = [...smallest_area];
    list[index][name] = value;
    setInputList(list);
  };

  // handle input change
  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target;
    const list = [...medium_area];
    list[index][name] = value;
    setInputList2(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick2 = (index) => {
    // i.preventDefault();
    const list = [...medium_area];
    list.splice(index, 1);
    setInputList2(list);
  };

  // handle click event of the Add button
  const handleAddClick2 = () => {
    setInputList2([...medium_area, { sUnit: "", mUnit: "" }]);
  };

  // handle input change
  const handleInputChange3 = (e, index) => {
    const { name, value } = e.target;
    const list = [...largest_area];
    list[index][name] = value;
    setInputList3(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick3 = (index) => {
    // i.preventDefault();
    const list = [...largest_area];
    list.splice(index, 1);
    setInputList3(list);
  };

  // handle click event of the Add button
  const handleAddClick3 = () => {
    setInputList3([...largest_area, { sUnit: "", mUnit: "" }]);
  };
  // handle input change
  const handleInputChange4 = (e, index) => {
    const { name, value } = e.target;
    const list = [...smallest_power];
    list[index][name] = value;
    setInputList4(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    // i.preventDefault();
    const list = [...smallest_area];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...smallest_area, { sUnit: "", mUnit: "" }]);
  };
  // handle click event of the Remove button
  const handleRemoveClick4 = (index) => {
    // i.preventDefault();
    const list = [...smallest_power];
    list.splice(index, 1);
    setInputList4(list);
  };

  // handle click event of the Add button
  const handleAddClick4 = () => {
    setInputList4([...smallest_power, { sUnit: "", mUnit: "" }]);
  };
  // handle input change
  const handleInputChange5 = (e, index) => {
    const { name, value } = e.target;
    const list = [...medium_power];
    list[index][name] = value;
    setInputList5(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick5 = (index) => {
    // i.preventDefault();
    const list = [...medium_power];
    list.splice(index, 1);
    setInputList5(list);
  };

  // handle click event of the Add button
  const handleAddClick5 = () => {
    setInputList5([...medium_power, { sUnit: "", mUnit: "" }]);
  };
  // handle input change
  const handleInputChange6 = (e, index) => {
    const { name, value } = e.target;
    const list = [...largest_power];
    list[index][name] = value;
    setinputList6(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick6 = (index) => {
    // i.preventDefault();
    const list = [...largest_power];
    list.splice(index, 1);
    setinputList6(list);
  };

  // handle click event of the Add button
  const handleAddClick6 = () => {
    setinputList6([...largest_power, { sUnit: "", mUnit: "" }]);
  };
  // handle input change
  const handleInputChange7 = (e, index) => {
    const { name, value } = e.target;
    const list = [...smallest_water];
    list[index][name] = value;
    setinputList7(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick7 = (index) => {
    // i.preventDefault();
    const list = [...smallest_water];
    list.splice(index, 1);
    setinputList7(list);
  };

  // handle click event of the Add button
  const handleAddClick7 = () => {
    setinputList7([...smallest_water, { sUnit: "", mUnit: "" }]);
  };

  // handle input change
  const handleInputChange8 = (e, index) => {
    const { name, value } = e.target;
    const list = [...medium_water];
    list[index][name] = value;
    setinputList8(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick8 = (index) => {
    // i.preventDefault();
    const list = [...medium_water];
    list.splice(index, 1);
    setinputList8(list);
  };

  // handle click event of the Add button
  const handleAddClick8 = () => {
    setinputList8([...medium_water, { sUnit: "", mUnit: "" }]);
  };
  // handle input change
  const handleInputChange9 = (e, index) => {
    const { name, value } = e.target;
    const list = [...largest_water];
    list[index][name] = value;
    setinputList9(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick9 = (index) => {
    // i.preventDefault();
    const list = [...largest_water];
    list.splice(index, 1);
    setinputList9(list);
  };

  // handle click event of the Add button
  const handleAddClick9 = () => {
    setinputList9([...largest_water, { sUnit: "", mUnit: "" }]);
  };

  // handle input change
  const handleInputChange10 = (e, index) => {
    const { name, value } = e.target;
    const list = [...smallest_capex];
    list[index][name] = value;
    setinputList10(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick10 = (index) => {
    // i.preventDefault();
    const list = [...smallest_capex];
    list.splice(index, 1);
    setinputList10(list);
  };

  // handle click event of the Add button
  const handleAddClick10 = () => {
    setinputList10([...smallest_capex, { sUnit: "", mUnit: "" }]);
  };

  // handle input change
  const handleInputChange11 = (e, index) => {
    const { name, value } = e.target;
    const list = [...medium_capex];
    list[index][name] = value;
    setinputList11(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick11 = (index) => {
    // i.preventDefault();
    const list = [...medium_capex];
    list.splice(index, 1);
    setinputList11(list);
  };

  // handle click event of the Add button
  const handleAddClick11 = () => {
    setinputList11([...medium_capex, { sUnit: "", mUnit: "" }]);
  };
  // handle input change
  const handleInputChange12 = (e, index) => {
    const { name, value } = e.target;
    const list = [...largest_capex];
    list[index][name] = value;
    setinputList12(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick12 = (index) => {
    // i.preventDefault();
    const list = [...largest_capex];
    list.splice(index, 1);
    setinputList12(list);
  };

  // handle click event of the Add button
  const handleAddClick12 = () => {
    setinputList12([...largest_capex, { sUnit: "", mUnit: "" }]);
  };

  // handle input change
  const handleInputChange13 = (e, index) => {
    const { name, value } = e.target;
    // alert(e.target.name)
    if (e.target.name == "sUnit") {
      const list = [...smallest_operation];
      list[index][name] = value;
      setinputList13(list);
    } else {
      if (smallest_operation[index].sUnit != "") {
        const list = [...smallest_operation];
        list[index][name] = value;
        setinputList13(list);
      } else {
        const list = [...smallest_operation];
        list[index]["error"] = "Please Enter Unit Capacity";
        setinputList13(list);
      }
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick13 = (index) => {
    // i.preventDefault();
    const list = [...smallest_operation];
    list.splice(index, 1);
    setinputList13(list);
  };

  // handle click event of the Add button
  const handleAddClick13 = () => {
    // console.log(smallest_operation.length)
    // for( var i=0;i<smallest_operation.length;i++){
    //  if(smallest_operation[i].sUnit==''){
    //   smallest_operation[i].error='Please Enter Unit capacity'
    //  }else if(smallest_operation[i].munit==''){
    //   smallest_operation[i].error='Please Enter Power Cost'
    //  }else{
    setinputList13([
      ...smallest_operation,
      {
        sUnit: "",
        mUnit: "",
        consumableCost: "",
        manpowerCost: "",
        maintenanceCost: "",
        opexCost: "",
      },
    ]);

    //  }
    // }

    // if(smallest_operation.length==1){
    //   if(smallest_operation[0].sUnit!=""){
    //     setinputList13([...smallest_operation,{ sUnit: '', mUnit: '',consumableCost:'',manpowerCost:'',maintenanceCost:'',opexCost:'' }])
    //   }else{
    //     // setinputList13(smallest_operation[0].error)
    //   }
    // }
  };

  // handle input change
  const handleInputChange14 = (e, index) => {
    // const list = [...medium_operation]
    // list[index][name] = value
    // setinputList14(list)
    const { name, value } = e.target;
    if (e.target.name == "sUnit") {
      const list = [...medium_operation];
      list[index][name] = value;
      setinputList14(list);
    } else {
      if (medium_operation[index].sUnit != "") {
        const list = [...medium_operation];
        list[index][name] = value;
        setinputList14(list);
      } else {
        const list = [...medium_operation];
        list[index]["error"] = "Please Enter Unit Capacity";
        setinputList14(list);
      }
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick14 = (index) => {
    // i.preventDefault();
    const list = [...medium_operation];
    list.splice(index, 1);
    setinputList14(list);
  };

  // handle click event of the Add button
  const handleAddClick14 = () => {
    setinputList14([
      ...medium_operation,
      {
        sUnit: "",
        mUnit: "",
        consumableCost: "",
        manpowerCost: "",
        maintenanceCost: "",
        opexCost: "",
      },
    ]);
  };
  // handle input change
  const handleInputChange15 = (e, index) => {
    // const { name, value } = e.target
    // const list = [...largest_operation]
    // list[index][name] = value
    // setinputList15(list)
    const { name, value } = e.target;
    if (e.target.name == "sUnit") {
      const list = [...largest_operation];
      list[index][name] = value;
      setinputList15(list);
    } else {
      if (largest_operation[index].sUnit != "") {
        const list = [...largest_operation];
        list[index][name] = value;
        setinputList15(list);
      } else {
        const list = [...largest_operation];
        list[index]["error"] = "Please Enter Unit Capacity";
        setinputList15(list);
      }
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick15 = (index) => {
    // i.preventDefault();
    const list = [...largest_operation];
    list.splice(index, 1);
    setinputList15(list);
  };

  // handle click event of the Add button
  const handleAddClick15 = () => {
    setinputList15([
      ...largest_operation,
      {
        sUnit: "",
        mUnit: "",
        consumableCost: "",
        manpowerCost: "",
        maintenanceCost: "",
        opexCost: "",
      },
    ]);
  };
  const validateInput = () => {
    const {
      name,
      date,
      // technology,
      company,
      techDetails,
      currentDeployment,
      websiteURL,
      phone,
      email,
      address,
      nationality,
      technologyReadiness,
      technologyEndorsed,
      productModular,
      remotelyMonitored,
      skilledPersonnel,
      geographicTerrains,
      technologyCertified,
      technologyPatented,
      // madeInIndia,
      techSteps,
      techInputOutput,
      techCivilStructuer,
      systemReplacement,
      technologyListed,
      // annualPowerCost,
      // annualConsumableCost,
      // annualMaintenance,
      // annualManCost,
      timeRequired,
      detailsOfServicing,
      typeAndFrequencyOfReplacement,
      technologyName,
      otherThematicArea,
      productCompletelyManufactured,
      studEmail,
      detailsOfSupport,
      threats,
      opportunities,
      strengthTechno,
      weeknessTechno,
      compAddress,
      // compWebsite,
      limitationTechnology,
      // annualOpex
    } = formData;
    const formStatus = FormValidator.validateSubmitTechForm({
      name,
      date,
      // technology,
      company,
      techDetails,
      currentDeployment,
      websiteURL,
      phone,
      email,
      address,
      // nationality,
      technologyReadiness,
      technologyEndorsed,
      productModular,
      remotelyMonitored,
      skilledPersonnel,
      geographicTerrains,
      technologyCertified,
      technologyPatented,
      /*madeInIndia,*/
      techSteps,
      techInputOutput,
      techCivilStructuer,
      systemReplacement,
      technologyListed,

      // annualPowerCost,
      // annualConsumableCost,
      // annualMaintenance,
      // annualManCost,
      timeRequired,
      // detailsOfServicing,
      typeAndFrequencyOfReplacement,
      technologyName,
      otherThematicArea,
      productCompletelyManufactured,
      // limitationTechnology,
      compAddress,
      // compWebsite,
      studEmail,
      detailsOfSupport,
      threats,
      opportunities,
      strengthTechno,
      weeknessTechno,
      limitationTechnology,
      // annualOpex
    });
    const errors = {
      name: formStatus.name,
      date: formStatus.date,
      // technology: formStatus.technology,
      company: formStatus.company,
      techDetails: formStatus.techDetails,
      currentDeployment: formStatus.currentDeployment,
      websiteURL: formStatus.websiteURL,
      phone: formStatus.phone,
      email: formStatus.email,
      address: formStatus.address,
      nationality: formStatus.nationality,
      technologyReadiness: formStatus.technologyReadiness,
      technologyEndorsed: formStatus.technologyEndorsed,
      productModular: formStatus.productModular,
      remotelyMonitored: formStatus.remotelyMonitored,
      skilledPersonnel: formStatus.skilledPersonnel,
      geographicTerrains: formStatus.geographicTerrains,
      technologyCertified: formStatus.technologyCertified,
      technologyPatented: formStatus.technologyPatented,
      // madeInIndia: formStatus.madeInIndia,
      techSteps: formStatus.techSteps,
      techInputOutput: formStatus.techInputOutput,
      techCivilStructuer: formStatus.techCivilStructuer,
      systemReplacement: formStatus.systemReplacement,
      technologyListed: formStatus.technologyListed,
      // annualPowerCost: formStatus.annualPowerCost,
      // annualConsumableCost: formStatus.annualConsumableCost,
      // annualMaintenance: formStatus.annualMaintenance,
      // annualManCost: formStatus.annualManCost,
      timeRequired: formStatus.timeRequired,
      // detailsOfServicing: formStatus.detailsOfServicing,
      typeAndFrequencyOfReplacement: formStatus.typeAndFrequencyOfReplacement,
      technologyName: formStatus.technologyName,
      otherThematicArea: formStatus.otherThematicArea,
      productCompletelyManufactured: formStatus.productCompletelyManufactured,

      detailsOfSupport: formStatus.detailsOfSupport,
      threats: formStatus.threats,
      opportunities: formStatus.opportunities,
      strengthTechno: formStatus.strengthTechno,
      weeknessTechno: formStatus.weeknessTechno,
      compAddress: formStatus.compAddress,
      // compWebsite: formStatus.compWebsite,
      studEmail: formStatus.studEmail,
      limitationTechnology: formStatus.limitationTechnology,
      // annualOpex:formStatus.annualOpex,
    };
    setError(errors);
    console.log("Error found=", formStatus);
    return !formStatus.hasErrors;
  };

  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };
  const resetCaptcha1 = () => {
    recaptchaRef1.current.reset();
  };
  const onSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    /*!validateInput() ||*/
    if (!validateInput() || technologyPhotosVideos.length === 0) {
      setHasInvalidSubmitOccurred(true);
      if (technologyPhotosVideos.length === 0) {
        setUploadedFileTechError("This is required");
      } else {
        setUploadedFileTechError("");
      }
    } else {
      const recaptchaValue = recaptchaRef.current.getValue();
      const recaptchaValue1 = recaptchaRef1.current.getValue();
      if (recaptchaValue === "") {
        setCapthaError(true);
      } else if (recaptchaValue1 === "") {
        setCapthaError1(true);
      } else {
        setCapthaError(false);
        setCapthaError1(false);
        let payload = {
          ...formData,
          ...{ technologyPhotosVideos },
          secret: recaptchaValue,
          geographicTerrains: formData.geographicTerrains.split(","),
          skilledPersonnel: formData.skilledPersonnel.split(","),
          area_requirements: {
            smallest: smallest_area,
            medium: medium_area,
            largest: largest_area,
          },
          power_requirements: {
            smallest: smallest_power,
            medium: medium_power,
            largest: largest_power,
          },
          water_requirements: {
            smallest: smallest_water,
            medium: medium_water,
            largest: largest_water,
          },
          capex: {
            smallest: smallest_capex,
            medium: medium_capex,
            largest: largest_capex,
          },
          operation_requirements: {
            smallest: smallest_operation,
            medium: medium_operation,
            largest: largest_operation,
          },
          request_for: "submit_technology",
        };
        console.log("payload");
        console.log(payload);

        submitTechSubmit(payload).then((data) => {
          if (data.status) {
            setFormData(defaultFormData);
            setTechnologyPhotosVideos([]);
            setFilesTech([]);
            // setShowModal(true)
            resetCaptcha();
          }
        });
      }
    }
  };

  const handleInputChange = (e, name) => {
    if (e && e.target) {
      const { value } = e.target;
      setFormData((user) => {
        return { ...user, [name]: value };
      });
    }
  };

  const onBlur = () => {
    validateInput();
  };
  useEffect(() => {
    submitTechInfo();
  }, [technologyPhotosVideos]);
  const handleClose = () => setShowModal(false);
  const onUploadFileTech = (file, name) => {
    if (
      file.type == "image/png" ||
      file.type == "image/jpeg" ||
      file.type == "image/jpg" ||
      file.type == "application/pdf"
    ) {
      if (file.size / 1024 / 1024 > 5) {
        setUploadedFileTechError(
          "File Size is too large. Allowed maximum size for image and PDF isreturn; 5 MB."
        );
        return;
      }
    }

    if (
      file.type == "image/png" ||
      file.type == "image/jpeg" ||
      file.type == "image/jpg" ||
      file.type == "application/pdf"
    ) {
      const dataValue = new FormData();
      dataValue.append("campaign", file);

      validateFile(dataValue).then((res) => {
        if (res) {
          if (technologyPhotosVideos.length < 5) {
            const data = new FormData();
            data.append("file", file);
            data.append("request_for", "submit_technology");
            data.append("attachfile_secret", recaptchaRef1.current.getValue());

            // uploadFileTech(data).then(res => {
            //   console.log("res",res)
            //   console.log(res?.data?.fid[0]['value'])
            //   if (res?.data?.fid[0]['value']) {
            //     setFilesTech([...filesTech, res?.data?.filename[0]['value']])
            //     setTechnologyPhotosVideos([
            //       ...technologyPhotosVideos,
            //       res?.data?.fid[0]?.value,
            //     ])
            //     setUploadedFileTechError('')
            //   } else {
            //     // alert("something went wrong");
            //     toast.error('something went wrong')
            //   }
            // })
            var axios = require("axios");

            var config = {
              method: "post",
              url: UPLOAD_FILE_EVENT2,
              headers: {
                "Content-Type": "multipart/form-data",
                // "Content-Disposition": 'file; filename="' + file.name + '"',
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                if (response.status === 200) {
                  console.log("if");
                  setTimeout(() => {
                    recaptchaRef1.current.reset();
                    setCapthaError1assist(true);
                  }, 1000);
                  if (response?.data?.file_id) {
                    setFilesTech([...filesTech, response?.data?.file_name]);
                    setTechnologyPhotosVideos([
                      ...technologyPhotosVideos,
                      response?.data?.file_id,
                    ]);
                    setUploadedFileTechError("");
                  } else {
                    toast.error("something went wrong from upload file");
                  }
                } else {
                  console.log("else");
                }
              })
              .catch((e) => {
                toast.error("Something Went Wrong from submit tech index ");
                return { status: false };
              });
          }
        }
      });
    } else {
      if (technologyPhotosVideos.length < 5) {
        const dataValue = new FormData();
        dataValue.append("campaign", file);

        validateFile(dataValue).then((res) => {
          if (res) {
            const data = new FormData();
            data.append("file", file);
            data.append("request_for", "submit_technology");
            // var axios = require('axios');

            // var config = {
            //   method: 'post',
            //   url: TECH_IMG_UPLOAD_URL,
            //   headers: {
            //     'Content-Type': 'application/octet-stream',
            //     'Content-Disposition': 'file; filename="'+file.name+'"'
            //   },
            //   data : file
            // };

            // axios(config)
            // .then(function (response) {
            //         if (response.status === 201) {
            //             console.log("if")
            //             if (response?.data?.fid[0]['value']) {
            //               setFilesTech([...filesTech, response?.data?.filename[0]['value']])
            //               setTechnologyPhotosVideos([
            //                 ...technologyPhotosVideos,
            //                 response?.data?.fid[0]?.value,
            //               ])
            //               setUploadedFileTechError('')
            //             } else {
            //               toast.error('something went wrong from upload file')
            //             }

            //         } else {
            //             console.log("else")
            //         }
            // }).catch(e => {
            //     toast.error('Something Went Wrong from submit tech index ');
            //     return { status: false }
            // });
            uploadFileTech(data).then((res) => {
              console.log("res", res);
              console.log(res?.data?.fid[0]["value"]);
              if (res?.data?.fid[0]["value"]) {
                setFilesTech([...filesTech, res?.data?.filename[0]["value"]]);
                setTechnologyPhotosVideos([
                  ...technologyPhotosVideos,
                  res?.data?.fid[0]?.value,
                ]);
                setUploadedFileTechError("");
              } else {
                // alert("something went wrong");
                toast.error("something went wrong");
              }
            });
          }
        });
      } else {
        toast.error("You can upload maximum 5 files only");
        // alert("only five file accepted")
      }
    }
  };
  const removeFile = (file, type) => {
    if (type === "tech") {
      let index = filesTech.indexOf(file);
      if (index > -1) {
        technologyPhotosVideos.splice(index, 1);
        filesTech.splice(index, 1);
        setFilesTech([...filesTech]);
        setTechnologyPhotosVideos([...technologyPhotosVideos]);
      }
    }
  };
  return (
    <div className="submit-technology">
      {showModal && (
        <SuccessModal
          title={`Thank You, Your form has been submitted successfully`}
          show
          closeModal={handleClose}
        />
      )}
      <Breadcrumb data={items} displayName={"Submit Technology"} />
      <div className="form-lower-section">
        <div className="container">
          <div className="form-container submit-tech-form">
            <form onSubmit={(e) => onSubmit(e)}>
              <div
                className="submit-tech-form-banner"
                style={{
                  backgroundImage: `url(${technologyData?.banner?.image})`,
                }}
              >
                {ReactHtmlParser(technologyData?.banner?.heading)}
              </div>

              <div className="form-body">
                <div>
                  <div className="header-main-top">
                    <strong>
                      Waste to Wealth Mission is actively looking for
                      technologies addressing the following problem statements:
                    </strong>
                  </div>
                  <ul>
                    <li className="header-data">
                      Plastic Waste management in hilly terrain.
                    </li>
                    <li className="header-data">
                      Segregation of mixed Municipal Solid Waste (MSW).{" "}
                    </li>
                    <li className="header-data">Air quality monitoring. </li>
                    <li className="header-data">
                      AI based smart solutions for remote monitoring of deployed
                      technologies.{" "}
                    </li>
                  </ul>
                </div>
                <div className="d-flex ci_card-body">
                  <marquee className="marquee-label">
                    {" "}
                    <strong>Important Note:</strong> Due to high number of
                    applicants, the response time from the month of submission
                    is 8 weeks. Submit your technologies here:-
                  </marquee>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <InputField
                      required
                      type="text"
                      name="technologyName"
                      label="1. Technology Name"
                      value={formData.technologyName}
                      onChange={handleInputChange}
                      error={error.technologyName}
                      placeholder={"Technology Name"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>

                  <div className="col-sm-6">
                    <InputField
                      required
                      type="text"
                      name="name"
                      label="2. Name of Applicant"
                      value={formData.name}
                      onChange={handleInputChange}
                      error={error.name}
                      placeholder={"Enter Name"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>

                  <div className="col-sm-6">
                    <InputField
                      required
                      type="text"
                      name="company"
                      label="3. Company/Firm/Organization/Institution Name"
                      value={formData.company}
                      onChange={handleInputChange}
                      error={error.company}
                      containerClass={"form-group"}
                      placeholder={"Enter Organization Name"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputField
                      type="text"
                      name="nationality"
                      label="4. Nationality"
                      value={formData.nationality}
                      onChange={handleInputChange}
                      // error={error.nationality}
                      containerClass={"form-group"}
                      placeholder={"Enter Nationality"}
                      autoComplete="off"
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                    />
                  </div>
                  <div className="col-sm-6 email">
                    <InputField
                      required
                      type="text"
                      name="studEmail"
                      label="5. Email"
                      value={formData.studEmail}
                      onChange={handleInputChange}
                      error={error.studEmail}
                      placeholder={"example@gmail.com"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputField
                      required
                      type="text"
                      name="websiteURL"
                      label="6. Company Website"
                      value={formData.websiteURL}
                      onChange={handleInputChange}
                      error={error.websiteURL}
                      containerClass={"form-group"}
                      placeholder={"Enter Company Website"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputField
                      required
                      type="text"
                      name="compAddress"
                      label="7. Company Address"
                      value={formData.compAddress}
                      onChange={handleInputChange}
                      error={error.compAddress}
                      containerClass={"form-group"}
                      placeholder={"Enter Company Address"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-sm-6">
                    {/* {formData.technology==="131" && ( */}
                    <InputField
                      type="text"
                      name="otherThematicArea"
                      label="8. Please specify technology thematic area"
                      value={formData.otherThematicArea}
                      onChange={handleInputChange}
                      error={error.otherThematicArea}
                      containerClass={"form-group"}
                      placeholder={"Enter Organization Name"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                    {/* )} */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="mb-15">9. Technology Details</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="techDetails"
                      label={
                        "a. Technology Description (Briefly describe your product/solution, the problem it addresses and the key performance deliverables): 500 words"
                      }
                      value={formData.techDetails}
                      onChange={handleInputChange}
                      error={error.techDetails}
                      placeholder={"Technology Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="techSteps"
                      label="b. Please clearly describe the technical process step
                                            by step, giving technical details about each step"
                      value={formData.techSteps}
                      onChange={handleInputChange}
                      error={error.techSteps}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="techInputOutput"
                      label="c. Please specify the input and output values of
                                            technology parameters it addresses with
                                            appropriate units. (200 words)"
                      value={formData.techInputOutput}
                      onChange={handleInputChange}
                      error={error.techInputOutput}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <SelectDropdown
                      required
                      name="technologyReadiness"
                      label="d. Technology Readiness Level"
                      value={formData.technologyReadiness}
                      onChange={handleInputChange}
                      error={error.technologyReadiness}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      placeholder={"Select"}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      options={dropdownValue?.technologyReadiness}
                      autoComplete="off"
                      defaultOptionText="Select"
                    />
                  </div>
                  <div className="col-sm-6">
                    <SelectDropdown
                      required
                      name="systemReplacement"
                      label="e. Does the system require any replacement of
                                            critical components? "
                      value={formData.systemReplacement}
                      onChange={handleInputChange}
                      error={error.systemReplacement}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      placeholder={"Select"}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      options={dropdownValue?.yesNo}
                      autoComplete="off"
                      defaultOptionText="Select"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {formData.systemReplacement === "2" && (
                      <TextAreaField
                        type="text"
                        name="typeAndFrequencyOfReplacement"
                        label="If YES, please specify the type and frequency
                      (No./year) of replacement."
                        value={formData.typeAndFrequencyOfReplacement}
                        onChange={handleInputChange}
                        error={error.typeAndFrequencyOfReplacement}
                        placeholder={"Enter Details"}
                        containerClass={"form-group"}
                        onBlur={onBlur}
                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        className="form-input-field"
                        autoComplete="off"
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="techCivilStructuer"
                      label="f. Civil and structural works required for
                                            installation
                                            (100 words)"
                      value={formData.techCivilStructuer}
                      onChange={handleInputChange}
                      error={error.techCivilStructuer}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <SelectDropdown
                      required
                      name="productModular"
                      label="g. Is the product/solution modular and easily scalable to achieve higher capacities? "
                      value={formData.productModular}
                      onChange={handleInputChange}
                      error={error.productModular}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      placeholder={"Select"}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      options={dropdownValue?.yesNo}
                      autoComplete="off"
                      defaultOptionText="Select"
                    />
                  </div>
                  <div className="col-sm-6">
                    <SelectDropdown
                      required
                      name="remotelyMonitored"
                      label="h. Can the system be remotely monitored? "
                      value={formData.remotelyMonitored}
                      onChange={handleInputChange}
                      error={error.remotelyMonitored}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      placeholder={"Select"}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      options={dropdownValue?.yesNo}
                      autoComplete="off"
                      defaultOptionText="Select"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 multiple-selection-box">
                    <MultiSelect
                      required
                      label="i. Type of skilled personnel required to operate the system"
                      name="skilledPersonnel"
                      options={dropdownValue?.skillType}
                      value={formData.skilledPersonnel}
                      onChange={handleInputChange}
                      error={error.skilledPersonnel}
                      touched={hasInvalidSubmitOccurred}
                      valid={hasInvalidSubmitOccurred}
                      onBlur={onBlur}
                      returnArray
                      placeholder="Select"
                    />
                  </div>
                  <div className="col-sm-6 multiple-selection-box">
                    <MultiSelect
                      required
                      label="j. Suitability of the system for types of geographic terrains and climate"
                      name="geographicTerrains"
                      options={dropdownValue?.geographic}
                      value={formData.geographicTerrains}
                      onChange={handleInputChange}
                      error={error.geographicTerrains}
                      touched={hasInvalidSubmitOccurred}
                      valid={hasInvalidSubmitOccurred}
                      onBlur={onBlur}
                      returnArray
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="technologyCertified"
                      label="10. Is the technology certified? (Share certification details including standards, testing institution, certificate, validity etc -  200 words)"
                      value={formData.technologyCertified}
                      onChange={handleInputChange}
                      error={error.technologyCertified}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="technologyPatented"
                      label="11. Is the technology patented? Please share details of the patent, IP owner and licensing conditions - (200 words)"
                      value={formData.technologyPatented}
                      onChange={handleInputChange}
                      error={error.technologyPatented}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="productCompletelyManufactured"
                      label="12. Is the product completely manufactured in India? If not, % of product manufactured in India (100 words)"
                      value={formData.productCompletelyManufactured}
                      onChange={handleInputChange}
                      error={error.productCompletelyManufactured}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="technologyEndorsed"
                      label="13. Has this technology been endorsed by any govt. agency? If yes, then please provide details of the same, else write N/A ( 200 words"
                      value={formData.technologyEndorsed}
                      onChange={handleInputChange}
                      error={error.technologyEndorsed}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* <div className="App">
    <h3><a href="https://cluemediator.com">Clue Mediator</a></h3>
    {inputList.map((x, i) => {
      return (
        <div className="box">
          <input
            name="firstName"
 placeholder="Enter First Name"
            value={x.firstName}
            onChange={e => handleInputChangeTest(e, i)}
          />
          <input
            className="ml10"
            name="lastName"
 placeholder="Enter Last Name"
            value={x.lastName}
            onChange={e => handleInputChangeTest(e, i)}
          />
          <div className="btn-box">
            {inputList.length !== 1 && <button
              className="mr10"
              onClick={() => handleRemoveClickTest(i)}>Remove</button>}
            {inputList.length - 1 === i && <button onClick={handleAddClickTest}>Add</button>}
          </div>
        </div>
      );
    })}
    <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
  </div> */}

                <div className="row">
                  <div className="col-sm-12">
                    <SelectDropdown
                      required
                      name="technologyListed"
                      label="14. Is the technology listed in the GEM Portal of
                                            Government of India?"
                      value={formData.technologyListed}
                      onChange={handleInputChange}
                      error={error.technologyListed}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      placeholder={"Select"}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      options={dropdownValue?.yesNo}
                      autoComplete="off"
                      defaultOptionText="Select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="limitationTechnology"
                      label="15. Limitations of technology
                      (Please state optimum conditions required for technology to perform efficiently - 300 words)"
                      value={formData.limitationTechnology}
                      onChange={handleInputChange}
                      error={error.limitationTechnology}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="currentDeployment"
                      label="16. Current Deployment (Location, Client and Capacity - 500 words)"
                      value={formData.currentDeployment}
                      onChange={handleInputChange}
                      error={error.currentDeployment}
                      placeholder={"Enter Details"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="mb-15">17. Area Requirement (m2)</label>
                  </div>
                </div>
                {smallest_area.map((x, i) => {
                  return (
                    <>
                      <div
                        className="row d-flex text-center margin4 margin4"
                        key={`smallest_area_${i}`}
                      >
                        <label className="mb-15 col-sm-2 ">Smallest Unit</label>
                        <div className="ml10 col-sm-4">
                          <InputField
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            onChange={(e) => handleInputChange1(e, i)}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Area Requirement (m2)"
                            value={x.mUnit}
                            onChange={(e) => handleInputChange1(e, i)}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box">
                          {smallest_area.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick(i)}
                            >
                              Remove
                            </button>
                          )}
                          {smallest_area.length - 1 === i &&
                            smallest_area.length <= 9 && (
                              <button
                                onClick={handleAddClick}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {medium_area.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Medium Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange2(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Area Requirement (m2)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange2(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {medium_area.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick2(i)}
                            >
                              Remove
                            </button>
                          )}
                          {medium_area.length - 1 === i &&
                            medium_area.length <= 9 && (
                              <button
                                onClick={handleAddClick2}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {largest_area.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Largest Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange3(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Area Requirement (m2)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange3(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {largest_area.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick3(i)}
                            >
                              Remove
                            </button>
                          )}
                          {largest_area.length - 1 === i &&
                            largest_area.length <= 9 && (
                              <button
                                onClick={handleAddClick3}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="row">
                  <div className="col-sm-12">
                    <label className="mb-15">
                      18. Monthly Power Requirement (kW)
                    </label>
                  </div>
                </div>
                {smallest_power.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2">Smallest Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange4(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Power Requirement (kW)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange4(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {smallest_power.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick4(i)}
                            >
                              Remove
                            </button>
                          )}
                          {smallest_power.length - 1 === i &&
                            smallest_power.length <= 9 && (
                              <button
                                onClick={handleAddClick4}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}

                {medium_power.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Medium Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange5(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Power Requirement (kW)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange5(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {medium_power.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick5(i)}
                            >
                              Remove
                            </button>
                          )}
                          {medium_power.length - 1 === i &&
                            medium_power.length <= 9 && (
                              <button
                                onClick={handleAddClick5}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {largest_power.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Largest Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange6(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Power Requirement (kW)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange6(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {largest_power.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick6(i)}
                            >
                              Remove
                            </button>
                          )}
                          {largest_power.length - 1 === i &&
                            largest_power.length <= 9 && (
                              <button
                                onClick={handleAddClick6}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className="row">
                  <div className="col-sm-12">
                    <label className="mb-15">
                      19. Monthly Water Requirement (KL)
                    </label>
                  </div>
                </div>

                {smallest_water.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Smallest Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange7(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Water Requirement (KL)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange7(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {smallest_water.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick7(i)}
                            >
                              Remove
                            </button>
                          )}
                          {smallest_water.length - 1 === i &&
                            smallest_water.length <= 9 && (
                              <button
                                onClick={handleAddClick7}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {medium_water.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2">Medium Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange8(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Water Requirement (KL)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange8(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {medium_water.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick8(i)}
                            >
                              Remove
                            </button>
                          )}
                          {medium_water.length - 1 === i &&
                            medium_water.length <= 9 && (
                              <button
                                onClick={handleAddClick8}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {largest_water.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Largest Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange9(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Enter Water Requirement (KL)"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange9(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {largest_water.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick9(i)}
                            >
                              Remove
                            </button>
                          )}
                          {largest_water.length - 1 === i &&
                            largest_water.length <= 9 && (
                              <button
                                onClick={handleAddClick9}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className="row">
                  <div className="col-sm-12">
                    <label className="mb-15">
                      20. Capex (INR Lakhs) (Please specify complete project
                      execution cost including civil, electromechanical and
                      others of your smallest, medium and largest capacity
                      units)
                    </label>
                  </div>
                </div>
                {smallest_capex.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Smallest Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange10(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            value={x.mUnit}
                            name="mUnit"
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            placeholder="Project Excecution cost (INR Lakhs)"
                            onChange={(e) => handleInputChange10(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {smallest_capex.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick10(i)}
                            >
                              Remove
                            </button>
                          )}
                          {smallest_capex.length - 1 === i &&
                            smallest_capex.length <= 9 && (
                              <button
                                onClick={handleAddClick10}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {medium_capex.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Medium Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            min="0"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange11(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Project Excecution cost (INR Lakhs)"
                            value={x.mUnit}
                            min="0"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange11(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {medium_capex.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick11(i)}
                            >
                              Remove
                            </button>
                          )}
                          {medium_capex.length - 1 === i &&
                            medium_capex.length <= 9 && (
                              <button
                                onClick={handleAddClick11}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {largest_capex.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Largest Unit</label>
                        <div className="ml10 col-sm-4">
                          <input
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            min="0"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange12(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Project Excecution cost (INR Lakhs)"
                            value={x.mUnit}
                            min="0"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange12(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {largest_capex.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick12(i)}
                            >
                              Remove
                            </button>
                          )}
                          {largest_capex.length - 1 === i &&
                            largest_capex.length <= 9 && (
                              <button
                                onClick={handleAddClick12}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className="row">
                  <div className="col-sm-12">
                    <label className="mb-15">
                      21. Operation and Maintenance Cost (Please specify annual
                      O&M cost of your smallest, medium and largest capacity
                      units)
                    </label>
                  </div>
                </div>

                {smallest_operation.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Smallest Unit</label>

                        <div className="ml10 col-sm-4">
                          <InputField
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            error={!x.sUnit && x.error}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                            onChange={(e) => handleInputChange13(e, i)}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <InputField
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Annual Power Cost(INR lakhs)*"
                            // onBlur={onBlur}
                            // hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                            onChange={(e) => handleInputChange13(e, i)}
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange13(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                      </div>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 "></label>

                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            name="consumableCost"
                            placeholder="Annual Consumables Cost(INR lakhs)*"
                            value={x.consumableCost}
                            step="1"
                            min="1"
                            value={
                              x.consumableCost && Math.max(0, x.consumableCost)
                            }
                            onChange={(e) => handleInputChange13(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="manpowerCost"
                            placeholder="Annual Manpower Cost(INR lakhs)*"
                            value={x.manpowerCost}
                            step="1"
                            min="1"
                            value={
                              x.manpowerCost && Math.max(0, x.manpowerCost)
                            }
                            onChange={(e) => handleInputChange13(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                      </div>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 "></label>

                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            name="maintenanceCost"
                            placeholder="Annual Maintainance Cost(INR lakhs)*"
                            value={x.maintenanceCost}
                            step="1"
                            min="1"
                            value={
                              x.maintenanceCost &&
                              Math.max(0, x.maintenanceCost)
                            }
                            onChange={(e) => handleInputChange13(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="opexCost"
                            placeholder="Total Annual Opex Cost(INR lakhs)*"
                            value={x.opexCost}
                            step="1"
                            min="1"
                            value={x.opexCost && Math.max(0, x.opexCost)}
                            onChange={(e) => handleInputChange13(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>

                        <div className="btn-box ">
                          {smallest_operation.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick13(i)}
                            >
                              Remove
                            </button>
                          )}
                          {smallest_operation.length - 1 === i &&
                            smallest_operation.length <= 9 && (
                              <button
                                onClick={handleAddClick13}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {medium_operation.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Medium Unit</label>

                        <div className="ml10 col-sm-4">
                          <InputField
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange14(e, i)}
                            error={!x.sUnit && x.error}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            placeholder="Annual Power Cost(INR lakhs)*"
                            value={x.mUnit}
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange14(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                      </div>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 "></label>

                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            name="consumableCost"
                            placeholder="Annual Consumables Cost(INR lakhs)*"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            value={
                              x.consumableCost && Math.max(0, x.consumableCost)
                            }
                            onChange={(e) => handleInputChange14(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="manpowerCost"
                            placeholder="Annual Manpower Cost(INR lakhs)*"
                            value={x.manpowerCost}
                            step="1"
                            min="1"
                            value={
                              x.manpowerCost && Math.max(0, x.manpowerCost)
                            }
                            onChange={(e) => handleInputChange14(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                      </div>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 "></label>

                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            name="maintenanceCost"
                            placeholder="Annual Maintainance Cost(INR lakhs)*"
                            // value={x.sUnit}
                            step="1"
                            min="1"
                            value={
                              x.maintenanceCost &&
                              Math.max(0, x.maintenanceCost)
                            }
                            onChange={(e) => handleInputChange14(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="opexCost"
                            placeholder="Total Annual Opex Cost(INR lakhs)*"
                            value={x.opexCost}
                            step="1"
                            min="1"
                            value={x.opexCost && Math.max(0, x.opexCost)}
                            onChange={(e) => handleInputChange14(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="btn-box ">
                          {medium_operation.length !== 1 && (
                            <button
                              className="mr10 red-border-btn-remove"
                              onClick={() => handleRemoveClick14(i)}
                            >
                              Remove
                            </button>
                          )}
                          {medium_operation.length - 1 === i &&
                            medium_operation.length <= 9 && (
                              <button
                                onClick={handleAddClick14}
                                className="green-border-btn-add"
                              >
                                Add
                              </button>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {largest_operation.map((x, i) => {
                  return (
                    <>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 ">Largest Unit</label>

                        <div className="ml10 col-sm-4">
                          <InputField
                            type="text"
                            name="sUnit"
                            placeholder="Enter Unit Capacity"
                            value={x.sUnit}
                            step="1"
                            min="1"
                            // value={x.sUnit && Math.max(0, x.sUnit)}
                            onChange={(e) => handleInputChange15(e, i)}
                            error={!x.sUnit && x.error}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="mUnit"
                            value={x.mUnit}
                            placeholder="Annual Power Cost(INR lakhs)*"
                            step="1"
                            min="1"
                            value={x.mUnit && Math.max(0, x.mUnit)}
                            onChange={(e) => handleInputChange15(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                      </div>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 "></label>

                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            name="consumableCost"
                            placeholder="Annual Consumables Cost(INR lakhs)*"
                            value={x.consumableCost}
                            step="1"
                            min="1"
                            value={
                              x.consumableCost && Math.max(0, x.consumableCost)
                            }
                            onChange={(e) => handleInputChange15(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="manpowerCost"
                            placeholder="Annual Manpower Cost(INR lakhs)*"
                            value={x.manpowerCost}
                            step="1"
                            min="1"
                            value={
                              x.manpowerCost && Math.max(0, x.manpowerCost)
                            }
                            onChange={(e) => handleInputChange15(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                      </div>
                      <div className="row d-flex text-center margin4" key={i}>
                        <label className="mb-15 col-sm-2 "></label>

                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            name="maintenanceCost"
                            placeholder="Annual Maintainance Cost(INR lakhs)*"
                            value={x.maintenanceCost}
                            step="1"
                            min="1"
                            value={
                              x.maintenanceCost &&
                              Math.max(0, x.maintenanceCost)
                            }
                            onChange={(e) => handleInputChange15(e, i)}
                            onKeyDown={formatInput}
                          />
                        </div>
                        <div className="ml10 col-sm-4">
                          <input
                            type="number"
                            className="ml10"
                            name="opexCost"
                            value={x.opexCost}
                            placeholder="Total Annual Opex Cost(INR lakhs)*"
                            step="1"
                            min="1"
                            value={x.opexCost && Math.max(0, x.opexCost)}
                            onChange={(e) => handleInputChange15(e, i)}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="number"
                      name="timeRequired"
                      label="22. Time required (days) for installation and commissioning of the system"
                      value={formData.timeRequired}
                      onChange={handleInputChange}
                      error={error.timeRequired}
                      placeholder={
                        "Time required (days) for installation and commissioning of the system"
                      }
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      // value={formData.timeRequired && Math.max(0, formData.timeRequired)}
                      autoComplete="off"
                      onKeyDown={formatInput}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <TextAreaField
                      required
                      type="text"
                      name="detailsOfSupport"
                      label="23. Details of support provided post installation
                      (Also mention replacement of parts/spares, manpower provided, turnaround time etc - 300 words)"
                      value={formData.detailsOfSupport}
                      onChange={handleInputChange}
                      error={error.detailsOfSupport}
                      placeholder={
                        "Details of support provided post installation "
                      }
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="mb-15">
                      24. Preliminary SWOT Analysis of Technology
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <TextAreaField
                    required
                    type="text"
                    name="strengthTechno"
                    label="a. Strengths of Technology
                      (Also highlight advantages of technology compared to similar technologies in market - 300 words)"
                    value={formData.strengthTechno}
                    onChange={handleInputChange}
                    error={error.strengthTechno}
                    placeholder={"Enter Details"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    className="form-input-field"
                    autoComplete="off"
                  />
                </div>
                <div className="col-sm-12">
                  <TextAreaField
                    required
                    type="text"
                    name="weeknessTechno"
                    label="b. Weaknesses of Technology (300 words)"
                    value={formData.weeknessTechno}
                    onChange={handleInputChange}
                    error={error.weeknessTechno}
                    placeholder={"Enter Details"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    className="form-input-field"
                    autoComplete="off"
                  />
                </div>
                <div className="col-sm-12">
                  <TextAreaField
                    required
                    type="text"
                    name="opportunities"
                    label="c. Opportunities
                      (Also highlight market opportunity for the technology, prospective customers - 300 words)"
                    value={formData.opportunities}
                    onChange={handleInputChange}
                    error={error.opportunities}
                    placeholder={"Enter Details"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    className="form-input-field"
                    autoComplete="off"
                  />
                </div>
                <div className="col-sm-12">
                  <TextAreaField
                    required
                    type="text"
                    name="threats"
                    label="d. Threats (Competitors, similar technologies - 300 words )"
                    value={formData.threats}
                    onChange={handleInputChange}
                    error={error.threats}
                    placeholder={"Enter Details"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    className="form-input-field"
                    autoComplete="off"
                  />
                </div>

                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6"></div>
                </div>
                <div className="col-12 col-lg-7">
                  <div className="captcha-field">
                    <ReCAPTCHA
                      ref={recaptchaRef1}
                      sitekey={SITE_KEY}
                      onChange={() => {
                        setCapthaError1assist(false);
                      }}
                    />
                    {capthaError1 && (
                      <InlineError message={"This is required"} />
                    )}
                  </div>
                </div>
                <div className="row upload-file">
                  <div className="col-sm-12">
                    <div
                      style={{
                        pointerEvents:
                          capthaErrorassist === true ? "none" : "auto",
                        opacity: capthaErrorassist === true ? 0.5 : 1,
                        // borderColor:
                        //   capthaErrorassist === true ? "lightgrey" : "#2ba749",
                        // color: capthaErrorassist === true ? "lightgrey" : "#2ba749",
                      }}
                    >
                      <FileUpload
                        htmlFor="uploadFileInput1"
                        maxFileSizeInMB={100}
                        labelClass="green-border-btn"
                        onUploadFile={(file) =>
                          onUploadFileTech(file, "proofOfTechDetails")
                        }
                        allowedFiles={[
                          ".png",
                          ".pdf",
                          ".jpg",
                          ".jpeg",
                          ".mp4",
                          ".ogg",
                          ".mkv",
                          ".mov",
                        ]}
                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        error=""
                        onBlur={onBlur}
                        id="uploadFileInput1"
                        visbility="hidden"
                        label={"Attach File"}
                      />

                      <div
                        className={`overlay ${
                          uploadedFileTechError ? "error" : ""
                        }`}
                      >
                        <i className="upload-file-icon"></i>
                        <div>
                          <h6 required>
                            Upload Technology Photos/Videos
                            <span className="required">*</span>
                          </h6>
                          <span>Maximum 5 uploads</span>
                        </div>
                      </div>
                    </div>

                    {uploadedFileTechError && (
                      <div className="inline-error">{`* ${uploadedFileTechError}`}</div>
                    )}
                    {filesTech &&
                      filesTech.length > 0 &&
                      filesTech.map((file) => (
                        <p className="file-name">
                          {file}{" "}
                          <span
                            className="del-file"
                            onClick={() => {
                              removeFile(file, "tech");
                            }}
                          >
                            X
                          </span>
                        </p>
                      ))}
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <h3 className="sub-title">Contact Details</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 email">
                    <InputField
                      required
                      type="text"
                      name="email"
                      label="Email Id"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={error.email}
                      placeholder={"abc@gmail.com"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-sm-6 phone">
                    <InputField
                      required
                      type="text"
                      name="phone"
                      label="Phone Number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      error={error.phone}
                      containerClass={"form-group"}
                      placeholder={"+91-987xxxxx21"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TextAreaField
                      required
                      type="text"
                      name="address"
                      label="Address"
                      value={formData.address}
                      onChange={handleInputChange}
                      error={error.address}
                      placeholder={"Address"}
                      containerClass={"form-group"}
                      onBlur={onBlur}
                      hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                      className="form-input-field"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-7">
                  <div className="captcha-field">
                    <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
                    {capthaError && (
                      <InlineError message={"This is required"} />
                    )}
                  </div>
                </div>

                <div className="form-footer">
                  <div className="row footer-btn-wrap">
                    <div className="bttn-group col-lg-12 d-flex justify-content-end">
                      <Link to="/" className="bordered-btn green-border">
                        Cancel
                      </Link>
                      <Button
                        type="submit"
                        text="Submit Application"
                        className="form-submit-button"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    technologyData: state.SubmitTechReducer.submitTechInfo.data,
  };
};
export default connect(mapStateToProps, {
  submitTechSubmit,
  validateFile,
  submitTechInfo,
  uploadFileTech,
})(withRouter(SubmitTechnology));
