/**
 * This  component use for list of current intervention list with filter
 */
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { getRutagData } from "./dependencies/action";

import Banner from "./dependencies/component/Banner";

// import OverView2 from "../dependencies/component/OverView2";
// import { ShareSocial } from "react-share-social";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

// import InputField from "../../../components/common/InputField";
// import Button from "../../../components/common/Button";
// import ReactHtmlParser from "react-html-parser";
// import searchIcon from "../../../assests/svg/searchIcon.svg";
// import {
//   getChallengeEventList,
//   getTechnologyType,
//   getFilterData,
//   getArticlesDetailsType,
// } from "../dependencies/action";

// import LeftSideCategory from "../../../components/common/LeftSideCategory";
// import GlobalDropDown from "../../common/GlobalDropDown";
import Breadcrumb from "../common/Breadcrumbs";
import "./style.scss";
import { initGA, logPageView } from "../../dependencies/utils/analytics";
import { navigate } from "@reach/router";

const items = [
  // { to: "/", label: "/" },
  // { to: "/current-intervention-listing", label: "What We Do" },
  // { to: "/current-intervention-listing", label: "Our Sentinels" },
  // {
  //   to: "/current-intervention-listing",
  //   label: "Current Projects",
  //   active: true,
  // },

  { to: "/", label: "/" },
  { to: "/rutag", label: "Resources" },
  {
    to: "/rutag",
    label: "RuTAG",
    active: true,
  },
];

const style = {
  background: "none",
  borderRadius: 0,
  border: 0,
  color: "transparent",
  padding: "0px",
  boxShadow: "0px",
  // display: "none",
};

const Rutagmainpage = (props) => {
  const {
    getChallengeEventList,
    currentInterventionList,
    getTechnologyType,
    interventionTechnology,
    getFilterData,
    filterData,
    articlesDetails,
    getRutagData,
    getArticlesDetailsType,
    bannerList,
    rutagList,
  } = props;
  useEffect(() => {
    getRutagData();
  }, [getRutagData]);

  const history = useHistory();
  const [page, setPage] = useState(0);
  const [categoryId, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [pageStatus, setPageStatus] = useState(false);
  const [isMoreFilter, setMoreFilter] = useState(false);
  const handleShowMoreFiltere = () => setMoreFilter(!isMoreFilter);
  const defaultFormData = {
    state: "",
    city: "",
    projectTimeline: "",
    useType: "",
    subTheme: "",
    scale: "",
    specialCondition: "",
    search: "",
  };
  const [formData, setFormData] = useState(defaultFormData);

  const sharesocial = useRef();
  const articlestitletext = useRef();

  const changePageNumber = (pageNumber) => {
    setPageStatus(false);
    setPage((pageNumber - 1) * 6);
  };
  // useEffect(() => {
  //   getChallengeEventListData();
  // }, [page, categoryId, style]);
  const handleInputChange = (e, name) => {
    const { value } = e.target;

    setFormData((user) => {
      return { ...user, [name]: value };
    });
  };

  useEffect(() => {
    // const reloadCount = sessionStorage.getItem("reloadCount");
    // if (reloadCount < 2) {
    //   // sharesocial.current.style.display = "none";
    //   sessionStorage.setItem("reloadCount", String(reloadCount + 1));
    //   window.location.reload();
    //   do {
    //     sharesocial.current.style.display = "none";
    //   } while (window.location.reload());
    // } else {
    //   sessionStorage.removeItem("reloadCount");
    //   // sharesocial.current.style.display = "block";
    // }
  }, []);

  const mostreaddata = [
    {
      id: 1,
      date: "May 08, 2021",
      title:
        "Applying for a Curfew/Movement/Emergency Pass during COVID-19 Lockdown",
    },
    {
      id: 2,
      date: "May 09, 2021",
      title: "State and National funds accepting donations for COVID-19",
    },
    {
      id: 3,
      date: "May 12, 2021",
      title: "10 Types of Financial Services Offered in India",
    },
  ];

  useEffect(() => {
    // to call the initialise google analytics funtion
    try {
      initGA();
      window.GA_INITIALIZED = true;
      // to save the number og visitors on this side
      logPageView();
    } catch (error) {}
  }, [window]);

  // const getChallengeEventListData = () => {
  //   getArticlesDetailsType(props.location.state?.pageLink);
  // };
  const onSubmit = (e) => {
    e.preventDefault();
    setPage(0);
    setPageStatus(true);
    // getChallengeEventListData();
  };

  useEffect(() => {
    // getTechnologyType();
    // getFilterData();
    // getArticlesDetailsType();
    // getChallengeEventListData();
  }, []);

  const getCategoryId = (Id) => {
    setCategory(Id);
  };

  const dummycards = [
    {
      id: 1,
      title: "Smart Vending E-cart",
      text1: {
        title: "Developed by:",
        content: "RuTAG, Indian Institute of Technology Bombay",
      },
      text2: {
        title: "Sector Catered:",
        content: "Livelihood",
      },
      backcolor: "#00aadf",
    },
    {
      id: 2,
      title: "Pico Hydro Power Based Cold Storage Unit",
      text1: {
        title: "Developed by:",
        content: "RuTAG, Indian Institute of Technology Roorkee",
      },
      text2: {
        title: "Sector Catered:",
        content: "Agriculture/Energy/Livelihood",
      },
      backcolor: "#dc912c",
    },
    {
      id: 3,
      title: "Groundwater Level Measuring Device",
      text1: {
        title: "Developed by:",
        content: "RuTAG, Indian Institute of Technology Delhi",
      },
      text2: {
        title: "Sector Catered:",
        content: "Water management",
      },
      backcolor: "#00aadf",
    },
    {
      id: 4,
      title: "Tulsi Mala Bead Making Device",
      text1: {
        title: "Developed by:",
        content: "RuTAG, Indian Institute of Technology Delhi",
      },
      text2: {
        title: "Sector Catered:",
        content: "Artisan",
      },
      backcolor: "#dc912c",
    },
    {
      id: 5,
      title: "Oil Extraction setup",
      text1: {
        title: "Developed by:",
        content: "RuTAG, Indian Institute of Technology Kanpur",
      },
      text2: {
        title: "Sector Catered:",
        content: "Agriculture/Food",
      },
      backcolor: "#00aadf",
    },
    {
      id: 6,
      title: "Small Scale Paddy Thresher",
      text1: {
        title: "Developed by:",
        content: "RuTAG, Indian Institute of Technology Kanpur",
      },
      text2: {
        title: "Sector Catered:",
        content: "Agriculture/Food",
      },
      backcolor: "#dc912c",
    },
  ];

  return (
    <div className="Rutagmainpage">
      <Breadcrumb data={items} displayName={rutagList?.heading} />

      <div
        className="bottom-section-list"
        style={{
          paddingTop: "0px",
        }}
      >
        <Banner rutagdata={rutagList?.list} />
        <div
          className="common-listing blogcontainer"
          style={{
            paddingTop: "25px",
            backgroundColor: "white",
          }}
        >
          <div className="row blogrow">
            {/* <div className="col-3 sidebar">
                
              </div> */}
            <div className="col-xs-12 list-container">
              <div
                className="boxWithShadow2"
                style={{
                  textAlign: "left",
                }}
              >
                {/* <div className="social-icons-div">
                   <div>
                     <a href="#" class="fa fa-facebook"></a>
                     <a href="#" class="fa fa-twitter"></a>
                     <a href="#" class="fa fa-linkedin"></a>
                   </div>
                 </div> */}

                {/* social div
                 */}
                {/* <div
                  ref={sharesocial}
                  className="reactSocialDiv"
                  // style={{ display: "flex", justifyContent: "flex-end" }}
                >
                 
                  <div className="sharetoheader">share</div>
                  <div
                    // style={{ width: "fit-content", position: "absolute" }}
                    className="reactsocialinner"
                  >
                    <FacebookShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      className="socialiconinner"
                      url={`https://uat.wastetowealth.gov.in/articles-case-studies/${props.location.state?.pageLink}`}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                  <div className="blogpage-date-tag">
                    <time>
                      {articlesDetails?.data?.publish_date != null
                        ? articlesDetails?.data?.publish_date
                        : "NA"}
                    </time>
                  </div>
                </div> */}

                <h1
                  // ref={articlestitletext}
                  className="blogTitleName"
                  style={{
                    width: "85%",
                    textAlign: "left",
                  }}
                >
                  <span
                    ref={articlestitletext}
                    class="field field--name-title field--type-string field--label-hidden rutagheader"
                  >
                    {rutagList?.list && rutagList?.list[0]?.title}
                  </span>
                </h1>

                <div
                  class="field field--name-body field--type-text-with-summary field--label-hidden field__item"
                  style={{
                    paddingTop: "2%",
                  }}
                >
                  <p
                    class="text-align-justify articlesparajustified"
                    style={{ textAlign: "left" }}
                  >
                    {ReactHtmlParser(
                      rutagList?.list && rutagList?.list[0]?.description
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 list-container">
              <div className="row cardrowrutag">
                {/* {dummycards &&
                  dummycards.map((item, index) => ( */}
                {rutagList &&
                  rutagList?.list &&
                  rutagList?.list[0]?.rualtechlist.map((item, index) => (
                    <div
                      className="col-md-4 col-sm-12 "
                      style={{
                        height: "16rem",
                        marginBottom: "3%",
                      }}
                    >
                      <div
                        className="card rutagboxdiv"
                        style={{
                          height: "100%",
                          backgroundColor: "#00aadf",
                          cursor: "pointer",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          history.push({
                            pathname: `/rutag${item?.pagelink}`,
                            state: {
                              item: item,
                              // location state
                              title: item?.title,
                              pagelink: `${item?.pagelink}`,
                            },
                          });
                        }}
                      >
                        <div
                          className="card-body row"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {/* <h5 className="card-title">Special title treatment</h5> */}
                          <p
                            className="card-text rutagheader"
                            style={{
                              fontSize: "18px",
                              marginTop: 0,
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            {item?.title}
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "16px",
                              }}
                            >
                              Developed by
                            </p>
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "16px",
                                marginTop: "-3%",
                              }}
                            >
                              {item?.Developed_by}
                            </p>
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "16px",
                              }}
                            >
                              Sector Catered
                            </p>
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "16px",
                                marginTop: "-3%",
                              }}
                            >
                              {item?.Sector_Catered}
                            </p>
                          </p>

                          {/* <a href="#" className="btn btn-primary">
                        Go somewhere
                      </a> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("redux states articles detail", state);
  return {
    // currentInterventionList: state.ArticlesReducer.currentInterventionList.data,
    // interventionTechnology: state.ArticlesReducer.interventionTechnology.data,
    // filterData: state.ArticlesReducer.filterData,
    // articlesDetails: state.ArticlesReducer.articlesDetails,
    // bannerList: state.RutagReducer.bannerList,
    rutagList: state.RutagReducer.rutagList,
  };
};

export default connect(mapStateToProps, {
  // getChallengeEventList,
  // getTechnologyType,
  // getFilterData,
  // getArticlesDetailsType,
  getRutagData,
})(withRouter(Rutagmainpage));
