import ReactHtmlParser from 'react-html-parser'
import './style.scss'
import dummyMissionImage from '../../../../../assests/images/dummy_img.png'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import SocialShare from '../../../../common/SocialShare'
import { Parallax } from 'react-scroll-parallax'
import { useController } from 'react-scroll-parallax'
import Slider from 'react-slick'
import evnbg from '../../../../../assests/images/pledge-certificate.png'
const RecentNews = props => {
  const { data, count } = props
  const { parallaxController } = useController()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length > 3 ? data?.length : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: false,
  }
  useEffect(() => {
    console.log(data)
  })
  useLayoutEffect(() => {
    const handler = () => parallaxController.update()
    window.addEventListener('load', handler)
    return () => window.removeEventListener('load', handler)
  }, [parallaxController])
  return (
    <div className="recent-news-bg-color bg-recent-news">
      <div className="yellow-line" />
      <div className="blue-line" />
      <div className="green-line" />

      <div className="container">
        <div className="container">
          <div className="container-wrap">
            <div className="row-recent-news">
              <div className="col-lg-12">
                {/* <div className='partner-heading'>{ReactHtmlParser(heading)}</div>
                            <div className='partner-sub-heading'>{ReactHtmlParser(description)}</div> */}

                <div className="recent-news-title">
                  <span>Swachhta Saarthi News</span>
                </div>
                {/* <div className='partner-sub-heading'>subtitles</div> */}
              </div>
            </div>
            <div className=" recent-news-detail-row">
              <Slider {...settings}>
                {data &&
                  Array.isArray(data.list) &&
                  data.list.length > 0 &&
                  data.list.map((value, index) => {
                    return (
                      <div className="recent-news-detail" key={index}>
                        <div className="recent-news-margin">
                          <div className="card recent-news-card" key={index}>
                            <div className="row no-gutters recent-news-card-row hover-zoom">
                              <div className="recent-news-card-image-holder">
                                <img
                                  className="card-img recent-news-card-image"
                                  src={value?.thumbnail}
                                  alt="Suresh Dasari Card"
                                />
                              </div>
                              <div>
                                <div className="col-lg-12">
                                  <div className="col-lg-12 recent-news-card-body">
                                    <h5 className="card-title recent-news-card-title clock-icon">
                                      {' '}
                                      {value?.date}
                                    </h5>
                                    {value?.newsType === 'Internal' ? (
                                      <Link to={value?.pagelink}>
                                        <p className="card-text recent-news-card-text">
                                          {value?.title}
                                        </p>
                                      </Link>
                                    ) : (
                                      <a href={value?.pagelink} target="_blank">
                                        <p className="card-text recent-news-card-text">
                                          {value?.title}
                                        </p>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <img src="https://uat.wastetowealth.gov.in/s3fs/2021-09/Sonia%20Duhan-01.jpeg" className='about-mentor-image' />
                                            <div className='about-mentor-name'>{value?.name}</div>
                                            <div className='about-mentor-designation'>{value?.designation}</div> */}
                        </div>
                      </div>
                    )
                  })}
              </Slider>

              <div className="margin-boy boy-bottle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecentNews
