import { combineReducers } from 'redux'
import {
  ABOUT_COHORT_GET_STATE_DETAILS,
  ABOUT_US,
  FELLOWSHIP_INITIATIVES,
} from '../../../constants/actionConstants'

const aboutUs = (state = {}, action) => {
  switch (action.type) {
    case ABOUT_US:
      return { ...action.payload }
    default:
      return state
  }
}
const aboutcohortStateDetails = (state = {}, action) => {
  switch (action.type) {
    case ABOUT_COHORT_GET_STATE_DETAILS:
      return { ...action.payload }
    default:
      return state
  }
}

const fellowshipInitiatives = (state = {}, action) => {
  switch (action.type) {
    case FELLOWSHIP_INITIATIVES:
      return { ...action.payload }
    default:
      return state
  }
}
const StaticPageReducer = combineReducers({
  aboutUs,
  aboutcohortStateDetails,
  fellowshipInitiatives,
})

export default StaticPageReducer
