import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import InputField from '../../../components/common/InputField';
import Button from '../../../components/common/Button';
import ListWithPagination from '../../common/ListWithPagination';
import ReactHtmlParser from 'react-html-parser';
import searchIcon from '../../../assests/svg/searchIcon.svg';
import { getChallengeEventList, getTechnologyType, getFilterData } from '../dependencies/action';
import LeftSideCategory from '../../../components/common/LeftSideCategory';
import GlobalDropDown from '../../common/GlobalDropDown';
import Breadcrumb from "../../common/Breadcrumbs";
import ReadMoreModal from "../dependencies/component/ReadMoreModal";
import * as moment from 'moment';
import { initGA, logPageView } from '../../../dependencies/utils/analytics'
import './style.scss';
const items = [
    { to: '/', label: '/' },
    { to: '/upcoming-intervention-listing', label: 'What We Do' },
    { to: '/upcoming-intervention-listing', label: 'Our Sentinels' },
    { to: '/upcoming-intervention-listing', label: 'Upcoming Projects', active: true },
]

const UpcomingInterventions = (props) => {
    const { getChallengeEventList, currentInterventionList, getTechnologyType, interventionTechnology, getFilterData, filterData } = props
    const [page, setPage] = useState(0);
    const [categoryId, setCategory] = useState('');
    const [isviewPartner, setviewPartner] = useState(false);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [partnerData, setPartnerData] = useState(null);
    const [pageStatus, setPageStatus] = useState(false)
    const defaultFormData = {
        state: '',
        city: '',
        subTheme: '',
        search: ''
    }
    const [formData, setFormData] = useState(defaultFormData);


    const changePageNumber = (pageNumber) => {
        setPageStatus(false)
        setPage((pageNumber - 1) * 6)
    }
    useEffect(() => {

        getChallengeEventListData()
    }, [page, categoryId]);

    const handleInputChange = (e, name) => {
        const { value, } = e.target;

        setFormData((user) => {
            return { ...user, [name]: value }
        });
    };

    const getChallengeEventListData = () => {
        let date = moment().format('DD-MM-YYYY')
        getChallengeEventList(
            page,
            categoryId,
            formData.city,
            formData.search,
            formData.subTheme,
            formData.state,
            date,
        );
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setPageStatus(true)
        getChallengeEventListData()
    };


    useEffect(() => {
        getTechnologyType();
        getFilterData();
    }, [])

    const getCategoryId = (Id) => {
        setCategory(Id)
    };

    const modalToggler = (data) => {
        setModalData(data)
        setShow(true);
    }

    const toggleViewPartner = (data) => {
        setPartnerData(data)
        setviewPartner(!isviewPartner);
    };

    
    useEffect(() => {
        try {
          initGA()
          window.GA_INITIALIZED = true
          logPageView()
        } catch (error) {
          
        }
        }, [window])
    return (
        <div className="UpcomingInterventions">
            <Breadcrumb data={items} displayName={'Upcoming Projects'} />
            <div className='filter-row filter-row-common'>
            {/*<div className='filter-row filter-row-style'>*/}
                <div className='container'>
                        <form onSubmit={e => onSubmit(e)}>
                            <div className='row'>
                                <div className='col-md-1 filter-text'>Filter by</div>
                                <div className="user-type-dropdown col-2 select-box">
                                            {/*<GlobalDropDown
                                                containerStyle="form-group "
                                                selectTagStyle="form-control dropdown-media"
                                                onChange={handleInputChange}
                                                options={''}
                                                value={formData.city}
                                                name={'city'}
                                                defaultOptionText={'Select City'}
                                                error=""
                                            />*/}
                                            <InputField containerStyle="form-group "

                                                onChange={handleInputChange}
                                                options={''}
                                                value={formData.city}
                                                name={'city'}
                                                placeholder={'Enter City / Locality'}
                                                error=""
                                            />
                                        </div>
                                        <div className="user-type-dropdown col-2 select-box">
                                            <GlobalDropDown
                                                containerStyle="form-group "
                                                selectTagStyle="form-control dropdown-media"
                                                onChange={handleInputChange}
                                                options={filterData?.list?.state}
                                                value={formData.state}
                                                name={'state'}
                                                defaultOptionText={'Select State'}
                                                error=""
                                            />
                                        </div>
                                        <div className="user-type-dropdown col-2 select-box">
                                            <GlobalDropDown
                                                containerStyle="form-group "
                                                selectTagStyle="form-control dropdown-media"
                                                onChange={handleInputChange}
                                                options={filterData?.list?.subThemes}
                                                value={formData.subTheme}
                                                name={'subTheme'}
                                                defaultOptionText={'Select SubTheme'}
                                                error=""
                                            />
                                        </div>
                                <div className='col-1'>
                                    <Button
                                            // icon={(<img src={searchIcon} className='search-icon' />)}
                                            type="submit"
                                            text="Apply"
                                            className='green-btn filter-btn'
                                        />
                                </div>
                            </div>
                        </form>
                </div>

            </div>
            <div className='bottom-section-list'>
                <div className='container common-listing upcoming-interventions'>
                        <div className="row">
                            <div className='col-md-3 sidebar'>
                                <div className="category-box">
                                <h3 className="sub-title f19">{ReactHtmlParser(interventionTechnology?.heading)}</h3>
                                <ul>
                                    {
                                        interventionTechnology
                                        && Array.isArray(interventionTechnology.list)
                                        && interventionTechnology.list.length > 0
                                        && interventionTechnology.list.map((value, index) => {
                                            let Id = value?.id
                                            return <li><LeftSideCategory onClickReply={() => {
                                                getCategoryId(Id)
                                            }} className={value?.id === categoryId ? 'category-active' : ''}
                                                categoryName={value?.name} count={value?.count}
                                                key={index} /></li>
                                        })
                                    }
                                </ul>
                                </div>
                            </div>
                            <div className='col-md-9 list-container'>
                                <div className='boxWithShadow'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="common-listing-title">
                                             {ReactHtmlParser(currentInterventionList?.heading)}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                             <div className="search">
                                                  <form onSubmit={e => onSubmit(e)}>
                                            <InputField
                                                type="search"
                                                name="search"
                                                placeholder="Search Projects"
                                                value={formData.search}
                                                onChange={handleInputChange}
                                                containerClass={"form-group"}
                                                className='subscribe-input-field'
                                            />
                                            <div className="btn-group">
                                                <Button
                                                    icon={(<img src={searchIcon} className='search-icon' />)}
                                                    type="submit"
                                                    text=""
                                                    className='list-submit-button'
                                                />
                                            </div>
                                        </form>
                                             </div>
                                        </div>

                                    </div>

                                    <ListWithPagination
                                        data={currentInterventionList?.list}
                                        itemsCountPerPage={6}
x                                        totalItemsCount={currentInterventionList?.totalCount}
                                        pageRangeDisplayed={5}
                                        onChangePagination={changePageNumber}
                                        checkStatus={pageStatus}
                                        nextUrl={'current-intervention-listing'}
                                        modalToggle={modalToggler}
                                        showPartner={isviewPartner}
                                        partnerData={partnerData}
                                        isViewPartner={toggleViewPartner}
                                        type = {'upcoming-intervention'}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <ReadMoreModal data={modalData}
            className="read-more-modal-content"
            isOpen={show}
            onClose={() => {
                setShow(false)
            }} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currentInterventionList: state.UpcomingInterventionsReducer.currentInterventionList.data,
        interventionTechnology: state.UpcomingInterventionsReducer.interventionTechnology.data,
        filterData: state.UpcomingInterventionsReducer.filterData
    }
}

const mapDispatchToProps = (dispatch) => ({
	getChallengeEventList: bindActionCreators(getChallengeEventList, dispatch),
	getTechnologyType: bindActionCreators(getTechnologyType, dispatch),
	getFilterData: bindActionCreators(getFilterData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpcomingInterventions));