// initiatives without dropdown

// import ReactHtmlParser from "react-html-parser";
// import "./style.scss";
// import teamImage from "../../../../../assests/images/malvika_image.png";
// import teamImage2 from "../../../../../assests/images/shailja.jpg";
// import Slider from "react-slick";
// import { useState, useEffect } from "react";
// import * as moment from "moment";
// import SocialShare from "../../../../common/SocialShare";
// import { API_URL } from "../../../../../constants/constants/ApplicationUrl";
// import evnbg from "../../../../../assests/images/pledge-certificate.png";
// import { Link } from "react-router-dom";
// import ImageSlider from "./Modal/ImageSlider";
// import DateField from "../../../../DateField";
// import ReadMoreReact from "read-more-react";
// import ReadMore from "../../../../common/ReadMore/ReadMore";
// const SSInitiatives = (props) => {
//   const {
//     data,
//     description,
//     heading,
//     count,
//     onChangePagination,
//     showTabData,
//     search,
//     getRecogniseFellow,
//     onCLickRecogniseFellowId,
//   } = props;
//   let [expanded, setExpanded] = useState(false);
//   const handleReadMore = () => {
//     setExpanded(!expanded);
//   };
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: data?.length < 3 ? data?.length : count,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 700,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//     adaptiveHeight: false,
//   };

//   const [showSlider, setShowSlider] = useState(false);
//   const [allImages, setAllImages] = useState([]);

//   const [date, setDate] = useState("");
//   const [selectedDate, setSelectedDate] = useState("");

//   const SmartText = ({ text, length = 20 }) => {
//     const [showLess, setShowLess] = useState(true);

//     if (text.length < length) {
//       return <p>{text}</p>;
//     }
//   };
//   const onClickShowImageModal = (img) => {
//     setAllImages(img);
//     setShowSlider(true);
//   };

//   const handleInputChange = (value) => {
//     console.log("Selected Date", value);
//     setDate(value);
//     if (value === null || value === "") {
//       setSelectedDate("");
//     } else {
//       setSelectedDate(moment(value).format("YYYY-MM"));
//     }
//   };
//   return (
//     <div className="MeatTeam2">
//       {/* <div className=' want-to-fellow-detail-row'>
//               <div class="col-md-0 green-bird"></div>
//               </div> */}
//       <div className="container" style={{ paddingTop: "40px" }}>
//         <div className="container-wrap">
//           <div className="row">
//             <div className="col-lg-12">
//               {/* <div className='partner-heading'>{ReactHtmlParser(heading)}</div>
//                             <div className='partner-sub-heading'>{ReactHtmlParser(description)}</div> */}

//               <div className="want-to-fellow-title">
//                 <span>Swachhta Saarthi Initiatives</span>
//               </div>
//               <div className="d-flex justify-content-between ssinitinput">
//                 <div className="partner-sub-heading"></div>
//                 <div className="float-right col-sm-3 ssinitinput2">
//                   <DateField
//                     required
//                     className="report-date-picker include-icon"
//                     type="month"
//                     name="month"
//                     placeholder="Select Date"
//                     value={date}
//                     onChange={handleInputChange}
//                     containerClass={"form-group"}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div
//             className={
//               data?.length < 3
//                 ? "want-to-fellow-detail-row2 lessthanthreerow3"
//                 : "want-to-fellow-detail-row2"
//             }
//           >
//             <Slider {...settings}>
//               {data &&
//                 Array.isArray(data) &&
//                 data.length > 0 &&
//                 data
//                   .filter((value) =>
//                     selectedDate !== ""
//                       ? moment.unix(value.original_date).format("YYYY-MM") ===
//                         selectedDate
//                       : true
//                   )
//                   .map((value, index) => {
//                     return (
//                       <div className="fellows-wrapper">
//                         <div className="fellows-block">
//                           <div className="row">
//                             <div className="col-md-12" key={index}>
//                               <div className="fellow-item">
//                                 <div className="fellow-item-head">
//                                   <div className="fellow-img">
//                                     {value?.fellow_profile_pic && (
//                                       <img src={value.fellow_profile_pic} />
//                                     )}
//                                   </div>
//                                   <div className="fellow-info">
//                                     <ul>
//                                       <li>
//                                         <span className="fellow-name">
//                                           <strong>
//                                             {value?.fellow_name}
//                                             {value.affiliationId}{" "}
//                                           </strong>
//                                         </span>
//                                       </li>
//                                       <li>
//                                         <span className="fellow-topic">
//                                           {value?.field_project_name}
//                                         </span>
//                                       </li>
//                                       <li>
//                                         <span className="fellow-address">
//                                           {value?.field_project_location}
//                                         </span>
//                                       </li>
//                                       <li>
//                                         <span className="fellow-date">
//                                           {value.date}
//                                         </span>
//                                       </li>
//                                     </ul>
//                                   </div>
//                                 </div>
//                                 <div className="fellow-item-content">
//                                   <div className="description">
//                                     <ReadMore
//                                       text={
//                                         value?.field_initiatives_description
//                                       }
//                                       length={150}
//                                     />
//                                   </div>
//                                   <div className="fellow-content-img">
//                                     {value?.thumbnail &&
//                                       value?.thumbnail.length > 0 && (
//                                         <div className="image">
//                                           <img
//                                             src={value?.thumbnail[0]}
//                                             onClick={() => {
//                                               onClickShowImageModal(
//                                                 value?.thumbnail
//                                               );
//                                             }}
//                                           />
//                                         </div>
//                                       )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     );
//                   })}
//             </Slider>
//           </div>
//           <div className="view-all-wrap d-flex justify-content-end">
//             <Link
//               className="d-flex align-items-center green-circle-link"
//               to={{ pathname: "/view-all-project", state: { results: data } }}
//             >
//               {" "}
//               View All Initiatives
//               {/* <a href="view-all-mentor" className="d-flex align-items-center green-circle-link">
//                               View All Mentors

//                             </a> */}
//               <i className="circle-goto-link-green" />
//             </Link>
//             {/* <a href="view-all-project" className="d-flex align-items-center green-circle-link">
//                               View All Initiatives
//                                 <i className="circle-goto-link-green" />
//                             </a> */}
//           </div>
//         </div>
//       </div>
//       <ImageSlider
//         isOpen={showSlider}
//         imageList={allImages}
//         className={"gallery-popup"}
//         onClose={() => {
//           setShowSlider(false);
//         }}
//       />
//     </div>
//   );
// };

// export default SSInitiatives;

// initiatives with dropdown

import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import Select from "react-select";

import teamImage from "../../../../../assests/images/malvika_image.png";
import teamImage2 from "../../../../../assests/images/shailja.jpg";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import * as moment from "moment";
import SocialShare from "../../../../common/SocialShare";
import { API_URL } from "../../../../../constants/constants/ApplicationUrl";
import evnbg from "../../../../../assests/images/pledge-certificate.png";
import { Link } from "react-router-dom";
import ImageSlider from "./Modal/ImageSlider";
import DateField from "../../../../DateField";
import ReadMoreReact from "read-more-react";
import ReadMore from "../../../../common/ReadMore/ReadMore";
import { truncate } from "fs";

const SSInitiatives = (props) => {
  const {
    data,
    description,
    heading,
    count,
    onChangePagination,
    showTabData,
    search,
    getRecogniseFellow,
    onCLickRecogniseFellowId,
    setInitYearSelected,
  } = props;

  const newInitArray = [
    {
      value: "2022",
      label: "Cohort 2",
      isdisabled: true,
    },
    {
      value: "2021",
      label: "Cohort 1",
    },
  ];

  console.log("data length", data?.length);
  console.log("data initiative", data);
  let [expanded, setExpanded] = useState(false);
  const handleReadMore = () => {
    setExpanded(!expanded);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length < 3 ? data?.length : count,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: false,
  };

  const [showSlider, setShowSlider] = useState(false);
  const [allImages, setAllImages] = useState([]);

  const [date, setDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const [selectedyearInitiatives, setSelectedyearInitiatives] = useState(
    newInitArray[1]
  );

  const SmartText = ({ text, length = 20 }) => {
    const [showLess, setShowLess] = useState(true);

    if (text.length < length) {
      return <p>{text}</p>;
    }
  };
  const onClickShowImageModal = (img) => {
    setAllImages(img);
    setShowSlider(true);
  };

  const handleInputChange = (value) => {
    console.log("Selected Date", value);
    setDate(value);
    if (value === null || value === "") {
      setSelectedDate("");
    } else {
      setSelectedDate(moment(value).format("YYYY-MM"));
    }
  };

  useEffect(() => {
    setInitYearSelected(selectedyearInitiatives);
  }, [selectedyearInitiatives]);

  const yearArrayStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isSelected ? "#f4f4f4" : null,

        // color: "#333333",
        color: isDisabled ? "lightgrey" : "#333333",
      };
    },

    control: (base, state) => ({
      ...base,
      background: "#fff",
      marginRight: "10px",
      height: "42px",
      fontSize: "15px",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#dddddd" : "#dddddd",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#2ba749" : "#dddddd",
      },
    }),
  };

  return (
    <div className="MeatTeam2 ssinitiativesmaindiv">
      {/* <div className=' want-to-fellow-detail-row'>
              <div class="col-md-0 green-bird"></div>
              </div> */}
      <div className="container" style={{ paddingTop: "40px" }}>
        <div className="container-wrap">
          <div className="row">
            <div className="col-lg-12">
              {/* <div className='partner-heading'>{ReactHtmlParser(heading)}</div>
                            <div className='partner-sub-heading'>{ReactHtmlParser(description)}</div> */}
              <div className="row">
                <div className="want-to-fellow-title title-paddingx col-lg-6">
                  <span>Swachhta Saarthi Initiatives</span>
                </div>
                <div
                  className="about-mentor-title col-lg-6"
                  style={{
                    display: "flex",
                    justifyContent: " flex-end",
                    // zIndex: 2,
                  }}
                >
                  <Select
                    className="fellowcompSelector"
                    placeholder="Cohort"
                    options={newInitArray}
                    styles={yearArrayStyles}
                    value={selectedyearInitiatives}
                    // value={yearArray[0]}
                    onChange={(e) => {
                      setSelectedyearInitiatives(e);
                      console.log(e.value);
                    }}
                    isOptionDisabled={(option) => option.isdisabled}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between ssinitinput">
                <div className="partner-sub-heading"></div>
                <div className="float-right col-sm-3 ssinitinput2">
                  <DateField
                    required
                    className="report-date-picker include-icon"
                    type="month"
                    name="month"
                    placeholder="Select Date"
                    value={date}
                    onChange={handleInputChange}
                    containerClass={"form-group"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              data?.length < 3
                ? "want-to-fellow-detail-row2 lessthanthreerow3"
                : "want-to-fellow-detail-row2"
            }
          >
            <Slider {...settings}>
              {data &&
                Array.isArray(data) &&
                data.length > 0 &&
                data
                  .filter((value) =>
                    selectedDate !== ""
                      ? moment.unix(value.original_date).format("YYYY-MM") ===
                        selectedDate
                      : true
                  )
                  .map((value, index) => {
                    return (
                      <div className="fellows-wrapper fellow-webx">
                        <div className="fellows-block">
                          <div className="row">
                            <div className="col-md-12" key={index}>
                              <div className="fellow-item">
                                <div className="fellow-item-head">
                                  <div className="fellow-img">
                                    {value?.fellow_profile_pic && (
                                      <img src={value.fellow_profile_pic} />
                                    )}
                                  </div>
                                  <div className="fellow-info">
                                    <ul>
                                      <li>
                                        <span className="fellow-name">
                                          <strong>
                                            {value?.fellow_name}
                                            {value.affiliationId}{" "}
                                          </strong>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="fellow-topic">
                                          {value?.field_project_name}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="fellow-address">
                                          {value?.field_project_location}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="fellow-date">
                                          {value.date}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="fellow-item-content">
                                  <div className="description">
                                    <ReadMore
                                      text={
                                        value?.field_initiatives_description
                                      }
                                      length={150}
                                    />
                                  </div>
                                  <div className="fellow-content-img">
                                    {value?.thumbnail &&
                                      value?.thumbnail.length > 0 && (
                                        <div className="image">
                                          <img
                                            src={value?.thumbnail[0]}
                                            onClick={() => {
                                              onClickShowImageModal(
                                                value?.thumbnail
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </Slider>
          </div>
          <div className="view-all-wrap d-flex justify-content-end view-allIniiative">
            <Link
              className="d-flex align-items-center green-circle-link"
              to={{ pathname: "/view-all-project", state: { results: data } }}
            >
              {" "}
              View All Initiatives
              {/* <a href="view-all-mentor" className="d-flex align-items-center green-circle-link">
                              View All Mentors

                            </a> */}
              <i className="circle-goto-link-green" />
            </Link>
            {/* <a href="view-all-project" className="d-flex align-items-center green-circle-link">
                              View All Initiatives
                                <i className="circle-goto-link-green" />
                            </a> */}
          </div>
        </div>
      </div>
      <ImageSlider
        isOpen={showSlider}
        imageList={allImages}
        className={"gallery-popup"}
        onClose={() => {
          setShowSlider(false);
        }}
      />
    </div>
  );
};

export default SSInitiatives;
