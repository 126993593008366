import {
  ArticlesService,
  //   ArticlesCaseStudiesService,
} from "./service";
import {
  CURRENT_INTERVENTION_LIST,
  INTERVENTION_TECNOLOGY,
  CURRENT_INTERVENTION_DETAILS,
  FILTER_DATA,
  ARTICLES_CASE_STUDIES_LIST,
  ARTICLES_CASE_STUDIES_TAGS,
  ARTICLES_CASE_STUDIES_DETAILS,
  ARTICLES_CASE_STUDIES_MOSTREAD,
  ARTICLES_CASE_STUDIES_TAG_LIST
} from "../../../constants/actionConstants";
import { isLoading } from "../../../dependencies/action/action";

export function getChallengeEventList(
  page,
  categoryId,
  city,
  search,
  usetype,
  subtheme,
  scale,
  condition,
  state,
  duration
) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let currentInterventionList =
      await ArticlesService.getCurrentInterventionList(
        page,
        categoryId,
        city,
        search,
        usetype,
        subtheme,
        scale,
        condition,
        state,
        duration
      );
    dispatch({
      type: CURRENT_INTERVENTION_LIST,
      payload: currentInterventionList,
    });
    dispatch(isLoading(false));
  };
}

export function getTechnologyType() {
  return async function (dispatch) {
    let technologyType = await ArticlesService.getTechnologyType();
    dispatch({
      type: INTERVENTION_TECNOLOGY,
      payload: technologyType,
    });
  };
}

export function getArticlesTagsType() {
  return async function (dispatch) {
    let articlesTagsType = await ArticlesService.getArticlesTagsType();
    dispatch({
      type: ARTICLES_CASE_STUDIES_TAGS,
      payload: articlesTagsType,
    });
  };
}

export function getArticlesTechnologyType(
  page,
  categoryId,
  city,
  search,
  usetype,
  subtheme,
  scale,
  condition,
  state,
  duration,
  date,
  tag
) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let articlesTechnology = await ArticlesService.getArticlesTechnology(
      page,
      categoryId,
      city,
      search,
      usetype,
      subtheme,
      scale,
      condition,
      state,
      duration,
      date,
      tag
    );
    dispatch({
      type: ARTICLES_CASE_STUDIES_LIST,
      payload: articlesTechnology,
    });
    dispatch(isLoading(false));
  };
}

export function getArticlesTagList(
  page,
  categoryId,
  city,
  search,
  usetype,
  subtheme,
  scale,
  condition,
  state,
  duration,
  date,
  tag
) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let articlesTechnology = await ArticlesService.getArticlesTagList(
      page,
      categoryId,
      city,
      search,
      usetype,
      subtheme,
      scale,
      condition,
      state,
      duration,
      date,
      tag
    );
    dispatch({
      type: ARTICLES_CASE_STUDIES_TAG_LIST,
      payload: articlesTechnology,
    });
    dispatch(isLoading(false));
  };
}

export function getArticlesDetailsType(path_alias) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let articlesDetails = await ArticlesService.getArticlesDetails(path_alias);
    dispatch({
      type: ARTICLES_CASE_STUDIES_DETAILS,
      payload: articlesDetails,
    });
    dispatch(isLoading(false));
  };
}

export function getMostReadType() {
  return async function (dispatch) {
    let mostReadType = await ArticlesService.getMostReadType();
    dispatch({
      type: ARTICLES_CASE_STUDIES_MOSTREAD,
      payload: mostReadType,
    });
  };
}

export function getCurrentInterventionDetails(url) {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let currentInterventionDetails =
      await ArticlesService.getCurrentInterventionDetails(url);

    dispatch({
      type: CURRENT_INTERVENTION_DETAILS,
      payload: currentInterventionDetails,
    });
    dispatch(isLoading(false));
  };
}

export function getFilterData() {
  return async function (dispatch) {
    dispatch(isLoading(true));
    let filterData = await ArticlesService.filterData();
    dispatch({
      type: FILTER_DATA,
      payload: filterData,
    });
    dispatch(isLoading(false));
  };
}
