import api from '../../../dependencies/utils/Api';
import {
    // CURRENT_INTERVENTION_LIST,
    // INTERVENTION_TECNOLOGY,
    // CURRENT_INTERVENTION_DETAILS,
    // FILTER_DATA
    SUCCESS_STORIES_LIST,
    SUCCESS_STORIES_TECNOLOGY,
    SUCCESS_STORIES_DETAILS,
    SUCCESS_STORIES_FILTER_DATA

} from "../../../constants/ApplicationUrl";

export const SuccessStoryService = {
    getSuccessStoryList(page, categoryId, city, search,usetype,subtheme,scale,condition,state,duration) {
        return api.GET(`${SUCCESS_STORIES_LIST}?page=${page}&category=${categoryId}&city=${city}&search=${search}&usetype=${usetype}&subtheme=${subtheme}&scale=${scale}&condition=${condition}&state=${state}&duration=${duration}`)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getTechnologyType() {
        return api.GET(SUCCESS_STORIES_TECNOLOGY)
            .then((response) => {
                const { data = { data } } = response.data || {};
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    getSuccessStoryDetails(url) {
        return api.GET(`${SUCCESS_STORIES_DETAILS}/${url}`)
            .then((response) => {
                const { data = { data } } = response.data || {};
      
                return { data }
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    filterData() {
        return api.GET(SUCCESS_STORIES_FILTER_DATA)
            .then((response) => {
                const { data: { responseCode, data } = {} } = response || {};
                if (responseCode === 200) {
                    return data;
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            })
    }

}