import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";
// import './style.scss';

const Overview = (props) => {
    const { data, body, mainTitle, category } = props
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };
    return (
        <div className="project-info">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div class="dotted-sq"></div>
                        <div className="image">
                        <Slider {...settings}>
                                {
                                    data
                                    && Array.isArray(data?.sliderImage)
                                    && data?.sliderImage?.length > 0
                                    && data?.sliderImage?.map((value, index) => {
                                        return (
                                            <img src={value?.image} alt=""  key={index} />
                                        )
                                    })
                                }
                            </Slider>
                            {/* <img
                                src={data
                                    && data?.sliderImage
                                    && data?.sliderImage.length > 0
                                    && data?.sliderImage[0].image}
                            /> */}
                        </div>
                    </div>
                    <div className="col-md-5 info">
                        <div class="green-bird"></div>
                        <div class="white-leaf"></div>
                        <h2>Overview</h2>
                        <ul>
                            <li> <span className="start-date">Start Date:</span> {data?.startDate}</li>
                            <li> <span className="end-date">End Date:</span> {data?.endDate}</li>
                            <li> <span className="location">Location:</span> {data?.location}</li>
                            <li> <span className="status">Status:</span> {data?.status?.value}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3> {ReactHtmlParser(mainTitle)}</h3>
                        {ReactHtmlParser(body)}

                        <div className="tag">
                            {
                                category
                                && Array.isArray(category)
                                && category.length > 0
                                && category.map((value, key) => {
                                    return <span className="green-bg" >{value?.title}</span>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div class="large-tree"></div>
                <div class="small-tree"></div>
            </div>
        </div>

    );
}

export default Overview;